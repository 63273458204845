import { useFlag } from '@unleash/proxy-client-react';
import { Avatar, Center, SimpleGrid, Text, UnstyledButton } from '@mantine/core';
import { BarkerCoreEnumsMarketplace } from '../../api';
import mobileClasses from '../../pages/MarketFilters.Mobile.module.css';
import tevo from '../../img/tevo.png';
import tnet from '../../img/tnet.jpeg';
import tm from '../../img/tm.png';
import { ReactComponent as SeatGeekLogo } from '../../img/sg.svg';
import { ReactNode } from 'react';
import { MarketplaceSelectorProps } from './SeatingChart.MarketplaceSelector.types';

export function MarketplaceSelectorMobile({ onChangeMarketplace, selectedMarketplaceId, availableMarketplaces }: MarketplaceSelectorProps) {
  const isTicketNetworkMarketplaceEnabled = useFlag('ticket-network-marketplace');

  // console.log(selectedMarketplaceId);

  return (
    <SimpleGrid cols={4} my="md" px={4} spacing="xs" pb="xl">
      <MobileMarketButton
        title="TEVO"
        selected={selectedMarketplaceId === BarkerCoreEnumsMarketplace.TicketEvolution}
        img={tevo}
        disabled={!availableMarketplaces?.includes(BarkerCoreEnumsMarketplace.TicketEvolution)}
        disabledMessage="Not Available"
        onClick={availableMarketplaces?.includes(BarkerCoreEnumsMarketplace.TicketEvolution) ? () => onChangeMarketplace(BarkerCoreEnumsMarketplace.TicketEvolution) : undefined}
      />
      <MobileMarketButton
        title="SeatGeek"
        selected={selectedMarketplaceId === BarkerCoreEnumsMarketplace.SeatGeek}
        img={<SeatGeekLogo />}
        disabled={!availableMarketplaces?.includes(BarkerCoreEnumsMarketplace.SeatGeek)}
        disabledMessage="Not Available"
        onClick={availableMarketplaces?.includes(BarkerCoreEnumsMarketplace.SeatGeek) ? () => onChangeMarketplace(BarkerCoreEnumsMarketplace.SeatGeek) : undefined}
      />
      <MobileMarketButton
        title="TicketNetwork"
        selected={selectedMarketplaceId === BarkerCoreEnumsMarketplace.TicketNetwork}
        img={tnet}
        disabled={!isTicketNetworkMarketplaceEnabled || !availableMarketplaces?.includes(BarkerCoreEnumsMarketplace.TicketNetwork)}
        disabledMessage={!isTicketNetworkMarketplaceEnabled ? 'Coming Soon' : 'Not Available'}
        onClick={availableMarketplaces?.includes(BarkerCoreEnumsMarketplace.TicketNetwork) ? () => onChangeMarketplace(BarkerCoreEnumsMarketplace.TicketNetwork) : undefined}
      />
      <MobileMarketButton
        title="Ticketmaster"
        selected={selectedMarketplaceId === BarkerCoreEnumsMarketplace.Ticketmaster}
        img={tm}
        disabled={!availableMarketplaces?.includes(BarkerCoreEnumsMarketplace.Ticketmaster)}
        disabledMessage={availableMarketplaces?.includes(BarkerCoreEnumsMarketplace.Ticketmaster) ? 'Not Available' : 'Coming Soon'}
        onClick={availableMarketplaces?.includes(BarkerCoreEnumsMarketplace.Ticketmaster) ? () => onChangeMarketplace(BarkerCoreEnumsMarketplace.Ticketmaster) : undefined}
      />
    </SimpleGrid>
  );
}

type MobileMarketButtonProps = {
  title: string;
  selected?: boolean;
  img?: string | ReactNode;
  disabled?: boolean;
  disabledMessage?: string;
  onClick?: () => void;
};

export function MobileMarketButton({ title, selected, img, disabled, disabledMessage, onClick }: MobileMarketButtonProps) {
  return (
    <>
      {!disabled ? (
        <UnstyledButton
          h={96}
          py="xs"
          style={
            selected
              ? { border: '1px solid var(--colors-selectedBorder)', borderRadius: 6, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12 }
              : { border: '1px solid var(--colors-gray-2)', borderRadius: 6, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12 }
          }
          bg={selected ? 'var(--colors-selectedBg)' : 'var(--colors-paper)'}
          onClick={onClick}
        >
          <Avatar
            key={title.toLowerCase()}
            m={0}
            mt={2}
            radius="xl"
            h={44}
            w={44}
            variant="outline"
            className={mobileClasses.avatar}
            bg="var(--colors-paper)"
            color={selected ? 'var(--colors-selectedBorder)' : 'var(--colors-gray-5)'}
            styles={
              selected
                ? {
                    placeholder: {
                      borderColor: 'var(--colors-selectedBorder)',
                    },
                  }
                : {}
            }
            style={selected ? { borderColor: 'var(--colors-selectedBorder)' } : {}}
          >
            {typeof img === 'string' ? <img className="mantine-Avatar-image" height={44} width={44} src={img} alt={title} /> : img}
          </Avatar>
          <Text fz={10} truncate maw="100%" px={2}>
            {title}
          </Text>
        </UnstyledButton>
      ) : (
        <UnstyledButton
          h={96}
          py="xs"
          pos="relative"
          style={{ border: '1px solid var(--colors-gray-2)', filter: 'grayscale(1)', borderRadius: 6, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12 }}
        >
          <Center h={44} w={44} pos="absolute" mt={2} fz={8} lts={0.4} lh={1.3} style={{ textTransform: 'uppercase', textAlign: 'center', zIndex: 1 }}>
            {disabledMessage}
          </Center>
          <Avatar
            key={title.toLowerCase()}
            m={0}
            mt={2}
            radius="xl"
            h={44}
            w={44}
            variant="outline"
            opacity={0.25}
            className={mobileClasses.avatar}
            color="var(--colors-darkPaper)"
          >
            {typeof img === 'string' ? <img className="mantine-Avatar-image" height={44} width={44} src={img} alt={title} /> : img}
          </Avatar>
          <Text opacity={0.5} fz={10}>
            {title}
          </Text>
        </UnstyledButton>
      )}
    </>
  );
}
