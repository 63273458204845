import { IconPropsBase } from './IconPropsBase';

export default function AccessibilityNewIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M12 5.9q-.775 0-1.312-.538-.538-.537-.538-1.312 0-.75.538-1.3.537-.55 1.312-.55t1.312.55q.538.55.538 1.3 0 .775-.538 1.312Q12.775 5.9 12 5.9ZM9.4 21.75V8.7q-1.525-.125-3.037-.35Q4.85 8.125 3.5 7.775l.375-1.5q1.9.5 3.963.712Q9.9 7.2 12 7.2t4.163-.213q2.062-.212 3.962-.712l.375 1.5q-1.35.35-2.862.575-1.513.225-3.038.35v13.05h-1.5v-6.125h-2.2v6.125Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M10 5.417q-.688 0-1.156-.469-.469-.469-.469-1.156 0-.667.479-1.146.479-.479 1.146-.479.688 0 1.156.479.469.479.469 1.166 0 .667-.479 1.136-.479.469-1.146.469ZM7.833 17.542V7.854q-1.166-.083-2.25-.312-1.083-.23-2.166-.5l.271-.98q1.583.417 3.124.636 1.542.219 3.188.219 1.646 0 3.188-.219 1.541-.219 3.124-.636l.271.98q-1.083.27-2.166.5-1.084.229-2.25.333v9.667h-1.084l-.187-4.73H9.125l-.208 4.73Z"
        />
      </svg>
    );
  }
}
