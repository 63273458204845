import { IconPropsBase } from './IconPropsBase';

export default function AlternateEmailIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M480-100q-78.77 0-148.14-29.92-69.37-29.92-120.68-81.21-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.93 69.37 29.92 120.68 81.22t81.25 120.65Q860-558.85 860-480v48.77q0 54.77-37.61 93T730-300q-35.39 0-65.62-17.31-30.23-17.31-47.77-47.62Q590.69-334 555.35-317 520-300 480-300q-74.92 0-127.46-52.54Q300-405.08 300-480q0-74.92 52.54-127.46Q405.08-660 480-660q74.92 0 127.46 52.54Q660-554.92 660-480v48.77q0 29.46 20.27 50.35Q700.54-360 730-360q29.46 0 49.73-20.88Q800-401.77 800-431.23V-480q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93h200v60H480Zm0-260q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M480.39-116q-75.28 0-141.59-28.42t-116.12-78.21q-49.81-49.79-78.25-116.09Q116-405.01 116-480.39q0-75.38 28.42-141.25t78.21-115.68q49.79-49.81 116.09-78.25Q405.01-844 480.39-844q75.38 0 141.25 28.43 65.87 28.42 115.68 78.22t78.25 115.65Q844-555.85 844-480v50.77q0 50.61-35.61 85.92T722-308q-34.39 0-62.12-19.31-27.73-19.31-44.42-49.62Q590.69-346 555.35-327 520-308 480-308q-71.6 0-121.8-50.27Q308-408.53 308-480.23q0-71.69 50.27-121.73Q408.53-652 480.23-652q71.69 0 121.73 50.2Q652-551.6 652-480v50.77q0 28.62 20.77 48.93Q693.54-360 722-360q28.46 0 49.23-20.3Q792-400.61 792-429.23V-480q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91h192v52H480.39ZM480-360q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Z"
        />
      </svg>
    );
  }
}
