import { Center, Flex, SegmentedControl, Text, Tooltip } from '@mantine/core';
import classes from './Inventory.Header.ViewOptions.module.css';
import TableRowsIcon from '../../components/icons/TableRows';
import ViewAgendaIcon from '../../components/icons/ViewAgenda';
import { PricerView } from '../../types';
import { FeatureHintWrapper } from '../FeatureHint/FeatureHintWrapper';
import { useGlobalState } from '../../data/GlobalState';

export const ViewOptions = ({ afterChanged }: { afterChanged: () => void }) => {
  const { pricerView, setPricerView } = useGlobalState('pricerView', 'setPricerView');
  // const [density, setDensity] = useState('default');

  return (
    // <Flex bg="var(--colors-paper)" gap="sm" justify="end" align="center" p="xs" pl="md" pr={1} style={{ borderLeft: '1px solid var(--colors-divider)' }}>
    <Flex bg="var(--colors-paper)" gap="sm" justify="end" align="center" h={16}>
      <Text className="viewOptionsText" size="xs" c="var(--colors-gray-5)">
        View
      </Text>
      <FeatureHintWrapper
        name="split-view"
        enabled
        target={
          <SegmentedControl
            size="xs"
            h={28}
            className={classes.nestedSegmentedControl}
            styles={{
              root: {
                background: 'var(--colors-paper)',
                padding: 0,
              },
              indicator: {
                boxShadow: 'none',
                background: 'var(--colors-opacity-hover)',
              },
              label: {
                boxShadow: 'none',
                padding: 0,
                color: 'var(--colors-gray-5)',
              },
            }}
            onChange={(value) => {
              setPricerView(value as PricerView);
              afterChanged();
            }}
            value={pricerView}
            data={[
              {
                label: (
                  <Tooltip label="Default View" withArrow>
                    <Center w={28} h={28}>
                      <TableRowsIcon size={20} />
                    </Center>
                  </Tooltip>
                ),
                value: PricerView.Standard,
              },
              {
                label: (
                  <Tooltip label="Split View" withArrow>
                    <Center w={28} h={28}>
                      <ViewAgendaIcon size={20} />
                    </Center>
                  </Tooltip>
                ),
                value: PricerView.Split,
              },
            ]}
          />
        }
      />
      {/* <Divider orientation="vertical" h={20} my="auto" color="var(--colors-gray-2)" />
      <SegmentedControl
        size="xs"
        h={28}
        className={classes.nestedSegmentedControl}
        styles={{
          root: {
            background: 'var(--colors-paper)',
            padding: 0,
          },
          indicator: {
            boxShadow: 'none',
            background: 'var(--colors-opacity-hover)',
          },
          label: {
            boxShadow: 'none',
            padding: 0,
            color: 'var(--colors-gray-5)',
          },
        }}
        onChange={(value) => {
          setDensity(value);
        }}
        value={density}
        data={[
          {
            label: (
              <Tooltip label="Default View" withArrow>
                <Center w={28} h={28}>
                  <DensitySmallIcon size={18} />
                </Center>
              </Tooltip>
            ),
            value: 'default',
          },
          {
            label: (
              <Tooltip label="Compact View" withArrow>
                <Center w={28} h={28}>
                  <FormatAlignJustifyIcon size={18} />
                </Center>
              </Tooltip>
            ),
            value: 'compact',
          },
        ]}
      /> */}
      {/*<Center h={20} pos="relative">*/}
      {/*  <Box className={classes.dividerArrow} />*/}
      {/*  <Divider orientation="vertical" h={20} my="auto" color="var(--colors-gray-3)" />*/}
      {/*</Center>*/}
    </Flex>
  );
};
