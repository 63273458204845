import { ActionIcon, Center, Divider, Flex, FocusTrap, Text } from '@mantine/core';
import classes from '../../../pages/ListingDetails.Mobile.module.css';
import { BNButton } from '../../../components/Button/Button';
import { useRef } from 'react';
import DoNotDisturbIcon from '../../../components/icons/DoNotDisturb';
import { BNNumberInput } from '../../../components/NumberInput/NumberInput';
import AddCircleIcon from '../../../components/icons/AddCircle';
import { MobileModalProps } from '../../../components/MobilePriceUpdateForm/MobilePriceUpdateForm';
import { useNowPricing } from '../../NowPricing/NowPricing.hooks';
import { useForm } from '@mantine/form';
import { useViewportPosition } from '../../../utils/use-viewport-position';

export function ComparablesUpdateForm({ closeModal }: MobileModalProps) {
  const { form } = useNowPricing('form');
  const {
    getInputProps,
    setFieldValue,
    onSubmit,
    values: { numComparables },
  } = useForm({
    initialValues: {
      numComparables: form.values.numComparables || 0,
    },
  });

  const ref = useRef<HTMLInputElement | null>(null);
  useViewportPosition(ref, [numComparables]);

  return (
    <FocusTrap>
      <form
        onSubmit={onSubmit((values) => {
          form.setFieldValue('numComparables', values.numComparables);
          closeModal();
        })}
      >
        <Flex p={0} h="100%" justify="space-between">
          <Flex py="sm" px="md" gap="xs" align="center" justify="start" style={{ flex: 1 }}>
            <Text size="md" fw={600}>
              # Comparables
            </Text>
          </Flex>
        </Flex>
        <Divider color="var(--colors-gray-0)" />
        <Flex py="xl" px="xl" w="100%" align="start" justify="center" gap="md">
          <ActionIcon
            className={classes.priceInputButton}
            onClick={() => {
              setFieldValue('numComparables', numComparables - 1);
            }}
          >
            <Center h={36} w={36} bg="var(--colors-gray-0)" style={{ borderRadius: 100 }}>
              <DoNotDisturbIcon size={30} color="var(--colors-selectedBorder)" />
            </Center>
          </ActionIcon>
          <BNNumberInput
            {...getInputProps('numComparables')}
            ref={ref}
            errorPosition="bottom"
            className={classes.priceInput}
            size="lg"
            w="calc(100% - 100px)"
            hideControls
            decimalScale={0}
            fixedDecimalScale
            data-autofocus
            autoFocus
            selectOnFocus
            inputMode="decimal"
          />
          <ActionIcon
            className={classes.priceInputButton}
            onClick={() => {
              setFieldValue('numComparables', numComparables + 1);
            }}
          >
            <Center h={36} w={36} bg="var(--colors-gray-0)" style={{ borderRadius: 100 }}>
              <AddCircleIcon size={30} color="var(--colors-selectedBorder)" />
            </Center>
          </ActionIcon>
        </Flex>
        <Divider color="var(--colors-gray-0)" />
        <Flex gap="sm" w="100%" py="xl" px="md">
          <BNButton fullWidth variant="default" onClick={() => closeModal()}>
            Cancel
          </BNButton>
          <BNButton fullWidth variant="filled" color="gray" type="submit">
            Done
          </BNButton>
        </Flex>
      </form>
    </FocusTrap>
  );
}
