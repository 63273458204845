import { Card, CardSection, Group, Skeleton, Stack } from '@mantine/core';
import classes from './SeasonList.SeasonCardSkeleton.module.css';

function SkeletonCard() {
  return (
    <Card radius={0} m={0} padding={0} h={128} className={classes.skeletonCard}>
      <CardSection p={16} py={12} pb={0}>
        <Skeleton height={8} width={120} mt={4} mb={16} />
        <Skeleton height={8} width={220} mt={16} mb={10} />
        <Skeleton height={8} width={180} mt={10} mb={16} />
      </CardSection>
      <CardSection p={16} py={12} pt={0}>
        <Group p={0} m={0} justify="space-between">
          <Group>
            <Skeleton height={8} width={40} />
          </Group>
          <Group gap={4}>
            <Skeleton height={8} width={40} />
          </Group>
        </Group>
      </CardSection>
    </Card>
  );
}

export function SeasonCardSkeleton() {
  return (
    <Stack gap={0}>
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
    </Stack>
  );
}
