import { IconPropsBase } from './IconPropsBase';

export default function EventListIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M632.307-140.001q-24.538 0-42.268-17.731-17.73-17.73-17.73-42.268v-167.693q0-24.538 17.73-42.268t42.268-17.73H800q24.538 0 42.268 17.73 17.731 17.73 17.731 42.268V-200q0 24.538-17.731 42.268-17.73 17.731-42.268 17.731H632.307Zm0-59.999H800v-167.693H632.307V-200Zm-532.306-53.847v-59.999h344.615v59.999H100.001Zm532.306-278.462q-24.538 0-42.268-17.73t-17.73-42.268V-760q0-24.538 17.73-42.268 17.73-17.731 42.268-17.731H800q24.538 0 42.268 17.731 17.731 17.73 17.731 42.268v167.693q0 24.538-17.731 42.268-17.73 17.73-42.268 17.73H632.307Zm0-59.998H800V-760H632.307v167.693Zm-532.306-53.847v-59.999h344.615v59.999H100.001Zm616.153 362.308Zm0-392.308Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M640.043-164.001q-21.274 0-36.504-15.407-15.23-15.406-15.23-36.68v-151.869q0-21.274 15.406-36.504 15.406-15.23 36.68-15.23h151.869q21.274 0 36.504 15.406 15.231 15.406 15.231 36.68v151.869q0 21.274-15.407 36.504-15.406 15.231-36.68 15.231H640.043Zm.264-51.999H792v-151.693H640.307V-216Zm-524.306-49.847v-51.999h368.615v51.999H116.001Zm524.042-274.462q-21.274 0-36.504-15.406-15.23-15.406-15.23-36.68v-151.869q0-21.274 15.406-36.504 15.406-15.231 36.68-15.231h151.869q21.274 0 36.504 15.407 15.231 15.406 15.231 36.68v151.869q0 21.274-15.407 36.504-15.406 15.23-36.68 15.23H640.043Zm.264-51.998H792V-744H640.307v151.693Zm-524.306-49.847v-51.999h368.615v51.999H116.001Zm600.153 350.308Zm0-376.308Z"
        />
      </svg>
    );
  }
}
