import millify from 'millify';
import { useMemo } from 'react';
import ListIcon from '../icons/List';
import { BNLabel } from './Label';
import pluralize from 'pluralize';
import classes from './OpenListingsLabel.module.css';

export const OpenlistingsLabel = ({
  listings,
  className,
  skeleton,
  hideOpen,
  label = 'listing',
}: {
  hideOpen?: boolean;
  className?: string;
  listings: number;
  skeleton?: boolean;
  label?: string;
}) => {
  const text = useMemo(() => (listings >= 1000 ? `${millify(listings || 0, { lowercase: true })}` : listings), [listings]);
  return (
    <BNLabel
      className={className || classes.label}
      leftIcon={<ListIcon size={20} />}
      text={text}
      skeleton={skeleton}
      tooltip={`${listings?.toLocaleString()}${!hideOpen ? ' open ' : ' '}${pluralize(label, listings)}`}
    />
  );
};
