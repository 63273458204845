import { IconPropsBase } from './IconPropsBase';

export default function SyncAltIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M286.154-133.847 100.001-320l186.153-186.153L328.922-464l-114 114.001h605.077v59.998H214.922l114 114.001-42.768 42.153Zm387.692-320L631.078-496l114-114.001H140.001v-59.998h605.077L631.078-784l42.768-42.153L859.999-640 673.846-453.847Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M294.154-157.847 116.001-336l178.153-178.153L330.922-477l-115 115.001h580.077v51.998H215.922l115 115.001-36.768 37.153Zm371.692-288L629.078-483l115-115.001H164.001v-51.998h580.077L629.078-765l36.768-37.153L843.999-624 665.846-445.847Z"
        />
      </svg>
    );
  }
}
