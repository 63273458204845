import { ManipulateType } from 'dayjs';

export function splitSearchRange(searchRange: string | undefined | null): [number, ManipulateType] | undefined {
  if (!searchRange) {
    return [1, 'M'];
  }
  const [unit, ...value] = searchRange;
  if (unit === 'a') {
    return undefined;
  }
  if (unit === 'c') {
    return [parseInt(value.join('')), 'd'];
  }
  return [parseInt(value.join('')), unit as ManipulateType];
}

export function reformatDateToISO(dateString: string): string {
  // Split the date string into components
  const [datePart, timePart, period] = dateString.split(' ');
  const [month, day, year] = datePart.split('/').map(Number);
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = timePart.split(':').map(Number);

  // Adjust for AM/PM
  if (period === 'PM' && hours !== 12) {
    hours += 12;
  } else if (period === 'AM' && hours === 12) {
    hours = 0; // Midnight case
  }

  // Manually format the date and time to ISO 8601
  const pad = (n: number): string => (n < 10 ? `0${n}` : n.toString());

  return `${year}-${pad(month)}-${pad(day)}T${pad(hours)}:${pad(minutes)}:00.000Z`;
}
