import { GroupCellRendererParams } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { Center, Loader, UnstyledButton } from '@mantine/core';
import { BarkerCoreModelsInventoryListing } from '../../api';
import SearchIcon from '../../components/icons/Search';
import { GroupHeader } from './Inventory.GroupHeader';
import { useInventory } from './Inventory.hooks';
import { ErrorBoundary } from 'react-error-boundary';
import { useEffect, useState } from 'react';
import { useGlobalState } from '../../data/GlobalState';
import { useFlag } from '@unleash/proxy-client-react';
import { useSetAtom } from 'jotai';
import { rowDraggingAtom } from '../../data/atoms';
import classes from './Inventory.styles.tsx.module.css';
import { useAppearanceSettings } from './Inventory.AppearanceSettings.hook';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';

export default function Grid() {
  const { isAnimationEnabled, setIsAnimationEnabled } = useGlobalState('isAnimationEnabled', 'setIsAnimationEnabled');
  const {
    gridRef,
    mergedEventListings,
    columnDefs,
    onGridReady,
    onMasterRowDragEnd,
    onMasterRowDragMove,
    defaultColDef,
    detailCellRendererParams,
    groupSortOrderComparator,
    onCellClickedWhenDirty,
    trySaveGridState,
    inventoryQuickFilter,
    setInventoryQuickFilter,
    setFilter,
    soldInventoryDetailCellRendererParams,
    isShowingEventPerformance,
    onRowGroupToggled,
    onRowDataUpdated,
  } = useInventory(
    'gridRef',
    'mergedEventListings',
    'columnDefs',
    'onGridReady',
    'onMasterRowDragEnd',
    'onMasterRowDragMove',
    'defaultColDef',
    'detailCellRendererParams',
    'groupSortOrderComparator',
    'onCellClickedWhenDirty',
    'trySaveGridState',
    'inventoryQuickFilter',
    'setInventoryQuickFilter',
    'setFilter',
    'soldInventoryDetailCellRendererParams',
    'isShowingEventPerformance',
    'onRowGroupToggled',
    'onRowDataUpdated',
  );
  const [error, setError] = useState(0);

  const animationFlag = useFlag('use-animations');
  const appearanceSettings = useAppearanceSettings();

  function CustomNoRowsOverlay() {
    return (
      <Center pb="xl">
        <BNEmptyState
          title="No Results Found"
          description="Try adjusting the filter options"
          bottomContent={
            inventoryQuickFilter && (
              <UnstyledButton
                mt={10}
                onClick={() => {
                  setFilter('');
                  setInventoryQuickFilter('');
                }}
                className={classes.grayLink}
              >
                or clear quick filter
              </UnstyledButton>
            )
          }
          icon={<SearchIcon color="var(--colors-iconFill)" size={28} />}
          iconBg="var(--colors-opacity-hover)"
          h={320}
          w={320}
          border={false}
        />
      </Center>
    );
  }

  function CustomLoadingOverlay() {
    return (
      <div className={`ag-overlay-loading-center ${classes.agOverlayLoadingCenter}`}>
        <Loader color="var(--colors-gray-5)" type="dots" />
      </div>
    );
  }

  const setIsRowDragging = useSetAtom(rowDraggingAtom);

  // Remove any drag & drop classes when the drop happens outside of the grid or viewport
  useEffect(() => {
    const removeClasses = () => {
      // dir-down is used to determine drop point for details grid.
      // Delay removal of classes to allow the details grid to determine position
      // This is a catch all handler when dropped off the grid
      setTimeout(() => {
        document.querySelector('body')?.classList.remove('row-dragging');
        document.querySelector('.show-dropzone')?.classList.remove('show-dropzone');
        document.querySelector('.hover-over')?.classList.remove('hover-over');
        document.querySelector('.dir-up')?.classList.remove('dir-up');
        document.querySelector('.dir-down')?.classList.remove('dir-down');
        setIsRowDragging(false);
      }, 500);
    };
    window.addEventListener('mouseup', removeClasses);
    return () => {
      window.removeEventListener('mouseup', removeClasses);
    };
  }, [setIsRowDragging]);

  const enableCellTextSelection = useFlag('grid-text-selection');

  return (
    <div id="inventory-grid" className="ag-theme-alpine">
      <ErrorBoundary
        FallbackComponent={() => <div>Error</div>}
        resetKeys={[error]}
        onError={() => {
          if (!import.meta.env.DEV) {
            setError(error + 1);
          }
        }}
      >
        <AgGridReact
          ref={gridRef}
          key={error}
          // ensureDomOrder // This could be useful when debugging
          // valueCache
          rowData={mergedEventListings}
          onRowDataUpdated={onRowDataUpdated}
          suppressScrollOnNewData
          suppressContextMenu
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          rowSelection="single"
          onRowDragEnd={onMasterRowDragEnd}
          onRowGroupOpened={onRowGroupToggled}
          onRowDragMove={onMasterRowDragMove}
          postSortRows={(params) => {
            const index = params.nodes.findIndex((x) => x.data?.listingId.endsWith('sales'));
            if (index > -1) {
              const node = params.nodes.splice(index, 1)[0];
              params.nodes.push(node);
            }
          }}
          onFirstDataRendered={() => animationFlag && setIsAnimationEnabled(true)}
          onRowSelected={(params) => {
            const { rowIndex } = params;
            const el = document.querySelector(`[row-index="${rowIndex}"]`);
            if (params.data) {
              el?.setAttribute('aria-label', `Listing ${params?.data.listingId} row`);
            }
          }}
          detailRowAutoHeight
          rowBuffer={50}
          getRowId={(params) => params.data?.tenantIdListingId}
          masterDetail
          initialGroupOrderComparator={groupSortOrderComparator}
          groupDisplayType="groupRows"
          groupDefaultExpanded={1}
          onCellClicked={onCellClickedWhenDirty}
          detailCellRendererParams={isShowingEventPerformance ? soldInventoryDetailCellRendererParams : detailCellRendererParams}
          onColumnMoved={trySaveGridState}
          onColumnResized={trySaveGridState}
          onSortChanged={trySaveGridState}
          onColumnVisible={trySaveGridState}
          defaultColDef={defaultColDef}
          suppressHorizontalScroll={false}
          suppressRowClickSelection
          animateRows={isAnimationEnabled}
          getRowClass={(params) => {
            if (isAnimationEnabled && params.data?.meta?.newRow) {
              return 'ag-row-created';
            }
            if (params.node.detail && params.data && params.data.listingId.endsWith('sales')) {
              return 'ag-sales-container';
            }
            if (appearanceSettings && appearanceSettings.highlightListingsOnHold && params.data && params.data.quantityReserved > 0 && !params.node.detail) {
              document.querySelectorAll(`#inventory-grid [row-id="${params.data?.tenantId}|${params.data?.listingId}"]`).forEach((x) => x.classList.add('ag-row-listing-on-hold'));
              return 'ag-row-listing-on-hold';
            }
            if (appearanceSettings && appearanceSettings.highlightListingsAtFloor && params.data && params.data.pricerStatusId === 'AtFloor' && !params.node.detail) {
              document.querySelectorAll(`#inventory-grid [row-id="${params.data?.tenantId}|${params.data?.listingId}"]`).forEach((x) => x.classList.add('ag-row-listing-at-floor'));
              return 'ag-row-listing-at-floor';
            }
            if (!params.node.detail) {
              document.querySelectorAll(`#inventory-grid div[row-id="${params.data?.tenantId}|${params.data?.listingId}"].ag-row-listing-at-floor`).forEach((x) => {
                x.classList.remove('ag-row-listing-at-floor');
                x.classList.remove('ag-row-listing-on-hold');
              });
            }
            return '';
          }}
          enableCellChangeFlash
          enableCellTextSelection={enableCellTextSelection}
          suppressMoveWhenRowDragging
          headerHeight={32}
          isRowMaster={(data) => (!!data.ruleId && data.ruleCount && data.ruleCount > 1) || data.listingId.endsWith('sales') || false}
          getRowHeight={(params) => (params.node.group ? 56 : params.node?.data?.listingId.endsWith('sales') ? 1 : 36)}
          rowStyle={{
            lineHeight: '28px',
            fontSize: '12px',
          }}
          groupRowRenderer={(params: GroupCellRendererParams<BarkerCoreModelsInventoryListing>) => {
            const [tenantId, eventId] = params.node.key!.split('|');
            return <GroupHeader tenantId={tenantId} eventId={eventId} />;
          }}
          noRowsOverlayComponent={CustomNoRowsOverlay}
          loadingOverlayComponent={CustomLoadingOverlay}
          rowDragText={(params) => {
            const sectionName = params.rowNode?.data?.section;
            const rowName = params.rowNode?.data?.row;
            const seatsFrom = params.rowNode?.data?.seatFrom;
            const seatsTo = params.rowNode?.data?.seatThru;
            return `Section ${sectionName} · Row ${rowName} · Seats ${seatsFrom}-${seatsTo}`;
          }}
          tooltipShowDelay={1000}
        />
      </ErrorBoundary>
    </div>
  );
}
