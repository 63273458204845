import { Button, MantineSize, Text } from '@mantine/core';
import { memo, SyntheticEvent, useCallback } from 'react';
import { BNButton } from '../../../components/Button/Button';
import classes from './Filters.SplitsButtonGroup.styles.tsx.module.css';

export const SplitsButtonGroupVariable = ({
  splits = [],
  onChange,
  disabled,
  size,
  count,
  onClick,
}: {
  splits: number[] | null | undefined;
  onChange: (splits: number[]) => void;
  onClick?: (event: SyntheticEvent) => void;
  disabled?: boolean;
  size?: MantineSize | undefined;
  count: number;
}) => {
  const onSplitButtonClick = useCallback(
    (value: number) => {
      if (splits?.includes(value)) {
        onChange(splits.filter((split) => split !== value));
      } else {
        onChange([...(splits || []), value]);
      }
    },
    [onChange, splits],
  );

  interface SplitsButtonProps {
    label: string;
    value: number;
    active: boolean;
    onClick: (value: number) => void;
    isDisabled?: boolean;
    bsize?: MantineSize | undefined;
  }
  const SplitsButton = memo(({ label, value, active, onClick: onInnerClick, isDisabled, bsize }: SplitsButtonProps) => (
    <BNButton
      disabled={isDisabled}
      size={bsize}
      miw={24}
      maw="calc(12.5% + 1px)"
      variant="default"
      className={active ? `${classes.buttons} ${classes.activeButton}` : `${classes.buttons}`}
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
        return onInnerClick(value);
      }}
    >
      <Text component="span" fz={bsize}>
        {label}
      </Text>
    </BNButton>
  ));

  const buttons = Array.from({ length: count }, (_, index) => {
    const label = (index + 1).toString();
    return (
      <SplitsButton bsize={size} isDisabled={disabled} key={index} label={label} value={index + 1} active={splits?.includes(index + 1) ?? false} onClick={onSplitButtonClick} />
    );
  });

  return <Button.Group className={count > 8 ? classes.btnGroupVariable : classes.btnGroup}>{buttons}</Button.Group>;
};
