import { ActionIcon, Flex, Text } from '@mantine/core';
import mobileClasses from './Mobile.module.css';
import classes from './CompanyPicker.Mobile.module.css';
import { useDisclosure } from '@mantine/hooks';
import MenuIcon from '../components/icons/Menu';
import { CompanyPickerList } from '../hoc/NavBar/CompanyModal';
import { NavBarMobile } from '../hoc/NavBar/NavBar.Mobile';

export default function CompanyPickerMobile() {
  document.body.setAttribute('data-mobile', 'true');
  document.body.style.background = 'var(--colors-paper)';
  const [menuOpened, { open: openMenu, close: closeMenu }] = useDisclosure(false);
  return (
    <div className={mobileClasses.mobileAppWrap}>
      <NavBarMobile
        leftSide={
          <ActionIcon size={36} onClick={openMenu}>
            <MenuIcon size={24} />
          </ActionIcon>
        }
        titleArea={
          <Text size="md" fw={600}>
            Company Picker
          </Text>
        }
        menuOpened={menuOpened}
        closeMenu={closeMenu}
      />
      <Flex direction="column" className={classes.companyPickerWrapper}>
        <CompanyPickerList />
      </Flex>
    </div>
  );
}
