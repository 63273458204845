import { Box, Center, Flex } from '@mantine/core';
import classes from './Sales.Performance.module.css';
import { StatBox } from '../../components/DataDisplay/StatBox';
import PaidIcon from '../../components/icons/Paid';
import ArrowForwardIcon from '../../components/icons/ArrowForward';
import { useState } from 'react';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import SalesMarketplaceBreakdown from './Sales.MarketplaceBreakdown';
import ErrorIcon from '../../components/icons/Error';
import { useSales } from './Sales.hooks';
import { formatCurrency } from '../../utils/formatters';
import { SalesChart } from './Sales.Chart';
import cx from 'clsx';
import ReceiptIcon from '../../components/icons/Receipt';

export function SalesPerformance() {
  const [showErrorStates] = useState(false);
  const { profit, revenue, returnOnInvestment, cost, filteredSales, totalTickets, totalEvents, totalWastedTickets } = useSales(
    'profit',
    'revenue',
    'returnOnInvestment',
    'cost',
    'filteredSales',
    'totalTickets',
    'totalEvents',
    'totalWastedTickets',
  );

  const numSales = new Set(filteredSales.map((sale) => `${sale.tenantId}|${sale.saleId}`)).size;

  return (
    <Flex className={classes.performanceWrapper} p="xs" gap="xs" h={199} mih={199} mah={199}>
      <Box flex={1} bg="var(--colors-paper)" style={{ borderRadius: 3, border: '1px solid light-dark(var(--colors-gray-2),var(--colors-paper))' }} pos="relative">
        <SalesChart />
      </Box>
      <Box className={cx(classes.performanceColumn, classes.marketplaceBreakdownColumn)} maw={360} w="20%" flex={1}>
        <Flex direction="column" gap="xs" flex={1}>
          <Flex flex={1} h="100%">
            {showErrorStates ? (
              <BNEmptyState
                title="Data Error"
                titleFz={14}
                border={false}
                description="Contact support if this issue persists"
                icon={<ErrorIcon color="var(--colors-red-error)" size={28} />}
              />
            ) : (
              <Box className={classes.marketplaceBreakdownWrapper}>
                <SalesMarketplaceBreakdown />
              </Box>
            )}
          </Flex>
        </Flex>
      </Box>
      <Flex className={cx(classes.performanceColumn, classes.statBoxColumn)} maw={260} w="20%" flex={1} direction="column" h="100%">
        <Flex direction="column" gap="xs" h="100%">
          <StatBox title="Profit/Loss" statValue={formatCurrency(profit)} icon={<ProfitabilityArrow value={profit} />} />
          <StatBox title="Total Revenue" statValue={formatCurrency(revenue)} icon={<PaidIcon />} />
        </Flex>
      </Flex>
      <Box className={cx(classes.performanceColumn, classes.statBoxColumn)} maw={260} w="20%" flex={1} h="100%">
        <Flex direction="column" gap="xs" h="100%">
          <StatBox
            title="Return on Investment"
            statValue={`${returnOnInvestment !== undefined && isFinite(returnOnInvestment) ? returnOnInvestment.toFixed(2) : '--'} %`}
            icon={<ProfitabilityArrow value={profit} />}
          />
          <StatBox title="Total Cost" statValue={formatCurrency(cost)} icon={<PaidIcon />} />
        </Flex>
      </Box>
      <Box className={cx(classes.performanceColumn, classes.statBoxColumn)} maw={260} w="20%" flex={1} h="100%">
        <StatBox title="Total Sales" statValue={numSales.toLocaleString()} isLoading={false} icon={<ReceiptIcon />}>
          <StatBox.StatLine left="Tickets Sold" right={totalTickets.toLocaleString()} />
          <StatBox.StatLine left="Events with a Sale" right={totalEvents.toLocaleString()} />
          <StatBox.StatLine left="Wasted Tickets" right={totalWastedTickets.toLocaleString()} />
        </StatBox>
      </Box>
    </Flex>
  );
}

function ProfitabilityArrow({ value }: { value?: number }) {
  if (value === undefined || value === 0) {
    return (
      <Center>
        <ArrowForwardIcon />
      </Center>
    );
  }

  if (value > 0) {
    return (
      <Center bg="var(--colors-successButton)" pos="absolute" inset={0} style={{ overflow: 'hidden', borderRadius: '50%', transform: 'rotate(-45deg)' }}>
        <ArrowForwardIcon color="#ffffff" />
      </Center>
    );
  }

  return (
    <Center bg="var(--colors-red-error)" pos="absolute" inset={0} style={{ overflow: 'hidden', borderRadius: '50%', transform: 'rotate(45deg)' }}>
      <ArrowForwardIcon color="#ffffff" />
    </Center>
  );
}
