import { Badge, Box, Center, Collapse, Group, Indicator, List, Popover, Stack, Text, Tooltip } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import PriceChangeIcon from '../../components/icons/PriceChange';
import SelectArrowsIcon from '../../components/icons/SelectArrows';
import AutoPriceIcon from '../../components/icons/AutoPrice';
import { useInventoryHeader } from './Inventory.Header.hooks';
import { useBulkState } from '../../data/BulkState';
import { useToggle } from '@mantine/hooks';
import { BNGridCheckbox } from '../../components/GridCheckbox/GridCheckbox';
import { Accordion_Edit } from './MultiSelectActionBar/Accordion_Edit';
import { EditPricing } from './MultiSelectActionBar/EditPricing';
import classes from './Inventory.MultiSelectActionbar.styles.tsx.module.css';
import { Grouping } from './MultiSelectActionBar/Grouping';
import DiscoverTuneIcon from '../../components/icons/DiscoverTune';
import VerticalAlignBottomIcon from '../../components/icons/VerticalAlignBottom';
import VerticalAlignTopIcon from '../../components/icons/VerticalAlignTop';
import pluralize from 'pluralize';
import { Admin } from './MultiSelectActionBar/Admin';
import { useFlag } from '@unleash/proxy-client-react';
import CompareArrowsIcon from '../../components/icons/CompareArrows';
import { BNAccordionMenu } from '../../components/Accordion/BNAccordion';
import { useGlobalState } from '../../data/GlobalState';
import { Merge } from './MultiSelectActionBar/Merge';
import { ArrowsJoin } from 'tabler-icons-react';
import WifiIcon from '../../components/icons/Wifi';
import { useState } from 'react';
import WifiOffIcon from '../../components/icons/WifiOff';
import InventoryIcon from '../../components/icons/Inventory';
import { isDtiHosted } from '../../utils/whitelabel-consts';
import EditNoteIcon from '../../components/icons/EditNote';
import { SharingMenuPanel } from '../DTI/Sharing/SharingMenuPanel';
import { EditProperties } from './MultiSelectActionBar/EditProperties';
import SplitscreenIcon from '../../components/icons/Splitscreen';
import { SplitsButtonGroupVariable } from '../SeatingChart/Filters/Filters.SplitsButtonGroupVariable';
import { BNSelect } from '../../components/Select/Select';

function MultiSelectActionBar() {
  const { currentUser, needsFeatureHint, markFeatureSeen } = useGlobalState('currentUser', 'needsFeatureHint', 'markFeatureSeen');
  const { pendingInlineChanges, showBulkOptions, mergedEventListings, markedListings, toggleBulkSelectAll, selectedListingCount } = useInventoryHeader(
    'pendingInlineChanges',
    'showBulkOptions',
    'mergedEventListings',
    'markedListings',
    'toggleBulkSelectAll',
    'selectedListingCount',
  );
  const {
    isAutoPricingEnabled,
    isPricingEnabled,
    disableAutoPricing,
    onPricingCloseResetPending,
    editAutoPricerAdjustments,
    editAutoPricerCeiling,
    editAutoPricerFloor,
    isMultipleMarketplacesSelected,
    editComparables,
    isMergeEnabled,
    editBroadcasting,
    isBulkActionLoading,
    splitOptions,
    editSplits,
    distinctTenantPointsOfSale,
  } = useBulkState(
    'isAutoPricingEnabled',
    'isPricingEnabled',
    'disableAutoPricing',
    'onPricingCloseResetPending',
    'editAutoPricerAdjustments',
    'editAutoPricerCeiling',
    'editAutoPricerFloor',
    'isMultipleMarketplacesSelected',
    'editComparables',
    'isMergeEnabled',
    'editBroadcasting',
    'isBulkActionLoading',
    'splitOptions',
    'editSplits',
    'distinctTenantPointsOfSale',
  );
  const [isPriceDialogOpen, toggleNewPriceDialog] = useToggle();
  const [isAutoPriceDialogOpen, toggleAutoPriceDialog] = useToggle();
  const [isInventoryDialogOpen, toggleInventoryDialog] = useToggle();
  const [inventoryMenuValue, setInventoryMenuValue] = useState<string | null>(null);
  const removeRuleFlag = useFlag('admin-remove-rule');
  const needsBulkSplitHint = needsFeatureHint('bulk-splits');
  const bulkEditInventoryFlag = useFlag('dti-bulk-edit-inventory') && isDtiHosted;
  const hasBulkSplitsFlag = useFlag('bulk-splits');

  const [splitValue, setSplitValue] = useState<string>();
  const [customSplits, setCustomSplits] = useState<number[]>([]);

  const splitsDisabledText = currentUser?.roleId === 'ReadOnlyUser' ? 'Insufficient permissions' : splitOptions.length === 0 ? 'Multiple points of sale selected' : undefined;

  return (
    <Collapse in={showBulkOptions} transitionDuration={0} animateOpacity={false}>
      <Group
        className={classes.bulkButtonsWrapper}
        bg="var(--colors-selectedBg)"
        wrap="nowrap"
        h={40}
        justify="space-between"
        pl={28}
        pr="md"
        style={{ borderBottom: '1px solid var(--colors-divider)' }}
      >
        <Group gap={6} wrap="nowrap" className={classes.bulkSelectionWrapper}>
          <BNGridCheckbox
            className={classes.checkbox}
            onChange={toggleBulkSelectAll}
            checked
            indeterminate={markedListings.length < mergedEventListings.length}
            size="xs"
            pl={2}
            label={
              <Text truncate>
                <strong>{selectedListingCount}</strong>{' '}
                <Box component="span" className={classes.textTrim}>
                  {pluralize('Listing', selectedListingCount)}{' '}
                </Box>
                Selected
              </Text>
            }
          />
        </Group>
        <Group className={classes.actionButtonWrapper} gap={0} wrap="nowrap" justify="right">
          <Popover
            withArrow
            offset={0}
            opened={isPriceDialogOpen}
            withinPortal={false}
            onClose={() => {
              onPricingCloseResetPending();
              toggleNewPriceDialog(false);
            }}
          >
            <Popover.Target>
              <Tooltip
                label={pendingInlineChanges ? 'Pending inline pricing must be saved first' : 'Cannot reprice auto-priced listings'}
                withArrow
                disabled={!pendingInlineChanges && (isPricingEnabled || currentUser?.roleId === 'ReadOnlyUser')}
              >
                <Box className={classes.actionButtonContainer}>
                  <BNButton
                    c="var(--colors-selectedBorder)"
                    disabled={!isPricingEnabled || pendingInlineChanges}
                    variant="subtle"
                    size="xs"
                    onClick={() => toggleNewPriceDialog()}
                    leftSection={<PriceChangeIcon />}
                    rightSection={<SelectArrowsIcon />}
                    className={classes.actionButton}
                    data-pendo-name="Bulk Pricing Button"
                  >
                    <Text fw={600} component="span" truncate>
                      Pricing
                    </Text>
                  </BNButton>
                </Box>
              </Tooltip>
            </Popover.Target>
            <Popover.Dropdown>{isPriceDialogOpen && <EditPricing toggleNewPriceDialog={toggleNewPriceDialog} />}</Popover.Dropdown>
          </Popover>
          <BNAccordionMenu
            width={332}
            opened={isAutoPriceDialogOpen}
            onClose={() => toggleAutoPriceDialog(false)}
            target={
              <Box>
                {isAutoPricingEnabled && (
                  <BNButton
                    c="var(--colors-selectedBorder)"
                    variant="subtle"
                    disabled={!isAutoPricingEnabled}
                    onClick={() => toggleAutoPriceDialog()}
                    size="xs"
                    leftSection={<AutoPriceIcon size={18} />}
                    rightSection={<SelectArrowsIcon />}
                    className={classes.actionButton}
                  >
                    <Text component="span" fw={600} truncate>
                      Auto-Pricing
                    </Text>
                  </BNButton>
                )}
                {!isAutoPricingEnabled && (
                  <Tooltip label="Cannot edit non-auto-priced listings" withArrow>
                    <Box className={classes.actionButtonContainer}>
                      <BNButton
                        c="var(--colors-selectedBorder)"
                        variant="subtle"
                        disabled={!isAutoPricingEnabled}
                        size="xs"
                        leftSection={<AutoPriceIcon size={18} />}
                        rightSection={<SelectArrowsIcon />}
                        className={classes.actionButton}
                      >
                        <Text fw={600} truncate>
                          Auto-Pricing
                        </Text>
                      </BNButton>
                    </Box>
                  </Tooltip>
                )}
              </Box>
            }
          >
            <BNAccordionMenu.Switch
              title="Auto-Price"
              leftSection={<AutoPriceIcon />}
              disabled={currentUser?.roleId === 'ReadOnlyUser'}
              checked
              onChange={() => {
                toggleAutoPriceDialog(false);
                disableAutoPricing();
              }}
            />
            <Accordion_Edit
              toggleAutoPriceDialog={toggleAutoPriceDialog}
              leftSection={<VerticalAlignBottomIcon />}
              title="Edit Floors"
              itemType="floor"
              updateFn={editAutoPricerFloor}
            />
            <Accordion_Edit
              toggleAutoPriceDialog={toggleAutoPriceDialog}
              disabled={isMultipleMarketplacesSelected}
              errorMessage={<Text size="xs">Cannot edit adjustment with multiple marketplaces selected.</Text>}
              leftSection={<DiscoverTuneIcon />}
              title="Edit Adjustments"
              itemType="adjustment"
              updateFn={editAutoPricerAdjustments}
            />
            <Accordion_Edit
              toggleAutoPriceDialog={toggleAutoPriceDialog}
              leftSection={<VerticalAlignTopIcon />}
              title="Edit Ceilings"
              itemType="ceiling"
              updateFn={editAutoPricerCeiling}
            />
            <Accordion_Edit
              toggleAutoPriceDialog={toggleAutoPriceDialog}
              leftSection={<CompareArrowsIcon />}
              title="Edit # Comparables"
              itemType="comparables"
              updateFn={editComparables}
            />
          </BNAccordionMenu>

          <Grouping />
          <BNAccordionMenu
            value={inventoryMenuValue}
            closeOnClickOutside={false}
            width={298}
            opened={isInventoryDialogOpen}
            onClose={() => toggleInventoryDialog(false)}
            target={
              <Box>
                <Indicator size={needsBulkSplitHint ? 6 : 0} offset={3} color="blue" display="flex">
                  <BNButton
                    c="var(--colors-selectedBorder)"
                    variant="subtle"
                    disabled={currentUser?.roleId === 'ReadOnlyUser'}
                    onClick={() => {
                      toggleInventoryDialog();
                    }}
                    size="xs"
                    leftSection={<InventoryIcon size={19} />}
                    rightSection={<SelectArrowsIcon />}
                    className={classes.actionButton}
                    data-pendo-name="Bulk Inventory Button"
                  >
                    <Text component="span" fw={600} truncate>
                      Inventory
                    </Text>
                  </BNButton>
                </Indicator>
              </Box>
            }
          >
            <BNAccordionMenu.Item
              closeMenuOnClick={false}
              component="div"
              classNames={{ item: classes.disableItemHover }}
              data-pendo-name="Bulk Broadcasting MenuItem"
              styles={{ item: { pointerEvents: 'none' }, itemSection: { pointerEvents: 'auto' } }}
              leftSection={<WifiIcon size={18} />}
              rightSection={
                <Group gap={4} align="center">
                  <Tooltip label={`Un-broadcast Selected ${pluralize('Listing', markedListings.length + 1)}`} withArrow>
                    <BNButton
                      size="compact-xs"
                      leftSection={<WifiOffIcon color="var(--colors-gray-5)" size={12} />}
                      fz={11}
                      c="var(--colors-paperReverse)"
                      variant="subtle"
                      onClick={async () => {
                        await editBroadcasting(false);
                        toggleInventoryDialog(false);
                      }}
                      loading={isBulkActionLoading}
                    >
                      OFF
                    </BNButton>
                  </Tooltip>{' '}
                  <Tooltip label={`Broadcast Selected ${pluralize('Listing', markedListings.length + 1)}`} withArrow>
                    <BNButton
                      size="compact-xs"
                      leftSection={<WifiIcon color="var(--colors-gray-5)" size={12} />}
                      fz={11}
                      c="var(--colors-paperReverse)"
                      variant="subtle"
                      onClick={async () => {
                        await editBroadcasting(true);
                        toggleInventoryDialog(false);
                      }}
                      loading={isBulkActionLoading}
                    >
                      ON
                    </BNButton>
                  </Tooltip>
                </Group>
              }
            >
              Broadcasting
            </BNAccordionMenu.Item>
            {hasBulkSplitsFlag && (
              <BNAccordionMenu.Panel
                id="edit-splits"
                leftSection={<SplitscreenIcon />}
                title="Splits"
                data-pendo-name="Bulk Listing Splits MenuItem"
                onClick={() => {
                  setSplitValue(undefined);
                  setCustomSplits([]);
                  markFeatureSeen('bulk-splits');
                  setInventoryMenuValue(inventoryMenuValue === 'edit-splits' ? null : 'edit-splits');
                }}
                disabledText={splitsDisabledText}
                disabled={!!splitsDisabledText}
                rightSection={
                  needsBulkSplitHint && (
                    <Badge ml={2} variant="filled" color="blue" size="xs" w={50}>
                      NEW
                    </Badge>
                  )
                }
              >
                <Group gap="xs" align="flex-end" justify="right">
                  <Stack gap="xs">
                    <BNSelect
                      m={0}
                      p={0}
                      w={236}
                      disabled={isBulkActionLoading || currentUser?.roleId === 'ReadOnlyUser'}
                      size="xs"
                      aria-label="Split Type"
                      labelProps={{ style: { whiteSpace: 'nowrap' } }}
                      data={splitOptions}
                      onChange={(value) => setSplitValue(value ?? undefined)}
                      value={splitValue}
                    />
                    {splitValue === 'CUSTOM' && (
                      <Stack gap="xs" w={236} mah={100} px="xs" py="xs" style={{ border: '1px solid var(--colors-gray-3)', borderRadius: 3, overflow: 'auto' }}>
                        <SplitsButtonGroupVariable count={8} size="xs" splits={customSplits} onChange={(splits) => setCustomSplits(splits)} />
                      </Stack>
                    )}
                    <Group gap="xs" align="flex-end" justify="right" wrap="nowrap">
                      <BNButton
                        fullWidth
                        variant="default"
                        disabled={isBulkActionLoading}
                        size="xs"
                        onClick={() => {
                          setInventoryMenuValue(null);
                        }}
                      >
                        Cancel
                      </BNButton>
                      <BNButton
                        disabled={currentUser?.roleId === 'ReadOnlyUser' || !splitValue}
                        fullWidth
                        loading={isBulkActionLoading}
                        variant="filled"
                        color="green"
                        size="xs"
                        onClick={async () => {
                          await editSplits({ splitType: splitValue ?? '', customSplits });
                          setInventoryMenuValue(null);
                        }}
                      >
                        Apply
                      </BNButton>
                    </Group>
                  </Stack>
                </Group>
              </BNAccordionMenu.Panel>
            )}
            {isDtiHosted && (
              <SharingMenuPanel
                bulk
                onClick={() => (inventoryMenuValue === 'sharing-options' ? setInventoryMenuValue(null) : setInventoryMenuValue('sharing-options'))}
                onClose={() => {
                  toggleInventoryDialog(false);
                }}
              />
            )}
            <BNAccordionMenu.Panel
              id="merge-listings"
              title="Merge Listings"
              leftSection={<ArrowsJoin size={18} />}
              data-pendo-name="Merge Listings MenuItem"
              onClick={() => (inventoryMenuValue === 'merge-listings' ? setInventoryMenuValue(null) : setInventoryMenuValue('merge-listings'))}
              disabled={!isMergeEnabled}
              disabledText={
                <Box p="xs">
                  <strong>Merge conditions not met:</strong>
                  <List size="xs" ml={0}>
                    <List.Item>All listings must belong to the same company</List.Item>
                    <List.Item>All listings must share the same event, section, and row</List.Item>
                    <List.Item>Seats must be together</List.Item>
                  </List>
                </Box>
              }
              tooltipProps={{ tooltipMaw: 240, tooltipOffset: { mainAxis: -16, crossAxis: 100 }, tooltipArrowOffset: 120 }}
            >
              <Merge toggleMergeListingDialog={() => setInventoryMenuValue(null)} />
            </BNAccordionMenu.Panel>
            {bulkEditInventoryFlag && (
              <BNAccordionMenu.Panel
                id="accordion-edit-properties"
                title="Edit Properties"
                data-pendo-name="Edit Properties MenuItem"
                disabled={distinctTenantPointsOfSale.length > 1 || !distinctTenantPointsOfSale.includes('DtiPortal')}
                disabledText="Multiple points of sale selected"
                leftSection={
                  <Center h={20} w={20} ml={-2}>
                    <EditNoteIcon size={24} />
                  </Center>
                }
                onClick={() => (inventoryMenuValue === 'accordion-edit-properties' ? setInventoryMenuValue(null) : setInventoryMenuValue('accordion-edit-properties'))}
              >
                <EditProperties
                  onClose={() => {
                    setInventoryMenuValue(null);
                    toggleInventoryDialog(false);
                  }}
                />
              </BNAccordionMenu.Panel>
            )}
          </BNAccordionMenu>
          {removeRuleFlag && <Admin />}
        </Group>
      </Group>
    </Collapse>
  );
}

export default MultiSelectActionBar;
