import { MantineProvider, localStorageColorSchemeManager } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import React from 'react';
import { theme } from './theme';

export function AppThemeWrapper(props: { children: React.ReactNode }) {
  const colorSchemeManager = localStorageColorSchemeManager({ key: 'mantine-color-scheme' });

  return (
    <MantineProvider theme={theme} colorSchemeManager={colorSchemeManager} defaultColorScheme="auto">
      <ModalsProvider>
        <Notifications position="bottom-right" zIndex={10000} />
        {props.children}
      </ModalsProvider>
    </MantineProvider>
  );
}
