import { useAtom, useAtomValue } from 'jotai';
import { selectedEventAtom, selectedMarketplaceEventAtom, showEventMappingAtom } from '../../data/atoms';
import { useCallback, useMemo } from 'react';
import { DatesRangeValue } from '@mantine/dates';
import { useForm } from '@mantine/form';
import {
  BarkerCoreEnumsMarketplace,
  BarkerCoreModelsInventoryEvent,
  BarkerCoreModelsInventoryEventMappingSuccess,
  getGetApiInventoryEventsEventIdMappingsQueryKey,
  useDeleteApiInventoryEventsEventIdMappingsMarketplaceId,
  useGetApiMarketplacesMarketplaceIdEvents,
  useGetApiMarketplacesMarketplaceIdEventsEventId,
  usePostApiInventoryEventsEventIdMappings,
} from '../../api';
import dayjs from 'dayjs';
import { parseEventIdFromUrl } from '../../utils/urls';
import yasml from '@thirtytech/yasml';
import { useDidUpdate } from '@mantine/hooks';
import { queryClient } from '../../data/api-config';

function EventMappingState({ overrideEvent }: { overrideEvent?: BarkerCoreModelsInventoryEvent | null }) {
  const [showEventMapping, setShowEventMapping] = useAtom(showEventMappingAtom);
  const selectedMarketplaceEvent = useAtomValue(selectedMarketplaceEventAtom);
  const selectedEventGlobal = useAtomValue(selectedEventAtom);
  const selectedEvent = overrideEvent || selectedEventGlobal;
  // const { refetchEventMappings } = useSeatingChart('refetchEventMappings');

  const fixedStartDate = 'T00:00:00.000';
  const fixedEndOfDate = 'T23:59:59.000';

  const searchForm = useForm<{ dateRange: DatesRangeValue; searchTerm: string }>({
    initialValues: {
      dateRange: [
        dayjs(selectedEvent?.localDateTime ?? new Date())
          .startOf('day')
          .toDate(),
        dayjs(selectedEvent?.localDateTime ?? new Date())
          .startOf('day')
          .toDate(),
      ],
      searchTerm: selectedEvent?.venue.name ?? '',
    },
    validate: {
      searchTerm: (value) => (value.length < 1 ? 'Search field is required' : null),
    },
  });

  useDidUpdate(() => {
    // Reset initial state of the form when selectedEvent changes needed for season view
    searchForm.setValues({
      dateRange: [
        dayjs(selectedEvent?.localDateTime ?? new Date())
          .startOf('day')
          .toDate(),
        dayjs(selectedEvent?.localDateTime ?? new Date())
          .startOf('day')
          .toDate(),
      ],
      searchTerm: selectedEvent?.venue.name ?? '',
    });
  }, [selectedEvent]);

  const pasteUrlForm = useForm<{ url: string }>({
    initialValues: {
      url: '',
    },
    validate: {
      url: (value) => (parseEventIdFromUrl(value) === null ? 'Invalid URL' : null),
    },
  });

  const startDate = (dayjs(searchForm.values.dateRange[0]).format('YYYY-MM-DD') + fixedStartDate) as unknown as Date;
  const endDate = (dayjs(searchForm.values.dateRange[1]).endOf('day').format('YYYY-MM-DD') + fixedEndOfDate) as unknown as Date;

  const {
    data: searchResults,
    remove: clearSearchResults,
    refetch: searchEvents,
    isFetching: isSearchLoading,
  } = useGetApiMarketplacesMarketplaceIdEvents(
    selectedMarketplaceEvent?.marketplaceId!,
    {
      query: searchForm.values.searchTerm,
      fromDate: startDate,
      toDate: endDate,
    },
    {
      query: {
        select(data) {
          return data.data;
        },
        enabled: false,
      },
      axios: {
        headers: {
          'x-tenant-id': selectedEvent?.tenantId,
        },
      },
    },
  );

  const { mutateAsync: unlinkEvent, isLoading: isUnlinkingEvent } = useDeleteApiInventoryEventsEventIdMappingsMarketplaceId({
    axios: {
      headers: {
        'x-tenant-id': selectedEvent?.tenantId,
      },
    },
  });

  const { mutateAsync: linkEvent, isLoading: isLinkingEvent } = usePostApiInventoryEventsEventIdMappings({
    axios: {
      headers: {
        'x-tenant-id': selectedEvent?.tenantId,
      },
    },
  });

  const removeEventLink = useCallback(
    async (marketplaceId: BarkerCoreEnumsMarketplace, eventId: string) => {
      await unlinkEvent({ marketplaceId, eventId });
      queryClient.invalidateQueries(getGetApiInventoryEventsEventIdMappingsQueryKey(eventId));
    },
    [unlinkEvent],
  );

  const updateEventLink = useCallback(
    async (marketplaceId: BarkerCoreEnumsMarketplace, eventId: string, data: BarkerCoreModelsInventoryEventMappingSuccess) => {
      await unlinkEvent({ marketplaceId, eventId });
      await linkEvent({ eventId, data });
      queryClient.invalidateQueries(getGetApiInventoryEventsEventIdMappingsQueryKey(eventId));
    },
    [unlinkEvent, linkEvent],
  );

  const isLinking = useMemo(() => isLinkingEvent || isUnlinkingEvent, [isLinkingEvent, isUnlinkingEvent]);

  const {
    data: pastedEvent,
    refetch: fetchPastedEvent,
    isFetching: isPastedEventLoading,
  } = useGetApiMarketplacesMarketplaceIdEventsEventId(selectedMarketplaceEvent?.marketplaceId!, parseEventIdFromUrl(pasteUrlForm.values.url) ?? '', {
    query: {
      select(data) {
        return data.data;
      },
      enabled: false,
    },
    axios: {
      headers: {
        'x-tenant-id': selectedEvent?.tenantId,
      },
    },
  });

  return {
    showEventMapping,
    setShowEventMapping,
    selectedMarketplaceEvent,
    selectedEvent,
    searchForm,
    searchEvents,
    searchResults,
    isSearchLoading,
    pasteUrlForm,
    pastedEvent,
    fetchPastedEvent,
    isPastedEventLoading,
    unlinkEvent: removeEventLink,
    isUnlinkingEvent,
    linkEvent: updateEventLink,
    isLinkingEvent: isLinking,
    clearSearchResults,
  };
}

export const { Provider: EventMappingProvider, useSelector: useEventMapping } = yasml(EventMappingState);
