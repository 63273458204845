import { IconPropsBase } from './IconPropsBase';

export default function MarketplaceTicketmasterIcon({ size = 24, color = 'currentColor' }: IconPropsBase) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <path
        fill={color}
        d="M7.11,6.19h2.88l.68-3.14,4.27-1.4-.98,4.54h3.56l-.62,2.91h-3.56l-1.45,6.68c-.09.36-.18.89-.18,1.48,0,.92.62,1.48,1.51,1.48.71,0,1.42-.15,1.93-.36l-.68,3.09c-.68.15-1.45.36-2.14.36-2.7,0-4.6-1.07-4.6-4.01,0-1.19.24-2.34.5-3.56l1.13-5.16h-2.88l.62-2.91"
      />
    </svg>
  );
}
