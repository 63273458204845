import { IconPropsBase } from './IconPropsBase';

export default function NotificationsIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M4.25 18.875v-1.5h2v-7.25q0-2.05 1.263-3.613Q8.775 4.95 10.75 4.5v-.7q0-.525.363-.888.362-.362.887-.362t.887.362q.363.363.363.888v.7q1.975.45 3.238 2.012 1.262 1.563 1.262 3.613v7.25h2v1.5Zm7.75-7.25ZM12 21.8q-.75 0-1.275-.525Q10.2 20.75 10.2 20h3.6q0 .75-.525 1.275-.525.525-1.275.525Zm-4.25-4.425h8.5v-7.25q0-1.775-1.238-3.013Q13.775 5.875 12 5.875T8.988 7.112Q7.75 8.35 7.75 10.125Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M4.208 15.396v-1.084h1V9.104q0-1.771 1.125-3.114 1.125-1.344 2.875-1.636V3.25q0-.333.23-.562.229-.23.562-.23.333 0 .562.23.23.229.23.562v1.104q1.75.292 2.875 1.625t1.125 3.125v5.208h1v1.084ZM10 9.729Zm0 8.104q-.562 0-.948-.385-.385-.386-.385-.948h2.666q0 .562-.385.948-.386.385-.948.385Zm-3.708-3.521h7.416V9.104q0-1.562-1.073-2.635Q11.562 5.396 10 5.396q-1.562 0-2.635 1.073-1.073 1.073-1.073 2.635Z"
        />
      </svg>
    );
  }
}
