import { useState, useEffect, useRef } from 'react';

export function useHoverKeystrokes(keyToTrack: string) {
  const [isKeyPressed, setIsKeyPressed] = useState<boolean>(false);
  const isKeyPressedRef = useRef<boolean>(false);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === keyToTrack) {
        isKeyPressedRef.current = true;
        setIsKeyPressed(true);
      }
    }

    function handleKeyUp(event: KeyboardEvent) {
      if (event.key === keyToTrack) {
        isKeyPressedRef.current = false;
        setIsKeyPressed(false);
      }
    }

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [, keyToTrack]);

  return { isKeyPressed, isKeyPressedRef };
}
