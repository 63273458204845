import { IconPropsBase } from './IconPropsBase';

export default function DemographyIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M18 18.05q.625 0 1.062-.437.438-.438.438-1.063t-.438-1.063q-.437-.437-1.062-.437t-1.062.437q-.438.438-.438 1.063t.438 1.063q.437.437 1.062.437Zm0 3q.75 0 1.4-.35.65-.35 1.075-.975-.575-.35-1.2-.513-.625-.162-1.275-.162-.65 0-1.275.162-.625.163-1.2.513.425.625 1.075.975.65.35 1.4.35ZM5.3 20.5q-.75 0-1.275-.525Q3.5 19.45 3.5 18.7V5.3q0-.75.525-1.275Q4.55 3.5 5.3 3.5h13.4q.75 0 1.275.525.525.525.525 1.275v6.325q-.375-.15-.737-.262Q19.4 11.25 19 11.2V5.3q0-.1-.1-.2t-.2-.1H5.3q-.1 0-.2.1t-.1.2v13.4q0 .1.1.2t.2.1h5.875q.05.425.163.788.112.362.262.712ZM5 18v1V5v6.2-.075V18Zm2.25-1.375h4.025q.05-.375.188-.75.137-.375.287-.75h-4.5Zm0-3.875h6.35q.625-.55 1.363-.925.737-.375 1.587-.525v-.05h-9.3Zm0-3.875h9.5v-1.5h-9.5ZM18 22.55q-1.875 0-3.188-1.312Q13.5 19.925 13.5 18.05q0-1.875 1.312-3.187Q16.125 13.55 18 13.55q1.875 0 3.188 1.313Q22.5 16.175 22.5 18.05q0 1.875-1.312 3.188Q19.875 22.55 18 22.55Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M15 15.042q.625 0 1.062-.438.438-.437.438-1.062t-.438-1.063q-.437-.437-1.062-.437t-1.062.437q-.438.438-.438 1.063t.438 1.062q.437.438 1.062.438Zm0 3q.792 0 1.469-.396t1.093-1.063q-.562-.395-1.218-.593-.656-.198-1.344-.198-.688 0-1.344.198-.656.198-1.218.593.416.667 1.093 1.063.677.396 1.469.396ZM4.75 16.583q-.542 0-.938-.395-.395-.396-.395-.938V4.75q0-.542.395-.937.396-.396.938-.396h10.5q.542 0 .938.396.395.395.395.937v5.062q-.271-.062-.531-.104-.26-.041-.552-.062V4.75q0-.083-.083-.167-.084-.083-.167-.083H4.75q-.083 0-.167.083-.083.084-.083.167v10.5q0 .083.083.167.084.083.167.083h4.875q.021.292.063.552.041.26.104.531ZM4.5 15v.5-11 5.146V9.604 15Zm1.708-1.312H9.75q.062-.271.167-.542.104-.271.229-.542H6.208Zm0-3.146h5.709q.395-.25.812-.448.417-.198.896-.302v-.334H6.208Zm0-3.146h7.584V6.312H6.208ZM15 18.625q-1.5 0-2.542-1.042-1.041-1.041-1.041-2.541t1.052-2.542q1.052-1.042 2.531-1.042 1.5 0 2.542 1.052 1.041 1.052 1.041 2.552 0 1.48-1.052 2.521-1.052 1.042-2.531 1.042Z"
        />
      </svg>
    );
  }
}
