import { ActionIcon, Indicator, Popover, Tooltip } from '@mantine/core';
import NotificationsIcon from '../../components/icons/Notifications';
import { NotificationsList } from './NotificationsList';
import { useNotifications } from './Notifications.hooks';
import pluralize from 'pluralize';

export function NotificationMenu({ onOpen, onClose }: { onOpen?: () => void; onClose?: () => void }) {
  const { notificationCount, isMenuOpened, toggleMenu, refetchAllNotifications, refetchInboxNotifications } = useNotifications(
    'notificationCount',
    'isMenuOpened',
    'toggleMenu',
    'refetchAllNotifications',
    'refetchInboxNotifications',
  );

  return (
    <Popover
      withArrow
      width={380}
      opened={isMenuOpened}
      closeOnClickOutside
      onChange={toggleMenu}
      onOpen={() => {
        refetchAllNotifications();
        refetchInboxNotifications();
        onOpen && onOpen();
      }}
      onClose={() => {
        onClose && onClose();
      }}
    >
      <Popover.Target>
        {notificationCount ? (
          <Indicator size={10} offset={3} color="var(--colors-red-error)" display="flex">
            <Tooltip label={`${notificationCount} new ${pluralize('notification', notificationCount)}`} position="left" withArrow>
              <ActionIcon onClick={toggleMenu} data-pendo-name="Notifications Menu">
                <NotificationsIcon size={24} />
              </ActionIcon>
            </Tooltip>
          </Indicator>
        ) : (
          <Tooltip label="Notifications" position="left" withArrow>
            <ActionIcon onClick={toggleMenu}>
              <NotificationsIcon size={24} />
            </ActionIcon>
          </Tooltip>
        )}
      </Popover.Target>
      <Popover.Dropdown p={0}>
        <NotificationsList />
      </Popover.Dropdown>
    </Popover>
  );
}
