import { deepMerge, Group, HoverCard, Select, SelectProps, Text } from '@mantine/core';
import React, { forwardRef } from 'react';
import ErrorFilledIcon from '../icons/ErrorFilled';
import classes from './Select.module.css';
import cx from 'clsx';

interface BNSelectProps extends SelectProps {
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  dropdownWidth?: number;
  withinPortal?: boolean;
}

/**
 * Select box input
 */
export const BNSelect = forwardRef<HTMLInputElement, BNSelectProps>(({ dropdownWidth, withinPortal, error, label, ...props }, ref) => {
  const mergedStyles = deepMerge(props.styles ?? {}, {
    dropdown: { '--bnCustomDropdownWidth': dropdownWidth ? `${dropdownWidth}px` : null },
  });
  return (
    <Select
      {...props}
      ref={ref}
      comboboxProps={{
        withinPortal: withinPortal ?? false,
      }}
      label={
        error ? (
          label ? (
            <>
              <HoverCard
                withinPortal
                withArrow
                position="top"
                shadow="md"
                styles={{
                  arrow: {
                    borderColor: 'var(--colors-red-error)',
                  },
                  dropdown: {
                    maxWidth: 220,
                    borderColor: 'var(--colors-red-error)',
                    color: 'var(--colors-gray-7)',
                    textAlign: 'center',
                  },
                }}
              >
                <HoverCard.Target>
                  <Group p={0} gap={4} wrap="nowrap" lh="1.25rem" style={{ alignItems: 'end' }}>
                    <Text c="var(--colors-red-error)" lh="1.25rem">{label}</Text>
                    <ErrorFilledIcon color="var(--colors-red-error)" size={20} />
                  </Group>
                </HoverCard.Target>
                {typeof error === 'string' && (
                  <HoverCard.Dropdown>
                    <Text size="xs">{error}</Text>
                  </HoverCard.Dropdown>
                )}
              </HoverCard>
            </>
          ) : undefined
        ) : label ? (
          <>{label}</>
        ) : undefined
      }
      error={error ? true : false}
      className={cx([classes.selectRoot, props.className || null, error ? classes.error : null])}
      classNames={{
        dropdown: cx(dropdownWidth ? classes.bnCustomDropdownWidth : null),
      }}
      styles={mergedStyles}
      withCheckIcon={props.withCheckIcon ?? false}
      allowDeselect={props.allowDeselect ?? false}
    />
  );
});
