import { Box, Card, Divider, Group, SimpleGrid, Stack, Text, Title } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import { BNSelect } from '../../components/Select/Select';
import { BNTextInput } from '../../components/TextInput/TextInput';
import { AdminContentHeader } from '../../hoc/Admin/AdminContentHeader';

export default function Payment() {
  return (
    <>
      <AdminContentHeader title="Payment Method" backlinkTo=".." backlinkTitle="Billing Overview" />
      {/* <Group
        py={24}
        style={{
          // borderBottom: '1px solid var(--colors-gray-1)',
          justifyContent: 'space-between',
          width: '100%',
          position: 'sticky',
          top: 0,
          backgroundColor: 'var(--colors-siteBg)',
          zIndex: 100,
        }}
      >
        <div>
          <Group c="var(--colors-gray-5)" h={18} gap={0} align="center" justify="left" mt={-42} mb={16}>
            <ChevronBackIcon size={12} />
            <Link to="/org/billing">
              <Text size="xs">
                Billing Overview
              </Text>
            </Link>
          </Group>
          <Title order={3}>Payment Method</Title>
        </div>
      </Group> */}
      <Card
        p="xl"
        maw={600}
        withBorder
        radius="xs"
        mt="xl"
        style={{ borderColor: 'var(--colors-divider)', borderRadius: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      >
        <form>
          <Box p="xs" pb="lg">
            <Group justify="space-between">
              <Title order={4}>ACH Payment Information</Title>
            </Group>
            <Stack my="md">
              <SimpleGrid cols={2} spacing="xl">
                <BNTextInput size="sm" label="First Name on Account *" />
                <BNTextInput size="sm" label="Last Name on Account *" />
              </SimpleGrid>
              <SimpleGrid cols={2} spacing="xl">
                <BNTextInput size="sm" label="Bank Name *" />
                <BNSelect
                  m={0}
                  p={0}
                  size="sm"
                  label="Account Type *"
                  data={[
                    { value: '1', label: 'Personal' },
                    { value: '2', label: 'Business' },
                  ]}
                  defaultValue="1"
                />
              </SimpleGrid>
              <SimpleGrid cols={2} spacing="xl">
                <BNTextInput size="sm" label="First Name on Account *" />
                <BNTextInput size="sm" label="Last Name on Account *" />
              </SimpleGrid>
            </Stack>
          </Box>
          <Divider color="var(--colors-divider)" mx={-24} />
          <Box px="xs" pt="xl" mt="xs">
            <Title order={6}>ACH AUTHORIZATION AGREEMENT</Title>
            <Text size="xs" c="var(--colors-gray-5)">
              Rev 03/02/2023
            </Text>
            <Text component="p" size="xs">
              By clicking the button below I affirm that I have proper authority and authorize Broker Nerds to charge this bank account owned by the company or individual specified
              in the form above, starting on 03/02/2023, and every 1 month thereafter, until this subscription Is canceled, for the amount of $XX.XX for Plan.
            </Text>
            <Text component="p" size="xs">
              This payment authorization is valid, in compliance with NACHA regulations, and will Remain in effect unless I, or another authorized user, notifies Acme, Inc. of its
              cancellation by contacting email@email.com or 555.555.5555.
            </Text>
          </Box>
          <SimpleGrid cols={2} spacing="xl" px="xs" pb="xl" pt="xs" mb="xs">
            <BNTextInput size="sm" label="First Name of Authorizer *" />
            <BNTextInput size="sm" label="Last Name of Authorizer *" />
          </SimpleGrid>
          <Divider color="var(--colors-divider)" mx={-24} />
          <Group px="xs" pt="xl" justify="space-between">
            <BNButton variant="subtle" color="red" size="sm">
              Remove
            </BNButton>
            <BNButton variant="filled" size="sm" color="green">
              Save Changes
            </BNButton>
          </Group>
        </form>
      </Card>
      <Text component="p" size="xs" maw={600} color="var(--colors-gray-5)" mt="xl">
        We currently support only ACH payments. Payments are processed by Quickbooks and will appear on your account as “Quickbooks - Broker Nerds Subscription”.
      </Text>
    </>
  );
}
