import { IconPropsBase } from './IconPropsBase';

export default function RuleIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="m570.23-180-41.77-41.77 104-104-104-104 41.77-41.77 104 104 104-104L820-429.77l-104 104 104 104L778.23-180l-104-104-104 104Zm79.38-346.54L521.85-654.31l41.76-41.77 85 85 170-170 41.77 42.77-210.77 211.77ZM100-297.69v-60h340v60H100Zm0-304.62v-60h340v60H100Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="m573.23-212-36.77-36.77 93-93-93-93 36.77-36.77 93 93 93-93L796-434.77l-93 93 93 93L759.23-212l-93-93-93 93Zm64.38-322.54L515.85-656.31l36.76-36.77 85 85 169-170 37.77 36.77-206.77 206.77ZM116-305.69v-52h316v52H116Zm0-296.62v-52h316v52H116Z"
        />
      </svg>
    );
  }
}
