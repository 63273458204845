import { ActionIcon, Badge, Box, Center, Group, Menu, Text, Tooltip, UnstyledButton } from '@mantine/core';
import BlockIcon from '../../components/icons/Block';
import ConfirmationNumberIcon from '../../components/icons/ConfirmationNumber';
import MoreHorizIcon from '../../components/icons/MoreHoriz';
import LiveHelpIcon from '../../components/icons/LiveHelp';
import { FC, PropsWithChildren, useMemo } from 'react';
import { BarkerCoreEnumsDeliveryMethod, BarkerCoreEnumsListingDisclosure } from '../../api';
import VisibilityOffIcon from '../../components/icons/VisibilityOff';
import AccessibilityNewIcon from '../../components/icons/AccessibilityNew';
import AccessibleIcon from '../../components/icons/Accessible';
import classes from './MarketListingsDelivery.styles.tsx.module.css';
import { formatMarketplaceSection } from '../../utils/formatters';
import { humanizeTime } from '../../utils/human-time';
import { DateFormats } from '../../utils/globals';
import dayjs from 'dayjs';
import { useSectionHighlight } from '../../components/hooks/useSectionHighlight';
import LocalShippingIcon from '../../components/icons/LocalShipping';
import QrCodeIcon from '../../components/icons/QrCode';
import FlashOnIcon from '../../components/icons/FlashOn';
import LocationOnIcon from '../../components/icons/LocationOn';
import { useLocation } from 'react-router-dom';
import 'react-swipeable-list/dist/styles.css';
import { LeadingActions, SwipeableListItem, SwipeAction, TrailingActions, Type } from 'react-swipeable-list';
import CheckCircleIcon from '../../components/icons/CheckCircle';
import WarningIcon from '../../components/icons/Warning';
import FoamFingerIcon from '../../components/icons/FoamFinger';

interface ListingCardProps {
  listingId: string;
  qty: number;
  section: string;
  row: string;
  price: string;
  disclosures?: BarkerCoreEnumsListingDisclosure[];
  target?: boolean;
  own?: boolean;
  ignore?: boolean;
  onIgnore: (listingId: string) => void;
  onUnignore: (listingId: string) => void;
  openSupport: () => void;
  tenantName?: string;
  tenantColor?: string;
  firstSeenAt?: Date;
  showListingAge?: boolean;
  outlier: boolean;
  deliveryMethod?: BarkerCoreEnumsDeliveryMethod;
}

export function ListingCardDelivery({
  listingId,
  qty,
  section,
  row,
  target,
  own,
  price,
  ignore,
  onIgnore,
  onUnignore,
  disclosures,
  openSupport,
  tenantName,
  tenantColor,
  firstSeenAt,
  showListingAge,
  outlier,
  deliveryMethod,
}: ListingCardProps) {
  const humanizedFirstSeenAt = humanizeTime(firstSeenAt);
  const firstSeenDesc = `First Seen: ${dayjs(firstSeenAt).format(DateFormats.Standard)}`;
  const { highlightSection, unhighlightSection } = useSectionHighlight(section);
  const isMobile = useLocation().pathname.includes('/mobile');

  const Wrapper: FC<PropsWithChildren<unknown>> = useMemo(
    () =>
      !isMobile
        ? ({ children }) => (
            <Box className={classes.listingCardWrapper} onMouseOver={highlightSection} onMouseOut={unhighlightSection}>
              {children}
            </Box>
          )
        : ({ children }) => (
            <Box p={4} onMouseOver={highlightSection} onMouseOut={unhighlightSection}>
              <SwipeableListItem
                leadingActions={
                  ignore && (
                    <LeadingActions>
                      <SwipeAction onClick={() => onUnignore(listingId)}>
                        <Center mr={4}>
                          <UnstyledButton style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <CheckCircleIcon color="white" />
                            <Text c="white" fz={11} mt={-2}>
                              Enable
                            </Text>
                          </UnstyledButton>
                        </Center>
                      </SwipeAction>
                    </LeadingActions>
                  )
                }
                trailingActions={
                  !ignore && (
                    <TrailingActions>
                      <SwipeAction onClick={() => onIgnore(listingId)} destructive>
                        <Center ml={4}>
                          <UnstyledButton bg="var(--colors-red-error)" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <BlockIcon color="white" />
                            <Text c="white" fz={11} mt={-2}>
                              Ignore
                            </Text>
                          </UnstyledButton>
                        </Center>
                      </SwipeAction>
                    </TrailingActions>
                  )
                }
                blockSwipe={!isMobile}
                listType={Type.IOS}
                fullSwipe
                threshold={0.5}
              >
                {children}
              </SwipeableListItem>
            </Box>
          ),
    [highlightSection, ignore, isMobile, listingId, onIgnore, onUnignore, unhighlightSection],
  );

  return (
    <Wrapper>
      <Group wrap="nowrap" py={6} px={12} className={`market-listing-card ${classes.card}`} c={ignore || own || outlier ? 'var(--colors-gray-4)' : 'inherit'}>
        {/* if target comparable, show indicator & enable tooltip */}
        {target && (
          <Tooltip label="Target Comparable" bg="var(--colors-selectedBorder)" withArrow withinPortal>
            <Box className={classes.indicator} />
          </Tooltip>
        )}
        {/* if own listing, show indicator + badge & enable tooltip */}
        {own && (
          <>
            <Tooltip
              label={
                tenantName ? (
                  <Group wrap="nowrap" gap={8}>
                    <Text>{tenantName}</Text>
                    <Box h={13} w={13} bg={tenantColor} pos="relative" className={classes.tenantDot} />
                  </Group>
                ) : (
                  'Your Listing'
                )
              }
              bg={tenantName ? undefined : 'var(--colors-brandcolor-5)'}
              withArrow
            >
              <Box className={`${classes.indicator} ${classes.ownIndicator} ${classes.tenantIndicator}`}>
                <Box className={classes.indicatorStripe} bg={tenantColor ?? 'var(--colors-brandcolor-5)'} />
              </Box>
            </Tooltip>
            <Badge size="xs" variant="transparent" color="var(--colors-gray-5)" className={`${classes.comingSoonBadge} ${classes.ownListingBadge}`}>
              Your Listing
            </Badge>
          </>
        )}
        {ignore && (
          <Badge size="xs" variant="transparent" color="var(--colors-gray-5)" className={`${classes.comingSoonBadge} ${classes.ignoredListingBadge}`}>
            Ignored Listing
          </Badge>
        )}
        {!own && !ignore && outlier && (
          <Badge size="xs" variant="transparent" color="var(--colors-gray-5)" className={`${classes.comingSoonBadge} ${classes.outlierListingBadge}`}>
            Outlier Listing
          </Badge>
        )}
        <Group
          wrap="nowrap"
          gap="xs"
          className={showListingAge ? `${classes.sectionText} section-text section-text-small` : `${classes.sectionText} section-text`}
          justify="space-between"
          pos="relative"
        >
          <Box miw={0}>
            <Text truncate size="xs" lh={1.4}>
              {formatMarketplaceSection(section)}
            </Text>
            <Text truncate color={ignore || own || outlier ? 'gray.4' : 'gray.5'} size="xs" lh={1.4}>
              Row {row === '' ? 'GA' : row.toUpperCase()}
            </Text>
          </Box>
          <Group wrap="nowrap" gap={0} className={`${classes.marketDisclosures} market-disclosures`}>
            {disclosures && disclosures?.length > 0 && disclosures.some((d) => d === 'LimitedObstructedView') && (
              <Tooltip label="Limited View" withArrow position="top">
                <Center maw={24} mah={24}>
                  <VisibilityOffIcon size={16} />
                </Center>
              </Tooltip>
            )}
            {disclosures && disclosures?.length > 0 && disclosures.some((d) => d === 'Accessibility') && (
              <Tooltip label="Accessible Seating" withArrow>
                <Center maw={24} mah={24}>
                  <AccessibleIcon size={16} />
                </Center>
              </Tooltip>
            )}
            {disclosures && disclosures?.length > 0 && disclosures.some((d) => d === 'StandingRoomOnly') && (
              <Tooltip label="Standing Room Only" withArrow>
                <Center maw={24} mah={24}>
                  <AccessibilityNewIcon size={16} />
                </Center>
              </Tooltip>
            )}
            {disclosures && disclosures?.length > 0 && disclosures.some((d) => d === 'ResaleRestricted') && (
              <Tooltip label="Resale Restricted" withArrow>
                <Center maw={24} mah={24}>
                  <WarningIcon size={16} />
                </Center>
              </Tooltip>
            )}
            {disclosures && disclosures?.length > 0 && disclosures.some((d) => d === 'FanListing') && (
              <Tooltip label="Fan Seller" withArrow>
                <Center maw={24} mah={24}>
                  <FoamFingerIcon size={16} />
                </Center>
              </Tooltip>
            )}
          </Group>
        </Group>
        <Group className={classes.qtySection} wrap="nowrap" gap={0}>
          <Box className={classes.qtyText}>
            <Text size="xs">{qty}</Text>
          </Box>
          <Box className={ignore ? `${classes.qtyIcon} ${classes.qtyIconIgnore}` : classes.qtyIcon}>
            {/* These icon options will go away & be replaced by the delivery method icons */}
            {/*{ignore ? (*/}
            {/*  <Tooltip label="Ignored" withArrow>*/}
            {/*    <Center>*/}
            {/*      <BlockIcon color="var(--colors-gray-4)" size={16} />*/}
            {/*    </Center>*/}
            {/*  </Tooltip>*/}
            {/*) : own ? (*/}
            {/*  <Tooltip label="Your Listing" withArrow>*/}
            {/*    <Center>*/}
            {/*      <DemographyIcon color="var(--colors-iconFill)" size={16} />*/}
            {/*    </Center>*/}
            {/*  </Tooltip>*/}
            {/*) : outlier ? (*/}
            {/*  <Tooltip label="Outlier" withArrow>*/}
            {/*    <Center>*/}
            {/*      <ScatterPlotIcon color="var(--colors-gray-4)" size={16} />*/}
            {/*    </Center>*/}
            {/*  </Tooltip>*/}
            {/*) : (*/}
            {/*  <Tooltip label={`${qty} ${pluralize('Ticket', qty)}`} withArrow>*/}
            {/*    <Center>*/}
            {/*      <ConfirmationNumberIcon color="var(--colors-gray-5)" size={16} />*/}
            {/*    </Center>*/}
            {/*  </Tooltip>*/}
            {deliveryMethod === BarkerCoreEnumsDeliveryMethod.Physical ? (
              <Tooltip label="Physical Delivery" withArrow>
                <Center h={18} w={18} mx={-1}>
                  <LocalShippingIcon color={ignore || own || outlier ? 'var(--colors-gray-4)' : 'var(--colors-gray-5)'} size={16} />
                </Center>
              </Tooltip>
            ) : deliveryMethod === BarkerCoreEnumsDeliveryMethod.Electronic ? (
              <Tooltip label="Electronic Delivery" withArrow>
                <Center h={18} w={18} mx={-1}>
                  <QrCodeIcon color={ignore || own || outlier ? 'var(--colors-gray-4)' : 'var(--colors-gray-5)'} size={18} />
                </Center>
              </Tooltip>
            ) : deliveryMethod === BarkerCoreEnumsDeliveryMethod.Special ? (
              <Tooltip label="Special Delivery" withArrow>
                <Center h={18} w={18} mx={-1}>
                  <LocationOnIcon color={ignore || own || outlier ? 'var(--colors-gray-4)' : 'var(--colors-gray-5)'} size={18} />
                </Center>
              </Tooltip>
            ) : deliveryMethod === BarkerCoreEnumsDeliveryMethod.Instant ? (
              <Tooltip label="Instant Delivery" withArrow>
                <Center h={18} w={18} mx={-1}>
                  <FlashOnIcon color={ignore || own || outlier ? 'var(--colors-gray-4)' : 'var(--colors-gray-5)'} size={18} />
                </Center>
              </Tooltip>
            ) : (
              <Tooltip label="Unknown Delivery" withArrow>
                <Center h={18} w={18} mx={-1}>
                  <ConfirmationNumberIcon color={ignore || own || outlier ? 'var(--colors-gray-4)' : 'var(--colors-gray-5)'} size={18} />
                </Center>
              </Tooltip>
            )}
          </Box>
          <Box className={classes.divider}>
            <Box className={classes.dividerLine} />
          </Box>
        </Group>
        {/* price */}
        <Box className={classes.price} c={ignore || own || outlier ? 'var(--colors-gray-4)' : 'inherit'}>
          <Text size="xs" truncate>
            {price}
          </Text>
        </Box>
        {showListingAge && firstSeenAt && (
          <Group className={`${classes.listingAgeContainer} listing-age-container`} wrap="nowrap">
            <Box className={classes.firstSeenDivider}>
              <Box className={classes.dividerLine} />
            </Box>
            <Tooltip label={firstSeenDesc} withArrow>
              <Text size="xs" c="var(--colors-gray-4)" ta="center" w="3ch">
                {humanizedFirstSeenAt}
              </Text>
            </Tooltip>
          </Group>
        )}
      </Group>
      <Box className={`${classes.iconArea} icon-area`}>
        <Menu withArrow width={200}>
          <Menu.Target>
            <Box className="hoverMenu">
              <ActionIcon size={28}>
                <MoreHorizIcon size={20} color="var(--colors-gray-5)" />
              </ActionIcon>
            </Box>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              leftSection={<BlockIcon />}
              pos="relative"
              onClick={() => (ignore ? onUnignore(listingId) : onIgnore(listingId))}
              data-pendo-name="Ignore Listing MenuItem"
            >
              {ignore ? 'Remove Ignore' : 'Ignore Listing'}
            </Menu.Item>
            <Menu.Item leftSection={<LiveHelpIcon />} onClick={openSupport}>
              Request Support
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Box>
    </Wrapper>
  );
}
