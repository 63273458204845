import { IconPropsExtended } from './IconPropsExtended';

export default function AutoPriceOffIcon({ size = 20, color = 'currentColor', fill = false, weight = 'normal' }: IconPropsExtended) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <polygon fill={color} points="8.96 13.68 6.93 11.65 5.44 15.68 6.9 15.68 7.64 13.68 8.96 13.68" />
        <polygon fill={color} points="18.77 8.2 19.83 5.87 22.16 4.81 19.83 3.75 18.77 1.41 17.71 3.75 15.38 4.81 17.71 5.87 18.77 8.2" />
        <path fill={color} d="m9.35,18c-1.67,0-3.08-.58-4.25-1.75-1.17-1.17-1.75-2.58-1.75-4.25,0-1.15.3-2.17.85-3.08l-1.11-1.11c-.83,1.22-1.24,2.61-1.24,4.19,0,2.09.73,3.87,2.18,5.32s3.23,2.18,5.32,2.18c1.58,0,2.98-.41,4.19-1.24l-1.11-1.11c-.91.55-1.93.85-3.08.85Z" />
        <path fill={color} d="m19.83,18.13l-1.06-2.33-.86,1.89-2-2c.62-1.09.94-2.32.94-3.68,0-2.09-.73-3.87-2.18-5.32-1.45-1.45-3.23-2.18-5.32-2.18-1.36,0-2.59.32-3.68.94l-2.91-2.91-1.06,1.06,18.66,18.66,1.06-1.06-1.14-1.14,1.89-.86-2.33-1.06ZM9.35,6c1.67,0,3.08.58,4.25,1.75,1.17,1.17,1.75,2.58,1.75,4.25,0,.93-.21,1.76-.58,2.53l-3.08-3.08-1.55-4.2h-1.58l-.29.78-1.46-1.46c.77-.37,1.61-.58,2.53-.58Zm.18,3.29l-.26-.26.08-.24.18.5Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <polygon fill={color} points="15.81 6.33 16.59 4.62 18.3 3.84 16.59 3.06 15.81 1.34 15.03 3.06 13.31 3.84 15.03 4.62 15.81 6.33" />
        <polygon fill={color} points="7.51 11.17 5.94 9.6 4.77 12.74 5.88 12.74 6.46 11.17 7.51 11.17" />
        <path
          fill={color}
          d="m16.59,15.38l-.78-1.72-.67,1.47-2.09-2.09c.54-.9.82-1.91.82-3.04,0-1.69-.59-3.12-1.78-4.31-1.18-1.18-2.62-1.77-4.31-1.77-1.13,0-2.14.28-3.04.82l-2.65-2.65-.77.77,2.53,2.53.77.77,1.9,1.9.77.77,9.83,9.83.77-.77-1.05-1.05,1.46-.67-1.72-.78ZM7.69,7.68l.1-.29.21.61-.32-.32Zm4.56,4.56l-2.58-2.58-1.28-3.44h-1.19l-.26.71-1.38-1.38c.67-.35,1.41-.54,2.24-.54,1.39,0,2.57.49,3.54,1.46s1.46,2.15,1.46,3.54c0,.82-.2,1.56-.54,2.24Z"
        />
        <path fill={color} d="m7.79,15c-1.39,0-2.57-.49-3.54-1.46s-1.46-2.15-1.46-3.54c0-1.03.27-1.94.81-2.75l-.77-.77c-.74,1.02-1.12,2.19-1.12,3.52,0,1.69.59,3.12,1.78,4.31,1.18,1.18,2.62,1.77,4.31,1.77,1.33,0,2.5-.38,3.52-1.12l-.77-.77c-.8.54-1.72.81-2.75.81Z" />
      </svg>
    );
  }
}
