import { ActionIcon, Box, Group, Tabs } from '@mantine/core';
import classes from './NotificationsList.styles.tsx.module.css';
import { useNavigate } from 'react-router-dom';
import { BarkerCoreEnumsNotificationType } from '../../api';
import dayjs from 'dayjs';
import { NotificationItem } from '../../components/Notifications/NotificationItem';
import { CustomNoRowsOverlay } from '../../components/Notifications/CustomNoRowsOverlay';
import VerticalAlignBottomIcon from '../../components/icons/VerticalAlignBottom';
import VerticalAlignTopIcon from '../../components/icons/VerticalAlignTop';
import MobileDataOffIcon from '../../components/icons/MobileDataOff';
import DescriptionIcon from '../../components/icons/Description';
import SettingsIcon from '../../components/icons/Settings';
import { BNButton } from '../../components/Button/Button';
import { useNotifications } from './Notifications.hooks';

function parseSubject(subject: string) {
  const subjectParts = subject.split(' - ');

  if (subjectParts.length >= 2) {
    return { title: subjectParts[0], desc: subjectParts.slice(1).join(' - ') };
  }

  return { title: subject };
}

function notificationIcon(notificationTypeId: BarkerCoreEnumsNotificationType) {
  switch (notificationTypeId) {
    case 'AtFloor':
      return { msgType: 'error', icon: <VerticalAlignBottomIcon /> };
    case 'AtCeiling':
      return { msgType: 'success', icon: <VerticalAlignTopIcon /> };
    case 'NoComparables':
      return { msgType: 'warning', icon: <MobileDataOffIcon /> };
    default:
      return { msgType: undefined, icon: <DescriptionIcon /> };
  }
}

export function NotificationsList() {
  const navigate = useNavigate();
  const { closeMenu, activeTab, setActiveTab, inboxNotifications, archiveNotifications, allNotifications, handleRead, handleArchive, handleUnarchive, archivedNotifications } =
    useNotifications(
      'closeMenu',
      'activeTab',
      'setActiveTab',
      'inboxNotifications',
      'archiveNotifications',
      'allNotifications',
      'handleRead',
      'handleArchive',
      'handleUnarchive',
      'archivedNotifications',
    );

  return (
    <Tabs defaultValue="inbox" value={activeTab} className={classes.notificationTabs} onChange={setActiveTab}>
      <Group justify="space-between" px="md" wrap="nowrap" style={{ borderBottom: '1px solid var(--colors-gray-2)' }}>
        <Tabs.List>
          <Tabs.Tab value="inbox">Inbox</Tabs.Tab>
          <Tabs.Tab value="all">All</Tabs.Tab>
          <Tabs.Tab value="archived">Archived</Tabs.Tab>
        </Tabs.List>
        <Group gap={4}>
          {/*<ActionIcon>*/}
          {/*  <OpenInFullIcon size={18} />*/}
          {/*</ActionIcon>*/}
          <ActionIcon onClick={() => navigate('companies')}>
            <SettingsIcon size={18} />
          </ActionIcon>
        </Group>
      </Group>
      <Tabs.Panel value="inbox" className={classes.panelInbox}>
        {inboxNotifications && inboxNotifications.length > 0 ? (
          <>
            <Box className={classes.notificationList} mah={380}>
              {inboxNotifications
                .filter((n) => n.archivedAt === null)
                .map((notification) => (
                  <NotificationItem
                    key={notification.notificationId}
                    category="inbox"
                    {...parseSubject(notification.subject)}
                    timestamp={dayjs(notification.createdAt).fromNow()}
                    {...notificationIcon(notification.typeId)}
                    actionLabel={notification.action?.label}
                    actionUrl={notification.action?.url}
                    isRead={!!notification.readAt}
                    onRead={() => handleRead(notification)}
                    onArchive={() => handleArchive(notification)}
                    onUnarchive={() => handleUnarchive(notification)}
                    onCloseMenu={closeMenu}
                  />
                ))}
            </Box>
            <Group wrap="nowrap" p="md" gap="xs" style={{ borderTop: '1px solid var(--colors-gray-2)' }}>
              <BNButton variant="default" size="xs" fullWidth onClick={() => archiveNotifications(inboxNotifications.filter((n) => n.readAt !== null))}>
                Archive Read
              </BNButton>
              <BNButton variant="default" size="xs" fullWidth onClick={() => archiveNotifications(inboxNotifications)}>
                Archive All
              </BNButton>
            </Group>
          </>
        ) : (
          <CustomNoRowsOverlay />
        )}
      </Tabs.Panel>
      <Tabs.Panel value="all" className={classes.panelAll}>
        <Box className={classes.notificationList} mah={380}>
          {allNotifications && allNotifications.length > 0 ? (
            allNotifications.map((notification) => (
              <NotificationItem
                key={notification.notificationId}
                category="all"
                {...parseSubject(notification.subject)}
                timestamp={dayjs(notification.createdAt).fromNow()}
                {...notificationIcon(notification.typeId)}
                actionLabel={notification.action?.label}
                actionUrl={notification.action?.url}
                isRead={!!notification.readAt}
                onRead={() => handleRead(notification)}
                onArchive={() => handleArchive(notification)}
                onUnarchive={() => handleUnarchive(notification)}
                onCloseMenu={closeMenu}
              />
            ))
          ) : (
            <CustomNoRowsOverlay />
          )}
        </Box>
      </Tabs.Panel>
      <Tabs.Panel value="archived" className={classes.panelArchived}>
        <Box className={classes.notificationList} mah={380}>
          {archivedNotifications && archivedNotifications.length > 0 ? (
            archivedNotifications.map((notification) => (
              <NotificationItem
                key={notification.notificationId}
                category="archived"
                {...parseSubject(notification.subject)}
                timestamp={dayjs(notification.createdAt).fromNow()}
                {...notificationIcon(notification.typeId)}
                actionLabel={notification.action?.label}
                actionUrl={notification.action?.url}
                isRead={!!notification.readAt}
                onRead={() => handleRead(notification)}
                onArchive={() => handleArchive(notification)}
                onUnarchive={() => handleUnarchive(notification)}
                onCloseMenu={closeMenu}
              />
            ))
          ) : (
            <CustomNoRowsOverlay />
          )}
        </Box>
      </Tabs.Panel>
    </Tabs>
  );
}
