import { BarkerCoreEnumsMarketplace } from '../api';

export function generateTicketEvolutionUrl(eventId: string | null) {
  if (!eventId) {
    return '';
  }
  return `https://core.ticketevolution.com/tickets/events/all/${eventId}/all`;
}

export function generateSeatGeekUrl(eventId: string | null) {
  if (!eventId) {
    return '';
  }

  return `https://seatgeek.com/e/events/${eventId}`;
}

export function generateTicketNetworkUrl(eventId: string | null) {
  if (!eventId) {
    return '';
  }

  return `https://www.ticketnetwork.com/tickets/${eventId}`;
}

export function generateStubHubUrl(eventId: string | null) {
  if (!eventId) {
    return '';
  }

  return `https://www.stubhub.com/event/${eventId}`;
}

export function generateVividSeatsUrl(eventId: string | null) {
  if (!eventId) {
    return '';
  }

  return `https://www.vividseats.com/production/${eventId}`;
}

export function generateTicketmasterUrl(eventId: string | null) {
  if (!eventId) {
    return '';
  }

  return `https://www.ticketmaster.com/event/${eventId}`;
}

export function parseVividSeatsUrl(url: string) {
  const regex = /vividseats\.com\/.*?production\/(\d{7})/;
  const match = url.match(regex);
  if (match && match.length > 1) {
    return match[1];
  }

  return null;
}

export function parseStubHubUrl(url: string) {
  const regex = /stubhub.*?event\/(\d{9}).*/;
  const match = url.match(regex);
  if (match && match.length > 1) {
    return match[1];
  }

  return null;
}

export function parseTicketEvolutionUrl(url: string) {
  const regex = /core\.ticketevolution\.com\/tickets\/events\/.*?\/(\d{7})\/all/;
  const match = url.match(regex);
  if (match && match.length > 1) {
    return match[1];
  }

  return null;
}

export function parseSeatGeekUrl(url: string) {
  const regex = /seatgeek\.com.*?(\d{7,8}).*/;
  const match = url.match(regex);
  if (match && match.length > 1) {
    return match[1];
  }

  return null;
}

export function parseTicketNetworkUrl(url: string) {
  const regex = /ticketnetwork\.com\/tickets\/(\d{7})/;
  const match = url.match(regex);
  if (match && match.length > 1) {
    return match[1];
  }

  return null;
}

export function parseTicketmasterUrl(url: string) {
  const regex = /(ticketmaster|livenation).*?\/event\/(\w{16})/;
  const match = url.match(regex);
  if (match && match.length > 1) {
    return match[2];
  }

  return null;
}

export function parseEventIdFromUrl(url: string) {
  const ticketEvolutionId = parseTicketEvolutionUrl(url);
  if (ticketEvolutionId) {
    return ticketEvolutionId;
  }

  const ticketNetworkId = parseTicketNetworkUrl(url);
  if (ticketNetworkId) {
    return ticketNetworkId;
  }

  const ticketmasterId = parseTicketmasterUrl(url);
  if (ticketmasterId) {
    return ticketmasterId;
  }

  return parseSeatGeekUrl(url);
}

export function generateSkyBoxInvoiceUrl(invoiceId: string) {
  return `https://skybox.vividseats.com/invoices?invoiceId=${invoiceId}`;
}

export function generateMarketplaceEventUrl(marketplaceId: BarkerCoreEnumsMarketplace, marketplaceEventId: string) {
  switch (marketplaceId) {
    case BarkerCoreEnumsMarketplace.TicketEvolution:
      return generateTicketEvolutionUrl(marketplaceEventId);
    case BarkerCoreEnumsMarketplace.SeatGeek:
      return generateSeatGeekUrl(marketplaceEventId);
    case BarkerCoreEnumsMarketplace.TicketNetwork:
      return generateTicketNetworkUrl(marketplaceEventId);
    case BarkerCoreEnumsMarketplace.StubHub:
      return generateStubHubUrl(marketplaceEventId);
    case BarkerCoreEnumsMarketplace.VividSeats:
      return generateVividSeatsUrl(marketplaceEventId);
    case BarkerCoreEnumsMarketplace.Ticketmaster:
      return generateTicketmasterUrl(marketplaceEventId);
    default:
      return 'Unable to generate URL';
  }
}
