import { Text } from '@mantine/core';
import { useDebouncedValue, useDidUpdate, useListState, useToggle } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { mergedEventListingsAtom, pendingListingUpdatesAtom, searchResultsAtom, selectedSearchEventsAtom, selectedTenantListingIdAtom } from '../../data/atoms';
import { SortItem } from './Inventory.SortItem';
import { onColumnMoved, onColumnSorted, useInventory } from './Inventory.hooks';
import yasml from '@thirtytech/yasml';
import { useBulkState } from '../../data/BulkState';
import { ColumnSettingItem, PricerView } from '../../types';
import { BarkerCoreEnumsPricerStatus } from '../../api';
import { useGlobalState } from '../../data/GlobalState';

const ALLOWED_SORTING_EVENT_COLUMNS: SortItem[] = [
  { key: 'event.localDateTime', name: 'Date', dir: 'asc' },
  { key: 'event.name', name: 'Name', dir: null },
  { key: 'event.venue.name', name: 'Venue Name', dir: null },
  { key: 'event.venue.city', name: 'City', dir: null },
  { key: 'event.openCost', name: 'Open Cost', dir: null },
  { key: 'event.openTickets', name: 'Open Tickets', dir: null },
  { key: 'event.openListings', name: 'Open Listings', dir: null },
];
ALLOWED_SORTING_EVENT_COLUMNS.sort((a, b) => a.name.localeCompare(b.name));
const InventoryHeaderState = () => {
  const {
    inventoryQuickFilter,
    setInventoryQuickFilter,
    toggleShowSort,
    gridRef,
    gridReady,
    clearInventoryGridState,
    clearEventGridState,
    updateEventSort,
    eventGridState,
    inventoryGridState,
    defaultColumnDefs,
    showBulkOptions,
    toggleBulkOptions,
    filter,
    setFilter,
    mergedEventListings,
    showPurchaseModal,
    openPurchaseModal,
  } = useInventory(
    'inventoryQuickFilter',
    'setInventoryQuickFilter',
    'toggleShowSort',
    'gridRef',
    'gridReady',
    'clearInventoryGridState',
    'clearEventGridState',
    'updateEventSort',
    'eventGridState',
    'inventoryGridState',
    'defaultColumnDefs',
    'showBulkOptions',
    'toggleBulkOptions',
    'filter',
    'setFilter',
    'mergedEventListings',
    'showPurchaseModal',
    'openPurchaseModal',
  );

  const selectedEvents = useAtomValue(selectedSearchEventsAtom);
  const searchResults = useAtomValue(searchResultsAtom);
  const events = useMemo(() => searchResults?.events || [], [searchResults]);
  const selectedListingId = useAtomValue(selectedTenantListingIdAtom);
  const [showSettings, setShowSettings] = useState(false);
  const [eventSort, eventSortHandlers] = useListState<SortItem>(eventGridState || []);
  const [listingSort, listingSortHandlers] = useListState<SortItem>([]);
  const [columnSettings, columnSettingsHandlers] = useListState<ColumnSettingItem>([]);
  const [enableUpdates, toggleUpdates] = useToggle();
  const inventoryGridStateRef = useRef(inventoryGridState);
  const [columnSettingsDebounced] = useDebouncedValue(columnSettings, 250);
  const [listingSortDebounced] = useDebouncedValue(listingSort, 250);
  const { pricerView } = useGlobalState('pricerView');
  const allListings = useAtomValue(mergedEventListingsAtom);
  const pendingChanges = useAtomValue(pendingListingUpdatesAtom);

  const pendingInlineChanges = useMemo(
    () =>
      allListings
        .filter((x) => pendingChanges.map((y) => y.listingId).includes(x.listingId))
        .some((listing) => listing.pricerStatusId === BarkerCoreEnumsPricerStatus.None || listing.pricerStatusId === BarkerCoreEnumsPricerStatus.Scheduled),
    [allListings, pendingChanges],
  );

  const allowedSortingColumns = useMemo(
    () =>
      structuredClone(
        defaultColumnDefs
          .filter((x) => x.sortIndex != null || x.allowSorting !== false)
          .map((x) => ({ key: x.field || x.colId!, name: x.headerName || x.field || x.colId!, dir: x.sort, sortIndex: x.sortIndex })),
      ).sort((a, b) => {
        const aIndex = typeof a.sortIndex === 'number' ? a.sortIndex : Infinity;
        const bIndex = typeof b.sortIndex === 'number' ? b.sortIndex : Infinity;
        return aIndex - bIndex || (a.dir || '').localeCompare(b.dir || '') || a.name.localeCompare(b.name);
      }),
    [defaultColumnDefs],
  );

  const defaultColumnSettings = useMemo(
    () =>
      defaultColumnDefs
        .filter((x) => !x.exclude)
        .map(
          (x) =>
            ({
              key: x.field || x.colId!,
              name: x.headerName!,
              visible: !x.hide,
              width: x.width ?? undefined,
              pinned: x.colId === 'action' ? 'right' : undefined,
            }) satisfies ColumnSettingItem,
        ),
    [defaultColumnDefs],
  );

  useDidUpdate(() => {
    inventoryGridStateRef.current = inventoryGridState;
  }, [inventoryGridState]);

  const resetEventDefaults = useCallback(() => {
    eventSortHandlers.setState(structuredClone(ALLOWED_SORTING_EVENT_COLUMNS));
  }, [eventSortHandlers]);

  const resetListingDefaults = useCallback(() => {
    listingSortHandlers.setState(allowedSortingColumns);
  }, [allowedSortingColumns, listingSortHandlers]);

  const resetColumnDefaults = useCallback(() => {
    columnSettingsHandlers.setState(defaultColumnSettings);
  }, [defaultColumnSettings, columnSettingsHandlers]);

  const resetAllDefaults = useCallback(() => {
    openConfirmModal({
      title: 'Reset confirmation',
      children: <Text size="sm">Are you sure you want to reset all the grid settings to their defaults?</Text>,
      labels: { confirm: 'Reset', cancel: 'Cancel' },
      confirmProps: { className: 'confirmButton', variant: 'filled', color: 'gray', size: 'sm' },
      cancelProps: { className: 'cancelButton', variant: 'default', size: 'sm' },
      closeButtonProps: { size: 'md' },
      onConfirm: () => {
        clearInventoryGridState();
        clearEventGridState();
        resetColumnDefaults();
        resetListingDefaults();
        resetEventDefaults();
      },
    });
  }, [clearEventGridState, clearInventoryGridState, resetColumnDefaults, resetEventDefaults, resetListingDefaults]);

  useDidUpdate(() => {
    if (enableUpdates) {
      const updateOrder = listingSortDebounced.map((x, i) => ({ colId: x.key, sort: x.dir ?? null, sortIndex: x.dir ? i : null }));
      gridRef.current?.columnApi.applyColumnState({
        state: updateOrder,
      });
    }
  }, [listingSortDebounced]);

  useDidUpdate(() => {
    if (enableUpdates) {
      gridRef.current?.columnApi.applyColumnState({
        applyOrder: true,
        state: [{ colId: 'dragCell' }, ...columnSettingsDebounced.map((x) => ({ colId: x.key, hide: !x.visible, width: x.width, pinned: x.pinned }))],
      });
    }
  }, [columnSettingsDebounced]);

  useDidUpdate(() => {
    if (enableUpdates) {
      const updateSort = eventSort.map((x, i) => ({ ...x, sortIndex: x.dir !== null ? i : undefined })).sort((a, b) => (a.sortIndex ?? Infinity) - (b.sortIndex ?? Infinity));
      updateEventSort(updateSort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventSort]);

  const isMenuOpened = useRef(false);
  const onMenuVisibilityChange = useCallback(() => {
    isMenuOpened.current = !isMenuOpened.current;
  }, []);

  const processListingSort = useCallback(
    ({ initialRender }: { initialRender?: boolean } = { initialRender: false }) => {
      if (!gridRef.current || !gridRef.current.columnApi) return;
      const columnState = gridRef.current.columnApi.getColumnState();
      const columnSort = columnState
        .sort((x) => x.sortIndex || Infinity)
        .map((x) => {
          const columnDef = gridRef.current?.columnApi.getColumn(x.colId)?.getColDef();
          return {
            key: x.colId,
            name: columnDef?.headerName ?? columnDef?.field ?? '',
            dir: x.sort,
            sortIndex: x.sortIndex,
          } satisfies SortItem;
        });

      let finalListingSort = [...columnSort.filter((x) => allowedSortingColumns.map((y) => y.key.toLowerCase()).includes(x.key.toLowerCase()))];
      if (initialRender) {
        finalListingSort = structuredClone(
          finalListingSort.map((x) => {
            const savedState = inventoryGridStateRef.current?.find((y) => y.colId === x.key) || {};
            const { dir, sortIndex, ...rest } = allowedSortingColumns.find((y) => y.key === x.key) || {};
            return { ...x, ...rest, sort: dir, sortIndex, ...savedState };
          }),
        );
      }
      finalListingSort.sort((a, b) => {
        const aIndex = typeof a.sortIndex === 'number' ? a.sortIndex : Infinity;
        const bIndex = typeof b.sortIndex === 'number' ? b.sortIndex : Infinity;
        return aIndex - bIndex || (a.dir || '').localeCompare(b.dir || '') || a.name.localeCompare(b.name);
      });
      listingSortHandlers.setState(finalListingSort);
    },
    [allowedSortingColumns, gridRef, listingSortHandlers],
  );

  const processColumnSettings = useCallback(() => {
    if (!gridRef.current || !gridRef.current.columnApi) return;
    const ignoredColIds = defaultColumnDefs.filter((x) => x.exclude).map((x) => x.field || x.colId!);
    const columns = gridRef.current.columnApi.getColumns();
    const columnState = gridRef.current.columnApi.getColumnState()!;
    const _columnSettingItems = columnState
      .filter((col) => !ignoredColIds.includes(col.colId))
      .map((col) => {
        const columnDef = columns?.find((x) => x.getColId() === col.colId)?.getColDef();
        return {
          key: col.colId,
          name: columnDef?.headerName ?? columnDef?.field ?? '',
          visible: !col.hide,
          width: col.width,
          pinned: col.pinned,
          sortIndex: col.sortIndex || 0,
        };
      });

    columnSettingsHandlers.setState(_columnSettingItems);
  }, [columnSettingsHandlers, defaultColumnDefs, gridRef]);

  const onColumnMovedEvt = useCallback(() => {
    if (!isMenuOpened.current) {
      processColumnSettings();
    }
  }, [processColumnSettings]);

  const onColumnSortedEvt = useCallback(() => {
    if (!isMenuOpened.current) {
      processListingSort();
    }
  }, [processListingSort]);

  useEffect(() => {
    if (enableUpdates) {
      document.addEventListener(onColumnMoved.type, onColumnMovedEvt);
      document.addEventListener(onColumnSorted.type, onColumnSortedEvt);
    }
    return () => {
      document.removeEventListener(onColumnMoved.type, onColumnMovedEvt);
      document.removeEventListener(onColumnSorted.type, onColumnSortedEvt);
    };
  }, [enableUpdates, onColumnMovedEvt, onColumnSortedEvt]);

  useEffect(() => {
    if (gridReady && showSettings) {
      processListingSort({ initialRender: true });

      if (!eventGridState || !eventGridState.length) {
        eventSortHandlers.setState(structuredClone(ALLOWED_SORTING_EVENT_COLUMNS));
      }
      processColumnSettings();

      setTimeout(() => toggleUpdates(true), 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridReady, showSettings]);

  const summarizedValues = useMemo(() => {
    const totalCost = pricerView === PricerView.Split ? events.reduce((acc, event) => acc + event.openCost!, 0) : selectedEvents.reduce((acc, event) => acc + event.openCost!, 0);
    const totalTickets =
      pricerView === PricerView.Split ? events.reduce((acc, event) => acc + event.openTickets!, 0) : selectedEvents.reduce((acc, event) => acc + event.openTickets!, 0);
    const totalListings =
      pricerView === PricerView.Split ? events.reduce((acc, event) => acc + event.openListings!, 0) : selectedEvents.reduce((acc, event) => acc + event.openListings!, 0);
    return {
      totalEvents: pricerView === PricerView.Split ? events.length : selectedEvents.length,
      totalCost,
      totalListings,
      totalTickets,
    };
  }, [events, pricerView, selectedEvents]);

  const toggleSettings = useCallback(() => {
    setShowSettings(!showSettings);
    toggleShowSort();
  }, [showSettings, toggleShowSort]);

  const { markedListings, setMarkedListings } = useBulkState('markedListings', 'setMarkedListings');

  const selectedListingCount = useMemo(() => new Set([...markedListings, selectedListingId.toString()]).size, [markedListings, selectedListingId]);

  const toggleBulkSelectAll = useCallback(() => {
    if (markedListings.length === 0) {
      // Select everything
      const allListingIds = mergedEventListings.filter((x) => !x.listingId.endsWith('sales')).map((x) => x.tenantIdListingId);
      setMarkedListings(allListingIds);
    } else {
      // Clear it
      setMarkedListings([]);
    }
  }, [mergedEventListings, markedListings.length, setMarkedListings]);

  return {
    columnSettings,
    columnSettingsHandlers,
    filter,
    setFilter,
    setInventoryQuickFilter,
    inventoryQuickFilter,
    summarizedValues,
    showSettings,
    eventSort,
    eventSortHandlers,
    listingSort,
    listingSortHandlers,
    resetAllDefaults,
    resetColumnDefaults,
    resetEventDefaults,
    resetListingDefaults,
    toggleSettings,
    onMenuVisibilityChange,
    showBulkOptions,
    toggleBulkOptions,
    mergedEventListings,
    markedListings,
    toggleBulkSelectAll,
    selectedListingCount,
    isMenuOpenedRef: isMenuOpened,
    pendingInlineChanges,
    showPurchaseModal,
    openPurchaseModal,
  };
};

export const { Provider: InventoryHeaderStateProvider, useSelector: useInventoryHeader } = yasml(InventoryHeaderState);
