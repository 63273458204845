import { AgGridReact } from '@ag-grid-community/react';
import { Box, Loader } from '@mantine/core';
import { useSales } from './Sales.hooks';
import { SalesGridHeader } from './Sales.Grid.Header';
import classes from './Sales.Grid.module.css';
import cx from 'clsx';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import SearchIcon from '../../components/icons/Search';
import { useFlag } from '@unleash/proxy-client-react';

export function SalesGrid() {
  const { columnDefs, onGridReady, filteredSales, gridRef, trySaveGridState, defaultCols, isLoading } = useSales(
    'columnDefs',
    'onGridReady',
    'filteredSales',
    'gridRef',
    'trySaveGridState',
    'defaultCols',
    'isLoading',
  );

  const enableCellTextSelection = useFlag('grid-text-selection');

  return (
    <Box className={cx('ag-theme-alpine', classes['sales-grid'])} pl="xs" bg="var(--colors-gray-0)">
      <AgGridReact
        ref={gridRef}
        rowData={isLoading ? undefined : filteredSales}
        suppressScrollOnNewData
        suppressContextMenu
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        rowSelection="single"
        detailRowAutoHeight
        rowBuffer={50}
        defaultColDef={defaultCols}
        onColumnMoved={trySaveGridState}
        onColumnResized={trySaveGridState}
        onSortChanged={trySaveGridState}
        onColumnVisible={trySaveGridState}
        suppressHorizontalScroll={false}
        suppressRowClickSelection
        enableCellChangeFlash
        enableCellTextSelection={enableCellTextSelection}
        animateRows
        suppressMoveWhenRowDragging
        headerHeight={32}
        rowStyle={{
          lineHeight: '28px',
          fontSize: '13px',
        }}
        loadingOverlayComponent={CustomLoadingOverlay}
        rowHeight={36}
        noRowsOverlayComponent={CustomNoRowsOverlay}
        tooltipShowDelay={1000}
      />
    </Box>
  );
}

function CustomLoadingOverlay() {
  return (
    <div
      className="ag-overlay-loading-center"
      style={{
        borderRadius: '200px',
        border: '0',
        background: 'transparent',
        boxShadow: 'none',
        padding: '0.625rem',
        margin: '0',
      }}
    >
      <Loader color="gray" variant="dots" />
    </div>
  );
}

function CustomNoRowsOverlay() {
  return (
    <BNEmptyState
      h={200}
      w={200}
      border={false}
      title="No Results Found"
      description="Check back later or adjust the filter options"
      icon={<SearchIcon color="var(--colors-iconFill)" size={28} />}
      iconBg="var(--colors-opacity-hover)"
    />
  );
}

SalesGrid.Header = SalesGridHeader;
