import { cloneElement, isValidElement, ReactElement, ReactNode, useMemo } from 'react';
import { useGlobalState } from '../../data/GlobalState';
import { Indicator } from '@mantine/core';

type TargetWithOnClick = ReactElement<{ onClick: (event: React.MouseEvent) => void }>;

export type FeatureHintWrapperWithChildrenProps = {
  name: string;
  enabled: boolean;
  offset?: number;
  size?: number;
  children: ReactNode;
  target?: never;
};

export type FeatureHintWrapperWithTargetProps = {
  name: string;
  enabled: boolean;
  offset?: number;
  size?: number;
  children?: never;
  target: TargetWithOnClick;
};

export type FeatureHintWrapperProps = FeatureHintWrapperWithChildrenProps | FeatureHintWrapperWithTargetProps;

export function FeatureHintWrapper({ name, enabled, offset, size, children, target }: FeatureHintWrapperProps) {
  const { needsFeatureHint, markFeatureSeen } = useGlobalState('needsFeatureHint', 'markFeatureSeen');

  const needsHint = needsFeatureHint(name);

  const memoizedTarget = useMemo(() => {
    if (target && isValidElement(target)) {
      return cloneElement(target, {
        onClick: (event: React.MouseEvent) => {
          markFeatureSeen(name);
          if (typeof target.props.onClick === 'function') {
            target.props.onClick(event);
          }
        },
      });
    }
    return null;
  }, [target, markFeatureSeen, name]);

  if ((!needsHint || !enabled) && children) {
    return <>{children}</>;
  }

  if ((!needsHint || !enabled) && target) {
    return <>{target}</>;
  }

  if (children) {
    return (
      <Indicator size={size ?? 6} offset={offset ?? 0} color="blue" display="flex">
        {children}
      </Indicator>
    );
  }

  if (memoizedTarget) {
    return (
      <Indicator size={size ?? 6} offset={offset ?? 0} color="blue" display="flex">
        {memoizedTarget}
      </Indicator>
    );
  }

  return null;
}
