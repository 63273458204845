import { IconPropsBase } from './IconPropsBase';

export default function PersonPinIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M480-68.46 368.46-180H212.31Q182-180 161-201q-21-21-21-51.31v-535.38Q140-818 161-839q21-21 51.31-21h535.38Q778-860 799-839q21 21 21 51.31v535.38Q820-222 799-201q-21 21-51.31 21H591.54L480-68.46ZM200-278.31q54-53 125.5-83.5t154.5-30.5q83 0 154.5 30.5t125.5 83.5v-509.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H212.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v509.38Zm280-200.15q54.15 0 92.08-37.92Q610-554.31 610-608.46t-37.92-92.08q-37.93-37.92-92.08-37.92t-92.08 37.92Q350-662.61 350-608.46t37.92 92.08q37.93 37.92 92.08 37.92ZM257.69-240h444.62v-8.46q-47.39-42.31-103.96-63.08-56.58-20.77-118.35-20.77-61 0-117.77 20.58-56.77 20.58-104.54 62.5v9.23ZM480-538.46q-28.85 0-49.42-20.58Q410-579.61 410-608.46t20.58-49.42q20.57-20.58 49.42-20.58t49.42 20.58Q550-637.31 550-608.46t-20.58 49.42q-20.57 20.58-49.42 20.58Zm0-.69Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M480-76.46 344.46-212H228.31q-27.31-2-45.81-20.06-18.5-18.07-18.5-44.25v-503.38q0-27.01 18.65-45.66Q201.3-844 228.31-844h503.38q27.01 0 45.66 18.65Q796-806.7 796-779.69v503.38q0 26.18-18.5 44.25Q759-214 731.69-212H615.54L480-76.46ZM216-309.31q56-46 124-68.5t140-22.5q72 0 140 22t124 69v-470.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H228.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v470.38Zm264.24-145.15q55.91 0 94.84-39.17Q614-532.79 614-588.7t-39.17-94.84q-39.16-38.92-95.07-38.92t-94.84 39.17Q346-644.13 346-588.22t39.17 94.84q39.16 38.92 95.07 38.92ZM242.69-264h474.62v1.54q-51.39-42.31-111.96-64.08-60.58-21.77-125.35-21.77-64 0-124.27 21.58-60.27 21.58-113.04 63.5v-.77ZM480-506.46q-33.85 0-57.92-24.08Q398-554.61 398-588.46t24.08-57.92q24.07-24.08 57.92-24.08t57.92 24.08Q562-622.31 562-588.46t-24.08 57.92q-24.07 24.08-57.92 24.08Zm0-89.69Z"
        />
      </svg>
    );
  }
}
