import { ActionIcon, Box, Checkbox, Container, Flex, Group, Indicator, ScrollArea, Table, Text } from '@mantine/core';
import { useCallback } from 'react';
import FilterListIcon from '../../components/icons/FilterList';
import { BNTextInput } from '../../components/TextInput/TextInput';
import { useSeatingChart } from './SeatingChart.hooks';
import { BNSwitch } from '../../components/Switch/Switch';
import { OpenlistingsLabel } from '../../components/Label/OpenListingsLabel';
import { OpenticketsLabel } from '../../components/Label/OpenTicketsLabel';
import CloseIcon from '../../components/icons/Close';
import { useSectionHighlight } from '../../components/hooks/useSectionHighlight';
import { formatMarketplaceSection } from '../../utils/formatters';
import { BNButton } from '../../components/Button/Button';
import ArrowForwardIcon from '../../components/icons/ArrowForward';
import pluralize from 'pluralize';
import ReplyIcon from '../../components/icons/Reply';
import classes from './SeatingChart.module.css';
import { isMobile } from 'react-device-detect';
import { CustomLoadingOverlay } from './SeatingChart.Loading';

export function SectionChecklist() {
  const {
    rule,
    setRule,
    selectedMarketplaceEvent,
    filterQuery,
    setFilterQuery,
    filteredSections,
    allMissingSections,
    toggleForceSectionChecklist,
    allSectionsWithCounts,
    isSeatingChartError,
    filter,
    setFilter,
    showUnmapped,
    setShowUnmapped,
    allSectionsInSeatingChart,
  } = useSeatingChart(
    'rule',
    'setRule',
    'selectedMarketplaceEvent',
    'filterQuery',
    'setFilterQuery',
    'filteredSections',
    'allMissingSections',
    'toggleForceSectionChecklist',
    'allSectionsWithCounts',
    'isSeatingChartError',
    'filter',
    'setFilter',
    'showUnmapped',
    'setShowUnmapped',
    'allSectionsInSeatingChart',
  );
  const selectedSectionIds = rule.filters.sectionIds || ([] as string[]);
  const { svgDetails } = selectedMarketplaceEvent;

  const onSectionClick = useCallback(
    (sectionId: string) => {
      let formSectionIds = rule.filters.sectionIds || [];
      if (!formSectionIds.includes(sectionId)) {
        formSectionIds = [...(formSectionIds || []), sectionId];
      } else {
        formSectionIds = formSectionIds.filter((x) => x !== sectionId) || [];
      }
      setRule({ ...rule, filters: { ...rule.filters, sectionIds: formSectionIds } });
    },
    [rule, setRule],
  );

  const sectionsToConsider = !showUnmapped
    ? allSectionsWithCounts.filter((x) => filteredSections.map((y) => y.section).includes(x.section))
    : allMissingSections.filter((x) => filteredSections.map((y) => y.section).includes(x.section));

  const selectedInConsideration = sectionsToConsider.filter((x) => selectedSectionIds.includes(x.section));

  return (
    <>
      <Box className={classes.sectionChecklistWrapper}>
        <Box mt={16} mb={12}>
          <Flex direction="row" gap={50}>
            <BNTextInput
              id="section-filter-text-box"
              leftSection={
                filterQuery ? (
                  <Indicator color="var(--colors-selectedBorder)" display="flex" size={4} offset={1}>
                    <FilterListIcon size={isMobile ? 22 : 20} color="var(--colors-selectedBorder)" />
                  </Indicator>
                ) : (
                  <FilterListIcon size={isMobile ? 22 : 20} />
                )
              }
              style={{ flex: 3 }}
              label={undefined}
              size={isMobile ? 'sm' : 'xs'}
              placeholder="Filter Available Sections"
              value={filter}
              onChange={(e) => setFilter(e.currentTarget.value)}
              rightSectionWidth={isMobile ? 70 : 57}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setFilterQuery(e.currentTarget.value);
                } else if (e.key === 'Escape') {
                  setFilter(undefined);
                  setFilterQuery('');
                }
              }}
              rightSection={
                (filterQuery || filter) && (
                  <Group wrap="nowrap" gap={4} px="xs">
                    <ActionIcon
                      size={isMobile ? 'md' : 'sm'}
                      className="clearButton"
                      onClick={() => {
                        setFilter('');
                        setFilterQuery('');
                      }}
                    >
                      <CloseIcon />
                    </ActionIcon>
                    <ActionIcon variant="filled" size={isMobile ? 'md' : 'sm'} onClick={() => setFilterQuery(filter!)}>
                      <ArrowForwardIcon color="var(--colors-paper)" size={20} />
                    </ActionIcon>
                  </Group>
                )
              }
            />
            {!isSeatingChartError && (
              <BNButton variant="default" leftSection={<ReplyIcon />} size="xs" color="gray" onClick={() => toggleForceSectionChecklist()}>
                Seating Chart
              </BNButton>
            )}
          </Flex>
        </Box>
        {!isSeatingChartError && allSectionsInSeatingChart.length === 0 && <CustomLoadingOverlay />}
        {(isSeatingChartError || allSectionsInSeatingChart.length !== 0) && (
          <Container p={0} className={classes.container}>
            {/* Normal state */}
            <Box className={classes.header}>
              <Checkbox
                size="xs"
                label={`${sectionsToConsider.length} ${filterQuery ? 'Matching' : ''} ${pluralize('Section', sectionsToConsider.length)}${
                  selectedInConsideration.length > 0 ? ` (${selectedInConsideration.length} selected)` : ''
                }`}
                className={`${classes.headerCheckBox} ${classes.checkBoxStyles}`}
                checked={sectionsToConsider.every((x) => selectedSectionIds.includes(x.section))}
                onChange={() => {
                  if (sectionsToConsider.every((x) => selectedSectionIds.includes(x.section))) {
                    // setRule({ ...rule, filters: { ...rule.filters, sectionIds: selectedSectionIds.filter((x) => !filteredSections.some((y) => y.sectionId === x)) } });
                    setRule({ ...rule, filters: { ...rule.filters, sectionIds: [] } });
                  } else {
                    setRule({
                      ...rule,
                      filters: {
                        ...rule.filters,
                        sectionIds: [...selectedSectionIds.filter((x) => !sectionsToConsider.some((y) => y.section === x)), ...sectionsToConsider.map((x) => x.section)],
                      },
                    });
                  }
                }}
                mx={0}
                pl={0}
                pr={0}
              />
              {!svgDetails && (
                <Text c="var(--colors-gray-5)" size="xs" pr={12} className={classes.headerRight}>
                  (Seating Chart Unavailable)
                </Text>
              )}
              {svgDetails && (
                <Box pr={12}>
                  <BNSwitch
                    label="Only Show Unmapped"
                    labelPosition="right"
                    size="xs"
                    outlined
                    checked={showUnmapped}
                    onClick={() => {
                      setShowUnmapped(!showUnmapped);
                    }}
                    className={classes.showUnmappedSwitch}
                  />
                </Box>
              )}
            </Box>
            <ScrollArea type="scroll" className={classes.scrollArea}>
              <Table className={classes.scrollAreaTable}>
                <tbody>
                  {sectionsToConsider.map((x) => (
                    <CheckboxItem key={x.section} {...x} selectedSelectionIds={selectedSectionIds} onChange={() => onSectionClick(x.section)} />
                  ))}
                </tbody>
              </Table>
            </ScrollArea>
          </Container>
        )}
      </Box>
    </>
  );
}

function CheckboxItem({
  section,
  count,
  totalQuantity,
  onChange,
  selectedSelectionIds,
}: {
  section: string;
  count: number;
  totalQuantity: number;
  selectedSelectionIds: string[];
  onChange: () => void;
}) {
  const { highlightSection, unhighlightSection } = useSectionHighlight(section);
  return (
    <tr onMouseOver={() => highlightSection()} onMouseOut={() => unhighlightSection()}>
      <td width="75%">
        <Checkbox size="xs" className={classes.checkBoxStyles} label={formatMarketplaceSection(section)} checked={selectedSelectionIds.includes(section)} onChange={onChange} />
      </td>
      <td>
        <Box c="var(--colors-gray-5)">
          <OpenlistingsLabel hideOpen listings={count} />
        </Box>
      </td>
      <td>
        <Box c="var(--colors-gray-5)">
          <OpenticketsLabel hideOpen tickets={totalQuantity} />
        </Box>
      </td>
    </tr>
  );
}
