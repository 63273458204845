import { Box, Text, Flex, Group, Divider, Tooltip, ActionIcon, Center } from '@mantine/core';
import pluralize from 'pluralize';
import { BNButton } from '../../components/Button/Button';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import AddCircleIcon from '../../components/icons/AddCircle';
import ChevronBackIcon from '../../components/icons/ChevronBack';
import { CatalogEventCard } from './Purchase.Catalog.EventCard';
import { PurchaseForm } from './Purchase.Form';
import { usePurchaseState } from './Purchase.hooks';
import classes from './Purchase.module.css';
import { Totals } from './Purchasing.Totals';

export function Step_AddInventory() {
  const { selectedEvents, scrollableRef, form, addTicketGroup, nextStep, prevStep, isSelectEventsEnabled } = usePurchaseState(
    'selectedEvents',
    'scrollableRef',
    'form',
    'addTicketGroup',
    'nextStep',
    'prevStep',
    'isSelectEventsEnabled',
  );
  return (
    <Flex direction="column" h="100%" className={classes.stepperStep2}>
      <Flex h="100%" flex={1} style={{ overflow: 'hidden' }}>
        <Flex direction="column" bg="var(--colors-paper)" pos="relative" w={288} style={{ borderRight: '1px solid var(--colors-divider)' }}>
          {selectedEvents && selectedEvents.length > 0 ? (
            <>
              <Group h={32} pos="absolute" top={0} left={0} right={0} justify="right" px={16} py={0} style={{ borderBottom: '1px solid var(--colors-divider)' }}>
                <Group w="100%" justify="space-between">
                  {isSelectEventsEnabled && (
                    <Tooltip label="Back to Select Events" withArrow>
                      <ActionIcon size="xs" onClick={() => prevStep()} ml={-4}>
                        <Center pl={4}>
                          <ChevronBackIcon size={12} />
                        </Center>
                      </ActionIcon>
                    </Tooltip>
                  )}
                  <Text fz={11} c="gray.5" tt="uppercase">
                    {selectedEvents ? selectedEvents.length : 0} {pluralize('Event', selectedEvents ? selectedEvents.length : 0)}
                  </Text>
                </Group>
              </Group>
              <Box
                pos="absolute"
                top={32}
                left={0}
                right={0}
                bottom={0}
                style={{
                  overflowY: 'auto',
                }}
              >
                {selectedEvents.map((event) => (
                  <Box key={event.id.toString()}>
                    <CatalogEventCard event={event} />
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            <BNEmptyState border={false} title="No Events Selected" description="Select events on the previous step" icon={<AddCircleIcon size={28} />} />
          )}
        </Flex>
        <Flex h="100%" w="100%" px="sm" flex={1} justify="center" style={{ overflowY: 'auto', borderRight: '1px solid var(--colors-lightDivider)' }} ref={scrollableRef}>
          <PurchaseForm />
        </Flex>
      </Flex>
      <Flex h={52} w="100%" gap="md" px="md" bg="var(--colors-paper)" align="center" justify="space-between" style={{ borderTop: '1px solid var(--colors-divider)' }}>
        <Flex gap="md">
          <Flex w={254}>
            {isSelectEventsEnabled && (
              <BNButton size="xs" leftSection={<ChevronBackIcon size={16} />} onClick={() => prevStep()}>
                Select Events
              </BNButton>
            )}
          </Flex>
          <Divider orientation="vertical" color="var(--colors-divider)" />
          {isSelectEventsEnabled && (
            <BNButton
              size="xs"
              variant="outline"
              onClick={() => {
                addTicketGroup();
              }}
            >
              Add New Event Group
            </BNButton>
          )}
        </Flex>
        <Group>
          <Totals />
          <Divider color="var(--colors-divider)" orientation="vertical" mx={4} />
          <BNButton
            size="xs"
            w={120}
            variant="filled"
            disabled={!selectedEvents || selectedEvents.length <= 0}
            onClick={() => {
              const { hasErrors } = form.validate();
              if (!hasErrors) {
                nextStep();
              }
            }}
          >
            Continue
          </BNButton>
        </Group>
      </Flex>
    </Flex>
  );
}
