import { IconPropsBase } from './IconPropsBase';

export default function WeatherSnowyIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M260-208.462q-17.154 0-29.346-12.192T218.462-250q0-17.154 12.192-29.346T260-291.538q17.154 0 29.346 12.192T301.538-250q0 17.154-12.192 29.346T260-208.462Zm120 120q-17.154 0-29.346-12.192T338.462-130q0-17.154 12.192-29.346T380-171.538q17.154 0 29.346 12.192T421.538-130q0 17.154-12.192 29.346T380-88.462Zm120-120q-17.154 0-29.346-12.192T458.462-250q0-17.154 12.192-29.346T500-291.538q17.154 0 29.346 12.192T541.538-250q0 17.154-12.192 29.346T500-208.462Zm240 0q-17.154 0-29.346-12.192T698.462-250q0-17.154 12.192-29.346T740-291.538q17.154 0 29.346 12.192T781.538-250q0 17.154-12.192 29.346T740-208.462Zm-120 120q-17.154 0-29.346-12.192T578.462-130q0-17.154 12.192-29.346T620-171.538q17.154 0 29.346 12.192T661.538-130q0 17.154-12.192 29.346T620-88.462ZM300-370.001q-87.154 0-148.576-61.423Q90.001-492.846 90.001-580q0-80.307 53.653-139.615 53.654-59.307 133.5-69.154 30.881-56.64 84.441-88.935 53.559-32.295 118.257-32.295 88.225 0 152.417 57.307 64.192 57.308 76.269 142.923 71.308 5.231 116.384 54.116 45.077 48.884 45.077 115.769 0 70.653-49.583 120.268Q770.833-370.001 700-370.001H300Zm0-59.998h400q45.846 0 77.924-32.077 32.077-32.078 32.077-77.924 0-45.846-32.077-77.924-32.078-32.077-77.924-32.077h-49.999V-680q0-70.125-49.955-120.063t-120.105-49.938q-51.018 0-92.974 27.667-41.955 27.667-63.198 74.488l-7.363 17.845H298q-61.131 2.143-104.566 45.536T149.999-580q0 62.143 43.929 106.072T300-429.999ZM480-540Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M264.113-248.462q-16.267 0-27.959-11.58-11.692-11.579-11.692-27.845 0-16.267 11.58-27.959 11.579-11.692 27.845-11.692 16.267 0 27.959 11.58 11.692 11.579 11.692 27.845 0 16.267-11.58 27.959-11.579 11.692-27.845 11.692Zm120 144q-16.267 0-27.959-11.58-11.692-11.579-11.692-27.845 0-16.267 11.58-27.959 11.579-11.692 27.845-11.692 16.267 0 27.959 11.58 11.692 11.579 11.692 27.845 0 16.267-11.58 27.959-11.579 11.692-27.845 11.692Zm120-144q-16.267 0-27.959-11.58-11.692-11.579-11.692-27.845 0-16.267 11.58-27.959 11.579-11.692 27.845-11.692 16.267 0 27.959 11.58 11.692 11.579 11.692 27.845 0 16.267-11.58 27.959-11.579 11.692-27.845 11.692Zm240 0q-16.267 0-27.959-11.58-11.692-11.579-11.692-27.845 0-16.267 11.58-27.959 11.579-11.692 27.845-11.692 16.267 0 27.959 11.58 11.692 11.579 11.692 27.845 0 16.267-11.58 27.959-11.579 11.692-27.845 11.692Zm-120 144q-16.267 0-27.959-11.58-11.692-11.579-11.692-27.845 0-16.267 11.58-27.959 11.579-11.692 27.845-11.692 16.267 0 27.959 11.58 11.692 11.579 11.692 27.845 0 16.267-11.58 27.959-11.579 11.692-27.845 11.692ZM295-394.001q-81.154 0-135.076-58.198Q106.001-510.396 106.001-593q0-74.307 51.062-127.185t127.091-61.584q29.881-54.64 81.941-87.435 52.059-32.795 113.331-32.795 83.554 0 144.294 53.807 60.741 53.808 72.481 137.554 69.645 5.1 113.721 50.742 44.077 45.642 44.077 108.012 0 65.653-46.083 111.768Q761.833-394.001 696-394.001H295Zm2-51.998h399q44.166 0 75.084-31.577 30.917-31.578 30.917-75.424 0-43.846-30.917-75.424-30.918-31.577-75.084-31.577h-42.999V-690q0-68.125-50.455-114.063t-119.605-45.938q-57.018 0-100.974 35.167-43.955 35.167-67.198 87.988l2.637-6.155H324q-66.131 2.143-116.066 41.036T157.999-590q0 59.143 40.429 101.572T297-445.999ZM480-553Z"
        />
      </svg>
    );
  }
}
