import {
  BarkerCoreEnumsPointOfSale,
  BarkerCoreModelsInventoryDtiSplitRules,
  BarkerCoreModelsInventoryEiboSplitRules,
  BarkerCoreModelsInventoryEphemeraSplitRules,
  BarkerCoreModelsInventoryListingSplits,
  BarkerCoreModelsInventorySkyBoxSplitRules,
  BarkerCoreModelsInventoryTicketNetworkSplitRules,
  BarkerCoreModelsInventoryTradeDeskPosSplitRules,
} from '../../api';
import { PartialBarkerCoreModelsInventoryPosNextSplitRules } from '../../types';

export type SplitType = { splitType: string; customSplits: number[] };

export function getPointOfSaleSplitType(
  pointOfSale: BarkerCoreEnumsPointOfSale,
  splits?: BarkerCoreModelsInventoryListingSplits,
): {
  splitType: string;
  customSplits: number[];
} {
  if (pointOfSale === 'SkyBox') {
    const skyboxSplits = splits as BarkerCoreModelsInventorySkyBoxSplitRules;

    return { splitType: skyboxSplits ? skyboxSplits.splitType?.toUpperCase() : 'DEFAULT', customSplits: skyboxSplits?.customSplits || [] };
  }
  if (pointOfSale === 'Ephemera') {
    const ephemeraSplits = splits as BarkerCoreModelsInventoryEphemeraSplitRules;
    return { splitType: ephemeraSplits ? ephemeraSplits.splitType?.toUpperCase() : 'STANDARD', customSplits: [] };
  }
  if (pointOfSale === 'DtiPortal') {
    const dtiSplits = splits as BarkerCoreModelsInventoryDtiSplitRules;

    return {
      splitType: dtiSplits ? dtiSplits.splitType?.toUpperCase() : 'ANY',
      customSplits: [],
    };
  }
  if (pointOfSale === 'PosNext') {
    const posNextSplits = splits as PartialBarkerCoreModelsInventoryPosNextSplitRules;

    return { splitType: posNextSplits ? posNextSplits.splitRule?.id.toString() : '1', customSplits: [] };
  }
  if (pointOfSale === 'TicketNetwork' || pointOfSale === 'AnyTickets') {
    const ticketNetworkSplits = splits as Omit<BarkerCoreModelsInventoryTicketNetworkSplitRules, 'description'>;

    return { splitType: ticketNetworkSplits ? ticketNetworkSplits.id?.toString() : '1', customSplits: [] };
  }
  if (pointOfSale === 'TradeDeskPos') {
    const tradeDeskSplits = splits as BarkerCoreModelsInventoryTradeDeskPosSplitRules;

    return { splitType: tradeDeskSplits ? tradeDeskSplits.split?.toString() : '0', customSplits: [] };
  }
  if (pointOfSale === 'EIBO') {
    const eiboSplits = splits as BarkerCoreModelsInventoryEiboSplitRules;
    return { splitType: eiboSplits ? eiboSplits.splitType?.toUpperCase() : 'DEFAULT', customSplits: [] };
  }

  return { splitType: '', customSplits: [] };
}

export function getPointOfSaleSplitOptions(pointOfSale: BarkerCoreEnumsPointOfSale) {
  switch (pointOfSale) {
    case 'SkyBox':
      return [
        { value: 'DEFAULT', label: 'Default' },
        { value: 'ANY', label: 'Any' },
        { value: 'NEVERLEAVEONE', label: 'Never leave one' },
        { value: 'CUSTOM', label: 'Custom' },
      ];
    case 'Ephemera':
      return [
        { value: 'STANDARD', label: 'Standard' },
        { value: 'ANY', label: 'Any' },
        { value: 'PAIRS', label: 'Pairs' },
        { value: 'NEVERLEAVEONE', label: 'Never leave one' },
        { value: 'NONE', label: 'None' },
        { value: 'CUSTOM', label: 'Custom' },
      ];
    case 'DtiPortal':
      return [
        { value: 'ANY', label: 'Any' },
        { value: 'NONE', label: 'None' },
        { value: 'NEVERLEAVEONE', label: 'Never leave one' },
        { value: 'CUSTOM', label: 'Pairs' },
      ];
    case 'TradeDeskPos':
      return [
        { value: '-1', label: 'None' },
        { value: '0', label: 'Never leave one' },
        { value: '1', label: 'Any' },
        { value: '2', label: 'Pairs' },
        { value: '3', label: 'Three' },
        { value: '4', label: 'Four' },
        { value: '5', label: 'Five' },
      ];
    case 'PosNext':
    case 'TicketNetwork':
    case 'AnyTickets':
      return [
        { value: '1', label: 'Standard' },
        { value: '2', label: 'All' },
        { value: '3', label: 'None' },
        { value: '4', label: 'Pairs' },
        { value: '5', label: 'Three' },
        { value: '6', label: 'Four' },
        { value: '7', label: 'Five' },
        // { value: '4', label: 'Multiples of 2' },
        // { value: '5', label: 'Multiples of 3' },
        // { value: '6', label: 'Multiples of 4' },
        // { value: '7', label: 'Multiples of 5' },
      ];
    case 'EIBO':
      return [
        { value: 'DEFAULT', label: 'DEFAULT' },
        { value: 'ANYSPLITS', label: 'ANYSPLITS' },
        { value: 'NOSPLIT', label: 'NOSPLIT' },
        { value: 'NOSINGLES', label: 'NOSINGLES' },
      ];
    default:
      return [];
  }
}

export function getSplitsDescription(splits: BarkerCoreModelsInventoryListingSplits) {
  if (splits) {
    const { description } = splits as BarkerCoreModelsInventoryTicketNetworkSplitRules;
    if (description) {
      // TicketNetwork splits are Id / Description unlike many others
      // And their names are really long so I'm simplifying them
      if (description === 'Standard Split Rule') {
        return 'Standard';
      }
      if (description === 'All Splits') {
        return 'All';
      }
      if (description === 'No Splits') {
        return 'None';
      }
      if (description === 'Split Multiples of 2') {
        return 'Pairs';
      }
      if (description === 'Split Multiples of 3') {
        return 'Three';
      }
      if (description === 'Split Multiples of 4') {
        return 'Four';
      }
      if (description === 'Split Multiples of 5') {
        return 'Five';
      }
      return description.toLowerCase();
    }

    const { splitType } = splits as BarkerCoreModelsInventorySkyBoxSplitRules;
    if (splitType) {
      if (splitType.toLowerCase() === 'neverleaveone') {
        return 'Never Leave One';
      }

      // DTI Custom splits
      if (splitType === 'CUSTOM' && !('customSplits' in splits)) {
        return 'Pairs';
      }

      return splitType.toLowerCase();
    }
  }

  return '';
}

export function convertSplitsModelToPointOfSaleModel(
  pointOfSale: BarkerCoreEnumsPointOfSale,
  splits: SplitType,
):
  | BarkerCoreModelsInventorySkyBoxSplitRules
  | BarkerCoreModelsInventoryEphemeraSplitRules
  | BarkerCoreModelsInventoryDtiSplitRules
  | BarkerCoreModelsInventoryTradeDeskPosSplitRules
  | BarkerCoreModelsInventoryEiboSplitRules
  | PartialBarkerCoreModelsInventoryPosNextSplitRules
  | Omit<BarkerCoreModelsInventoryTicketNetworkSplitRules, 'description'>
  | null {
  switch (pointOfSale) {
    case 'SkyBox':
      return {
        splitType: splits.splitType,
        customSplits: splits.splitType === 'CUSTOM' ? splits.customSplits : [],
      } satisfies BarkerCoreModelsInventorySkyBoxSplitRules;
    case 'Ephemera':
      return { splitType: splits.splitType } satisfies BarkerCoreModelsInventoryEphemeraSplitRules;
    case 'DtiPortal':
      return {
        splitType:
          splits.splitType === 'CUSTOM'
            ? 'Custom'
            : splits.splitType === 'NEVERLEAVEONE'
              ? 'DontLeaveOne'
              : splits.splitType === 'NONE'
                ? 'None'
                : splits.splitType === 'ANY'
                  ? 'Any'
                  : splits.splitType,
      } satisfies BarkerCoreModelsInventoryDtiSplitRules;
    case 'PosNext':
      return { splitRule: { id: splits.splitType } } satisfies PartialBarkerCoreModelsInventoryPosNextSplitRules;
    case 'TradeDeskPos':
      return { split: parseInt(splits.splitType) } satisfies BarkerCoreModelsInventoryTradeDeskPosSplitRules;
    case 'TicketNetwork':
    case 'AnyTickets':
      return { id: parseInt(splits.splitType) } satisfies Omit<BarkerCoreModelsInventoryTicketNetworkSplitRules, 'description'>;
    case 'EIBO':
      return { splitType: splits.splitType } satisfies BarkerCoreModelsInventoryEiboSplitRules;
    default:
      return null;
  }
}

export function setSplitsModelForUpdate(pointOfSale: BarkerCoreEnumsPointOfSale, value: SplitType) {
  const update = convertSplitsModelToPointOfSaleModel(pointOfSale, value);
  if (update) {
    return { op: 'replace', path: '/splits', value: update };
  }
  return null;
}
