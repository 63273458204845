import { Center, Text } from '@mantine/core';
import WarningIcon from '../../components/icons/Warning';
import { useSeatingChart } from './SeatingChart.hooks';
import pluralize from 'pluralize';
import { BNButton } from '../../components/Button/Button';
import classes from './SeatingChart.module.css';

export function MissingSections({ count }: { count: number }) {
  const { toggleForceSectionChecklist, setShowUnmapped } = useSeatingChart('toggleForceSectionChecklist', 'setShowUnmapped');
  return (
    <BNButton
      className={classes.missingSectionsWrapper}
      variant="default"
      color="yellow"
      fw={500}
      px={0}
      onClick={() => {
        setShowUnmapped(true);
        toggleForceSectionChecklist();
      }}
    >
      <Center h={30} w={30} style={{ borderTopLeftRadius: 3, borderBottomLeftRadius: 3, color: 'var(--colors-yellow-6)' }}>
        <WarningIcon />
      </Center>
      {/* <Divider m={0} p={0} orientation="vertical" color="var(--colors-gray-1)" /> */}
      <Text className={classes.missingSectionsText} size="xs">
        {count} unmapped {pluralize('section', count)} selected
      </Text>
    </BNButton>
  );
}
