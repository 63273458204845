import { IconPropsBase } from './IconPropsBase';

export default function SelectAllIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M293.85-293.85v-372.3h372.3v372.3h-372.3Zm60-60h252.3v-252.3h-252.3v252.3ZM204.62-204.62V-140q-26.66 0-45.64-18.98T140-204.62h64.62ZM140-293.85v-64.61h64.62v64.61H140Zm0-153.84v-64.62h64.62v64.62H140Zm0-153.85v-64.61h64.62v64.61H140Zm64.62-153.84H140q0-26.66 18.98-45.64T204.62-820v64.62ZM293.85-140v-64.62h64.61V-140h-64.61Zm0-615.38V-820h64.61v64.62h-64.61ZM447.69-140v-64.62h64.62V-140h-64.62Zm0-615.38V-820h64.62v64.62h-64.62ZM601.54-140v-64.62h64.61V-140h-64.61Zm0-615.38V-820h64.61v64.62h-64.61ZM755.38-140v-64.62H820q0 26.85-18.98 45.73Q782.04-140 755.38-140Zm0-153.85v-64.61H820v64.61h-64.62Zm0-153.84v-64.62H820v64.62h-64.62Zm0-153.85v-64.61H820v64.61h-64.62Zm0-153.84V-820q26.66 0 45.64 18.98T820-755.38h-64.62Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M307.85-307.85v-344.3h344.3v344.3h-344.3Zm52-52h240.3v-240.3h-240.3v240.3ZM220.62-220.62V-164q-23.36 0-39.99-16.63Q164-197.26 164-220.62h56.62ZM164-307.85v-56.61h56.62v56.61H164Zm0-143.84v-56.62h56.62v56.62H164Zm0-143.85v-56.61h56.62v56.61H164Zm56.62-143.84H164q0-23.36 16.63-39.99Q197.26-796 220.62-796v56.62ZM307.85-164v-56.62h56.61V-164h-56.61Zm0-575.38V-796h56.61v56.62h-56.61ZM451.69-164v-56.62h56.62V-164h-56.62Zm0-575.38V-796h56.62v56.62h-56.62ZM595.54-164v-56.62h56.61V-164h-56.61Zm0-575.38V-796h56.61v56.62h-56.61ZM739.38-164v-56.62H796q0 23.85-16.63 40.23Q762.74-164 739.38-164Zm0-143.85v-56.61H796v56.61h-56.62Zm0-143.84v-56.62H796v56.62h-56.62Zm0-143.85v-56.61H796v56.61h-56.62Zm0-143.84V-796q23.36 0 39.99 16.63Q796-762.74 796-739.38h-56.62Z"
        />
      </svg>
    );
  }
}