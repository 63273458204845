import { IconPropsBase } from './IconPropsBase';

export default function DateRangeIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M8 13.95q-.375 0-.625-.262-.25-.263-.25-.638 0-.35.25-.612.25-.263.625-.263t.625.263q.25.262.25.612 0 .375-.25.638-.25.262-.625.262Zm4 0q-.375 0-.625-.262-.25-.263-.25-.638 0-.35.25-.612.25-.263.625-.263t.625.263q.25.262.25.612 0 .375-.25.638-.25.262-.625.262Zm4 0q-.375 0-.625-.262-.25-.263-.25-.638 0-.35.25-.612.25-.263.625-.263t.625.263q.25.262.25.612 0 .375-.25.638-.25.262-.625.262ZM5.3 21.5q-.75 0-1.275-.525Q3.5 20.45 3.5 19.7V6.3q0-.75.525-1.275Q4.55 4.5 5.3 4.5h1.4V2.375h1.525V4.5H15.8V2.375h1.5V4.5h1.4q.75 0 1.275.525.525.525.525 1.275v13.4q0 .75-.525 1.275-.525.525-1.275.525Zm0-1.5h13.4q.1 0 .2-.1t.1-.2v-9.4H5v9.4q0 .1.1.2t.2.1ZM5 8.8h14V6.3q0-.1-.1-.2t-.2-.1H5.3q-.1 0-.2.1t-.1.2Zm0 0V6v2.8Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M6.75 11.458q-.271 0-.458-.198-.188-.198-.188-.448 0-.27.188-.468.187-.198.458-.198t.458.187q.188.188.188.459 0 .27-.188.468-.187.198-.458.198Zm3.25 0q-.271 0-.458-.198-.188-.198-.188-.448 0-.27.188-.468.187-.198.458-.198t.458.187q.188.188.188.459 0 .27-.188.468-.187.198-.458.198Zm3.25 0q-.271 0-.458-.198-.188-.198-.188-.448 0-.27.188-.468.187-.198.458-.198t.458.187q.188.188.188.459 0 .27-.188.468-.187.198-.458.198Zm-8.5 6.125q-.562 0-.948-.395-.385-.396-.385-.938V5.75q0-.542.385-.937.386-.396.948-.396h1.833V2.312h1.105v2.105h4.645V2.312h1.084v2.105h1.833q.562 0 .948.396.385.395.385.937v10.5q0 .542-.385.938-.386.395-.948.395Zm0-1.083h10.5q.083 0 .167-.083.083-.084.083-.167v-7h-11v7q0 .083.083.167.084.083.167.083ZM4.5 8.167h11V5.75q0-.083-.083-.167-.084-.083-.167-.083H4.75q-.083 0-.167.083-.083.084-.083.167Zm0 0V5.5v2.667Z"
        />
      </svg>
    );
  }
}
