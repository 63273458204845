import { useMutation, useQuery } from '@tanstack/react-query';
import type { MutationFunction, QueryFunction, QueryKey, UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  BarkerCoreEnumsMarketplace,
  BarkerCoreModelsAdministrativeValidateIntegrationRequest,
  BarkerCoreModelsAdministrativeValidateIntegrationResponse,
  BarkerCoreModelsMarketplacesEvent,
  BarkerCoreModelsMarketplacesGetListingsResponse,
  BarkerCoreModelsMarketplacesGetSeatingChartResponse,
  BarkerCoreModelsPricingRuleFilters,
  BarkerCoreModelsWarehouseListingSnapshot,
  BarkerOrleansStateIntervalOverride,
  GetApiMarketplacesMarketplaceIdEventsEventIdListingsParams,
  GetApiMarketplacesMarketplaceIdEventsEventIdSeatingChartParams,
  GetApiMarketplacesMarketplaceIdEventsEventIdSectionAliasesParams,
  GetApiMarketplacesMarketplaceIdEventsParams,
  PutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFilteredParams,
} from './models';

export const putApiMarketplacesMarketplaceIdValidate = (
  marketplaceId: BarkerCoreEnumsMarketplace,
  barkerCoreModelsAdministrativeValidateIntegrationRequest: BarkerCoreModelsAdministrativeValidateIntegrationRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsAdministrativeValidateIntegrationResponse>> => {
  return axios.put(`/api/Marketplaces/${marketplaceId}/Validate`, barkerCoreModelsAdministrativeValidateIntegrationRequest, options);
};

export const getPutApiMarketplacesMarketplaceIdValidateMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdValidate>>,
    TError,
    { marketplaceId: BarkerCoreEnumsMarketplace; data: BarkerCoreModelsAdministrativeValidateIntegrationRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdValidate>>,
  TError,
  { marketplaceId: BarkerCoreEnumsMarketplace; data: BarkerCoreModelsAdministrativeValidateIntegrationRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdValidate>>,
    { marketplaceId: BarkerCoreEnumsMarketplace; data: BarkerCoreModelsAdministrativeValidateIntegrationRequest }
  > = (props) => {
    const { marketplaceId, data } = props ?? {};

    return putApiMarketplacesMarketplaceIdValidate(marketplaceId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiMarketplacesMarketplaceIdValidateMutationResult = NonNullable<Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdValidate>>>;
export type PutApiMarketplacesMarketplaceIdValidateMutationBody = BarkerCoreModelsAdministrativeValidateIntegrationRequest;
export type PutApiMarketplacesMarketplaceIdValidateMutationError = AxiosError<void>;

export const usePutApiMarketplacesMarketplaceIdValidate = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdValidate>>,
    TError,
    { marketplaceId: BarkerCoreEnumsMarketplace; data: BarkerCoreModelsAdministrativeValidateIntegrationRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdValidate>>,
  TError,
  { marketplaceId: BarkerCoreEnumsMarketplace; data: BarkerCoreModelsAdministrativeValidateIntegrationRequest },
  TContext
> => {
  const mutationOptions = getPutApiMarketplacesMarketplaceIdValidateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiMarketplacesMarketplaceIdEventsEventIdSeatingChart = (
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  params?: GetApiMarketplacesMarketplaceIdEventsEventIdSeatingChartParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsMarketplacesGetSeatingChartResponse>> => {
  return axios.get(`/api/Marketplaces/${marketplaceId}/Events/${eventId}/SeatingChart`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetApiMarketplacesMarketplaceIdEventsEventIdSeatingChartQueryKey = (
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  params?: GetApiMarketplacesMarketplaceIdEventsEventIdSeatingChartParams,
) => {
  return [`/api/Marketplaces/${marketplaceId}/Events/${eventId}/SeatingChart`, ...(params ? [params] : [])] as const;
};

export const getGetApiMarketplacesMarketplaceIdEventsEventIdSeatingChartQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSeatingChart>>,
  TError = AxiosError<void>,
>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  params?: GetApiMarketplacesMarketplaceIdEventsEventIdSeatingChartParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSeatingChart>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiMarketplacesMarketplaceIdEventsEventIdSeatingChartQueryKey(marketplaceId, eventId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSeatingChart>>> = ({ signal }) =>
    getApiMarketplacesMarketplaceIdEventsEventIdSeatingChart(marketplaceId, eventId, params, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!(marketplaceId && eventId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSeatingChart>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiMarketplacesMarketplaceIdEventsEventIdSeatingChartQueryResult = NonNullable<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSeatingChart>>>;
export type GetApiMarketplacesMarketplaceIdEventsEventIdSeatingChartQueryError = AxiosError<void>;

export const useGetApiMarketplacesMarketplaceIdEventsEventIdSeatingChart = <
  TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSeatingChart>>,
  TError = AxiosError<void>,
>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  params?: GetApiMarketplacesMarketplaceIdEventsEventIdSeatingChartParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSeatingChart>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiMarketplacesMarketplaceIdEventsEventIdSeatingChartQueryOptions(marketplaceId, eventId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiMarketplacesMarketplaceIdEventsEventIdListings = (
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  params?: GetApiMarketplacesMarketplaceIdEventsEventIdListingsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsMarketplacesGetListingsResponse>> => {
  return axios.get(`/api/Marketplaces/${marketplaceId}/Events/${eventId}/Listings`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetApiMarketplacesMarketplaceIdEventsEventIdListingsQueryKey = (
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  params?: GetApiMarketplacesMarketplaceIdEventsEventIdListingsParams,
) => {
  return [`/api/Marketplaces/${marketplaceId}/Events/${eventId}/Listings`, ...(params ? [params] : [])] as const;
};

export const getGetApiMarketplacesMarketplaceIdEventsEventIdListingsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdListings>>,
  TError = AxiosError<void>,
>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  params?: GetApiMarketplacesMarketplaceIdEventsEventIdListingsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdListings>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiMarketplacesMarketplaceIdEventsEventIdListingsQueryKey(marketplaceId, eventId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdListings>>> = ({ signal }) =>
    getApiMarketplacesMarketplaceIdEventsEventIdListings(marketplaceId, eventId, params, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!(marketplaceId && eventId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdListings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiMarketplacesMarketplaceIdEventsEventIdListingsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdListings>>>;
export type GetApiMarketplacesMarketplaceIdEventsEventIdListingsQueryError = AxiosError<void>;

export const useGetApiMarketplacesMarketplaceIdEventsEventIdListings = <
  TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdListings>>,
  TError = AxiosError<void>,
>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  params?: GetApiMarketplacesMarketplaceIdEventsEventIdListingsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdListings>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiMarketplacesMarketplaceIdEventsEventIdListingsQueryOptions(marketplaceId, eventId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiMarketplacesMarketplaceIdEventsEventIdSectionAliases = (
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  params?: GetApiMarketplacesMarketplaceIdEventsEventIdSectionAliasesParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<string[]>> => {
  return axios.get(`/api/Marketplaces/${marketplaceId}/Events/${eventId}/SectionAliases`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetApiMarketplacesMarketplaceIdEventsEventIdSectionAliasesQueryKey = (
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  params?: GetApiMarketplacesMarketplaceIdEventsEventIdSectionAliasesParams,
) => {
  return [`/api/Marketplaces/${marketplaceId}/Events/${eventId}/SectionAliases`, ...(params ? [params] : [])] as const;
};

export const getGetApiMarketplacesMarketplaceIdEventsEventIdSectionAliasesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSectionAliases>>,
  TError = AxiosError<void>,
>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  params?: GetApiMarketplacesMarketplaceIdEventsEventIdSectionAliasesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSectionAliases>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiMarketplacesMarketplaceIdEventsEventIdSectionAliasesQueryKey(marketplaceId, eventId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSectionAliases>>> = ({ signal }) =>
    getApiMarketplacesMarketplaceIdEventsEventIdSectionAliases(marketplaceId, eventId, params, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!(marketplaceId && eventId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSectionAliases>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiMarketplacesMarketplaceIdEventsEventIdSectionAliasesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSectionAliases>>
>;
export type GetApiMarketplacesMarketplaceIdEventsEventIdSectionAliasesQueryError = AxiosError<void>;

export const useGetApiMarketplacesMarketplaceIdEventsEventIdSectionAliases = <
  TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSectionAliases>>,
  TError = AxiosError<void>,
>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  params?: GetApiMarketplacesMarketplaceIdEventsEventIdSectionAliasesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSectionAliases>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiMarketplacesMarketplaceIdEventsEventIdSectionAliasesQueryOptions(marketplaceId, eventId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiMarketplacesMarketplaceIdEventsEventIdSellerListings = (
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.get(`/api/Marketplaces/${marketplaceId}/Events/${eventId}/SellerListings`, options);
};

export const getGetApiMarketplacesMarketplaceIdEventsEventIdSellerListingsQueryKey = (marketplaceId: BarkerCoreEnumsMarketplace, eventId: string) => {
  return [`/api/Marketplaces/${marketplaceId}/Events/${eventId}/SellerListings`] as const;
};

export const getGetApiMarketplacesMarketplaceIdEventsEventIdSellerListingsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSellerListings>>,
  TError = AxiosError<void>,
>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSellerListings>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiMarketplacesMarketplaceIdEventsEventIdSellerListingsQueryKey(marketplaceId, eventId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSellerListings>>> = ({ signal }) =>
    getApiMarketplacesMarketplaceIdEventsEventIdSellerListings(marketplaceId, eventId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!(marketplaceId && eventId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSellerListings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiMarketplacesMarketplaceIdEventsEventIdSellerListingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSellerListings>>
>;
export type GetApiMarketplacesMarketplaceIdEventsEventIdSellerListingsQueryError = AxiosError<void>;

export const useGetApiMarketplacesMarketplaceIdEventsEventIdSellerListings = <
  TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSellerListings>>,
  TError = AxiosError<void>,
>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSellerListings>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiMarketplacesMarketplaceIdEventsEventIdSellerListingsQueryOptions(marketplaceId, eventId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiMarketplacesMarketplaceIdEventsEventId = (
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsMarketplacesEvent>> => {
  return axios.get(`/api/Marketplaces/${marketplaceId}/Events/${eventId}`, options);
};

export const getGetApiMarketplacesMarketplaceIdEventsEventIdQueryKey = (marketplaceId: BarkerCoreEnumsMarketplace, eventId: string) => {
  return [`/api/Marketplaces/${marketplaceId}/Events/${eventId}`] as const;
};

export const getGetApiMarketplacesMarketplaceIdEventsEventIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventId>>,
  TError = AxiosError<void>,
>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventId>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiMarketplacesMarketplaceIdEventsEventIdQueryKey(marketplaceId, eventId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventId>>> = ({ signal }) =>
    getApiMarketplacesMarketplaceIdEventsEventId(marketplaceId, eventId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!(marketplaceId && eventId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiMarketplacesMarketplaceIdEventsEventIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventId>>>;
export type GetApiMarketplacesMarketplaceIdEventsEventIdQueryError = AxiosError<void>;

export const useGetApiMarketplacesMarketplaceIdEventsEventId = <TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventId>>, TError = AxiosError<void>>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventId>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiMarketplacesMarketplaceIdEventsEventIdQueryOptions(marketplaceId, eventId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiMarketplacesMarketplaceIdEvents = (
  marketplaceId: BarkerCoreEnumsMarketplace,
  params: GetApiMarketplacesMarketplaceIdEventsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsMarketplacesEvent[]>> => {
  return axios.get(`/api/Marketplaces/${marketplaceId}/Events`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetApiMarketplacesMarketplaceIdEventsQueryKey = (marketplaceId: BarkerCoreEnumsMarketplace, params: GetApiMarketplacesMarketplaceIdEventsParams) => {
  return [`/api/Marketplaces/${marketplaceId}/Events`, ...(params ? [params] : [])] as const;
};

export const getGetApiMarketplacesMarketplaceIdEventsQueryOptions = <TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEvents>>, TError = AxiosError<void>>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  params: GetApiMarketplacesMarketplaceIdEventsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEvents>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiMarketplacesMarketplaceIdEventsQueryKey(marketplaceId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEvents>>> = ({ signal }) =>
    getApiMarketplacesMarketplaceIdEvents(marketplaceId, params, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!marketplaceId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEvents>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiMarketplacesMarketplaceIdEventsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEvents>>>;
export type GetApiMarketplacesMarketplaceIdEventsQueryError = AxiosError<void>;

export const useGetApiMarketplacesMarketplaceIdEvents = <TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEvents>>, TError = AxiosError<void>>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  params: GetApiMarketplacesMarketplaceIdEventsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEvents>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiMarketplacesMarketplaceIdEventsQueryOptions(marketplaceId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiMarketplacesMarketplaceIdEventsEventIdSnapshots = (
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsWarehouseListingSnapshot[]>> => {
  return axios.get(`/api/Marketplaces/${marketplaceId}/Events/${eventId}/Snapshots`, options);
};

export const getGetApiMarketplacesMarketplaceIdEventsEventIdSnapshotsQueryKey = (marketplaceId: BarkerCoreEnumsMarketplace, eventId: string) => {
  return [`/api/Marketplaces/${marketplaceId}/Events/${eventId}/Snapshots`] as const;
};

export const getGetApiMarketplacesMarketplaceIdEventsEventIdSnapshotsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSnapshots>>,
  TError = AxiosError<void>,
>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSnapshots>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiMarketplacesMarketplaceIdEventsEventIdSnapshotsQueryKey(marketplaceId, eventId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSnapshots>>> = ({ signal }) =>
    getApiMarketplacesMarketplaceIdEventsEventIdSnapshots(marketplaceId, eventId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!(marketplaceId && eventId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSnapshots>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiMarketplacesMarketplaceIdEventsEventIdSnapshotsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSnapshots>>>;
export type GetApiMarketplacesMarketplaceIdEventsEventIdSnapshotsQueryError = AxiosError<void>;

export const useGetApiMarketplacesMarketplaceIdEventsEventIdSnapshots = <
  TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSnapshots>>,
  TError = AxiosError<void>,
>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdEventsEventIdSnapshots>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiMarketplacesMarketplaceIdEventsEventIdSnapshotsQueryOptions(marketplaceId, eventId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFiltered = (
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  barkerCoreModelsPricingRuleFilters: BarkerCoreModelsPricingRuleFilters,
  params?: PutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFilteredParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsWarehouseListingSnapshot[]>> => {
  return axios.put(`/api/Marketplaces/${marketplaceId}/Events/${eventId}/Snapshots/Filtered`, barkerCoreModelsPricingRuleFilters, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFilteredMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFiltered>>,
    TError,
    {
      marketplaceId: BarkerCoreEnumsMarketplace;
      eventId: string;
      data: BarkerCoreModelsPricingRuleFilters;
      params?: PutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFilteredParams;
    },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFiltered>>,
  TError,
  {
    marketplaceId: BarkerCoreEnumsMarketplace;
    eventId: string;
    data: BarkerCoreModelsPricingRuleFilters;
    params?: PutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFilteredParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFiltered>>,
    {
      marketplaceId: BarkerCoreEnumsMarketplace;
      eventId: string;
      data: BarkerCoreModelsPricingRuleFilters;
      params?: PutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFilteredParams;
    }
  > = (props) => {
    const { marketplaceId, eventId, data, params } = props ?? {};

    return putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFiltered(marketplaceId, eventId, data, params, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFilteredMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFiltered>>
>;
export type PutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFilteredMutationBody = BarkerCoreModelsPricingRuleFilters;
export type PutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFilteredMutationError = AxiosError<void>;

export const usePutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFiltered = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFiltered>>,
    TError,
    {
      marketplaceId: BarkerCoreEnumsMarketplace;
      eventId: string;
      data: BarkerCoreModelsPricingRuleFilters;
      params?: PutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFilteredParams;
    },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFiltered>>,
  TError,
  {
    marketplaceId: BarkerCoreEnumsMarketplace;
    eventId: string;
    data: BarkerCoreModelsPricingRuleFilters;
    params?: PutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFilteredParams;
  },
  TContext
> => {
  const mutationOptions = getPutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFilteredMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsRecent = (
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  barkerCoreModelsPricingRuleFilters: BarkerCoreModelsPricingRuleFilters,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsWarehouseListingSnapshot[]>> => {
  return axios.put(`/api/Marketplaces/${marketplaceId}/Events/${eventId}/Snapshots/Recent`, barkerCoreModelsPricingRuleFilters, options);
};

export const getPutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsRecentMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsRecent>>,
    TError,
    { marketplaceId: BarkerCoreEnumsMarketplace; eventId: string; data: BarkerCoreModelsPricingRuleFilters },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsRecent>>,
  TError,
  { marketplaceId: BarkerCoreEnumsMarketplace; eventId: string; data: BarkerCoreModelsPricingRuleFilters },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsRecent>>,
    { marketplaceId: BarkerCoreEnumsMarketplace; eventId: string; data: BarkerCoreModelsPricingRuleFilters }
  > = (props) => {
    const { marketplaceId, eventId, data } = props ?? {};

    return putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsRecent(marketplaceId, eventId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsRecentMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsRecent>>
>;
export type PutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsRecentMutationBody = BarkerCoreModelsPricingRuleFilters;
export type PutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsRecentMutationError = AxiosError<void>;

export const usePutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsRecent = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsRecent>>,
    TError,
    { marketplaceId: BarkerCoreEnumsMarketplace; eventId: string; data: BarkerCoreModelsPricingRuleFilters },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsRecent>>,
  TError,
  { marketplaceId: BarkerCoreEnumsMarketplace; eventId: string; data: BarkerCoreModelsPricingRuleFilters },
  TContext
> => {
  const mutationOptions = getPutApiMarketplacesMarketplaceIdEventsEventIdSnapshotsRecentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postApiMarketplacesMarketplaceIdEventsEventIdBoost = (
  marketplaceId: BarkerCoreEnumsMarketplace,
  eventId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/Marketplaces/${marketplaceId}/Events/${eventId}/Boost`, undefined, options);
};

export const getPostApiMarketplacesMarketplaceIdEventsEventIdBoostMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiMarketplacesMarketplaceIdEventsEventIdBoost>>,
    TError,
    { marketplaceId: BarkerCoreEnumsMarketplace; eventId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiMarketplacesMarketplaceIdEventsEventIdBoost>>,
  TError,
  { marketplaceId: BarkerCoreEnumsMarketplace; eventId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiMarketplacesMarketplaceIdEventsEventIdBoost>>,
    { marketplaceId: BarkerCoreEnumsMarketplace; eventId: string }
  > = (props) => {
    const { marketplaceId, eventId } = props ?? {};

    return postApiMarketplacesMarketplaceIdEventsEventIdBoost(marketplaceId, eventId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiMarketplacesMarketplaceIdEventsEventIdBoostMutationResult = NonNullable<Awaited<ReturnType<typeof postApiMarketplacesMarketplaceIdEventsEventIdBoost>>>;

export type PostApiMarketplacesMarketplaceIdEventsEventIdBoostMutationError = AxiosError<void>;

export const usePostApiMarketplacesMarketplaceIdEventsEventIdBoost = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiMarketplacesMarketplaceIdEventsEventIdBoost>>,
    TError,
    { marketplaceId: BarkerCoreEnumsMarketplace; eventId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiMarketplacesMarketplaceIdEventsEventIdBoost>>,
  TError,
  { marketplaceId: BarkerCoreEnumsMarketplace; eventId: string },
  TContext
> => {
  const mutationOptions = getPostApiMarketplacesMarketplaceIdEventsEventIdBoostMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiMarketplacesMarketplaceIdBoosts = (
  marketplaceId: BarkerCoreEnumsMarketplace,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerOrleansStateIntervalOverride[]>> => {
  return axios.get(`/api/Marketplaces/${marketplaceId}/Boosts`, options);
};

export const getGetApiMarketplacesMarketplaceIdBoostsQueryKey = (marketplaceId: BarkerCoreEnumsMarketplace) => {
  return [`/api/Marketplaces/${marketplaceId}/Boosts`] as const;
};

export const getGetApiMarketplacesMarketplaceIdBoostsQueryOptions = <TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdBoosts>>, TError = AxiosError<void>>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdBoosts>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiMarketplacesMarketplaceIdBoostsQueryKey(marketplaceId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdBoosts>>> = ({ signal }) =>
    getApiMarketplacesMarketplaceIdBoosts(marketplaceId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!marketplaceId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdBoosts>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiMarketplacesMarketplaceIdBoostsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdBoosts>>>;
export type GetApiMarketplacesMarketplaceIdBoostsQueryError = AxiosError<void>;

export const useGetApiMarketplacesMarketplaceIdBoosts = <TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdBoosts>>, TError = AxiosError<void>>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdBoosts>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiMarketplacesMarketplaceIdBoostsQueryOptions(marketplaceId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiMarketplacesMarketplaceIdBoostsEnabled = (marketplaceId: BarkerCoreEnumsMarketplace, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.get(`/api/Marketplaces/${marketplaceId}/Boosts/Enabled`, options);
};

export const getGetApiMarketplacesMarketplaceIdBoostsEnabledQueryKey = (marketplaceId: BarkerCoreEnumsMarketplace) => {
  return [`/api/Marketplaces/${marketplaceId}/Boosts/Enabled`] as const;
};

export const getGetApiMarketplacesMarketplaceIdBoostsEnabledQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdBoostsEnabled>>,
  TError = AxiosError<void>,
>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdBoostsEnabled>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiMarketplacesMarketplaceIdBoostsEnabledQueryKey(marketplaceId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdBoostsEnabled>>> = ({ signal }) =>
    getApiMarketplacesMarketplaceIdBoostsEnabled(marketplaceId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!marketplaceId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdBoostsEnabled>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiMarketplacesMarketplaceIdBoostsEnabledQueryResult = NonNullable<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdBoostsEnabled>>>;
export type GetApiMarketplacesMarketplaceIdBoostsEnabledQueryError = AxiosError<void>;

export const useGetApiMarketplacesMarketplaceIdBoostsEnabled = <TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdBoostsEnabled>>, TError = AxiosError<void>>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdBoostsEnabled>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiMarketplacesMarketplaceIdBoostsEnabledQueryOptions(marketplaceId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putApiMarketplacesMarketplaceIdBoostsEnabled = (marketplaceId: BarkerCoreEnumsMarketplace, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Marketplaces/${marketplaceId}/Boosts/Enabled`, undefined, options);
};

export const getPutApiMarketplacesMarketplaceIdBoostsEnabledMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdBoostsEnabled>>, TError, { marketplaceId: BarkerCoreEnumsMarketplace }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdBoostsEnabled>>, TError, { marketplaceId: BarkerCoreEnumsMarketplace }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdBoostsEnabled>>, { marketplaceId: BarkerCoreEnumsMarketplace }> = (props) => {
    const { marketplaceId } = props ?? {};

    return putApiMarketplacesMarketplaceIdBoostsEnabled(marketplaceId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiMarketplacesMarketplaceIdBoostsEnabledMutationResult = NonNullable<Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdBoostsEnabled>>>;

export type PutApiMarketplacesMarketplaceIdBoostsEnabledMutationError = AxiosError<void>;

export const usePutApiMarketplacesMarketplaceIdBoostsEnabled = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdBoostsEnabled>>, TError, { marketplaceId: BarkerCoreEnumsMarketplace }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiMarketplacesMarketplaceIdBoostsEnabled>>, TError, { marketplaceId: BarkerCoreEnumsMarketplace }, TContext> => {
  const mutationOptions = getPutApiMarketplacesMarketplaceIdBoostsEnabledMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteApiMarketplacesMarketplaceIdBoostsEnabled = (marketplaceId: BarkerCoreEnumsMarketplace, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/Marketplaces/${marketplaceId}/Boosts/Enabled`, options);
};

export const getDeleteApiMarketplacesMarketplaceIdBoostsEnabledMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiMarketplacesMarketplaceIdBoostsEnabled>>, TError, { marketplaceId: BarkerCoreEnumsMarketplace }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteApiMarketplacesMarketplaceIdBoostsEnabled>>, TError, { marketplaceId: BarkerCoreEnumsMarketplace }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiMarketplacesMarketplaceIdBoostsEnabled>>, { marketplaceId: BarkerCoreEnumsMarketplace }> = (props) => {
    const { marketplaceId } = props ?? {};

    return deleteApiMarketplacesMarketplaceIdBoostsEnabled(marketplaceId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiMarketplacesMarketplaceIdBoostsEnabledMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiMarketplacesMarketplaceIdBoostsEnabled>>>;

export type DeleteApiMarketplacesMarketplaceIdBoostsEnabledMutationError = AxiosError<void>;

export const useDeleteApiMarketplacesMarketplaceIdBoostsEnabled = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiMarketplacesMarketplaceIdBoostsEnabled>>, TError, { marketplaceId: BarkerCoreEnumsMarketplace }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof deleteApiMarketplacesMarketplaceIdBoostsEnabled>>, TError, { marketplaceId: BarkerCoreEnumsMarketplace }, TContext> => {
  const mutationOptions = getDeleteApiMarketplacesMarketplaceIdBoostsEnabledMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiMarketplacesMarketplaceIdStatus = (marketplaceId: BarkerCoreEnumsMarketplace, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> => {
  return axios.get(`/api/Marketplaces/${marketplaceId}/Status`, options);
};

export const getGetApiMarketplacesMarketplaceIdStatusQueryKey = (marketplaceId: BarkerCoreEnumsMarketplace) => {
  return [`/api/Marketplaces/${marketplaceId}/Status`] as const;
};

export const getGetApiMarketplacesMarketplaceIdStatusQueryOptions = <TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdStatus>>, TError = AxiosError<unknown>>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdStatus>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiMarketplacesMarketplaceIdStatusQueryKey(marketplaceId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdStatus>>> = ({ signal }) =>
    getApiMarketplacesMarketplaceIdStatus(marketplaceId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!marketplaceId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdStatus>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiMarketplacesMarketplaceIdStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdStatus>>>;
export type GetApiMarketplacesMarketplaceIdStatusQueryError = AxiosError<unknown>;

export const useGetApiMarketplacesMarketplaceIdStatus = <TData = Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdStatus>>, TError = AxiosError<unknown>>(
  marketplaceId: BarkerCoreEnumsMarketplace,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiMarketplacesMarketplaceIdStatus>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiMarketplacesMarketplaceIdStatusQueryOptions(marketplaceId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
