import { BarkerEventListing } from '../types';
import { BarkerCoreEnumsSeatingType } from '../api';

export function isContinuousSeatingPattern(listingsToMerge: BarkerEventListing[]): boolean {
  let seatsFormContinuousPattern = false;

  const orderedAssociatedListings = listingsToMerge.sort((a, b) => parseInt(a.seatFrom) - parseInt(b.seatFrom));
  const seatingTypes = listingsToMerge.map((l) => l.seatingType);
  const distinctSeatingTypes = Array.from(new Set(seatingTypes));
  const seatingType = distinctSeatingTypes.length === 1 ? distinctSeatingTypes[0] : BarkerCoreEnumsSeatingType.Unknown;

  switch (seatingType) {
    case BarkerCoreEnumsSeatingType.Consecutive:
      seatsFormContinuousPattern = continuousConsecutiveCheck(orderedAssociatedListings);
      break;
    case BarkerCoreEnumsSeatingType.EvenOdd:
      seatsFormContinuousPattern = continuousEvenOddCheck(orderedAssociatedListings);
      break;
    case BarkerCoreEnumsSeatingType.GeneralAdmission:
      seatsFormContinuousPattern = continuousConsecutiveCheck(orderedAssociatedListings) || continuousEvenOddCheck(orderedAssociatedListings);
      break;
    case BarkerCoreEnumsSeatingType.Unknown:
      // This shouldn't happen, however if seating type is unknown and we're checking single ticket listings, a potential seat gap is exposed -
      // ex 10, 12 will pass the even/odd check even if the seating type *should* be consecutive.
      seatsFormContinuousPattern = continuousConsecutiveCheck(orderedAssociatedListings) || continuousEvenOddCheck(orderedAssociatedListings);
      break;
  }
  return seatsFormContinuousPattern;
}

const continuousEvenOddCheck = (orderedListings: BarkerEventListing[]): boolean => {
  let lastSeatThru: number | null = null;

  for (const listing of orderedListings) {
    const seatFrom = parseInt(listing.seatFrom);
    const seatThru = parseInt(listing.seatThru);

    if (lastSeatThru !== null && seatFrom - lastSeatThru !== 2) {
      return false;
    }

    lastSeatThru = seatThru;
  }

  return true;
};

const continuousConsecutiveCheck = (orderedListings: BarkerEventListing[]): boolean => {
  let lastSeatThru: number | null = null;

  for (const listing of orderedListings) {
    const seatFrom = parseInt(listing.seatFrom);
    const seatThru = parseInt(listing.seatThru);

    if (lastSeatThru !== null && seatFrom - lastSeatThru !== 1) {
      return false;
    }

    lastSeatThru = seatThru;
  }

  return true;
};
