import { Box, Container, Text, Loader } from '@mantine/core';
import { EventMappingSearchResultsCard } from './EventMapping.SearchResultsCard';
import { useEventMapping } from './EventMapping.hooks';
import classes from './EventMapping.styles.tsx.module.css';

export function EventMappingSearchResults() {
  const { selectedEvent, searchResults, isSearchLoading, selectedMarketplaceEvent, linkEvent, setShowEventMapping } = useEventMapping(
    'selectedEvent',
    'searchResults',
    'isSearchLoading',
    'selectedMarketplaceEvent',
    'linkEvent',
    'setShowEventMapping',
  );

  return (
    <Container p={0} m={0} className={classes.resultsContainer}>
      <Box className={classes.resultsScroller}>
        {searchResults === undefined && (
          <Box p="sm" className={classes.emptyState}>
            <Text c="var(--colors-gray-5)" size="xs">
              Results will appear here
            </Text>
          </Box>
        )}
        {isSearchLoading && (
          <Box p="sm" className={classes.loadingState}>
            <Loader color="var(--colors-gray-5)" type="dots" />
          </Box>
        )}
        {!isSearchLoading && searchResults && searchResults.length === 0 && (
          <Box p="sm" className={classes.emptyState}>
            <Text c="var(--colors-gray-5)" size="xs">
              No Results Found
            </Text>
          </Box>
        )}
        {!isSearchLoading &&
          searchResults &&
          selectedEvent &&
          searchResults.length > 0 &&
          searchResults.map((result) => (
            <EventMappingSearchResultsCard
              key={result.eventId}
              event={result}
              selectedMarketplaceEventId={selectedMarketplaceEvent.marketplaceEventId!}
              posEvent={selectedEvent}
              marketplaceId={selectedMarketplaceEvent.marketplaceId!}
              onLinkEvent={(marketplaceEvent) => {
                linkEvent(selectedMarketplaceEvent.marketplaceId!, selectedEvent.eventId, {
                  marketplaceId: selectedMarketplaceEvent.marketplaceId!,
                  marketplaceEventId: marketplaceEvent.eventId,
                  marketplaceEvent,
                  posEvent: selectedEvent,
                });
                setShowEventMapping(false);
              }}
            />
          ))}
      </Box>
    </Container>
  );
}
