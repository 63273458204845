import { IconPropsBase } from './IconPropsBase';

export default function LocationOnIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="M480.068-485.385q29.855 0 51.047-21.26 21.192-21.26 21.192-51.115t-21.26-51.047q-21.26-21.192-51.115-21.192t-51.047 21.26q-21.192 21.26-21.192 51.115t21.26 51.047q21.26 21.192 51.115 21.192ZM480-179.461q117.384-105.076 179.654-201.577 62.269-96.5 62.269-169.039 0-109.384-69.5-179.846T480-800.385q-102.923 0-172.423 70.462t-69.5 179.846q0 72.539 62.269 169.039Q362.616-284.537 480-179.461Zm0 79.844Q329.001-230.463 253.539-343.154q-75.461-112.692-75.461-206.923 0-138.46 89.577-224.191Q357.231-859.999 480-859.999t212.345 85.731q89.577 85.731 89.577 224.191 0 94.231-75.461 206.923Q630.999-230.463 480-99.617Zm0-458.075Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="M480.28-485.385q26.643 0 45.335-18.973 18.692-18.972 18.692-45.615 0-26.642-18.972-45.334-18.973-18.692-45.615-18.692-26.643 0-45.335 18.972-18.692 18.973-18.692 45.615 0 26.643 18.972 45.335 18.973 18.692 45.615 18.692ZM480-184.461q114.384-100.076 178.154-195.077 63.769-95 63.769-167.539 0-105.384-68.5-175.346T480-792.385q-104.923 0-173.423 69.962t-68.5 175.346q0 72.539 63.769 167.539Q365.616-284.537 480-184.461Zm0 68.844Q333.026-240.57 259.552-348.208q-73.474-107.638-73.474-198.869 0-122.46 82.077-209.691 82.076-87.231 211.345-87.231t211.845 87.231q82.577 87.231 82.577 209.691 0 91.231-72.961 198.423Q627.999-241.463 480-115.617Zm0-434.075Z" />
      </svg>
    );
  }
}
