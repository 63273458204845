import { BarkerEventListing } from '../../types';
import { GridApi, ICellRendererParams, IRowNode } from '@ag-grid-community/core';
import { useEffect, useRef } from 'react';

type Props = {
  hashId: string;
  ruleCount: number;
  level: number;
  node: IRowNode<BarkerEventListing | unknown> & { beans?: { gridApi: GridApi<BarkerEventListing | unknown> } };
  registerRowDragger: ICellRendererParams['registerRowDragger'];
};

export const DragCell = ({ hashId, node, level, ruleCount, registerRowDragger }: Props) => {
  const dragRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    registerRowDragger(dragRef.current!, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (ruleCount && level === 1 && ruleCount > 1) {
    return (
      <button
        type="button"
        aria-label={`Expand group ${hashId}`}
        onClick={(e) => {
          e.preventDefault();
          node.setExpanded(!node?.expanded);
        }}
        className="custom-icon-grip"
      >
        {ruleCount}
      </button>
    );
  }
  return (
    <div className="ag-cell-wrapper wow" role="presentation">
      <div className="ag-drag-handle ag-row-drag" ref={dragRef}>
        <span className="ag-icon ag-icon-grip" />
      </div>
      <span className="ag-cell-value" />
    </div>
  );
};
