import { Box, Tooltip } from '@mantine/core';
import { BarkerEventListing } from '../../types';
import { useHover } from '@mantine/hooks';

export function ListingIdDisplay({ listing }: { listing: BarkerEventListing }) {
  const { hovered, ref } = useHover();
  return (
    <Box ref={ref}>
      {import.meta.env.DEV && hovered && listing?.ruleId ? (
        <Tooltip withinPortal withArrow label={listing?.ruleId}>
          <div>{listing?.listingId}</div>
        </Tooltip>
      ) : (
        <div>{listing?.listingId}</div>
      )}
    </Box>
  );
}
