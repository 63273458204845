import { IconPropsBase } from './IconPropsBase';

export default function MenuOpenIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path fill={color} d="M3.3 17.625v-1.5h12.5v1.5Zm16.35-.975L15 12l4.65-4.65L20.7 8.4 17.1 12l3.6 3.6ZM3.3 12.75v-1.5h9.5v1.5Zm0-4.875v-1.5h12.5v1.5Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M3.25 14.188v-1.084h9.583v1.084Zm12.729-.48L12.271 10l3.708-3.708.771.77L13.812 10l2.938 2.938ZM3.25 10.542V9.458h7.583v1.084Zm0-3.646V5.812h9.583v1.084Z"
        />
      </svg>
    );
  }
}
