import { Box, Divider, Flex, Text } from '@mantine/core';
import { AdjustmentValueInputDiagonal } from '../../components/AdjustmentInput/AdjustmentValueInputDiagonal';
import { BNButton } from '../../components/Button/Button';
import { BNNumberInput } from '../../components/NumberInput/NumberInput';
import { BNSwitch } from '../../components/Switch/Switch';
import AutoPriceIcon from '../../components/icons/AutoPrice';
import ChevronBackIcon from '../../components/icons/ChevronBack';
import { useDidUpdate, useDisclosure } from '@mantine/hooks';
import { useSeasonPricer } from '../../data/SeasonsPricerState';
import { useAtom, useAtomValue } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { useEffect, useMemo, useState } from 'react';
import { auth } from '../../data/atoms.auth';
import classes from './SeasonEvents.EventCard.module.css';
import { useSeasonEventCard } from './SeasonEvents.EventCard.hooks';
import { useForm } from '@mantine/form';

export function EventCardForm({ eventId, listingId }: { eventId: string; listingId: string }) {
  const [showAutoMore, { toggle: toggleAutoMore }] = useDisclosure();
  const { rulesAtom, selectedLocation, listingsAtom, errorEventId } = useSeasonPricer('rulesAtom', 'selectedLocation', 'listingsAtom', 'errorEventId');
  const [listings, setListings] = useAtom(listingsAtom);
  const { targetPrice } = useSeasonEventCard('targetPrice');
  const [rule, updateRule] = useAtom(useMemo(() => focusAtom(rulesAtom, (x) => x.prop(eventId)), [eventId, rulesAtom]));
  const minFloorPrice = useAtomValue(auth.tenantsAtom).find((x) => x.tenantId === selectedLocation?.tenantId)?.settings?.pricerSettings?.minimumFloorPrice ?? 0;
  const settings = useAtomValue(auth.principalAtom)?.settings?.pricerSettings;
  const [override, setOverride] = useState(false);
  const originalPricerStatus = selectedLocation!.listings.find((x) => x.listingId === listingId)!.pricerStatusId;
  const listing = listings[listingId];
  const form = useForm({
    validateInputOnChange: false,
    validateInputOnBlur: true,
    initialValues: {
      floorPrice: rule.floorPrice,
      ceilingPrice: rule.ceilingPrice,
      adjustmentTypeId: rule.adjustmentTypeId,
      adjustmentValue: rule.adjustmentValue,
    },
    validate: {
      floorPrice: (value, values) => {
        if (rule.isAutoPriced) {
          if (!value) {
            return 'Floor price is required and cannot be $0';
          }
          if (value >= (values?.ceilingPrice || Infinity)) {
            return 'Floor price must be less than ceiling price';
          }
        }
        return null;
      },
      ceilingPrice: (value, values) => {
        if (rule.isAutoPriced) {
          if (values.floorPrice && value && value <= values.floorPrice) {
            return 'Ceiling price must be larger than floor price';
          }
        }

        return null;
      },
    },
  });

  useDidUpdate(() => {
    form.setValues({
      floorPrice: rule.floorPrice,
      ceilingPrice: rule.ceilingPrice,
      adjustmentValue: rule.adjustmentValue,
      adjustmentTypeId: rule.adjustmentTypeId,
    });
  }, [rule.floorPrice, rule.ceilingPrice, rule.adjustmentValue, rule.adjustmentTypeId]);

  useDidUpdate(() => {
    form.validate();
  }, [errorEventId]);

  useEffect(() => {
    if (!rule.isAutoPriced && override) {
      setOverride(false);
      updateRule((x) => ({ ...x, meta: { adjustmentOverride: false, numComparablesOverride: false } }));
    }
  }, [override, rule.isAutoPriced, updateRule]);

  useEffect(() => {
    if (!override && rule.isAutoPriced && targetPrice) {
      const floorMultiplier = settings?.floorMultiplier ?? 0;
      const ceilingMultiplier = settings?.ceilingMultiplier ?? 0;
      const floorCostMultiplier = settings?.floorCostMultiplier || 0;
      const ceilingCostMultiplier = settings?.ceilingCostMultiplier || 0;
      const costBasedFloor = (listing?.unitCost ?? 0) * floorCostMultiplier;
      const costBasedCeiling = (listing?.unitCost ?? 0) * ceilingCostMultiplier;

      let compBasedFloor = 0;
      let compBasedCeiling = 0;

      if (targetPrice && floorMultiplier) {
        compBasedFloor = targetPrice * floorMultiplier;
      }
      if (targetPrice && ceilingMultiplier) {
        compBasedCeiling = targetPrice * ceilingMultiplier;
      }

      let calculatedFloor = Math.max(costBasedFloor, compBasedFloor);
      const calculatedCeiling = Math.max(costBasedCeiling, compBasedCeiling);

      if (calculatedFloor > 0 && minFloorPrice && minFloorPrice > calculatedFloor) {
        calculatedFloor = minFloorPrice;
      }

      let floor: number | undefined;
      let ceiling: number | undefined;

      if (calculatedFloor === 0) {
        floor = undefined;
      } else {
        floor = calculatedFloor;
      }

      if (calculatedCeiling === 0) {
        ceiling = undefined;
      } else {
        ceiling = calculatedCeiling;
      }

      // const ceiling = settings?.ceilingMultiplier ? targetPrice * ceilingMultiplier : undefined;
      // const floor = minFloorPrice > targetPrice * floorMultiplier ? minFloorPrice : targetPrice * floorMultiplier;

      updateRule((x) => ({ ...x, floorPrice: floor, ceilingPrice: ceiling }));
    }
  }, [
    override,
    minFloorPrice,
    rule.isAutoPriced,
    settings?.ceilingMultiplier,
    settings?.floorMultiplier,
    targetPrice,
    updateRule,
    settings?.floorCostMultiplier,
    settings?.ceilingCostMultiplier,
    listing?.unitCost,
  ]);

  return (
    <>
      <Flex gap="xs" p="xs" pb="sm">
        <Flex direction="column" gap={4}>
          <Text c="var(--colors-gray-5)" lh="1.25rem">Auto</Text>
          <BNSwitch
            size="xs"
            label={<AutoPriceIcon size={18} />}
            colorLabel
            checked={rule.isAutoPriced}
            onChange={() => {
              if (rule.isAutoPriced) {
                setListings((x) => {
                  const _listing = x[listingId];
                  _listing.pricerStatusId = originalPricerStatus;
                  return { ...x, [listingId]: _listing };
                });
              }
              updateRule((x) => ({ ...x, isAutoPriced: !x.isAutoPriced }));
            }}
          />
        </Flex>
        <Box
          w="100%"
          onClick={(e) => {
            if (e.target instanceof HTMLButtonElement || e.target instanceof SVGElement) {
              setOverride(true);
            }
          }}
        >
          <BNNumberInput
            label="Floor"
            size="xs"
            leftSection={<Text size="xs">$</Text>}
            disabled={!rule.isAutoPriced}
            onKeyUp={() => setOverride(true)}
            decimalScale={2}
            fixedDecimalScale
            step={1}
            stepHoldDelay={500}
            stepHoldInterval={100}
            {...form.getInputProps('floorPrice')}
            onChange={(e) => {
              form.getInputProps('floorPrice').onChange(e);
              if (typeof e === 'number') {
                updateRule((x) => ({ ...x, floorPrice: e }));
              }
            }}
            min={0}
            // value={rule.isAutoPriced ? rule?.floorPrice || '' : ''}
            w="100%"
          />
        </Box>
        <BNNumberInput
          {...form.getInputProps('ceilingPrice')}
          label="Ceiling"
          size="xs"
          leftSection={<Text size="xs">$</Text>}
          onKeyUp={() => setOverride(true)}
          decimalScale={2}
          fixedDecimalScale
          disabled={!rule.isAutoPriced}
          step={1}
          stepHoldDelay={500}
          stepHoldInterval={100}
          min={0}
          // value={rule.isAutoPriced ? rule?.ceilingPrice ?? '' : undefined}
          w="100%"
        />
      </Flex>

      <Flex gap="xs" pos="relative" mt={rule.isAutoPriced ? -10 : 0}>
        {rule.isAutoPriced && !showAutoMore && (
          <>
            <Flex mb={1} mr={0} pos="relative" w={60}>
              <BNButton fz={12} fw={500} size="compact-xs" variant="subtle" className={classes.noBgHover} pos="relative" onClick={() => toggleAutoMore()}>
                More{' '}
                <Box ml={6} style={{ transform: 'rotate(-90deg) translateX(2px)' }}>
                  <ChevronBackIcon size={10} />
                </Box>
              </BNButton>
            </Flex>
            <Divider color="var(--colors-divider)" w="auto" pos="absolute" top={13} right={10} left={80} variant="dotted" />
          </>
        )}
        {rule.isAutoPriced && showAutoMore && (
          <>
            <Flex mb={41} mr={0} pos="relative" style={{ alignSelf: 'end' }} w={60}>
              <BNButton fz={12} fw={500} size="compact-xs" variant="subtle" className={classes.noBgHover} pos="relative" onClick={() => toggleAutoMore()}>
                Less{' '}
                <Box ml={6} style={{ transform: 'rotate(90deg) translateX(2px)' }}>
                  <ChevronBackIcon size={10} />
                </Box>
              </BNButton>
            </Flex>
            <Flex gap="xs" px="xs" pb="sm" align="end">
              <Box
                w="50%"
                onClick={(e) => {
                  if (e.target instanceof HTMLButtonElement || e.target instanceof SVGElement) {
                    updateRule((x) => ({ ...x, meta: { ...x.meta, adjustmentOverride: true } }));
                  }
                }}
              >
                <AdjustmentValueInputDiagonal
                  label="Adjustment"
                  onKeyUp={() => {
                    updateRule((x) => ({ ...x, meta: { ...x.meta, adjustmentOverride: true } }));
                  }}
                  type={rule.adjustmentTypeId ?? 'Amount'}
                  value={rule.adjustmentValue ?? 0}
                  onChange={({ value, type }) => {
                    form.getInputProps('adjustmentTypeId').onChange(type);
                    form.getInputProps('adjustmentValue').onChange(value);
                    updateRule((x) => ({ ...x, adjustmentValue: value, adjustmentTypeId: type, meta: { ...x.meta, adjustmentOverride: true } }));
                  }}
                />
              </Box>
              <Box
                w="50%"
                onClick={(e) => {
                  if (e.target instanceof HTMLButtonElement || e.target instanceof SVGElement) {
                    updateRule((x) => ({ ...x, meta: { ...x.meta, numComparablesOverride: true } }));
                  }
                }}
              >
                <BNNumberInput
                  selectOnFocus
                  label="# Comparables"
                  decimalScale={0}
                  fixedDecimalScale
                  min={1}
                  size="xs"
                  onChange={(value) => {
                    updateRule((x) => ({ ...x, numComparables: value || 1 }));
                  }}
                  onKeyUp={() => {
                    updateRule((x) => ({ ...x, meta: { ...x.meta, numComparablesOverride: true } }));
                  }}
                  value={rule.numComparables ?? 1}
                />
              </Box>
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
}
