import { IconPropsBase } from './IconPropsBase';

export default function DragIndicatorIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M9 19.625q-.675 0-1.15-.475-.475-.475-.475-1.15 0-.675.475-1.15.475-.475 1.15-.475.675 0 1.15.475.475.475.475 1.15 0 .675-.475 1.15-.475.475-1.15.475Zm6 0q-.675 0-1.15-.475-.475-.475-.475-1.15 0-.675.475-1.15.475-.475 1.15-.475.675 0 1.15.475.475.475.475 1.15 0 .675-.475 1.15-.475.475-1.15.475Zm-6-6q-.675 0-1.15-.475-.475-.475-.475-1.15 0-.675.475-1.15.475-.475 1.15-.475.675 0 1.15.475.475.475.475 1.15 0 .675-.475 1.15-.475.475-1.15.475Zm6 0q-.675 0-1.15-.475-.475-.475-.475-1.15 0-.675.475-1.15.475-.475 1.15-.475.675 0 1.15.475.475.475.475 1.15 0 .675-.475 1.15-.475.475-1.15.475Zm-6-6q-.675 0-1.15-.475-.475-.475-.475-1.15 0-.675.475-1.15.475-.475 1.15-.475.675 0 1.15.475.475.475.475 1.15 0 .675-.475 1.15-.475.475-1.15.475Zm6 0q-.675 0-1.15-.475-.475-.475-.475-1.15 0-.675.475-1.15.475-.475 1.15-.475.675 0 1.15.475.475.475.475 1.15 0 .675-.475 1.15-.475.475-1.15.475Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M7.5 15.688q-.5 0-.844-.355-.344-.354-.344-.833 0-.5.355-.844.354-.344.833-.344.5 0 .844.355.344.354.344.833 0 .5-.355.844-.354.344-.833.344Zm5 0q-.5 0-.844-.355-.344-.354-.344-.833 0-.5.355-.844.354-.344.833-.344.5 0 .844.355.344.354.344.833 0 .5-.355.844-.354.344-.833.344Zm-5-4.5q-.5 0-.844-.355-.344-.354-.344-.833 0-.5.355-.844.354-.344.833-.344.5 0 .844.355.344.354.344.833 0 .5-.355.844-.354.344-.833.344Zm5 0q-.5 0-.844-.355-.344-.354-.344-.833 0-.5.355-.844.354-.344.833-.344.5 0 .844.355.344.354.344.833 0 .5-.355.844-.354.344-.833.344Zm-5-4.5q-.5 0-.844-.355-.344-.354-.344-.833 0-.5.355-.844.354-.344.833-.344.5 0 .844.355.344.354.344.833 0 .5-.355.844-.354.344-.833.344Zm5 0q-.5 0-.844-.355-.344-.354-.344-.833 0-.5.355-.844.354-.344.833-.344.5 0 .844.355.344.354.344.833 0 .5-.355.844-.354.344-.833.344Z"
        />
      </svg>
    );
  }
}
