import { Text, Stack, Box, Grid, Flex, Paper, Group, SimpleGrid, Divider, UnstyledButton } from '@mantine/core';
import pluralize from 'pluralize';
import { BNButton } from '../../components/Button/Button';
import { BNDatePicker } from '../../components/DatePicker/DatePicker';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import AddCircleIcon from '../../components/icons/AddCircle';
import DateRangeIcon from '../../components/icons/DateRange';
import SearchIcon from '../../components/icons/Search';
import { BNSelect } from '../../components/Select/Select';
import { BNSwitch } from '../../components/Switch/Switch';
import { BNTextInput } from '../../components/TextInput/TextInput';
import { CatalogEventCard } from './Purchase.Catalog.EventCard';
import { CatalogGrid } from './Purchase.Catalog.Grid';
import { usePurchaseState } from './Purchase.hooks';
import { useInventory } from '../Inventory/Inventory.hooks';
import { Totals } from './Purchasing.Totals';
import FilterListIcon from '../../components/icons/FilterList';
import ThingsToDoIcon from '../../components/icons/ThingsToDo';
import FolderCopyIcon from '../../components/icons/FolderCopy';
import classes from './Purchase.module.css';

export function Step_CatalogSelect() {
  const { closePurchaseModal } = useInventory('closePurchaseModal');
  const { searchForm, filtersForm, onSearchSubmit, selectedEvents, isCatalogLoading, removeCatalogEvent, nextStep, addTicketGroup, setSelectedEvents, catalogGridRef } =
    usePurchaseState(
      'searchForm',
      'filtersForm',
      'onSearchSubmit',
      'selectedEvents',
      'isCatalogLoading',
      'removeCatalogEvent',
      'nextStep',
      'addTicketGroup',
      'setSelectedEvents',
      'catalogGridRef',
    );
  return (
    <Flex direction="column" h="100%">
      <Flex h="100%" flex={1} style={{ overflow: 'hidden' }}>
        <Flex w={288} style={{ borderRight: '1px solid var(--colors-divider)' }}>
          <Paper>
            <form onSubmit={searchForm.onSubmit(onSearchSubmit)} onReset={searchForm.onReset}>
              <Paper className="searchWrapper" pos="relative">
                <Group p={16} gap={12} className="searchArea" wrap="wrap">
                  <SimpleGrid cols={2} spacing={12} style={{ flexGrow: 1 }}>
                    <BNDatePicker
                      aria-label="From Date"
                      size="xs"
                      variant="default"
                      key={searchForm.key('fromDate')}
                      {...searchForm.getInputProps('fromDate')}
                      clearable
                      valueFormat="MM/DD/YY"
                      leftSection={<DateRangeIcon size={20} />}
                      selectOnFocus
                      placeholder="From Date"
                    />
                    <BNDatePicker
                      aria-label="To Date"
                      size="xs"
                      variant="default"
                      key={searchForm.key('toDate')}
                      {...searchForm.getInputProps('toDate')}
                      clearable
                      valueFormat="MM/DD/YY"
                      leftSection={<DateRangeIcon size={20} />}
                      selectOnFocus
                      placeholder="To Date"
                    />
                  </SimpleGrid>
                  <Grid columns={24} gutter={0} w="100%" h={30}>
                    <Grid.Col span="content" style={{ flex: 1 }}>
                      <BNTextInput
                        key={searchForm.key('headliner')}
                        {...searchForm.getInputProps('headliner')}
                        aria-label="Headliner"
                        size="xs"
                        variant="default"
                        leftSection={<SearchIcon size={20} />}
                        placeholder="Headliner"
                        type="search"
                        clearable
                        clearableOnClick={() => searchForm.setValues({ headliner: '' })}
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid columns={24} gutter={0} w="100%" h={30}>
                    <Grid.Col span="content" style={{ flex: 1 }}>
                      <BNTextInput
                        key={searchForm.key('venue')}
                        {...searchForm.getInputProps('venue')}
                        aria-label="Venue"
                        size="xs"
                        variant="default"
                        leftSection={<ThingsToDoIcon size={20} />}
                        placeholder="Venue"
                        type="search"
                        clearable
                        clearableOnClick={() => searchForm.setValues({ venue: '' })}
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid columns={24} gutter={0} w="100%" h={30}>
                    <Grid.Col span="content" style={{ flex: 1 }}>
                      <BNSelect
                        searchable
                        data={[
                          { label: 'AHL', value: 'AHL' },
                          { label: 'Arena Football', value: 'Arena Football' },
                          { label: 'Auto Racing', value: 'Auto Racing' },
                          { label: 'College Baseball', value: 'College Baseball' },
                          { label: 'College Basketball', value: 'College Basketball' },
                          { label: 'College Football', value: 'College Football' },
                          { label: 'College Hockey', value: 'College Hockey' },
                          { label: 'Concerts', value: 'Concerts' },
                          { label: 'Horse Racing / Rodeo', value: 'Horse Racing / Rodeo' },
                          { label: 'International Soccer', value: 'International Soccer' },
                          { label: 'MLB', value: 'MLB' },
                          { label: 'MLS', value: 'MLS' },
                          { label: 'NBA', value: 'NBA' },
                          { label: 'NFL', value: 'NFL' },
                          { label: 'NHL', value: 'NHL' },
                          { label: 'Olympic Sports', value: 'Olympic Sports' },
                          { label: 'Other Events', value: 'Other Events' },
                          { label: 'PGA', value: 'PGA' },
                          { label: 'Tennis', value: 'Tennis' },
                          { label: 'Theatre', value: 'Theatre' },
                          { label: 'WNBA', value: 'WNBA' },
                          { label: 'Wrestling / Boxing', value: 'Wrestling / Boxing' },
                        ]}
                        key={searchForm.key('category')}
                        {...searchForm.getInputProps('category')}
                        aria-label="Category"
                        size="xs"
                        variant="default"
                        leftSection={<FolderCopyIcon size={18} />}
                        placeholder="Category"
                        type="search"
                        clearable
                      />
                    </Grid.Col>
                  </Grid>
                  <SimpleGrid cols={2} spacing={12} w="100%">
                    <BNButton size="xs" fullWidth type="reset" disabled={isCatalogLoading} variant="default">
                      Reset
                    </BNButton>
                    <BNButton loading={isCatalogLoading} type="submit" size="xs" variant="filled" color="gray" fullWidth>
                      Search
                    </BNButton>
                  </SimpleGrid>
                </Group>

                <Divider color="var(--colors-divider)" />
                <Flex h={32} align="center" px="sm" justify="space-between" style={{ borderBottom: '0.0625rem solid var(--colors-paperHover)' }}>
                  <Text tt="uppercase" lts={0.5} c="var(--colors-gray-5)" fz={11}>
                    Advanced Filters
                  </Text>
                </Flex>
                <Grid columns={24} gutter={0} w="100%" p={16}>
                  <Grid.Col span="content" style={{ flex: 1 }}>
                    <BNTextInput
                      key={filtersForm.key('query')}
                      {...filtersForm.getInputProps('query')}
                      aria-label="Quick Filter"
                      size="xs"
                      variant="default"
                      leftSection={<FilterListIcon size={20} />}
                      placeholder="Quick Filter"
                      type="search"
                      clearable
                      clearableOnClick={() => filtersForm.setValues({ query: '' })}
                    />
                  </Grid.Col>
                </Grid>
                <Stack px="md">
                  <BNSwitch label="Exclude Parking" size="xs" {...filtersForm.getInputProps('excludeParking', { type: 'checkbox' })} key={filtersForm.key('excludeParking')} />
                  <BNSwitch
                    label="Exclude Events Without Tickets"
                    size="xs"
                    {...filtersForm.getInputProps('excludeEventsWithoutTickets', { type: 'checkbox' })}
                    key={filtersForm.key('excludeEventsWithoutTickets')}
                  />
                </Stack>
              </Paper>
            </form>
          </Paper>
        </Flex>
        <Flex flex={1} h="100%">
          <CatalogGrid />
        </Flex>
        <Flex direction="column" bg="var(--colors-paper)" pos="relative" w={288} style={{ borderLeft: '1px solid var(--colors-divider)' }}>
          {selectedEvents && selectedEvents.length > 0 ? (
            <>
              <Group h={32} pos="absolute" top={0} left={0} right={0} justify="right" px={16} py={0} style={{ borderBottom: '1px solid var(--colors-divider)' }}>
                <Group w="100%" justify="space-between">
                  <UnstyledButton
                    className={classes.textLink}
                    c="var(--colors-gray-5)"
                    size="compact-xs"
                    variant="subtle"
                    fz="xs"
                    onClick={() => {
                      catalogGridRef.current?.api.deselectAll();
                      setSelectedEvents([]);
                    }}
                  >
                    Clear All
                  </UnstyledButton>
                  <Text fz={11} c="gray.5" tt="uppercase">
                    {selectedEvents ? selectedEvents.length : 0} {pluralize('Event', selectedEvents ? selectedEvents.length : 0)}
                  </Text>
                </Group>
              </Group>
              <Box
                pos="absolute"
                top={32}
                left={0}
                right={0}
                bottom={0}
                style={{
                  overflowY: 'auto',
                }}
              >
                {selectedEvents.map((event) => (
                  <Box key={event.id.toString()} onClick={() => removeCatalogEvent(event)}>
                    <CatalogEventCard event={event} />
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            <BNEmptyState
              border={false}
              title="No Events Selected"
              description="Search for events, then use the checkbox for the event to select it"
              icon={<AddCircleIcon size={28} />}
            />
          )}
        </Flex>
      </Flex>
      <Flex
        h={52}
        w="100%"
        px="md"
        bg="var(--colors-paper)"
        align="center"
        justify="space-between"
        style={{ borderTop: '1px solid var(--colors-divider)' }}
      >
        <Flex gap="md">
          <Flex w={254}>
            <BNButton size="xs" w={120} onClick={() => closePurchaseModal()}>
              Cancel Order
            </BNButton>
          </Flex>
        </Flex>
        <Group>
          <Totals />
          <Divider color="var(--colors-divider)" orientation="vertical" mx={4} />
          <BNButton
            size="xs"
            w={120}
            variant="filled"
            disabled={!selectedEvents || selectedEvents.length <= 0}
            onClick={() => {
              nextStep();
              addTicketGroup();
            }}
          >
            Continue
          </BNButton>
        </Group>
      </Flex>
    </Flex>
  );
}
