import { IconPropsBase } from './IconPropsBase';

export default function ArrowBackIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path fill={color} d="M12 19.625 4.375 12 12 4.375l1.075 1.075-5.825 5.8h12.375v1.5H7.25l5.825 5.8Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path fill={color} d="M10 15.688 4.312 10 10 4.312l.771.792-4.375 4.354h9.292v1.084H6.396l4.375 4.354Z" />
      </svg>
    );
  }
}
