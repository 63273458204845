import { RowDragEndEvent, RowDragMoveEvent } from '@ag-grid-community/core';
import { v4 as uuid } from 'uuid';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { pendingListingUpdatesByListingIdAtom, rowDraggingAtom, ruleStateAtom, transientGlobalStateAtom } from '../../data/atoms';
import { InventorySeasonLocationExtended } from '../../types';
import { useRuleState } from '../../data/RuleState';
import { useDidUpdate } from '@mantine/hooks';
import { AgGridReact } from '@ag-grid-community/react';
import { seasons } from '../../data/atoms.seasons';

export function useInventoryDragEvents({ gridRef, detailGridRef }: { gridRef: RefObject<AgGridReact>; detailGridRef: RefObject<Partial<AgGridReact>> }) {
  const _seasonLocations = useAtomValue(seasons.selectedSeasonAtom)?.locations;
  const seasonLocations = useMemo(() => _seasonLocations ?? [], [_seasonLocations]);
  const setSelectedLocation = useSetAtom(seasons.selectedLocationAtom);
  const { validateDirtyRuleState } = useRuleState('isLocalRule', 'validateDirtyRuleState');
  const [rule, setRule] = useAtom(ruleStateAtom);
  const setTransientGlobalState = useSetAtom(transientGlobalStateAtom);
  const pendingUpdatesByListId = useAtomValue(pendingListingUpdatesByListingIdAtom);
  const pendingUpdatesByListIdRef = useRef(pendingUpdatesByListId);
  const updateLocation = useSetAtom(seasons.updateLocationAtom);
  const selectedLocation = useAtomValue(seasons.selectedLocationAtom);

  useEffect(() => {
    const obv = new MutationObserver((mutatons) => {
      for (const m of mutatons) {
        if (m.addedNodes.length) {
          m.addedNodes.forEach((node) => {
            if (node instanceof Element && node.classList.contains('ag-dnd-ghost')) {
              node.classList.add('row-drag');
            }
          });
        }
      }
    });
    obv.observe(document.body, { childList: true });
  }, []);

  useDidUpdate(() => {
    pendingUpdatesByListIdRef.current = pendingUpdatesByListId;
  }, [pendingUpdatesByListId]);

  const calculateNextRuleTiers = useCallback(
    (ruleId: string, listing: InventorySeasonLocationExtended, destinationListing: InventorySeasonLocationExtended, direction?: 'up' | 'down' | 'master' | null) => {
      const ruleListings = seasonLocations.filter((x) => x.ruleId === ruleId).sort((a, b) => (a.ruleTier || 0) - (b.ruleTier || 0));
      // Creating a new rule and assign both listings to it
      if (ruleListings.length === 0 || ruleListings.length === 1) {
        return [100, 0];
      }

      // Assigning a rule from the outside to a listing that is already in a rule
      if (direction === 'master') {
        const lastListingRuleTier = (ruleListings.pop()?.ruleTier || 50) * 2;
        return [lastListingRuleTier, destinationListing.ruleTier || 0];
      }

      const nextRuleTierIndex = ruleListings.findIndex((x) => x.hashId === destinationListing.hashId) + 1;
      const nextRuleTier = ruleListings[nextRuleTierIndex]?.ruleTier
        ? (ruleListings[nextRuleTierIndex]!.ruleTier! + destinationListing.ruleTier!) / 2
        : (destinationListing.ruleTier || 1) + (direction && direction === 'down' ? -1 : 1);

      // Detail listing is dropped onto anchor listing
      if (destinationListing.ruleTier === 0 && listing.ruleId === destinationListing.ruleId) {
        return [0, nextRuleTier];
      }

      // Handle condition where ruleTiers got sync'd up somehow
      if (nextRuleTier && nextRuleTier === destinationListing.ruleTier) {
        return [destinationListing.ruleTier + 1, nextRuleTier + 2];
      }

      // if (direction === 'up') {
      //   return [destinationListing.ruleTier - 1 || 50, nextRuleTier || 0];
      // } else if (direction === 'down') {
      //   return [destinationListing.ruleTier + 1 || 50, nextRuleTier || 0];
      // }

      // Changing the order of a listing within a rule group
      return [destinationListing.ruleTier || 50, nextRuleTier || 0];
    },
    [seasonLocations],
  );

  // Ref used to pivot which group is hovered over when dragging
  const modId = useRef<string | undefined>();
  const setIsRowDragging = useSetAtom(rowDraggingAtom);

  const onMasterRowDragMove = useCallback(
    (e: RowDragMoveEvent<InventorySeasonLocationExtended>) => {
      setIsRowDragging(true);
      const ghost = document.querySelector('.ag-dnd-ghost');
      const overALittle = document.elementFromPoint(e.event.clientX, e.event.clientY);
      const newRow = overALittle?.closest('[row-id]');
      const newRowId = newRow?.getAttribute('row-id');
      const masterOverNode = gridRef?.current?.api.getRowNode(newRowId!);
      // @ts-ignore: TS2341
      const detailOverNode = !detailGridRef.current?.api?.destroyCalled ? detailGridRef?.current?.api.getRowNode(newRowId!) : null;
      const floatingClasses = ['remove-from-group', 'change-group-anchor', 'create-group', 'move-inside-group', 'add-to-group', 'disallow-group'];
      const ruleCount = e.overNode?.data?.ruleCount ?? 0;

      // Is Dragging
      document.querySelector('body')?.classList.add('row-dragging');
      // Handle hover-state for the row element hovered over
      const rowsWithClass = document.querySelectorAll('#inventory-grid .hover-over');
      rowsWithClass.forEach((element) => element.classList.remove('hover-over', 'dir-up', 'dir-down'));

      if (ghost) {
        // Reset classes on each drag event.
        floatingClasses.forEach((c) => ghost.classList.remove(c));

        // Show header dropzone when dragging a grouped listing
        if (e.node.level === 0) {
          const currentGroupHeaderDropZone = document.querySelector(`[data-overlay-id="${e.node.data?.seasonName}"]`);
          currentGroupHeaderDropZone?.parentElement?.classList.add('show-dropzone');
        }

        // Create Group Condition
        if ((e.node.level === 1 && masterOverNode?.group) || (e.node.data?.seasonName !== e.overNode?.data?.seasonName && e.node.data?.seasonName !== masterOverNode?.key)) {
          ghost.classList.add('disallow-group');
        } else if (e.node.level === 1 && e.overNode?.level === 1 && (ruleCount === 0 || ruleCount === 1) && e.node.id !== e.overNode.id) {
          // Handles creating a group if overNode is not already a group
          ghost.classList.add('create-group');
        } else if (
          (e.node.level === 1 && e.overNode?.level === 2) ||
          (!e.overNode?.group && e.node.level === 1 && e.overNode?.level === 1 && ruleCount > 1 && e.node.id !== e.overNode.id)
        ) {
          // Handles adding from master to detail on the anchor or the details overlay.
          ghost.classList.add('add-to-group');
          if (e.node.id !== e.overNode.id) {
            if (detailOverNode) {
              e.overNode = detailOverNode;
              const hoveredRow = document.querySelector<HTMLElement>(`[row-id="${e.overNode.id}"]`)!;
              const rowLocation = hoveredRow.getBoundingClientRect();
              // get half of the row height so we can detect whether the dropzone should be above or below the overnode
              // add arrows to inner listings
              if (!e.overNode?.group) {
                // is it in the top half of the row?
                if (e.event.clientY - rowLocation.top < rowLocation.height / 2) {
                  hoveredRow?.classList.add('dir-up');
                } else {
                  hoveredRow?.classList.add('dir-down');
                }
              }
              // show dropzone in header
              hoveredRow?.classList.add('hover-over');
            }
          }
        } else if (!e.overNode?.group && !masterOverNode && e.node.level === 0 && e.overNode?.level === 0 && e.node.id !== e.overNode.id) {
          // Handles moving inside of a group
          ghost.classList.add('move-inside-group');
          const hoveredRow = document.querySelector<HTMLElement>(`[row-id="${e.overNode.id}"]`);
          // Handle details grid additional conditions
          if (e.node.id !== e.overNode.id) {
            const rowHeight = e.overNode?.rowHeight || 36;
            // get half of the row height so we can detect whether the dropzone should be above or below the overnode
            const rowHalf = rowHeight / 2;
            // add arrows to inner listings
            if (!e.overNode.group) {
              if (e.overNode.rowTop) {
                // is it in the top half of the row?
                if (e.y < e.overNode.rowTop + rowHalf) {
                  hoveredRow?.classList.add('dir-up');
                } else {
                  hoveredRow?.classList.add('dir-down');
                }
              } else if (e.y < rowHalf) {
                // is it in the top half of the row?
                hoveredRow?.classList.add('dir-up');
              } else {
                hoveredRow?.classList.add('dir-down');
              }
            } else {
              // show dropzone in header
              hoveredRow?.classList.add('hover-over');
            }
          }
        } else if (e.node.level === 0 && e.overNode?.level === 1 && e.node.data?.ruleId === e.overNode.data?.ruleId && !masterOverNode?.group) {
          // Handles changing anchor if overNode is the anchor andruleId the same ruleId
          ghost.classList.add('change-group-anchor');
        } else if (
          (e.node.level === 0 && e.node.data?.seasonName === e.overNode?.key) ||
          (e.node.level === 0 && e.overNode?.level === 1 && e.node.data?.ruleId !== e.overNode.data?.ruleId)
        ) {
          // Handles removing from group on any master row that isn't the anchor
          ghost.classList.add('remove-from-group');
        } else if (e.node.level === 0 && masterOverNode && masterOverNode.group) {
          // Handles removing from group when overNode is group header
          ghost.classList.add('remove-from-group');
        } else if (
          masterOverNode &&
          !masterOverNode.group &&
          masterOverNode.data.ruleId === e.node.data?.ruleId &&
          e.node?.id !== masterOverNode.id &&
          e.node.id !== e.overNode?.id
        ) {
          ghost.classList.add('change-group-anchor');
        }
      }

      // Add hover class to the row it's over, as long as it isn't itself
      if (e.overNode && e.overNode !== e.node) {
        const hoveredRow = document.querySelector<HTMLElement>(`[row-id="${e.overNode.id}"]`);

        // Exclude detail container from hover-over class
        // Don't hover from details grid to master grid
        // Don't hover if not on the same tenantEventId
        if (!((e.node.level === 0 && e.overNode.id?.includes('detail_')) || (e.node.level === 0 && e.overNode.level === 1))) {
          hoveredRow?.classList.add('hover-over');
        }

        // Show the header dropzone if the dragged node is from a group
        if (e.node.level === 0) {
          const currentGroupHeaderDropZone = document.querySelector(`[data-overlay-id="${e.node.data?.seasonName}"]`);
          currentGroupHeaderDropZone?.parentElement?.classList.add('show-dropzone');

          // Event header when sticky
          if (masterOverNode?.leafGroup) {
            newRow?.classList.add('hover-over');
          }
        } else {
          // include the details grid in the hover only if the dragged node is from outside of the group
          const hoveredDetailsMask = document.querySelector<HTMLElement>(`[row-id="detail_${e.overNode.id}"]`);
          hoveredDetailsMask?.classList.add('hover-over');

          if (e.overNode.id?.includes('detail_')) {
            modId.current = e.overNode.id?.split('_').pop();
            const hoveredAnchorWithMask = document.querySelector<HTMLElement>(`[row-id="${modId}"]`);
            hoveredAnchorWithMask?.classList.add('hover-over');
          }
        }
      }
    },
    [detailGridRef, gridRef, setIsRowDragging],
  );

  /**
   * Handles dragging listings on the outer grid to the detail grid. (aka. Adding RuleID)
   * Also creates a rule if the anchor listing does not already have one configured.
   */
  const onMasterRowDragEnd = useCallback(
    async (e: RowDragEndEvent<InventorySeasonLocationExtended>) => {
      // add class to ag-grid drag ghost element for styling
      // Is Dragging
      document.querySelector('body')?.classList.remove('row-dragging');
      const ghost = document.querySelector('.ag-dnd-ghost');
      if (e.node.data && e.overNode?.data && e.node.data.tenantId === e.overNode.data.tenantId) {
        ghost?.classList.add('row-drag');

        // Clear dropzone overlay
        const activeDropzone = document.querySelector('.show-dropzone');
        activeDropzone?.classList.remove('show-dropzone');

        // Don't drop yourself on your own head.
        if (e.node === e.overNode) {
          return;
        }
        // Don't go from details to master.
        if (e.node.level === 0) {
          return;
        }
        // Escape if the node being dragged is the same ruleId;
        if (e.overNode.master && e.overNode.data.ruleId === e.node.data.ruleId) {
          return;
        }

        // Anchor rule must be selected and dirty state must be validated before making it selected.
        if (e.api.getSelectedNodes().length > 0 && e.overNode.data.hashId !== e.api.getSelectedNodes()[0].data?.hashId) {
          if (!(await validateDirtyRuleState())) {
            return;
          }
        }

        let { ruleId } = e.overNode.data;
        const { hashId } = e.node.data;
        if (!ruleId) {
          if (e.overNode.data.hashId === selectedLocation?.hashId) {
            setRule((r) => ({ ...r, isAutoPriced: true }));
            ruleId = rule.ruleId!;
          } else {
            const newRuleId = uuid();
            setRule((r) => ({ ...r, ruleId: newRuleId, isAutoPriced: true }));
            ruleId = newRuleId;
          }
        } else {
          setTransientGlobalState((s) => ({ ...s, autoUpdateAutoPricedListPriceRuleId: ruleId }));
        }
        const { ...location } = seasonLocations.find((x) => x.hashId === hashId)!;
        const overALittle = document.elementFromPoint(e.event.clientX, e.event.clientY);
        const newRow = overALittle?.closest('[row-id]');
        const newRowId = newRow?.getAttribute('row-id');

        // @ts-ignore: TS2341
        const newOverNode: typeof e.overNode | null = !detailGridRef.current?.api?.destroyCalled ? detailGridRef?.current?.api?.getRowNode(newRowId!) : null;
        const dirUpOrDown = newOverNode ? (document.querySelector(`[row-id="${newOverNode.id}"`)?.classList.contains('dir-down') ? 'down' : 'up') : null;
        const parentGroupNode = e.overNode.data;
        if (newOverNode) {
          e.overNode = newOverNode;
        } else if (!e.overNode.isSelected()) {
          setSelectedLocation(seasonLocations.find((x) => x.hashId === e.overNode!.data!.hashId)!);
        }

        if (e.overNode.data) {
          const { hashId: anchorHashId } = e.overNode.data;
          const { ...anchorListing } = e.overNode.data;
          const [listingNewRuleTier, anchorNewRuleTier] = calculateNextRuleTiers(
            ruleId,
            location,
            e.overNode.data,
            e.overNode.master || e.overNode.detail ? 'master' : dirUpOrDown,
          );

          if (anchorListing.ruleId !== ruleId) {
            updateLocation(anchorHashId, {
              ruleId,
              ruleCount: 2,
            });
          }

          updateLocation(parentGroupNode.hashId, {
            ruleCount: anchorListing.ruleCount ? anchorListing.ruleCount + 1 : 2,
          });

          if (anchorListing.ruleTier !== anchorNewRuleTier) {
            updateLocation(anchorHashId, {
              ruleTier: anchorNewRuleTier,
            });
          }

          if (location.ruleId !== ruleId) {
            updateLocation(hashId, {
              ruleId,
            });
          }

          if (location.ruleTier !== listingNewRuleTier) {
            updateLocation(hashId, {
              ruleTier: listingNewRuleTier,
            });
          }

          if (!dirUpOrDown && e.overNode && !e.overNode.master) {
            e.overNode.master = true;
          }

          if (e.overNode.level === 1) {
            e.overNode.setExpanded(true);
          }
        }
        const rowsWithClass = document.querySelectorAll('#inventory-grid .hover-over');
        rowsWithClass.forEach((element) => element.classList.remove('hover-over', 'dir-up', 'dir-down'));
      }
    },
    [
      calculateNextRuleTiers,
      detailGridRef,
      rule.ruleId,
      seasonLocations,
      selectedLocation?.hashId,
      setRule,
      setSelectedLocation,
      setTransientGlobalState,
      updateLocation,
      validateDirtyRuleState,
    ],
  );

  /**
   * Handles dragging listings on the detail grid to the outer grid. (aka. Removing RuleID)
   * Handles replacing anchor listing from a detail listing
   */
  const onDetailMasterRowDragEnd = useCallback(
    async (e: RowDragEndEvent<InventorySeasonLocationExtended>) => {
      const hashId = e.node.data?.hashId!;
      const ruleId = e.node.data?.ruleId;
      const anchorLocation = seasonLocations.filter((x) => x.ruleId === ruleId).toSorted((a, b) => (a.ruleTier || 0) - (b.ruleTier || 0))[0];
      const locationsMatchingRule = seasonLocations.filter((x) => x.ruleId === ruleId).length;
      const anchorNode = gridRef.current!.api.getRowNode(anchorLocation.hashId)!;
      // Is Dragging
      document.querySelector('body')?.classList.remove('row-dragging');

      // Clear dropzone overlay
      const activeDropzone = document.querySelector('.show-dropzone');
      activeDropzone?.classList.remove('show-dropzone');

      // Dropping on detail node mask wrapper should do nothing. Group means it's the group header which is allowed.
      if (e.overNode?.level === 2 && !e.overNode.group) {
        return;
      }

      if ((e.overNode?.data?.seasonName === e.node.data?.seasonName || e.node.data?.seasonName === e.overNode?.key) && e.node.data?.ruleId !== e.overNode?.data?.ruleId) {
        // Break up the rule
        if (locationsMatchingRule === 2) {
          anchorNode.setExpanded(false);
          anchorNode.master = false;
          requestAnimationFrame(() => {
            gridRef.current!.api.redrawRows({ rowNodes: [anchorNode] });
          });
        }
        updateLocation(hashId, {
          ruleCount: undefined,
          ruleId: undefined,
          ruleTier: undefined,
        });
        updateLocation(anchorLocation.hashId, {
          ruleCount: locationsMatchingRule - 1,
          ruleId: locationsMatchingRule === 2 ? undefined : ruleId,
          ruleTier: locationsMatchingRule === 2 ? undefined : anchorLocation.ruleTier,
        });
      } else if (
        e.overNode?.master &&
        e.node.data &&
        e.overNode?.data &&
        e.node.data.tenantId === e.overNode.data.tenantId &&
        e.node.data.seasonName === e.overNode.data.seasonName
      ) {
        // ANCHOR SWAP - section
        const [locationNewRuleTier, destinationNewRuleTier] = calculateNextRuleTiers(ruleId!, e.node.data, anchorLocation);
        e.node.data.ruleTier = locationNewRuleTier;
        anchorLocation.ruleTier = destinationNewRuleTier;

        updateLocation(e.node.data.hashId, {
          ruleTier: locationNewRuleTier,
          ruleCount: anchorLocation.ruleCount,
        });
        updateLocation(anchorLocation.hashId, {
          ruleTier: destinationNewRuleTier,
          ruleCount: undefined,
        });

        requestAnimationFrame(() => {
          gridRef.current!.api.redrawRows();
        });
      }
    },
    [seasonLocations, gridRef, updateLocation, calculateNextRuleTiers],
  );

  /**
   * Handles dragging and dropping listings inside of the details grid
   */
  const onDetailRowDragEnd = useCallback(
    async (e: RowDragEndEvent<InventorySeasonLocationExtended>) => {
      // Is Dragging
      document.querySelector('body')?.classList.remove('row-dragging');

      const overALittle = document.elementFromPoint(e.event.clientX, e.event.clientY);
      const newRow = overALittle?.closest('[row-id]');
      const newRowId = newRow?.getAttribute('row-id');
      const masterOverNode = gridRef?.current?.api.getRowNode(newRowId!);

      // Details handler now triggers instead of master. Parlay to master handler. Sticky header removal
      if (masterOverNode?.group) {
        e.overNode = masterOverNode;
        onDetailMasterRowDragEnd(e);
        return;
      }

      // Details handler now triggers instead of master. Parlay to master handler. Anchor Swap
      if (masterOverNode?.master && masterOverNode?.data.ruleId === e.node.data?.ruleId) {
        e.overNode = masterOverNode;
        onDetailMasterRowDragEnd(e);
        return;
      }

      // document.body.classList.remove('grid-dragging');
      // add class to ag-grid drag ghost element for styling
      const ghost = document.querySelector('.ag-dnd-ghost');
      ghost?.classList.add('row-drag');
      // detect direction
      const dirUpOrDown = document.querySelector(`[row-id="${e.overNode?.id}"`)?.classList.contains('dir-down') ? 'down' : 'up';
      // Clear hovers
      const rowsWithClass = document.querySelectorAll('#inventory-grid .hover-over');
      rowsWithClass.forEach((element) => element.classList.remove('hover-over', 'dir-up', 'dir-down'));

      // Clear dropzone overlay
      const activeDropzone = document.querySelector('.show-dropzone');
      activeDropzone?.classList.remove('show-dropzone');

      if (e.node === e.overNode) {
        return; // Don't do anything if the node is dropped on itself
      }
      // TODO: This will handle when dragging and detail listing onto another detail listing
      const dragListing = e.node.data!;
      const dropListing = e.overNode?.data!;
      const { ruleId } = dragListing;
      const [listingNewRuleTier, destinationNewRuleTier] = calculateNextRuleTiers(ruleId!, dragListing, dropListing);

      if (dirUpOrDown === 'up') {
        dragListing.ruleTier = listingNewRuleTier;
        dropListing.ruleTier = destinationNewRuleTier;
      } else {
        dragListing.ruleTier = destinationNewRuleTier;
        dropListing.ruleTier = listingNewRuleTier;
      }

      updateLocation(dragListing.hashId, {
        ruleTier: dragListing.ruleTier,
      });
      updateLocation(dropListing.hashId, {
        ruleTier: dropListing.ruleTier,
      });

      requestAnimationFrame(() => {
        gridRef.current!.api.redrawRows();
      });
    },
    [calculateNextRuleTiers, gridRef, onDetailMasterRowDragEnd, updateLocation],
  );

  return { onMasterRowDragEnd, onMasterRowDragMove, onDetailRowDragEnd, onDetailMasterRowDragEnd };
}
