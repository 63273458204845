import { Box, Divider, Group, Popover, SegmentedControl, Text, UnstyledButton } from '@mantine/core';
import SelectArrowsIcon from '../icons/SelectArrows';
import classes from './OrderSelect.module.css';
export type SortOrder = 'asc' | 'desc';
interface BNOrderSelectProps {
  onClick?: () => void;
  selectedItem: string;
  dropdownWidth?: number;
  data: string[];
  isOpen: boolean;
  sortOrder: SortOrder;
  onSelectionChange: (value: string) => void;
  onSortOrderChange: (value: SortOrder) => void;
  onClose: () => void;
  isMobile?: boolean;
}

export default function BNOrderSelect({
  data,
  sortOrder,
  dropdownWidth,
  onClick,
  isOpen,
  onClose,
  onSelectionChange,
  selectedItem,
  onSortOrderChange,
  isMobile,
}: BNOrderSelectProps) {
  return (
    <Popover width={dropdownWidth} position="bottom" shadow="md" trapFocus opened={isOpen} onClose={onClose}>
      <Popover.Target>
        <Box>
          <UnstyledButton
            pl={0}
            pr={4}
            className={classes.select}
            style={{ color: isOpen ? 'var(--colors-selectedBorder)' : 'var(--colors-gray-5)', fill: isOpen ? 'var(--colors-selectedBorder)' : 'var(--colors-gray-5)' }}
            onClick={() => {
              if (onClick) {
                onClick();
              }
            }}
          >
            <Group mr={2} gap={1}>
              <Text fz={isMobile ? 13 : 11} maw={isMobile ? 120 : 68} className={classes.selectLabelText}>
                {selectedItem}
              </Text>
              {/* This text should only display if DESC is selected */}
              {sortOrder === 'desc' && <Text fz={10}>(DESC)</Text>}
            </Group>
            <SelectArrowsIcon />
          </UnstyledButton>
        </Box>
      </Popover.Target>
      <Popover.Dropdown p={4} mt={-6}>
        {data.map((item, index) => {
          return (
            <UnstyledButton
              key={index}
              h={isMobile ? 36 : 30}
              fz={isMobile ? 'sm' : 'xs'}
              lh={isMobile ? '1.25em' : '1.1em'}
              onClick={() => {
                if (onSelectionChange) {
                  onSelectionChange(item);
                }
              }}
              className={selectedItem === item ? `${classes.selectOption} ${classes.selectedOption}` : `${classes.selectOption}`}
            >
              {item}
            </UnstyledButton>
          );
        })}
        <Divider color={'var(--colors-divider)'} mx={-4} mt={4} mb={0} />
        <Box p={4} pt={isMobile ? 8 : 4}>
          <SegmentedControl
            size={isMobile ? 'sm' : 'xs'}
            w="100%"
            className={isMobile ? classes.segmentedControlMobile : classes.segmentedControl}
            onChange={(value) => {
              if (onSortOrderChange) {
                onSortOrderChange(value as SortOrder);
              }
            }}
            value={sortOrder}
            data={[
              { label: 'ASC', value: 'asc' },
              { label: 'DESC', value: 'desc' },
            ]}
          />
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
}
