import { IconPropsBase } from './IconPropsBase';

export default function MarketplaceTicketNetworkIcon({ size = 24, color = 'currentColor' }: IconPropsBase) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <path fill={color} d="M5.87,19.93c0,1.07-.87,1.94-1.94,1.94s-1.94-.87-1.94-1.94.87-1.94,1.94-1.94,1.94.87,1.94,1.94h0" />
      <path fill={color} d="M2.97,11.04v3.97c0,.09.07.17.16.18.88.12,5.18.92,5.35,5.38,0,.1.08.17.18.17h4.17c.1,0,.18-.08.18-.18,0,0,0,0,0-.01-.08-1.31-.9-8.92-9.84-9.7-.1,0-.19.07-.19.16,0,0,0,.01,0,.02" />
      <path fill={color} d="M2.97,2.18v2.07c0,.09.07.16.16.16h0c.52,0,.96.38,1.03.89.06.55-.33,1.05-.89,1.11-.04,0-.07,0-.11,0h0c-.1,0-.18.08-.18.18v2.01c0,.09.07.17.16.18,1.43.14,11.13,1.15,11.96,11.79,0,.1.08.18.18.18h1.92c.09,0,.16-.06.18-.14.1-.55.62-.91,1.17-.81.41.08.73.4.81.81.02.08.09.14.18.14h2.3c.1,0,.18-.08.18-.18,0,0,0,0,0,0-.05-1.87-1.05-17.28-18.84-18.57-.1,0-.19.07-.19.17,0,0,0,0,0,.01" />
    </svg>
  );
}
