import { Box, Center, Flex, Skeleton, Text, UnstyledButton } from '@mantine/core';
import classes from './MobileFieldLabel.module.css';
import { MouseEventHandler, ReactNode } from 'react';
import WarningIcon from '../../components/icons/Warning';
import ErrorFilledIcon from '../../components/icons/ErrorFilled';

export type MobileFieldLabelProps = {
  label: string;
  value: string;
  h: number | string;
  w: number | string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  disabled?: boolean;
  error?: ReactNode;
  errorVariant?: 'error' | 'warning';
  borderColor?: string;
  highlightText?: boolean;
  priceUpdateModalOpened?: boolean;
};

export function MobileFieldLabel({
  label,
  value,
  onClick,
  h,
  w,
  isLoading,
  error,
  errorVariant,
  disabled,
  borderColor = 'var(--colors-gray-3)',
  highlightText,
  priceUpdateModalOpened,
}: MobileFieldLabelProps) {
  return (
    <UnstyledButton
      h={h}
      w={w}
      onClick={onClick}
      disabled={disabled}
      className={classes.mobileFieldLabel}
      style={{ borderColor: error ? (errorVariant === 'warning' ? 'var(--colors-yellow-warning)' : 'var(--colors-red-error)') : borderColor }}
    >
      <Text size="xs" c={error ? (errorVariant === 'warning' ? 'var(--colors-yellow-warning)' : 'var(--colors-red-error)') : 'var(--colors-gray-5)'} truncate>
        {error ? (
          <Flex component="span" align="center" pr="xs" gap={2}>
            <Text component="span" truncate>
              {label}
            </Text>
            <Center component="span" style={{ flexShrink: 0 }}>
              {errorVariant === 'warning' ? <WarningIcon size={18} /> : <ErrorFilledIcon size={18} />}
            </Center>
          </Flex>
        ) : (
          label
        )}
      </Text>
      {isLoading ? (
        <Skeleton w={40} h={8} />
      ) : (
        <Text component="div" size="sm" mih={20} fw={600} pr={1} style={{ display: 'flex' }}>
          {highlightText ? (
            <Box
              bg="var(--colors-selectedBg)"
              pl={4}
              pr={3}
              mr={-4}
              style={{ borderLeft: '1px solid var(--colors-selectedBorder)', borderRight: '1px solid var(--colors-selectedBorder)' }}
            >
              {value}
            </Box>
          ) : (
            `${value}`
          )}
          {label === 'List Price' && priceUpdateModalOpened && !highlightText && <span className="price-cursor">|</span>}
        </Text>
      )}
    </UnstyledButton>
  );
}
