import { atom, getDefaultStore } from 'jotai';
import { deepEqual } from 'fast-equals';
import { v4 as uuid } from 'uuid';
import { selectAtom } from 'jotai/utils';
import { focusAtom } from 'jotai-optics';
import {
  BarkerCoreEnumsMarketplace,
  BarkerCoreEnumsPricerStatus,
  BarkerCoreModelsInventoryDtiSplitRules,
  BarkerCoreModelsInventoryEvent,
  BarkerCoreModelsInventoryGetInventoryResponse,
  BarkerCoreModelsInventoryListing,
  BarkerCoreModelsMarketplacesListing,
  GetApiInventoryParams,
  getGetApiPricingRulesRuleIdQueryOptions,
} from '../api';
import {
  BarkerCoreModelsInventoryGetInventoryResponseExtended,
  BarkerEventListing,
  FilterMode,
  GlobalTransientState,
  ListingUpdate,
  MarketplaceEventExtended,
  MarketplacesListingWithAlias,
  Rule,
} from '../types';
import { SetStateAction } from 'react';
import { weakEquals } from '../utils/weakEquals';
import { calculateTargetPrice, getRowComparisonValue, getSplitsValue } from '../utils/rule-utils';
import { ApplyFilters, getOutlierListings, getOutlierPriceThreshold, GetTargetComparablePrice, GetTargetComparables } from '../hoc/MarketListings/marketListingsFilter';
import { DEFAULT_FILTERS, DEFAULT_RULE, DEFAULT_RULE_SETTINGS } from './constants';
import { convertSplitsModelToPointOfSaleModel, getPointOfSaleSplitType } from '../components/Splits/Splits.utils';
import { TenantIdEventId, TenantIdListingId } from '../models/tenantIdListingId';
import { StringWithPipe } from '../ts-utils';
import dayjs from 'dayjs';
import { auth } from './atoms.auth';
import { atomWithQuery } from 'jotai-tanstack-query';
import { Sale } from '../hoc/Sales/Sales.types';

const store = getDefaultStore();
window.atomStore = store;
window.atomStoreGetValue = (label: string) => (store.dev_get_mounted_atoms ? store.get([...store.dev_get_mounted_atoms()].find((x) => x.debugLabel === label)!) : null);

export namespace pricing {
  //TODO: Begin moving all the atoms into pricing namespace to avoid collisions and poluting global namespace
}

export const searchParamsHistoryAtom = atom<GetApiInventoryParams | null>(null);
export const selectedSearchEventsAtom = atom<BarkerCoreModelsInventoryEvent[]>([]);
export const hasSearchResultsAtom = atom<boolean>(false);
const _searchResultsAtom = atom<BarkerCoreModelsInventoryGetInventoryResponseExtended | null>(null);
export const searchResultsAtom = atom(
  (get) => get(_searchResultsAtom),
  (get, set, value: BarkerCoreModelsInventoryGetInventoryResponseExtended | null) => {
    const selectedListing = get(selectedMergedListingAtom);
    const found = value?.listings.find((x) => x.tenantId === selectedListing?.tenantId && x.listingId === selectedListing?.listingId);
    if (!found) {
      set(selectedTenantListingIdAtom, null);
    }
    set(_searchResultsAtom, value);
  },
);

export const rowDraggingAtom = atom(false);
export const initialPageLoadAtom = atom(true);

const _showBulkOptionsAtom = atom(false);
export const showBulkOptionsAtom = atom(
  (get) => get(_showBulkOptionsAtom),
  (get, set, show: boolean | SetStateAction<boolean>) => {
    const value = typeof show === 'function' ? show(get(_showBulkOptionsAtom)) : show;
    set(_showBulkOptionsAtom, value);
    if (!value) {
      set(_markListingsAtom, []);
    }
  },
);

const _markListingsAtom = atom<string[]>([]);
export const markListingsAtom = atom(
  (get) => get(_markListingsAtom),
  (get, set, tenantIdlistingId: string | string[] | SetStateAction<string[]>) => {
    if (Array.isArray(tenantIdlistingId)) {
      set(_markListingsAtom, tenantIdlistingId);
      set(
        _markListingsAtom,
        tenantIdlistingId.filter((l) => !l.endsWith('sales')),
      );
    } else if (typeof tenantIdlistingId === 'string') {
      const exists = get(_markListingsAtom).includes(tenantIdlistingId);
      if (exists) {
        set(_markListingsAtom, [...get(_markListingsAtom).filter((x) => x !== tenantIdlistingId)]);
      } else {
        set(_markListingsAtom, [...new Set([...get(_markListingsAtom), tenantIdlistingId])]);
      }
    } else if (typeof tenantIdlistingId === 'function') {
      set(_markListingsAtom, [...new Set([...tenantIdlistingId(get(_markListingsAtom).filter((l) => !l.endsWith('sales')))])]);
    }
  },
);

export const filterOutListingsAtom = atom<string[]>([]);

export const mergedEventListingsUnfilteredRulesAtom = atom<BarkerEventListing[]>((get) => {
  const search = get(searchResultsAtom);
  const eventsMap = new Map(search?.events.map((event) => [TenantIdEventId.create(event.tenantId, event.eventId).toString(), event]));
  const filterOutListings = get(filterOutListingsAtom);
  const selectedSearchEvent = get(selectedSearchEventsAtom);

  const listings =
    search?.listings
      .filter((listing) => selectedSearchEvent.some((event) => event.tenantId === listing.tenantId && event.eventId === listing.eventId))
      // .filter((listing) => (selectedSearchEvent.length ? selectedSearchEvent.some((event) => event.tenantId === listing.tenantId && event.eventId === listing.eventId) : true))
      .map((listing) => {
        const event = eventsMap.get(TenantIdEventId.create(listing.tenantId, listing.eventId).toString())!;
        const count = listing.ruleId ? search?.listings.filter((l) => l.ruleId === listing.ruleId).length : 0;
        return {
          ...listing,
          event,
          ruleCount: count,
          tenantIdEventId: TenantIdEventId.create(listing.tenantId, listing.eventId).toStringTyped(),
          tenantIdListingId: TenantIdListingId.create(listing.tenantId, listing.listingId).toStringTyped(),
          tags: listing.tags ?? [],
        } satisfies BarkerEventListing;
      })
      .filter((x) => !filterOutListings.includes(x.tenantIdListingId)) || [];

  // Add fake listing for each uniqie tenantId+eventId combination
  const fakeListings = Array.from(eventsMap)
    .map(
      ([, event]) =>
        ({
          eventId: event.eventId,
          event,
          tenantId: event.tenantId,
          ruleId: null,
          listingId: `${event.eventId}|sales`,
          tenantIdEventId: TenantIdEventId.create(event.tenantId, event.eventId).toStringTyped(),
          tenantIdListingId: `${event.tenantId}|${event.eventId}|sales` as StringWithPipe,
          isBroadcasting: false,
          pricerStatusId: 'None',
          quantityRemaining: 0,
          row: 'Sales',
          section: 'Sales',
          seatFrom: '0',
          seatThru: '0',
          unitCost: 0,
          ruleCount: 0,
          tags: [],
          unitPrice: 0,
          quantityReserved: 0,
          seatingType: 'Unknown',
          ticketStockId: 'Unknown',
          numBarcodes: 0,
          unitFace: 0,
          cachedAt: new Date(2001, 0, 1),
        }) satisfies BarkerEventListing,
    )
    .filter((x) => selectedSearchEvent.some((y) => y.tenantId === x.tenantId && y.eventId === x.eventId));
  // .filter((x) => (selectedSearchEvent.length ? selectedSearchEvent.some((y) => y.tenantId === x.tenantId && y.eventId === x.eventId) : true));
  listings.push(...fakeListings);

  return listings;
});

export const mergedEventListingsAtom = atom<BarkerEventListing[]>((get) => {
  const listingsWithLowestRuleTier = new Set(
    get(mergedEventListingsUnfilteredRulesAtom)
      .filter((x) => x.ruleId)
      .sort((a, b) => {
        if (a.ruleId === b.ruleId) {
          return (a.ruleTier || 0) - (b.ruleTier || 0);
        }
        return a.ruleId!.localeCompare(b.ruleId!);
      })
      .reduce((acc, cur) => {
        if (!acc.find((x) => x.ruleId === cur.ruleId)) {
          acc.push(cur);
        }
        return acc;
      }, [] as BarkerEventListing[])
      .map((x) => x.tenantIdListingId),
  );
  return get(mergedEventListingsUnfilteredRulesAtom).filter((x) => x.ruleId === null || listingsWithLowestRuleTier.has(x.tenantIdListingId));
});

export const updateEventAtom = atom(null, (get, set, event: BarkerCoreModelsInventoryEvent) => {
  const searchResults = structuredClone(get(searchResultsAtom));
  const index = searchResults?.events.findIndex((x) => x.eventId === event.eventId && x.tenantId === event.tenantId) ?? -1;
  if (index > -1) {
    searchResults!.events[index] = event;
    // Hack to keep scrolling position when updating event from comments.
    set(transientGlobalStateAtom, (s) => ({ ...s, ignoreGridScrolling: true }));
    set(searchResultsAtom, searchResults);
  }
});

export const updateEventListingsAtom = atom(
  null,
  (
    get,
    set,
    data: BarkerCoreModelsInventoryGetInventoryResponse & {
      tenantId: string;
      eventId: string;
    },
  ) => {
    const searchResults = get(searchResultsAtom) ?? { events: [], listings: [] };
    const { tenantId, eventId } = data;
    if (eventId) {
      if (data.events.length !== 0) {
        const event = data.events[0];
        const previousEventListings = searchResults?.listings.filter((x) => x.tenantId === tenantId && x.eventId === eventId) || [];
        const newListings = data.listings.map((x) => ({
          ...x,
          meta: { newRow: !previousEventListings.find((y) => y.tenantId === x.tenantId && y.listingId === x.listingId) },
        }));
        const filteredOutNewListingsFromExisting = searchResults?.listings.filter((x) => !(x.tenantId === tenantId && x.eventId === eventId)) || [];
        const mergedNewListings = [...filteredOutNewListingsFromExisting, ...newListings];

        const events = structuredClone(searchResults?.events.filter((e) => !(e.tenantId === tenantId && e.eventId === eventId))) || [];
        events.push(event);

        set(searchResultsAtom, {
          events,
          listings: mergedNewListings,
        });
      } else if (data.events.length === 0) {
        const listingsWithoutEventId = searchResults?.listings.filter((x) => x.eventId !== eventId) || [];
        searchResults!.listings = listingsWithoutEventId;

        const eventIndex = searchResults?.events.findIndex((x) => eventId === x.eventId);
        if (eventIndex && eventIndex > -1) {
          searchResults!.events[eventIndex] = {
            ...searchResults!.events[eventIndex],
            openListings: 0,
            openTickets: 0,
          };
        }
      }
    }
  },
);

function updateListing(listing: BarkerCoreModelsInventoryListing, index: number, listings: BarkerCoreModelsInventoryListing[], events: BarkerCoreModelsInventoryEvent[]) {
  const oldListing = listings[index];
  const qtyDelta = listing.quantityRemaining - oldListing.quantityRemaining;
  const costDelta = listing.quantityRemaining * listing.unitCost - oldListing.unitCost * oldListing.quantityRemaining;
  // eslint-disable-next-line no-param-reassign
  listings[index] = listing;
  const eventIndex = events.findIndex((x) => x.eventId === listing.eventId);
  if (eventIndex > -1) {
    const event = events[eventIndex];
    // eslint-disable-next-line no-param-reassign
    events[eventIndex] = {
      ...event,
      openTickets: event.openTickets + qtyDelta,
      openCost: event.openCost + costDelta,
    };
  }
}

function addListing(listing: BarkerCoreModelsInventoryListing, listings: BarkerCoreModelsInventoryListing[], events: BarkerCoreModelsInventoryEvent[]) {
  listings.push(listing);
  const eventIndex = events.findIndex((x) => x.eventId === listing.eventId);
  //NOTE:  Event may not be in list anymore.
  if (eventIndex > -1) {
    const event = events[eventIndex];
    // eslint-disable-next-line no-param-reassign
    const newEvent = {
      ...event,
      openListings: event.openListings + 1,
      openTickets: event.openTickets + listing.quantityRemaining,
      openCost: event.openCost + listing.unitCost * listing.quantityRemaining,
    };

    // eslint-disable-next-line no-param-reassign
    events[eventIndex] = newEvent;
  }
}

function removeListing(listing: BarkerCoreModelsInventoryListing, listings: BarkerCoreModelsInventoryListing[], events: BarkerCoreModelsInventoryEvent[]) {
  const index = listings.findIndex((x) => x.tenantId === listing.tenantId && x.listingId === listing.listingId);
  if (index > -1) {
    const eventIndex = events.findIndex((x) => x.eventId === listing.eventId);
    if (eventIndex > -1) {
      const event = events[eventIndex];
      // eslint-disable-next-line no-param-reassign
      events[eventIndex] = {
        ...event,
        openListings: event.openListings - 1,
        openTickets: event.openTickets - listing.quantityRemaining,
        openCost: event.openCost - listing.unitCost * listing.quantityRemaining,
      };
      listings.splice(index, 1);
    }
  }
}

export const updateListingsAtom = atom(
  null,
  (
    get,
    set,
    listing:
      | (BarkerCoreModelsInventoryListing & { meta?: Record<'isLocalRule', unknown> })
      | BarkerCoreModelsInventoryListing[]
      | { listingsToUpdate: BarkerCoreModelsInventoryListing[]; listingsToRemove?: TenantIdListingId[]; newSelectedListingId?: StringWithPipe },
  ) => {
    const data = structuredClone(get(searchResultsAtom));
    if (data) {
      if (Array.isArray(listing)) {
        listing.forEach((l) => {
          const index = data.listings.findIndex((x) => x.tenantId === l.tenantId && x.listingId === l.listingId);
          if (index > -1) {
            updateListing(l, index, data.listings, data.events);
          } else {
            addListing(l, data.listings, data.events);
          }
        });
        set(searchResultsAtom, { events: data.events, listings: data.listings });
      } else if ('listingsToUpdate' in listing) {
        if ((listing.listingsToRemove?.length ?? 0) > 0) {
          const listingsToRemove = data.listings.filter((l) => listing.listingsToRemove!.some((r) => r.tenantId === l.tenantId && r.listingId === l.listingId));
          listingsToRemove.forEach((l) => {
            removeListing(l, data.listings, data.events);
          });
        }
        listing.listingsToUpdate.forEach((l) => {
          const index = data.listings.findIndex((x) => x.tenantId === l.tenantId && x.listingId === l.listingId);
          if (index > -1) {
            updateListing(l, index, data.listings, data.events);
          } else {
            addListing(l, data.listings, data.events);
          }
        });
        set(searchResultsAtom, { events: data.events, listings: data.listings });
        if (listing.newSelectedListingId) {
          set(selectedTenantListingIdAtom, listing.newSelectedListingId);
        }
      } else {
        const index = data.listings.findIndex((l) => l.tenantId === listing.tenantId && l.listingId === listing.listingId);
        if (index > -1) {
          updateListing(listing, index, data.listings, data.events);
        } else {
          addListing(listing, data.listings, data.events);
        }
        set(searchResultsAtom, { events: data.events, listings: data.listings });
      }
    }
  },
);

export const showIgnoredListingsAtom = atom(false);
export const showOwnListingsAtom = atom(false);
export const showOutliersAtom = atom(false);
export const autoUpdateRuleOnTargetComparableChangesAtom = atom(false);

export const targetComparableFloorPriceRuleAtom = atom((get) => {
  const auto = get(autoUpdateRuleOnTargetComparableChangesAtom);
  const rule = get(ruleStateAtom);
  const selectedListing = get(selectedMergedListingAtom);
  const minFloorPrice = get(auth.tenantsAtom).filter((t) => t.tenantId === selectedListing?.tenantId)[0]?.settings?.pricerSettings?.minimumFloorPrice ?? 0;
  if (auto && rule.automationTypeId !== 'SchedulePrice') {
    const settings = get(auth.principalAtom)?.settings;
    const floorMultiplier = settings?.pricerSettings?.floorMultiplier || 0;
    const ceilingMultiplier = settings?.pricerSettings?.ceilingMultiplier || 0;
    const floorCostMultiplier = settings?.pricerSettings?.floorCostMultiplier || 0;
    const ceilingCostMultiplier = settings?.pricerSettings?.ceilingCostMultiplier || 0;
    const targetComparables = get(targetComparablesAtom);
    const costBasedFloor = (selectedListing?.unitCost ?? 0) * floorCostMultiplier;
    const costBasedCeiling = (selectedListing?.unitCost ?? 0) * ceilingCostMultiplier;
    let compBasedFloor = 0;
    let compBasedCeiling = 0;

    if (targetComparables && targetComparables.length > 0) {
      const targetComparablePrice = GetTargetComparablePrice(targetComparables);
      if (targetComparablePrice && floorMultiplier) {
        compBasedFloor = targetComparablePrice * floorMultiplier;
      }

      if (targetComparablePrice && ceilingMultiplier) {
        compBasedCeiling = targetComparablePrice * ceilingMultiplier;
      }
    }

    let calculatedFloor = Math.max(costBasedFloor, compBasedFloor);
    const calculatedCeiling = Math.max(costBasedCeiling, compBasedCeiling);

    if (calculatedFloor > 0 && minFloorPrice && minFloorPrice > calculatedFloor) {
      calculatedFloor = minFloorPrice;
    }

    if (calculatedFloor === 0) {
      rule.floorPrice = undefined;
    } else {
      rule.floorPrice = calculatedFloor;
    }

    if (calculatedCeiling === 0) {
      rule.ceilingPrice = undefined;
    } else {
      rule.ceilingPrice = calculatedCeiling;
    }
    return rule;
  }
  return rule;
});

export const marketplaceListingsFilteredAtom = atom((get) => {
  const selectedMarketplaceEvent = get(selectedMarketplaceEventAtom);
  const seatingChartFilters = get(seatingChartFiltersAtom);
  const listings = get(marketplaceListingsAtom);
  const showOwnListings = get(showOwnListingsAtom);
  const showIgnoredListings = get(showIgnoredListingsAtom);
  const showOutliers = get(showOutliersAtom);
  const filtered = ApplyFilters(listings, seatingChartFilters, showOwnListings, showIgnoredListings, showOutliers).map((listing) => {
    const section = selectedMarketplaceEvent.sections?.find((s) => s.sections?.map((x) => x.toLowerCase()).includes(listing.sectionId!.toLowerCase()));
    if (!section) {
      return listing;
    }

    return {
      ...listing,
      sectionAlias: selectedMarketplaceEvent?.marketplaceId === 'Ticketmaster' ? listing.section.toUpperCase() : undefined,
    } satisfies MarketplacesListingWithAlias;
  }) as MarketplacesListingWithAlias[];

  return filtered;
});

export const marketplaceOutlierThresholdAtom = atom((get) => {
  const filters = get(seatingChartFiltersAtom);
  const rowData = get(marketplaceListingsFilteredAtom);

  if (deepEqual(filters, DEFAULT_FILTERS)) {
    return null;
  }

  if (filters.outlierCriteria === undefined) {
    return null;
  }

  // Don't include own listings in outlier calculation
  const notOurListings = rowData.filter((listing) => !listing.isOwnListing);

  return getOutlierPriceThreshold(notOurListings, filters.outlierCriteria);
});

export const marketplaceListingsOutliersAtom = atom((get) => {
  const filters = get(seatingChartFiltersAtom);
  const rowData = get(marketplaceListingsFilteredAtom);

  if (deepEqual(filters, DEFAULT_FILTERS)) {
    return [];
  }

  if (filters.outlierCriteria === undefined) {
    return [];
  }

  return getOutlierListings(rowData, filters.outlierCriteria);
});

export const marketplaceTargetComparablesAtom = atom((get) => {
  const filters = get(seatingChartFiltersAtom);
  let rowData = get(marketplaceListingsFilteredAtom);
  const { isAutoPriced, numComparables } = get(ruleStateAtom);

  if (!isAutoPriced) {
    return null;
  }

  if (deepEqual(filters, DEFAULT_FILTERS)) {
    return null;
  }

  // Exclude own listings
  rowData = rowData.filter((listing) => !listing.isOwnListing);

  // If there are no primary filters then return nothing
  if (!((filters?.sectionIds && filters?.sectionIds?.length > 0) || filters?.rows !== '' || (filters?.splits && filters?.splits?.length > 0))) {
    return null;
  }

  if (filters.outlierCriteria) {
    const outliers = get(marketplaceListingsOutliersAtom);
    rowData = rowData.filter((listing) => !outliers.includes(listing));
  }

  if (filters.ignoredListingIds && filters.ignoredListingIds?.length > 0) {
    const filtered = rowData.filter((listing) => !filters.ignoredListingIds?.includes(listing.listingId));
    const target = GetTargetComparables(filtered, numComparables ?? 1) ?? null;
    return target;
  }

  const target = GetTargetComparables(rowData, numComparables ?? 1) ?? null;
  return target;
});

export const targetComparablesAtom = atom((get) => get(marketplaceTargetComparablesAtom));

export const marketplaceListingsAtom = atom<BarkerCoreModelsMarketplacesListing[]>([]);

const _selectedMarketplaceIdAtom = atom<BarkerCoreEnumsMarketplace | undefined>(undefined);
export const selectedMarketplaceIdAtom = atom(
  (get) => get(_selectedMarketplaceIdAtom),
  (get, set, marketplace: BarkerCoreEnumsMarketplace) => {
    const rule = get(ruleStateAtom);
    if (rule && rule.marketplaceId !== marketplace) {
      set(ruleStateAtom, {
        ...rule,
        marketplaceId: marketplace,
        filters: { ...rule.filters, sectionIds: [] },
      });
    }
    set(_selectedMarketplaceIdAtom, marketplace);
  },
);

export const selectedMarketplaceEventAtom = atom<MarketplaceEventExtended>({
  marketplaceEventId: null,
  marketplaceId: null,
  svgDetails: null,
  marketplaceEvent: null,
  isUserMapped: false,
  metadata: null,
  sections: [],
});

export const showEventMappingAtom = atom<boolean>(false);

const _showEventPerformanceAtom = atom<boolean>(false);
export const showEventPerformanceAtom = atom(
  (get) => get(_showEventPerformanceAtom),
  (get, set, value: boolean) => {
    set(_showEventPerformanceAtom, value);
  },
);

export const selectedSaleAtom = atom<Sale | undefined>(undefined);

export const selectedMergedListingAtom = atom((get) => {
  const { tenantId, listingId } = get(selectedTenantListingIdAtom);
  const inventory = get(mergedEventListingsAtom);
  const inventoryListing = inventory?.find((listing) => listing.tenantId === tenantId && listing.listingId === listingId);
  return inventoryListing;
});

export const selectedSectionAtom = atom<string | undefined>(undefined);
export const selectedListingRuleIdAtom = atom<string | undefined>(undefined);

export const selectedMergedListingLastRuleTierAtom = atom((get) => {
  const selected = get(selectedMergedListingAtom);
  const inventory = get(mergedEventListingsUnfilteredRulesAtom);
  const listingsMatchingRuleId = inventory?.filter((l) => l.ruleId === selected?.ruleId);

  // Return largest tier
  return listingsMatchingRuleId?.reduce((prev, listing) => (prev > (listing.ruleTier || 0) ? prev : listing.ruleTier || 0), 0);
});

export const listingsMatchingSelectedRuleIdAtom = atom((get) => {
  const selected = get(selectedMergedListingAtom);
  const inventory = get(mergedEventListingsUnfilteredRulesAtom);
  const listingsMatchingRuleId = inventory?.filter((l) => l.ruleId === selected?.ruleId);
  return listingsMatchingRuleId;
});

export const selectedMergedListingPriceAtom = atom((get) => get(selectedMergedListingAtom)?.unitPrice);

const _selectedTenantListingIdAtom = atom<StringWithPipe | null | undefined>(null);
export const previousSelectedTenantListingIdAtom = atom<StringWithPipe | null | undefined>(null);

export const selectedTenantListingIdAtom = atom(
  (get) => new TenantIdListingId(get(_selectedTenantListingIdAtom)),
  (get, set, input: StringWithPipe | null | undefined, options: { keepRule: boolean } = { keepRule: false }) => {
    const current = get(_selectedTenantListingIdAtom);
    if (current !== input) {
      const inventory = get(mergedEventListingsAtom);
      const [tenantId, listingId] = input?.split('|') || [];
      const inventoryListing = inventory?.find((listing) => listing.tenantId === tenantId && listing.listingId === listingId);
      if (current === null) {
        const currentEventId = get(_selectedEventAtom)?.eventId;
        if (currentEventId) {
          set(ruleFiltersByEventIdAtom, (s) => ({ ...s, [currentEventId]: get(ruleStateAtom)?.filters }));
        }
      }
      set(previousSelectedTenantListingIdAtom, current);
      set(_selectedTenantListingIdAtom, input);
      set(selectedEventAtom, inventoryListing?.event);
      set(selectedSectionAtom, inventoryListing?.section);
      set(initialPageLoadAtom, false);
      if (!inventoryListing?.ruleId && !options.keepRule) {
        // Note: This is when we restart rule if there isn't one. First entry point to mix with principal defaults
        set(ruleStateAtom, null);
      }
    }

    if (input !== null) {
      if (get(showEventPerformanceAtom)) {
        set(showEventPerformanceAtom, false);
      }
    }
  },
);

const _selectedEventAtom = atom<BarkerCoreModelsInventoryEvent | null | undefined>(null);
export const previousSelectedEventAtom = atom<BarkerCoreModelsInventoryEvent | null | undefined>(null);

export const selectedEventAtom = atom(
  (get) => get(_selectedEventAtom),
  (get, set, event: BarkerCoreModelsInventoryEvent | null | undefined) => {
    const currentEvent = get(_selectedEventAtom);
    if ((currentEvent?.tenantId ?? '') + (currentEvent?.eventId ?? '') !== (event?.tenantId ?? '') + (event?.eventId ?? '')) {
      set(previousSelectedEventAtom, currentEvent);
    }
    set(_selectedEventAtom, event);
    const filterMode = get(filterModeAtom);
    if (filterMode === FilterMode.Locked && event?.eventId && !get(selectedTenantListingIdAtom).hasValue()) {
      const lockedEventRuleFilters = get(ruleFiltersByEventIdAtom)[event.eventId];
      if (lockedEventRuleFilters) {
        set(ruleStateAtom, { ...DEFAULT_RULE, ruleId: uuid(), filters: lockedEventRuleFilters, autoAdjustSplits: false });
      }
    }
  },
);

export const pendingListingUpdatesAtom = atom<ListingUpdate[]>([]);

export const pendingListingUpdatesByListingIdAtom = atom(
  (get) => {
    const pending = get(pendingListingUpdatesAtom);
    const groupedUpdates = pending.reduce(
      (acc, update) => {
        if (acc[update.listingId]) {
          acc[update.listingId].push(update);
        } else {
          acc[update.listingId] = [update];
        }
        return acc;
      },
      {} as { [key: string]: ListingUpdate[] },
    );
    return groupedUpdates;
  },
  (get, set, update: ListingUpdate) => {
    const { listingId, property } = update;
    const pending = get(pendingListingUpdatesAtom);
    const filtered = pending.filter((x) => !(x.listingId === listingId && x.property === property));
    filtered.push(update);
    set(pendingListingUpdatesAtom, filtered);
  },
);

export const applyRuleToSelectedGroupedListingsAtom = atom(null, (get, set, listPrice?: number) => {
  const selectedListing = get(selectedMergedListingAtom);
  const ruleState = get(ruleStateAtom);
  const cleanRuleState = get(ruleStateInitialValueAtom);
  const { numActive, isAutoPriced, automationTypeId } = ruleState;
  const tenants = get(auth.tenantsAtom);
  const roundingSettings = tenants.filter((t) => t.tenantId === selectedListing?.tenantId)[0]?.settings?.pricerSettings?.roundPrices ?? 'None';

  if (!selectedListing || (!isAutoPriced && automationTypeId !== 'SchedulePrice') || selectedListing.ruleId !== ruleState.ruleId) {
    return;
  }

  const listingWithMatchingRuleId = get(mergedEventListingsUnfilteredRulesAtom)
    ?.filter((listing) => selectedListing.ruleId != null && selectedListing.ruleId === listing.ruleId)
    .sort((a, b) => a.ruleTier! - b.ruleTier!);

  set(transientGlobalStateAtom, (s) => ({ ...s, applyGroupListingRule: false }));

  let anchorPrice = automationTypeId === 'SchedulePrice' && listPrice ? listPrice : 0;
  listingWithMatchingRuleId?.forEach((listing, i) => {
    const price = calculateTargetPrice({
      listing,
      offset: i,
      anchorPrice,
      rule: ruleState,
      targetComparables: get(targetComparablesAtom),
      tenantRoundingSettings: roundingSettings,
    });
    if (i === 0) {
      anchorPrice = price;
    }

    if (!get(filtersEmptyAtom) && (ruleState.floorPrice || 0) > 0) {
      if (price !== listing.unitPrice) {
        const listingUpdate: ListingUpdate = {
          listingId: listing.listingId,
          tenantId: listing.tenantId,
          property: 'unitPrice',
          value: price,
          previousValue: listing.unitPrice,
        };
        set(pendingListingUpdatesByListingIdAtom, listingUpdate);
      } else {
        const filtered = get(pendingListingUpdatesAtom).filter((x) => !(x.listingId === listing.listingId && x.property === 'unitPrice'));
        set(pendingListingUpdatesAtom, filtered);
      }
    }

    if (listing.pricerStatusId === 'None') {
      let pricerStatusId: BarkerCoreEnumsPricerStatus = 'None';
      if (ruleState.isAutoPriced) {
        if (ruleState.floorPrice && price <= ruleState.floorPrice) {
          pricerStatusId = 'AtFloor';
        }
        if (ruleState.ceilingPrice && price >= ruleState.ceilingPrice && i === 0) {
          pricerStatusId = 'AtCeiling';
        }
        pricerStatusId = 'AutoPriced';
      } else if (ruleState.automationTypeId === 'SchedulePrice') {
        if (ruleState.floorPrice && price <= ruleState.floorPrice) {
          pricerStatusId = 'AtFloor';
        }
        if (ruleState.ceilingPrice && price >= ruleState.ceilingPrice && i === 0) {
          pricerStatusId = 'AtCeiling';
        }
        pricerStatusId = 'Scheduled';
      }
      const listingUpdate: ListingUpdate = {
        listingId: listing.listingId,
        tenantId: listing.tenantId,
        property: 'pricerStatusId',
        value: pricerStatusId,
        previousValue: listing.pricerStatusId,
      };
      set(pendingListingUpdatesByListingIdAtom, listingUpdate);
    }

    const principalPricerSettings = get(auth.principalAtom)?.settings?.pricerSettings;

    if (numActive != null && listingWithMatchingRuleId.length > 1) {
      const active = i < numActive;
      if (active && !listing.splits) {
        const tenantPointOfSale = get(auth.tenantsAtom).find((t) => t.tenantId === listing?.event?.tenantId)?.pointOfSaleId;
        if (tenantPointOfSale) {
          const splitUpdate = convertSplitsModelToPointOfSaleModel(tenantPointOfSale, getPointOfSaleSplitType(tenantPointOfSale, listing?.splits));
          if (splitUpdate) {
            const pendingSplitUpdate: ListingUpdate = {
              listingId: listing.listingId,
              tenantId: listing.tenantId,
              property: 'splits',
              value: splitUpdate,
              previousValue: listing.splits,
            };
            set(pendingListingUpdatesByListingIdAtom, pendingSplitUpdate);
          }
        }
      } else {
        const filtered = get(pendingListingUpdatesAtom).filter((x) => !(x.listingId === listing.listingId && x.property === 'splits'));
        set(pendingListingUpdatesAtom, filtered);
      }
    }

    if (
      numActive != null &&
      (listingWithMatchingRuleId.length > 1 ||
        (!cleanRuleState?.isAutoPriced && ruleState.isAutoPriced && (principalPricerSettings?.broadcastSingleListing ?? DEFAULT_RULE_SETTINGS.broadcastSingleListing)))
    ) {
      const active = i < numActive;
      if (listing.isBroadcasting !== active) {
        const activeUpdate: ListingUpdate = {
          tenantId: listing.tenantId,
          listingId: listing.listingId,
          property: 'isBroadcasting',
          value: active,
          previousValue: listing.isBroadcasting,
        };

        set(pendingListingUpdatesByListingIdAtom, activeUpdate);

        const pointOfSaleId = tenants.find((t) => t.tenantId === listing.tenantId)?.pointOfSaleId;

        if (pointOfSaleId === 'DtiPortal' && (listing.splits as BarkerCoreModelsInventoryDtiSplitRules).splitType.toLowerCase() === 'none') {
          const splitsUpdate: ListingUpdate = {
            tenantId: listing.tenantId,
            listingId: listing.listingId,
            property: 'splits',
            value: { splitType: 'DontLeaveOne' },
            previousValue: listing.splits,
          };

          set(pendingListingUpdatesByListingIdAtom, splitsUpdate);
        }
      } else {
        const filtered = get(pendingListingUpdatesAtom).filter((x) => !(x.listingId === listing.listingId && x.property === 'isBroadcasting'));
        set(pendingListingUpdatesAtom, filtered);
      }
    }
  });
});

export const rejectAllPendingUpdatesAtom = atom(null, (get, set, listingIds?: string | string[]) => {
  const pendingListingUpdates = get(pendingListingUpdatesAtom);
  const searchResults = get(searchResultsAtom);
  if (pendingListingUpdates.length > 0) {
    const groupedUpdates = pendingListingUpdates
      .filter((x) => (listingIds ? (Array.isArray(listingIds) ? listingIds.includes(x.listingId) : x.listingId === listingIds) : true))
      .reduce(
        (acc, update) => {
          if (acc[update.listingId]) {
            acc[update.listingId].push(update);
          } else {
            acc[update.listingId] = [update];
          }
          return acc;
        },
        {} as { [key: string]: ListingUpdate[] },
      );
    const updatedListings = Object.keys(groupedUpdates).map((_listingId) => {
      const updates = groupedUpdates[_listingId].reverse();
      const listing = structuredClone(searchResults?.listings.find((l) => l.listingId === _listingId));
      if (listing) {
        updates.forEach((update) => {
          (listing[update.property] as unknown) = update.previousValue;
        });
      }
      return listing;
    });
    set(updateListingsAtom, updatedListings.filter(Boolean));
    set(
      pendingListingUpdatesAtom,
      pendingListingUpdates.filter((x) => (listingIds ? (Array.isArray(listingIds) ? !listingIds.includes(x.listingId) : x.listingId !== listingIds) : false)),
    );
  }
});

export const rejectPendingUpdatesByListingIdAtom = atom(null, (get, set, listingId: string) => {
  const pendingListingUpdates = get(selectAtom(pendingListingUpdatesByListingIdAtom, (s) => s[listingId]));
  const searchResults = get(searchResultsAtom);
  const listing = searchResults?.listings.find((l) => l.listingId === listingId);
  if (pendingListingUpdates?.length > 0 && listing) {
    const updates = pendingListingUpdates.filter((x) => x.listingId === listingId);
    updates.forEach((update) => {
      // @ts-ignore
      listing[update.property] = update.previousValue;
    });
    set(updateListingsAtom, listing);
    set(pendingListingUpdatesAtom, (prev) => prev.filter((x) => x.listingId !== listingId));
  }
});

export const clearPendingUpdateByPropertyAtom = atom(
  null,
  (
    _,
    set,
    {
      listingId,
      properties,
    }: {
      listingId: string;
      properties: (keyof BarkerCoreModelsInventoryListing)[];
    },
  ) => {
    set(pendingListingUpdatesAtom, (prev) => prev.filter((update) => !(update.listingId === listingId && properties.includes(update.property))));
  },
);

const _eventMarketplaceDefaultsAtom = atom<Record<string, BarkerCoreEnumsMarketplace>>({});
export const eventMarketplaceDefaultsAtom = atom(
  (get) => get(_eventMarketplaceDefaultsAtom),
  (get, set, { marketplaceId, eventId }: { marketplaceId: BarkerCoreEnumsMarketplace; eventId: string }) => {
    const defaults = get(_eventMarketplaceDefaultsAtom);
    set(_eventMarketplaceDefaultsAtom, { ...defaults, [eventId]: marketplaceId });
  },
);

export const localRuleDefaultAtom = atom<Omit<Rule, 'ruleId'>>(() => DEFAULT_RULE);

export const ruleStateQueryAtom = atomWithQuery((get) =>
  getGetApiPricingRulesRuleIdQueryOptions(get(selectedMergedListingAtom)?.ruleId!, {
    query: {
      select(data) {
        return data.data;
      },
      enabled: false,
    },
    axios: {
      headers: {
        'x-tenant-id': get(selectedMergedListingAtom)?.event?.tenantId,
      },
    },
  }),
);

// store.sub(ruleStateQueryAtom, () => {
//   const serverRule = store.get(ruleStateQueryAtom);
//   if (serverRule.data && serverRule.data.ruleId !== '00000000-0000-0000-0000-000000000000') {
//     store.set(ruleStateAtom, serverRule.data!);
//   }
// });

export const filterModeAtom = atom(FilterMode.Standard);

const _PAGE_LOAD_RULE_ID = uuid();

const _ruleStateSharedAtom = atom<{ base: Rule; rule: Rule }>({
  base: { ...DEFAULT_RULE, ruleId: _PAGE_LOAD_RULE_ID },
  rule: { ...DEFAULT_RULE, ruleId: _PAGE_LOAD_RULE_ID },
});
export const ruleStateInitialValueAtom = focusAtom(_ruleStateSharedAtom, (a) => a.prop('base'));
const _ruleStateAtom = focusAtom(_ruleStateSharedAtom, (a) => a.prop('rule'));

export const initiallyAutoPricedAtom = atom((get) => {
  const rule = get(ruleStateInitialValueAtom);
  return !!(rule?.isAutoPriced || rule?.automationTypeId === 'SchedulePrice');
});

export const ruleStateResetAtom = atom(null, (get, set, options?: { retainFilters: boolean }) => {
  if (options && options.retainFilters) {
    const filters = get(_ruleStateAtom)!.filters!;
    const rule = get(ruleStateInitialValueAtom)!;
    set(_ruleStateAtom, { ...rule, filters });
  } else {
    set(_ruleStateAtom, get(ruleStateInitialValueAtom));
  }
});

export const ruleFiltersByEventIdAtom = atom({} as Record<string, Rule['filters']>);

// Used for winventory. Will be set in the querystring
export const forceRuleIdAtom = atom<string | null>(null);

export const ruleStateAtom = atom(
  (get) => get(_ruleStateAtom),
  (
    get,
    set,
    rule: Rule | string | null | SetStateAction<Rule>,
    options?: {
      isLocalRule?: boolean;
      isAutoPriced?: boolean;
      forceUpdate?: boolean;
      currentListingId?: string;
      enableAutoPricing?: 'Auto-Pricing' | 'Scheduled';
    },
  ) => {
    const selectedListing = get(selectedMergedListingAtom);
    const selectedListingRuleId = selectedListing?.ruleId;
    const principalPricerSettings = get(auth.principalAtom)?.settings?.pricerSettings;
    const currentRule = get(ruleStateAtom);
    const availableMarketplaces = get(auth.tenantsAtom).find((x) => x.tenantId === selectedListing?.tenantId)?.marketplaceIds ?? ['SeatGeek'];
    const defaultMarketplace = !availableMarketplaces.includes('SeatGeek') ? availableMarketplaces[0] : 'SeatGeek';
    const tenantPricerSettings = get(auth.tenantsAtom).filter((t) => t.tenantId === selectedListing?.tenantId)[0]?.settings?.pricerSettings;
    if (rule && typeof rule === 'object') {
      if (options && options.forceUpdate && options.currentListingId !== undefined && selectedListing?.tenantIdListingId !== options.currentListingId) {
        // Selected listing changed. Don't update current state
        return;
      }

      const { applyGroupListingRule } = get(transientGlobalStateAtom);

      // TODO: Not sure I need both this and isAutoPricedRuleSettings.
      const partialAutoPricingRuleSettingsDefaults: Partial<Rule> = {
        marketplaceId: principalPricerSettings?.defaultMarketplace ?? defaultMarketplace,
        numActive: principalPricerSettings?.numActive ?? DEFAULT_RULE.numActive,
        numComparables: principalPricerSettings?.numComparables?.[0]?.numComparables ?? DEFAULT_RULE.numComparables,
        staggerByTypeId: principalPricerSettings?.staggerByTypeId ?? DEFAULT_RULE.staggerByTypeId,
        adjustmentTypeId: principalPricerSettings?.adjustmentTypeId ?? DEFAULT_RULE.adjustmentTypeId,
        staggerByValue: principalPricerSettings?.staggerByValue ?? DEFAULT_RULE.staggerByValue,
        adjustmentValue: principalPricerSettings?.adjustmentValue ?? DEFAULT_RULE.adjustmentValue,
      };

      const partialScheduledRuleSettingsDefaults: Partial<Rule> = {
        marketplaceId: principalPricerSettings?.defaultMarketplace ?? defaultMarketplace,
        floorPrice:
          principalPricerSettings?.schedulePricerSettings?.floorMultiplier && selectedListing?.unitCost
            ? selectedListing.unitCost * principalPricerSettings.schedulePricerSettings.floorMultiplier
            : DEFAULT_RULE.floorPrice,
        ceilingPrice:
          principalPricerSettings?.schedulePricerSettings?.ceilingMultiplier && selectedListing?.unitCost
            ? selectedListing.unitCost * principalPricerSettings.schedulePricerSettings.ceilingMultiplier
            : DEFAULT_RULE.ceilingPrice,
        numActive: principalPricerSettings?.schedulePricerSettings?.numActive ?? DEFAULT_RULE.numActive,
        staggerByTypeId: principalPricerSettings?.schedulePricerSettings?.staggerByTypeId ?? DEFAULT_RULE.staggerByTypeId,
        adjustmentTypeId: principalPricerSettings?.schedulePricerSettings?.adjustmentTypeId ?? DEFAULT_RULE.adjustmentTypeId,
        staggerByValue: principalPricerSettings?.schedulePricerSettings?.staggerByValue ?? DEFAULT_RULE.staggerByValue,
        schedulePricerSettings: {
          expiresAt: dayjs(selectedListing?.event.localDateTime).add(1, 'day').toDate(),
          intervalMinutes: principalPricerSettings?.schedulePricerSettings?.intervalMinutes ?? DEFAULT_RULE_SETTINGS.intervalMinutes,
        },
        adjustmentValue: principalPricerSettings?.schedulePricerSettings?.adjustmentValue ?? DEFAULT_RULE.adjustmentValue,
      };

      const isAutoPricedRuleSettings: Partial<Rule> = {
        staggerByTypeId: rule.staggerByTypeId ?? principalPricerSettings?.staggerByTypeId ?? DEFAULT_RULE.staggerByTypeId,
        adjustmentTypeId: rule.adjustmentTypeId ?? principalPricerSettings?.adjustmentTypeId ?? DEFAULT_RULE.adjustmentTypeId,
        marketplaceId: rule.marketplaceId ?? principalPricerSettings?.defaultMarketplace ?? defaultMarketplace,
        adjustmentValue: rule.adjustmentValue ?? principalPricerSettings?.adjustmentValue ?? DEFAULT_RULE.adjustmentValue,
        numActive: rule.numActive ?? principalPricerSettings?.numActive ?? DEFAULT_RULE.numActive,
        staggerByValue: rule.staggerByValue ?? principalPricerSettings?.staggerByValue ?? DEFAULT_RULE.staggerByValue,
        floorPrice: rule.floorPrice
          ? rule.floorPrice
          : principalPricerSettings?.floorMultiplier && selectedListing?.unitCost
            ? selectedListing.unitCost * principalPricerSettings.floorMultiplier
            : DEFAULT_RULE.floorPrice,
        ceilingPrice: rule.ceilingPrice
          ? rule.ceilingPrice
          : principalPricerSettings?.ceilingMultiplier && selectedListing?.unitCost
            ? selectedListing.unitCost * principalPricerSettings.ceilingMultiplier
            : DEFAULT_RULE.ceilingPrice,
        autoAdjustSplits: rule.autoAdjustSplits ?? principalPricerSettings?.autoAdjustSplits ?? DEFAULT_RULE.autoAdjustSplits,
      };

      const newRule = structuredClone({
        ...rule,
        ...(options?.enableAutoPricing === 'Auto-Pricing'
          ? partialAutoPricingRuleSettingsDefaults
          : options?.enableAutoPricing === 'Scheduled'
            ? partialScheduledRuleSettingsDefaults
            : isAutoPricedRuleSettings),
        marketplaceId: rule.marketplaceId ?? defaultMarketplace,
        isAutoPriced: rule.isAutoPriced,
        autoAdjustSplits: rule.autoAdjustSplits ?? DEFAULT_RULE.autoAdjustSplits,
        meta: { ...rule.meta, isLocalRule: options?.isLocalRule !== undefined ? options.isLocalRule : rule.meta?.isLocalRule },
        filters: {
          ...rule.filters,
          exclusions: rule.filters?.exclusions ?? DEFAULT_RULE.filters.exclusions,
          deliveryExclusions: rule.filters?.deliveryExclusions ?? DEFAULT_RULE.filters.deliveryExclusions,
          ignoredListingIds: rule.filters?.ignoredListingIds ?? DEFAULT_RULE.filters.ignoredListingIds,
          maxPrice: rule.filters?.maxPrice ?? DEFAULT_RULE.filters.maxPrice,
          minPrice: rule.filters?.minPrice ?? DEFAULT_RULE.filters.minPrice,
          rows: rule.filters?.rows ?? DEFAULT_RULE.filters.rows,
          sectionIds: rule.filters?.sectionIds ?? DEFAULT_RULE.filters.sectionIds,
          splits: getSplitsValue(
            rule.autoAdjustSplits ?? DEFAULT_RULE.autoAdjustSplits,
            rule.filters?.splits,
            selectedListing?.quantityRemaining,
            selectedListing?.row,
            principalPricerSettings?.quantitySplitMatrix,
            principalPricerSettings?.generalAdmissionSplits,
            tenantPricerSettings?.quantitySplitMatrix,
            tenantPricerSettings?.generalAdmissionSplits,
          ),
          // rule.autoAdjustSplits && companyPricerSettings?.quantitySplitMatrix && selectedListing
          //   ? companyPricerSettings?.quantitySplitMatrix[Math.min(selectedListing.quantityRemaining, 8)]
          //   : rule.filters?.splits ?? DEFAULT_RULE.filters.splits,
        },
      });

      // Fix adjustmentValue in case of Percentage and value being 0
      if (newRule.adjustmentTypeId === 'Percentage' && !newRule.adjustmentValue) {
        newRule.adjustmentValue = 1;
      }

      if (currentRule?.ruleId !== rule.ruleId || (options?.forceUpdate && selectedListingRuleId === rule.ruleId)) {
        set(autoUpdateRuleOnTargetComparableChangesAtom, false);
        set(_ruleStateSharedAtom, {
          base: newRule,
          // rule: newRule,
          rule: {
            ...newRule,
            isAutoPriced: get(transientGlobalStateAtom).autoUpdateAutoPricedListPriceRuleId === rule.ruleId && !rule.automationTypeId ? true : rule.isAutoPriced,
          },
        });
        if (rule.isAutoPriced) {
          set(autoUpdateRuleOnTargetComparableChangesAtom, false);
        }
        set(selectedMarketplaceIdAtom, rule.marketplaceId);
      } else {
        set(_ruleStateAtom, newRule);
      }

      if (applyGroupListingRule) {
        set(applyRuleToSelectedGroupedListingsAtom);
        set(transientGlobalStateAtom, (s) => ({ ...s, applyGroupListingRule: false }));
      }
    } else if (typeof rule === 'function') {
      set(_ruleStateAtom, structuredClone(rule(get(ruleStateAtom)!)));
    } else {
      // Handles string & null conditions
      const eventMarketplace = get(eventMarketplaceDefaultsAtom);
      const newRule = structuredClone({
        ...get(localRuleDefaultAtom),
        ruleId: typeof rule === 'string' ? rule : uuid(),
        isAutoPriced: options?.isAutoPriced || false,
        marketplaceId: eventMarketplace[selectedListing?.eventId ?? ''] ?? principalPricerSettings?.defaultMarketplace ?? defaultMarketplace,
        numActive: principalPricerSettings?.numActive ?? DEFAULT_RULE.numActive,
        numComparables: principalPricerSettings?.numComparables?.[0]?.numComparables ?? DEFAULT_RULE.numComparables,
        staggerByTypeId: principalPricerSettings?.staggerByTypeId ?? DEFAULT_RULE.staggerByTypeId,
        adjustmentTypeId: principalPricerSettings?.adjustmentTypeId ?? DEFAULT_RULE.adjustmentTypeId,
        staggerByValue: principalPricerSettings?.staggerByValue ?? DEFAULT_RULE.staggerByValue,
        // TODO: Update with schedule defaults settings
        adjustmentValue: principalPricerSettings?.adjustmentValue ?? DEFAULT_RULE.adjustmentValue,
        autoAdjustSplits: principalPricerSettings?.autoAdjustSplits ?? DEFAULT_RULE.autoAdjustSplits,
        filters: {
          ...DEFAULT_RULE.filters,
          outlierCriteria: principalPricerSettings?.outlierCriteria,
          rows: getRowComparisonValue(selectedListing?.row, principalPricerSettings?.rowComparisons),
          exclusions: principalPricerSettings?.exclusions ?? DEFAULT_RULE.filters.exclusions,
          deliveryExclusions: principalPricerSettings?.deliveryExclusions ?? DEFAULT_RULE.filters.deliveryExclusions,
          splits: getSplitsValue(
            principalPricerSettings?.autoAdjustSplits ?? DEFAULT_RULE.autoAdjustSplits,
            null,
            selectedListing?.quantityRemaining,
            selectedListing?.row,
            principalPricerSettings?.quantitySplitMatrix,
            principalPricerSettings?.generalAdmissionSplits,
            tenantPricerSettings?.quantitySplitMatrix,
            tenantPricerSettings?.generalAdmissionSplits,
          ),
        },
      }) satisfies Rule;

      // Fix adjustmentValue in case of Percentage and value being 0
      if (newRule.adjustmentTypeId === 'Percentage' && !newRule.adjustmentValue) {
        newRule.adjustmentValue = 1;
      }

      set(_ruleStateSharedAtom, {
        base: newRule,
        rule: newRule,
      });
      set(selectedMarketplaceIdAtom, newRule.marketplaceId);
      set(autoUpdateRuleOnTargetComparableChangesAtom, true);
    }
  },
);

export const seatingChartFiltersAtom = focusAtom(ruleStateAtom, (a) => a.prop('filters'));
export const sectionRuleFilterAtom = focusAtom(seatingChartFiltersAtom, (a) => a.prop('sectionIds'));
export const resetFiltersAtom = atom(null, (get, set) => {
  const initialValue = get(ruleStateInitialValueAtom);
  set(seatingChartFiltersAtom, initialValue!.filters);
});

export const filtersDirtyAtom = atom((get) => {
  const { filters, marketplaceId } = get(ruleStateAtom);
  const initialValue = get(ruleStateInitialValueAtom)?.filters;

  if (!initialValue) {
    return false;
  }

  const dirty = !deepEqual(filters, initialValue) || marketplaceId !== get(ruleStateInitialValueAtom)?.marketplaceId;

  return dirty;
});

export const filtersEmptyAtom = atom((get) => {
  const { filters } = get(ruleStateAtom);
  const empty = weakEquals(filters, DEFAULT_FILTERS);
  return empty;
});

export const ruleDirtyAtom = atom((get) => {
  const rule = get(ruleStateAtom);
  const initialValue = get(ruleStateInitialValueAtom);
  if (rule && rule.isAutoPriced) {
    const dirty = !weakEquals(rule, initialValue, [
      'ruleId',
      'numActive',
      'adjustmentTypeId',
      'marketplaceId',
      'ceilingPrice',
      'floorPrice',
      'adjustmentValue',
      'isAutoPriced',
      'staggerByTypeId',
      'staggerByValue',
      'numComparables',
      'automationTypeId',
      'autoAdjustSplits',
    ]);
    return dirty;
  }
  if (rule && rule.automationTypeId === 'SchedulePrice') {
    const ruleDirty = !weakEquals(rule, initialValue, [
      'ruleId',
      'numActive',
      'adjustmentTypeId',
      'marketplaceId',
      'ceilingPrice',
      'floorPrice',
      'adjustmentValue',
      'staggerByTypeId',
      'staggerByValue',
      'automationTypeId',
      'autoAdjustSplits',
    ]);
    const scheduleDirty = !weakEquals(rule.schedulePricerSettings || {}, initialValue?.schedulePricerSettings);
    return ruleDirty || scheduleDirty;
  }
  if (rule && !rule.isAutoPriced) {
    const dirty = !weakEquals(rule, initialValue, ['isAutoPriced', 'automationTypeId']);
    return dirty;
  }
  return false;
});

export const calculatedTargetPriceAtom = atom((get) => {
  const listing = get(selectedMergedListingAtom);
  if (listing) {
    const rule = get(targetComparableFloorPriceRuleAtom);
    const targetComparables = get(targetComparablesAtom);
    const roundingSettings = get(auth.tenantsAtom).find((t) => t.tenantId === listing.tenantId)?.settings?.pricerSettings?.roundPrices ?? 'None';
    const target = calculateTargetPrice({ listing, offset: 0, rule, targetComparables, tenantRoundingSettings: roundingSettings });
    return target;
  }
  return 0;
});

export const transientGlobalStateAtom = atom<GlobalTransientState>({
  autoUpdateAutoPricedListPriceRuleId: null,
  applyGroupListingRule: false,
  ignoreGridScrolling: false,
  expandGroupByListingId: null,
} satisfies GlobalTransientState);

export const mobileVersionAtom = atom('');
