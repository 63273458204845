import { IconPropsBase } from './IconPropsBase';

export default function PriorityIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 96 960 960">
        <path
          fill={color}
          d="M360 915.999q-91.666 0-155.832-64.167Q140.001 787.666 140.001 696V456q0-91.666 64.167-155.832Q268.334 236.001 360 236.001h240q91.666 0 155.832 64.167Q819.999 364.334 819.999 456v240q0 91.666-64.167 155.832Q691.666 915.999 600 915.999H360Zm80-193.846L666.153 496 624 453.847l-184 184-88-88L309.847 592 440 722.153ZM360 856h240q66 0 113-47t47-113V456q0-66-47-113t-113-47H360q-66 0-113 47t-47 113v240q0 66 47 113t113 47Zm120-280Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 96 960 960">
        <path
          fill={color}
          d="M360 891.999q-81.666 0-138.832-57.167Q164.001 777.666 164.001 696V456q0-81.666 57.167-138.832Q278.334 260.001 360 260.001h240q81.666 0 138.832 57.167Q795.999 374.334 795.999 456v240q0 81.666-57.167 138.832Q681.666 891.999 600 891.999H360Zm69-202.846L619.153 500 582 462.847l-153 152-68-67L324.847 585 429 689.153ZM360 840h240q60 0 102-42t42-102V456q0-60-42-102t-102-42H360q-60 0-102 42t-42 102v240q0 60 42 102t102 42Zm120-264Z"
        />
      </svg>
    );
  }
}
