import { Group, HoverCard, Indicator, Text } from '@mantine/core';
import { DateInput, DateInputProps, DateValue } from '@mantine/dates';
import React, { ReactNode, useMemo } from 'react';
import ErrorFilledIcon from '../icons/ErrorFilled';
import WarningIcon from '../icons/Warning';

interface BNDatePickerProps extends Omit<DateInputProps, 'firstDayOfWeek' | 'renderDay'> {
  selectOnFocus?: boolean;
  errorVariant?: 'error' | 'warning';
  errorPosition?: 'auto' | 'bottom';
}

interface ErrorHoverCardProps {
  label: ReactNode;
  error: ReactNode;
  inline?: boolean;
  errorVariant?: 'error' | 'warning';
}

const ErrorHoverCard = ({ label, error, inline, errorVariant }: ErrorHoverCardProps) => {
  return (
    <HoverCard
      position="top"
      shadow="md"
      withArrow
      styles={{
        arrow: {
          borderColor: errorVariant === 'warning' ? 'var(--colors-yellow-6)' : 'var(--colors-red-error)',
        },
        dropdown: {
          maxWidth: 220,
          borderColor: errorVariant === 'warning' ? 'var(--colors-yellow-6)' : 'var(--colors-red-error)',
          color: 'var(--colors-gray-7)',
          textAlign: 'center',
        },
      }}
    >
      <HoverCard.Target>
        <Group className={errorVariant === 'warning' ? 'warning' : 'error'} p={0} pl={inline ? 2 : 0} gap={inline ? 0 : 4}>
          {!inline && <Text c={errorVariant === 'warning' ? 'var(--colors-yellow-6)' : 'var(--colors-red-error)'}>{label}</Text>}
          {errorVariant === 'warning' ? <WarningIcon color="var(--colors-yellow-6)" size={20} /> : <ErrorFilledIcon color="var(--colors-red-error)" size={20} />}
        </Group>
      </HoverCard.Target>
      {typeof error === 'string' && (
        <HoverCard.Dropdown>
          <Text size="xs">{error}</Text>
        </HoverCard.Dropdown>
      )}
    </HoverCard>
  );
};

export const BNDatePicker = ({ selectOnFocus, onFocus, label, error, leftSection, errorVariant, errorPosition, ...props }: BNDatePickerProps) => {
  const today = useMemo(() => new Date(), []);
  return (
    <DateInput
      {...props}
      firstDayOfWeek={0}
      renderDay={(date: Date) => {
        const day = date.getDate();
        const isToday = date.toDateString() === today.toDateString();
        return (
          <Indicator size={4} color="var(--colors-selectedBorder)" offset={-2} disabled={!isToday}>
            <div>{day}</div>
          </Indicator>
        );
      }}
      label={error ? errorPosition === 'bottom' ? label : label ? <ErrorHoverCard label={label} error={error} errorVariant={errorVariant} /> : undefined : label}
      error={error ? (errorPosition === 'bottom' ? error : true) : false}
      errorProps={{ className: errorVariant === 'warning' ? 'warning' : 'error' }}
      leftSection={
        error ? errorPosition === 'bottom' ? leftSection : label ? leftSection : <ErrorHoverCard label={label} error={error} errorVariant={errorVariant} inline /> : leftSection
      }
      leftSectionProps={{
        style: { pointerEvents: error ? 'inherit' : 'none' },
      }}
      styles={{
        input: { borderColor: error ? (errorVariant === 'warning' ? 'var(--colors-yellow-6)' : 'var(--colors-red-error)') : undefined },
      }}
      onFocus={(e) => {
        if (selectOnFocus) {
          e.target.select();
        }

        if (onFocus) {
          onFocus(e);
        }
      }}
    />
  );
};
