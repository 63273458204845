import { IconPropsBase } from './IconPropsBase';

export default function ScheduledPriceIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M9.2,2.7V1.3h5.6v1.5C14.8,2.7,9.2,2.7,9.2,2.7z M12,21.5c-1.2,0-2.3-0.2-3.3-0.7c-1-0.4-1.9-1.1-2.7-1.8
	c-0.8-0.8-1.4-1.7-1.8-2.7c-0.4-1-0.7-2.1-0.7-3.3s0.2-2.3,0.7-3.3C4.6,8.7,5.2,7.8,6,7c0.8-0.8,1.7-1.4,2.7-1.8
	c1-0.4,2.1-0.7,3.3-0.7c1,0,2,0.2,2.9,0.5c0.9,0.3,1.8,0.8,2.6,1.5l1.2-1.2l1.1,1.1l-1.2,1.2c0.6,0.8,1.1,1.7,1.5,2.6
	c0.3,0.9,0.5,1.9,0.5,2.9c0,1.2-0.2,2.3-0.7,3.3c-0.4,1-1.1,1.9-1.8,2.7c-0.8,0.8-1.7,1.4-2.7,1.8C14.3,21.3,13.2,21.5,12,21.5z
	 M12,20c1.9,0,3.6-0.7,5-2s2-3,2-5s-0.7-3.6-2-4.9S13.9,6,12,6S8.4,6.7,7.1,8.1S5,11.1,5,13s0.7,3.6,2.1,5S10.1,20,12,20z
	 M11.2,17.7h1.5v-1h1.1c0.3,0,0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6v-2.7c0-0.3-0.1-0.5-0.3-0.6c-0.2-0.2-0.4-0.3-0.6-0.3h-3.1
	v-1.5h4V9.3h-2v-1h-1.5v1h-1.1c-0.3,0-0.5,0.1-0.6,0.3c-0.2,0.2-0.3,0.4-0.3,0.6v2.7c0,0.3,0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3
	h3.1v1.5h-4v1.5h2C11.2,16.7,11.2,17.7,11.2,17.7z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M7.7,2.8V1.7h4.7v1.1C12.3,2.8,7.7,2.8,7.7,2.8z M10,17.6c-0.9,0-1.8-0.2-2.6-0.5c-0.8-0.3-1.5-0.8-2.1-1.4
	c-0.6-0.6-1.1-1.3-1.4-2.1c-0.3-0.8-0.5-1.7-0.5-2.6c0-0.9,0.2-1.8,0.5-2.6c0.3-0.8,0.8-1.5,1.4-2.1c0.6-0.6,1.3-1.1,2.1-1.4
	C8.2,4.6,9.1,4.4,10,4.4c0.8,0,1.5,0.1,2.3,0.4c0.7,0.3,1.4,0.7,2,1.1l1-0.9L16,5.8l-0.9,1c0.5,0.6,0.9,1.3,1.1,2
	c0.3,0.7,0.4,1.5,0.4,2.3c0,0.9-0.2,1.8-0.5,2.6c-0.3,0.8-0.8,1.5-1.4,2.1c-0.6,0.6-1.3,1.1-2.1,1.4C11.8,17.4,10.9,17.6,10,17.6z
	 M10,16.5c1.5,0,2.8-0.5,3.9-1.6s1.6-2.4,1.6-3.9S15,8.2,13.9,7.1S11.5,5.5,10,5.5S7.2,6,6.1,7.1S4.5,9.5,4.5,11s0.5,2.8,1.6,3.9
	S8.5,16.5,10,16.5z M9.4,14.8h1.1v-1h0.8c0.3,0,0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6v-1.5c0-0.3-0.1-0.5-0.3-0.6
	c-0.2-0.2-0.4-0.3-0.6-0.3H8.8V9.3h3.5V8.2h-1.8v-1H9.4v1H8.6c-0.3,0-0.5,0.1-0.6,0.3C7.8,8.6,7.7,8.9,7.7,9.1v1.5
	c0,0.3,0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3h2.6v1.2H7.7v1.1h1.8C9.4,13.8,9.4,14.8,9.4,14.8z"
        />
      </svg>
    );
  }
}
