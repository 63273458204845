/* eslint-disable prefer-destructuring */
import { Box, Center, Flex, Group, Stack, Text } from '@mantine/core';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts';
import classes from './Sales.Performance.module.css';
import { useCallback } from 'react';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import LineChartIcon from '../../components/icons/LineChart';
import pluralize from 'pluralize';
import { MarketplaceColors, MarketplaceNames } from '../../models/marketplaces';
import { useSales } from './Sales.hooks';
import { BNLoadingState } from '../../components/Loading/LoadingState';
import cx from 'clsx';

type MarketplaceItemProps = {
  name: MarketplaceNames;
  color?: string;
  percentage: string;
};

function MarketplaceItem({ name, color, percentage }: MarketplaceItemProps) {
  const bgColor = color ?? MarketplaceColors[name];

  return (
    <Group gap="xs" justify="space-between" wrap="nowrap">
      <Group gap="xs" wrap="nowrap" style={{ overflow: 'hidden' }}>
        <Box h={13} w={13} bg={bgColor} style={{ borderRadius: 12, flexShrink: 0, border: '1px solid var(--colors-paper)' }} />
        <Text size="xs" truncate>
          {name}
        </Text>
      </Group>
      <Text size="xs">{percentage}%</Text>
    </Group>
  );
}

export default function SalesMarketplaceBreakdown() {
  const { marketplaceBreakdown, isLoading } = useSales('marketplaceBreakdown', 'isLoading');

  const isEmpty = marketplaceBreakdown === undefined || Object.keys(marketplaceBreakdown).length === 0;

  const totalTickets = Object.values(marketplaceBreakdown ?? {}).reduce((acc, value) => acc + value, 0);

  const data = !isEmpty
    ? Object.entries(marketplaceBreakdown ?? {})
        .map(([name, value]) => ({
          name,
          percentage: (value / totalTickets) * 100,
          tickets: value,
        }))
        .sort((a, b) => b.tickets - a.tickets)
    : [];

  // const isEmpty = false;

  // const data02 = [
  //   { name: 'SeatGeek', percentage: 30, tickets: 10 },
  //   { name: 'Ticketmaster', percentage: 25, tickets: 10 },
  //   { name: 'Vivid Seats', percentage: 20, tickets: 10 },
  //   { name: 'TicketNetwork', percentage: 20, tickets: 10 },
  //   { name: 'StubHub', percentage: 3, tickets: 10 },
  //   { name: 'TEVO', percentage: 1, tickets: 10 },
  //   { name: 'TickPick', percentage: 1, tickets: 10 },
  //   { name: 'Other', percentage: 1, tickets: 10 },
  // ];

  // const totalTickets = 100;

  const CustomTooltip = useCallback(({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length > 0) {
      if (payload[0].payload.name) {
        const valueDisplay = payload[0].payload.tickets;
        return (
          <Box className="custom-tooltip">
            <Group gap="xs">
              <Box h={12} w={12} style={{ borderRadius: 100 }} bg={payload[0].payload.fill} />
              {payload[0].payload.name}{' '}
            </Group>
            <Box pl={22}>
              <Text c="var(--colors-gray-5)">
                {valueDisplay} {pluralize('Ticket', valueDisplay)}
              </Text>
            </Box>
          </Box>
        );
      }
    }
    return null;
  }, []);

  if (isLoading) {
    return <BNLoadingState border />;
  }

  if (isEmpty) {
    return <BNEmptyState border={false} title="No Sales Data" description="Check back later" icon={<LineChartIcon size={28} />} />;
  }

  return (
    <Flex direction="column" gap={3} h="100%" w="100%" pos="relative">
      <Text c="var(--colors-gray-5)" fw={500} tt="uppercase" fz={11} lts={0.25} pos="absolute" top={10} left={12}>
        Marketplace Breakdown
      </Text>
      <Box className={classes.marketplaceChartWrapper} pos="absolute">
        <Box pos="absolute" h={112} w={112} className={classes.pieChartRing} style={{ border: '1px dotted var(--colors-gray-3)', borderRadius: 100 }} />
        <Center pos="absolute" w={154} h={150} className={classes.pieChartText} style={{ flexDirection: 'column' }}>
          <Text fz="xl" fw={600}>
            {totalTickets.toLocaleString()}
          </Text>
          <Text lh={1} fz="xs" c="var(--colors-gray-5)" tt="uppercase">
            {pluralize('Ticket', totalTickets)}
          </Text>
          <Text lh={1} fz="xs" c="var(--colors-gray-5)" tt="uppercase">
            Sold
          </Text>
        </Center>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={160} height={160}>
            <Pie dataKey="percentage" data={data} cx={72} cy={72} innerRadius={64} outerRadius={72} paddingAngle={5} fill="#8884d8">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={MarketplaceColors[entry.name as MarketplaceNames]} stroke="var(--colors-paper)" />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </Box>
      <Box className={classes.marketplaceScroll} mt={30}>
        <Stack className={cx(classes.marketplaceStack, data.length > 7 ? classes.compactStack : null)} gap={data.length > 7 ? 0 : 1} px="md" pb="xs">
          {data.map((entry, index) => (
            <MarketplaceItem key={index} name={entry.name as MarketplaceNames} percentage={entry.percentage.toFixed(0)} />
          ))}
        </Stack>
      </Box>
    </Flex>
  );
}
