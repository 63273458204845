import { parsePath } from 'path-data-parser';

type Point = { x: number; y: number };
type PathCommand = { command: string; points: Point[] };

function parsePathCommands(pathData: string): PathCommand[] {
  const pathCommands: PathCommand[] = [];
  const commandRegex = /([MmLlHhVvCcSsQqTtAaZz])\s*([^MmLlHhVvCcSsQqTtAaZz]*)/g;
  let match: RegExpExecArray | null;

  // eslint-disable-next-line no-cond-assign
  while ((match = commandRegex.exec(pathData)) !== null) {
    const command = match[1];
    const pointsData = match[2].trim();
    const points: Point[] = [];

    if (command.toUpperCase() !== 'Z' && pointsData.length > 0) {
      const pairs = pointsData.split(/[ ,]+/);
      for (let i = 0; i < pairs.length; i += 2) {
        points.push({ x: parseFloat(pairs[i]), y: parseFloat(pairs[i + 1]) });
      }
    }

    pathCommands.push({ command, points });
  }

  return pathCommands;
}

export function findPathCenter(pathElement: SVGElement): Point | null {
  const path = pathElement.getAttribute('d')!;
  const commands = parsePathCommands(path);
  if (commands.length === 0) return null;

  let minX = Number.POSITIVE_INFINITY;
  let maxX = Number.NEGATIVE_INFINITY;
  let minY = Number.POSITIVE_INFINITY;
  let maxY = Number.NEGATIVE_INFINITY;

  commands.forEach((cmd) => {
    cmd.points.forEach((pt) => {
      if (pt.x < minX) minX = pt.x;
      if (pt.x > maxX) maxX = pt.x;
      if (pt.y < minY) minY = pt.y;
      if (pt.y > maxY) maxY = pt.y;
    });
  });

  const location = {
    x: (minX + maxX) / 2,
    y: (minY + maxY) / 2,
  };

  const svg = pathElement.ownerSVGElement;
  if (svg && pathElement.getAttribute('transform')) {
    const rect = pathElement.getBoundingClientRect();
    const x = rect.left + rect.width / 2;
    const y = rect.top + rect.height / 2;
    const pt = svg.createSVGPoint();
    pt.x = x;
    pt.y = y;
    const svgPoint = pt.matrixTransform(svg.getScreenCTM()?.inverse());
    const result = { x: svgPoint.x, y: svgPoint.y };
    return result;
  }

  return location;
}

export function findBottomCenterPath(pathElement: SVGElement): { x: number; y: number } {
  const path = pathElement.getAttribute('d')!;
  const commands = parsePath(path);
  let lowestPoint = { x: 0, y: -Infinity };

  commands.forEach((command) => {
    command.data.forEach((arg, index) => {
      // Assuming even indices are X coordinates and odd indices are Y coordinates
      if (index % 2 !== 0 && arg > lowestPoint.y) {
        // Y coordinate
        lowestPoint = { x: command.data[index - 1], y: arg };
      }
    });
  });

  return lowestPoint;
}

export function findCenterXCenterYPath(pathElement: SVGElement): { x: number; y: number } {
  const path = pathElement.getAttribute('d')!;
  const commands = parsePath(path);
  let lowestPoints: { x: number; y: number }[] = [];
  let maxY = -Infinity;

  // Find the lowest points (highest Y-coordinate)
  commands.forEach((command) => {
    command.data.forEach((arg, index) => {
      if (index % 2 !== 0) {
        // Y coordinate
        if (arg > maxY) {
          maxY = arg;
          lowestPoints = [{ x: command.data[index - 1], y: arg }];
        } else if (arg === maxY) {
          lowestPoints.push({ x: command.data[index - 1], y: arg });
        }
      }
    });
  });

  // Calculate the middle X-coordinate of these points
  if (lowestPoints.length > 1) {
    const minX = Math.min(...lowestPoints.map((p) => p.x));
    const maxX = Math.max(...lowestPoints.map((p) => p.x));
    return { x: (minX + maxX) / 2, y: maxY };
  }
  if (lowestPoints.length === 1) {
    return lowestPoints[0];
  }

  return { x: 0, y: 0 }; // Default return in case path is empty or no lowest point is found
}

export function findApproximateCenterOfUPath(pathElement: SVGElement): Point {
  const path = pathElement.getAttribute('d')!;
  const commands = parsePath(path);
  let minX = Infinity;
  let maxX = -Infinity;
  let minY = Infinity;
  let maxY = -Infinity;

  // Find the extreme points
  commands.forEach((command) => {
    command.data.forEach((arg, index) => {
      if (index % 2 === 0) {
        // X coordinate
        minX = Math.min(minX, arg);
        maxX = Math.max(maxX, arg);
      } else {
        // Y coordinate
        minY = Math.min(minY, arg);
        maxY = Math.max(maxY, arg);
      }
    });
  });

  const midX = (minX + maxX) / 2;
  const midY = (minY + maxY) / 2;
  let closestPoint = { x: commands[0].data[0], y: commands[0].data[1] };
  let smallestDistance = Infinity;

  // Iterate through the path to find the point closest to the calculated center
  commands.forEach((command) => {
    command.data.forEach((arg, index) => {
      if (index % 2 === 0) {
        // X coordinate
        const currentX = arg;
        const currentY = command.data[index + 1];
        const distance = Math.sqrt((currentX - midX) ** 2 + (currentY - midY) ** 2);

        if (distance < smallestDistance) {
          smallestDistance = distance;
          closestPoint = { x: currentX, y: currentY };
        }
      }
    });
  });

  return closestPoint;
}

export function findCenterOfUPath(pathElement: SVGElement): Point | null {
  try {
    const path = pathElement.getAttribute('d');
    if (!path) {
      return null;
    }
    const commands = parsePath(path);
    let minX = Infinity;
    let maxX = -Infinity;
    let minY = Infinity;
    let maxY = -Infinity;

    // Find the extreme points
    commands.forEach((command) => {
      command.data.forEach((arg, index) => {
        if (index % 2 === 0) {
          // X coordinate
          minX = Math.min(minX, arg);
          maxX = Math.max(maxX, arg);
        } else {
          // Y coordinate
          minY = Math.min(minY, arg);
          maxY = Math.max(maxY, arg);
        }
      });
    });

    // Calculate the center point
    const result = {
      x: (minX + maxX) / 2,
      y: (minY + maxY) / 2,
    };
    if (isPointInsideExistingSVGPath(pathElement, result)) {
      return result;
    }

    const svg = pathElement.ownerSVGElement;
    if (svg && pathElement.getAttribute('transform')) {
      const rect = pathElement.getBoundingClientRect();
      const x = rect.left + rect.width / 2;
      const y = rect.top + rect.height / 2;
      const pt = svg.createSVGPoint();
      pt.x = x;
      pt.y = y;
      const svgPoint = pt.matrixTransform(svg.getScreenCTM()?.inverse());
      const transformResult = { x: svgPoint.x, y: svgPoint.y };
      return transformResult;
    }

    return findCenterXCenterYPath(pathElement);
  } catch (error) {
    return null;
  }
}

function isPointInsideExistingSVGPath(pathElement: SVGPathElement | SVGElement, point: Point): boolean {
  // Convert the point to the SVGPoint structure
  const svgPoint = pathElement.ownerSVGElement!.createSVGPoint();
  svgPoint.x = point.x;
  svgPoint.y = point.y;

  // Check if the point is inside the path
  return (pathElement as SVGPathElement).isPointInFill(svgPoint);
}

export function findCenterPointOnPath(pathElement: SVGElement): Point {
  const path = pathElement.getAttribute('d')!;
  const commands = parsePath(path);
  let totalLength = 0;
  let previousPoint = { x: 0, y: 0 };

  // Calculate total length
  commands.forEach((command) => {
    if (command.key === 'M') {
      previousPoint = { x: command.data[0], y: command.data[1] };
    } else if (command.key === 'L') {
      totalLength += Math.sqrt((command.data[0] - previousPoint.x) ** 2 + (command.data[1] - previousPoint.y) ** 2);
      previousPoint = { x: command.data[0], y: command.data[1] };
    }
    // Add more cases for other command types (e.g., curves)
  });

  const halfLength = totalLength / 2;
  let lengthSum = 0;
  previousPoint = { x: 0, y: 0 };

  // Find center point
  for (const command of commands) {
    if (command.key === 'M') {
      previousPoint = { x: command.data[0], y: command.data[1] };
    } else if (command.key === 'L') {
      const segmentLength = Math.sqrt((command.data[0] - previousPoint.x) ** 2 + (command.data[1] - previousPoint.y) ** 2);
      if (lengthSum + segmentLength >= halfLength) {
        const remainingLength = halfLength - lengthSum;
        const fraction = remainingLength / segmentLength;
        return {
          x: previousPoint.x + fraction * (command.data[0] - previousPoint.x),
          y: previousPoint.y + fraction * (command.data[1] - previousPoint.y),
        };
      }
      lengthSum += segmentLength;
      previousPoint = { x: command.data[0], y: command.data[1] };
    }
    // Add more cases for other command types
  }

  return { x: 0, y: 0 }; // Default return in case path is empty
}

export function findRectangleCenters(svgPath: string): Point | null {
  const centers: Point[] = [];
  const pathParts = svgPath.split(/(?=[MCL])/); // Split the path by M and L

  let currentPoint: Point = { x: 0, y: 0 };
  let startPoint: Point = { x: 0, y: 0 };
  let points: Point[] = [];

  pathParts.forEach((part) => {
    const command = part[0];
    const coords = part
      .substring(1)
      .trim()
      .split(/[\s,]+/)
      .map(Number);

    for (let i = 0; i < coords.length; i += 2) {
      const x = coords[i];
      const y = coords[i + 1];
      if (isNaN(x) || isNaN(y)) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (command === 'M') {
        if (points.length > 0) {
          // If we have collected points for a rectangle, calculate the center
          const c = calculateCenter(points);
          if (c) {
            centers.push(c);
          }
        }
        // Move to the new start point and reset the points array
        startPoint = { x, y };
        points = [startPoint];
        currentPoint = startPoint;
      } else if (command === 'L' || command === 'C') {
        currentPoint = { x, y };
        points.push(currentPoint);
      }
    }
  });

  // After the loop, check if there is one last rectangle to account for
  if (points.length > 0) {
    const c = calculateCenter(points);
    if (c) {
      centers.push(c);
    }
  }
  const result = calculateCenter(centers);
  return result;
}
// Function to calculate the center from a set of points
function calculateCenter(points: Point[]) {
  const xValues = points.map((p) => p.x);
  const yValues = points.map((p) => p.y);
  const minX = Math.min(...xValues);
  const maxX = Math.max(...xValues);
  const minY = Math.min(...yValues);
  const maxY = Math.max(...yValues);

  const result = { x: (minX + maxX) / 2, y: (minY + maxY) / 2 };
  if (isNaN(result.x) || isNaN(result.y)) {
    return null;
  }
  return result;
}

// Function to calculate the distance between two points
function getDistance(x1: number, y1: number, x2: number, y2: number): number {
  const dx = x2 - x1;
  const dy = y2 - y1;
  return Math.sqrt(dx * dx + dy * dy);
}

// Function to map distance to opacity value
function mapDistanceToOpacity(distance: number, minDistance: number, maxDistance: number): number {
  if (distance < minDistance) return 0; // Fully transparent if within min distance
  if (distance > maxDistance) return 1; // Fully opaque if beyond max distance
  // Calculate opacity based on distance
  return (distance - minDistance) / (maxDistance - minDistance);
}

// Function to check the cursor's distance from the circle center
export function checkDistance(event: MouseEvent): void {
  // Get the circle element and its properties
  const circles = document.querySelectorAll('svg circle') as NodeListOf<SVGCircleElement>;

  circles.forEach((circle) => {
    const circleRect = circle.getBoundingClientRect();
    const circleX = circleRect.left + circleRect.width / 2;
    const circleY = circleRect.top + circleRect.height / 2;

    // Calculate the distance from the cursor to the circle center
    const distance = getDistance(event.clientX, event.clientY, circleX, circleY);

    // Map the distance to an opacity value
    const opacity = mapDistanceToOpacity(distance, 25, 125);

    // Apply the calculated opacity to the circle
    // eslint-disable-next-line no-param-reassign
    circle.style.opacity = opacity.toString();
  });
}

export function getCenterOfSvgPath(pathSelector: string): Point {
  // Get the SVG path element by selector
  const pathElement: SVGGraphicsElement = document.querySelector(pathSelector) as SVGGraphicsElement;

  if (!pathElement) {
    throw new Error(`SVG path element not found: ${pathSelector}`);
  }

  // Calculate the bounding box of the path
  const bbox = pathElement.getBBox();

  // Calculate the center of the bounding box
  const x = bbox.x + bbox.width / 2;
  const y = bbox.y + bbox.height / 2;

  return { x, y };
}

export function getCenterOfSvgPathWithTransform(pathSelector: string): Point {
  // Get the SVG path element by selector
  const pathElement = document.querySelector(pathSelector) as SVGGraphicsElement;

  if (!pathElement) {
    throw new Error(`SVG path element not found: ${pathSelector}`);
  }

  // Get the transformed bounding box of the path
  const rect = pathElement.getBoundingClientRect();

  // Calculate the center of the bounding box
  const x = rect.left + rect.width / 2;
  const y = rect.top + rect.height / 2;

  // Convert screen coordinates to SVG coordinates if necessary
  // Note: This conversion is required if you need the coordinates in SVG space
  const svg = pathElement.ownerSVGElement;
  if (svg) {
    const pt = svg.createSVGPoint();
    pt.x = x;
    pt.y = y;
    const svgPoint = pt.matrixTransform(svg.getScreenCTM()?.inverse());
    return { x: svgPoint.x, y: svgPoint.y };
  }

  // If no conversion is needed, return the screen coordinates
  return { x, y };
}

export function getDomPathToSVGElement(el: Element): string {
  const path: string[] = [];
  let currentEl: Element | null = el;

  while (currentEl && currentEl.nodeType === Node.ELEMENT_NODE) {
    const tagName = currentEl.tagName.toLowerCase();
    let index = 1; // Position among siblings of the same type

    for (let sibling = currentEl.previousElementSibling; sibling; sibling = sibling.previousElementSibling) {
      if (sibling.nodeType === Node.ELEMENT_NODE && sibling.tagName.toLowerCase() === tagName) {
        index++;
      }
    }

    const selector = `${tagName}:nth-of-type(${index})`;
    path.unshift(selector);

    // Break the loop if we reach an SVG element
    if (tagName === 'svg') {
      break;
    }

    currentEl = currentEl.parentElement;
  }

  return path.join(' > ');
}

export function matchSectionNumbers(input: string, search: string): boolean {
  const searchLength = search.length;

  for (let i = 0; i <= input.length - searchLength; i++) {
    const substring = input.substring(i, i + searchLength);

    if (substring === search) {
      const before = i === 0 || !/^\d$/.test(input[i - 1]);
      const after = i + searchLength === input.length || !/^\d$/.test(input[i + searchLength]);

      if (before && after) {
        return true;
      }
    }
  }
  return false;
}
