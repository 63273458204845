import { forwardRef } from 'react';
import { BNNumberInput, BNNumberInputProps } from './NumberInput';
import classes from './PercentageInput.module.css';

type BNPercentageInputProps = { hideControls?: boolean } & BNNumberInputProps;

/**
 * Percentage Number Input
 */
export const BNPercentageInput = forwardRef<HTMLInputElement, BNPercentageInputProps>(({ hideControls, ...props }, ref) => {
  return <BNNumberInput {...props} hideControls={hideControls} className={`${classes.numInput} pctSize-${props.size} hidecontrols-${hideControls}`} />;
});
