import { useSearchParams } from 'react-router-dom';

export function useQueryParam<T>(key: string, defaultValue: T): T {
  const [searchParams] = useSearchParams();
  const param = searchParams.get(key);

  if (param === null) {
    return defaultValue;
  }

  if (typeof defaultValue === 'number') {
    return Number(param) as unknown as T;
  } if (typeof defaultValue === 'boolean') {
    return (param === 'true') as unknown as T;
  }

  return param as unknown as T;
}
