import { useQuery } from '@tanstack/react-query';
import yasml from '@thirtytech/yasml';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import {
  BarkerCoreModelsWarehouseListingSnapshot,
  getApiMarketplacesMarketplaceIdEventsEventIdSnapshots,
  getGetApiInventoryEventsEventIdMappingsQueryKey,
  putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFiltered,
  putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsRecent,
  useGetApiInventoryEventsEventIdMappings,
} from '../../api';
import { useAtomValue } from 'jotai';
import { filtersEmptyAtom, ruleStateAtom, seatingChartFiltersAtom, selectedEventAtom } from '../../data/atoms';
import { useLocalStorage } from '@mantine/hooks';
import { ChartTimePeriod } from '../../components/Charts/TicketVolumeAndPrice';
import { useRuleState } from '../../data/RuleState';

function MarketplaceTrendsState() {
  const selectedEvent = useAtomValue(selectedEventAtom);
  const ruleFiltersEmpty = useAtomValue(filtersEmptyAtom);
  const rule = useAtomValue(ruleStateAtom);

  const filters = useAtomValue(seatingChartFiltersAtom);
  const selectedMarketplaceId = rule.marketplaceId;
  const { isLoading: isRuleLoading } = useRuleState('isLoading', 'resetForm');
  const [snapshotPeriod, setSnapshotPeriod] = useLocalStorage<ChartTimePeriod>({
    key: 'setting-snapshotPeriod',
    defaultValue: '1w',
    getInitialValueInEffect: false,
    // serialize: (value) => value,
    // deserialize: (value) => value as ChartTimePeriod,
  });
  const { data: eventMappings, isFetching: isMappingsLoading } = useGetApiInventoryEventsEventIdMappings(selectedEvent?.eventId!, {
    query: {
      enabled: !!selectedEvent?.eventId,
      queryKey: [...getGetApiInventoryEventsEventIdMappingsQueryKey(selectedEvent?.eventId!), selectedEvent?.tenantId],
      select(data) {
        return data.data;
      },
    },
    axios: {
      headers: {
        'x-tenant-id': selectedEvent?.tenantId,
      },
    },
  });
  const mapping = useMemo(() => eventMappings?.find((m) => m.marketplaceId === selectedMarketplaceId), [eventMappings, selectedMarketplaceId]);
  const toDate = useMemo(() => dayjs().endOf('day').toDate(), []);
  const { data: snapshots, isFetching: isSnapshotsLoading } = useQuery(
    [ruleFiltersEmpty, isRuleLoading, selectedMarketplaceId, mapping?.marketplaceEventId, snapshotPeriod, selectedEvent?.tenantId, filters],
    () => {
      // Return a snapshot for a specific marketplace event and period; ensuring the marketplaceEventId is available.
      if (ruleFiltersEmpty && snapshotPeriod !== '1d' && mapping?.marketplaceEventId) {
        return getApiMarketplacesMarketplaceIdEventsEventIdSnapshots(selectedMarketplaceId, mapping.marketplaceEventId, {
          headers: {
            'x-tenant-id': selectedEvent?.tenantId,
          },
        });
      }
      if (!ruleFiltersEmpty && !isRuleLoading && selectedMarketplaceId && mapping?.marketplaceEventId) {
        if (snapshotPeriod === '1d') {
          return putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsRecent(selectedMarketplaceId, mapping?.marketplaceEventId!, filters, {
            headers: {
              'x-tenant-id': selectedEvent?.tenantId,
            },
          });
        }

        return putApiMarketplacesMarketplaceIdEventsEventIdSnapshotsFiltered(
          selectedMarketplaceId,
          mapping?.marketplaceEventId!,
          filters,
          {
            from:
              snapshotPeriod === '1w'
                ? dayjs(toDate).subtract(1, 'week').startOf('day').toDate()
                : snapshotPeriod === '1m'
                  ? dayjs(toDate).subtract(1, 'month').startOf('day').toDate()
                  : snapshotPeriod === '3m'
                    ? dayjs(toDate).subtract(3, 'month').startOf('day').toDate()
                    : snapshotPeriod === '1y'
                      ? dayjs(toDate).subtract(1, 'year').startOf('day').toDate()
                      : dayjs(toDate).subtract(100, 'year').startOf('day').toDate(),
            to: toDate,
          },
          {
            headers: {
              'x-tenant-id': selectedEvent?.tenantId,
            },
          },
        );
      }
      return Promise.resolve({ data: [] as BarkerCoreModelsWarehouseListingSnapshot[] });
    },
  );

  const isLoading = useMemo(() => isMappingsLoading || isRuleLoading || isSnapshotsLoading, [isMappingsLoading, isRuleLoading, isSnapshotsLoading]);
  const [showMedPrice, setShowMedPrice] = useState(true);
  const [showMinPrice, setShowMinPrice] = useState(true);
  const [showVolume, setShowVolume] = useState(true);

  const data = useMemo(
    () =>
      snapshots?.data
        ?.filter(
          (snapshot) =>
            (snapshotPeriod === '1d' && dayjs(snapshot.createdAt).isAfter(dayjs().subtract(1, 'day'))) ||
            (snapshotPeriod === '1w' && dayjs(snapshot.createdAt).isAfter(dayjs().subtract(1, 'week'))) ||
            (snapshotPeriod === '1m' && dayjs(snapshot.createdAt).isAfter(dayjs().subtract(1, 'month'))) ||
            (snapshotPeriod === '3m' && dayjs(snapshot.createdAt).isAfter(dayjs().subtract(3, 'month'))) ||
            (snapshotPeriod === '1y' && dayjs(snapshot.createdAt).isAfter(dayjs().subtract(1, 'year'))) ||
            snapshotPeriod === 'all',
        )
        .sort((a, b) => (dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1))
        .map((snapshot) => ({
          name: snapshotPeriod === '1d' ? dayjs(snapshot.createdAt).format('h A') : dayjs(snapshot.createdAt).format('M/D'),
          tickets: snapshot.totalTickets,
          medPrice: snapshot.medPrice,
          minPrice: snapshot.minPrice,
        })) ?? [],
    [snapshots?.data, snapshotPeriod],
  );

  return {
    isLoading,
    snapshots,
    snapshotPeriod,
    setSnapshotPeriod,
    showMedPrice,
    setShowMedPrice,
    showMinPrice,
    setShowMinPrice,
    showVolume,
    setShowVolume,
    data,
  };
}

export const { Provider: MarketplaceTrendsProvider, useSelector: useMarketplaceTrends } = yasml(MarketplaceTrendsState);
