import { useSearchResults } from './SearchResults.hooks';
import dayjs from 'dayjs';
import { Box, Divider, Flex } from '@mantine/core';
import classes from './Search.Mobile.module.css';
import { BNButton } from '../../components/Button/Button';
import SearchIcon from '../../components/icons/Search';
import CloseIcon from '../../components/icons/Close';
import DateRangeIcon from '../../components/icons/DateRange';
import AutoPriceIcon from '../../components/icons/AutoPrice';
import VerticalAlignBottomIcon from '../../components/icons/VerticalAlignBottom';
import MobileDataOffIcon from '../../components/icons/MobileDataOff';
import AutoPriceOffIcon from '../../components/icons/AutoPriceOff';
import ListIcon from '../../components/icons/List';
import StadiumIcon from '../../components/icons/Stadium';
import WifiOffIcon from '../../components/icons/WifiOff';
import pluralize from 'pluralize';
import DateSinceSaleIcon from '../../components/icons/DateSinceSale';
import { BarkerCoreEnumsPricerStatus } from '../../api';
import TagIcon from '../../components/icons/Tag';
import PriceChangeIcon from '../../components/icons/PriceChange';

export type SearchPillsAreaProps = {
  openSearch: () => void;
};

export function SearchPillsArea({ openSearch }: SearchPillsAreaProps) {
  const { form, reset, search, mobileDateLabel } = useSearchResults('form', 'reset', 'search', 'mobileDateLabel');
  const hasFilters =
    form.values.query.length > 0 ||
    form.values.fromDate?.getTime() !== dayjs().startOf('day').toDate().getTime() ||
    form.values.toDate?.getTime() !== dayjs().endOf('day').toDate().getTime() ||
    form.values.pricerStatuses?.includes('AutoPriced') ||
    form.values.pricerStatuses?.includes('AtFloor') ||
    form.values.pricerStatuses?.includes('NoComparables') ||
    form.values.pricerStatuses?.includes('None') ||
    form.values.listingId ||
    form.values.eventId ||
    form.values.onlyNotBroadcasting ||
    form.values.daysSinceLastSale ||
    form.values.daysSinceRepriced ||
    form.values.purchaseDateFrom ||
    form.values.tags?.length > 0;

  return (
    hasFilters && (
      <Box className={classes.tagScrollerWrapper}>
        <Flex pb={16} px={16} align="center" gap={12} className={classes.tagScroller}>
          {hasFilters && (
            <BNButton
              size="xs"
              variant="filled"
              color="gray"
              px={8}
              style={{ borderRadius: 6, flexShrink: 0 }}
              onClick={() => {
                reset();
              }}
            >
              Reset All
            </BNButton>
          )}
          {form.values.query.length > 0 && (
            <SearchPill
              icon={<SearchIcon />}
              label={form.values.query}
              onClick={openSearch}
              onClose={() => {
                form.setFieldValue('query', '');
                search({ ...form.values, query: '' }, true);
              }}
            />
          )}
          {mobileDateLabel !== 'Today' && (
            <SearchPill
              icon={<DateRangeIcon />}
              label={
                mobileDateLabel === 'All'
                  ? 'All Events'
                  : mobileDateLabel === 'Custom'
                    ? `${dayjs(form.values.fromDate).format('M/D')}-${dayjs(form.values.toDate).format('M/D')}`
                    : mobileDateLabel
              }
              onClick={openSearch}
              onClose={() => {
                form.setValues({
                  fromDate: dayjs().startOf('day').toDate(),
                  toDate: dayjs().endOf('day').toDate(),
                });
                search(
                  {
                    ...form.values,
                    fromDate: dayjs().startOf('day').toDate(),
                    toDate: dayjs().endOf('day').toDate(),
                  },
                  true,
                );
              }}
            />
          )}
          {(['AutoPriced', 'AtFloor', 'NoComparables', 'None'] as BarkerCoreEnumsPricerStatus[]).map(
            (status) =>
              form.values.pricerStatuses?.includes(status) && (
                <SearchPill
                  key={status}
                  icon={
                    status === 'AutoPriced' ? (
                      <AutoPriceIcon />
                    ) : status === 'AtFloor' ? (
                      <VerticalAlignBottomIcon />
                    ) : status === 'NoComparables' ? (
                      <MobileDataOffIcon />
                    ) : status === 'None' ? (
                      <AutoPriceOffIcon />
                    ) : null
                  }
                  label={
                    status === 'AutoPriced'
                      ? 'Auto-Priced'
                      : status === 'AtFloor'
                        ? 'At Floor'
                        : status === 'NoComparables'
                          ? 'No Comps'
                          : status === 'None'
                            ? 'Not Auto-Priced'
                            : ''
                  }
                  onClick={openSearch}
                  onClose={() => {
                    form.setFieldValue('pricerStatuses', [...(form.values.pricerStatuses?.filter((s) => s !== status) ?? [])]);
                    search({ ...form.values, pricerStatuses: form.values.pricerStatuses?.filter((s) => s !== status) }, true);
                  }}
                />
              ),
          )}
          {form.values.listingId && (
            <SearchPill
              icon={<ListIcon />}
              label={`LID: ${form.values.listingId}`}
              onClick={openSearch}
              onClose={() => {
                form.setFieldValue('listingId', undefined);
                search({ ...form.values, listingId: undefined }, true);
              }}
            />
          )}
          {form.values.eventId && (
            <SearchPill
              icon={<StadiumIcon />}
              label={`EID: ${form.values.eventId}`}
              onClick={openSearch}
              onClose={() => {
                form.setFieldValue('eventId', undefined);
                search({ ...form.values, eventId: undefined }, true);
              }}
            />
          )}
          {form.values.onlyNotBroadcasting && (
            <SearchPill
              icon={<WifiOffIcon />}
              label="Not Broadcasting"
              onClick={openSearch}
              onClose={() => {
                form.setFieldValue('onlyNotBroadcasting', false);
                search({ ...form.values, onlyNotBroadcasting: false }, true);
              }}
            />
          )}
          {form.values.daysSinceLastSale && (
            <SearchPill
              icon={<DateSinceSaleIcon />}
              label={`${form.values.daysSinceLastSale} ${pluralize('Day', form.values.daysSinceLastSale)} Since Sale`}
              onClick={openSearch}
              onClose={() => {
                form.setFieldValue('daysSinceLastSale', undefined);
                search({ ...form.values, daysSinceLastSale: undefined }, true);
              }}
            />
          )}
          {form.values.daysSinceRepriced && (
            <SearchPill
              icon={<PriceChangeIcon />}
              label={`${form.values.daysSinceRepriced} ${pluralize('Day', form.values.daysSinceRepriced)} Since Repriced`}
              onClick={openSearch}
              onClose={() => {
                form.setFieldValue('daysSinceRepriced', undefined);
                search({ ...form.values, daysSinceRepriced: undefined }, true);
              }}
            />
          )}
          {form.values.purchaseDateFrom && form.values.purchaseDateTo && (
            <SearchPill
              icon={<DateRangeIcon />}
              label={`Purchased ${dayjs(form.values.purchaseDateFrom).format('M/D')}-${dayjs(form.values.purchaseDateTo).format('M/D')}`}
              onClick={openSearch}
              onClose={() => {
                form.setFieldValue('purchaseDateFrom', undefined);
                form.setFieldValue('purchaseDateTo', undefined);
                search({ ...form.values, purchaseDateFrom: undefined, purchaseDateTo: undefined }, true);
              }}
            />
          )}
          {form.values.tags?.map((tag) => (
            <SearchPill
              key={tag}
              icon={<TagIcon />}
              label={tag}
              onClick={openSearch}
              onClose={() => {
                form.setFieldValue('tags', form.values.tags?.filter((t) => t !== tag) ?? []);
                search({ ...form.values, tags: form.values.tags?.filter((t) => t !== tag) }, true);
              }}
            />
          ))}
        </Flex>
      </Box>
    )
  );
}

type SearchPillProps = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  onClose: () => void;
};

function SearchPill({ icon, label, onClick, onClose }: SearchPillProps) {
  return (
    <Flex className={classes.tagBtn}>
      <BNButton onClick={onClick} variant="subtle" size="xs" pl={6} fw={500} c="var(--colors-paperReverse)" leftSection={icon}>
        {label}
      </BNButton>
      <Divider orientation="vertical" h={24} color="var(--colors-darkPaper)" style={{ alignSelf: 'center' }} />
      <BNButton variant="subtle" size="xs" px={4} onClick={onClose}>
        <CloseIcon />
      </BNButton>
    </Flex>
  );
}
