export type BarkerCoreEnumsDeliveryMethod = (typeof BarkerCoreEnumsDeliveryMethod)[keyof typeof BarkerCoreEnumsDeliveryMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BarkerCoreEnumsDeliveryMethod = {
  Unknown: 'Unknown',
  Electronic: 'Electronic',
  Physical: 'Physical',
  Instant: 'Instant',
  Special: 'Special',
} as const;
