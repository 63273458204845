import { Box, Center, ComboboxItem, Divider, Flex, Group, Loader, Modal, RingProgress, ScrollArea, Space, Stack, Text, Tooltip, UnstyledButton } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import classes from './AddPDFs.module.css';
import cx from 'clsx';
import { Dropzone, FileWithPath, MIME_TYPES } from '@mantine/dropzone';
import { useCallback, useState } from 'react';
import pluralize from 'pluralize';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { formatDate, formatSeatRange, seatRange } from '../../../utils/formatters';
import { DateFormats } from '../../../utils/globals';
import PictureAsPDFIcon from '../../../components/icons/PictureAsPDF';
import { BNButton } from '../../../components/Button/Button';
import UploadIcon from '../../../components/icons/Upload';
import CloseIcon from '../../../components/icons/Close';
import { BNSelect } from '../../../components/Select/Select';
import { BarkerEventListing } from '../../../types';
import { BarkerCoreModelsDTIItem, BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal, useGetDtiItemsAccountId } from '../../../api';
import CheckIcon from '../../../components/icons/Check';
import { useDTIInventory } from '../DTI.Inventory.hooks';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

type AssignSeat = {
  seat: number;
  file: FileWithPath;
  pageIndex: number;
};

export function AddPdfsDialog({ opened, onClose }: { opened: boolean; onClose: () => void }) {
  const { selectedListing } = useDTIInventory('selectedListing');
  // TODO: Maybe move this into DTI Inventory hooks

  // Prefetch this while they are finding the PDF
  const { data: dtiItems } = useGetDtiItemsAccountId(
    (selectedListing?.vendorProperties as BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal)?.ownerId,
    {
      listing_id: selectedListing?.listingId,
    },
    {
      query: {
        enabled: !!selectedListing && opened,
        select(data) {
          return data.data.items;
        },
      },
    },
  );
  if (!opened || !selectedListing) {
    return null;
  }

  return <AddPdfs onClose={onClose} isOpen={opened} selectedListing={selectedListing} listingItems={dtiItems} />;
}

export function AddPdfs({
  selectedListing,
  listingItems,
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
  selectedListing: BarkerEventListing;
  listingItems: BarkerCoreModelsDTIItem[] | undefined;
}) {
  const vendorProperties = selectedListing.vendorProperties as BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal;
  const [seatAssignments, setSeatAssignments] = useState<{ filename: string; pageIndex: number; seat: number; dtiItemId: number }[]>([]);
  const [availableSeats, setAvailableSeats] = useState<number[]>(seatRange(parseInt(selectedListing.seatFrom), selectedListing.quantityRemaining, vendorProperties.isOddEven));
  const { savePdfs, isSavePdfsLoading } = useDTIInventory('savePdfs', 'isSavePdfsLoading');

  const assignSeat = useCallback(
    ({ seat, file, pageIndex }: AssignSeat) => {
      setAvailableSeats((prev) => {
        if (seat < 0) {
          setSeatAssignments((assignments) => assignments.filter((x) => x.seat !== Math.abs(seat)));
          return [...prev, Math.abs(seat)].sort((a, b) => a - b);
        }
        setSeatAssignments((assignments) => {
          const dtiItemId = listingItems?.find((x) => x.seat === seat)?.item_id ?? 0;
          return [...assignments, { filename: file.name, pageIndex, seat, dtiItemId }];
        });

        return prev.filter((x) => x !== seat);
      });
    },
    [listingItems],
  );

  const IsSeatsAvailable = availableSeats.length > 0;

  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [filePages, setFilePages] = useState<Record<string, number>>({});
  const [selected, setSelected] = useState<{
    file: FileWithPath;
    index: number;
  }>();
  useDidUpdate(() => {
    if (files.length > 0) {
      setSelected({ file: files[0], index: 1 });
    } else {
      // Reset state when files are removed.
      setSeatAssignments([]);
    }
  }, [files]);

  const onSubmit = useCallback(async () => {
    const saving = files.map(async (file) => {
      const pages = filePages[file.name];
      const metadata = `[${Array.from({ length: pages })
        .map((_, index) => {
          const assignment = seatAssignments?.find((x) => x.filename === file.name && x.pageIndex === index);
          if (assignment && assignment.dtiItemId) {
            return assignment.dtiItemId;
          }

          return 'null';
        })
        .join(',')}]`;
      return savePdfs({
        accountId: vendorProperties.ownerId,
        data: {
          file,
          metadata,
        },
      });
    });
    await Promise.all(saving);
    onClose();
  }, [filePages, files, onClose, savePdfs, seatAssignments, vendorProperties.ownerId]);

  return (
    <Modal
      centered
      styles={{ body: { display: 'flex', flex: 1, overflow: 'hidden', padding: 0, maxHeight: 864 }, content: { display: 'flex', flexDirection: 'column', overflow: 'hidden' } }}
      size={files.length !== 0 ? 1260 : 540}
      opened={isOpen}
      closeButtonProps={{ size: 'md' }}
      onClose={() => onClose()}
      title={
        <Group>
          <PictureAsPDFIcon color="var(--colors-iconFill)" size={24} />
          Add PDFs
        </Group>
      }
    >
      <Flex flex={1} direction="column" pos="relative" style={{ overflow: 'hidden' }}>
        <Flex style={{ overflow: 'hidden' }}>
          <Stack w={files.length !== 0 ? '50%' : '100%'} gap={0} bg="var(--colors-paper)" pt="md" pb={0} style={{ zIndex: 2, overflow: 'hidden' }}>
            <Group px="lg" mt={-2} pos="relative" style={{ zIndex: 1 }}>
              <Box>
                <Text size="xs" fw={600}>
                  {selectedListing.event.name}
                </Text>
                <Text p={0} c="var(--colors-gray-5)" size="xs" fw={500}>
                  <span>
                    <span>{formatDate(selectedListing.event.localDateTime, DateFormats.Extended)}</span> - {selectedListing.event.venue.name} · {selectedListing.event.venue.city},{' '}
                    {selectedListing.event.venue.state}
                  </span>
                </Text>
              </Box>
            </Group>
            <Group px="lg" pt="sm" pb="lg" className={classes.gradient}>
              <Group align="start" gap={0} justify="space-between" style={{ border: '1px solid var(--colors-gray-3)', borderRadius: 3 }} p="sm" w="100%">
                <Box style={{ overflow: 'hidden' }}>
                  <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
                    Section
                  </Text>
                  <Text lh={1.25} pt={2} size="sm" style={{ whiteSpaceCollapse: 'collapse', overflowWrap: 'break-word' }}>
                    {selectedListing?.section}
                  </Text>
                </Box>
                <Box style={{ overflow: 'hidden' }}>
                  <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
                    Row
                  </Text>
                  <Text lh={1.25} pt={2} size="sm" style={{ whiteSpaceCollapse: 'collapse', overflowWrap: 'break-word' }}>
                    {selectedListing?.row}
                  </Text>
                </Box>
                <Box w="16.666667%">
                  <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
                    Qty
                  </Text>
                  <Group gap={4}>
                    <Text size="sm">{selectedListing.quantityRemaining}</Text>
                  </Group>
                </Box>
                <Box w="33.3333%">
                  <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
                    Seats
                  </Text>
                  <Group gap={4}>
                    <Text size="sm">{formatSeatRange(selectedListing.seatFrom, selectedListing.seatThru, selectedListing.quantityRemaining)}</Text>
                  </Group>
                </Box>
              </Group>
            </Group>
            {files.length !== 0 && (
              <Stack flex={1} gap={0} style={{ overflow: 'hidden', borderBottom: '1px solid var(--colors-divider)' }}>
                <ScrollArea type="auto" ml="lg" mr={0}>
                  <Stack gap={4} justify="center" align="left">
                    {files.map((file, index) => (
                      <PdfDocumentItem
                        key={index}
                        assignSeat={assignSeat}
                        availableSeats={availableSeats}
                        file={file}
                        files={files}
                        index={index}
                        selection={selected}
                        onSelectionChange={setSelected}
                        filePages={(filename, pageCount) => setFilePages((prev) => ({ ...prev, [filename]: pageCount }))}
                        onDelete={(_file) => setFiles((prev) => prev.filter((x) => x !== _file))}
                      />
                    ))}
                  </Stack>
                </ScrollArea>
              </Stack>
            )}
            <Stack h={152} px="lg" pt="md" style={{ overflow: 'hidden' }}>
              <Dropzone accept={[MIME_TYPES.pdf]} multiple onDrop={(x) => setFiles((prev) => [...prev, ...x])} loaderProps={{ mt: -48, c: 'var(--colors-gray-5)', size: 'md' }}>
                <Dropzone.Accept>
                  <Group
                    className={classes.dropzoneAccept}
                    justify="center"
                    gap="xl"
                    mih={120}
                    bg="var(--colors-hoverBg)"
                    style={{ pointerEvents: 'none', border: '1px dashed var(--colors-hoverBorder)', borderRadius: 3 }}
                  >
                    <Center bg="var(--colors-opacity-hover)" c="var(--colors-hoverBorder)" h={44} w={44} style={{ borderRadius: 100 }}>
                      <UploadIcon size={24} color="var(--colors-iconFill)" />
                    </Center>
                    <div>
                      <Text size="md" inline>
                        Drag PDF here or click to select files
                      </Text>
                      <Text size="xs" c="var(--colors-gray-5)" inline mt={7}>
                        Attach as many files as you like, each file should not exceed 5mb
                      </Text>
                    </div>
                  </Group>
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <Group
                    className={classes.dropzoneReject}
                    justify="center"
                    gap="xl"
                    mih={120}
                    style={{ pointerEvents: 'none', border: '1px dashed var(--colors-gray-3)', borderRadius: 3 }}
                  >
                    <Center c="var(--colors-gray-5)" h={44} w={44} style={{ borderRadius: 100, border: '1px solid var(--colors-opacity-hover)' }}>
                      <CloseIcon size={24} color="var(--colors-red-error)" />
                    </Center>
                    <div>
                      <Text size="md" inline>
                        Drag PDF here or click to select files
                      </Text>
                      <Text size="xs" c="var(--colors-gray-5)" inline mt={7}>
                        Attach as many files as you like, each file should not exceed 5mb
                      </Text>
                    </div>
                  </Group>
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <Group className={classes.dropzoneIdle} justify="center" gap="xl" mih={120} style={{ border: '1px dashed var(--colors-gray-3)', borderRadius: 3 }}>
                    <Center bg="var(--colors-opacity-hover)" c="var(--colors-gray-5)" h={44} w={44} style={{ borderRadius: 100 }}>
                      <UploadIcon size={24} color="var(--colors-gray-5)" />
                    </Center>
                    <div>
                      <Text size="md" inline>
                        {files.length ? 'Drag additional' : 'Drag'} PDF{files.length ? 's' : ''} here or click to select files
                      </Text>
                      <Text size="xs" c="var(--colors-gray-5)" inline mt={7}>
                        Attach as many files as you like, each file should not exceed 5mb
                      </Text>
                    </div>
                  </Group>
                </Dropzone.Idle>
              </Dropzone>
              <Space h={0} />
            </Stack>
          </Stack>
          {files.length !== 0 && (
            <Stack w="50%" mah="100%" h={812} bg="var(--colors-gray-0)" align="center" justify="center" py={8} className={classes.largePreview}>
              <ScrollArea scrollbars="xy" type="auto" mx="xs" w="calc(100% - 16px)" className={classes.pdfPreviewScrollarea}>
                <ImagePreview selected={selected} />
              </ScrollArea>
            </Stack>
          )}
        </Flex>
        {files.length !== 0 && (
          <Flex h={52} pl="lg" align="center" justify="space-between" style={{ borderTop: '1px solid var(--colors-divider)', flexShrink: 0 }}>
            <Flex align="center" justify="start" w="100%" gap="md">
              <>
                <Group gap={6} align="center">
                  {!IsSeatsAvailable ? (
                    <Center h={24} w={24} bg="var(--colors-successButton)" style={{ borderRadius: 100 }}>
                      <CheckIcon color="white" />
                    </Center>
                  ) : (
                    <RingProgress
                      size={24}
                      thickness={2}
                      sections={[
                        { value: ((selectedListing.quantityRemaining - availableSeats.length) / selectedListing.quantityRemaining) * 100, color: 'var(--colors-successButton)' },
                      ]}
                      rootColor="var(--colors-gray-2)"
                    />
                  )}
                  <Text>
                    <strong>
                      {selectedListing.quantityRemaining - availableSeats.length} / {selectedListing.quantityRemaining}
                    </strong>{' '}
                    Tickets Assigned
                  </Text>
                </Group>
                <Divider orientation="vertical" color="var(--colors-divider)" />
                <Text style={{ whiteSpace: 'nowrap' }}>
                  <strong>{files.length > 0 && `${files.length}`}</strong> {pluralize('File', files.length)} Attached
                </Text>
              </>
            </Flex>

            <Flex w="100%" justify="end" gap={12} align="center" px={16}>
              <BNButton size="xs" w={80} onClick={() => onClose()} disabled={isSavePdfsLoading}>
                Cancel
              </BNButton>
              <Tooltip
                label={!listingItems?.length ? 'Unable to save. Fetching listing items' : 'No tickets assigned'}
                position="top"
                withArrow
                disabled={!!listingItems?.length && seatAssignments.length > 0}
              >
                <div>
                  <BNButton
                    disabled={!listingItems?.length || seatAssignments.length === 0}
                    loading={isSavePdfsLoading}
                    type="submit"
                    size="xs"
                    variant="filled"
                    color="green"
                    w={80}
                    onClick={() => onSubmit()}
                  >
                    Save
                  </BNButton>
                </div>
              </Tooltip>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Modal>
  );
}

function ImagePreview({ selected }: { selected: { file: FileWithPath; index: number } | undefined }) {
  if (!selected) {
    return null;
  }
  return (
    <Document
      file={selected.file}
      loading={
        <Center h={792} mah="100%">
          <Loader type="dots" />
        </Center>
      }
    >
      <Page
        pageNumber={selected.index}
        loading={
          <Center h={792} mah="100%">
            <Loader type="dots" />
          </Center>
        }
      />
    </Document>
  );
}

function PdfDocumentItem({
  availableSeats,
  file,
  files,
  index,
  selection,
  onSelectionChange,
  assignSeat,
  filePages,
  onDelete,
}: {
  availableSeats: number[];
  file: FileWithPath;
  files: FileWithPath[];
  index: number;
  selection?: { file: FileWithPath; index: number };
  onSelectionChange?: ({ file, index }: { file: FileWithPath; index: number }) => void;
  assignSeat: (assignment: AssignSeat) => void;
  filePages: (file: string, pageCount: number) => void;
  onDelete: (file: FileWithPath) => void;
}) {
  const [pages, _setPages] = useState(0);
  const setPages = useCallback(
    (numPages: number) => {
      _setPages(numPages);
      filePages(file.name, numPages);
    },
    [file.name, filePages],
  );

  return (
    <Document
      file={file}
      onLoadSuccess={({ numPages }: { numPages: number }) => setPages(numPages)}
      loading={
        <Center mih={300}>
          <Loader type="dots" />
        </Center>
      }
    >
      <Flex w="100%" align="center" pos="sticky" top={0} justify="space-between" pr="md" bg="var(--colors-paper)" py="xs" style={{ zIndex: 100 }}>
        <Text fw={600} tt="uppercase" lts="0.025em" flex={1}>
          {file.name}
        </Text>
        <BNButton size="compact-xs" variant="subtle" onClick={() => onDelete(file)}>
          Delete PDF
        </BNButton>
      </Flex>
      <Flex gap="lg" wrap="wrap" p="xs" px={4}>
        {Array.from({ length: pages }).map((_, pageIndex) => (
          <PdfPageItem
            key={pageIndex}
            assignSeat={assignSeat}
            availableSeats={availableSeats}
            file={file}
            onSelectionChange={onSelectionChange}
            pageIndex={pageIndex}
            selection={selection}
          />
        ))}
      </Flex>
      {index + 1 !== files.length ? <Divider color="var(--colors-divider)" w="100%" mt="xs" mb="sm" /> : <Space h={24} />}
    </Document>
  );
}

function PdfPageItem({
  assignSeat,
  availableSeats,
  file,
  onSelectionChange,
  pageIndex,
  selection,
}: {
  onSelectionChange: (({ file, index }: { file: FileWithPath; index: number }) => void) | undefined;
  file: FileWithPath;
  pageIndex: number;
  selection: { file: FileWithPath; index: number } | undefined;
  availableSeats: number[];
  assignSeat: (assignment: AssignSeat) => void;
}) {
  const [assignment, setAssignment] = useState<string | null>();
  return (
    <Box className={classes.thumbnailContainer} onClick={() => onSelectionChange?.({ file, index: pageIndex + 1 })}>
      <Stack align="center" gap="xs" pb="sm">
        <UnstyledButton
          className={cx(classes.thumbnail, selection?.file === file && selection?.index === pageIndex + 1 && classes.thumbnailSelected)}
          style={{ pointerEvents: 'none' }}
        >
          <Page
            pageNumber={pageIndex + 1}
            width={122}
            height={160}
            loading={
              <Center w={122} h={160}>
                <Loader type="oval" size="xs" />
              </Center>
            }
          />
          {assignment && (
            <Center bg="var(--colors-successButton)" style={{ borderRadius: 100 }} h={24} w={24} pos="absolute" top={8} left={8}>
              <CheckIcon color="white" />
            </Center>
          )}
          <Flex
            bg={selection?.file === file && selection?.index === pageIndex + 1 ? 'var(--colors-selectedBorder)' : '#e6e6e6'}
            pos="absolute"
            bottom={10}
            right={10}
            px={6}
            style={{ borderRadius: 4 }}
          >
            <Text fw={600} c={selection?.file === file && selection?.index === pageIndex + 1 ? 'var(--colors-selectedBg)' : '#525252'} size="xs">
              Page {pageIndex + 1}
            </Text>
          </Flex>
        </UnstyledButton>
        <BNSelect
          placeholder="Assign to"
          w={128}
          size="xs"
          clearable
          disabled={!availableSeats.length && !assignment}
          value={assignment}
          data={availableSeats
            .map((seat) => ({ label: `Seat ${seat}`, value: seat.toString() }) satisfies ComboboxItem)
            .concat(assignment ? [{ label: `Seat ${assignment}`, value: assignment }] : [])}
          onChange={(value) => {
            if (value) {
              const seat = parseInt(value);
              assignSeat({ seat, file, pageIndex });
              setAssignment(value);
            } else if (assignment) {
              assignSeat({ seat: -assignment, file, pageIndex });
              setAssignment(null);
            }
          }}
        />
      </Stack>
    </Box>
  );
}
