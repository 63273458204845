import { RowDragEndEvent, RowDragMoveEvent } from '@ag-grid-community/core';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RefObject, useCallback, useEffect, useRef } from 'react';
import { BarkerCoreModelsInventoryListing } from '../../api';
import { v4 as uuid } from 'uuid';
import {
  applyRuleToSelectedGroupedListingsAtom,
  listingsMatchingSelectedRuleIdAtom,
  mergedEventListingsUnfilteredRulesAtom,
  pendingListingUpdatesAtom,
  pendingListingUpdatesByListingIdAtom,
  rejectPendingUpdatesByListingIdAtom,
  rowDraggingAtom,
  ruleStateAtom,
  selectedTenantListingIdAtom,
  transientGlobalStateAtom,
  updateListingsAtom,
} from '../../data/atoms';
import { BarkerEventListing } from '../../types';
import { useRuleState } from '../../data/RuleState';
import { useDidUpdate } from '@mantine/hooks';
import { AgGridReact } from '@ag-grid-community/react';

export function useInventoryDragEvents({
  gridRef,
  detailGridRef,
}: {
  gridRef: RefObject<AgGridReact<BarkerEventListing>>;
  detailGridRef: RefObject<Partial<AgGridReact<BarkerEventListing> | undefined>>;
}) {
  const rejectPendingUpdates = useSetAtom(rejectPendingUpdatesByListingIdAtom);
  const eventListings = useAtomValue(mergedEventListingsUnfilteredRulesAtom);
  const updateListing = useSetAtom(updateListingsAtom);
  const [selectedListingId, setSelectedListingId] = useAtom(selectedTenantListingIdAtom);
  const { validateDirtyRuleState } = useRuleState('isLocalRule', 'validateDirtyRuleState');
  const [rule, setRule] = useAtom(ruleStateAtom);
  const setTransientGlobalState = useSetAtom(transientGlobalStateAtom);
  const setPendingUpdates = useSetAtom(pendingListingUpdatesAtom);
  const pendingUpdatesByListId = useAtomValue(pendingListingUpdatesByListingIdAtom);
  const pendingUpdatesByListIdRef = useRef(pendingUpdatesByListId);
  const applyRuleToSelectedGroupedListings = useSetAtom(applyRuleToSelectedGroupedListingsAtom);
  const listingsMatchingRuleId = useAtomValue(listingsMatchingSelectedRuleIdAtom);

  useEffect(() => {
    const obv = new MutationObserver((mutations) => {
      for (const m of mutations) {
        if (m.addedNodes.length) {
          m.addedNodes.forEach((node) => {
            if (node instanceof Element && node.classList.contains('ag-dnd-ghost')) {
              node.classList.add('row-drag');
            }
          });
        }
      }
    });
    obv.observe(document.body, { childList: true });
  }, []);

  useDidUpdate(() => {
    pendingUpdatesByListIdRef.current = pendingUpdatesByListId;
  }, [pendingUpdatesByListId]);

  const calculateNextRuleTiers = useCallback(
    (ruleId: string, listing: BarkerCoreModelsInventoryListing, destinationListing: BarkerCoreModelsInventoryListing, direction?: 'up' | 'down' | 'master' | null) => {
      const ruleListings = eventListings.filter((x) => x.ruleId === ruleId).sort((a, b) => (a.ruleTier || 0) - (b.ruleTier || 0));
      // Creating a new rule and assign both listings to it
      if (ruleListings.length === 0 || ruleListings.length === 1) {
        return [100, 0];
      }

      // Assigning a rule from the outside to a listing that is already in a rule
      if (direction === 'master') {
        const lastListingRuleTier = (ruleListings.pop()?.ruleTier || 50) * 2;
        return [lastListingRuleTier, destinationListing.ruleTier || 0];
      }

      const nextRuleTierIndex = ruleListings.findIndex((x) => x.listingId === destinationListing.listingId) + 1;
      const nextRuleTier = ruleListings[nextRuleTierIndex]?.ruleTier
        ? (ruleListings[nextRuleTierIndex]!.ruleTier! + destinationListing.ruleTier!) / 2
        : (destinationListing.ruleTier || 1) + (direction && direction === 'down' ? -1 : 1);

      // Detail listing is dropped onto anchor listing
      if (destinationListing.ruleTier === 0 && listing.ruleId === destinationListing.ruleId) {
        return [0, nextRuleTier];
      }

      // Handle condition where ruleTiers got sync'd up somehow
      if (nextRuleTier && nextRuleTier === destinationListing.ruleTier) {
        return [destinationListing.ruleTier + 1, nextRuleTier + 2];
      }

      // if (direction === 'up') {
      //   return [destinationListing.ruleTier - 1 || 50, nextRuleTier || 0];
      // } else if (direction === 'down') {
      //   return [destinationListing.ruleTier + 1 || 50, nextRuleTier || 0];
      // }

      // Changing the order of a listing within a rule group
      return [destinationListing.ruleTier || 50, nextRuleTier || 0];
    },
    [eventListings],
  );

  // Ref used to pivot which group is hovered over when dragging
  const modId = useRef<string | undefined>();
  const setIsRowDragging = useSetAtom(rowDraggingAtom);

  const onMasterRowDragMove = useCallback(
    (e: RowDragMoveEvent<BarkerEventListing>) => {
      setIsRowDragging(true);
      const ghost = document.querySelector('.ag-dnd-ghost');
      const overALittle = document.elementFromPoint(e.event.clientX, e.event.clientY);
      const newRow = overALittle?.closest('[row-id]');
      const newRowId = newRow?.getAttribute('row-id');
      const masterOverNode = gridRef?.current?.api.getRowNode(newRowId!);
      // @ts-ignore: TS2341
      const detailOverNode = !detailGridRef.current?.api?.destroyCalled ? detailGridRef?.current?.api.getRowNode(newRowId!) : null;
      const floatingClasses = ['remove-from-group', 'change-group-anchor', 'create-group', 'move-inside-group', 'add-to-group', 'disallow-group'];
      const ruleCount = e.overNode?.data?.ruleCount ?? 0;

      // Is Dragging
      document.querySelector('body')?.classList.add('row-dragging');
      // Handle hover-state for the row element hovered over
      const rowsWithClass = document.querySelectorAll('#inventory-grid .hover-over');
      rowsWithClass.forEach((element) => element.classList.remove('hover-over', 'dir-up', 'dir-down'));

      if (ghost) {
        // Reset classes on each drag event.
        floatingClasses.forEach((c) => ghost.classList.remove(c));

        // Show header dropzone when dragging a grouped listing
        if (e.node.level === 0) {
          const currentGroupHeaderDropZone = document.querySelector(`[data-overlay-id="${e.node.data?.eventId}"]`);
          currentGroupHeaderDropZone?.parentElement?.classList.add('show-dropzone');
        }

        // Create Group Condition
        if (
          (e.node.level === 1 && masterOverNode?.group) ||
          (e.node.data?.tenantIdEventId !== e.overNode?.data?.tenantIdEventId && e.node.data?.tenantIdEventId !== masterOverNode?.key)
        ) {
          ghost.classList.add('disallow-group');
        } else if (e.node.level === 1 && e.overNode?.level === 1 && (ruleCount === 0 || ruleCount === 1) && e.node.id !== e.overNode.id) {
          // Handles creating a group if overNode is not already a group
          ghost.classList.add('create-group');
        } else if (
          (e.node.level === 1 && e.overNode?.level === 2) ||
          (!e.overNode?.group && e.node.level === 1 && e.overNode?.level === 1 && ruleCount > 1 && e.node.id !== e.overNode.id)
        ) {
          // Handles adding from master to detail on the anchor or the details overlay.
          ghost.classList.add('add-to-group');
          if (e.node.id !== e.overNode.id) {
            if (detailOverNode) {
              e.overNode = detailOverNode;
              const hoveredRow = document.querySelector<HTMLElement>(`[row-id="${e.overNode.id}"]`)!;
              const rowLocation = hoveredRow.getBoundingClientRect();
              // get half of the row height so we can detect whether the dropzone should be above or below the overnode
              // add arrows to inner listings
              if (!e.overNode?.group) {
                // is it in the top half of the row?
                if (e.event.clientY - rowLocation.top < rowLocation.height / 2) {
                  hoveredRow?.classList.add('dir-up');
                } else {
                  hoveredRow?.classList.add('dir-down');
                }
              }
              // show dropzone in header
              hoveredRow?.classList.add('hover-over');
            }
          }
        } else if (!e.overNode?.group && !masterOverNode && e.node.level === 0 && e.overNode?.level === 0 && e.node.id !== e.overNode.id) {
          // Handles moving inside of a group
          ghost.classList.add('move-inside-group');
          const hoveredRow = document.querySelector<HTMLElement>(`[row-id="${e.overNode.id}"]`);
          // Handle details grid additional conditions
          if (e.node.id !== e.overNode.id) {
            const rowHeight = e.overNode?.rowHeight || 36;
            // get half of the row height so we can detect whether the dropzone should be above or below the overnode
            const rowHalf = rowHeight / 2;
            // add arrows to inner listings
            if (!e.overNode.group) {
              if (e.overNode.rowTop) {
                // is it in the top half of the row?
                if (e.y < e.overNode.rowTop + rowHalf) {
                  hoveredRow?.classList.add('dir-up');
                } else {
                  hoveredRow?.classList.add('dir-down');
                }
              } else if (e.y < rowHalf) {
                // is it in the top half of the row?
                hoveredRow?.classList.add('dir-up');
              } else {
                hoveredRow?.classList.add('dir-down');
              }
            } else {
              // show dropzone in header
              hoveredRow?.classList.add('hover-over');
            }
          }
        } else if (e.node.level === 0 && e.overNode?.level === 1 && e.node.data?.ruleId === e.overNode.data?.ruleId && !masterOverNode?.group) {
          // Handles changing anchor if overNode is the anchor andruleId the same ruleId
          ghost.classList.add('change-group-anchor');
        } else if (
          (e.node.level === 0 && e.node.data?.tenantIdEventId === e.overNode?.key) ||
          (e.node.level === 0 && e.overNode?.level === 1 && e.node.data?.ruleId !== e.overNode.data?.ruleId)
        ) {
          // Handles removing from group on any master row that isn't the anchor
          ghost.classList.add('remove-from-group');
        } else if (e.node.level === 0 && masterOverNode && masterOverNode.group) {
          // Handles removing from group when overNode is group header
          ghost.classList.add('remove-from-group');
        } else if (
          masterOverNode &&
          !masterOverNode.group &&
          masterOverNode.data?.ruleId === e.node.data?.ruleId &&
          e.node?.id !== masterOverNode.id &&
          e.node.id !== e.overNode?.id
        ) {
          ghost.classList.add('change-group-anchor');
        }
      }

      // Add hover class to the row it's over, as long as it isn't itself
      if (e.overNode && e.overNode !== e.node) {
        const hoveredRow = document.querySelector<HTMLElement>(`[row-id="${e.overNode.id}"]`);

        // Exclude detail container from hover-over class
        // Don't hover from details grid to master grid
        // Don't hover if not on the same tenantEventId
        if (
          !(
            (e.node.level === 0 && e.overNode.id?.includes('detail_')) ||
            (e.node.level === 0 && e.overNode.level === 1) ||
            e.node.data?.tenantIdEventId !== e.overNode.data?.tenantIdEventId
          )
        ) {
          hoveredRow?.classList.add('hover-over');
        }

        // Show the header dropzone if the dragged node is from a group
        if (e.node.level === 0) {
          const currentGroupHeaderDropZone = document.querySelector(`[data-overlay-id="${e.node.data?.eventId}"]`);
          currentGroupHeaderDropZone?.parentElement?.classList.add('show-dropzone');

          // Event header when sticky
          if (masterOverNode?.leafGroup) {
            newRow?.classList.add('hover-over');
          }
        } else {
          // include the details grid in the hover only if the dragged node is from outside of the group
          const hoveredDetailsMask = document.querySelector<HTMLElement>(`[row-id="detail_${e.overNode.id}"]`);
          hoveredDetailsMask?.classList.add('hover-over');

          if (e.overNode.id?.includes('detail_')) {
            modId.current = e.overNode.id?.split('_').pop();
            const hoveredAnchorWithMask = document.querySelector<HTMLElement>(`[row-id="${modId}"]`);
            hoveredAnchorWithMask?.classList.add('hover-over');
          }
        }
      }
    },
    [detailGridRef, gridRef, setIsRowDragging],
  );

  /**
   * Handles dragging listings on the outer grid to the detail grid. (aka. Adding RuleID)
   * Also creates a rule if the anchor listing does not already have one configured.
   */
  const onMasterRowDragEnd = useCallback(
    async (e: RowDragEndEvent<BarkerEventListing>) => {
      // add class to ag-grid drag ghost element for styling
      // Is Dragging
      document.querySelector('body')?.classList.remove('row-dragging');
      const ghost = document.querySelector('.ag-dnd-ghost');
      if (e.node.data && e.overNode?.data && e.node.data.tenantId === e.overNode.data.tenantId && e.node.data.eventId === e.overNode.data.eventId) {
        ghost?.classList.add('row-drag');

        // Clear dropzone overlay
        const activeDropzone = document.querySelector('.show-dropzone');
        activeDropzone?.classList.remove('show-dropzone');

        // Don't drop yourself on your own head.
        if (e.node === e.overNode) {
          return;
        }
        // Don't go from details to master.
        if (e.node.level === 0) {
          return;
        }
        // Escape if the node being dragged is the same ruleId;
        if (e.overNode.master && e.overNode.data.ruleId === e.node.data.ruleId) {
          return;
        }

        // Anchor rule must be selected and dirty state must be validated before making it selected.
        if (e.api.getSelectedNodes().length > 0 && e.overNode.data.listingId !== e.api.getSelectedNodes()[0].data?.listingId) {
          if (!(await validateDirtyRuleState())) {
            return;
          }
        }

        // Have to set listing ID here to prevent rule creation from using the wrong selectedListing
        // setSelectedListingId(e.overNode.data.tenantIdListingId, { keepRule: e.overNode.data.tenantIdListingId === selectedListingId.listingId });
        setSelectedListingId(e.overNode.data.tenantIdListingId);

        let { ruleId } = e.overNode.data;
        const { listingId: anchorListingId } = e.overNode.data;
        const { tenantIdListingId } = e.node.data;
        if (!ruleId) {
          if (e.overNode.data.tenantIdListingId === selectedListingId.toStringTyped()) {
            setRule((r) => ({ ...r, isAutoPriced: true }));
            ruleId = rule.ruleId;
          } else {
            const newRuleId = uuid();
            setRule((r) => ({ ...r, ruleId: newRuleId, isAutoPriced: true }), { isLocalRule: true });
            ruleId = newRuleId;
          }
        }
        setTransientGlobalState((s) => ({ ...s, autoUpdateAutoPricedListPriceRuleId: ruleId, expandGroupByListingId: e.overNode?.data?.tenantIdListingId! }));

        const { ...listing } = eventListings.find((x) => x.tenantIdListingId === tenantIdListingId)!;
        const overALittle = document.elementFromPoint(e.event.clientX, e.event.clientY);
        const newRow = overALittle?.closest('[row-id]');
        const newRowId = newRow?.getAttribute('row-id');

        // @ts-ignore: TS2341
        const newOverNode = !detailGridRef.current?.api?.destroyCalled ? detailGridRef?.current?.api?.getRowNode(newRowId!) : null;
        const dirUpOrDown = newOverNode ? (document.querySelector(`[row-id="${newOverNode.id}"`)?.classList.contains('dir-down') ? 'down' : 'up') : null;
        if (newOverNode) {
          e.overNode = newOverNode;
        }
        const { ...anchorListing } = e.overNode.data;
        const [listingNewRuleTier, anchorNewRuleTier] = calculateNextRuleTiers(ruleId!, listing, e.overNode.data!, e.overNode.master || e.overNode.detail ? 'master' : dirUpOrDown);

        if (anchorListing.ruleId !== ruleId) {
          setPendingUpdates((prev) => [
            ...prev,
            {
              listingId: anchorListing.listingId,
              tenantId: anchorListing.tenantId,
              property: 'ruleId',
              value: ruleId,
              previousValue: anchorListing.ruleId,
            },
          ]);
        }

        if (anchorListing.ruleTier !== anchorNewRuleTier) {
          setPendingUpdates((prev) => [
            ...prev,
            {
              listingId: anchorListing.listingId,
              tenantId: anchorListing.tenantId,
              property: 'ruleTier',
              value: anchorNewRuleTier,
              previousValue: anchorListing.ruleTier,
            },
          ]);
        }

        if (listing.ruleId !== ruleId) {
          setPendingUpdates((prev) => [
            ...prev,
            {
              listingId: listing.listingId,
              tenantId: listing.tenantId,
              property: 'ruleId',
              value: ruleId,
              previousValue: listing.ruleId,
            },
          ]);
        }

        if (listing.ruleTier !== listingNewRuleTier) {
          setPendingUpdates((prev) => [
            ...prev,
            {
              listingId: listing.listingId,
              tenantId: listing.tenantId,
              property: 'ruleTier',
              value: listingNewRuleTier,
              previousValue: listing.ruleTier,
            },
          ]);
        }

        if (!dirUpOrDown && e.overNode && !e.overNode.master) {
          e.overNode.master = true;
        }
        listing.ruleId = ruleId;
        listing.ruleTier = listingNewRuleTier;
        anchorListing.ruleId = ruleId;
        anchorListing.ruleTier = anchorNewRuleTier;
        // Note: Should fix update anchor listing automatically
        anchorListing.ruleCount = anchorListing?.ruleCount ? anchorListing.ruleCount + 1 : 2;

        updateListing(anchorListing);
        updateListing(listing);
        setTransientGlobalState((s) => ({
          ...s,
          applyGroupListingRule: true,
        }));
        if (selectedListingId.listingId === anchorListingId) {
          applyRuleToSelectedGroupedListings();
        }
        const rowsWithClass = document.querySelectorAll('#inventory-grid .hover-over');
        rowsWithClass.forEach((element) => element.classList.remove('hover-over', 'dir-up', 'dir-down'));
      }
    },
    [
      setSelectedListingId,
      setTransientGlobalState,
      eventListings,
      detailGridRef,
      calculateNextRuleTiers,
      updateListing,
      selectedListingId,
      validateDirtyRuleState,
      setRule,
      rule.ruleId,
      setPendingUpdates,
      applyRuleToSelectedGroupedListings,
    ],
  );

  /**
   * Handles dragging listings on the detail grid to the outer grid. (aka. Removing RuleID)
   * Handles replacing anchor listing from a detail listing
   */
  const onDetailMasterRowDragEnd = useCallback(
    async (e: RowDragEndEvent<BarkerEventListing>) => {
      const tenantIdListingId = e.node.data?.tenantIdListingId!;
      const listing = eventListings.find((x) => x.tenantIdListingId === tenantIdListingId)!;
      const anchorNode = gridRef.current!.api.getRowNode(selectedListingId.toString())!;
      // Is Dragging
      document.querySelector('body')?.classList.remove('row-dragging');

      // Clear dropzone overlay
      const activeDropzone = document.querySelector('.show-dropzone');
      activeDropzone?.classList.remove('show-dropzone');

      // Dropping on detail node mask wrapper should do nothing. Group means it's the group header which is allowed.
      if (e.overNode?.level === 2 && !e.overNode.group) {
        return;
      }

      const { ruleId } = listing;
      if (
        (e.overNode?.data?.tenantIdEventId === e.node.data?.tenantIdEventId || e.node.data?.tenantIdEventId === e.overNode?.key) &&
        e.node.data?.ruleId !== e.overNode?.data?.ruleId
      ) {
        if (listingsMatchingRuleId.length === 2 && anchorNode.data) {
          anchorNode.setExpanded(false);
          anchorNode.master = false;
          anchorNode.data.ruleCount = 1;
          requestAnimationFrame(() => {
            gridRef.current!.api.redrawRows({ rowNodes: [anchorNode] });
          });
        }
        // Handle condition when ruleId is pending and then is removed
        const hasPendingRuleSwap = pendingUpdatesByListIdRef.current[listing.listingId]?.find((x) => x.property === 'ruleId');
        if (hasPendingRuleSwap) {
          rejectPendingUpdates(listing.listingId);
        } else if (ruleId && anchorNode.data) {
          setPendingUpdates((prev) => [
            ...prev,
            {
              listingId: listing.listingId,
              tenantId: listing.tenantId,
              property: 'ruleId',
              value: null,
              previousValue: listing.ruleId,
            },
            {
              listingId: listing.listingId,
              tenantId: listing.tenantId,
              property: 'ruleTier',
              value: null,
              previousValue: listing.ruleTier,
            },
            {
              listingId: listing.listingId,
              tenantId: listing.tenantId,
              property: 'pricerStatusId',
              value: 'None',
              previousValue: listing.pricerStatusId,
            },
          ]);
          updateListing([anchorNode.data, { ...listing, ruleId: null, ruleTier: null, pricerStatusId: 'None' }]);
        }
        applyRuleToSelectedGroupedListings();
      } else if (e.overNode?.master && e.node.data && e.overNode?.data && e.node.data.tenantId === e.overNode.data.tenantId && e.node.data.eventId === e.overNode.data.eventId) {
        // ANCHOR SWAP - section
        const anchorListing = e.overNode.data!;
        const [listingNewRuleTier, destinationNewRuleTier] = calculateNextRuleTiers(ruleId!, listing, anchorListing);
        setPendingUpdates((prev) => [
          ...prev,
          {
            listingId: listing.listingId,
            tenantId: listing.tenantId,
            property: 'ruleTier',
            value: listingNewRuleTier,
            previousValue: listing.ruleTier,
          },
          {
            listingId: anchorListing.listingId,
            tenantId: anchorListing.tenantId,
            property: 'ruleTier',
            value: destinationNewRuleTier,
            previousValue: anchorListing.ruleTier,
          },
        ]);
        listing.ruleTier = listingNewRuleTier;
        anchorListing.ruleTier = destinationNewRuleTier;
        listing.ruleCount = anchorListing.ruleCount;
        updateListing([listing, anchorListing]);
        setSelectedListingId(listing.tenantIdListingId, { keepRule: true });
        setTransientGlobalState((s) => ({
          ...s,
          expandGroupByListingId: listing.tenantIdListingId,
          // refreshDetailsCells: true,
          applyGroupListingRule: true,
        }));
        applyRuleToSelectedGroupedListings();
      }
    },
    [
      applyRuleToSelectedGroupedListings,
      calculateNextRuleTiers,
      eventListings,
      gridRef,
      listingsMatchingRuleId.length,
      rejectPendingUpdates,
      selectedListingId,
      setPendingUpdates,
      setSelectedListingId,
      setTransientGlobalState,
      updateListing,
    ],
  );

  /**
   * Handles dragging and dropping listings inside of the details grid
   */
  const onDetailRowDragEnd = useCallback(
    async (e: RowDragEndEvent<BarkerEventListing>) => {
      // Is Dragging
      document.querySelector('body')?.classList.remove('row-dragging');

      const overALittle = document.elementFromPoint(e.event.clientX, e.event.clientY);
      const newRow = overALittle?.closest('[row-id]');
      const newRowId = newRow?.getAttribute('row-id');
      const masterOverNode = gridRef?.current?.api.getRowNode(newRowId!);

      // Details handler now triggers instead of master. Parlay to master handler. Sticky header removal
      if (masterOverNode?.group) {
        e.overNode = masterOverNode;
        onDetailMasterRowDragEnd(e);
        return;
      }

      // Details handler now triggers instead of master. Parlay to master handler. Anchor Swap
      if (masterOverNode?.master && masterOverNode?.data?.ruleId === e.node.data?.ruleId) {
        e.overNode = masterOverNode;
        onDetailMasterRowDragEnd(e);
        return;
      }

      // document.body.classList.remove('grid-dragging');
      // add class to ag-grid drag ghost element for styling
      const ghost = document.querySelector('.ag-dnd-ghost');
      ghost?.classList.add('row-drag');
      // detect direction
      const dirUpOrDown = document.querySelector(`[row-id="${e.overNode?.id}"`)?.classList.contains('dir-down') ? 'down' : 'up';
      // Clear hovers
      const rowsWithClass = document.querySelectorAll('#inventory-grid .hover-over');
      rowsWithClass.forEach((element) => element.classList.remove('hover-over', 'dir-up', 'dir-down'));

      // Clear dropzone overlay
      const activeDropzone = document.querySelector('.show-dropzone');
      activeDropzone?.classList.remove('show-dropzone');

      if (e.node === e.overNode) {
        return; // Don't do anything if the node is dropped on itself
      }
      // TODO: This will handle when dragging and detail listing onto another detail listing
      const dragListing = e.node.data!;
      const dropListing = e.overNode?.data!;
      const { ruleId } = dragListing;
      const [listingNewRuleTier, destinationNewRuleTier] = calculateNextRuleTiers(ruleId!, dragListing, dropListing);
      const previousDragRuleTier = dragListing.ruleTier;
      const previousDropRuleTier = dropListing.ruleTier;

      if (dirUpOrDown === 'up') {
        dragListing.ruleTier = listingNewRuleTier;
        dropListing.ruleTier = destinationNewRuleTier;
      } else {
        dragListing.ruleTier = destinationNewRuleTier;
        dropListing.ruleTier = listingNewRuleTier;
      }

      updateListing([dragListing, dropListing]);
      setPendingUpdates((prev) => [
        ...prev,
        {
          listingId: dragListing.listingId,
          tenantId: dragListing.tenantId,
          property: 'ruleTier',
          value: dragListing.ruleTier,
          previousValue: previousDragRuleTier,
        },
      ]);
      setPendingUpdates((prev) => [
        ...prev,
        {
          listingId: dropListing.listingId,
          tenantId: dropListing.tenantId,
          property: 'ruleTier',
          value: dropListing.ruleTier,
          previousValue: previousDropRuleTier,
        },
      ]);
      applyRuleToSelectedGroupedListings();
    },
    [applyRuleToSelectedGroupedListings, calculateNextRuleTiers, gridRef, onDetailMasterRowDragEnd, setPendingUpdates, updateListing],
  );

  return { onMasterRowDragEnd, onMasterRowDragMove, onDetailRowDragEnd, onDetailMasterRowDragEnd };
}
