import { Box, Group, Text } from '@mantine/core';
import { MarketplaceColors } from '../../models/marketplaces';
import { getStandardMarketplaceName } from '../../utils/marketplace-utils';

export function MarketplaceIdentifier({ value }: { value: string }) {
  const marketplaceName = value as keyof typeof MarketplaceColors;
  const formattedMarketplaceName = getStandardMarketplaceName(marketplaceName);
  const marketplaceColor = MarketplaceColors[formattedMarketplaceName];
  return (
    <Group gap="xs" align="center" wrap="nowrap" style={{ overflow: 'hidden' }}>
      <Box
        h={13}
        w={13}
        bg={marketplaceColor}
        style={{
          border: '1px solid var(--colors-paper)',
          borderRadius: '13px',
          flexShrink: 0,
        }}
      />
      <Text truncate>{formattedMarketplaceName}</Text>
    </Group>
  );
}
