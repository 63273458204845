import classes from './AdminFooter.tsx.module.css';
import { Container, Group, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import BNLogo from '../../components/icons/BNLogo';
import DTILogoHover from '../../components/icons/DTILogoHover';
import { isDtiHosted } from '../../utils/whitelabel-consts';

interface AdminFooterProps {
  links: { link: string; label: string; links: { link: string; label: string }[]; disabled?: boolean }[];
}

export function AdminFooter({ links }: AdminFooterProps) {
  const items = links.map((link) =>
    link.disabled ? (
      <Text key={link.label} size="sm" color="var(--colors-gray-4)">
        {link.label}
      </Text>
    ) : (
      <Link to={link.link} key={link.label}>
        <Text c="dimmed" size="sm">
          {link.label}
        </Text>
      </Link>
    ),
  );

  return (
    <Container className={classes.inner} py="xl" h="auto" fluid>
      <Group py="xl" justify="space-between" w="100%">
        <Group>
          {isDtiHosted ? <DTILogoHover size={100} /> : <BNLogo />}
          {!isDtiHosted && (
            <Text size="sm" fw={600} c="dimmed">
              Broker Nerds
            </Text>
          )}
        </Group>
        <Group gap={16} className={classes.links}>
          {items}
        </Group>
      </Group>
    </Container>
  );
}
