import { useLocalStorage } from '@mantine/hooks';
import yasml from '@thirtytech/yasml';
import { useCallback, useEffect } from 'react';
import { FilterMode, PricerView } from '../types';
import { useFlag } from '@unleash/proxy-client-react';
import { useSetAtom } from 'jotai';
import { filterModeAtom } from '../data/atoms';

function HomeState() {
  const [isTrendsCollapsed, setIsTrendsCollapsed] = useLocalStorage({
    key: 'setting-trendsPanel',
    defaultValue: false,
    getInitialValueInEffect: false,
  });

  const handleTrendsToggle = useCallback(() => {
    setIsTrendsCollapsed((x) => !x);
  }, [setIsTrendsCollapsed]);
  const setFilterModeAtom = useSetAtom(filterModeAtom);
  const filterModeFlag = useFlag('locked-filters');
  useEffect(() => setFilterModeAtom(filterModeFlag ? FilterMode.Locked : FilterMode.Standard), [filterModeFlag, setFilterModeAtom]);

  return { isTrendsCollapsed, setIsTrendsCollapsed, handleTrendsToggle };
}

export const { Provider: HomeStateProvider, useSelector: useHomeState } = yasml(HomeState);
