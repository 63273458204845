import { IconPropsBase } from './IconPropsBase';

export default function ExportNotesIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="m655.308-148.078 106.615-106.615v87.385h35.384V-315H649.616v35.385H737L630.385-173l24.923 24.922Zm-442.999 8.077q-29.923 0-51.115-21.193-21.193-21.192-21.193-51.115v-535.382q0-29.923 21.193-51.115 21.192-21.193 51.115-21.193h535.382q29.923 0 51.115 21.193 21.193 21.192 21.193 51.115v252.998Q805.23-501 790.422-505.384q-14.807-4.385-30.422-7v-235.307q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H212.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v535.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h234.307q2.23 16.615 6.615 31.422 4.384 14.808 10.692 28.577H212.309ZM200-240v40V-760-512.384v-3V-240Zm90.001-54.616h160.692q2.615-15.615 7.769-30.423 5.154-14.807 11.231-29.576H290.001v59.999Zm0-155.385h253.616q25.846-21.922 55.152-36.73 29.307-14.807 62.768-21.038v-2.23H290.001v59.998Zm0-155.384h379.998v-59.999H290.001v59.999ZM720-57.694q-74.922 0-127.461-52.538-52.538-52.538-52.538-127.46 0-74.923 52.538-127.461Q645.078-417.691 720-417.691t127.461 52.538q52.538 52.538 52.538 127.461 0 74.922-52.538 127.46Q794.922-57.694 720-57.694Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="m665.308-152.078 80.615-80.615v57.385h43.384V-307H657.616v43.385H715L634.385-183l30.923 30.922Zm-436.999-11.923q-26.623 0-45.465-18.843-18.843-18.842-18.843-45.465v-503.382q0-26.623 18.843-45.465 18.842-18.843 45.465-18.843h503.382q26.623 0 45.465 18.843 18.843 18.842 18.843 45.465v242.998q-12.869-3.067-25.677-5.111-12.807-2.045-26.322-3.58v-234.307q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H228.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v503.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h233.307q1.105 14.131 3.052 26.68 1.947 12.55 5.255 25.319H228.309ZM216-240v24-528V-497.384v-2V-240Zm82.001-62.616h169.692q3.615-13.615 8.459-26.389 4.845-12.773 10.541-25.61H298.001v51.999Zm0-151.385h273.616q19.846-11.922 39.652-21.23 19.807-9.307 42.268-14.538v-16.23H298.001v51.998Zm0-151.384h363.998v-51.999H298.001v51.999ZM719.774-65.694q-71.696 0-121.735-50.264-50.038-50.264-50.038-121.96 0-71.697 50.265-121.735 50.264-50.038 121.96-50.038t121.735 50.264q50.038 50.265 50.038 121.961t-50.265 121.734q-50.264 50.038-121.96 50.038Z"
        />
      </svg>
    );
  }
}
