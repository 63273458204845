import { IconPropsBase } from './IconPropsBase';

export default function LocalShippingIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M233.755-177.694q-46.832 0-79.524-32.756t-32.692-79.551H53.847v-417.69q0-30.308 21-51.308t51.307-21h544.615v152.308h106.152l129.232 173.077v164.613h-72.307q0 46.795-32.783 79.551t-79.615 32.756q-46.832 0-79.524-32.756-32.693-32.756-32.693-79.551H346.153q0 46.923-32.783 79.615-32.782 32.692-79.615 32.692Zm.091-59.998q22 0 37.154-15.154 15.154-15.154 15.154-37.155 0-22-15.154-37.154-15.154-15.154-37.154-15.154-22.001 0-37.155 15.154t-15.154 37.154q0 22.001 15.154 37.155t37.155 15.154Zm-120-112.307h28.923q12.768-22.231 36.884-37.27 24.116-15.039 54.193-15.039 29.308 0 53.808 14.847 24.5 14.846 37.269 37.462H610.77V-720H126.154q-4.615 0-8.462 3.846-3.846 3.847-3.846 8.463v357.692Zm607.693 112.307q22 0 37.154-15.154 15.154-15.154 15.154-37.155 0-22-15.154-37.154-15.154-15.154-37.154-15.154-22.001 0-37.155 15.154t-15.154 37.154q0 22.001 15.154 37.155t37.155 15.154Zm-50.77-192.307H850L746.153-567.693h-75.384v137.694ZM362.308-535Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M233.755-209.694q-46.832 0-79.524-32.756t-32.692-79.551H61.847v-385.69q0-27.008 18.65-45.658 18.65-18.65 45.657-18.65h536.615v136.308h106.152l129.232 181.077v132.613h-64.307q0 46.795-32.783 79.551t-79.615 32.756q-46.832 0-79.524-32.756-32.693-32.756-32.693-79.551H346.153q0 46.923-32.783 79.615-32.782 32.692-79.615 32.692Zm.091-51.998q25.4 0 42.854-17.454t17.454-42.855q0-25.4-17.454-42.854t-42.854-17.454q-25.401 0-42.855 17.454-17.454 17.454-17.454 42.854 0 25.401 17.454 42.855 17.454 17.454 42.855 17.454Zm-120-112.307h20.923q12.768-27.231 39.884-43.77t59.193-16.539q31.308 0 58.808 16.347 27.5 16.346 39.798 43.962H610.77V-720H126.154q-4.615 0-8.462 3.846-3.846 3.847-3.846 8.463v333.692Zm607.693 112.307q25.4 0 42.854-17.454t17.454-42.855q0-25.4-17.454-42.854t-42.854-17.454q-25.401 0-42.855 17.454-17.454 17.454-17.454 42.854 0 25.401 17.454 42.855 17.454 17.454 42.855 17.454Zm-58.77-184.307 177.231-1-98.847-136.694h-78.384v137.694ZM364.308-544Z"
        />
      </svg>
    );
  }
}
