import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Group, Indicator, Text, UnstyledButton } from '@mantine/core';
import DescriptionIcon from '../icons/Description';
import { BNButton } from '../Button/Button';

export interface NotificationItemProps {
  category: string;
  isRead?: boolean;
  msgType?: string;
  title: string;
  desc?: string;
  timestamp?: string;
  actionUrl?: string;
  actionLabel?: string;
  icon?: ReactNode;
  onArchive?: () => void;
  onRead?: () => void;
  onUnarchive?: () => void;
  onCloseMenu?: () => void;
}

function reformatUrl(actionUrl: string) {
  if (actionUrl.startsWith('https://pricer.brokernerds.com')) {
    return actionUrl.replace('https://pricer.brokernerds.com', '');
  }

  if (actionUrl.startsWith('https://pricer.brokernerds.dev')) {
    return actionUrl.replace('https://pricer.brokernerds.dev', '');
  }

  return actionUrl;
}

export function NotificationItem({
  category,
  isRead = false,
  msgType,
  title,
  desc,
  timestamp,
  actionLabel,
  actionUrl,
  icon = <DescriptionIcon />,
  onArchive,
  onRead,
  onUnarchive,
  onCloseMenu,
}: NotificationItemProps) {
  const navigate = useNavigate();
  return (
    <Box pb={2} className={`notification-item notification-${msgType} notification-${isRead && 'read'}`}>
      <Group wrap="nowrap" p="sm" gap="xs" align="flex-start">
        <Indicator size={6} offset={3} disabled={isRead}>
          <Box className="notification-icon" w={24} h={24}>
            {icon}
          </Box>
        </Indicator>
        <Box style={{ flex: 1 }}>
          <Text className="notification-title" lh={1.2} my={2} size="sm">
            {title}
          </Text>
          {desc && (
            <Text size="xs" lh={1.5} c="var(--colors-gray-5)">
              {desc}
            </Text>
          )}
          <Text size="xs" lh={1.5} c="var(--colors-gray-5)">
            {timestamp}
            <Box component="span" display="inline" className="mark-as">
              {category === 'inbox' && !isRead && (
                <>
                  &nbsp;&middot;&nbsp;
                  <UnstyledButton fz="xs" c="var(--colors-gray-5)" onClick={onRead}>
                    Mark as Read
                  </UnstyledButton>
                </>
              )}
              {category === 'inbox' && (
                <>
                  &nbsp;&middot;&nbsp;
                  <UnstyledButton fz="xs" c="var(--colors-gray-5)" onClick={onArchive}>
                    Archive
                  </UnstyledButton>
                </>
              )}
              {category === 'archived' && (
                <>
                  &nbsp;&middot;&nbsp;
                  <UnstyledButton fz="xs" c="var(--colors-gray-5)" onClick={onUnarchive}>
                    Unarchive
                  </UnstyledButton>
                </>
              )}
            </Box>
          </Text>
        </Box>
        {actionUrl && (
          <Box style={{ flexGrow: 0 }}>
            <BNButton
              variant="default"
              fullWidth
              size="compact-xs"
              px="xs"
              onClick={() => {
                navigate(reformatUrl(actionUrl));
                if (onCloseMenu) {
                  onCloseMenu();
                }
              }}
            >
              {actionLabel}
            </BNButton>
          </Box>
        )}
      </Group>
    </Box>
  );
}
