import { IconPropsBase } from './IconPropsBase';

export default function FoamFingerIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M18.32,10.82c-.1-1.12-1.01-1.97-2.12-1.97-.33,0-.67.08-.97.24-.38-.67-1.08-1.07-1.85-1.07-.36,0-.72.09-1.04.27-.4-.59-1.06-.95-1.77-.95-.22,0-.43.03-.64.1v-3.97c0-1.18-.96-2.13-2.13-2.13s-2.14.96-2.14,2.14v14.8c0,2.07,1.68,3.76,3.75,3.76h5.17c2.07,0,3.75-1.69,3.75-3.76v-7.45h-.01ZM12.62,10.15c0-.42.34-.76.76-.76s.76.34.76.76v.88c0,.42-.34.76-.76.76s-.76-.34-.76-.76v-.88ZM9.81,9.47c0-.42.34-.77.77-.77s.76.34.76.76v1.42l-.15-.02c-.35-.06-.7.05-.93.15l-.45.19v-1.73ZM7.04,3.47c0-.42.34-.77.77-.77s.76.34.76.76v8.27l-1.53.65V3.47ZM16.96,18.27c0,1.31-1.07,2.38-2.38,2.38h-5.17c-1.31,0-2.38-1.07-2.38-2.38v-4.53l3.71-1.58c.19-.08.4-.08.59,0,.19.08.34.22.42.41.09.21.07.46-.05.67-.01.02-.03.05-.04.07-.1.13-.19.21-.3.26l-2.79,1.19v1.36l3.28-1.4c.49-.21.88-.6,1.08-1.1.06-.15.1-.3.12-.47l.02-.12.12.02c.06,0,.13.01.2.01.49,0,.96-.18,1.33-.5l.08-.07.08.07c.47.42,1.12.59,1.73.46.08-.02.13-.03.19-.05l.16-.05v5.38ZM16.96,11.03c0,.42-.34.76-.76.76s-.76-.34-.76-.76v-.05s0-.04.01-.08c0-.02,0-.03.01-.05.02-.1.05-.18.09-.25.06-.1.11-.15.18-.21l.07-.04s.08-.06.12-.07c.19-.08.38-.07.56,0,.07.03.12.07.18.12l.03.02c.15.14.24.31.25.51v.06s.01.05.01.05Z"
        />
        <path
          fill={color}
          d="M12.2,16.82l.22-.86h-.58l-.22.86h-.63l.22-.86h-.58l-.21.86h-.82l-.14.57h.82l-.16.63h-.82l-.14.57h.82l-.22.86h.58l.22-.86h.63l-.22.86h.58l.22-.86h.82l.14-.57h-.82l.16-.63h.82l.14-.57h-.82ZM11.32,18.02h-.63l.16-.63h.63l-.16.63Z"
        />
        <polygon fill={color} points="15.1 19.46 15.1 15.96 13.83 15.96 13.83 16.54 14.52 16.54 14.52 19.46 15.1 19.46" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M9.89,13.09h-.44l-.18.72h-.57l.18-.72h-.44l-.18.72h-.68l-.11.44h.68l-.14.56h-.68l-.11.44h.68l-.18.72h.44l.18-.72h.57l-.18.72h.44l.18-.72h.68l.11-.44h-.68l.14-.56h.68l.11-.44h-.68l.18-.72ZM9.02,14.81h-.57l.14-.56h.57l-.14.56Z"
        />
        <polygon fill={color} points="11 12.97 11 13.66 11.57 13.66 11.57 16.09 12.26 16.09 12.26 13.66 12.26 12.97 11.57 12.97 11 12.97" />
        <path
          fill={color}
          d="M15.15,8.92c-.03-.93-.75-1.65-1.65-1.65-.32,0-.61.09-.86.25-.27-.55-.83-.94-1.49-.94-.33,0-.64.1-.91.27-.28-.5-.82-.84-1.43-.84-.23,0-.46.05-.66.14v-3.49c0-.91-.74-1.65-1.65-1.65s-1.66.74-1.66,1.66v12.34c0,1.66,1.35,3,3,3h4.3c1.66,0,3-1.35,3-3v-6.09h0ZM10.5,8.71v-.48c0-.36.29-.66.66-.66s.66.29.66.66v.73c0,.36-.29.66-.66.66s-.66-.29-.66-.66v-.25ZM8.16,8.61v-.94c0-.36.3-.66.66-.66s.65.29.65.65v1.21c-.09-.01-.17-.04-.26-.04-.22,0-.44.04-.65.13l-.4.17v-.53ZM5.84,9.51V2.67c0-.36.3-.66.66-.66s.65.29.65.65v6.9h0s-1.31.56-1.31.56v-.62ZM14.16,15c0,1.11-.9,2-2,2h-4.3c-1.11,0-2-.9-2-2v-3.79l1.66-.71,1.45-.62c.34-.14.72.01.87.35.08.2.05.41-.05.58-.01.02-.02.04-.04.06-.07.09-.15.17-.26.22l-2.32.99v1.09l2.71-1.15c.41-.17.72-.49.89-.9.07-.17.1-.34.11-.52.1.02.19.03.29.03.46,0,.87-.19,1.18-.49.3.3.71.49,1.17.49.12,0,.23-.01.34-.04,0,0,0,0,0,0,.11-.02.21-.06.31-.1,0,0,0,0,0,0v4.52ZM14.16,8.97c0,.36-.29.66-.66.66s-.66-.29-.66-.66v-.04s.02-.07.02-.11c0,0,0,0,0,0,.01-.08.04-.15.08-.21h0c.04-.06.08-.12.13-.17.02-.02.04-.03.06-.04.04-.03.08-.06.13-.08.07-.03.15-.05.24-.05s.17.02.25.05c.06.02.1.06.15.1.01.01.03.02.04.03.12.11.19.26.2.42,0,0,0,0,0,0,0,.02.01.04.01.06v.04Z"
        />
      </svg>
    );
  }
}
