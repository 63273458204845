import { IconPropsBase } from './IconPropsBase';

export default function RefreshIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M12.05 19.5q-3.15 0-5.325-2.175Q4.55 15.15 4.55 12q0-3.15 2.175-5.325Q8.9 4.5 12.05 4.5q1.75 0 3.313.775 1.562.775 2.587 2.2V4.5h1.5v6.125h-6.1v-1.5h3.95q-.8-1.45-2.2-2.287Q13.7 6 12.05 6 9.55 6 7.8 7.75T6.05 12q0 2.5 1.75 4.25T12.05 18q1.925 0 3.475-1.1T17.7 14h1.575q-.675 2.45-2.687 3.975Q14.575 19.5 12.05 19.5Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M10.042 15.583q-2.334 0-3.959-1.625T4.458 10q0-2.333 1.625-3.958t3.959-1.625q1.375 0 2.52.614 1.146.615 1.896 1.636v-2.25h1.084v4.271h-4.25V7.604h2.52q-.604-.958-1.593-1.531-.99-.573-2.177-.573-1.875 0-3.188 1.312Q5.542 8.125 5.542 10q0 1.875 1.312 3.188Q8.167 14.5 10.042 14.5q1.75 0 3-1.156t1.437-2.844h1.104q-.166 2.167-1.76 3.625-1.594 1.458-3.781 1.458Z"
        />
      </svg>
    );
  }
}
