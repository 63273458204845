import { IconPropsBase } from './IconPropsBase';

export default function MarketplaceTickPickIcon({ size = 24, color = 'currentColor' }: IconPropsBase) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <path
        fill={color}
        d="M21.25,12.98c-.26-1.28-.77-2.49-1.49-3.57-.05-.08-.13-.13-.22-.12h-5.34s0,0,0,0h0c0-.15-.02-.3-.02-.45-.04-1.01.03-2.03.22-3.02.25-1.32.75-2.57,1.48-3.7.02-.03.07-.06.04-.12h-.16c-1.47,0-2.93,0-4.4,0-.21,0-.33.03-.43.27-.25.64-1.08,1.02-1.75.86-.48-.11-.87-.5-.78-1.04.02-.1-.05-.09-.11-.09-1.3,0-2.61,0-3.92,0-.07,0-.14.04-.17.1-.19.29-.37.59-.53.91-1.01,2-1.32,4.13-1.12,6.35.08.86.26,1.7.53,2.52.33,1.02.84,1.98,1.5,2.83.05.05.09.11.16.05h0c.05.04.12.02.18.02,1.56,0,3.13,0,4.69,0,.16,0,.19.05.19.19.03.99-.06,1.97-.26,2.94-.3,1.36-.82,2.61-1.64,3.74-.08.1-.16.21-.25.33h5.49c.08,0,.13-.01.15-.11.04-.15.11-.28.21-.4.35-.44.91-.67,1.47-.61.52.05.98.43.92,1,0,.1.02.12.11.12h3.23c.07,0,.13-.02.17-.08.13-.18.27-.36.39-.54,1.22-1.87,1.72-3.95,1.69-6.17,0-.74-.09-1.48-.25-2.21ZM4.78,14.67h0s0,0,0,0h0Z"
      />
      <polygon fill={color} points="14.2 9.29 14.2 9.29 14.2 9.29 14.2 9.29" />
    </svg>
  );
}
