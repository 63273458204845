import { IconPropsBase } from './IconPropsBase';

export default function ListIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M8.05 16.775q.375 0 .638-.263.262-.262.262-.637t-.262-.625Q8.425 15 8.05 15t-.625.25q-.25.25-.25.625t.25.637q.25.263.625.263Zm0-3.9q.375 0 .638-.25.262-.25.262-.625t-.262-.625q-.263-.25-.638-.25t-.625.25q-.25.25-.25.625t.25.625q.25.25.625.25ZM8.05 9q.375 0 .638-.25.262-.25.262-.625t-.262-.638q-.263-.262-.638-.262t-.625.262q-.25.263-.25.638t.25.625q.25.25.625.25Zm3.05 7.625h5.7v-1.5h-5.7Zm0-3.875h5.7v-1.5h-5.7Zm0-3.875h5.7v-1.5h-5.7ZM5.3 20.5q-.75 0-1.275-.525Q3.5 19.45 3.5 18.7V5.3q0-.75.525-1.275Q4.55 3.5 5.3 3.5h13.4q.75 0 1.275.525.525.525.525 1.275v13.4q0 .75-.525 1.275-.525.525-1.275.525Zm0-1.5h13.4q.1 0 .2-.1t.1-.2V5.3q0-.1-.1-.2t-.2-.1H5.3q-.1 0-.2.1t-.1.2v13.4q0 .1.1.2t.2.1ZM5 5v14V5Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M6.792 13.812q.27 0 .468-.187t.198-.458q0-.292-.187-.479-.188-.188-.479-.188-.271 0-.459.188-.187.187-.187.458t.187.469q.188.197.459.197Zm0-3.166q.27 0 .468-.188.198-.187.198-.458t-.187-.458q-.188-.188-.479-.188-.271 0-.459.188-.187.187-.187.458t.187.458q.188.188.459.188Zm0-3.146q.27 0 .468-.188.198-.187.198-.458t-.187-.469q-.188-.197-.479-.197-.271 0-.459.187-.187.187-.187.458 0 .292.187.479.188.188.459.188Zm2.291 6.188h4.75v-1.084h-4.75Zm0-3.146h4.75V9.458h-4.75Zm0-3.146h4.75V6.312h-4.75ZM4.75 16.583q-.562 0-.948-.385-.385-.386-.385-.948V4.75q0-.562.385-.948.386-.385.948-.385h10.5q.562 0 .948.385.385.386.385.948v10.5q0 .562-.385.948-.386.385-.948.385Zm0-1.083h10.5q.083 0 .167-.083.083-.084.083-.167V4.75q0-.083-.083-.167-.084-.083-.167-.083H4.75q-.083 0-.167.083-.083.084-.083.167v10.5q0 .083.083.167.084.083.167.083Zm-.25-11v11-11Z"
        />
      </svg>
    );
  }
}
