import { Box, Card, CardSection, Group, Text, Tooltip } from '@mantine/core';
import { BarkerCoreModelsDTIGetEventsResponseItem } from '../../api';
import { DateFormats } from '../../utils/globals';
import { formatDate } from '../../utils/formatters';
import classes from './Purchase.module.css';
import dayjs from 'dayjs';
import DoNotDisturbIcon from '../../components/icons/DoNotDisturb';
import { BNButton } from '../../components/Button/Button';

export function CatalogEventCard({
  event,
  isSelected,
  onClick,
}: {
  event: BarkerCoreModelsDTIGetEventsResponseItem;
  isSelected?: boolean;
  onClick?: (event: BarkerCoreModelsDTIGetEventsResponseItem, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) {
  const tenantClassName = 'tenant-single';

  return (
    <Card
      radius={0}
      m={0}
      padding={0}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        onClick?.(event, e);
      }}
      className={`${classes.card} ${tenantClassName}`}
    >
      {isSelected && <Box className={`${classes.arrow} selected-arrow`} />}
      <CardSection p={16} pt={12} pb={0} style={{ zIndex: 1 }}>
        <Text className="date-text" size="xs" fw={700} tt="uppercase" ml={0}>
          {formatDate(dayjs(event.event_date_time).toDate(), DateFormats.Standard)}
        </Text>
        <Text mt={6} size="sm" fw={600} className={classes.name} lineClamp={2}>
          {event.event_name}
        </Text>
        <Group className={classes.venue}>
          <Text className={classes.venueName} size="xs" fw={500}>
            {event.venue_name}
          </Text>
        </Group>
      </CardSection>
      <CardSection p={16} pt={0} pb={12} className={classes.actions}>
        <Tooltip label="Remove Event from List" withArrow>
          <BNButton leftSection={<DoNotDisturbIcon size={18} />} ml={-6} size="xs" variant="subtle" px={2}>
            Remove
          </BNButton>
        </Tooltip>
      </CardSection>
    </Card>
  );
}
