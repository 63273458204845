import { getLocationFriendlyName, LocationIcon } from './LocationProperty';
import { Center, Group, Text } from '@mantine/core';

export function LocationDisplay({ location }: { location: string }) {
  const friendlyName = getLocationFriendlyName(location);

  return (
    <Group wrap="nowrap" gap={4}>
      <Text lh={1.25} pt={2}>
        {friendlyName}
      </Text>
      <Center style={{ flexShrink: 0 }}>
        <LocationIcon location={location} color="var(--colors-iconFill)" size={16} />
      </Center>
    </Group>
  );
}
