export type MarketplaceNames = keyof typeof MarketplaceColors;

export const MarketplaceColors = {
  'Vivid Seats': 'var(--colors-marketplace-5)',
  StubHub: 'var(--colors-marketplace-0)',
  SeatGeek: 'var(--colors-marketplace-2)',
  Ticketmaster: 'var(--colors-marketplace-1)',
  TEVO: 'var(--colors-marketplace-4)',
  TicketNetwork: 'var(--colors-marketplace-8)',
  TickPick: 'var(--colors-marketplace-6)',
  Gametime: 'var(--colors-marketplace-7)',
  AXS: 'var(--colors-marketplace-3)',
  Other: 'var(--colors-marketplace-9)',
};
