import { Menu, Flex, Group, Divider, Center, Box, Text, RingProgress } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import ChevronBackIcon from '../../components/icons/ChevronBack';
import ChevronForwardIcon from '../../components/icons/ChevronForward';
import PlaylistAddIcon from '../../components/icons/PlaylistAdd';
import { usePurchaseState } from './Purchase.hooks';
import { SummaryGrid } from './Purchase.Summary.Grid';
import { Totals } from './Purchasing.Totals';
import classes from './Purchase.module.css';
import CheckIcon from '../../components/icons/Check';

export function Step_Summary() {
  const { form, onSubmit, prevStep, isSaving } = usePurchaseState('form', 'onSubmit', 'prevStep', 'isSaving');
  const showLoadingOverlay = false;
  return (
    <Flex h="100%" gap={0}>
      {showLoadingOverlay && <LoadingOverlay />}
      <Flex direction="column" h="100%" flex={1} style={{ overflow: 'hidden' }}>
        <Flex flex={1} h="100%">
          <SummaryGrid />
        </Flex>
        <Flex h={52} w="100%" px="md" bg="var(--colors-paper)" align="center" justify="space-between" style={{ borderTop: '1px solid var(--colors-divider)' }}>
          <Flex gap="md" justify="space-between" w="100%">
            <Flex w={254}>
              <BNButton size="xs" leftSection={<ChevronBackIcon size={16} />} onClick={() => prevStep()}>
                Add Inventory
              </BNButton>
            </Flex>
            <Group gap="xl">
              <Totals />
              <Group gap={0} style={{ borderRadius: 3 }} bg="var(--colors-successButton)">
                <BNButton
                  size="xs"
                  color="green"
                  variant="filled"
                  onClick={() => form.onSubmit(onSubmit)()}
                  loading={isSaving}
                  style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                  flex={1}
                  miw={120}
                >
                  Finish & Close
                </BNButton>
                <Divider orientation="vertical" h={30} color="var(--colors-paper)" opacity={0.35} />
                <Menu
                  position="top"
                  withArrow
                  styles={{
                    arrow: { borderColor: 'var(--colors-paper)', borderBottomColor: 'var(--colors-successButton)', borderRightColor: 'var(--colors-successButton)' },
                    dropdown: { borderColor: 'var(--colors-successButton)' },
                  }}
                >
                  <Menu.Target>
                    <BNButton size="xs" color="green" variant="filled" px={4} style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} w={30}>
                      <Center style={{ transform: 'rotate(90deg)' }}>
                        <ChevronForwardIcon size={12} />
                      </Center>
                    </BNButton>
                  </Menu.Target>
                  <Menu.Dropdown>
                    {/* <Menu.Item leftSection={<ListIcon />}>Finish & View Listings in Pricer</Menu.Item> */}
                    <Menu.Item leftSection={<PlaylistAddIcon />}>Submit & Start Over</Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </Group>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

function LoadingOverlay() {
  const isSaving = false;
  return (
    <Center pos="absolute" inset={0} bg="var(--colors-overlay)" style={{ zIndex: 3, borderRadius: 3 }}>
      <Box bg="var(--colors-paper)" className={classes.loadingWrapper} c="var(--colors-paperReverse)" p="xs">
        <Box className={classes.loadingGrid}>
          <Center py="lg">
            <RingProgress
              className={classes.ringProgress}
              rootColor="var(--colors-divider)"
              size={64}
              thickness={4}
              sections={[{ value: 10, color: 'var(--colors-successButton)' }]}
              label={
                <Center bg={isSaving ? 'var(--colors-successButton)' : 'var(--colors-paper)'} h={48} w={48} className={classes.ringProgressInner}>
                  {isSaving ? (
                    <CheckIcon size={32} color="white" />
                  ) : (
                    <Text fz="sm" c="var(--colors-paperReverse)">
                      10%
                    </Text>
                  )}
                </Center>
              }
            />
            <Text fz="xs" fw="500" mt="xs" c="var(--colors-paperReverse)">
              Adding Inventory
            </Text>
          </Center>
        </Box>
      </Box>
    </Center>
  );
}
