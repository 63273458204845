/* eslint-disable prefer-destructuring */
import { Box, Center, Flex, Group, Stack, Text } from '@mantine/core';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts';
import classes from './EventPerformance.module.css';
import { useCallback } from 'react';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import LineChartIcon from '../../components/icons/LineChart';
import { BNLoadingState } from '../../components/Loading/LoadingState';
import { useEventPerformance } from './EventPerformance.hooks';
import pluralize from 'pluralize';
import { MarketplaceColors, MarketplaceNames } from '../../models/marketplaces';

type MarketplaceItemProps = {
  name: MarketplaceNames;
  color?: string;
  percentage: string;
};

function MarketplaceItem({ name, color, percentage }: MarketplaceItemProps) {
  const bgColor = color ?? MarketplaceColors[name];

  return (
    <Group gap="xs" justify="space-between" wrap="nowrap">
      <Group gap="xs" wrap="nowrap" style={{ overflow: 'hidden' }}>
        <Box h={12} w={12} bg={bgColor} style={{ borderRadius: 12, flexShrink: 0 }} />
        <Text size="xs" truncate>
          {name}
        </Text>
      </Group>
      <Text size="xs">{percentage}%</Text>
    </Group>
  );
}

export default function MarketplaceBreakdown() {
  const { marketplaceBreakdown, isListingsSoldLoading } = useEventPerformance('marketplaceBreakdown', 'isListingsSoldLoading');

  const isEmpty = marketplaceBreakdown === undefined || Object.keys(marketplaceBreakdown).length === 0;

  // const data02 = !isEmpty
  //   ? [
  //       { name: 'Marketplace A', percentage: 30, tickets: 10 },
  //       { name: 'Marketplace B', percentage: 25 },
  //       { name: 'Marketplace C', percentage: 20 },
  //       { name: 'Marketplace D', percentage: 20 },
  //       { name: 'Marketplace E', percentage: 3 },
  //       { name: 'Marketplace F', percentage: 1 },
  //       { name: 'Marketplace G', percentage: 1 },
  //     ]
  //   : [{}];

  const totalTickets = Object.values(marketplaceBreakdown ?? {}).reduce((acc, value) => acc + value, 0);

  const data = !isEmpty
    ? Object.entries(marketplaceBreakdown ?? {})
        .map(([name, value]) => ({
          name,
          percentage: (value / totalTickets) * 100,
          tickets: value,
        }))
        .sort((a, b) => b.tickets - a.tickets)
    : [];

  const CustomTooltip = useCallback(({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length > 0) {
      if (payload[0].payload.name) {
        const valueDisplay = payload[0].payload.tickets;
        return (
          <Group className="custom-tooltip" gap="xs">
            <Box h={12} w={12} style={{ borderRadius: 100 }} bg={payload[0].payload.fill} />
            {payload[0].payload.name}{' '}
            <Group gap={0}>
              <Text c="var(--colors-gray-5)">
                {valueDisplay} {pluralize('Ticket', valueDisplay)}
              </Text>
            </Group>
          </Group>
        );
      }
    }
    return null;
  }, []);

  if (isListingsSoldLoading) {
    return <BNLoadingState border />;
  }

  if (isEmpty) {
    return (
      <Flex h="100%" mih={120}>
        <BNEmptyState title="No Sales Data" description="Check back later" icon={<LineChartIcon size={28} />} />
      </Flex>
    );
  }

  return (
    <Flex direction="row-reverse" justify="space-between" gap={3} h="100%" w="100%" pos="relative">
      <Box className={classes.marketplaceChartWrapper}>
        <Box pos="absolute" h={130} w={130} className={classes.pieChartRing} />
        <Center pos="absolute" w={160} h={170} className={classes.pieChartText} style={{ flexDirection: 'column' }}>
          <Text fz="xl" fw={600}>
            {totalTickets}
          </Text>
          <Text lh={1} fz="xs" c="var(--colors-gray-5)" tt="uppercase">
            {pluralize('Ticket', totalTickets)}
          </Text>
          <Text lh={1} fz="xs" c="var(--colors-gray-5)" tt="uppercase">
            Sold
          </Text>
        </Center>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={180} height={180}>
            <Pie dataKey="percentage" data={data} cx="50%" cy="50%" innerRadius={72} outerRadius={80} paddingAngle={5} fill="#8884d8">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={MarketplaceColors[entry.name as MarketplaceNames]} stroke="var(--colors-paper)" />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </Box>
      <Stack w={{ base: '100%', lg: '40%' }} gap={data.length > 8 ? 0 : 3}>
        {data.map((entry, index) => (
          <MarketplaceItem key={index} name={entry.name as MarketplaceNames} percentage={entry.percentage.toFixed(0)} />
        ))}
        {/*<MarketplaceItem name="Marketplace ABCDEF" color="var(--colors-marketplace-0)" percentage={30} />*/}
        {/*<MarketplaceItem name="Market B" color="var(--colors-marketplace-1)" percentage={25} />*/}
        {/*<MarketplaceItem name="Market C" color="var(--colors-marketplace-2)" percentage={20} />*/}
        {/*<MarketplaceItem name="Market D" color="var(--colors-marketplace-3)" percentage={20} />*/}
        {/*<MarketplaceItem name="Market E" color="var(--colors-marketplace-4)" percentage={3} />*/}
        {/*<MarketplaceItem name="Market F" color="var(--colors-marketplace-5)" percentage={1} />*/}
        {/*<MarketplaceItem name="Other" color="var(--colors-marketplace-6)" percentage={1} />*/}
      </Stack>
    </Flex>
  );
}
