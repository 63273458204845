import { ActionIcon, Avatar, Box, Center, Group, Menu, Modal, Popover, Skeleton, Text, Tooltip, UnstyledButton } from '@mantine/core';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import advancedFormats from 'dayjs/plugin/advancedFormat';
import LinkIcon from '../../components/icons/Link';
import LiveHelpIcon from '../../components/icons/LiveHelp';
import MoreVertIcon from '../../components/icons/MoreVert';
import OpenNewIcon from '../../components/icons/OpenNew';
import tevo from '../../img/tevo.png';
import tnet from '../../img/tnet.jpeg';
import { ReactComponent as SeatGeekLogo } from '../../img/sg.svg';
import { DateFormats } from '../../utils/globals';
import { useMemo, useState } from 'react';
import { generateSeatGeekUrl, generateTicketEvolutionUrl, generateTicketmasterUrl, generateTicketNetworkUrl } from '../../utils/urls';
import { BarkerCoreEnumsMarketplace } from '../../api';
import { useSeatingChart } from './SeatingChart.hooks';
import { formatDate } from '../../utils/formatters';
import tm from '../../img/tm.png';
import SyncIcon from '../../components/icons/Sync';
import { useDisclosure } from '@mantine/hooks';
import { SupportForm } from '../Support/SupportForm';
import CheckBoxIcon from '../../components/icons/CheckBox';
import RocketLaunchIcon from '../../components/icons/RocketLaunch';
import CachedIcon from '../../components/icons/Cached';
import SyncAltIcon from '../../components/icons/SyncAlt';
import { useGlobalState } from '../../data/GlobalState';
import classes from './SeatingChart.module.css';
import { TicketmasterMetadata } from '../../types';
import { MarketplaceSelector } from './SeatingChart.MarketplaceSelector';

dayjs.extend(weekOfYear);
dayjs.extend(advancedFormats);

export function Header() {
  const {
    showEventMapping,
    setShowEventMapping,
    selectedMarketplaceEvent,
    isLoading,
    fetchUpdatedSeatingChart,
    isEditMarketplaceLinksDialogOpen,
    toggleForceSectionChecklist,
    setRule,
    boostConfirm,
    isBoosted,
    isBoostingAllowed,
    isSeatingChartError,
    selectedMarketplaceId,
    selectedEvent,
    isSeasonView,
  } = useSeatingChart(
    'showEventMapping',
    'setShowEventMapping',
    'selectedMarketplaceEvent',
    'isLoading',
    'fetchUpdatedSeatingChart',
    'isEditMarketplaceLinksDialogOpen',
    'toggleForceSectionChecklist',
    'setRule',
    'boostConfirm',
    'isBoosted',
    'isBoostingAllowed',
    'isSeatingChartError',
    'selectedMarketplaceId',
    'selectedEvent',
    'isSeasonView',
  );

  const [isMarketSelectorOpen, setIsMarketSelectorOpen] = useState(false);
  const [supportOpen, { open: openSupport, close: closeSupport }] = useDisclosure();

  const ticketmasterId = useMemo(() => {
    if (selectedMarketplaceEvent.marketplaceId === BarkerCoreEnumsMarketplace.Ticketmaster && selectedMarketplaceEvent.marketplaceEvent?.metadata) {
      const metadata = JSON.parse(selectedMarketplaceEvent.marketplaceEvent.metadata) as TicketmasterMetadata;
      return metadata.TicketmasterId;
    }
    return '';
  }, [selectedMarketplaceEvent]);

  const isTicketmasterResaleEnabled = useMemo(() => {
    if (selectedMarketplaceEvent.marketplaceId === BarkerCoreEnumsMarketplace.Ticketmaster && selectedMarketplaceEvent.marketplaceEvent?.metadata) {
      const metadata = JSON.parse(selectedMarketplaceEvent.marketplaceEvent.metadata) as TicketmasterMetadata;
      return metadata.IsResaleEnabled;
    }
    return false;
  }, [selectedMarketplaceEvent]);

  const { tenants } = useGlobalState('tenants');
  const tenantPointOfSale = useMemo(() => tenants?.find((t) => t.tenantId === selectedEvent?.tenantId)?.pointOfSaleId || 'Unknown', [selectedEvent?.tenantId, tenants]);

  return (
    <Group pr={16} pl={14} py={10} gap="xs" align="center" justify="space-between" wrap="nowrap" className={classes.seatingChartHeaderWrapper}>
      <Group p={0} pl={2} gap="xs" h={44} wrap="nowrap" className={classes.seatingChartHeaderLeft}>
        <Popover
          position="bottom"
          withArrow
          opened={isMarketSelectorOpen && !isEditMarketplaceLinksDialogOpen}
          closeOnClickOutside
          onClose={() => {
            setIsMarketSelectorOpen(false);
          }}
        >
          <Tooltip label="Change Marketplace" position="right" withArrow>
            <Popover.Target>
              {selectedMarketplaceEvent?.marketplaceId === BarkerCoreEnumsMarketplace.SeatGeek ? (
                <Avatar
                  key="seatgeek"
                  m={0}
                  radius="xl"
                  variant="outline"
                  className={classes.avatar}
                  color="var(--colors-darkPaper)"
                  onClick={() => {
                    setIsMarketSelectorOpen(!isMarketSelectorOpen);
                  }}
                >
                  <ActionIcon>
                    <SyncAltIcon color="var(--colors-gray-5)" size={20} />
                  </ActionIcon>
                  <SeatGeekLogo />
                </Avatar>
              ) : (
                <Avatar
                  key="other"
                  m={0}
                  radius="xl"
                  variant="outline"
                  className={classes.avatar}
                  color="var(--colors-darkPaper)"
                  onClick={() => setIsMarketSelectorOpen(!isMarketSelectorOpen)}
                >
                  <ActionIcon>
                    <SyncAltIcon color="var(--colors-gray-5)" size={20} />
                  </ActionIcon>
                  <img
                    className="mantine-Avatar-image"
                    height={36}
                    width={36}
                    src={
                      selectedMarketplaceEvent?.marketplaceId === BarkerCoreEnumsMarketplace.TicketEvolution
                        ? tevo
                        : selectedMarketplaceEvent?.marketplaceId === BarkerCoreEnumsMarketplace.TicketNetwork
                          ? tnet
                          : tm
                    }
                    alt="Selected Marketplace"
                  />
                </Avatar>
              )}
            </Popover.Target>
          </Tooltip>
          <Popover.Dropdown>
            <MarketplaceSelector
              onChange={() => {
                setIsMarketSelectorOpen(false);
                setRule((rule) => ({ ...rule, filters: { ...rule.filters, sectionIds: [] } }));
              }}
            />
          </Popover.Dropdown>
        </Popover>
        <Box className={classes.listingText}>
          {isLoading ? (
            <>
              <Skeleton height={8} maw={306} width="100%" my={7} />
              <Skeleton height={8} maw={200} width="100%" my={7} />
            </>
          ) : selectedMarketplaceEvent?.marketplaceEvent ? (
            <>
              {isSeasonView && (
                <>
                  <Text size="sm" fw={600} truncate w="100%">
                    {selectedEvent?.enrichmentData?.seasonDetails?.name}
                  </Text>
                  <Text p={0} c="var(--colors-gray-5)" size="xs" truncate>
                    <Tooltip
                      label={`${selectedMarketplaceEvent?.marketplaceEvent.venueName} · ${selectedMarketplaceEvent?.marketplaceEvent.city}, ${selectedMarketplaceEvent?.marketplaceEvent.region}`}
                    >
                      <span>
                        {selectedMarketplaceEvent?.marketplaceEvent.venueName} · {selectedMarketplaceEvent?.marketplaceEvent.city},{' '}
                        {selectedMarketplaceEvent?.marketplaceEvent.region}
                      </span>
                    </Tooltip>
                  </Text>
                </>
              )}
              {!isSeasonView && (
                <>
                  <Text size="sm" fw={600} truncate w="100%">
                    {selectedMarketplaceEvent?.marketplaceEvent?.name}
                  </Text>
                  <Text p={0} c="var(--colors-gray-5)" size="xs" truncate>
                    <Tooltip
                      label={`${selectedMarketplaceEvent?.marketplaceEvent.venueName} · ${selectedMarketplaceEvent?.marketplaceEvent.city}, ${selectedMarketplaceEvent?.marketplaceEvent.region}`}
                    >
                      <span>
                        <span className={classes.desc}>{formatDate(selectedMarketplaceEvent?.marketplaceEvent.dateTimeLocal, DateFormats.Extended)}</span> -{' '}
                        {selectedMarketplaceEvent?.marketplaceEvent.venueName} · {selectedMarketplaceEvent?.marketplaceEvent.city},{' '}
                        {selectedMarketplaceEvent?.marketplaceEvent.region}
                      </span>
                    </Tooltip>
                  </Text>
                </>
              )}
            </>
          ) : (
            <>
              <Text size="sm" fw={600} className={classes.name}>
                Marketplace data not found
              </Text>
              {selectedEvent && (
                <Text p={0} c="var(--colors-gray-5)" size="xs" fw={500} className={classes.descWrap}>
                  <span>
                    <span>
                      Check back later or{' '}
                      <UnstyledButton fz="xs" td="underline" onClick={() => setShowEventMapping(!showEventMapping)} className={classes.grayLink}>
                        update the event mapping
                      </UnstyledButton>
                    </span>
                  </span>
                </Text>
              )}
              {!selectedEvent && (
                <Text p={0} c="var(--colors-gray-5)" size="xs" fw={500} className={classes.descWrap}>
                  Please update your search query
                </Text>
              )}
            </>
          )}
        </Box>
        {/* {selectedMarketplaceEvent?.isUserMapped && (
            <Group p={0} align="center">
              <Tooltip label="Custom-mapped event" withArrow>
                <Box
                  className={classes.indicatorWrapper}
                  onClick={() => {
                    setShowEventMapping(true);
                  }}
                >
                  <LinkIcon color="var(--colors-gray-5)" />
                </Box>
              </Tooltip>
            </Group>
          )} */}
      </Group>
      <Group wrap="nowrap" gap={4} style={{ flexShrink: 0 }}>
        {isBoosted && (
          <Tooltip label="Boosted! This event will auto-price at the fastest interval" withArrow>
            <Center h={28} w={28} bg="var(--colors-divider)" className={classes.indicatorWrapper}>
              <RocketLaunchIcon color="var(--colors-iconFill)" size={18} />
            </Center>
          </Tooltip>
        )}
        {isTicketmasterResaleEnabled && (
          <Tooltip label="Ticketmaster Resale Enabled" withArrow>
            <Center h={28} w={28} bg="var(--colors-divider)" className={classes.indicatorWrapper}>
              <CachedIcon color="#d0006f" size={20} />
            </Center>
          </Tooltip>
        )}
        <Menu withArrow width={200}>
          <Menu.Target>
            <ActionIcon>
              <MoreVertIcon size={24} color="var(--colors-gray-5)" />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            {selectedMarketplaceEvent?.marketplaceId === BarkerCoreEnumsMarketplace.TicketEvolution &&
              (selectedMarketplaceEvent?.marketplaceEventId ? (
                <Menu.Item component="a" target="_blank" href={generateTicketEvolutionUrl(selectedMarketplaceEvent?.marketplaceEventId!)} leftSection={<OpenNewIcon />}>
                  View in Core
                </Menu.Item>
              ) : (
                <Menu.Item leftSection={<OpenNewIcon />} disabled>
                  View in Core
                </Menu.Item>
              ))}
            {selectedMarketplaceEvent?.marketplaceId === BarkerCoreEnumsMarketplace.SeatGeek &&
              (selectedMarketplaceEvent?.marketplaceEventId ? (
                <Menu.Item component="a" target="_blank" href={generateSeatGeekUrl(selectedMarketplaceEvent?.marketplaceEventId!)} leftSection={<OpenNewIcon />}>
                  View on SeatGeek
                </Menu.Item>
              ) : (
                <Menu.Item leftSection={<OpenNewIcon />} disabled>
                  View on SeatGeek
                </Menu.Item>
              ))}
            {selectedMarketplaceEvent?.marketplaceId === BarkerCoreEnumsMarketplace.TicketNetwork &&
              (selectedMarketplaceEvent?.marketplaceEventId ? (
                <Menu.Item component="a" target="_blank" href={generateTicketNetworkUrl(selectedMarketplaceEvent?.marketplaceEventId!)} leftSection={<OpenNewIcon />}>
                  View on TicketNetwork
                </Menu.Item>
              ) : (
                <Menu.Item leftSection={<OpenNewIcon />} disabled>
                  View on TicketNetwork
                </Menu.Item>
              ))}
            {selectedMarketplaceEvent?.marketplaceId === BarkerCoreEnumsMarketplace.Ticketmaster &&
              (ticketmasterId ? (
                <Menu.Item component="a" target="_blank" href={generateTicketmasterUrl(ticketmasterId)} leftSection={<OpenNewIcon />}>
                  View on Ticketmaster
                </Menu.Item>
              ) : (
                <Menu.Item leftSection={<OpenNewIcon />} disabled>
                  View on Ticketmaster
                </Menu.Item>
              ))}
            <Menu.Item
              onClick={() => {
                setShowEventMapping(!showEventMapping);
              }}
              leftSection={
                <span style={{ transform: 'rotate(-45deg)' }}>
                  <LinkIcon />
                </span>
              }
            >
              Edit Event Mapping
            </Menu.Item>
            <Menu.Item
              disabled={!selectedMarketplaceEvent.marketplaceEventId || isSeatingChartError}
              onClick={() => {
                setShowEventMapping(false);
                toggleForceSectionChecklist();
              }}
              leftSection={<CheckBoxIcon />}
              data-pendo-name="Section Checklist"
            >
              Section Checklist
            </Menu.Item>
            {isBoostingAllowed && (
              <Menu.Item disabled={isBoosted} onClick={() => boostConfirm()} leftSection={<RocketLaunchIcon />} data-pendo-name="Boost Event">
                Boost Event
              </Menu.Item>
            )}
            <Menu.Item leftSection={<SyncIcon />} onClick={() => fetchUpdatedSeatingChart()}>
              Check for Updates
            </Menu.Item>
            <Menu.Item
              leftSection={<LiveHelpIcon />}
              onClick={() => {
                openSupport();
              }}
              style={{ borderBottom: 'none' }}
            >
              Request Support
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      {supportOpen && (selectedMarketplaceEvent?.marketplaceEvent || (!selectedMarketplaceEvent.marketplaceEvent && selectedEvent)) && (
        <Modal
          opened={supportOpen}
          onClose={closeSupport}
          closeOnClickOutside={false}
          title={
            <Group gap="xs">
              <LiveHelpIcon color="var(--colors-iconFill)" />
              <Text size="sm">Support Request - Marketplace</Text>
            </Group>
          }
          centered
          size="md"
          className={classes.supportModal}
          // temp fix to show large select box options
        >
          {selectedMarketplaceEvent?.marketplaceEvent && (
            <SupportForm type="marketplaceEvent" marketplaceId={selectedMarketplaceEvent.marketplaceId} event={selectedMarketplaceEvent.marketplaceEvent} onClose={closeSupport} />
          )}
          {!selectedMarketplaceEvent.marketplaceEvent && selectedEvent && (
            <SupportForm type="marketplaceMissingEvent" marketplaceId={selectedMarketplaceId} event={selectedEvent} pointOfSaleId={tenantPointOfSale} onClose={closeSupport} />
          )}
        </Modal>
      )}
    </Group>
  );
}
