import { IconPropsBase } from './IconPropsBase';

export default function ContingentEventIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M19.97,5.03c-.35-.35-.78-.53-1.28-.53h-1.38v-2.12h-1.5v2.12h-7.58v-2.12h-1.54v2.12h-1.38c-.51,0-.93.17-1.28.53-.35.35-.53.78-.53,1.28v13.38c0,.51.17.93.53,1.28.35.35.78.53,1.28.53h13.38c.51,0,.93-.17,1.28-.53.35-.35.53-.78.53-1.28V6.31c0-.51-.17-.93-.53-1.28ZM19,19.69c0,.08-.03.15-.1.21-.06.06-.13.1-.21.1H5.31c-.08,0-.15-.03-.21-.1-.06-.06-.1-.13-.1-.21V6.31c0-.08.03-.15.1-.21.06-.06.13-.1.21-.1h13.38c.08,0,.15.03.21.1.06.06.1.13.1.21v13.38Z"
        />
        <path fill={color} d="M12.02,16.53c-.29,0-.53.1-.72.3-.2.2-.3.44-.3.72s.1.53.3.72c.2.2.44.3.72.3s.53-.1.72-.3c.2-.2.3-.44.3-.72s-.1-.53-.3-.72c-.2-.2-.44-.3-.72-.3Z" />
        <path
          fill={color}
          d="M12.21,7.36c-.81,0-1.51.21-2.09.62-.58.41-.99.95-1.23,1.62l1.28.52c.14-.42.38-.77.71-1.04.33-.27.75-.4,1.24-.4.54,0,.96.15,1.26.44.3.29.46.65.46,1.07,0,.31-.09.61-.28.91-.18.3-.47.62-.85.96-.54.49-.91.94-1.11,1.36-.2.42-.3.94-.3,1.57h1.41c.01-.52.09-.92.22-1.2.14-.27.41-.62.83-1.03.53-.53.91-.98,1.14-1.36.23-.37.34-.8.34-1.27,0-.83-.28-1.5-.85-2.01-.57-.51-1.3-.76-2.19-.76Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M10.01,13.65c-.21,0-.39.07-.54.2s-.23.31-.23.52.07.39.22.54c.15.15.33.22.54.22s.39-.07.54-.22c.14-.15.21-.33.21-.54s-.07-.39-.22-.52-.32-.2-.53-.2Z"
        />
        <path
          fill={color}
          d="M9.94,6.8c-.58,0-1.07.17-1.46.52-.39.35-.67.71-.83,1.1l.92.41c.1-.23.25-.47.45-.71.19-.24.5-.37.93-.37.51,0,.86.13,1.05.4.2.27.3.54.3.84,0,.31-.1.58-.31.8-.21.22-.41.43-.6.64-.43.42-.68.75-.77.99-.09.24-.13.61-.13,1.1h1.01c.01-.42.06-.72.14-.9.09-.18.29-.42.63-.72.26-.25.5-.52.7-.82.2-.3.31-.66.31-1.08,0-.68-.24-1.21-.71-1.6-.47-.39-1.01-.59-1.62-.59Z"
        />
        <path
          fill={color}
          d="M16.19,4.81c-.26-.26-.58-.39-.95-.39h-1.82v-2.1h-1.08v2.1h-4.65v-2.1h-1.12v2.1h-1.82c-.38,0-.69.13-.95.39-.26.26-.39.58-.39.95v10.49c0,.38.13.69.39.95.26.26.58.39.95.39h10.49c.38,0,.69-.13.95-.39.26-.26.39-.58.39-.95V5.76c0-.38-.13-.69-.39-.95ZM15.5,16.24c0,.06-.03.12-.08.18-.05.05-.11.08-.18.08H4.76c-.06,0-.12-.03-.18-.08-.05-.05-.08-.11-.08-.18V5.76c0-.06.03-.12.08-.18.05-.05.11-.08.18-.08h10.49c.06,0,.12.03.18.08.05.05.08.11.08.18v10.49Z"
        />
      </svg>
    );
  }
}
