import { usePutApiPrincipalsLogin } from '../api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAtomValue } from 'jotai';
import LogoLoader from '../components/Loading/LogoLoader';
import { auth } from '../data/atoms.auth';
import { useAuth0 } from '@auth0/auth0-react';

export default function AuthCallback() {
  const { error } = useAuth0();
  const { mutateAsync: login } = usePutApiPrincipalsLogin();
  const [searchParams] = useSearchParams();
  const apiToken = useAtomValue(auth.apiTokenAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (error && error.message === 'Invalid state') {
      // Take them back to the start, which should prompt them to log back in again
      navigate('/');
    }

    if (!apiToken) {
      return;
    }

    if (searchParams.has('returnTo')) {
      const returnTo = searchParams.get('returnTo');

      if (returnTo) {
        const decodedReturnTo = decodeURIComponent(returnTo);

        login().then(() => {
          navigate(decodedReturnTo);
        });
      }
    }
  }, [searchParams, login, navigate, error, apiToken]);

  if (error) {
    // Any error besides the 'Invalid state' error will cause a more elegant user error experience
    throw new Error(error.message);
  }

  return <LogoLoader />;
}
