import { Paper } from '@mantine/core';
import Grid from './Inventory.Grid';
import { InventoryStateProvider } from './Inventory.hooks';
import classes from './Inventory.module.css';
import { InventoryHeaderStateProvider } from './Inventory.Header.hooks';
import { Header } from './Inventory.Header';

export function SeasonInventory() {
  return (
    <Paper className={classes.inventoryWrapper}>
      <InventoryStateProvider>
        <InventoryHeaderStateProvider>
          <Header />
        </InventoryHeaderStateProvider>
        <Grid />
      </InventoryStateProvider>
    </Paper>
  );
}
