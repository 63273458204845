import { IconPropsBase } from './IconPropsBase';

export default function ForumIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="M284.616-275.387q-15.039 0-25.212-10.173t-10.173-25.211v-59.999H744.23l12.693 12.693V-720h59.998q15.039 0 25.212 10.173t10.173 25.211v550.766L710.769-275.387H284.616Zm-176.922-33.846v-515.381q0-15.039 10.173-25.212t25.212-10.173h498.46q15.038 0 25.211 10.173 10.173 10.173 10.173 25.212v338.46q0 15.038-10.173 25.211-10.173 10.173-25.211 10.173H249.231L107.694-309.233Zm509.23-201.536V-800H167.693v316.926l27.694-27.695h421.537Zm-449.231 0V-800v289.231Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="M364.616-275.387q-28.039 0-47.712-19.673-19.673-19.673-19.673-47.711v-27.999H728.23l12.693 12.693V-672h27.998q28.039 0 47.712 19.673 19.673 19.673 19.673 47.711v454.766L710.769-275.387H364.616Zm-240.922-41.846v-459.381q0-28.039 19.673-47.712 19.673-19.673 47.712-19.673h410.46q28.038 0 47.711 19.673t19.673 47.712v266.46q0 28.038-19.673 47.711t-47.711 19.673H249.231L123.694-317.233Zm493.23-177.536V-792H175.693v324.926l27.694-27.695h413.537Zm-441.231 0V-792v297.231Z" />
      </svg>
    );
  }
}
