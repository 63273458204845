import { useSearchResults } from './SearchResults.hooks';
import { MantineSize, MultiSelect } from '@mantine/core';
import { useGlobalState } from '../../data/GlobalState';
import { useMemo } from 'react';
import { useFlag } from '@unleash/proxy-client-react';

type TagsFilterProps = {
  size?: MantineSize;
};

export function TagsFilter({ size }: TagsFilterProps) {
  const { tags, form } = useSearchResults('tags', 'form');
  const { tenants, selectedTenantIds } = useGlobalState('tenants', 'selectedTenantIds');
  const singleDtiTenantSelected = useMemo(
    () => selectedTenantIds?.length === 1 && tenants?.find((t) => t.tenantId === selectedTenantIds[0] && t.pointOfSaleId === 'DtiPortal'),
    [selectedTenantIds, tenants],
  );
  const multipleTenantsSelected = selectedTenantIds?.length > 1;

  const multiTenantTagFilteringFlag = useFlag('multi-tenant-tag-filtering');

  const dontDisplayTagsFilter = (!!selectedTenantIds && multipleTenantsSelected && !multiTenantTagFilteringFlag) || singleDtiTenantSelected;

  if (dontDisplayTagsFilter) {
    return null;
  }

  return (
    <MultiSelect
      disabled={!tags}
      size={size || 'xs'}
      data={tags ?? []}
      label="Tags"
      searchable
      hidePickedOptions
      nothingFoundMessage="No tags found"
      {...form.getInputProps('tags')}
      data-pendo-name="Tags Filter Select"
    />
  );
}
