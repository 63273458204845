import { Box, Paper } from '@mantine/core';
import { SearchResultStateProvider } from '../hoc/EventSearch/SearchResults.hooks';
import { SeatingChartProvider } from '../hoc/SeatingChart/SeatingChart.hooks';
import { RuleStateProvider } from '../data/RuleState';
import { BulletinModal } from '../hoc/Bulletin/BulletinModal';
import { InviteModal } from '../hoc/InviteModal/InviteModal';
import { useLocalStorage } from '@mantine/hooks';
import { VersionNotificationBanner } from '../hoc/Banners/VersionNotificationBanner';
import { SupportActivationBanner } from '../hoc/Banners/SupportActivationBanner';
import { BulkStateProvider, useBulkState } from '../data/BulkState';
import { BulkExitOverlay } from '../components/Overlay/BulkExitOverlay';
import { PlanExpirationBanner } from '../hoc/Banners/PlanExpirationBanner';
import { UnpaidInvoiceBanner } from '../hoc/Banners/UnpaidInvoiceBanner';
import { EventPerformance } from '../hoc/EventPerformance/EventPerformance';
import { IntegrationProblemBanner } from '../hoc/Banners/IntegrationProblemBanner';
import { EventPerformanceProvider } from '../hoc/EventPerformance/EventPerformance.hooks';
import { ListingStateProvider } from '../data/ListingState';
import cx from 'clsx';
import { useFlag } from '@unleash/proxy-client-react';
import { HomeStateProvider, useHomeState } from './Home.hooks';
import { StackedFilters } from '../hoc/EventSearch/Search.StackedFilters';
import { PricerView } from '../types';
import { isDtiHosted } from '../utils/whitelabel-consts';
import { DTIInventoryStateProvider } from '../hoc/DTI/DTI.Inventory.hooks';
import { DTIModals } from '../hoc/DTI/DTIModals';
import { NowPricing } from '../hoc/NowPricing/NowPricing';
import { SeatingChart } from '../hoc/SeatingChart/SeatingChart';
import { BNSidebar } from '../hoc/NavBar/Sidebar';
import { Search } from '../hoc/EventSearch/Search';
import { SearchResults } from '../hoc/EventSearch/SearchResults';
import { Inventory } from '../hoc/Inventory/Inventory';
import { MarketListings } from '../hoc/MarketListings/MarketListings';
import { useGlobalState } from '../data/GlobalState';
import { Allotment } from 'allotment';

export default function Home() {
  return (
    <ListingStateProvider>
      <RuleStateProvider>
        <BulkStateProvider>
          <BulletinModal />
          <InviteModal />
          <div className="outer-appWrap">
            <VersionNotificationBanner />
            <SupportActivationBanner />
            <PlanExpirationBanner />
            <UnpaidInvoiceBanner />
            <IntegrationProblemBanner />
            <HomeStateProvider>
              {isDtiHosted ? (
                <DTIInventoryStateProvider>
                  <HomeInterior />
                  <DTIModals />
                </DTIInventoryStateProvider>
              ) : (
                <HomeInterior />
              )}
            </HomeStateProvider>
          </div>
        </BulkStateProvider>
      </RuleStateProvider>
    </ListingStateProvider>
  );
}

function HomeInterior() {
  const [showSidebar] = useLocalStorage({
    key: 'setting-sidebar',
    defaultValue: true,
    getInitialValueInEffect: false,
  });
  const [pricerLayout, setPricerLayout] = useLocalStorage<number[]>({
    key: 'setting-pricer-layout',
    defaultValue: [1132, 788],
    getInitialValueInEffect: false,
  });
  const searchAdvancedFlag = useFlag('search-advanced');
  const { isTrendsCollapsed, handleTrendsToggle } = useHomeState('isTrendsCollapsed', 'handleTrendsToggle');
  const { pricerView } = useGlobalState('pricerView');
  const { showBulkOptions } = useBulkState('showBulkOptions');

  return (
    <div className="inner-appWrap">
      <Allotment defaultSizes={pricerLayout} onChange={(value) => setPricerLayout(value)} separator={false}>
        <Allotment.Pane preferredSize="90%" minSize={450}>
          <div className={cx('appWrap appLeft', showSidebar ? 'sidebar-open' : 'sidebar-collapse')}>
            <div className="col1">
              <BNSidebar>
                {showBulkOptions && <BulkExitOverlay />}
                <Paper className="sidebarSearchWrapper" pos="relative">
                  <SearchResultStateProvider>
                    <Search />
                    {pricerView !== PricerView.Split && <SearchResults />}
                    {searchAdvancedFlag && pricerView === PricerView.Split && <StackedFilters />}
                  </SearchResultStateProvider>
                </Paper>
              </BNSidebar>
            </div>
            <div className={cx('col2', pricerView === PricerView.Split ? 'stacked-grids' : null)}>
              <Inventory />
            </div>
          </div>
        </Allotment.Pane>
        <Allotment.Pane minSize={788} maxSize={1288} preferredSize="10%">
          <div className={cx('appRight', isTrendsCollapsed && 'trends-collapse')}>
            <div className="col3">
              <Paper className="seatingChartWrapper" pos="relative">
                <SeatingChartProvider>
                  <SeatingChart isTrendsCollapsed={isTrendsCollapsed} handleTrendsToggle={handleTrendsToggle} />
                </SeatingChartProvider>
              </Paper>
            </div>
            <div className="col4">
              <NowPricing />
              <MarketListings />
            </div>
            <EventPerformanceProvider>
              <EventPerformance />
            </EventPerformanceProvider>
            {showBulkOptions && (
              <Box className="bulk-overlay-right">
                <BulkExitOverlay />
              </Box>
            )}
          </div>
        </Allotment.Pane>
      </Allotment>
    </div>
  );
}
