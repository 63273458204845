import { Divider, Flex, Text } from '@mantine/core';
import { BNButton } from '../../../components/Button/Button';
import { useRef } from 'react';
import { AdjustmentValueInputDiagonalMerged } from '../../../components/AdjustmentInput/AdjustmentValueInputDiagonal.Merged';
import { MobileModalProps } from '../../../components/MobilePriceUpdateForm/MobilePriceUpdateForm';
import { useNowPricing } from '../../NowPricing/NowPricing.hooks';
import { useForm } from '@mantine/form';
import { useViewportPosition } from '../../../utils/use-viewport-position';

export function AdjustmentUpdateForm({ closeModal }: MobileModalProps) {
  const { form } = useNowPricing('form', 'selectedMarketplaceId');
  const {
    getInputProps,
    onSubmit,
    values: { adjustmentTypeId, adjustmentValue },
  } = useForm({
    initialValues: {
      adjustmentTypeId: form.values.adjustmentTypeId,
      adjustmentValue: form.values.adjustmentValue,
    },
  });

  const ref = useRef<HTMLInputElement | null>(null);
  useViewportPosition(ref, [adjustmentTypeId, adjustmentValue]);

  return (
    <form
      onSubmit={onSubmit((values) => {
        form.setFieldValue('adjustmentTypeId', values.adjustmentTypeId);
        form.setFieldValue('adjustmentValue', values.adjustmentValue);
        closeModal();
      })}
    >
      <Flex p={0} h="100%" justify="space-between">
        <Flex py="sm" px="md" gap="xs" align="center" justify="start" style={{ flex: 1 }}>
          <Text size="md" fw={600}>Adjustment</Text>
        </Flex>
      </Flex>
      <Divider color="var(--colors-gray-0)" />
      <Flex py="xl" px="xl" w="100%" align="center" justify="center" gap="md">
        <AdjustmentValueInputDiagonalMerged
          ref={ref}
          mobile
          onChange={(value) => {
            getInputProps('adjustmentValue').onChange(value.value);
            getInputProps('adjustmentTypeId').onChange(value.type);
          }}
          value={adjustmentValue || 0}
          type={adjustmentTypeId || 'Amount'}
        />
      </Flex>
      <Divider color="var(--colors-gray-0)" />
      <Flex gap="sm" w="100%" py="xl" px="md">
        <BNButton fullWidth variant="default" onClick={() => closeModal()}>
          Cancel
        </BNButton>
        <BNButton fullWidth variant="filled" color="gray" type="submit">
          Done
        </BNButton>
      </Flex>
    </form>
  );
}
