import { ActionIcon, Collapse, Group, Paper, Text } from '@mantine/core';
import CloseIcon from '../../components/icons/Close';
import { BarkerCoreEnumsUserRole, useGetApiAccountingInvoices } from '../../api';
import { useAtomValue } from 'jotai';
import dayjs from 'dayjs';
import { useLocalStorage } from '@mantine/hooks';
import WarningIcon from '../../components/icons/Warning';
import { useGlobalState } from '../../data/GlobalState';
import { auth } from '../../data/atoms.auth';
import { isDtiHosted, supportEmail } from '../../utils/whitelabel-consts';

export function UnpaidInvoiceBanner() {
  const apiToken = useAtomValue(auth.apiTokenAtom);
  const { currentUsers, selectedTenantIds } = useGlobalState('currentUsers', 'selectedTenantIds');

  const userAdminTenants = currentUsers
    .filter((u) => selectedTenantIds.some((t) => t === u.tenantId) && (u.roleId === BarkerCoreEnumsUserRole.TenantAdmin || u.roleId === BarkerCoreEnumsUserRole.GlobalAdmin))
    .map((u) => u.tenantId);

  const { data: previousInvoices } = useGetApiAccountingInvoices(
    {
      fromDate: dayjs().subtract(3, 'month').startOf('day').toDate(),
      toDate: dayjs().endOf('day').toDate(),
    },
    {
      axios: {
        headers: {
          'x-tenant-id': userAdminTenants.join(','),
        },
      },
      query: {
        enabled: !!apiToken && userAdminTenants.length > 0 && !isDtiHosted, // Don't show the banner if we're in DTI
        select: (data) => data.data,
      },
    },
  );

  const [snoozedBanners, setSnoozedBanners] = useLocalStorage<{ id: string; until: Date }[]>({
    key: 'snoozed-unpaid-invoice-banners',
    getInitialValueInEffect: false,
    defaultValue: [],
  });

  const unpaidInvoices = previousInvoices?.filter((invoice) => invoice.paidAt === null && dayjs(invoice.dueAt).isBefore(dayjs().subtract(5, 'days')));

  if (unpaidInvoices === undefined || unpaidInvoices.length === 0) {
    return null;
  }

  const unSnoozedInvoices = unpaidInvoices.filter((invoice) => !snoozedBanners.some((banner) => banner.id === invoice.tenantId && dayjs(banner.until).isAfter(dayjs())));

  if (unSnoozedInvoices.length === 0) {
    return null;
  }

  const earliestInvoiceDue = unSnoozedInvoices.sort((a, b) => dayjs(a.dueAt).diff(dayjs(b.dueAt)))[0];

  const daysToExpiration = earliestInvoiceDue.dueAt ? dayjs(earliestInvoiceDue.dueAt).diff(dayjs(), 'days') : 0;

  const bannerColor = daysToExpiration <= 0 ? 'var(--colors-red-error)' : 'var(--colors-yellow-5)';
  const textColor = daysToExpiration <= 0 ? 'rgba(255,255,255,1)' : 'rgba(23,23,23,1)';

  if (daysToExpiration < 0) {
    return (
      <Collapse in w="100%" ta="center">
        <Paper w="100%" bg="var(--colors-red-error)" c="rgba(255,255,255,1)">
          <Group mih={36} align="center" justify="center" gap="xs">
            <WarningIcon size={20} color="rgba(255,255,255,1)" />
            <Text size="xs" c="rgba(255,255,255,1)">
              You have a past-due invoice. Please contact{' '}
              <Text component="a" href={`mailto:${supportEmail}`} td="underline">
                {supportEmail}
              </Text>{' '}
              to avoid your service being suspended.
            </Text>
          </Group>
        </Paper>
      </Collapse>
    );
  }

  return (
    <Collapse in w="100%" ta="center">
      <Paper w="100%" bg={bannerColor} c={textColor}>
        <Group mih={36} align="center" justify="center" gap="xs">
          <WarningIcon size={20} color={textColor} />
          <Text size="xs" c={textColor}>
            You have an unpaid invoice due in <strong>{daysToExpiration} days</strong>. Please contact{' '}
            <Text component="a" href={`mailto:${supportEmail}`} td="underline">
              {supportEmail}
            </Text>{' '}
            if you have any questions. Unpaid invoices will result in a suspension of your account.
          </Text>
          <ActionIcon
            c={textColor}
            onClick={() => {
              setSnoozedBanners([...snoozedBanners, { id: earliestInvoiceDue.tenantId, until: dayjs().add(1, 'day').toDate() }]);
            }}
            pos="absolute"
            right={16}
          >
            <CloseIcon size={20} />
          </ActionIcon>
        </Group>
      </Paper>
    </Collapse>
  );
}
