import { Box, Center, Container, Group, Text } from '@mantine/core';
import StadiumIcon from '../../components/icons/Stadium';
import classes from './SeatingChart.module.css';

export function NoEventSelected() {
  return (
    <Box className={classes.emptyStateOuterWrapper}>
      <Container p={0} className={classes.emptyStateWrapper}>
        <Center className={classes.center}>
          <Box className={classes.iconWrapper}>
            <StadiumIcon color="var(--colors-iconFill)" size={28} />
          </Box>
          <Text size="md" fw={600}>
            No Event Selected
          </Text>
          <Group gap={0}>
            <Text size="xs" c="var(--colors-gray-5)">
              Please update your search query
            </Text>
          </Group>
        </Center>
      </Container>
    </Box>
  );
}
