import { atom } from 'jotai';
import { RESET } from 'jotai/utils';
import { v4 as uuid } from 'uuid';
import { InventorySeasonLocationExtended, SeasonSummary } from '../types';
import { localRuleDefaultAtom, ruleStateAtom, selectedMarketplaceEventAtom, selectedSectionAtom } from './atoms';
import { extendRuleFromSettings } from '../utils/rule-utils';
import { auth } from './atoms.auth';
import { focusAtom } from 'jotai-optics';
import { BarkerCoreModelsInventorySeasonLocationListing } from '../api';

export namespace seasons {
  export const selectedSeasonAtom = atom<SeasonSummary | undefined>(undefined);
  export const selectedSeasonLocationsAtom = focusAtom(selectedSeasonAtom, (o) => o.valueOr({} as { locations: [] | undefined }).prop('locations'));
  export const updateLocationAtom = atom(null, (get, set, hashId: string, update: Partial<InventorySeasonLocationExtended>) => {
    const season = get(selectedSeasonAtom);
    const locations = [...(season?.locations ?? [])];
    const locationIndex = locations.findIndex((l) => l.hashId === hashId);

    if (season && locationIndex !== -1) {
      locations[locationIndex] = { ...locations[locationIndex], ...update };

      set(selectedSeasonAtom, {
        ...season,
        locations,
      });

      const location = locations[locationIndex];
      if (get(_selectedLocationAtom)?.hashId === location.hashId) {
        set(_selectedLocationAtom, location);
      }
    }
  });
  export const updateLocationListingAtom = atom(null, (get, set, listingId: string, update: Partial<BarkerCoreModelsInventorySeasonLocationListing>) => {
    get(selectedSeasonLocationsAtom)?.forEach((location) => {
      if (location.listings.some((x) => x.listingId === listingId)) {
        const listings = location.listings.map((l) => (l.listingId === listingId ? { ...l, ...update } : l));
        set(updateLocationAtom, location.hashId, { listings });
      }
    });
  });

  const _initialSelectedLocationAtom = atom<InventorySeasonLocationExtended | undefined>(undefined);
  const _selectedLocationAtom = atom<InventorySeasonLocationExtended | undefined>(undefined);
  export const previousSelectedLocationAtom = atom<InventorySeasonLocationExtended | undefined>(undefined);
  export const selectedLocationAtom = atom(
    (get) => get(_selectedLocationAtom),
    (get, set, update: ReturnType<typeof _selectedLocationAtom.read> | typeof RESET) => {
      if (update === RESET) {
        const initialValue = get(_initialSelectedLocationAtom);
        set(_selectedLocationAtom, initialValue);
      } else {
        const last = get(_selectedLocationAtom);
        if (last?.hashId !== update?.hashId) {
          set(_initialSelectedLocationAtom, update);
          if (update !== undefined) {
            set(setSeasonRuleFromLocationAtom, update);
          }
        }
        set(previousSelectedLocationAtom, last);
        set(_selectedLocationAtom, update);
        set(selectedSectionAtom, update?.section);
      }
    },
  );

  export const setSeasonRuleFromLocationAtom = atom(null, (get, set, update: InventorySeasonLocationExtended) => {
    const marketplaceId = get(selectedMarketplaceEventAtom)?.marketplaceId;
    const defaultRule = get(localRuleDefaultAtom);
    const principalPricerSettings = get(auth.principalAtom)?.settings?.pricerSettings;
    const tenantPricerSettings = get(auth.tenantsAtom).filter((t) => t.tenantId === update?.tenantId)[0]?.settings?.pricerSettings;
    const newRule = extendRuleFromSettings(defaultRule, marketplaceId, update?.row, update?.listings[0].quantity ?? 0, principalPricerSettings, tenantPricerSettings, {
      isAutoPriced: false,
    });
    newRule.ruleId = uuid();
    set(ruleStateAtom, newRule);
  });

  export const clearRuleStateFromLocationsAtom = atom(null, (get, set) => {
    const season = get(seasons.selectedSeasonAtom);
    season?.locations.forEach((location) => {
      set(seasons.updateLocationAtom, location.hashId, { ruleId: undefined, ruleTier: undefined, ruleCount: undefined });
    });
  });
}
