import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { isDtiHosted } from '../utils/whitelabel-consts';

export const Auth0ProviderWithNavigate = ({ children }: any) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState | undefined) => {
    if (!appState?.returnTo) {
      navigate(window.location.pathname);
    } else {
      const returnTo = encodeURIComponent(appState.returnTo);

      navigate(`/auth/callback?returnTo=${returnTo}`);
    }
  };

  const domain = import.meta.env.VITE_AUTH_DOMAIN;
  const clientId = isDtiHosted ? import.meta.env.VITE_AUTH_DTI_CLIENT_ID : import.meta.env.VITE_AUTH_CLIENT_ID;
  const redirectUri = window.location.origin + '/auth/callback';
  const audience = isDtiHosted ? import.meta.env.VITE_AUTH_DTI_AUDIENCE : import.meta.env.VITE_AUTH_AUDIENCE;

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  return (
    <Auth0Provider domain={domain} clientId={clientId} redirectUri={redirectUri} audience={audience} onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  );
};
