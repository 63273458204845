import { BarkerCoreModelsCommonPrice } from '../api';
import dayjs from 'dayjs';
import { DateFormats } from './globals';
import { FormStatus } from '@mantine/form/lib/types';
import { OrderEdit } from '../types';

export function formatCurrency(value: number | BarkerCoreModelsCommonPrice | '' | null, includeCents: boolean = true): string {
  if (typeof value === 'number') {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: includeCents ? 2 : 0, maximumFractionDigits: includeCents ? 2 : 0 });
  }

  if (value === '') {
    return '';
  }

  return (
    value?.value?.toLocaleString('en-US', {
      style: 'currency',
      currency: value?.currency,
      minimumFractionDigits: includeCents ? 2 : 0,
      maximumFractionDigits: includeCents ? 2 : 0,
    }) || ''
  );
}

export function formatCurrencyNumber(value: number) {
  return value % 1 === 0 ? value : parseFloat(value.toFixed(2));
}

export function formatNumber(value: number) {
  return value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
}

export function formatDate(value: Date, dateFormat: DateFormats): string {
  let result = dayjs(value).format(dateFormat);

  if (result.includes('3:30 AM')) {
    result = result.replace('3:30 AM', 'TBA');
  } else if (result.includes('3:30am')) {
    result = result.replace('3:30am', 'TBA');
  }

  return result;
}

export function capitalizeIfAllSame(input: string): string {
  return input.replace(/\b[a-zA-Z]+\b/g, (word) => {
    if (/^(.)\1*$/i.test(word)) {
      return word.toUpperCase();
    }
    return word;
  });
}

export function formatMarketplaceSection(value: string): string {
  const comparableValue = value.toLowerCase();
  let result = value;

  if (comparableValue === 'ga') {
    return 'General Admission';
  }

  if (comparableValue.includes('ga ')) {
    result = result.replace('ga ', 'GA ');
  } else if (comparableValue.endsWith(' ga')) {
    result = result.replace(' ga', ' GA');
  } else if (comparableValue.includes('wc ')) {
    result = result.replace('wc ', 'WC ');
  } else if (comparableValue.endsWith(' wc')) {
    result = result.replace(' wc', ' WC');
  }

  if (comparableValue.includes('vip ')) {
    result = result.replace('vip ', 'VIP ');
  } else if (comparableValue.endsWith(' vip')) {
    result = result.replace(' vip', ' VIP');
  }

  if (comparableValue.includes('sro')) {
    result = result.replace('sro', 'SRO');
  }

  result = capitalizeIfAllSame(result);

  if (result.length <= 2) {
    result = result.toUpperCase();
  }

  return result;
}

export function formatPricedAt(pricedAt?: Date | null, posPricedAt?: Date | null, unitPrice?: number, latestPrice?: number | null) {
  const posNewerThanPricedAt =
    !pricedAt ||
    (latestPrice && latestPrice !== unitPrice) ||
    (latestPrice && latestPrice !== unitPrice && posPricedAt && dayjs(posPricedAt).isAfter(dayjs(pricedAt).add(60, 'seconds'))); // Add 60 seconds to account for time differences
  const fromNow = posNewerThanPricedAt ? (!posPricedAt ? 'Unknown' : dayjs(posPricedAt).fromNow(true)) : dayjs(pricedAt).fromNow(true);
  const response = pricedAt || posPricedAt ? fromNow : 'Never';
  return response === 'a few seconds' ? 'Now' : response;
}

export function formatSeatRange(seatFrom?: string, seatThru?: string, quantity?: number) {
  if (seatFrom === undefined || seatThru === undefined || quantity === undefined) {
    return '';
  }

  if (quantity === 1) {
    return seatFrom;
  }

  return `${seatFrom}-${seatThru}`;
}

export function seatRange(seatFrom: number, quantity: number, isOddEven: boolean) {
  const seats: number[] = [];
  for (let i = 0; i < quantity; i++) {
    seats.push(seatFrom + (isOddEven ? i * 2 : i));
  }
  return seats;
}

// export function parsePropertiesChanged(changes: Record<string, boolean>[]) {
export function parsePropertiesChanged(changes: FormStatus): (keyof OrderEdit | string)[] {
  return Array.from(
    new Set(
      Object.keys(changes)
        .map((key) => key.split('.').pop())
        .filter((key): key is string => !!key),
    ),
  ) as (keyof OrderEdit)[];
}
