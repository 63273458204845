import { IconPropsBase } from './IconPropsBase';

export default function MarketplaceAXSIcon({ size = 24, color = 'currentColor' }: IconPropsBase) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <path fill={color} d="M21.77,2.47c.22-.26.12-.47-.22-.47h-7.11c-.25,0-.55.18-.67.41l-.56,1.11,2.24,6.34,6.31-7.4h0Z" />
      <path
        fill={color}
        d="M20.1,21.56L10.34,2.54c-.15-.3-.55-.54-.89-.54H2.44c-.34,0-.44.21-.22.47l6.76,8.07-3.73,7.35c-.15.3,0,.55.34.55h1.98c.28,0,.44-.2.44-.2l3.75-4.39,6.47,7.72s.35.44.75.44h.85c.11,0,.49,0,.26-.44h0"
      />
    </svg>
  );
}
