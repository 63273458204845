import { Paper } from '@mantine/core';
import { SeatingChartProvider } from '../hoc/SeatingChart/SeatingChart.hooks';
import { RuleStateProvider } from '../data/RuleState';
import { BulletinModal } from '../hoc/Bulletin/BulletinModal';
import { InviteModal } from '../hoc/InviteModal/InviteModal';
import { VersionNotificationBanner } from '../hoc/Banners/VersionNotificationBanner';
import { SupportActivationBanner } from '../hoc/Banners/SupportActivationBanner';
import { BulkStateProvider } from '../data/BulkState';
import { PlanExpirationBanner } from '../hoc/Banners/PlanExpirationBanner';
import { UnpaidInvoiceBanner } from '../hoc/Banners/UnpaidInvoiceBanner';
import { IntegrationProblemBanner } from '../hoc/Banners/IntegrationProblemBanner';
import { ListingStateProvider } from '../data/ListingState';
import { useQueryParam } from '../utils/use-query-param';
import { useEffect, useMemo } from 'react';
import { useGetApiInventory } from '../api';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import { forceRuleIdAtom, initialPageLoadAtom, searchResultsAtom, selectedEventAtom, selectedSearchEventsAtom, selectedTenantListingIdAtom } from '../data/atoms';
import { TenantIdListingId } from '../models/tenantIdListingId';
import { NowPricing } from '../hoc/NowPricing/NowPricing';
import { SeatingChart } from '../hoc/SeatingChart/SeatingChart';
import { MarketListings } from '../hoc/MarketListings/MarketListings';

export default function RuleIsolation() {
  return (
    <ListingStateProvider>
      <RuleStateProvider>
        <BulkStateProvider>
          <BulletinModal />
          <InviteModal />
          <div className="outer-appWrap rule-isolated">
            <VersionNotificationBanner />
            <SupportActivationBanner />
            <PlanExpirationBanner />
            <UnpaidInvoiceBanner />
            <IntegrationProblemBanner />
            <RuleIsolationInterior />
          </div>
        </BulkStateProvider>
      </RuleStateProvider>
    </ListingStateProvider>
  );
}

function RuleIsolationInterior() {
  // TODO: Figure out if we need a listing to display.
  // TODO: Move this state into a yasml hook.
  const eventId = useQueryParam('eventId', '');
  const listingId = useQueryParam('listingId', '');
  // TODO: Fetch the rule based on this rule template ruleId.
  const templateRuleId = useQueryParam('ruleId', '');
  const setForceRuleId = useSetAtom(forceRuleIdAtom);
  const defaultSearchDates = useMemo(
    () => ({
      fromDate: new Date(),
      toDate: dayjs().add(100, 'years').toDate(),
    }),
    [],
  );
  const setSelectedEvent = useSetAtom(selectedEventAtom);
  const setSearchResults = useSetAtom(searchResultsAtom);
  const setSelectedListing = useSetAtom(selectedTenantListingIdAtom);
  const setSelectedSearchEvents = useSetAtom(selectedSearchEventsAtom);
  const { refetch: refetchSingleEvent } = useGetApiInventory(
    {
      ...defaultSearchDates,
      eventIds: [eventId],
      listingIds: [listingId],
    },
    {
      query: {
        enabled: false,
        select(data) {
          return data.data;
        },
      },
    },
  );

  const setInitialPageLoad = useSetAtom(initialPageLoadAtom);
  useEffect(() => {
    setInitialPageLoad(false);
  }, [setInitialPageLoad]);

  useEffect(() => {
    if (eventId) {
      refetchSingleEvent().then((result) => {
        if (result.data) {
          setSearchResults(result.data);
          setSelectedSearchEvents(result.data.events);
          const event = result.data?.events[0];
          if (event) {
            setSelectedEvent(event);
            const listing = result.data?.listings.find((_listing) => _listing.eventId === event.eventId && _listing.listingId === listingId);
            if (listing) {
              setSelectedListing(TenantIdListingId.from(listing.tenantId, listing.listingId).toStringTyped());
              if (!listing.ruleId && templateRuleId) {
                setForceRuleId(templateRuleId);
              }
            }
          }
        } else if (templateRuleId) {
          setForceRuleId(templateRuleId);
        }
      });
      // Fetch Event and set it as selectedEvent.
    }
  }, [eventId, listingId, refetchSingleEvent, templateRuleId, setForceRuleId, setSearchResults, setSelectedEvent, setSelectedListing, setSelectedSearchEvents]);

  return (
    <div className="inner-appWrap">
      <div className="isolated-rule-appWrap">
        <div className="isolated-rule-col-1">
          <Paper className="seatingChartWrapper" pos="relative">
            <SeatingChartProvider>
              <SeatingChart />
            </SeatingChartProvider>
          </Paper>
        </div>
        <div className="isolated-rule-col-2">
          <NowPricing />
          <MarketListings />
        </div>
      </div>
    </div>
  );
}
