import { IconPropsBase } from './IconPropsBase';

export default function ArrowDropdownIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path fill={color} d="m12 14.65-4.4-4.4h8.8Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path fill={color} d="m10 11.708-3.5-3.5h7Z" />
      </svg>
    );
  }
}
