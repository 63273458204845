import { IconPropsBase } from './IconPropsBase';

export default function RemoveIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path fill={color} d="M5.25 12.75v-1.5h13.5v1.5Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path fill={color} d="M5.208 10.542V9.458h9.584v1.084Z" />
      </svg>
    );
  }
}
