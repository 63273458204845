import { Box, Group, MantineSize, MultiSelect, MultiSelectProps, Text } from '@mantine/core';
import AccessibilityNewIcon from '../../../components/icons/AccessibilityNew';
import AccessibleIcon from '../../../components/icons/Accessible';
import VisibilityOffIcon from '../../../components/icons/VisibilityOff';
import { useSeatingChart } from '../SeatingChart.hooks';
import { BarkerCoreEnumsDeliveryMethod, BarkerCoreEnumsListingDisclosure } from '../../../api';
import classes from './Filters.ExclusionMultiSelect.styles.tsx.module.css';
import LocalShippingIcon from '../../../components/icons/LocalShipping';
import FlashOnIcon from '../../../components/icons/FlashOn';
import QrCodeIcon from '../../../components/icons/QrCode';
import ConfirmationNumberIcon from '../../../components/icons/ConfirmationNumber';
import LocationOnIcon from '../../../components/icons/LocationOn';

type ExclusionMultiSelectProps = {
  size?: MantineSize;
};

export function ExclusionMultiSelect({ size = 'xs' }: ExclusionMultiSelectProps) {
  const { rule, form } = useSeatingChart('rule', 'form');

  const renderOption: MultiSelectProps['renderOption'] = ({ option }) => {
    const item = ExclusionsList.find(({ value }) => value === option.value);
    return (
      <Box className={classes.selectItem}>
        <Group gap={10} wrap="nowrap">
          {item?.icon}
          <div>
            <Text size="xs">{item?.label}</Text>
          </div>
        </Group>
      </Box>
    );
  };

  // Custom values that will be listed in the dropdown
  // const SelectItem = memo(
  //   forwardRef<HTMLDivElement, ItemProps>(({ icon, label, description, ...others }: ItemProps, ref) => (
  //     <div ref={ref} {...others} className={classes.selectItem}>
  //       <Group gap={10} wrap="nowrap">
  //         {icon}
  //         <div>
  //           <Text fz="xs">{label}</Text>
  //         </div>
  //       </Group>
  //     </div>
  //   )),
  // );

  // Custom values that will appear in the input when selected
  // const ValueItem = memo(
  //   ({
  //     value,
  //     label,
  //     onRemove,
  //   }: MultiSelectProps & {
  //     value: BarkerCoreEnumsListingDisclosure | BarkerCoreEnumsDeliveryMethod;
  //   }) => (
  //     <Tooltip label={label} withArrow>
  //       <UnstyledButton onMouseDown={() => onRemove} className={classes.valueItem}>
  //         <Box pl={4} pr={2} className={classes.valueItemContent}>
  //           {value === BarkerCoreEnumsListingDisclosure.Accessibility && <AccessibleIcon size={16} />}
  //           {value === BarkerCoreEnumsListingDisclosure.LimitedObstructedView && <VisibilityOffIcon size={16} />}
  //           {value === BarkerCoreEnumsListingDisclosure.StandingRoomOnly && <AccessibilityNewIcon size={16} />}
  //           {value === BarkerCoreEnumsDeliveryMethod.Electronic && <QrCodeIcon size={16} />}
  //           {value === BarkerCoreEnumsDeliveryMethod.Instant && <FlashOnIcon size={16} />}
  //           {value === BarkerCoreEnumsDeliveryMethod.Physical && <LocalShippingIcon size={16} />}
  //           {value === BarkerCoreEnumsDeliveryMethod.Unknown && <ConfirmationNumberIcon size={16} />}
  //           {value === BarkerCoreEnumsDeliveryMethod.Special && <LocationOnIcon size={16} />}
  //         </Box>
  //         <CloseIcon size={12} />
  //       </UnstyledButton>
  //     </Tooltip>
  //   ),
  // );

  return (
    <Box className={classes.wrapper}>
      <MultiSelect
        className={classes.multiSelect}
        data={ExclusionsList.map((item) => item.value)}
        size={size}
        label="Exclusions"
        placeholder="None"
        maxDropdownHeight={280}
        // itemComponent={SelectItem}
        renderOption={renderOption}
        // valueComponent={ValueItem}
        hidePickedOptions
        defaultValue={[]}
        value={((form.values.exclusions ?? []) as (BarkerCoreEnumsListingDisclosure | BarkerCoreEnumsDeliveryMethod)[]).concat(rule.filters.deliveryExclusions ?? [])}
        onChange={(value: (BarkerCoreEnumsListingDisclosure | BarkerCoreEnumsDeliveryMethod | string)[]) => {
          const deliveryExclusions = value.filter((v) =>
            Object.values(BarkerCoreEnumsDeliveryMethod).includes(v as unknown as BarkerCoreEnumsDeliveryMethod),
          ) as BarkerCoreEnumsDeliveryMethod[];
          const disclosureExclusions = value.filter((v) =>
            Object.values(BarkerCoreEnumsListingDisclosure).includes(v as unknown as BarkerCoreEnumsListingDisclosure),
          ) as BarkerCoreEnumsListingDisclosure[];

          // setRule({ ...rule, filters: { ...rule.filters, exclusions: disclosureExclusions, deliveryExclusions } });
          form.setFieldValue('exclusions', disclosureExclusions);
          form.setFieldValue('deliveryExclusions', deliveryExclusions);
        }}
        data-pendo-name="Exclusions Filter MultiSelect"
        // withinPortal={mobile}
      />
    </Box>
  );
}

export const ExclusionsList = [
  {
    group: 'Disclosures',
    icon: <VisibilityOffIcon color="var(--colors-iconFill)" />,
    label: 'Limited View',
    value: BarkerCoreEnumsListingDisclosure.LimitedObstructedView,
  },
  {
    group: 'Disclosures',
    icon: <AccessibleIcon color="var(--colors-iconFill)" />,
    label: 'Accessible Seating',
    value: BarkerCoreEnumsListingDisclosure.Accessibility,
  },
  {
    group: 'Disclosures',
    icon: <AccessibilityNewIcon color="var(--colors-iconFill)" />,
    label: 'Standing Room Only',
    value: BarkerCoreEnumsListingDisclosure.StandingRoomOnly,
  },
  {
    group: 'Delivery Methods',
    icon: <FlashOnIcon color="var(--colors-iconFill)" />,
    label: 'Instant Delivery',
    value: BarkerCoreEnumsDeliveryMethod.Instant,
  },
  {
    group: 'Delivery Methods',
    icon: <QrCodeIcon color="var(--colors-iconFill)" />,
    label: 'Electronic Delivery',
    value: BarkerCoreEnumsDeliveryMethod.Electronic,
  },
  {
    group: 'Delivery Methods',
    icon: <LocalShippingIcon color="var(--colors-iconFill)" />,
    label: 'Physical Delivery',
    value: BarkerCoreEnumsDeliveryMethod.Physical,
  },
  {
    group: 'Delivery Methods',
    icon: <LocationOnIcon color="var(--colors-iconFill)" />,
    label: 'Special Delivery',
    value: BarkerCoreEnumsDeliveryMethod.Special,
  },
  {
    group: 'Delivery Methods',
    icon: <ConfirmationNumberIcon color="var(--colors-iconFill)" />,
    label: 'Unknown Delivery',
    value: BarkerCoreEnumsDeliveryMethod.Unknown,
  },
];
