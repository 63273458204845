import { IconPropsBase } from './IconPropsBase';

export default function MotionPhotosPausedFillIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M376.155-367.693h59.999v-224.614h-59.999v224.614Zm147.691 0h59.999v-224.614h-59.999v224.614Zm-43.779 267.692q-78.836 0-148.204-29.857-69.369-29.856-120.682-81.039-51.314-51.182-81.247-120.374-29.933-69.191-29.933-147.96 0-39.587 7.847-77.793 7.846-38.206 22.922-74.513l46.615 46.614q-8.385 26-12.885 52.137T160-480q0 134 93 227t227 93q134 0 227-93t93-227q0-134-93-227t-227-93q-27 0-52.885 4.5-25.884 4.5-51.269 12.885l-45.999-45.999q35.769-15.308 72.115-23.346 36.346-8.039 75.73-8.039 79.236 0 148.925 29.77 69.69 29.769 121.382 81.268 51.692 51.5 81.846 120.81 30.154 69.311 30.154 148.115 0 78.805-29.92 148.173-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM228.462-683.846q-19.615 0-33.653-14.039-14.038-14.038-14.038-33.653t14.038-33.653q14.038-14.038 33.653-14.038t33.653 14.038q14.039 14.038 14.039 33.653t-14.039 33.653q-14.038 14.039-33.653 14.039ZM240-480q0-100 70-170t170-70q100 0 170 70t70 170q0 100-70 170t-170 70q-100 0-170-70t-70-170Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M388.155-367.693h51.999v-224.614h-51.999v224.614Zm131.691 0h51.999v-224.614h-51.999v224.614Zm-39.503 251.692q-75.112 0-141.48-28.357-66.369-28.356-116.182-78.039-49.814-49.682-78.247-115.874-28.433-66.191-28.433-141.386 0-37.735 7.347-74.154 7.346-36.419 22.422-70.726l40.615 40.614q-8.385 25-13.385 51.137T168-480q0 130 91 221t221 91q130 0 221-91t91-221q0-130-91-221t-220.839-91q-26.161 0-52.381 4.5t-51.934 12.885l-39.999-39.999q33.769-12.308 69.615-20.846 35.846-8.539 72.23-8.539 76.236 0 142.425 28.27 66.19 28.269 116.382 78.268 50.192 50 78.846 116.035 28.654 66.034 28.654 141.115 0 75.08-28.42 141.448-28.42 66.369-78.21 116.182-49.791 49.814-115.853 78.247t-141.173 28.433ZM236.462-675.846q-19.615 0-33.653-14.039-14.038-14.038-14.038-33.653t14.038-33.653q14.038-14.038 33.653-14.038t33.653 14.038q14.039 14.038 14.039 33.653t-14.039 33.653q-14.038 14.039-33.653 14.039ZM240-480q0-100 70-170t170-70q100 0 170 70t70 170q0 100-70 170t-170 70q-100 0-170-70t-70-170Z"
        />
      </svg>
    );
  }
}
