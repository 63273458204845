import { Box, Group, Modal, Text, Tooltip } from '@mantine/core';
import LiveHelpIcon from '../../components/icons/LiveHelp';
import { ListingCostIconLabel } from '../../components/Label/ListingCostLabel';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { useDisclosure } from '@mantine/hooks';
import RemoveIcon from '../../components/icons/Remove';
import classes from './Inventory.GroupHeader.module.css';
import cx from 'clsx';
import { seasons } from '../../data/atoms.seasons';
import { useGlobalState } from '../../data/GlobalState';

export const GroupHeader = () => {
  const selectedSeason = useAtomValue(seasons.selectedSeasonAtom);
  const tenantId = selectedSeason?.tenantId;
  const { tenants, principal } = useGlobalState('tenants', 'principal');
  const tenantName = useMemo(() => tenants?.find((t) => t.tenantId === tenantId)?.name || 'Unknown', [tenantId, tenants]);

  // Set tenant color name by searching through color arrays
  const tenantColorName = useMemo(() => {
    // List all possible tenant colors from previous options
    const tenantRed = ['#C92A2A', '#F03E3E', '#FF6B6B', '#FFA8A8', 'var(--colors-tenant-red-5)', 'red'];
    const tenantOrange = ['#D9480F', '#F76707', '#FF922B', '#FFC078', 'var(--colors-tenant-orange-5)', 'orange'];
    const tenantYellow = ['#E67700', '#F59F00', '#FCC419', '#FFE066', 'var(--colors-tenant-yellow-5)', 'yellow'];
    const tenantGreen = ['#2B8A3E', '#37B24D', '#51CF66', '#8CE99A', 'var(--colors-tenant-green-5)', 'green'];
    const tenantCyan = ['#0B7285', '#1098AD', '#22B8CF', '#66D9E8', 'var(--colors-tenant-cyan-5)', 'cyan'];
    const tenantBlue = ['#1864AB', '#1C7ED6', '#339AF0', '#74C0FC', 'var(--colors-tenant-blue-5)', 'blue'];
    const tenantGrape = ['#862E9C', '#AE3EC9', '#CC5DE8', '#E599F7', 'var(--colors-tenant-grape-5)', 'grape'];
    const tenantPink = ['#A61E4D', '#D6336C', '#F06595', '#FAA2C1', 'var(--colors-tenant-pink-5)', 'pink'];
    const tenantBrandColor = ['#3d8161', '#63b187', 'var(--colors-brandgreen-5)', 'brandgreen', 'var(--colors-brandcolor-5)', 'brandcolor'];
    const tenantBlack = ['#000000', 'black'];
    const tenantGray4 = ['#333333', 'gray4'];
    const tenantGray3 = ['#666666', 'gray3'];
    const tenantGray2 = ['#999999', 'gray2'];
    const tenantGray1 = ['#E0E0E0', 'gray1'];

    const tenantColor = tenantId ? principal?.settings?.tenantColors[tenantId] : 'brandcolor';

    if (tenantColor) {
      if (tenantBrandColor.includes(tenantColor)) {
        return 'brandcolor';
      }
      if (tenantRed.includes(tenantColor)) {
        return 'red';
      }
      if (tenantOrange.includes(tenantColor)) {
        return 'orange';
      }
      if (tenantYellow.includes(tenantColor)) {
        return 'yellow';
      }
      if (tenantGreen.includes(tenantColor)) {
        return 'green';
      }
      if (tenantCyan.includes(tenantColor)) {
        return 'cyan';
      }
      if (tenantBlue.includes(tenantColor)) {
        return 'blue';
      }
      if (tenantGrape.includes(tenantColor)) {
        return 'grape';
      }
      if (tenantPink.includes(tenantColor)) {
        return 'pink';
      }
      if (tenantBlack.includes(tenantColor)) {
        return 'black';
      }
      if (tenantGray4.includes(tenantColor)) {
        return 'gray4';
      }
      if (tenantGray3.includes(tenantColor)) {
        return 'gray3';
      }
      if (tenantGray2.includes(tenantColor)) {
        return 'gray2';
      }
      if (tenantGray1.includes(tenantColor)) {
        return 'gray1';
      }
    }
    return 'brandcolor';
  }, [principal?.settings?.tenantColors, tenantId]);

  const isMultiTenant = tenants && tenants.length > 1;
  const tenantClassName = isMultiTenant ? `tenant-${tenantColorName}` : 'tenant-single';

  const [supportOpen, { close: closeSupport }] = useDisclosure();

  return (
    <Box className={cx([classes.disableGroupHeaderWrapper, 'listingsGroupHeaderWrapper', tenantClassName])}>
      <Group
        className="dragOverlay"
        data-overlay-id={selectedSeason?.seasonName}
        justify="space-between"
        pos="absolute"
        top={4}
        bottom={4}
        right={5}
        left={8}
        bg="var(--colors-red-0)"
      >
        <Box
          className="icon"
          style={{
            border: '1px solid var(--colors-red-5)',
            borderRadius: 100,
            height: 18,
            width: 18,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <RemoveIcon color="var(--colors-red-5)" />
        </Box>
        <Text className="dragOverlayText" fw={700} ta="center" px="xs" lh={1.3} display="flex" style={{ whiteSpace: 'normal' }}>
          Drop Here to Remove from Group
        </Text>
        <Box
          className="icon"
          style={{
            border: '1px solid var(--colors-red-5)',
            borderRadius: 100,
            height: 18,
            width: 18,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <RemoveIcon color="var(--colors-red-5)" />
        </Box>
      </Group>
      <Group m={0} p={0} px={16} className="listingsGroupHeader">
        {tenants && tenants.length > 1 ? (
          <Tooltip
            position="right"
            label={
              <Group wrap="nowrap" gap={8}>
                <Text>{tenantName}</Text>
                <Box h={13} w={13} pos="relative" className={cx([classes.tenantDot, `tenant-${tenantColorName}`])} />
              </Group>
            }
            withArrow
          >
            <Box className={`${classes.sideStripe} side-stripe`} />
          </Tooltip>
        ) : (
          <Box className={`${classes.sideStripe} side-stripe side-stripe-no-hover`} />
        )}
        <Box py={4} className={classes.leftSide}>
          <Box className={classes.dateTime}>
            <Text className="listingsDate1" size="sm" fw={600}>
              {/* {formatEventDate(event.localDateTime, currentYear ? DateFormats.MonthDay : DateFormats.MonthDayYear)} */}
              {selectedSeason?.seasonYear}
            </Text>
            <Text className="listingsDate2" mt={-2} fw={500}>
              Season
              {/* {formatEventDate(event.localDateTime, DateFormats.Short)} */}
            </Text>
          </Box>
          <Box className={classes.overflowHidden}>
            <Text size="sm" className="listingsTitle" fw={600}>
              {selectedSeason?.seasonName}
            </Text>
            <Text mt={-2} className="listingsDesc" fw={500}>
              {`${selectedSeason?.venueName} · ${selectedSeason?.city}, ${selectedSeason?.state}`}
            </Text>
          </Box>
        </Box>
        <Group pl={8} pr={8} gap="xs" c="var(--colors-gray-5)">
          <Group gap="xs" c="var(--colors-gray-5)">
            <ListingCostIconLabel cost={selectedSeason?.totalCost || 0} />
            {/* <OpenlistingsLabel listings={event.openListings} /> */}
            {/* <OpenticketsLabel tickets={event.openTickets} /> */}
          </Group>
        </Group>
      </Group>
      {supportOpen && (
        <Modal
          opened={supportOpen}
          onClose={closeSupport}
          closeOnClickOutside={false}
          title={
            <Group gap="xs">
              <LiveHelpIcon color="var(--colors-iconFill)" />
              <Text size="sm">Support Request - Inventory</Text>
            </Group>
          }
          centered
          size="md"
        >
          {/* <SupportForm type="inventoryEvent" event={event} onClose={closeSupport} pointOfSaleId={tenantPointOfSale} /> */}
        </Modal>
      )}
    </Box>
  );
};
