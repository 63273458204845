import { IconPropsBase } from './IconPropsBase';

export default function FiltersIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M11.25 20.75v-5.5h1.5v2h8v1.5h-8v2Zm-8-2v-1.5h5.5v1.5Zm4-4v-2h-4v-1.5h4v-2h1.5v5.5Zm4-2v-1.5h9.5v1.5Zm4-4v-5.5h1.5v2h4v1.5h-4v2Zm-12-2v-1.5h9.5v1.5Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M9.708 16.792v-4.584h1.084v1.75h6v1.084h-6v1.75Zm-6.5-1.75v-1.084h4.584v1.084Zm3-2.75v-1.75h-3V9.458h3v-1.75h1.084v4.584Zm3-1.75V9.458h7.584v1.084Zm3-2.75V3.208h1.084v1.75h3.5v1.084h-3.5v1.75Zm-9-1.75V4.958h7.584v1.084Z"
        />
      </svg>
    );
  }
}
