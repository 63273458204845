import { Text, TextProps } from '@mantine/core';

type Props = {
  currency?: boolean;
  children: React.ReactNode;
};
export default function BNText(props: TextProps & Props) {
  const { currency, children, ...rest } = props;
  if (currency && (typeof children === 'string' || typeof children === 'number')) {
    if (!children.toString().includes('$')) {
      const value = parseFloat(children.toString()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return <Text {...rest}>${value}</Text>;
    }
  }
  return <Text {...rest}>{children}</Text>;
}
