export type BarkerCoreEnumsListingDisclosure = (typeof BarkerCoreEnumsListingDisclosure)[keyof typeof BarkerCoreEnumsListingDisclosure];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BarkerCoreEnumsListingDisclosure = {
  LimitedObstructedView: 'LimitedObstructedView',
  Accessibility: 'Accessibility',
  StandingRoomOnly: 'StandingRoomOnly',
  ResaleRestricted: 'ResaleRestricted',
  FanListing: 'FanListing',
} as const;
