import { IconPropsBase } from './IconPropsBase';

export default function ArrowForwardIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path fill={color} d="m12 19.625-1.075-1.075 5.825-5.8H4.375v-1.5H16.75l-5.825-5.8L12 4.375 19.625 12Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path fill={color} d="m10 15.688-.771-.792 4.375-4.354H4.312V9.458h9.292L9.229 5.104 10 4.312 15.688 10Z" />
      </svg>
    );
  }
}
