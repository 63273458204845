import { IconPropsBase } from './IconPropsBase';

export default function PlaylistAddIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M130.001-330.001v-59.998h280v59.998h-280Zm0-160v-59.998h440v59.998h-440Zm0-160v-59.998h440v59.998h-440Zm520 480v-160h-160v-59.998h160v-160h59.998v160h160v59.998h-160v160h-59.998Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M154.001-406.001v-51.998h288v51.998h-288Zm0-150v-51.998h432v51.998h-432Zm0-150v-51.998h432v51.998h-432Zm492 456v-156h-156v-51.998h156v-156h51.998v156h156v51.998h-156v156h-51.998Z"
        />
      </svg>
    );
  }
}
