import { Box, Group, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { DateFormats } from '../../utils/globals';
import { useMemo } from 'react';
import { formatDate } from '../../utils/formatters';
import classes from '../Inventory/Inventory.GroupHeader.module.css';
import cx from 'clsx';
import { BarkerCoreModelsDTIGetEventsResponseItem } from '../../api';

export const GroupHeader = ({ event }: { event: BarkerCoreModelsDTIGetEventsResponseItem }) => {
  const currentYear = useMemo(() => dayjs().year() === dayjs(event?.event_date_time).year(), [event?.event_date_time]);

  if (!event) return null;

  return (
    <Box className={cx([classes.groupHeaderWrapper, 'listingsGroupHeaderWrapper'])}>
      <Group m={0} p={0} px={16} className="listingsGroupHeader">
        <Box className={`${classes.sideStripe} side-stripe side-stripe-no-hover`} />
        <Box py={4} className={classes.leftSide}>
          <Box className={classes.dateTime}>
            <Text className="listingsDate1" size="sm" fw={600}>
              {formatDate(dayjs(event.event_date_time).toDate(), currentYear ? DateFormats.MonthDay : DateFormats.MonthDayYear)}
            </Text>
            <Text className="listingsDate2" mt={-2} fw={500} fz="xs">
              {formatDate(dayjs(event.event_date_time).toDate(), DateFormats.Short)}
            </Text>
          </Box>
          <Box className={classes.overflowHidden}>
            <Text size="sm" className="listingsTitle" fw={600}>
              {event.event_name}
            </Text>
            <Text mt={-2} className="listingsDesc" fw={500} fz="xs">
              {event.venue_name}
            </Text>
          </Box>
        </Box>
      </Group>
    </Box>
  );
};
