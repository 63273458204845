import { ActionIcon, Box, Flex, FocusTrap, Group, Text, TextInput } from '@mantine/core';
import mobileClasses from './Mobile.module.css';
import classes from './EventListings.Mobile.module.css';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ChevronBackIcon from '../components/icons/ChevronBack';
import SearchIcon from '../components/icons/Search';
import { BNButton } from '../components/Button/Button';
import CloseIcon from '../components/icons/Close';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BNOrderSelect, { SortOrder } from '../components/Select/OrderSelect';
import { useSearchResults } from '../hoc/EventSearch/SearchResults.hooks';
import dayjs from 'dayjs';
import { DateFormats } from '../utils/globals';
import { useInventory } from '../hoc/Inventory/Inventory.hooks';
import { useGlobalState } from '../data/GlobalState';
import { useAtomValue, useSetAtom } from 'jotai/index';
import { searchResultsAtom, selectedSearchEventsAtom, updateListingsAtom } from '../data/atoms';
import { BNEmptyState } from '../components/EmptyState/EmptyState';
import { ListingCostIconLabel } from '../components/Label/ListingCostLabel';
import { OpenlistingsLabel } from '../components/Label/OpenListingsLabel';
import { OpenticketsLabel } from '../components/Label/OpenTicketsLabel';
import { SortItem } from '../hoc/Inventory/Inventory.SortItem';
import { ListingCardWrapper } from '../hoc/Mobile/ListingCardWrapper';
import BNPullToRefresh from '../components/PullToRefresh/PullToRefresh';
import { useListingState } from '../data/ListingState';
import { TenantIdEventId } from '../models/tenantIdListingId';
import { NavBarMobile } from '../hoc/NavBar/NavBar.Mobile';
import { useQueryParam } from '../utils/use-query-param';

export function EventListingsMobile() {
  const navigate = useNavigate();
  const location = useLocation();
  const { eventId } = useParams();
  const tenantId = useQueryParam('tenantId', '') || location?.state?.tenantId;
  const events = useAtomValue(searchResultsAtom)?.events ?? [];
  const { isLoading } = useSearchResults('isLoading');
  const setSelectedEvents = useSetAtom(selectedSearchEventsAtom);

  useEffect(() => {
    // NOTE: There is a possible race condition when done loading and setting the atoms.
    if (!isLoading) {
      // NOTE: Temparary handle when tenantId is missing for direct routes.
      const found = events.find((x) => (tenantId ? x.tenantId === tenantId && x.eventId === eventId : x.eventId === eventId));
      if (!found) {
        navigate('/mobile');
      } else {
        setSelectedEvents([found]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return <EventListingsMobileInner />;
}

export function EventListingsMobileInner() {
  document.body.setAttribute('data-mobile', 'true');
  const [menuOpened, { close: closeMenu }] = useDisclosure(false);
  const [showSearch, { open: openSearch, close: closeSearch }] = useDisclosure(false);
  const [unsavedChanges, { close: hideUnsavedChanges }] = useDisclosure(false);
  const location = useLocation();
  const navigate = useNavigate();
  const handleReset = () => {
    navigate(`${location.pathname}${location.search}`, { state: { filter: '' } });
    handleQuickFilterChange('');
  };
  const [isSortOptionsOpen, setIsSortOptionsOpen] = useState(false);

  const sortOptions = ['Section', 'Row', 'Quantity', 'Cost', 'List Price', 'Repriced'];
  const [listingSort, setListingSort] = useLocalStorage<SortItem>({
    key: 'mobile-event-listings-sort',
    getInitialValueInEffect: false,
    defaultValue: { key: 'Section', name: 'Section', dir: 'asc' },
  });

  const { currentUser } = useGlobalState('currentUser');
  const { patchListing, setLastClickedActiveEventId } = useListingState('patchListing', 'setLastClickedActiveEventId');
  const { eventId } = useParams();
  const tenantId = useQueryParam('tenantId', '') || location.state?.tenantId;
  const { refetchLastClickedEvent, isLoadingEventId } = useSearchResults('refetchLastClickedEvent', 'isLoadingEventId');
  const searchResultEvents = useAtomValue(searchResultsAtom)?.events;
  const selectedEvent = searchResultEvents?.find((x) => x.tenantId === tenantId && x.eventId === eventId);
  const updateInventoryListing = useSetAtom(updateListingsAtom);
  const { mergedEventListings, setFilter, setInventoryQuickFilter, inventoryQuickFilter } = useInventory(
    'mergedEventListings',
    'setFilter',
    'setInventoryQuickFilter',
    'inventoryQuickFilter',
  );
  const filteredListings = mergedEventListings.filter((x) => x.tenantId === tenantId && x.eventId === eventId && !x.listingId.includes('sales'));
  const sortedListings = filteredListings.sort((a, b) => {
    if (listingSort.key === 'Section') {
      return listingSort.dir === 'asc'
        ? a.section.localeCompare(b.section, undefined, { numeric: true, sensitivity: 'base' })
        : b.section.localeCompare(a.section, undefined, { numeric: true, sensitivity: 'base' });
    }
    if (listingSort.key === 'Row') {
      return listingSort.dir === 'asc'
        ? a.row.localeCompare(b.row, undefined, { numeric: true, sensitivity: 'base' })
        : b.row.localeCompare(a.row, undefined, { numeric: true, sensitivity: 'base' });
    }
    if (listingSort.key === 'Quantity') {
      return listingSort.dir === 'asc' ? (a.quantityRemaining > b.quantityRemaining ? 1 : -1) : b.quantityRemaining > a.quantityRemaining ? 1 : -1;
    }
    if (listingSort.key === 'Cost') {
      return listingSort.dir === 'asc' ? (a.unitCost > b.unitCost ? 1 : -1) : b.unitCost > a.unitCost ? 1 : -1;
    }
    if (listingSort.key === 'List Price') {
      return listingSort.dir === 'asc' ? (a.unitPrice > b.unitPrice ? 1 : -1) : b.unitPrice > a.unitPrice ? 1 : -1;
    }
    if (listingSort.key === 'Repriced') {
      return listingSort.dir === 'asc'
        ? dayjs(a.pricedAt ?? new Date()).unix() < dayjs(b.pricedAt ?? new Date()).unix()
          ? 1
          : -1
        : dayjs(b.pricedAt ?? new Date()).unix() < dayjs(a.pricedAt ?? new Date()).unix()
          ? 1
          : -1;
    }
    return 0;
  });

  useEffect(() => {
    if (selectedEvent) {
      setLastClickedActiveEventId(TenantIdEventId.create(selectedEvent.tenantId, selectedEvent.eventId));
    }
  }, [selectedEvent, setLastClickedActiveEventId]);

  const handleQuickFilterChange = (value: string) => {
    setInventoryQuickFilter(value);
    setFilter(value);
  };

  useEffect(() => {
    // TODO: Figure out how to type this
    if (location.state?.filter) {
      openSearch();
      handleQuickFilterChange(location.state.filter);
    }
    if (location.state?.scrollPosition) {
      requestAnimationFrame(() => {
        document.getElementById('scrollArea')?.scrollTo(0, location.state.scrollPosition);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePrice = useCallback(
    async (_tenantId: string, listingId: string, price: number, previousPrice: number) => {
      const { data: listing } = await patchListing({
        tenantId: _tenantId,
        listingId,
        data: [
          { op: 'Replace', path: '/unitPrice', value: price },
          { op: 'Replace', path: '/previousPrice', value: previousPrice },
        ],
      });
      updateInventoryListing(listing);
    },
    [patchListing, updateInventoryListing],
  );

  const { tenants, principal } = useGlobalState('tenants', 'principal');
  const isMultiTenant = tenants && tenants.length > 1;
  // Set tenant color name by searching through color arrays
  const tenantColorName = useMemo(() => {
    if (!selectedEvent?.eventId || !isMultiTenant) return 'brandcolor';

    // List all possible tenant colors from previous options
    const tenantRed = ['#C92A2A', '#F03E3E', '#FF6B6B', '#FFA8A8', 'var(--colors-tenant-red-5)', 'red'];
    const tenantOrange = ['#D9480F', '#F76707', '#FF922B', '#FFC078', 'var(--colors-tenant-orange-5)', 'orange'];
    const tenantYellow = ['#E67700', '#F59F00', '#FCC419', '#FFE066', 'var(--colors-tenant-yellow-5)', 'yellow'];
    const tenantGreen = ['#2B8A3E', '#37B24D', '#51CF66', '#8CE99A', 'var(--colors-tenant-green-5)', 'green'];
    const tenantCyan = ['#0B7285', '#1098AD', '#22B8CF', '#66D9E8', 'var(--colors-tenant-cyan-5)', 'cyan'];
    const tenantBlue = ['#1864AB', '#1C7ED6', '#339AF0', '#74C0FC', 'var(--colors-tenant-blue-5)', 'blue'];
    const tenantGrape = ['#862E9C', '#AE3EC9', '#CC5DE8', '#E599F7', 'var(--colors-tenant-grape-5)', 'grape'];
    const tenantPink = ['#A61E4D', '#D6336C', '#F06595', '#FAA2C1', 'var(--colors-tenant-pink-5)', 'pink'];
    const tenantBrandColor = ['#3d8161', '#63b187', 'var(--colors-brandgreen-5)', 'brandgreen', 'var(--colors-brandcolor-5)', 'brandcolor'];
    const tenantBlack = ['#000000', 'black'];
    const tenantGray4 = ['#333333', 'gray4'];
    const tenantGray3 = ['#666666', 'gray3'];
    const tenantGray2 = ['#999999', 'gray2'];
    const tenantGray1 = ['#E0E0E0', 'gray1'];

    const tenantColor = principal?.settings?.tenantColors[selectedEvent?.tenantId];

    if (tenantColor) {
      if (tenantBrandColor.includes(tenantColor)) {
        return 'brandcolor';
      }
      if (tenantRed.includes(tenantColor)) {
        return 'red';
      }
      if (tenantOrange.includes(tenantColor)) {
        return 'orange';
      }
      if (tenantYellow.includes(tenantColor)) {
        return 'yellow';
      }
      if (tenantGreen.includes(tenantColor)) {
        return 'green';
      }
      if (tenantCyan.includes(tenantColor)) {
        return 'cyan';
      }
      if (tenantBlue.includes(tenantColor)) {
        return 'blue';
      }
      if (tenantGrape.includes(tenantColor)) {
        return 'grape';
      }
      if (tenantPink.includes(tenantColor)) {
        return 'pink';
      }
      if (tenantBlack.includes(tenantColor)) {
        return 'black';
      }
      if (tenantGray4.includes(tenantColor)) {
        return 'gray4';
      }
      if (tenantGray3.includes(tenantColor)) {
        return 'gray3';
      }
      if (tenantGray2.includes(tenantColor)) {
        return 'gray2';
      }
      if (tenantGray1.includes(tenantColor)) {
        return 'gray1';
      }
    }
    return 'brandcolor';
  }, [selectedEvent?.eventId, selectedEvent?.tenantId, isMultiTenant, principal?.settings?.tenantColors]);

  const tenantClassName = isMultiTenant ? `colors-tenant-mobile-${tenantColorName}` : 'tenant-single';
  document.body.style.background = `var(--${tenantClassName}, var(--colors-mobileBg))`;

  const scrollAreaRef = useRef<HTMLDivElement>(null);

  if (!selectedEvent) {
    return null;
  }

  const emptyStateTitle = `No listings found for "${inventoryQuickFilter ?? ''}"`;

  return (
    <>
      <div className={mobileClasses.mobileAppWrap}>
        <NavBarMobile
          leftSide={
            !unsavedChanges ? (
              <ActionIcon opacity={0.7} size={36} onClick={() => navigate('/mobile')} className={mobileClasses.chevronIconButton}>
                <ChevronBackIcon size={20} color="white" />
              </ActionIcon>
            ) : (
              <BNButton variant="default" className={mobileClasses.headerButtonDefault} h={28} w={64} px={0} onClick={() => hideUnsavedChanges()}>
                Cancel
              </BNButton>
            )
          }
          rightSide={
            !unsavedChanges ? (
              !showSearch ? (
                <ActionIcon
                  opacity={0.7}
                  size={36}
                  onClick={() => {
                    openSearch();
                  }}
                >
                  <SearchIcon size={24} color="white" />
                </ActionIcon>
              ) : (
                <ActionIcon
                  opacity={0.7}
                  size={36}
                  onClick={() => {
                    closeSearch();
                    handleReset();
                  }}
                >
                  <CloseIcon size={24} color="white" />
                </ActionIcon>
              )
            ) : (
              <BNButton
                className={mobileClasses.headerButtonFill}
                disabled={currentUser?.roleId === 'ReadOnlyUser'}
                variant="filled"
                bg="white"
                h={28}
                w={64}
                px={0}
                c={`var(--${tenantClassName}, var(--colors-mobileBg))`}
                onClick={() => hideUnsavedChanges()}
                style={{ borderRadius: 6 }}
              >
                Save
              </BNButton>
            )
          }
          titleArea={
            <>
              {!unsavedChanges ? (
                !showSearch && (
                  <Text fw={600} ta="center" c="white" lh={1.3} fz={14} truncate>
                    {selectedEvent.name}
                    <Text size="xs" component="span" display="block" fw={500} opacity={0.7} truncate>
                      {`${dayjs(selectedEvent.localDateTime).format(DateFormats.Standard)} · ${selectedEvent.venue.name}`}
                    </Text>
                  </Text>
                )
              ) : (
                <Text fw={600} ta="center" c="white" lh={2} fz={14} truncate onClick={() => hideUnsavedChanges()}>
                  Unsaved Changes
                </Text>
              )}
              {showSearch && (
                <FocusTrap>
                  <Flex className={!unsavedChanges ? classes.headerSearchActive : classes.headerSearchActiveWithChanges}>
                    <TextInput
                      size="sm"
                      variant="default"
                      leftSection={<SearchIcon size={24} />}
                      placeholder="Filter Listings"
                      type="search"
                      name="Search"
                      value={inventoryQuickFilter}
                      onChange={(e) => {
                        handleQuickFilterChange(e.currentTarget.value);
                      }}
                      data-autofocus="true"
                      autoFocus
                    />
                    {showSearch && unsavedChanges && (
                      <ActionIcon
                        pos="absolute"
                        right={8}
                        opacity={0.7}
                        size={36}
                        onClick={() => {
                          closeSearch();
                          handleReset();
                        }}
                      >
                        <CloseIcon size={24} color="white" />
                      </ActionIcon>
                    )}
                  </Flex>
                </FocusTrap>
              )}
            </>
          }
          menuOpened={menuOpened}
          closeMenu={closeMenu}
          containerStyle={{ background: `var(--${tenantClassName}, var(--colors-mobileBg))` }}
          headerBg="transparent"
          bottomContent={
            <>
              {unsavedChanges && showSearch && <Flex w="100%" h={44} />}
              <Flex h={40} fz={13} align="center" justify="space-between" px={16} c="white" style={{ borderTop: '1px dotted rgba(0,0,0,.3)' }}>
                <Group align="center" gap={2} className={classes.orderSelect}>
                  <Text className={classes.selectLabel}>Sort:</Text>
                  <BNOrderSelect
                    data={sortOptions.map((x) => x)}
                    isMobile
                    dropdownWidth={200}
                    selectedItem={listingSort.key}
                    isOpen={isSortOptionsOpen}
                    sortOrder={listingSort.dir as SortOrder}
                    onClose={() => setIsSortOptionsOpen(false)}
                    onClick={() => setIsSortOptionsOpen(!isSortOptionsOpen)}
                    onSortOrderChange={(value: 'asc' | 'desc') => {
                      setListingSort({ ...listingSort, dir: value });
                      setIsSortOptionsOpen(false);
                    }}
                    onSelectionChange={(value) => {
                      setListingSort({ ...listingSort, key: value });
                      setIsSortOptionsOpen(false);
                    }}
                  />
                </Group>
                <Group gap="xs" align="center">
                  <ListingCostIconLabel cost={selectedEvent.openCost} />
                  <OpenlistingsLabel listings={selectedEvent.openListings} />
                  <OpenticketsLabel tickets={selectedEvent.openTickets} />
                </Group>
              </Flex>
            </>
          }
        />
        <Flex direction="column" className={classes.listingsArea}>
          <Box className={`${classes.listingsListWrapper} scrollArea`} id="scrollArea" ref={scrollAreaRef}>
            <BNPullToRefresh wrapperRef={scrollAreaRef} listClass="scrollArea" refreshAction={refetchLastClickedEvent} topPadding={8} isRefreshing={isLoadingEventId !== null} />
            {sortedListings.length === 0 ? (
              <BNEmptyState
                title={emptyStateTitle}
                h="50%"
                bottomContent={
                  <BNButton
                    onClick={() => {
                      handleReset();
                      closeSearch();
                    }}
                    variant="default"
                    size="xs"
                    mt="xs"
                  >
                    Clear Filter
                  </BNButton>
                }
              />
            ) : (
              sortedListings.map((listing) => <ListingCardWrapper key={listing.listingId} listing={listing} updatePrice={updatePrice} scrollAreaRef={scrollAreaRef} />)
            )}
          </Box>
        </Flex>
      </div>
    </>
  );
}
