import {
  BarkerCoreEnumsAdjustmentType,
  BarkerCoreEnumsMarketplace,
  BarkerCoreEnumsPricerStatus,
  BarkerCoreEnumsRounding,
  BarkerCoreModelsAdministrativePricerSettings,
  BarkerCoreModelsAdministrativePricerSettingsQuantitySplitMatrix,
  BarkerCoreModelsAdministrativeRowComparison,
  BarkerCoreModelsAdministrativeTenantPricerSettings,
  BarkerCoreModelsAdministrativeTenantPricerSettingsQuantitySplitMatrix,
  BarkerCoreModelsInventoryListing,
  BarkerCoreModelsMarketplacesListing,
  BarkerCoreModelsMarketplacesSeatingChartZone,
  BarkerCoreModelsPricingRule,
  BarkerCoreModelsPricingRuleFilters,
} from '../api';
import { v4 as uuid } from 'uuid';
import { DEFAULT_FILTERS, DEFAULT_RULE } from '../data/constants';
import { ApplyFilters, GetTargetComparablePrice } from '../hoc/MarketListings/marketListingsFilter';
import { BarkerEventListing, MarketplacesListingWithAlias, Rule } from '../types';
import { weakEquals } from './weakEquals';
import { OmitStrict } from '../ts-utils';

const A = 'A'.charCodeAt(0);
const Z = 'Z'.charCodeAt(0);

export function getRowComparisonValue(listingRow: string | undefined, rowComparisons: BarkerCoreModelsAdministrativeRowComparison[] | undefined): string {
  // Note: Zero and empty string here will return empty string
  if (listingRow && rowComparisons) {
    // Check if listingRow is within range of regex
    const listingRowIsNumber = Number(listingRow);

    for (const rowComparison of rowComparisons) {
      const range = rowComparison.rowRange.split('-');
      const lower = range[0];
      const upper = range[1];
      if (Number(lower) && listingRowIsNumber) {
        const listingNumber = parseInt(listingRow);
        const lowerNumber = parseInt(lower);
        const upperNumber = parseInt(upper);
        if (listingNumber >= lowerNumber && listingNumber <= upperNumber) {
          const lowerResult = 1;
          const upperResult = listingNumber + rowComparison.threshold;
          return `${lowerResult}-${upperResult}`;
        }
      } else if (!Number(lower) && !listingRowIsNumber) {
        const lowerLetterCode = lower.toUpperCase().charCodeAt(0);
        const upperLetterCode = upper.toUpperCase().charCodeAt(0);
        const listingLetterCode = listingRow.toUpperCase().charCodeAt(0);
        if (listingLetterCode >= lowerLetterCode && listingLetterCode <= upperLetterCode) {
          // Don't process row names Test, Misc, etc.
          if (listingRow.length > 2) {
            return '';
          }
          // Handle if the double characters don't match
          if (listingRow.length === 2 && listingRow[0] !== listingRow[1]) {
            return '';
          }
          const lowerResult = 'A';
          let upperResult = '';
          if (listingLetterCode + rowComparison.threshold > Z) {
            upperResult = 'Z';
          } else if (listingLetterCode + rowComparison.threshold < A) {
            upperResult = 'A';
          } else {
            upperResult = String.fromCharCode(listingLetterCode + rowComparison.threshold);
          }

          if (listingRow.length === 2) {
            return `${lowerResult}${lowerResult}-${upperResult}${upperResult}`;
          }
          return `${lowerResult}-${upperResult}`;
        }
      }
    }
  }
  return '';
}

export function roundAdjustmentValue(value: number | null | undefined): number | null | undefined {
  if (value === null || value === undefined) {
    return value;
  }
  if (value > 0) {
    return Math.ceil(value);
  }
  return Math.floor(value);
}

export const calculateTargetPrice = ({
  listing,
  offset,
  anchorPrice,
  rule,
  targetComparables,
  tenantRoundingSettings,
}: {
  listing: BarkerEventListing | BarkerCoreModelsInventoryListing;
  offset: number;
  rule: Rule;
  targetComparables?: BarkerCoreModelsMarketplacesListing[] | null;
  anchorPrice?: number; // Required with offset. Add validation for this later
  tenantRoundingSettings: BarkerCoreEnumsRounding;
}) => {
  const { automationTypeId } = rule;
  const currentPrice = automationTypeId === 'SchedulePrice' && anchorPrice ? anchorPrice! : listing.unitPrice;
  const { filters } = rule;
  if (weakEquals(filters, DEFAULT_FILTERS)) {
    return currentPrice;
  }

  const { adjustmentTypeId, adjustmentValue, ceilingPrice, floorPrice, staggerByValue, staggerByTypeId } = rule;
  const _floorPrice = floorPrice || 0;
  const _ceilingPrice = ceilingPrice || Infinity;
  const targetComparablePrice = GetTargetComparablePrice(targetComparables) || 0;

  if (offset === 0 && targetComparablePrice === 0) {
    let price = currentPrice;
    if (_floorPrice > currentPrice) {
      price = _floorPrice;
    }
    if (_ceilingPrice < currentPrice) {
      price = _ceilingPrice;
    }
    return price;
  }

  if (offset > 0 && targetComparablePrice === 0) {
    let adjustedPrice = anchorPrice!;
    if (floorPrice && staggerByValue && staggerByValue > 0 && offset > 0) {
      if (staggerByTypeId === 'Amount') {
        adjustedPrice += staggerByValue * offset;
      } else if (staggerByTypeId === 'Percentage') {
        adjustedPrice += adjustedPrice * ((staggerByValue - 1) * offset);
      }
    }

    adjustedPrice = parseFloat(adjustedPrice.toFixed(2));

    if (tenantRoundingSettings && tenantRoundingSettings !== 'None') {
      if (tenantRoundingSettings === 'AlwaysDown') {
        adjustedPrice = Math.floor(adjustedPrice);
      } else if (tenantRoundingSettings === 'AlwaysUp') {
        adjustedPrice = Math.ceil(adjustedPrice);
      } else if (tenantRoundingSettings === 'Nearest') {
        adjustedPrice = Math.round(adjustedPrice);
      }
    }

    return adjustedPrice;
  }

  let adjustedPrice = targetComparablePrice;
  if (_floorPrice > targetComparablePrice) {
    adjustedPrice = _floorPrice;
  }
  if (_ceilingPrice < targetComparablePrice && offset === 0) {
    adjustedPrice = _ceilingPrice;
  }

  if (targetComparablePrice && adjustmentValue) {
    if (adjustmentTypeId === BarkerCoreEnumsAdjustmentType.Amount) {
      adjustedPrice = targetComparablePrice + adjustmentValue;
    }
    if (adjustmentTypeId === BarkerCoreEnumsAdjustmentType.Percentage) {
      adjustedPrice = targetComparablePrice * adjustmentValue;
    }
  }

  if (tenantRoundingSettings && tenantRoundingSettings !== 'None') {
    if (tenantRoundingSettings === 'AlwaysDown') {
      adjustedPrice = Math.floor(adjustedPrice);
    } else if (tenantRoundingSettings === 'AlwaysUp') {
      adjustedPrice = Math.ceil(adjustedPrice);
    } else if (tenantRoundingSettings === 'Nearest') {
      adjustedPrice = Math.round(adjustedPrice);
    }
  }

  // Add 2.25% Markup for Ticketmaster
  // 4/4/2024 No longer need markup code. May reuse it in the future.
  // if (marketplaceId === BarkerCoreEnumsMarketplace.Ticketmaster) {
  //   // Subtract a penny for floating point arithmetic issues with rounding;
  //   adjustedPrice = (adjustedPrice - 0.01) * (1 / TM_MARKUP);
  // }

  if (adjustedPrice > _ceilingPrice) {
    adjustedPrice = _ceilingPrice;
  }

  if (adjustedPrice < _floorPrice) {
    adjustedPrice = _floorPrice;
  }

  adjustedPrice = calculateStaggeredPrice({
    floorPrice: _floorPrice,
    staggerByTypeId,
    staggerByValue,
    offset,
    price: adjustedPrice,
    tenantRoundingSettings,
  });

  return parseFloat(adjustedPrice.toFixed(2));
};

export const calculateStaggeredPrice = ({
  floorPrice,
  staggerByTypeId,
  staggerByValue,
  offset,
  price,
  tenantRoundingSettings,
}: {
  floorPrice: number | null | undefined;
  staggerByTypeId: BarkerCoreEnumsAdjustmentType | null | undefined;
  staggerByValue: number | null | undefined;
  offset: number;
  price: number;
  tenantRoundingSettings: BarkerCoreEnumsRounding | null | undefined;
}) => {
  let adjustedPrice = price;
  if (floorPrice && staggerByValue && staggerByValue > 0 && offset > 0) {
    if (staggerByTypeId === 'Amount') {
      adjustedPrice += staggerByValue * offset;
    } else if (staggerByTypeId === 'Percentage') {
      adjustedPrice += adjustedPrice * ((staggerByValue - 1) * offset);
    }

    if (tenantRoundingSettings && tenantRoundingSettings !== 'None') {
      if (tenantRoundingSettings === 'AlwaysDown') {
        adjustedPrice = Math.floor(adjustedPrice);
      } else if (tenantRoundingSettings === 'AlwaysUp') {
        adjustedPrice = Math.ceil(adjustedPrice);
      } else if (tenantRoundingSettings === 'Nearest') {
        adjustedPrice = Math.round(adjustedPrice);
      }
    }
  }

  return adjustedPrice;
};

export const calculatePricerStatusId = (price: number, rule: BarkerCoreModelsPricingRule) => {
  let pricerStatusId: BarkerCoreEnumsPricerStatus = 'None';
  if (rule.isAutoPriced) {
    if (rule.floorPrice && price <= rule.floorPrice) {
      pricerStatusId = 'AtFloor';
    }
    if (rule.ceilingPrice && price >= rule.ceilingPrice) {
      pricerStatusId = 'AtCeiling';
    }
    pricerStatusId = 'AutoPriced';
  } else if (rule.automationTypeId === 'SchedulePrice') {
    if (rule.floorPrice && price <= rule.floorPrice) {
      pricerStatusId = 'AtFloor';
    }
    if (rule.ceilingPrice && price >= rule.ceilingPrice) {
      pricerStatusId = 'AtCeiling';
    }
    pricerStatusId = 'Scheduled';
  }
  return pricerStatusId;
};

export const filterMarketplaceListings = ({
  marketplaceId,
  marketListings,
  filters,
  sections,
  showOwnListings,
  showIgnoredListings,
  showOutliers,
}: {
  marketplaceId: BarkerCoreEnumsMarketplace;
  marketListings: BarkerCoreModelsMarketplacesListing[] | null | undefined;
  filters: BarkerCoreModelsPricingRuleFilters;
  sections: BarkerCoreModelsMarketplacesSeatingChartZone[] | null | undefined;
  showOwnListings: boolean;
  showIgnoredListings: boolean;
  showOutliers: boolean;
}) => {
  if (!marketListings) {
    return [];
  }

  const filtered = ApplyFilters(marketListings, filters, showOwnListings, showIgnoredListings, showOutliers).map((listing) => {
    const section = sections?.find((s) => s.sections?.map((x) => x.toLowerCase()).includes(listing.sectionId!.toLowerCase()));
    if (!section) {
      return listing;
    }

    return {
      ...listing,
      sectionAlias: marketplaceId === 'Ticketmaster' ? listing.section.toUpperCase() : undefined,
    } satisfies MarketplacesListingWithAlias;
  }) as MarketplacesListingWithAlias[];
  return filtered;
};

export const extendRuleFromSettings = (
  defaultRule: OmitStrict<Rule, 'ruleId'>,
  marketplaceId: BarkerCoreEnumsMarketplace | null | undefined,
  row: string | undefined,
  quantityRemaining: number,
  principalPricerSettings: BarkerCoreModelsAdministrativePricerSettings | undefined,
  tenantPricerSettings: BarkerCoreModelsAdministrativeTenantPricerSettings | undefined,
  options: { isAutoPriced: boolean },
) =>
  structuredClone({
    ...defaultRule,
    ruleId: typeof defaultRule === 'string' ? defaultRule : uuid(),
    isAutoPriced: options?.isAutoPriced || false,
    marketplaceId: marketplaceId || principalPricerSettings?.defaultMarketplace || DEFAULT_RULE.marketplaceId,
    numActive: principalPricerSettings?.numActive || DEFAULT_RULE.numActive,
    numComparables: principalPricerSettings?.numComparables?.[0]?.numComparables ?? DEFAULT_RULE.numComparables,
    staggerByTypeId: principalPricerSettings?.staggerByTypeId || DEFAULT_RULE.staggerByTypeId,
    adjustmentTypeId: principalPricerSettings?.adjustmentTypeId || DEFAULT_RULE.adjustmentTypeId,
    staggerByValue: principalPricerSettings?.staggerByValue || DEFAULT_RULE.staggerByValue,
    adjustmentValue: principalPricerSettings?.adjustmentValue || DEFAULT_RULE.adjustmentValue,
    autoAdjustSplits: principalPricerSettings?.autoAdjustSplits ?? DEFAULT_RULE.autoAdjustSplits,
    filters: {
      ...DEFAULT_RULE.filters,
      outlierCriteria: principalPricerSettings?.outlierCriteria,
      rows: getRowComparisonValue(row, principalPricerSettings?.rowComparisons),
      exclusions: principalPricerSettings?.exclusions || DEFAULT_RULE.filters.exclusions,
      splits: getSplitsValue(
        principalPricerSettings?.autoAdjustSplits ?? DEFAULT_RULE.autoAdjustSplits,
        defaultRule.filters.splits,
        quantityRemaining,
        row ?? '',
        principalPricerSettings?.quantitySplitMatrix,
        principalPricerSettings?.generalAdmissionSplits,
        tenantPricerSettings?.quantitySplitMatrix,
        tenantPricerSettings?.generalAdmissionSplits,
      ),
    },
  } satisfies Rule);

export const getSplitsValue = (
  auto: boolean,
  splits: number[] | null | undefined,
  quantityRemaining?: number,
  row?: string,
  principalSplitMatrix?: BarkerCoreModelsAdministrativePricerSettingsQuantitySplitMatrix,
  principalGaSplits?: number[] | null,
  tenantSplitMatrix?: BarkerCoreModelsAdministrativeTenantPricerSettingsQuantitySplitMatrix,
  tenantGaSplits?: number[] | null,
) => {
  if (auto) {
    if (isGeneralAdmission(row ?? '') && tenantGaSplits) {
      return tenantGaSplits;
    }

    if (tenantSplitMatrix) {
      return tenantSplitMatrix[Math.min(quantityRemaining ?? 0, 8)];
    }

    return [];
  }

  if (splits) {
    return splits;
  }

  if (isGeneralAdmission(row ?? '') && principalGaSplits) {
    return principalGaSplits;
  }

  if (principalSplitMatrix) {
    return principalSplitMatrix[Math.min(quantityRemaining ?? 0, 8)];
  }

  return DEFAULT_RULE.filters.splits;
};

export function isGeneralAdmission(row: string) {
  // Handles event selection where row is not defined
  if (row === '') {
    return false;
  }
  if (!isNaN(parseInt(row))) {
    return false;
  }

  if (reservedRows.has(row)) {
    return false;
  }

  if (row.toUpperCase().includes('WC')) {
    // For wheelchair rows like 1WC, 2WC, etc...
    return false;
  }

  return true;
}

export const reservedRows = new Set([
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'AA',
  'BB',
  'CC',
  'DD',
  'EE',
  'FF',
  'GG',
  'HH',
  'II',
  'JJ',
  'KK',
  'LL',
  'MM',
  'NN',
  'OO',
  'PP',
  'QQ',
  'RR',
  'SS',
  'TT',
  'UU',
  'VV',
  'WW',
  'XX',
  'YY',
  'ZZ',
  'AAA',
  'BBB',
  'CCC',
  'DDD',
  'EEE',
  'FFF',
  'GGG',
  'HHH',
  'III',
  'JJJ',
  'KKK',
  'LLL',
  'MMM',
  'NNN',
  'OOO',
  'PPP',
  'QQQ',
  'RRR',
  'SSS',
  'TTT',
  'UUU',
  'VVV',
  'WWW',
  'XXX',
  'YYY',
  'ZZZ',
]);

type OrderedRows = {
  [key: string]: number;
};

export const orderedRowsSingleBetter: OrderedRows = {
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
  F: 6,
  G: 7,
  H: 8,
  I: 9,
  J: 10,
  K: 11,
  L: 12,
  M: 13,
  N: 14,
  O: 15,
  P: 16,
  Q: 17,
  R: 18,
  S: 19,
  T: 20,
  U: 21,
  V: 22,
  W: 23,
  X: 24,
  Y: 25,
  Z: 26,
  AA: 27,
  BB: 28,
  CC: 29,
  DD: 30,
  EE: 31,
  FF: 32,
  GG: 33,
  HH: 34,
  II: 35,
  JJ: 36,
  KK: 37,
  LL: 38,
  MM: 39,
  NN: 40,
  OO: 41,
  PP: 42,
  QQ: 43,
  RR: 44,
  SS: 45,
  TT: 46,
  UU: 47,
  VV: 48,
  WW: 49,
  XX: 50,
  YY: 51,
  ZZ: 52,
};

export const orderedRowsDoubleBetter: OrderedRows = {
  AA: 1,
  BB: 2,
  CC: 3,
  DD: 4,
  EE: 5,
  FF: 6,
  GG: 7,
  HH: 8,
  II: 9,
  JJ: 10,
  KK: 11,
  LL: 12,
  MM: 13,
  NN: 14,
  OO: 15,
  PP: 16,
  QQ: 17,
  RR: 18,
  SS: 19,
  TT: 20,
  UU: 21,
  VV: 22,
  WW: 23,
  XX: 24,
  YY: 25,
  ZZ: 26,
  A: 27,
  B: 28,
  C: 29,
  D: 30,
  E: 31,
  F: 32,
  G: 33,
  H: 34,
  I: 35,
  J: 36,
  K: 37,
  L: 38,
  M: 39,
  N: 40,
  O: 41,
  P: 42,
  Q: 43,
  R: 44,
  S: 45,
  T: 46,
  U: 47,
  V: 48,
  W: 49,
  X: 50,
  Y: 51,
  Z: 52,
};
