import { Area, Bar, CartesianGrid, Cell, ComposedChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { formatCurrency } from '../../utils/formatters';
import { useCallback, useState } from 'react';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { Box, Divider, Flex, Group, Text } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import { useSales } from './Sales.hooks';
import millify from 'millify';
import LineChartIcon from '../../components/icons/LineChart';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import dayjs from 'dayjs';

export function SalesChart() {
  const [showROI, setShowROI] = useState(true);
  const [showPL, setShowPL] = useState(true);
  const { salesTrends } = useSales('salesTrends');

  const data = Object.entries(salesTrends).map(([date, { totalProfit, roiPct }]) => ({
    date,
    roiPct,
    totalProfit,
  }));

  const CustomTooltip = useCallback(
    ({ active, payload }: TooltipProps<ValueType, NameType>) => {
      if (active && payload && payload.length > 0) {
        if (typeof payload[0].value !== 'number') {
          return null;
        }

        const date = dayjs(payload[0].payload.date).toDate();
        const month = date.toLocaleString('default', { month: 'numeric' });
        const day = date.getDate();
        const label = isNaN(date.getTime()) ? payload[0].payload.date : `${month}/${day}`;

        const isLoss = payload[0].payload.totalProfit < 0;

        return (
          <div className="custom-tooltip">
            {showPL && (
              <p className="label gray" style={{ color: isLoss ? 'var(--colors-red-error)' : 'var(--colors-successButton)' }}>
                {`${formatCurrency(payload[0].payload.totalProfit)}`} P/L
              </p>
            )}
            {showROI && (
              <p className="label gray">
                {payload[0].payload.roiPct.toFixed(0)}% <span>ROI</span>
              </p>
            )}
            {payload[0].payload.date && <p className="desc">{label}</p>}
          </div>
        );
      }

      return null;
    },
    [showPL, showROI],
  );
  if (Object.keys(salesTrends).length === 0) {
    return <BNEmptyState border={false} title="No Sales Data" description="Check back later" icon={<LineChartIcon size={28} />} />;
  }

  return (
    <>
      <Text c="var(--colors-gray-5)" fw={500} tt="uppercase" fz={11} lts={0.25} pos="absolute" top={10} left={12}>
        Sales Performance
      </Text>
      <Box pos="relative" h="100%" w="100%" pt={36} pb="xs">
        <Flex pos="absolute" top={7} left={0} right={10} align="center" justify="end">
          <BNButton size="compact-xs" style={{ opacity: showROI ? 1 : 0.5 }} variant="white" onClick={() => setShowROI(!showROI)}>
            <Box>
              <Divider w={16} size={1} color="var(--colors-gray-4)" />
            </Box>
            <Text ml={8} fz={10} fw={600} c="inherit">
              ROI %
            </Text>
          </BNButton>
          <BNButton size="compact-xs" style={{ opacity: showPL ? 1 : 0.5 }} variant="white" onClick={() => setShowPL(!showPL)}>
            <Group gap={1.5}>
              <Divider mt={4} orientation="vertical" h={8} size={2} color="var(--colors-gray-4)" />
              <Divider orientation="vertical" h={12} size={2} color="var(--colors-gray-4)" />
              <Divider mt={4} orientation="vertical" h={8} size={2} color="var(--colors-gray-4)" />
            </Group>
            <Text fz={10} ml={8} fw={600} c="inherit">
              P/L
            </Text>
          </BNButton>
        </Flex>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart width={400} height={316} data={data}>
            <defs>
              <linearGradient id="linearGradientSales" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0" stopColor="var(--colors-gray-2)" stopOpacity={1} />
                <stop offset="100%" stopColor="var(--colors-paper)" stopOpacity={1} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="0" stroke="var(--colors-gray-0" vertical={false} />
            <XAxis
              minTickGap={48}
              dataKey="date"
              orientation="bottom"
              strokeWidth={0}
              style={{ fontSize: 8, opacity: 0.65 }}
              height={16}
              tickFormatter={(value) => {
                const date = dayjs(value).toDate();
                const month = date.toLocaleString('default', { month: 'numeric' });
                const day = date.getDate();
                return isNaN(date.getTime()) ? value : `${month}/${day}`;
              }}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              dataKey="roiPct"
              yAxisId="right"
              orientation="right"
              type="number"
              width={32}
              fontSize={8}
              style={{ fontSize: 8, opacity: showROI ? 0.65 : 0 }}
              tickFormatter={(value) => {
                if (typeof value !== 'number') {
                  return value;
                }
                return `${value.toFixed(0)}%`;
              }}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              dataKey="totalProfit"
              yAxisId="left"
              orientation="left"
              type="number"
              width={32}
              fontSize={8}
              style={{ fontSize: 8, opacity: showPL ? 0.65 : 0 }}
              tickFormatter={(value) => {
                if (typeof value !== 'number') {
                  return value;
                }

                return `$${millify(value, { precision: 0 })}`;
              }}
              // Set domain here for min & max value, we may have to calculate the values manually to center it at 0
              // domain={[-8000, 8000]}
            />
            <Area
              opacity={showROI ? 1 : 0}
              activeDot={showROI}
              yAxisId="right"
              type="monotone"
              dataKey="roiPct"
              strokeWidth={1}
              stroke="var(--colors-gray-2)"
              fill="url(#linearGradientSales)"
            />
            <Bar opacity={showPL ? 1 : 0} yAxisId="left" dataKey="totalProfit">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.totalProfit > 0 ? 'var(--colors-successButton)' : 'var(--colors-red-error)'} />
              ))}
            </Bar>
            <Tooltip content={<CustomTooltip />} wrapperStyle={{ outline: 'none' }} />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
}
