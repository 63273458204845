import { dti } from '../../data/atoms.dti';
import { useAtom } from 'jotai/index';
import { SellPrivatelyDialog } from './SellPrivately/SellPrivatelyDialog';
import { EditPropertiesDialog } from './EditProperties/EditPropertiesDialog';
import { AddPdfsDialog } from './Attachments/AddPDFs';

export function DTIModals() {
  const [currentModal, setCurrentModal] = useAtom(dti.currentModalAtom);

  switch (currentModal) {
    case DtiModals.SellPrivately:
      return <SellPrivatelyDialog opened={currentModal === DtiModals.SellPrivately} onClose={() => setCurrentModal(null)} />;
    case DtiModals.EditProperties:
      return <EditPropertiesDialog opened={currentModal === DtiModals.EditProperties} onClose={() => setCurrentModal(null)} />;
    case DtiModals.AddPdfs:
      return <AddPdfsDialog opened={currentModal === DtiModals.AddPdfs} onClose={() => setCurrentModal(null)} />;
    default:
      return null;
  }
}

export enum DtiModals {
  SellPrivately = 'SellPrivately',
  EditProperties = 'EditProperties',
  AddPdfs = 'AddPfs',
}
