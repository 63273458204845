export type BarkerCoreEnumsPointOfSale = (typeof BarkerCoreEnumsPointOfSale)[keyof typeof BarkerCoreEnumsPointOfSale];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BarkerCoreEnumsPointOfSale = {
  Unknown: 'Unknown',
  Ephemera: 'Ephemera',
  SkyBox: 'SkyBox',
  PosNext: 'PosNext',
  TicketUtils: 'TicketUtils',
  TradeDeskPos: 'TradeDeskPos',
  DtiPortal: 'DtiPortal',
  TicketNetwork: 'TicketNetwork',
  AnyTickets: 'AnyTickets',
  EIBO: 'EIBO',
} as const;
