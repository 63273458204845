import classes from './DTILogo.module.css';

interface LogoProps {
  size?: number;
}

export default function DTILogo({ size = 48 }: LogoProps) {
  return (
    <svg className={classes.dtiLogo} height={size} width={size} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1520_286" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="60" height="60">
        <path d="M60 0H0V60H60V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1520_286)">
        <path
          d="M32.1165 0L29.3188 20.7893L33.1711 20.8252L34.491 10.4879L43.1281 10.5381L41.801 20.8969L44.9144 20.9256L44.6131 23.7951H47.626L47.3104 26.5282L53.6806 26.5641L51.0622 45.1941L42.4251 45.1439L44.735 28.6875L40.811 28.6516L38.7091 45.0937L30.072 45.0435L32.1739 28.5799L28.2571 28.544L28.214 28.8382H28.1997L25.983 45.2372L17.3531 45.1869H12.8624C8.7519 45.1869 5.4089 41.844 5.4089 37.7335V27.5899C5.4089 23.4794 8.7519 20.1365 12.8624 20.1365H20.0863C20.3087 20.1365 20.531 20.1508 20.7463 20.1724L23.4722 0H0V60.2875H20.9543C21.0547 55.4238 25.0218 51.507 29.9142 51.507C34.8066 51.507 38.7737 55.4238 38.8741 60.2875H60.2947V0H32.1237H32.1165ZM53.6949 20.861H48.1066L48.5801 16.3847H54.1684L53.6949 20.861Z"
          fill="var(--colors-markBg)"
        />
        <path
          d="M19.7061 26.9219H12.8696C12.5038 26.9219 12.1953 27.2303 12.1953 27.5962V37.7397C12.1953 38.0697 12.4464 38.3495 12.762 38.4069H18.1566L19.7061 26.9219Z"
          fill="var(--colors-markBg)"
        />
      </g>
    </svg>
  );
}
