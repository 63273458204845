import yasml from '@thirtytech/yasml';
import { useAtom, useAtomValue } from 'jotai';
import { selectedEventAtom, showEventPerformanceAtom } from '../../data/atoms';
import {
  BarkerCoreEnumsMarketplace,
  getGetApiInventoryEventsEventIdListingsQueryKey,
  getGetApiInventoryEventsEventIdMappingsQueryKey,
  getGetApiInventoryEventsEventIdSnapshotsQueryKey,
  getGetApiSalesQueryKey,
  useGetApiInventoryEventsEventIdListings,
  useGetApiInventoryEventsEventIdMappings,
  useGetApiInventoryEventsEventIdSnapshots,
  useGetApiMarketplacesMarketplaceIdEventsEventIdSnapshots,
  useGetApiSales,
} from '../../api';
import dayjs from 'dayjs';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import { getStandardMarketplaceName } from '../../utils/marketplace-utils';
import { useCallback, useState } from 'react';

function EventPerformanceState() {
  const [showEmptyStates, showEmptyStatesHandlers] = useDisclosure(false);
  const [showLoadingStates, showLoadingStatesHandlers] = useDisclosure(false);
  const [showErrorStates, showErrorStatesHandlers] = useDisclosure(false);
  const [performanceSnapshotPeriod, setPerformanceSnapshotPeriod] = useLocalStorage({
    key: 'setting-snapshotPeriodPerformance',
    defaultValue: '3m',
    getInitialValueInEffect: false,
  });

  const [showEventPerformance, setShowEventPerformance] = useAtom(showEventPerformanceAtom);

  const selectedEvent = useAtomValue(selectedEventAtom);

  const {
    data: listingsSold,
    isLoading: isListingsSoldLoading,
    refetch: refetchListingsSold,
  } = useGetApiSales(
    {
      eventId: selectedEvent?.eventId ?? '',
      fromDate: dayjs().subtract(2, 'year').startOf('day').toDate(),
      toDate: dayjs().endOf('day').endOf('day').toDate(),
    },
    {
      query: {
        enabled: !!selectedEvent?.eventId && showEventPerformance,
        select(data) {
          // HACK. The server is not returning all the sale data. So we are just spreading sale over the missing sale data
          // Need to figure out why the server is not returning all the sale data. Likely perf optimization of redundant data.
          // Sale will be missing listingsSold property.
          return data.data.sales.flatMap((sale) => sale.listings.map((listing) => ({ ...listing, event: selectedEvent, sale: listing.sale ?? { ...sale } })));
        },
        queryKey: [
          ...getGetApiSalesQueryKey({
            eventId: selectedEvent?.eventId ?? '',
            fromDate: dayjs().subtract(2, 'year').startOf('day').toDate(),
            toDate: dayjs().endOf('day').endOf('day').toDate(),
          }),
          selectedEvent?.tenantId,
        ],
      },
      axios: {
        headers: {
          'x-tenant-id': selectedEvent?.tenantId,
        },
      },
    },
  );

  const {
    data: listingsOpen,
    isLoading: isListingsOpenLoading,
    refetch: refetchListingsOpen,
  } = useGetApiInventoryEventsEventIdListings(selectedEvent?.eventId ?? '', {
    query: {
      enabled: !!selectedEvent?.eventId && showEventPerformance,
      select(data) {
        return data.data;
      },
      queryKey: [...getGetApiInventoryEventsEventIdListingsQueryKey(selectedEvent?.eventId ?? ''), selectedEvent?.tenantId],
    },
    axios: {
      headers: {
        'x-tenant-id': selectedEvent?.tenantId,
      },
    },
  });

  const {
    data: inventorySnapshots,
    isLoading: isInventorySnapshotsLoading,
    refetch: refetchInventorySnapshots,
  } = useGetApiInventoryEventsEventIdSnapshots(selectedEvent?.eventId ?? '', {
    query: {
      enabled: !!selectedEvent?.eventId && showEventPerformance,
      select(data) {
        return data.data;
      },
      queryKey: [...getGetApiInventoryEventsEventIdSnapshotsQueryKey(selectedEvent?.eventId ?? ''), selectedEvent?.tenantId],
    },
    axios: {
      headers: {
        'x-tenant-id': selectedEvent?.tenantId,
      },
    },
  });

  const { data: eventMappings } = useGetApiInventoryEventsEventIdMappings(selectedEvent?.eventId ?? '', {
    query: {
      enabled: !!selectedEvent?.eventId && showEventPerformance,
      select(data) {
        return data.data;
      },
      queryKey: [...getGetApiInventoryEventsEventIdMappingsQueryKey(selectedEvent?.eventId ?? ''), selectedEvent?.tenantId],
    },
    axios: {
      headers: {
        'x-tenant-id': selectedEvent?.tenantId,
      },
    },
  });

  const seatGeekEventId = eventMappings?.find((mapping) => mapping.marketplaceId === BarkerCoreEnumsMarketplace.SeatGeek)?.marketplaceEventId;

  const {
    data: seatGeekSnapshots,
    isLoading: isSeatGeekSnapshotsLoading,
    refetch: refetchSeatGeekSnapshots,
  } = useGetApiMarketplacesMarketplaceIdEventsEventIdSnapshots(BarkerCoreEnumsMarketplace.SeatGeek, seatGeekEventId ?? '', {
    query: {
      enabled: !!seatGeekEventId && showEventPerformance,
      select(data) {
        return data.data;
      },
    },
    axios: {
      headers: {
        'x-tenant-id': selectedEvent?.tenantId,
      },
    },
  });

  const [isRefreshing, setIsRefreshing] = useState(false);

  const refresh = useCallback(async () => {
    setIsRefreshing(true);
    await refetchListingsSold();
    await refetchListingsOpen();
    await refetchInventorySnapshots();
    await refetchSeatGeekSnapshots();
    setIsRefreshing(false);
  }, [refetchListingsSold, refetchListingsOpen, refetchInventorySnapshots, refetchSeatGeekSnapshots, setIsRefreshing]);

  const marketplaceBreakdown = listingsSold?.reduce(
    (acc, listing) => {
      const marketplaceName = getStandardMarketplaceName(listing.sale?.marketplaceName ?? 'Other');
      const marketplaceTotal = acc[marketplaceName] ?? 0;
      acc[marketplaceName] = marketplaceTotal + listing.quantity;
      return acc;
    },
    {} as Record<string, number>,
  );

  const soldCost =
    listingsSold?.reduce((acc, listing) => {
      const cost = (listing.unitCost ?? 0) * listing.quantity;
      return acc + cost;
    }, 0) ?? 0;

  const openCost =
    listingsOpen?.reduce((acc, listing) => {
      const cost = (listing.unitCost ?? 0) * listing.quantityRemaining;
      return acc + cost;
    }, 0) ?? 0;

  const totalCost = soldCost && openCost ? soldCost + openCost : 0;

  const soldRevenue =
    listingsSold?.reduce((acc, listing) => {
      const revenue = (listing.unitPrice ?? 0) * listing.quantity;
      return acc + revenue;
    }, 0) ?? 0;

  const soldProfit = soldRevenue - soldCost;

  const soldReturnOnInvestment = (soldProfit / soldCost) * 100;

  const openTickets = listingsOpen?.reduce((acc, listing) => acc + listing.quantityRemaining, 0);

  const openTicketsBroadcasting = listingsOpen?.reduce((acc, listing) => acc + (listing.isBroadcasting ? listing.quantityRemaining : 0), 0);

  const soldTickets = listingsSold?.reduce((acc, listing) => acc + listing.quantity, 0);

  const soldTicketsLast24Hours = listingsSold?.reduce((acc, listing) => {
    const saleDate = dayjs(listing.sale?.createdAt);
    const isWithinLast24Hours = saleDate.isAfter(dayjs().subtract(24, 'hours'));
    return acc + (isWithinLast24Hours ? listing.quantity : 0);
  }, 0);

  const soldTicketsLast7Days = listingsSold?.reduce((acc, listing) => {
    const saleDate = dayjs(listing.sale?.createdAt);
    const isWithinLast7Days = saleDate.isAfter(dayjs().subtract(7, 'days'));
    return acc + (isWithinLast7Days ? listing.quantity : 0);
  }, 0);

  const soldTicketsLast30Days = listingsSold?.reduce((acc, listing) => {
    const saleDate = dayjs(listing.sale?.createdAt);
    const isWithinLast30Days = saleDate.isAfter(dayjs().subtract(30, 'days'));
    return acc + (isWithinLast30Days ? listing.quantity : 0);
  }, 0);

  const unrealizedRevenue =
    listingsOpen?.reduce((acc, listing) => {
      const revenue = (listing.unitPrice ?? 0) * listing.quantityRemaining;
      return acc + revenue;
    }, 0) ?? 0;

  const unrealizedProfit = unrealizedRevenue - openCost;

  const projectedProfit = soldProfit + unrealizedProfit;

  const projectedReturnOnInvestment = (projectedProfit / totalCost) * 100;

  const performanceTrends = inventorySnapshots
    ?.filter(
      (snapshot) =>
        (performanceSnapshotPeriod === '1d' && dayjs(snapshot.createdAt).isAfter(dayjs().subtract(1, 'day'))) ||
        (performanceSnapshotPeriod === '1w' && dayjs(snapshot.createdAt).isAfter(dayjs().subtract(1, 'week'))) ||
        (performanceSnapshotPeriod === '1m' && dayjs(snapshot.createdAt).isAfter(dayjs().subtract(1, 'month'))) ||
        (performanceSnapshotPeriod === '3m' && dayjs(snapshot.createdAt).isAfter(dayjs().subtract(3, 'month'))) ||
        (performanceSnapshotPeriod === '1y' && dayjs(snapshot.createdAt).isAfter(dayjs().subtract(1, 'year'))) ||
        performanceSnapshotPeriod === 'all',
    )
    .map((item) => {
      const date = dayjs(item.createdAt);
      const dateString = date.format('M/D');
      const dailyOpenTickets = item.openTickets;
      const dailyOpenCost = item.openCost;
      const dailyUnrealizedRevenue = item.openPrice;
      let dailySoldTickets = 0;
      let dailySoldCost = 0;
      let dailySoldRevenue = 0;

      if (listingsSold) {
        const soldListings = listingsSold.filter((listing) => dayjs(listing.sale?.createdAt).isBefore(date));
        soldListings.forEach((listing) => {
          dailySoldTickets += listing.quantity;
          dailySoldCost += (listing.unitCost ?? 0) * listing.quantity;
          dailySoldRevenue += (listing.unitPrice ?? 0) * listing.quantity;
        });
      }

      const totalTickets = dailyOpenTickets + dailySoldTickets;
      const currentRoi = soldRevenue && soldCost ? ((dailySoldRevenue - dailySoldCost) / dailySoldCost) * 100 : 0;
      const unrealizedRoi = dailyUnrealizedRevenue && dailyOpenCost ? ((dailyUnrealizedRevenue - dailyOpenCost) / dailyOpenCost) * 100 : 0;
      let potentialRoi = ((dailySoldRevenue + dailyUnrealizedRevenue - (dailySoldCost + dailyOpenCost)) / (dailySoldCost + dailyOpenCost)) * 100;

      if (isNaN(potentialRoi)) {
        potentialRoi = 0;
      }

      const currentRoiPlot = currentRoi > 300 ? 300 : currentRoi;
      const unrealizedRoiPlot = unrealizedRoi > 300 ? 300 : unrealizedRoi;
      const potentialRoiPlot = potentialRoi > 300 ? 300 : potentialRoi;

      return {
        date: dateString,
        currentRoi,
        unrealizedRoi,
        potentialRoi,
        openTickets: dailyOpenTickets,
        soldTickets: dailySoldTickets,
        totalTickets,
        currentRoiPlot,
        unrealizedRoiPlot,
        potentialRoiPlot,
        roi4: undefined,
        roi5: undefined,
        roi6: undefined,
        amt4: undefined,
        amt5: undefined,
        amt6: undefined,
      };
    });

  return {
    showEmptyStates,
    showEmptyStatesHandlers,
    showLoadingStates,
    showLoadingStatesHandlers,
    showErrorStates,
    showErrorStatesHandlers,
    selectedEvent,
    showEventPerformance,
    setShowEventPerformance,
    listingsSold,
    isListingsSoldLoading: isListingsSoldLoading || showLoadingStates,
    isListingsOpenLoading: isListingsOpenLoading || showLoadingStates,
    performanceSnapshotPeriod,
    setPerformanceSnapshotPeriod,
    performanceTrends,
    isPerformanceTrendsLoading: isInventorySnapshotsLoading || showLoadingStates,
    seatGeekSnapshots,
    isSeatGeekSnapshotsLoading: (isSeatGeekSnapshotsLoading && !!seatGeekEventId) || showLoadingStates,
    marketplaceBreakdown,
    soldCost,
    openCost,
    totalCost,
    soldRevenue,
    soldProfit,
    soldReturnOnInvestment,
    openTickets,
    openTicketsBroadcasting,
    soldTickets,
    soldTicketsLast24Hours,
    soldTicketsLast7Days,
    soldTicketsLast30Days,
    unrealizedRevenue,
    unrealizedProfit,
    projectedProfit,
    projectedReturnOnInvestment,
    refresh,
    isRefreshing,
  };
}

export const { Provider: EventPerformanceProvider, useSelector: useEventPerformance } = yasml(EventPerformanceState);
