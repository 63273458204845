import { BarkerCoreEnumsPointOfSale, getApiInventoryStatus } from '../../api';
import { useAtomValue } from 'jotai';
import { auth } from '../../data/atoms.auth';
import { Collapse, Group, Paper, Text } from '@mantine/core';
import WarningIcon from '../../components/icons/Warning';
import { useGlobalState } from '../../data/GlobalState';
import { useQueries } from '@tanstack/react-query';
import { supportEmail } from '../../utils/whitelabel-consts';

export function IntegrationProblemBanner() {
  const apiToken = useAtomValue(auth.apiTokenAtom);
  const { selectedTenantIds, tenants } = useGlobalState('selectedTenantIds', 'tenants');

  const tenantStatuses = useQueries({
    queries: selectedTenantIds.map((tenantId) => ({
      queryKey: ['inventoryStatus', tenantId],
      queryFn: () =>
        getApiInventoryStatus({
          headers: {
            'x-tenant-id': tenantId,
          },
        }),
      enabled: !!apiToken,
      refetchOnWindowFocus: true,
    })),
  });

  const tenantIdWithError = tenantStatuses.find((result) => result?.data?.data?.toUpperCase() === 'DOWN')?.data?.config?.headers.get('x-tenant-id');

  const tenantWithError = tenants?.find((tenant) => tenant.tenantId === tenantIdWithError);

  if (!tenantWithError) {
    return null;
  }

  // For DTI tenants, we want to show the proper support email members@dtimanagement.com, otherwise we want to forcibly list support@brokernerds.com because DTI doesn't handle support for non-DTI tenants
  const posSupportEmail = tenantWithError?.pointOfSaleId === BarkerCoreEnumsPointOfSale.DtiPortal ? supportEmail : 'support@brokernerds.com';

  const errorMessage =
    tenants && tenants.length > 1
      ? `We are experiencing issues communicating with the point of sale for ${tenantWithError.name}.`
      : 'We are experiencing issues communicating your point of sale.';

  return (
    <Collapse in w="100%" ta="center">
      <Paper w="100%" bg="var(--colors-red-error)" c="rgba(255,255,255,1)">
        <Group mih={36} align="center" justify="center" gap="xs">
          <WarningIcon size={20} color="rgba(255,255,255,1)" />
          <Text size="xs" c="rgba(255,255,255,1)">
            {errorMessage} Please contact{' '}
            <Text component="a" href={`mailto:${posSupportEmail}`} td="underline">
              {posSupportEmail}
            </Text>{' '}
            for assistance.
          </Text>
        </Group>
      </Paper>
    </Collapse>
  );
}
