import { IconPropsBase } from './IconPropsBase';

export default function AddIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path fill={color} d="M11.25 18.75v-6h-6v-1.5h6v-6h1.5v6h6v1.5h-6v6Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path fill={color} d="M9.458 14.792v-4.25h-4.25V9.458h4.25v-4.25h1.084v4.25h4.25v1.084h-4.25v4.25Z" />
      </svg>
    );
  }
}
