import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { formatCurrency, formatDate, formatMarketplaceSection } from '../../utils/formatters';
import { DateFormats } from '../../utils/globals';
import classes from '../MarketListings/MarketListingsDelivery.styles.tsx.module.css';
import { Box, Center, Group, Text, Tooltip } from '@mantine/core';
import ConfirmationNumberIcon from '../../components/icons/ConfirmationNumber';
import { reformatDateToISO } from '../../utils/date-utils';

dayjs.extend(utc);

export function SalesCard({ saleDate, quantity, section, row, price }: { saleDate: string; quantity: number; section: string; row: string; price: number }) {
  const utcDate = dayjs.utc(reformatDateToISO(saleDate));
  const dateTime = utcDate.local();

  const friendlyTime = dateTime.isBefore(dayjs().startOf('day'))
    ? formatDate(dateTime.toDate(), DateFormats.GridShort)
    : formatDate(dateTime.toDate(), DateFormats.GridTimeOnly).toLocaleLowerCase();
  const fullTime = formatDate(dateTime.toDate(), DateFormats.GridFull);

  return (
    <Group wrap="nowrap" py={6} px={12} className={`market-listing-card ${classes.card}`} c="inherit">
      <Group wrap="nowrap" gap="xs" className={`${classes.sectionText} section-text section-text-small`} justify="space-between" pos="relative">
        <Box miw={0}>
          <Text truncate size="xs" lh={1.4}>
            {formatMarketplaceSection(section)}
          </Text>
          <Text truncate color="gray.5" size="xs" lh={1.4}>
            Row {row === '' ? 'GA' : row.toUpperCase()}
          </Text>
        </Box>
      </Group>
      <Group className={classes.qtySection} wrap="nowrap" gap={0}>
        <Box className={classes.qtyText}>
          <Text size="xs">{quantity}</Text>
        </Box>
        <Box className={classes.qtyIcon}>
          <Center h={18} w={18} mx={-1}>
            <ConfirmationNumberIcon color="var(--colors-gray-5)" size={18} />
          </Center>
        </Box>
        <Box className={classes.divider}>
          <Box className={classes.dividerLine} />
        </Box>
      </Group>
      <Box className={classes.price} c="inherit">
        <Text size="xs" truncate>
          {formatCurrency(price)}
        </Text>
      </Box>
      <Group className={`${classes.listingAgeContainer} listing-age-container`} wrap="nowrap">
        <Box className={classes.firstSeenDivider}>
          <Box className={classes.dividerLine} />
        </Box>
        <Tooltip label={fullTime} withArrow>
          <Text size="xs" c="var(--colors-gray-4)" ta="center" w="7ch">
            {friendlyTime}
          </Text>
        </Tooltip>
      </Group>
    </Group>
  );
}
