import { Divider, Paper } from '@mantine/core';
import Grid from './Inventory.Grid';
import { InventoryStateProvider, useInventory } from './Inventory.hooks';
import classes from './Inventory.styles.tsx.module.css';
import { InventoryHeaderStateProvider } from './Inventory.Header.hooks';
import EventsGrid from './Inventory.EventsGrid';
import { InventoryEventStateProvider } from './Inventory.Events.hooks';
import { ListingSortBar } from './Inventory.Header.ListingSortBar';
import { EventSortBar } from './Inventory.Header.EventSortBar';
import MultiSelectActionBar from './Inventory.MultiSelectActionBar';
import { PricerView } from '../../types';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import { useLocalStorage } from '@mantine/hooks';
import { UnsavedChangesPopover } from './Inventory.UnsavedChangesPopover';
import { PurchaseModal } from '../../pages/Purchase';
import { Header } from './Inventory.Header';
import { ListingHistoryDialog, ListingHistoryDialogDTI } from './Inventory.ListingHistoryDialog';
import { isDtiHosted } from '../../utils/whitelabel-consts';
import { useGlobalState } from '../../data/GlobalState';
import { BarkerCoreEnumsPricingMode } from '../../api';

export function Inventory() {
  return (
    <Paper className={classes.inventoryWrapper}>
      <InventoryStateProvider>
        <InventoryInterior />
      </InventoryStateProvider>
    </Paper>
  );
}

function InventoryInterior() {
  const { pricerView, pricerMode } = useGlobalState('pricerView', 'pricerMode');
  const [splitViewPositions, setSplitViewPositions] = useLocalStorage<number[]>({
    key: 'setting-splitview-positions',
    defaultValue: [],
    getInitialValueInEffect: false,
  });
  const {
    selectedHistoryListing: { listing, isOpen },
    setSelectedHistoryListing: setListing,
  } = useInventory('selectedHistoryListing', 'setSelectedHistoryListing');

  return (
    <>
      <InventoryHeaderStateProvider>
        <Header />
        <Allotment vertical minSize={250} defaultSizes={splitViewPositions} onChange={(change) => setSplitViewPositions(change)} separator={false}>
          {pricerView === PricerView.Split && (
            <Allotment.Pane minSize={120} preferredSize="40%">
              <InventoryEventStateProvider>
                <EventSortBar />
                <EventsGrid />
                <Divider color="var(--colors-gray-2)" />
                {pricerMode === BarkerCoreEnumsPricingMode.Staged && pricerView === PricerView.Split && <UnsavedChangesPopover position="bottom" />}
              </InventoryEventStateProvider>
            </Allotment.Pane>
          )}
          <Allotment.Pane preferredSize="60%">
            <MultiSelectActionBar />
            <ListingSortBar hideEventSort={pricerView === PricerView.Split} />
            <Grid />
            {pricerMode === BarkerCoreEnumsPricingMode.Staged && pricerView === PricerView.Standard && <UnsavedChangesPopover position="bottom" />}
          </Allotment.Pane>
        </Allotment>
      </InventoryHeaderStateProvider>
      {isDtiHosted ? (
        <ListingHistoryDialogDTI listing={listing} isOpen={isOpen} setListing={setListing} />
      ) : (
        <ListingHistoryDialog listing={listing} isOpen={isOpen} setListing={setListing} />
      )}
      <PurchaseModal />
    </>
  );
}
