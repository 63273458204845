import { Popover, Divider, Group, SimpleGrid, Box, Text, Stack } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import EditIcon from '../../components/icons/Edit';
import SearchIcon from '../../components/icons/Search';
import { BNSwitch } from '../../components/Switch/Switch';
import { BNTextInput } from '../../components/TextInput/TextInput';
import classes from './Purchase.module.css';
import { usePurchaseState } from './Purchase.hooks';
import dayjs from 'dayjs';
import { DateFormats } from '../../utils/globals';
import { useState } from 'react';
import { useDidUpdate, useDisclosure } from '@mantine/hooks';

export function EventsSelector({ selectedEventIds, groupIndex }: { selectedEventIds: number[]; groupIndex: number }) {
  const { selectedEvents, form } = usePurchaseState('selectedEvents', 'form');
  const [search, setSearch] = useState('');
  const [isOpen, { close: closeIsOpen, toggle: toggleIsOpen }] = useDisclosure(false);
  const [initialSelectedEventIds, setInitialSelectedEvents] = useState(form.values.groups[groupIndex].eventIds);

  useDidUpdate(() => {
    if (isOpen) {
      setInitialSelectedEvents(form.values.groups[groupIndex].eventIds);
    }
  }, [isOpen]);

  return (
    <Popover position="left" withArrow opened={isOpen} onClose={() => closeIsOpen()}>
      <Popover.Target>
        <BNButton className={classes.eventGroupHeaderButton} leftSection={<EditIcon size={16} />} onClick={() => toggleIsOpen()} variant="subtle" size="compact-xs" fw="500" fz={11}>
          Edit Events
        </BNButton>
      </Popover.Target>
      <Popover.Dropdown w={460}>
        <Stack gap={12}>
          <Box>
            <BNTextInput
              clearable
              clearableOnClick={() => setSearch('')}
              leftSection={<SearchIcon />}
              placeholder="Filter Events"
              size="xs"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </Box>
          <Divider mx={-16} my={0} color="var(--colors-borderDivider)" />
          {/* Repeatable Event List Item */}
          {Array.from(new Set(selectedEventIds.concat(selectedEvents.map((x) => x.id))))
            .sort((a, b) => b - a)
            .map((eventId) => (
              <EventListItem
                key={eventId}
                eventId={eventId}
                search={search}
                disabled={selectedEventIds.length === 1 && selectedEventIds.includes(eventId)}
                checked={selectedEventIds.includes(eventId)}
                onClick={(_eventId) => {
                  const index = selectedEventIds.indexOf(_eventId);
                  if (index === -1) {
                    form.setFieldValue(`groups.${groupIndex}.eventIds`, [...selectedEventIds, _eventId]);
                  } else {
                    form.setFieldValue(
                      `groups.${groupIndex}.eventIds`,
                      selectedEventIds.filter((x) => x !== _eventId),
                    );
                  }
                }}
              />
            ))}
          <Divider mx={-16} my={0} color="var(--colors-borderDivider)" />
          <SimpleGrid cols={2}>
            <BNButton
              size="xs"
              onClick={() => {
                form.setFieldValue(`groups.${groupIndex}.eventIds`, initialSelectedEventIds);
              }}
            >
              Reset All
            </BNButton>
            <BNButton size="xs" variant="filled" onClick={() => closeIsOpen()}>
              Done
            </BNButton>
          </SimpleGrid>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
}

function EventListItem({
  eventId,
  checked,
  disabled,
  onClick,
  search,
}: {
  eventId: number;
  search: string;
  checked: boolean;
  disabled?: boolean;
  onClick: (eventId: number) => void;
}) {
  const { orderEvents } = usePurchaseState('orderEvents');
  const event = orderEvents.find((e) => e.id === eventId);
  if (!event) {
    return null;
  }
  if (search !== '' && event?.event_name.toLowerCase().indexOf(search.toLowerCase()) === -1) {
    return null;
  }

  return (
    <Box mx={-16} my={-12} mah={200} className={classes.eventToggleList} style={{ overflowY: 'auto' }}>
      <Group justify="space-between" p="xs">
        <Box flex={1} style={{ overflow: 'hidden' }}>
          <Text fw={600} truncate>
            {event.event_name}
          </Text>
          <Text c="var(--colors-gray-5)" truncate>
            {dayjs(event.event_date_time).format(DateFormats.Standard)} - {event.venue_name}
            {/* Mon Jul 29, 2024 at 3:05pm - Camden Yards, Baltimore, MD */}
          </Text>
        </Box>
        <BNSwitch size="sm" checked={checked} disabled={disabled} onClick={() => onClick(eventId)} />
      </Group>
    </Box>
  );
}
