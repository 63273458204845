import { Box, Loader } from '@mantine/core';
import classes from './SeatingChart.module.css';

export function CustomLoadingOverlay() {
  return (
    <Box className={classes.loadingWrapper}>
      <Loader color="var(--colors-gray-5)" type="dots" />
    </Box>
  );
}
