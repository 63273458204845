import { IconPropsBase } from './IconPropsBase';

export default function SunnyIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M450.001-751.538v-147.691h59.998v147.691h-59.998Zm242.921 100.769-41.153-41.153L754.922-798.46l42.538 43.153-104.538 104.538Zm58.616 200.768v-59.998h147.691v59.998H751.538ZM450.001-60.771v-147.307h59.998v147.307h-59.998ZM267.847-652.385 161.54-754.922l43.538-42.153 104.537 104.153-41.768 40.537Zm486.46 490.845L651.769-268.078l40.538-40.153 104.768 101.923-42.768 44.768ZM60.771-450.001v-59.998h147.691v59.998H60.771ZM204.693-161.54l-41.768-43.538 103.153-103.153 21.692 20.461 22.076 21.077L204.693-161.54Zm275.398-98.461q-91.629 0-155.859-64.14-64.231-64.14-64.231-155.768 0-91.629 64.14-155.859 64.14-64.231 155.768-64.231 91.629 0 155.859 64.14 64.231 64.14 64.231 155.768 0 91.629-64.14 155.859-64.14 64.231-155.768 64.231ZM480-320q66 0 113-47t47-113q0-66-47-113t-113-47q-66 0-113 47t-47 113q0 66 47 113t113 47Zm0-160Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M454.001-759.538v-131.691h51.998v131.691h-51.998Zm241.921 102.769-40.153-38.153 95.153-93.538 38.538 36.153-93.538 95.538Zm63.616 202.768v-51.998h131.691v51.998H759.538ZM454.001-68.771v-131.307h51.998v131.307h-51.998ZM264.847-658.385l-96.307-93.537 40.538-36.153 92.537 92.153-36.768 37.537Zm487.46 489.845-92.538-96.538 36.538-36.153 94.768 91.923-38.768 40.768ZM68.771-454.001v-51.998h131.691v51.998H68.771ZM207.693-168.54l-36.768-39.538 93.153-91.153 17.692 17.461 17.076 18.077-91.153 95.153Zm272.398-91.461q-91.629 0-155.859-64.14-64.231-64.14-64.231-155.768 0-91.629 64.14-155.859 64.14-64.231 155.768-64.231 91.629 0 155.859 64.14 64.231 64.14 64.231 155.768 0 91.629-64.14 155.859-64.14 64.231-155.768 64.231ZM480-312q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Zm0-168Z"
        />
      </svg>
    );
  }
}
