import React, { useState } from 'react';
import { ActionIcon, Box, Divider, Flex, Group, Popover, Text, Tooltip } from '@mantine/core';
import { BNTextInput } from '../../components/TextInput/TextInput';
import CloseIcon from '../../components/icons/Close';
import ArrowForwardIcon from '../../components/icons/ArrowForward';
import { useEventComments } from './EventComments.hooks';
import { CommentsList } from './EventComments.CommentsList';
import { BNButton } from '../../components/Button/Button';
import ForumIcon from '../../components/icons/Forum';
import classes from './EventComments.module.css';
import AddComment from '../../components/icons/AddComment';
import { BNLoadingState } from '../../components/Loading/LoadingState';
import pluralize from 'pluralize';

interface EventCommentsProps {
  commentsOpen: boolean;
  closeComments: () => void;
  numComments: number;
  openComments: () => void;
}

interface AddCommentsInputProps {
  addComment: (commentToAdd: string) => void;
}

function AddCommentsInput({ addComment }: AddCommentsInputProps) {
  const [newComment, setNewComment] = useState('');
  return (
    <BNTextInput
      autoFocus
      id="addCommentsTextBox"
      leftSection={<AddComment size={18} />}
      value={newComment}
      size="xs"
      onChange={(e) => {
        setNewComment(e.currentTarget.value);
      }}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          addComment(newComment);
          setNewComment('');
        } else if (e.key === 'Escape') {
          setNewComment('');
        }
      }}
      placeholder="Add Comment"
      className={newComment ? classes.showRightSection : classes.clearableInputAndGo}
      rightSectionWidth={57}
      rightSection={
        <Group wrap="nowrap" gap={4} px="xs">
          <ActionIcon
            size="sm"
            className="clearButton"
            onClick={() => {
              setNewComment('');
            }}
          >
            <CloseIcon color="var(--colors-gray-4)" />
          </ActionIcon>
          <ActionIcon
            variant="filled"
            size="sm"
            onClick={() => {
              addComment(newComment);
              setNewComment('');
            }}
          >
            <ArrowForwardIcon color="var(--colors-paper)" size={20} />
          </ActionIcon>
        </Group>
      }
    />
  );
}

export function EventComments({ commentsOpen, closeComments, numComments, openComments }: EventCommentsProps) {
  const { comments, isCommentsLoading, addComment, editComment, deleteComment, principal } = useEventComments(
    'comments',
    'isCommentsLoading',
    'addComment',
    'editComment',
    'deleteComment',
    'principal',
  );

  return (
    <Popover withinPortal withArrow opened width={380} closeOnClickOutside onClose={() => closeComments()}>
      <Popover.Target>
        <Tooltip label={`${numComments} event ${pluralize('comment', numComments)}`} withArrow withinPortal>
          <BNButton
            variant="subtle"
            className={classes.commentButton}
            c="currentColor"
            size="compact-xs"
            fw={500}
            fz={11}
            px={4}
            h={28}
            leftSection={<ForumIcon />}
            onClick={(e) => {
              e.stopPropagation();
              commentsOpen ? closeComments() : openComments();
            }}
            data-pendo-name="Event Comments Button"
          >
            {numComments}
          </BNButton>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown onClick={(e) => e.stopPropagation()} pb="sm" pt={0} mah={320} style={{ display: 'flex', flexDirection: 'column' }}>
        <>
          <Flex align="center" justify="space-between" h={40} style={{ flexShrink: 0 }}>
            <Text fw={600} fz="xs">
              Event Comments
            </Text>
            <ActionIcon onClick={closeComments} size="md">
              <CloseIcon color="var(--colors-gray-4)" />
            </ActionIcon>
          </Flex>
          {/* Only show loading state if we have comments to load */}
          {isCommentsLoading && numComments > 0 && (
            <>
              <Divider color="var(--colors-divider)" mx={-16} />
              <BNLoadingState h={100} />
            </>
          )}
          {!isCommentsLoading && comments.length > 0 && (
            <>
              <Divider color="var(--colors-divider)" mx={-16} />
              <CommentsList comments={comments} editComment={editComment} deleteComment={deleteComment} principal={principal} />
            </>
          )}
          <Divider color="var(--colors-divider)" mb="sm" mx={-16} />
          <Box style={{ flexShrink: 0 }}>
            <AddCommentsInput addComment={addComment} />
          </Box>
        </>
      </Popover.Dropdown>
    </Popover>
  );
}
