import { IconPropsBase } from './IconPropsBase';

export default function ClearNightIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M524-60.002q-79.804 0-149.632-30.269-69.829-30.269-121.829-82.268-51.999-52-82.268-121.829-30.27-69.828-30.27-149.632 0-131.384 80.115-234.037Q300.23-780.69 427.306-812.69q-11.076 96.307 19.847 186.768 30.923 90.462 99.615 159.154t159.154 99.615q90.461 30.923 186.768 19.847-31.385 127.076-134.346 207.19Q655.384-60.001 524-60.001ZM524-120q88 0 163-44t118-121.107q-86-8.011-163-43.56-77-35.549-138-96.633-61-61.085-97-137.892Q371-640 364-726q-77 43-120.5 118.5T200-444q0 135 94.5 229.5T524-120Zm-20-305.385Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M531.105-68.001q-76.291 0-143.045-28.931-66.755-28.931-116.476-78.652-49.721-49.721-78.652-116.476-28.931-66.754-28.931-143.045 0-125.279 75.115-223.932 75.114-98.653 198.19-129.653-10.076 96.307 20.347 181.268 30.423 84.962 97.115 151.654 64.692 64.692 151.654 94.615 86.961 29.923 178.268 19.847-29.385 121.076-128.846 197.19-99.46 76.115-224.739 76.115ZM531-120q88 0 164-45t115-122.107q-83-5.011-158.5-39.56T517-420.3q-60-60.085-94-134.892Q389-630 383-714q-77 41-122 116.184T216-435q0 131.25 91.875 223.125T531-120Zm-14-300.385Z"
        />
      </svg>
    );
  }
}
