import { Paper } from '@mantine/core';
import { MarketListingProvider } from './MarketListings.hooks';
import classes from './MarketListings.styles.tsx.module.css';
import { isDtiHosted } from '../../utils/whitelabel-consts';
import { DTIMarketplaceStateProvider } from '../DTI/DTI.Marketplace.hooks';
import { MarketListingsHeader } from './MarketListings.Header';
import { MarketListingsList } from './MarketListings.List';
import { MarketplaceResultsContainer } from '../MarketplaceResults/MarketplaceResultsContainer';
import { useGlobalState } from '../../data/GlobalState';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai/index';
import { selectedEventAtom } from '../../data/atoms';

export function MarketListings() {
  const selectedEvent = useAtomValue(selectedEventAtom);
  const { tenants } = useGlobalState('tenants');
  const eventTenant = useMemo(() => tenants?.find((t) => t.tenantId === selectedEvent?.tenantId), [selectedEvent?.tenantId, tenants]);
  const tenantPointOfSale = eventTenant?.pointOfSaleId || 'Unknown';

  return (
    <Paper className={classes.marketListingsWrapper}>
      {isDtiHosted && tenantPointOfSale === 'DtiPortal' ? (
        <DTIMarketplaceStateProvider>
          <MarketListingProvider>
            <MarketplaceResultsContainer />
          </MarketListingProvider>
        </DTIMarketplaceStateProvider>
      ) : (
        <MarketListingProvider>
          <MarketListingsHeader />
          <MarketListingsList />
        </MarketListingProvider>
      )}
    </Paper>
  );
}
