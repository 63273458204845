import { Box, Center, Stack, Text } from '@mantine/core';
import NotificationsIcon from '../icons/Notifications';

export function CustomNoRowsOverlay() {
  return (
    <Center p={80}>
      <Stack align="center" gap={0}>
        <Box
          bg="var(--colors-darkPaper)"
          mb={16}
          mt={-16}
          h={44}
          w={44}
          style={{
            border: '1px solid var(--colors-paper)',
            borderRadius: 44,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <NotificationsIcon color="var(--colors-iconFill)" size={28} />
        </Box>
        <Text size="md" fw={600} c="gray.9">
          No New Notifications
        </Text>
        <Text size="xs" c="gray.5" ta="center">
          We'll let you know when one arrives
        </Text>
      </Stack>
    </Center>
  );
}
