import { Box, Collapse, Divider, Flex, Group, Menu, Text } from '@mantine/core';
import { DragDropContext, Draggable, DraggableProvided, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { BNButton } from '../../components/Button/Button';
import { BNSwitch } from '../../components/Switch/Switch';
import ResetIcon from '../../components/icons/Reset';
import { SortItem } from './Inventory.SortItem';
import { useInventoryHeader } from './Inventory.Header.hooks';
import SelectArrowsIcon from '../../components/icons/SelectArrows';
import classes from './Inventory.styles.tsx.module.css';
import DragIndicatorIcon from '../../components/icons/DragIndicator';
import { useHomeState } from '../../pages/Home.hooks';
import { PricerView } from '../../types';
import { useGlobalState } from '../../data/GlobalState';

export function ListingSortBar({ hideEventSort = false, hideListingSort = false }) {
  const {
    columnSettings,
    columnSettingsHandlers,
    showSettings,
    eventSort,
    eventSortHandlers,
    listingSort,
    listingSortHandlers,
    resetAllDefaults,
    resetColumnDefaults,
    resetEventDefaults,
    resetListingDefaults,
    onMenuVisibilityChange,
  } = useInventoryHeader(
    'columnSettings',
    'columnSettingsHandlers',
    'showSettings',
    'eventSort',
    'eventSortHandlers',
    'listingSort',
    'listingSortHandlers',
    'resetAllDefaults',
    'resetColumnDefaults',
    'resetEventDefaults',
    'resetListingDefaults',
    'onMenuVisibilityChange',
  );

  const { pricerView } = useGlobalState('pricerView');

  const listingColumnSettingsItems = (columnSettings ?? []).map((item, index) => (
    <Draggable key={item.key} index={index} draggableId={item.key}>
      {(provided: DraggableProvided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Menu.Item
            {...provided.dragHandleProps}
            component="a"
            leftSection={<DragIndicatorIcon color="var(--colors-gray-4)" size={20} />}
            c={item.visible ? 'var(--colors-gray-9)' : 'var(--colors-gray-5)'}
            fz="xs"
            py={6}
            tt="capitalize"
            style={{ borderBottom: '1px solid var(--colors-paperHover)' }}
            rightSection={
              <BNSwitch
                size="xs"
                label={undefined}
                checked={item.visible}
                onClick={() => {
                  columnSettingsHandlers.setItemProp(index, 'visible', !item.visible);
                }}
              />
            }
            data-pendo-item={`Column Settings - ${item.name} Switch`}
          >
            {item.name}
          </Menu.Item>
        </div>
      )}
    </Draggable>
  ));

  const actionIndex = columnSettings.findIndex((c) => c.key === 'action');

  return (
    <Collapse in={showSettings} transitionTimingFunction="ease-in-out">
      <Box px="xs" className={classes.settingsShelf}>
        <Flex wrap="nowrap" p={0} gap={0} align="center" justify="space-between" maw="100%" w="100%">
          <Flex align="center" wrap="nowrap" pl={0} pr={0} gap={0} maw={pricerView === PricerView.Split ? '50%' : '62.5%'} style={{ flex: 1 }}>
            {!hideEventSort && (
              <Menu withArrow arrowPosition="center" width={240} closeOnItemClick={false} onChange={onMenuVisibilityChange}>
                <Menu.Target>
                  <BNButton px={6} size="xs" variant="subtle" maw={pricerView === PricerView.Split ? '50%' : '33%'} className={classes.dropBtn} rightSection={<SelectArrowsIcon />}>
                    <Text fw={600} truncate>
                      Event Sorting
                    </Text>
                  </BNButton>
                </Menu.Target>
                <DragDropContext
                  onDragEnd={({ destination, source }) => {
                    if (destination) {
                      eventSortHandlers.reorder({ from: source.index, to: destination?.index || 0 });
                      if (destination?.droppableId === 'unsorted') {
                        eventSortHandlers.setItemProp(destination.index, 'dir', null);
                      } else {
                        eventSortHandlers.setItemProp(destination.index, 'dir', eventSort[destination.index].dir || 'asc');
                      }
                    }
                  }}
                >
                  <Menu.Dropdown p={4} className={classes.menuDropdown}>
                    <Droppable droppableId="sorted" direction="vertical">
                      {(provided: DroppableProvided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {eventSort
                            .map((item, index) => ({ ...item, index }))
                            .filter((item) => item.dir !== null)
                            .map((item) => (
                              <SortItem key={item.key} item={item} updateHandler={eventSortHandlers} />
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <Menu.Divider
                      my={4}
                      mx={-4}
                      style={{
                        borderTop: '2px solid var(--colors-gray-3)',
                        borderBottom: '4px solid var(--colors-paperHover)',
                      }}
                    />
                    <Droppable droppableId="unsorted" direction="vertical">
                      {(provided: DroppableProvided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {eventSort
                            .map((item, index) => ({ ...item, index }))
                            .filter((item) => item.dir === null)
                            .map((item) => (
                              <SortItem key={item.key} item={item} updateHandler={eventSortHandlers} />
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <Divider color="var(--colors-divider)" my={4} mx={-4} />
                    <Box>
                      <BNButton leftSection={<ResetIcon />} size="xs" variant="subtle" className={classes.resetBtn} fullWidth onClick={resetEventDefaults}>
                        Reset to Default
                      </BNButton>
                    </Box>
                  </Menu.Dropdown>
                </DragDropContext>
              </Menu>
            )}
            {!hideListingSort && (
              <>
                <Menu withArrow arrowPosition="center" width={240} closeOnItemClick={false} onChange={onMenuVisibilityChange}>
                  <Menu.Target>
                    <BNButton
                      size="xs"
                      px={6}
                      variant="subtle"
                      maw={pricerView === PricerView.Split ? '50%' : '33%'}
                      className={classes.dropBtn}
                      rightSection={<SelectArrowsIcon />}
                    >
                      <Text fw={600} truncate>
                        Listing Sorting
                      </Text>
                    </BNButton>
                  </Menu.Target>
                  <DragDropContext
                    onDragEnd={({ destination, source }) => {
                      listingSortHandlers.setState((prevState) => {
                        const newState = structuredClone(prevState);
                        if (destination?.droppableId === 'unsorted') {
                          newState[source.index].dir = null;
                        } else {
                          newState[source.index].dir = newState[source.index].dir || 'asc';
                        }
                        const moveItem = newState.splice(source.index, 1)[0];
                        newState.splice(destination?.index || 0, 0, moveItem);
                        return newState
                          .map((item, index) => ({ ...item, index }))
                          .sort((a, b) => {
                            if (!a.dir && !b.dir) {
                              return a.name.localeCompare(b.name);
                            }
                            return a.index - b.index;
                          });
                      });
                    }}
                  >
                    <Menu.Dropdown p={4} className={classes.menuDropdown}>
                      <Droppable droppableId="sorted" direction="vertical">
                        {(provided: DroppableProvided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                              maxHeight: 400,
                              overflowY: 'scroll',
                            }}
                          >
                            {listingSort
                              .map((item, index) => ({ ...item, index }))
                              .filter((item) => item.dir != null)
                              .map((item) => (
                                <SortItem key={item.key} item={item} updateHandler={listingSortHandlers} />
                              ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                      <Menu.Divider
                        my={4}
                        mx={-4}
                        style={{
                          borderTop: '2px solid var(--colors-gray-3)',
                          borderBottom: '4px solid var(--colors-paperHover)',
                        }}
                      />
                      <Droppable droppableId="unsorted" direction="vertical">
                        {(provided: DroppableProvided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={
                              pricerView === PricerView.Split
                                ? {
                                    maxHeight: 200,
                                    overflowY: 'scroll',
                                  }
                                : { maxHeight: 400, overflowY: 'scroll' }
                            }
                          >
                            {listingSort
                              .map((item, index) => ({ ...item, index }))
                              .filter((item) => item.dir == null)
                              .map((item) => (
                                <SortItem key={item.key} item={item} updateHandler={listingSortHandlers} />
                              ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                      <Divider color="var(--colors-divider)" my={4} mx={-4} />
                      <Box>
                        <BNButton leftSection={<ResetIcon />} size="xs" variant="subtle" className={classes.resetBtn} fullWidth onClick={resetListingDefaults}>
                          Reset to Default
                        </BNButton>
                      </Box>
                    </Menu.Dropdown>
                  </DragDropContext>
                </Menu>
                <Menu withArrow arrowPosition="center" width={240} closeOnItemClick={false} onChange={onMenuVisibilityChange}>
                  <Menu.Target>
                    <BNButton
                      size="xs"
                      px={6}
                      variant="subtle"
                      maw={pricerView === PricerView.Split ? '50%' : '33%'}
                      className={classes.dropBtn}
                      rightSection={<SelectArrowsIcon />}
                    >
                      <Text fw={600} truncate>
                        Column Settings
                      </Text>
                    </BNButton>
                  </Menu.Target>
                  <DragDropContext
                    onDragEnd={({ destination, source }) =>
                      columnSettingsHandlers.reorder({
                        from: source.index,
                        to: destination?.index || 0,
                      })
                    }
                  >
                    <Menu.Dropdown p={4} className={classes.menuDropdown}>
                      <Droppable droppableId="key" direction="vertical">
                        {(provided: DroppableProvided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={
                              pricerView === PricerView.Split
                                ? {
                                    maxHeight: 200,
                                    overflowY: 'scroll',
                                  }
                                : { maxHeight: 412, overflowY: 'scroll' }
                            }
                          >
                            {listingColumnSettingsItems}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                      <Divider color="var(--colors-divider)" my={4} mx={-4} />
                      <Box>
                        <BNButton leftSection={<ResetIcon />} size="xs" variant="subtle" className={classes.resetBtn} fullWidth onClick={resetColumnDefaults}>
                          Reset to Default
                        </BNButton>
                      </Box>
                    </Menu.Dropdown>
                  </DragDropContext>
                </Menu>
              </>
            )}
          </Flex>
          <Flex wrap="nowrap" justify="end" maw={pricerView === PricerView.Split ? '50%' : '37.5%'} w="100%" gap={0}>
            {!hideListingSort && (
              <Group wrap="nowrap" p={0} pr={4} gap={0} className={classes.pinSwitchWrapper}>
                <BNSwitch
                  size="xs"
                  colorLabel
                  label="Pin action menu"
                  outlined
                  checked={columnSettings[actionIndex]?.pinned === 'right'}
                  onClick={() => {
                    columnSettingsHandlers.setItemProp(actionIndex, 'pinned', columnSettings[actionIndex]?.pinned === 'right' ? null : 'right');
                  }}
                  data-pendo-name="Pin Action Menu Switch"
                />
              </Group>
            )}
            <BNButton onClick={resetAllDefaults} size="xs" variant="subtle" color="gray" className={classes.resetDropBtn} leftSection={<ResetIcon />}>
              Reset to Default
            </BNButton>
          </Flex>
        </Flex>
      </Box>
    </Collapse>
  );
}
