import { Box, Loader, MantineSize, Switch, SwitchProps } from '@mantine/core';
import React, { SyntheticEvent } from 'react';
import CheckIcon from '../icons/Check';
import classes from './Switch.module.css';
import cx from 'clsx';

interface BNSwitchProps extends SwitchProps {
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  outlined?: boolean;
  size?: MantineSize | 'xxs' | undefined;
  colorLabel?: boolean;
  loading?: boolean;
}
export const BNSwitch = ({ checked, size, disabled, outlined, colorLabel, loading, ...props }: BNSwitchProps) => {
  // Check if set to our custom size of 'xxs'.
  const isXXS = size === 'xxs';
  if (isXXS) {
    // Set the size we pass below as 'xs' to preserve the appearance. 'xxs' size is used only to scale down via a transform.
    size = 'xs';
  }
  return (
    <Switch
      {...props}
      color="var(--colors-selectedBg)"
      disabled={disabled || loading}
      size={size}
      checked={checked}
      onLabel={
        loading ? (
          <Loader type="oval" size={size === 'xs' ? 8 : size === 'sm' ? 10 : size === 'md' ? 12 : size === 'lg' ? 14 : size === 'xl' ? 16 : 8} />
        ) : (
          props.onLabel || (
            <Box mr={-6} ml={size === 'xs' ? -6 : size === 'sm' ? -6 : size === 'md' ? -2 : 0}>
              <CheckIcon size={size === 'xs' ? 16 : size === 'sm' ? 20 : size === 'md' ? 20 : 24} color="var(--colors-selectedBorder)" />
            </Box>
          )
        )
      }
      offLabel={loading ? <Loader type="oval" size={size === 'xs' ? 8 : size === 'sm' ? 10 : size === 'md' ? 12 : size === 'lg' ? 14 : size === 'xl' ? 16 : 8} /> : props.offLabel}
      labelPosition={props.labelPosition || 'right'}
      classNames={{
        root: cx([classes.bnSwitch, isXXS ? classes.switchSizeXxs : null]),
        label: cx(colorLabel ? classes.switchColorLabel : null),
      }}
      styles={{
        label: {
          color: colorLabel && checked ? 'var(--colors-selectedBorder)' : 'var(--colors-gray-5)',
          paddingLeft: size === 'xs' ? 6 : 12,
          display: 'flex',
          alignItems: 'center',
        },
        track: {
          cursor: 'pointer',
          backgroundColor: checked ? 'var(--colors-selectedBg)' : 'transparent',
          borderColor: checked ? 'var(--colors-selectedBorder)' : 'var(--colors-gray-3)',
        },
        thumb: {
          cursor: 'pointer',
          backgroundColor: checked ? 'var(--colors-selectedBorder)' : 'var(--colors-gray-4)',
          borderColor: checked ? 'var(--colors-selectedBorder)' : 'var(--colors-gray-4)',
        },
      }}
    />
  );
};
