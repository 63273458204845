import { IconPropsBase } from './IconPropsBase';

export default function VerticalAlignBottomIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path fill={color} d="M4.25 20.75v-1.5h15.5v1.5Zm7.75-4.1L7.35 12l1.05-1.05 2.85 2.85V3.25h1.5V13.8l2.85-2.85L16.65 12Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path fill={color} d="M4.208 16.792v-1.084h11.584v1.084ZM10 13.708 6.292 10l.77-.771 2.396 2.396V3.208h1.084v8.417l2.396-2.396.77.771Z" />
      </svg>
    );
  }
}
