import { IconPropsBase } from './IconPropsBase';

export default function MarketplaceStubHubIcon({ size = 24, color = 'currentColor' }: IconPropsBase) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <path
        fill={color}
        d="M2,3.67l1.67,15.42-.96,2.92,4.29-1.67,13.33.42,1.67-18.75L2,3.67ZM16.17,14.23c.03.92-.41,1.8-1.16,2.33-.75.58-1.85.86-3.21.86s-2.42-.29-3.18-.86c-.75-.53-1.19-1.41-1.16-2.33v-.17h1.65v.17c0,.47.2.82.68,1.11.47.29,1.15.43,2.02.43s1.57-.15,2.05-.43.7-.64.7-1.11v-.23c0-.54-.32-.9-.97-1.11l-3.84-1.29c-.63-.2-1.13-.51-1.46-.91-.34-.41-.52-.92-.51-1.45v-.26c0-1.98,1.3-3.01,3.9-3.03h.17c2.65,0,4.03.97,4.11,2.86v.14h-1.66v-.13c-.1-.83-.9-1.24-2.44-1.24h-.14c-1.55.03-2.3.49-2.3,1.39v.26c.01.17.09.33.22.43.18.16.38.29.61.36l3.88,1.29c.59.18,1.11.53,1.51,1,.38.48.58,1.07.56,1.68v.23Z"
      />
    </svg>
  );
}
