import { IconPropsBase } from './IconPropsBase';

export default function TuneIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 96 960 960">
        <path
          fill={color}
          d="M450.001 925.999V706.001h59.998v80h320v59.998h-320v80h-59.998Zm-320-80v-59.998h219.998v59.998H130.001Zm160-160v-80h-160v-59.998h160v-80h59.998v219.998h-59.998Zm160-80v-59.998h379.998v59.998H450.001Zm160-160V226.001h59.998v80h160v59.998h-160v80h-59.998Zm-480-80v-59.998h379.998v59.998H130.001Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 96 960 960">
        <path
          fill={color}
          d="M466.001 901.999V682.001h51.998v84h288v51.998h-288v84h-51.998Zm-312-84v-51.998h219.998v51.998H154.001Zm144-132v-84h-144v-51.998h144v-84h51.998v219.998h-51.998Zm144-84v-51.998h363.998v51.998H442.001Zm144-132V250.001h51.998v84h168v51.998h-168v84h-51.998Zm-432-84v-51.998h363.998v51.998H154.001Z"
        />
      </svg>
    );
  }
}
