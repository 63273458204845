import { Box, Center, Divider, Flex, Space, Stepper, Text, Title, useComputedColorScheme } from '@mantine/core';
import { useEffect, useState } from 'react';
import { isDtiHosted } from '../utils/whitelabel-consts';
import DTILogo from '../components/icons/DTILogo';
import { BNButton } from '../components/Button/Button';
import { BNOnboardingSelector } from '../components/OnboardingSelector/OnboardingSelector';
import BNLogo from '../components/icons/BNLogo';
import TableRowsIcon from '../components/icons/TableRows';
import ViewAgendaIcon from '../components/icons/ViewAgenda';
import classes from './Onboarding.module.css';
import dtiStep1SplitDark from '../../public_dti/dti-step1-split-dark.gif';
import dtiStep1SplitDarkStill from '../../public_dti/dti-step1-split-dark.jpg';
import dtiStep1SplitLight from '../../public_dti/dti-step1-split-light.gif';
import dtiStep1SplitLightStill from '../../public_dti/dti-step1-split-light.jpg';
import dtiStep1StackedDark from '../../public_dti/dti-step1-stacked-dark.gif';
import dtiStep1StackedDarkStill from '../../public_dti/dti-step1-stacked-dark.jpg';
import dtiStep1StackedLight from '../../public_dti/dti-step1-stacked-light.gif';
import dtiStep1StackedLightStill from '../../public_dti/dti-step1-stacked-light.jpg';
import dtiStep2SplitDarkIndividual from '../../public_dti/dti-step2-split-dark-individual.gif';
import dtiStep2SplitDarkIndividualStill from '../../public_dti/dti-step2-split-dark-individual.jpg';
import dtiStep2SplitDarkStaged from '../../public_dti/dti-step2-split-dark-staged.gif';
import dtiStep2SplitDarkStagedStill from '../../public_dti/dti-step2-split-dark-staged.jpg';
import dtiStep2SplitLightIndividual from '../../public_dti/dti-step2-split-light-individual.gif';
import dtiStep2SplitLightIndividualStill from '../../public_dti/dti-step2-split-light-individual.jpg';
import dtiStep2SplitLightStaged from '../../public_dti/dti-step2-split-light-staged.gif';
import dtiStep2SplitLightStagedStill from '../../public_dti/dti-step2-split-light-staged.jpg';
import dtiStep2StackedDarkIndividual from '../../public_dti/dti-step2-stacked-dark-individual.gif';
import dtiStep2StackedDarkIndividualStill from '../../public_dti/dti-step2-stacked-dark-individual.jpg';
import dtiStep2StackedDarkStaged from '../../public_dti/dti-step2-stacked-dark-staged.gif';
import dtiStep2StackedDarkStagedStill from '../../public_dti/dti-step2-stacked-dark-staged.jpg';
import dtiStep2StackedLightIndividual from '../../public_dti/dti-step2-stacked-light-individual.gif';
import dtiStep2StackedLightIndividualStill from '../../public_dti/dti-step2-stacked-light-individual.jpg';
import dtiStep2StackedLightStaged from '../../public_dti/dti-step2-stacked-light-staged.gif';
import dtiStep2StackedLightStagedStill from '../../public_dti/dti-step2-stacked-light-staged.jpg';

import DotsBackground from '../components/ErrorPage/DotsBackground';
import ArrowForwardIcon from '../components/icons/ArrowForward';
import ArrowBackIcon from '../components/icons/ArrowBack';
import StacksIcon from '../components/icons/Stacks';
import { HeaderAction } from '../hoc/Admin/HeaderAction';
import { PricingSettingsStateProvider, usePricingSettings } from '../hoc/Settings/PricingSettings.hooks';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../data/GlobalState';
import { PricerView } from '../types';
import { BarkerCoreEnumsPricingMode, getGetApiPrincipalsInterruptsQueryKey, useGetApiPrincipalsInterrupts, usePutApiPrincipalsInterruptsInterruptIdComplete } from '../api';
import PriceChangeIcon from '../components/icons/PriceChange';
import { useQueryClient } from '@tanstack/react-query';

export function Onboarding() {
  return (
    <PricingSettingsStateProvider>
      <OnboardingImpl />
    </PricingSettingsStateProvider>
  );
}

function OnboardingImpl() {
  const [exampleStepActive, setExampleStepActive] = useState(0);
  const nextStep = () => setExampleStepActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () => setExampleStepActive((current) => (current > 0 ? current - 1 : current));
  const computedColorScheme = useComputedColorScheme();
  const dark = computedColorScheme === 'dark';

  const { form, submit } = usePricingSettings('form', 'submit');
  const { setPricerView: savePricerView } = useGlobalState('setPricerView');
  const [pricerView, setPricerView] = useState<PricerView>();
  const [pricingMode, setPricingMode] = useState<BarkerCoreEnumsPricingMode>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: interrupts } = useGetApiPrincipalsInterrupts();
  const { mutateAsync: completeInterrupt, isLoading: isCompletingInterrupt } = usePutApiPrincipalsInterruptsInterruptIdComplete();

  useEffect(() => {
    if (interrupts !== undefined) {
      if (!interrupts.data.some((i) => i.interruptTypeId === 'Onboarding')) {
        navigate('/');
      }
    }
  }, [interrupts, navigate]);

  async function finishOnboarding() {
    if (!pricerView || !pricingMode || !interrupts) {
      return;
    }
    savePricerView(pricerView);
    form.setFieldValue('pricingMode', pricingMode);
    submit();
    await completeInterrupt({ interruptId: interrupts.data.filter((i) => i.interruptTypeId === 'Onboarding')[0].interruptId });
    await queryClient.invalidateQueries(getGetApiPrincipalsInterruptsQueryKey());
    navigate('/');
  }

  return (
    <>
      <HeaderAction links={[]} containerBg="var(--colors-gray-0)" containerStyle={{ borderBottom: '1px solid var(--colors-opacity-hover)', zIndex: 200 }} hideProfileMenuItem />
      <Flex className={classes.contentWrapper} gap="xl" direction="column">
        <Center pos="relative" style={{ gap: 36, zIndex: 101 }}>
          {exampleStepActive !== 0 && (
            <BNButton onClick={prevStep} pos="absolute" left={0} top={0} leftSection={<ArrowBackIcon />} variant="subtle">
              Back
            </BNButton>
          )}
          <Stepper active={exampleStepActive} onStepClick={setExampleStepActive} className={classes.onboardingStepper}>
            <Stepper.Step
              component="div"
              label={
                <>
                  <Text tt="uppercase" fw="700" mt={10} lts="0.025em" mb="md" c="var(--colors-selectedBorder)">
                    STEP 1 OF 2
                  </Text>
                  <Flex justify="center" gap="xs" mx="auto" mt={-4}>
                    <Divider color="var(--colors-selectedBorder)" size={2} w={20} />
                    <Divider color="var(--colors-gray-3)" size={2} w={20} />
                  </Flex>
                  <Space h={36} className={classes.headerSpace} />
                  <Title>Select a Layout</Title>
                </>
              }
              description="Pricing view can be updated at any time in settings."
            >
              <BNOnboardingSelector
                value={pricerView}
                onChange={(value) => {
                  if (value !== PricerView.Standard && value !== PricerView.Split) {
                    return;
                  }

                  setPricerView(value);
                }}
                options={[
                  {
                    icon: <TableRowsIcon size={24} />,
                    title: 'Stacked View',
                    description: 'Events & listings combined into one scrollable grid.',
                    image: dark ? dtiStep1StackedDarkStill : dtiStep1StackedLightStill,
                    imageHover: dark ? dtiStep1StackedDark : dtiStep1StackedLight,
                    badgeText: 'Recommended for Fewer Listings',
                    value: PricerView.Standard,
                  },
                  {
                    icon: <ViewAgendaIcon size={24} />,
                    title: 'Split View',
                    description: 'Separate resizable grids for events & listings',
                    image: dark ? dtiStep1SplitDarkStill : dtiStep1SplitLightStill,
                    imageHover: dark ? dtiStep1SplitDark : dtiStep1SplitLight,
                    badgeText: 'Recommended for Large Inventories',
                    value: PricerView.Split,
                  },
                ]}
              />
            </Stepper.Step>
            <Stepper.Step
              component="div"
              icon={isDtiHosted ? <DTILogo size={48} /> : <BNLogo size={53} />}
              label={
                <>
                  <Text tt="uppercase" fw="700" mt={10} lts="0.025em" mb="md" c="var(--colors-selectedBorder)">
                    STEP 2 OF 2
                  </Text>
                  <Flex justify="center" gap="xs" mx="auto" mt={-4}>
                    <Divider color="var(--colors-gray-3)" size={2} w={20} />
                    <Divider color="var(--colors-selectedBorder)" size={2} w={20} />
                  </Flex>
                  <Space h={36} className={classes.headerSpace} />
                  <Title>Select a Pricing Style</Title>
                </>
              }
              description="Pricing style can be updated at any time in settings."
            >
              {pricerView === PricerView.Standard ? (
                <BNOnboardingSelector
                  value={pricingMode}
                  onChange={(value) => {
                    if (value !== BarkerCoreEnumsPricingMode.Individual && value !== BarkerCoreEnumsPricingMode.Staged) {
                      return;
                    }

                    setPricingMode(value);
                  }}
                  options={[
                    {
                      icon: <PriceChangeIcon size={24} />,
                      title: 'Instant Price Changes',
                      description: 'Price listings one at a time. Updates are immediate.',
                      image: dark ? dtiStep2StackedDarkIndividualStill : dtiStep2StackedLightIndividualStill,
                      imageHover: dark ? dtiStep2StackedDarkIndividual : dtiStep2StackedLightIndividual,
                      badgeText: 'A new way to price',
                      value: BarkerCoreEnumsPricingMode.Individual,
                    },
                    {
                      icon: <StacksIcon size={24} />,
                      title: 'Staged Price Changes',
                      description: 'Queue pending price changes before applying.',
                      image: dark ? dtiStep2StackedDarkStagedStill : dtiStep2StackedLightStagedStill,
                      imageHover: dark ? dtiStep2StackedDarkStaged : dtiStep2StackedLightStaged,
                      badgeText: 'Similar to original DTI Portal',
                      value: BarkerCoreEnumsPricingMode.Staged,
                    },
                  ]}
                />
              ) : (
                <BNOnboardingSelector
                  value={pricingMode}
                  onChange={(value) => {
                    if (value !== BarkerCoreEnumsPricingMode.Individual && value !== BarkerCoreEnumsPricingMode.Staged) {
                      return;
                    }

                    setPricingMode(value);
                  }}
                  options={[
                    {
                      icon: <PriceChangeIcon size={24} />,
                      title: 'Instant Price Changes',
                      description: 'Price listings one at a time. Updates are immediate.',
                      image: dark ? dtiStep2SplitDarkIndividualStill : dtiStep2SplitLightIndividualStill,
                      imageHover: dark ? dtiStep2SplitDarkIndividual : dtiStep2SplitLightIndividual,
                      badgeText: 'A new way to price',
                      value: BarkerCoreEnumsPricingMode.Individual,
                    },
                    {
                      icon: <StacksIcon size={24} />,
                      title: 'Staged Price Changes',
                      description: 'Queue pending price changes before applying.',
                      image: dark ? dtiStep2SplitDarkStagedStill : dtiStep2SplitLightStagedStill,
                      imageHover: dark ? dtiStep2SplitDarkStaged : dtiStep2SplitLightStaged,
                      badgeText: 'Similar to original DTI Portal',
                      value: BarkerCoreEnumsPricingMode.Staged,
                    },
                  ]}
                />
              )}
            </Stepper.Step>
          </Stepper>
        </Center>
        <Flex bg="var(--colors-siteBg)" mt="xs" py="md" pos="relative" style={{ zIndex: 100 }} px="xl" justify="center">
          {exampleStepActive === 0 ? (
            <BNButton disabled={!pricerView} onClick={nextStep} variant="filled" w={200} rightSection={<ArrowForwardIcon />}>
              Continue
            </BNButton>
          ) : (
            <BNButton color="green" variant="filled" w={200} disabled={!pricingMode} loading={isCompletingInterrupt} onClick={finishOnboarding}>
              Get Started
            </BNButton>
          )}
        </Flex>
        <Box pos="fixed" className={classes.onboardingDots} bottom={0}>
          <DotsBackground />
        </Box>
      </Flex>
    </>
  );
}
