import { useMutation, useQuery } from '@tanstack/react-query';
import type { MutationFunction, QueryFunction, QueryKey, UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  BarkerCoreModelsPricingFilterSuggestion,
  BarkerCoreModelsPricingMonitor,
  BarkerCoreModelsPricingMonitorBase,
  BarkerCoreModelsPricingRule,
  BarkerCoreModelsPricingRuleBase,
  BarkerCoreModelsPricingRuleFilters,
  BarkerCoreModelsPricingSplitRuleRequest,
  BarkerCoreModelsPricingSplitRuleResponse,
  BarkerCoreModelsPricingUpdateRulesRequestBulk,
  GetApiPricingSuggestionsParams,
} from './models';

export const getApiPricingRulesRuleId = (ruleId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsPricingRule>> => {
  return axios.get(`/api/Pricing/Rules/${ruleId}`, options);
};

export const getGetApiPricingRulesRuleIdQueryKey = (ruleId: string) => {
  return [`/api/Pricing/Rules/${ruleId}`] as const;
};

export const getGetApiPricingRulesRuleIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiPricingRulesRuleId>>, TError = AxiosError<void>>(
  ruleId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPricingRulesRuleId>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiPricingRulesRuleIdQueryKey(ruleId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiPricingRulesRuleId>>> = ({ signal }) => getApiPricingRulesRuleId(ruleId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!ruleId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiPricingRulesRuleId>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiPricingRulesRuleIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPricingRulesRuleId>>>;
export type GetApiPricingRulesRuleIdQueryError = AxiosError<void>;

export const useGetApiPricingRulesRuleId = <TData = Awaited<ReturnType<typeof getApiPricingRulesRuleId>>, TError = AxiosError<void>>(
  ruleId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPricingRulesRuleId>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPricingRulesRuleIdQueryOptions(ruleId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putApiPricingRulesRuleId = (
  ruleId: string,
  barkerCoreModelsPricingRuleBase: BarkerCoreModelsPricingRuleBase,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsPricingRule>> => {
  return axios.put(`/api/Pricing/Rules/${ruleId}`, barkerCoreModelsPricingRuleBase, options);
};

export const getPutApiPricingRulesRuleIdMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPricingRulesRuleId>>, TError, { ruleId: string; data: BarkerCoreModelsPricingRuleBase }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiPricingRulesRuleId>>, TError, { ruleId: string; data: BarkerCoreModelsPricingRuleBase }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiPricingRulesRuleId>>, { ruleId: string; data: BarkerCoreModelsPricingRuleBase }> = (props) => {
    const { ruleId, data } = props ?? {};

    return putApiPricingRulesRuleId(ruleId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiPricingRulesRuleIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiPricingRulesRuleId>>>;
export type PutApiPricingRulesRuleIdMutationBody = BarkerCoreModelsPricingRuleBase;
export type PutApiPricingRulesRuleIdMutationError = AxiosError<void>;

export const usePutApiPricingRulesRuleId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPricingRulesRuleId>>, TError, { ruleId: string; data: BarkerCoreModelsPricingRuleBase }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiPricingRulesRuleId>>, TError, { ruleId: string; data: BarkerCoreModelsPricingRuleBase }, TContext> => {
  const mutationOptions = getPutApiPricingRulesRuleIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteApiPricingRulesRuleId = (ruleId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/Pricing/Rules/${ruleId}`, options);
};

export const getDeleteApiPricingRulesRuleIdMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiPricingRulesRuleId>>, TError, { ruleId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteApiPricingRulesRuleId>>, TError, { ruleId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiPricingRulesRuleId>>, { ruleId: string }> = (props) => {
    const { ruleId } = props ?? {};

    return deleteApiPricingRulesRuleId(ruleId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiPricingRulesRuleIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiPricingRulesRuleId>>>;

export type DeleteApiPricingRulesRuleIdMutationError = AxiosError<void>;

export const useDeleteApiPricingRulesRuleId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiPricingRulesRuleId>>, TError, { ruleId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof deleteApiPricingRulesRuleId>>, TError, { ruleId: string }, TContext> => {
  const mutationOptions = getDeleteApiPricingRulesRuleIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiPricingRulesRuleIdListingIds = (ruleId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<string[]>> => {
  return axios.get(`/api/Pricing/Rules/${ruleId}/ListingIds`, options);
};

export const getGetApiPricingRulesRuleIdListingIdsQueryKey = (ruleId: string) => {
  return [`/api/Pricing/Rules/${ruleId}/ListingIds`] as const;
};

export const getGetApiPricingRulesRuleIdListingIdsQueryOptions = <TData = Awaited<ReturnType<typeof getApiPricingRulesRuleIdListingIds>>, TError = AxiosError<void>>(
  ruleId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPricingRulesRuleIdListingIds>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiPricingRulesRuleIdListingIdsQueryKey(ruleId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiPricingRulesRuleIdListingIds>>> = ({ signal }) =>
    getApiPricingRulesRuleIdListingIds(ruleId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!ruleId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiPricingRulesRuleIdListingIds>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiPricingRulesRuleIdListingIdsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPricingRulesRuleIdListingIds>>>;
export type GetApiPricingRulesRuleIdListingIdsQueryError = AxiosError<void>;

export const useGetApiPricingRulesRuleIdListingIds = <TData = Awaited<ReturnType<typeof getApiPricingRulesRuleIdListingIds>>, TError = AxiosError<void>>(
  ruleId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPricingRulesRuleIdListingIds>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPricingRulesRuleIdListingIdsQueryOptions(ruleId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postApiPricingRules = (
  barkerCoreModelsPricingRuleBase: BarkerCoreModelsPricingRuleBase,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsPricingRule>> => {
  return axios.post(`/api/Pricing/Rules`, barkerCoreModelsPricingRuleBase, options);
};

export const getPostApiPricingRulesMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiPricingRules>>, TError, { data: BarkerCoreModelsPricingRuleBase }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiPricingRules>>, TError, { data: BarkerCoreModelsPricingRuleBase }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiPricingRules>>, { data: BarkerCoreModelsPricingRuleBase }> = (props) => {
    const { data } = props ?? {};

    return postApiPricingRules(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiPricingRulesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiPricingRules>>>;
export type PostApiPricingRulesMutationBody = BarkerCoreModelsPricingRuleBase;
export type PostApiPricingRulesMutationError = AxiosError<void>;

export const usePostApiPricingRules = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiPricingRules>>, TError, { data: BarkerCoreModelsPricingRuleBase }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postApiPricingRules>>, TError, { data: BarkerCoreModelsPricingRuleBase }, TContext> => {
  const mutationOptions = getPostApiPricingRulesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiPricingRulesBulk = (
  barkerCoreModelsPricingUpdateRulesRequestBulk: BarkerCoreModelsPricingUpdateRulesRequestBulk[],
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Pricing/Rules/Bulk`, barkerCoreModelsPricingUpdateRulesRequestBulk, options);
};

export const getPutApiPricingRulesBulkMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPricingRulesBulk>>, TError, { data: BarkerCoreModelsPricingUpdateRulesRequestBulk[] }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiPricingRulesBulk>>, TError, { data: BarkerCoreModelsPricingUpdateRulesRequestBulk[] }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiPricingRulesBulk>>, { data: BarkerCoreModelsPricingUpdateRulesRequestBulk[] }> = (props) => {
    const { data } = props ?? {};

    return putApiPricingRulesBulk(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiPricingRulesBulkMutationResult = NonNullable<Awaited<ReturnType<typeof putApiPricingRulesBulk>>>;
export type PutApiPricingRulesBulkMutationBody = BarkerCoreModelsPricingUpdateRulesRequestBulk[];
export type PutApiPricingRulesBulkMutationError = AxiosError<void>;

export const usePutApiPricingRulesBulk = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPricingRulesBulk>>, TError, { data: BarkerCoreModelsPricingUpdateRulesRequestBulk[] }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiPricingRulesBulk>>, TError, { data: BarkerCoreModelsPricingUpdateRulesRequestBulk[] }, TContext> => {
  const mutationOptions = getPutApiPricingRulesBulkMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiPricingRulesRuleIdFilters = (
  ruleId: string,
  barkerCoreModelsPricingRuleFilters: BarkerCoreModelsPricingRuleFilters,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsPricingRule>> => {
  return axios.put(`/api/Pricing/Rules/${ruleId}/Filters`, barkerCoreModelsPricingRuleFilters, options);
};

export const getPutApiPricingRulesRuleIdFiltersMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPricingRulesRuleIdFilters>>, TError, { ruleId: string; data: BarkerCoreModelsPricingRuleFilters }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiPricingRulesRuleIdFilters>>, TError, { ruleId: string; data: BarkerCoreModelsPricingRuleFilters }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiPricingRulesRuleIdFilters>>, { ruleId: string; data: BarkerCoreModelsPricingRuleFilters }> = (props) => {
    const { ruleId, data } = props ?? {};

    return putApiPricingRulesRuleIdFilters(ruleId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiPricingRulesRuleIdFiltersMutationResult = NonNullable<Awaited<ReturnType<typeof putApiPricingRulesRuleIdFilters>>>;
export type PutApiPricingRulesRuleIdFiltersMutationBody = BarkerCoreModelsPricingRuleFilters;
export type PutApiPricingRulesRuleIdFiltersMutationError = AxiosError<void>;

export const usePutApiPricingRulesRuleIdFilters = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPricingRulesRuleIdFilters>>, TError, { ruleId: string; data: BarkerCoreModelsPricingRuleFilters }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiPricingRulesRuleIdFilters>>, TError, { ruleId: string; data: BarkerCoreModelsPricingRuleFilters }, TContext> => {
  const mutationOptions = getPutApiPricingRulesRuleIdFiltersMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiPricingRulesRuleIdAutoPrice = (ruleId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Pricing/Rules/${ruleId}/AutoPrice`, undefined, options);
};

export const getPutApiPricingRulesRuleIdAutoPriceMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPricingRulesRuleIdAutoPrice>>, TError, { ruleId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiPricingRulesRuleIdAutoPrice>>, TError, { ruleId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiPricingRulesRuleIdAutoPrice>>, { ruleId: string }> = (props) => {
    const { ruleId } = props ?? {};

    return putApiPricingRulesRuleIdAutoPrice(ruleId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiPricingRulesRuleIdAutoPriceMutationResult = NonNullable<Awaited<ReturnType<typeof putApiPricingRulesRuleIdAutoPrice>>>;

export type PutApiPricingRulesRuleIdAutoPriceMutationError = AxiosError<void>;

export const usePutApiPricingRulesRuleIdAutoPrice = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPricingRulesRuleIdAutoPrice>>, TError, { ruleId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiPricingRulesRuleIdAutoPrice>>, TError, { ruleId: string }, TContext> => {
  const mutationOptions = getPutApiPricingRulesRuleIdAutoPriceMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postApiPricingRulesRuleIdSplit = (
  ruleId: string,
  barkerCoreModelsPricingSplitRuleRequest: BarkerCoreModelsPricingSplitRuleRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsPricingSplitRuleResponse[]>> => {
  return axios.post(`/api/Pricing/Rules/${ruleId}/Split`, barkerCoreModelsPricingSplitRuleRequest, options);
};

export const getPostApiPricingRulesRuleIdSplitMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiPricingRulesRuleIdSplit>>, TError, { ruleId: string; data: BarkerCoreModelsPricingSplitRuleRequest }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiPricingRulesRuleIdSplit>>, TError, { ruleId: string; data: BarkerCoreModelsPricingSplitRuleRequest }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiPricingRulesRuleIdSplit>>, { ruleId: string; data: BarkerCoreModelsPricingSplitRuleRequest }> = (props) => {
    const { ruleId, data } = props ?? {};

    return postApiPricingRulesRuleIdSplit(ruleId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiPricingRulesRuleIdSplitMutationResult = NonNullable<Awaited<ReturnType<typeof postApiPricingRulesRuleIdSplit>>>;
export type PostApiPricingRulesRuleIdSplitMutationBody = BarkerCoreModelsPricingSplitRuleRequest;
export type PostApiPricingRulesRuleIdSplitMutationError = AxiosError<void>;

export const usePostApiPricingRulesRuleIdSplit = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiPricingRulesRuleIdSplit>>, TError, { ruleId: string; data: BarkerCoreModelsPricingSplitRuleRequest }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postApiPricingRulesRuleIdSplit>>, TError, { ruleId: string; data: BarkerCoreModelsPricingSplitRuleRequest }, TContext> => {
  const mutationOptions = getPostApiPricingRulesRuleIdSplitMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiPricingMonitorsMonitorId = (monitorId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsPricingMonitor>> => {
  return axios.get(`/api/Pricing/Monitors/${monitorId}`, options);
};

export const getGetApiPricingMonitorsMonitorIdQueryKey = (monitorId: string) => {
  return [`/api/Pricing/Monitors/${monitorId}`] as const;
};

export const getGetApiPricingMonitorsMonitorIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiPricingMonitorsMonitorId>>, TError = AxiosError<void>>(
  monitorId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPricingMonitorsMonitorId>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiPricingMonitorsMonitorIdQueryKey(monitorId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiPricingMonitorsMonitorId>>> = ({ signal }) => getApiPricingMonitorsMonitorId(monitorId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!monitorId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiPricingMonitorsMonitorId>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiPricingMonitorsMonitorIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPricingMonitorsMonitorId>>>;
export type GetApiPricingMonitorsMonitorIdQueryError = AxiosError<void>;

export const useGetApiPricingMonitorsMonitorId = <TData = Awaited<ReturnType<typeof getApiPricingMonitorsMonitorId>>, TError = AxiosError<void>>(
  monitorId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPricingMonitorsMonitorId>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPricingMonitorsMonitorIdQueryOptions(monitorId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteApiPricingMonitorsMonitorId = (monitorId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/Pricing/Monitors/${monitorId}`, options);
};

export const getDeleteApiPricingMonitorsMonitorIdMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiPricingMonitorsMonitorId>>, TError, { monitorId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteApiPricingMonitorsMonitorId>>, TError, { monitorId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiPricingMonitorsMonitorId>>, { monitorId: string }> = (props) => {
    const { monitorId } = props ?? {};

    return deleteApiPricingMonitorsMonitorId(monitorId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiPricingMonitorsMonitorIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiPricingMonitorsMonitorId>>>;

export type DeleteApiPricingMonitorsMonitorIdMutationError = AxiosError<void>;

export const useDeleteApiPricingMonitorsMonitorId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiPricingMonitorsMonitorId>>, TError, { monitorId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof deleteApiPricingMonitorsMonitorId>>, TError, { monitorId: string }, TContext> => {
  const mutationOptions = getDeleteApiPricingMonitorsMonitorIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiPricingMonitorsMonitorId = (
  monitorId: string,
  barkerCoreModelsPricingMonitorBase: BarkerCoreModelsPricingMonitorBase,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsPricingMonitor>> => {
  return axios.put(`/api/Pricing/Monitors/${monitorId}`, barkerCoreModelsPricingMonitorBase, options);
};

export const getPutApiPricingMonitorsMonitorIdMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPricingMonitorsMonitorId>>, TError, { monitorId: string; data: BarkerCoreModelsPricingMonitorBase }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiPricingMonitorsMonitorId>>, TError, { monitorId: string; data: BarkerCoreModelsPricingMonitorBase }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiPricingMonitorsMonitorId>>, { monitorId: string; data: BarkerCoreModelsPricingMonitorBase }> = (props) => {
    const { monitorId, data } = props ?? {};

    return putApiPricingMonitorsMonitorId(monitorId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiPricingMonitorsMonitorIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiPricingMonitorsMonitorId>>>;
export type PutApiPricingMonitorsMonitorIdMutationBody = BarkerCoreModelsPricingMonitorBase;
export type PutApiPricingMonitorsMonitorIdMutationError = AxiosError<void>;

export const usePutApiPricingMonitorsMonitorId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPricingMonitorsMonitorId>>, TError, { monitorId: string; data: BarkerCoreModelsPricingMonitorBase }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiPricingMonitorsMonitorId>>, TError, { monitorId: string; data: BarkerCoreModelsPricingMonitorBase }, TContext> => {
  const mutationOptions = getPutApiPricingMonitorsMonitorIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiPricingMonitorsMonitorIdClearAlert = (monitorId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Pricing/Monitors/${monitorId}/ClearAlert`, undefined, options);
};

export const getPutApiPricingMonitorsMonitorIdClearAlertMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPricingMonitorsMonitorIdClearAlert>>, TError, { monitorId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiPricingMonitorsMonitorIdClearAlert>>, TError, { monitorId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiPricingMonitorsMonitorIdClearAlert>>, { monitorId: string }> = (props) => {
    const { monitorId } = props ?? {};

    return putApiPricingMonitorsMonitorIdClearAlert(monitorId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiPricingMonitorsMonitorIdClearAlertMutationResult = NonNullable<Awaited<ReturnType<typeof putApiPricingMonitorsMonitorIdClearAlert>>>;

export type PutApiPricingMonitorsMonitorIdClearAlertMutationError = AxiosError<void>;

export const usePutApiPricingMonitorsMonitorIdClearAlert = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPricingMonitorsMonitorIdClearAlert>>, TError, { monitorId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiPricingMonitorsMonitorIdClearAlert>>, TError, { monitorId: string }, TContext> => {
  const mutationOptions = getPutApiPricingMonitorsMonitorIdClearAlertMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postApiPricingMonitors = (
  barkerCoreModelsPricingMonitorBase: BarkerCoreModelsPricingMonitorBase,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsPricingMonitor>> => {
  return axios.post(`/api/Pricing/Monitors`, barkerCoreModelsPricingMonitorBase, options);
};

export const getPostApiPricingMonitorsMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiPricingMonitors>>, TError, { data: BarkerCoreModelsPricingMonitorBase }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiPricingMonitors>>, TError, { data: BarkerCoreModelsPricingMonitorBase }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiPricingMonitors>>, { data: BarkerCoreModelsPricingMonitorBase }> = (props) => {
    const { data } = props ?? {};

    return postApiPricingMonitors(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiPricingMonitorsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiPricingMonitors>>>;
export type PostApiPricingMonitorsMutationBody = BarkerCoreModelsPricingMonitorBase;
export type PostApiPricingMonitorsMutationError = AxiosError<void>;

export const usePostApiPricingMonitors = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiPricingMonitors>>, TError, { data: BarkerCoreModelsPricingMonitorBase }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postApiPricingMonitors>>, TError, { data: BarkerCoreModelsPricingMonitorBase }, TContext> => {
  const mutationOptions = getPostApiPricingMonitorsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiPricingSuggestions = (
  params?: GetApiPricingSuggestionsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsPricingFilterSuggestion>> => {
  return axios.get(`/api/Pricing/Suggestions`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetApiPricingSuggestionsQueryKey = (params?: GetApiPricingSuggestionsParams) => {
  return [`/api/Pricing/Suggestions`, ...(params ? [params] : [])] as const;
};

export const getGetApiPricingSuggestionsQueryOptions = <TData = Awaited<ReturnType<typeof getApiPricingSuggestions>>, TError = AxiosError<void>>(
  params?: GetApiPricingSuggestionsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPricingSuggestions>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiPricingSuggestionsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiPricingSuggestions>>> = ({ signal }) => getApiPricingSuggestions(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiPricingSuggestions>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiPricingSuggestionsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPricingSuggestions>>>;
export type GetApiPricingSuggestionsQueryError = AxiosError<void>;

export const useGetApiPricingSuggestions = <TData = Awaited<ReturnType<typeof getApiPricingSuggestions>>, TError = AxiosError<void>>(
  params?: GetApiPricingSuggestionsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPricingSuggestions>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPricingSuggestionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putApiPricingAutoPricingPause = (options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Pricing/AutoPricing/Pause`, undefined, options);
};

export const getPutApiPricingAutoPricingPauseMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPricingAutoPricingPause>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiPricingAutoPricingPause>>, TError, void, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiPricingAutoPricingPause>>, void> = () => {
    return putApiPricingAutoPricingPause(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiPricingAutoPricingPauseMutationResult = NonNullable<Awaited<ReturnType<typeof putApiPricingAutoPricingPause>>>;

export type PutApiPricingAutoPricingPauseMutationError = AxiosError<void>;

export const usePutApiPricingAutoPricingPause = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPricingAutoPricingPause>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiPricingAutoPricingPause>>, TError, void, TContext> => {
  const mutationOptions = getPutApiPricingAutoPricingPauseMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiPricingAutoPricingResume = (options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Pricing/AutoPricing/Resume`, undefined, options);
};

export const getPutApiPricingAutoPricingResumeMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPricingAutoPricingResume>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiPricingAutoPricingResume>>, TError, void, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiPricingAutoPricingResume>>, void> = () => {
    return putApiPricingAutoPricingResume(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiPricingAutoPricingResumeMutationResult = NonNullable<Awaited<ReturnType<typeof putApiPricingAutoPricingResume>>>;

export type PutApiPricingAutoPricingResumeMutationError = AxiosError<void>;

export const usePutApiPricingAutoPricingResume = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPricingAutoPricingResume>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiPricingAutoPricingResume>>, TError, void, TContext> => {
  const mutationOptions = getPutApiPricingAutoPricingResumeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiPricingStatus = (options?: AxiosRequestConfig): Promise<AxiosResponse<string>> => {
  return axios.get(`/api/Pricing/Status`, options);
};

export const getGetApiPricingStatusQueryKey = () => {
  return [`/api/Pricing/Status`] as const;
};

export const getGetApiPricingStatusQueryOptions = <TData = Awaited<ReturnType<typeof getApiPricingStatus>>, TError = AxiosError<unknown>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPricingStatus>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiPricingStatusQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiPricingStatus>>> = ({ signal }) => getApiPricingStatus({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiPricingStatus>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiPricingStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPricingStatus>>>;
export type GetApiPricingStatusQueryError = AxiosError<unknown>;

export const useGetApiPricingStatus = <TData = Awaited<ReturnType<typeof getApiPricingStatus>>, TError = AxiosError<unknown>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPricingStatus>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPricingStatusQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
