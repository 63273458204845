import { Center, Flex, Text } from '@mantine/core';
import { useGlobalState } from '../../data/GlobalState';
import { BNAvatar } from '../../components/Avatar/Avatar';
import { useNotifications } from '../Notifications/Notifications.hooks';
import SearchIcon from '../../components/icons/Search';

export function NavBarCollapsed() {
  const { principal } = useGlobalState('principal');
  const { notificationCount } = useNotifications('notificationCount');
  return (
    <Flex
      h="100%"
      bg="var(--colors-paper)"
      px="md"
      className="sidebar-collapsed"
      direction="column"
      align="center"
      justify="space-between"
      pos="absolute"
      inset={0}
      w={52}
      style={{ zIndex: 0, borderRight: '1px solid var(--colors-divider)' }}
    >
      <Center h={52} w={52} style={{ borderBottom: '1px solid transparent' }}>
        <BNAvatar principal={principal} indicator={!!notificationCount} />
      </Center>
      <Center pos="absolute" top="calc(50% - 3.75rem)" w="7.5rem" style={{ transform: 'rotate(90deg) translateX(48px)' }}>
        <SearchIcon color="var(--colors-gray-5)" size={24} />
        <Text ml="xs" fz={11} lts="0.05em" c="var(--colors-gray-5)" tt="uppercase">
          Search
        </Text>
      </Center>
    </Flex>
  );
}
