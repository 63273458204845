import { useEffect, useState } from 'react';
import { ActionIcon, Collapse, Group, Paper, Text, UnstyledButton } from '@mantine/core';
import CloseIcon from '../../components/icons/Close';
import { useGetApiSupportContactsSelf, usePutApiSupportContactsSelfSendActivation } from '../../api';
import LiveHelpIcon from '../../components/icons/LiveHelp';
import { useAtomValue } from 'jotai';
import dayjs from 'dayjs';
import { auth } from '../../data/atoms.auth';
import { isDtiHosted } from '../../utils/whitelabel-consts';

export function SupportActivationBanner() {
  const [bannerOpen, setBannerOpen] = useState(false);
  const apiToken = useAtomValue(auth.apiTokenAtom);
  const { data: contact } = useGetApiSupportContactsSelf({
    query: {
      enabled: !!apiToken && !isDtiHosted, // Don't show the banner if we're in DTI
      select: (data) => data.data,
    },
  });

  useEffect(() => {
    if (contact !== undefined && !contact.active && contact.createdAt < dayjs().subtract(1, 'day').toDate()) {
      setBannerOpen(true);
    }
  }, [contact]);

  const { mutateAsync: resendActivation } = usePutApiSupportContactsSelfSendActivation();

  return (
    <Collapse in={bannerOpen} w="100%" ta="center">
      <Paper w="100%" bg="var(--colors-brandcolor-5)" c="var(--colors-paper)">
        <Group mih={36} align="center" justify="center" gap="xs">
          <LiveHelpIcon size={20} color="var(--colors-paper)" />
          <Text size="xs" c="var(--colors-paper)">
            You haven&apos;t activated your account in our Help Desk. You can{' '}
            <UnstyledButton fz="xs" c="var(--colors-paper)" fw={500} td="underline" onClick={() => resendActivation().then(() => setBannerOpen(false))}>
              click here
            </UnstyledButton>{' '}
            to re-send the activation email.
          </Text>
          <ActionIcon c="var(--colors-paper)" onClick={() => setBannerOpen(false)} pos="absolute" right={16}>
            <CloseIcon size={20} />
          </ActionIcon>
        </Group>
      </Paper>
    </Collapse>
  );
}
