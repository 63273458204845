import { Menu, SegmentedControl } from '@mantine/core';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import DragIndicatorIcon from '../../components/icons/DragIndicator';
import { UseListStateHandlers } from '@mantine/hooks';
import classes from './Inventory.SortItem.tsx.module.css';

export type SortItemDir = 'asc' | 'desc' | null | undefined;
export type SortItem = {
  key: string;
  name: string;
  dir: SortItemDir;
  sortIndex?: number | null | undefined;
};

export type SortItemWithIndex = SortItem & { index: number };

type SortItemProps = {
  updateHandler: UseListStateHandlers<Omit<SortItem, 'index'>>;
  item: SortItemWithIndex;
};
export function SortItem({ item, updateHandler }: SortItemProps) {
  return (
    <Draggable key={item.key} index={item.index} draggableId={item.key}>
      {(provided: DraggableProvided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Menu.Item
            {...provided.dragHandleProps}
            component="a"
            c={!item.dir ? 'var(--colors-gray-5)' : ''}
            leftSection={<DragIndicatorIcon color="var(--colors-gray-4)" size={20} />}
            style={{ textTransform: 'capitalize', borderBottom: '1px solid var(--colors-paperHover)' }}
            fz="xs"
            py={6}
            rightSection={
              <>
                {item.dir != null && (
                  <SegmentedControl
                    size="xs"
                    className={classes.segmentedControl}
                    onChange={(value) => {
                      updateHandler.setItemProp(item.index, 'dir', value as SortItemDir);
                    }}
                    value={item.dir}
                    data={[
                      { label: 'ASC', value: 'asc' },
                      { label: 'DESC', value: 'desc' },
                    ]}
                  />
                )}
                {item.dir == null && ''}
              </>
            }
          >
            {item.name}
          </Menu.Item>
        </div>
      )}
    </Draggable>
  );
}
