import { ActionIcon, Box, Collapse, Divider, Flex, Group, Indicator, Menu, Paper, Progress, ScrollArea, Text, Tooltip } from '@mantine/core';
import { DragDropContext, Draggable, DraggableProvided, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { BNButton } from '../../components/Button/Button';
import ResetIcon from '../../components/icons/Reset';
import SettingsIcon from '../../components/icons/Settings';
import { SortItem } from '../Inventory/Inventory.SortItem';
import { useDebouncedValue, useDidUpdate, useListState, useToggle } from '@mantine/hooks';
import classes from './Sales.styles.module.css';
import { ColumnPinnedType } from '@ag-grid-community/core';
import SelectArrowsIcon from '../../components/icons/SelectArrows';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { openConfirmModal } from '@mantine/modals';
import DragIndicatorIcon from '../../components/icons/DragIndicator';
import { BNSwitch } from '../../components/Switch/Switch';
import FileSaveIcon from '../../components/icons/FileSave';
import { onColumnMoved, onColumnSorted, useSales } from './Sales.hooks';
import pluralize from 'pluralize';
import { BNTextInput } from '../../components/TextInput/TextInput';
import FilterListIcon from '../../components/icons/FilterList';
import CloseIcon from '../../components/icons/Close';
import ArrowForwardIcon from '../../components/icons/ArrowForward';
import { AutoRefresh } from './Sales.AutoRefresh';
import { SalesSearchPillsArea } from './Sales.Grid.Header.PillsArea';

type ColumnSettingItem = {
  sortIndex?: number;
  key: string;
  name: string;
  visible: boolean; // TODO: Rename this to hide to match the grid eventually.
  width: number | undefined;
  pinned: ColumnPinnedType;
};

export function SalesGridHeader() {
  const {
    gridRef,
    gridReady,
    showSidebar,
    filteredSales,
    isRefetching,
    defaultColumnDefs,
    gridState,
    showSort,
    toggleShowSort,
    filter,
    setFilter,
    salesQuickFilter,
    setSalesQuickFilter,
    clearGridState,
  } = useSales(
    'gridRef',
    'gridReady',
    'showSidebar',
    'filteredSales',
    'isRefetching',
    'defaultColumnDefs',
    'gridState',
    'showSort',
    'toggleShowSort',
    'filter',
    'setFilter',
    'salesQuickFilter',
    'setSalesQuickFilter',
    'clearGridState',
  );
  const [listingSort, listingSortHandlers] = useListState<SortItem>([]);
  const [columnSettings, columnSettingsHandlers] = useListState<ColumnSettingItem>([]);
  const inventoryGridStateRef = useRef(gridState);
  const [enableUpdates, toggleUpdates] = useToggle();
  const isMenuOpened = useRef(false);
  const [columnSettingsDebounced] = useDebouncedValue(columnSettings, 250);
  const [listingSortDebounced] = useDebouncedValue(listingSort, 250);
  const actionIndex = columnSettings.findIndex((c) => c.key === 'action');

  const defaultColumnSettings = useMemo(
    () =>
      defaultColumnDefs
        .filter((x) => !x.exclude)
        .map(
          (x) =>
            ({
              key: x.field || x.colId!,
              name: x.headerName!,
              visible: !x.hide,
              width: x.width ?? undefined,
              pinned: x.colId === 'action' ? 'right' : undefined,
            }) satisfies ColumnSettingItem,
        ),
    [defaultColumnDefs],
  );

  const allowedSortingColumns = useMemo(
    () =>
      structuredClone(
        defaultColumnDefs
          .filter((x) => x.sortIndex != null || x.allowSorting !== false)
          .map((x) => ({
            key: x.field ?? x.colId!,
            name: x.headerName ?? x.field ?? x.colId!,
            dir: x.sort,
            sortIndex: x.sortIndex,
          })),
      ).sort((a, b) => {
        const aIndex = typeof a.sortIndex === 'number' ? a.sortIndex : Infinity;
        const bIndex = typeof b.sortIndex === 'number' ? b.sortIndex : Infinity;
        return aIndex - bIndex || (a.dir || '').localeCompare(b.dir || '') || a.name.localeCompare(b.name);
      }),
    [defaultColumnDefs],
  );

  const onMenuVisibilityChange = useCallback(() => {
    isMenuOpened.current = !isMenuOpened.current;
  }, []);

  const resetListingDefaults = useCallback(() => {
    listingSortHandlers.setState(allowedSortingColumns);
  }, [allowedSortingColumns, listingSortHandlers]);

  const resetColumnDefaults = useCallback(() => {
    columnSettingsHandlers.setState(defaultColumnSettings);
  }, [columnSettingsHandlers, defaultColumnSettings]);

  const resetAllDefaults = useCallback(() => {
    openConfirmModal({
      title: 'Reset confirmation',
      children: <Text size="sm">Are you sure you want to reset all the grid settings to their defaults?</Text>,
      labels: { confirm: 'Reset', cancel: 'Cancel' },
      confirmProps: { className: 'confirmButton', variant: 'filled', color: 'gray', size: 'sm' },
      cancelProps: { className: 'cancelButton', variant: 'default', size: 'sm' },
      closeButtonProps: { size: 'md' },
      onConfirm: () => {
        clearGridState();
        resetColumnDefaults();
        resetListingDefaults();
      },
    });
  }, [clearGridState, resetColumnDefaults, resetListingDefaults]);

  const processListingSort = useCallback(
    ({ initialRender }: { initialRender?: boolean } = { initialRender: false }) => {
      if (!gridRef.current || !gridRef.current.columnApi) return;
      const columnState = gridRef.current.columnApi.getColumnState();
      const columnSort = columnState
        .sort((x) => x.sortIndex || Infinity)
        .map((x) => {
          const columnDef = gridRef.current?.columnApi.getColumn(x.colId)?.getColDef();
          return {
            key: x.colId,
            name: columnDef?.headerName ?? columnDef?.field ?? '',
            dir: x.sort,
            sortIndex: x.sortIndex,
          } satisfies SortItem;
        });

      let finalListingSort = [...columnSort.filter((x) => allowedSortingColumns.map((y) => y.key.toLowerCase()).includes(x.key.toLowerCase()))];
      if (initialRender) {
        finalListingSort = structuredClone(
          finalListingSort.map((x) => {
            const savedState = inventoryGridStateRef.current?.find((y) => y.colId === x.key) || {};
            const { dir, sortIndex, ...rest } = allowedSortingColumns.find((y) => y.key === x.key) || {};
            return { ...x, ...rest, sort: dir, sortIndex, ...savedState };
          }),
        );
      }
      finalListingSort.sort((a, b) => {
        const aIndex = typeof a.sortIndex === 'number' ? a.sortIndex : Infinity;
        const bIndex = typeof b.sortIndex === 'number' ? b.sortIndex : Infinity;
        return aIndex - bIndex || (a.dir || '').localeCompare(b.dir || '') || a.name.localeCompare(b.name);
      });
      listingSortHandlers.setState(finalListingSort);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allowedSortingColumns, listingSortHandlers],
  );

  const processColumnSettings = useCallback(() => {
    if (!gridRef.current || !gridRef.current.columnApi) return;
    const ignoredColIds = defaultColumnDefs.filter((x) => x.exclude).map((x) => x.field || x.colId!);
    const columns = gridRef.current.columnApi.getColumns();
    const columnState = gridRef.current.columnApi.getColumnState()!;
    const _columnSettingItems = columnState
      .filter((col) => !ignoredColIds.includes(col.colId))
      .map((col) => {
        const columnDef = columns?.find((x) => x.getColId() === col.colId)?.getColDef();
        return {
          key: col.colId,
          name: columnDef?.headerName ?? columnDef?.field ?? '',
          visible: !col.hide,
          width: col.width,
          pinned: col.pinned,
          sortIndex: col.sortIndex || 0,
        };
      });
    columnSettingsHandlers.setState(_columnSettingItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnSettingsHandlers, defaultColumnDefs]);

  const onColumnMovedEvt = useCallback(() => {
    if (!isMenuOpened.current) {
      processColumnSettings();
    }
  }, [processColumnSettings]);

  const onColumnSortedEvt = useCallback(() => {
    if (!isMenuOpened.current) {
      processListingSort();
    }
  }, [processListingSort]);

  useDidUpdate(() => {
    if (enableUpdates) {
      const updateOrder = listingSortDebounced.map((x, i) => ({ colId: x.key, sort: x.dir ?? null, sortIndex: x.dir ? i : null }));
      gridRef.current?.columnApi.applyColumnState({
        state: updateOrder,
      });
    }
  }, [listingSortDebounced]);

  useDidUpdate(() => {
    if (enableUpdates) {
      gridRef.current?.columnApi.applyColumnState({
        applyOrder: true,
        state: [{ colId: 'dragCell' }, ...columnSettingsDebounced.map((x) => ({ colId: x.key, hide: !x.visible, width: x.width, pinned: x.pinned }))],
      });
    }
  }, [columnSettingsDebounced]);

  useEffect(() => {
    if (enableUpdates) {
      document.addEventListener(onColumnMoved.type, onColumnMovedEvt);
      document.addEventListener(onColumnSorted.type, onColumnSortedEvt);
    }
    return () => {
      document.removeEventListener(onColumnMoved.type, onColumnMovedEvt);
      document.removeEventListener(onColumnSorted.type, onColumnSortedEvt);
    };
  }, [enableUpdates, onColumnMovedEvt, onColumnSortedEvt]);

  useEffect(() => {
    if (gridReady && showSort) {
      processListingSort({ initialRender: true });
      processColumnSettings();

      setTimeout(() => toggleUpdates(true), 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridReady, showSort]);

  const columnSettingsItems = (columnSettings ?? []).map((item, index) => (
    <Draggable key={item.key} index={index} draggableId={item.key}>
      {(provided: DraggableProvided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Menu.Item
            {...provided.dragHandleProps}
            component="a"
            leftSection={<DragIndicatorIcon color="var(--colors-gray-4)" size={20} />}
            c={item.visible ? 'var(--colors-gray-9)' : 'var(--colors-gray-5)'}
            fz="xs"
            py={6}
            tt="capitalize"
            style={{ borderBottom: '1px solid var(--colors-paperHover)' }}
            rightSection={
              <BNSwitch
                size="xs"
                label={undefined}
                checked={item.visible}
                onClick={() => {
                  columnSettingsHandlers.setItemProp(index, 'visible', !item.visible);
                }}
              />
            }
            data-pendo-item={`Column Settings - ${item.name} Switch`}
          >
            {item.name}
          </Menu.Item>
        </div>
      )}
    </Draggable>
  ));

  return (
    <Paper pos="relative" bg="var(--colors-gray-0)">
      {/* Progress bar to display while data is updating */}
      {isRefetching && (
        <Progress radius={0} value={100} animated color="var(--colors-gray-4)" size={4} pos="absolute" bottom={0} left={0} right={0} className={classes.progressBar} />
      )}
      <Group pr="xs" className={classes.headerWrapper} gap={0} justify="space-between" bg="var(--colors-paper)">
        <Flex gap="xs" h="100%" align="center" style={{ overflow: 'hidden' }}>
          <ScrollArea
            h="100%"
            display="flex"
            scrollbars="x"
            type="always"
            scrollbarSize={6}
            style={{ alignItems: 'center' }}
            styles={{ viewport: { display: 'flex', alignItems: 'center' } }}
            classNames={{ scrollbar: classes.filterScrollbar }}
          >
            {showSidebar && (
              <Text pl="md" c="var(--colors-gray-5)">
                {`Showing ${filteredSales?.length.toLocaleString()} ${pluralize('Sale', filteredSales?.length)}`}
              </Text>
            )}
            {!showSidebar && <SalesSearchPillsArea />}
          </ScrollArea>
        </Flex>
        <Group gap="xs">
          <Divider color="var(--colors-divider)" orientation="vertical" h={52} my="auto" mr={8} />
          <BNTextInput
            id="filter-text-box"
            leftSection={
              salesQuickFilter ? (
                <Indicator color="var(--colors-selectedBorder)" display="flex" size={4} offset={1}>
                  <FilterListIcon color="var(--colors-selectedBorder)" />
                </Indicator>
              ) : (
                <FilterListIcon />
              )
            }
            value={filter}
            size="xs"
            onChange={(e) => {
              setFilter(e.currentTarget.value);
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                setSalesQuickFilter(filter);
              } else if (e.key === 'Escape') {
                setFilter('');
                setSalesQuickFilter('');
              }
            }}
            data-pendo-name="Sales Quick Filter Input"
            placeholder="Filter Sales"
            aria-label="Filter Sales"
            className={salesQuickFilter ? `${classes.showRightSection}` : `${classes.clearableInputAndGo}`}
            rightSectionWidth={57}
            rightSection={
              <Group wrap="nowrap" gap={4} px="xs">
                <ActionIcon
                  size="sm"
                  className="clearButton"
                  onClick={() => {
                    setFilter('');
                    setSalesQuickFilter('');
                  }}
                >
                  <CloseIcon />
                </ActionIcon>
                <ActionIcon variant="filled" size="sm" onClick={() => setSalesQuickFilter(filter)}>
                  <ArrowForwardIcon color="var(--colors-paper)" size={20} />
                </ActionIcon>
              </Group>
            }
          />
          <Divider color="var(--colors-gray-2)" orientation="vertical" h={20} my="auto" ml={8} />
          <AutoRefresh />
          <Divider color="var(--colors-gray-2)" orientation="vertical" h={20} my="auto" />
          <Tooltip label="Export (.xlsx)" position="top" withArrow>
            <ActionIcon data-pendo-name="Sales Grid Export Button" onClick={() => gridRef.current?.api.exportDataAsExcel()}>
              <FileSaveIcon size={22} />
            </ActionIcon>
          </Tooltip>
          <Divider color="var(--colors-gray-2)" orientation="vertical" h={20} my="auto" />
          <Tooltip label="Grid Settings" position="top" withArrow>
            <ActionIcon data-pendo-name="Sales Grid Settings Button" onClick={() => toggleShowSort()} className={showSort ? classes.settingsButtonActive : classes.settingsButton}>
              <SettingsIcon size={20} color={showSort ? 'var(--colors-selectedBorder)' : undefined} />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Group>
      {/* Settings shelf */}
      <Collapse in={showSort} transitionTimingFunction="ease-in-out">
        <Box px="xs" className={classes.settingsShelf}>
          <Flex wrap="nowrap" p={0} gap={0} align="center" justify="space-between" maw="100%" w="100%">
            <Flex align="center" wrap="nowrap" pl={0} pr={0} gap={0} maw="62.5%" style={{ flex: 1 }}>
              <Menu withArrow arrowPosition="center" width={240} closeOnItemClick={false} onChange={onMenuVisibilityChange}>
                <Menu.Target>
                  <BNButton size="xs" px={6} variant="subtle" maw="33%" className={classes.dropBtn} rightSection={<SelectArrowsIcon />}>
                    <Text truncate>Sales Sorting</Text>
                  </BNButton>
                </Menu.Target>
                <DragDropContext
                  onDragEnd={({ destination, source }) => {
                    listingSortHandlers.setState((prevState) => {
                      const newState = structuredClone(prevState);
                      if (destination?.droppableId === 'unsorted') {
                        newState[source.index].dir = null;
                      } else {
                        newState[source.index].dir = newState[source.index].dir || 'asc';
                      }
                      const moveItem = newState.splice(source.index, 1)[0];
                      newState.splice(destination?.index || 0, 0, moveItem);
                      return newState
                        .map((item, index) => ({ ...item, index }))
                        .sort((a, b) => {
                          if (!a.dir && !b.dir) {
                            return a.name.localeCompare(b.name);
                          }
                          return a.index - b.index;
                        });
                    });
                  }}
                >
                  <Menu.Dropdown p={4} className={classes.menuDropdown}>
                    <Droppable droppableId="sorted" direction="vertical">
                      {(provided: DroppableProvided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {listingSort
                            .map((item, index) => ({ ...item, index }))
                            .filter((item) => item.dir != null)
                            .map((item) => (
                              <SortItem key={item.key} item={item} updateHandler={listingSortHandlers} />
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <Menu.Divider
                      my={4}
                      mx={-4}
                      style={{
                        borderTop: '2px solid var(--colors-gray-3)',
                        borderBottom: '4px solid var(--colors-paperHover)',
                      }}
                    />
                    <Droppable droppableId="unsorted" direction="vertical">
                      {(provided: DroppableProvided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{
                            maxHeight: 400,
                            overflowY: 'scroll',
                          }}
                        >
                          {listingSort
                            .map((item, index) => ({ ...item, index }))
                            .filter((item) => item.dir == null)
                            .map((item) => (
                              <SortItem key={item.key} item={item} updateHandler={listingSortHandlers} />
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <Divider color="var(--colors-divider)" my={4} mx={-4} />
                    <Box>
                      <BNButton leftSection={<ResetIcon />} size="xs" variant="subtle" className={classes.resetBtn} fullWidth onClick={resetListingDefaults}>
                        Reset to Default
                      </BNButton>
                    </Box>
                  </Menu.Dropdown>
                </DragDropContext>
              </Menu>
              <Menu withArrow arrowPosition="center" width={240} closeOnItemClick={false} onChange={onMenuVisibilityChange}>
                <Menu.Target>
                  <BNButton size="xs" px={6} variant="subtle" maw="33%" className={classes.dropBtn} rightSection={<SelectArrowsIcon />}>
                    <Text truncate>Column Settings</Text>
                  </BNButton>
                </Menu.Target>
                <DragDropContext
                  onDragEnd={({ destination, source }) =>
                    columnSettingsHandlers.reorder({
                      from: source.index,
                      to: destination?.index || 0,
                    })
                  }
                >
                  <Menu.Dropdown p={4} className={classes.menuDropdown}>
                    <Droppable droppableId="key" direction="vertical">
                      {(provided: DroppableProvided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{
                            maxHeight: 400,
                            overflowY: 'scroll',
                          }}
                        >
                          {columnSettingsItems}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <Divider color="var(--colors-divider)" my={4} mx={-4} />
                    <Box>
                      <BNButton leftSection={<ResetIcon />} size="xs" variant="subtle" className={classes.resetBtn} fullWidth onClick={resetColumnDefaults}>
                        Reset to Default
                      </BNButton>
                    </Box>
                  </Menu.Dropdown>
                </DragDropContext>
              </Menu>
            </Flex>
            <Flex wrap="nowrap" justify="end" maw="37.5%" w="100%" gap={0}>
              <Group darkHidden lightHidden wrap="nowrap" p={0} pr={4} gap={0} className={classes.pinSwitchWrapper}>
                <BNSwitch
                  size="xs"
                  colorLabel
                  label="Pin action menu"
                  outlined
                  checked={columnSettings[actionIndex]?.pinned === 'right'}
                  onClick={() => {
                    columnSettingsHandlers.setItemProp(actionIndex, 'pinned', columnSettings[actionIndex]?.pinned === 'right' ? null : 'right');
                  }}
                  data-pendo-name="Pin Action Menu Switch"
                />
              </Group>
              <BNButton onClick={resetAllDefaults} size="xs" variant="subtle" color="gray" className={classes.resetDropBtn} leftSection={<ResetIcon />}>
                <span className="show-medium">Reset</span>
                <span className="show-large">&nbsp;to Default</span>
              </BNButton>
            </Flex>
          </Flex>
        </Box>
      </Collapse>
    </Paper>
  );
}
