import { Box, Card, CardSection, Group, Text, Tooltip } from '@mantine/core';
import { BarkerCoreModelsInventoryEvent } from '../../api';
import { useSearchResults } from './SearchResults.hooks';
import { DateFormats } from '../../utils/globals';
import { ListingCostIconLabel } from '../../components/Label/ListingCostLabel';
import { OpenlistingsLabel } from '../../components/Label/OpenListingsLabel';
import { OpenticketsLabel } from '../../components/Label/OpenTicketsLabel';
import { useMemo } from 'react';
import { formatDate } from '../../utils/formatters';
import { LastSoldLabel } from '../../components/Label/LastSoldLabel';
import { useDelayedValue } from '../../utils/use-delayed-value';
import classes from './SearchResults.EventCardColors.module.css';
import cx from 'clsx';
import { TenantIdEventId } from '../../models/tenantIdListingId';

export function EventCardColors({
  event,
  isSelected,
  isMultiTenant,
  tenantColor,
  tenantName,
}: {
  event: BarkerCoreModelsInventoryEvent;
  isSelected: boolean;
  isMultiTenant?: boolean;
  tenantColor: string;
  tenantName: string;
}) {
  const { onEventClicked, isLoadingEventId } = useSearchResults('onEventClicked', 'isLoadingEventId');
  const [isLoadingEventIdDebounced] = useDelayedValue(isLoadingEventId, 500);

  // Set tenant color name by searching through color arrays
  const tenantColorName = useMemo(() => {
    if (!event || !isMultiTenant) return 'var(--colors-brandcolor-5)';

    // List all possible tenant colors from previous options
    const tenantRed = ['#C92A2A', '#F03E3E', '#FF6B6B', '#FFA8A8', 'var(--colors-tenant-red-5)', 'red'];
    const tenantOrange = ['#D9480F', '#F76707', '#FF922B', '#FFC078', 'var(--colors-tenant-orange-5)', 'orange'];
    const tenantYellow = ['#E67700', '#F59F00', '#FCC419', '#FFE066', 'var(--colors-tenant-yellow-5)', 'yellow'];
    const tenantGreen = ['#2B8A3E', '#37B24D', '#51CF66', '#8CE99A', 'var(--colors-tenant-green-5)', 'green'];
    const tenantCyan = ['#0B7285', '#1098AD', '#22B8CF', '#66D9E8', 'var(--colors-tenant-cyan-5)', 'cyan'];
    const tenantBlue = ['#1864AB', '#1C7ED6', '#339AF0', '#74C0FC', 'var(--colors-tenant-blue-5)', 'blue'];
    const tenantGrape = ['#862E9C', '#AE3EC9', '#CC5DE8', '#E599F7', 'var(--colors-tenant-grape-5)', 'grape'];
    const tenantPink = ['#A61E4D', '#D6336C', '#F06595', '#FAA2C1', 'var(--colors-tenant-pink-5)', 'pink'];
    const tenantBrandColor = ['#3d8161', '#63b187', 'var(--colors-brandgreen-5)', 'brandgreen', 'var(--colors-brandcolor-5)', 'brandcolor'];
    const tenantBlack = ['#000000', 'black'];
    const tenantGray4 = ['#333333', 'gray4'];
    const tenantGray3 = ['#666666', 'gray3'];
    const tenantGray2 = ['#999999', 'gray2'];
    const tenantGray1 = ['#E0E0E0', 'gray1'];

    if (tenantColor) {
      if (tenantBrandColor.includes(tenantColor)) {
        return 'brandcolor';
      }
      if (tenantRed.includes(tenantColor)) {
        return 'red';
      }
      if (tenantOrange.includes(tenantColor)) {
        return 'orange';
      }
      if (tenantYellow.includes(tenantColor)) {
        return 'yellow';
      }
      if (tenantGreen.includes(tenantColor)) {
        return 'green';
      }
      if (tenantCyan.includes(tenantColor)) {
        return 'cyan';
      }
      if (tenantBlue.includes(tenantColor)) {
        return 'blue';
      }
      if (tenantGrape.includes(tenantColor)) {
        return 'grape';
      }
      if (tenantPink.includes(tenantColor)) {
        return 'pink';
      }
      if (tenantBlack.includes(tenantColor)) {
        return 'black';
      }
      if (tenantGray4.includes(tenantColor)) {
        return 'gray4';
      }
      if (tenantGray3.includes(tenantColor)) {
        return 'gray3';
      }
      if (tenantGray2.includes(tenantColor)) {
        return 'gray2';
      }
      if (tenantGray1.includes(tenantColor)) {
        return 'gray1';
      }
    }
    return 'brandcolor';
  }, [event, isMultiTenant, tenantColor]);

  const tenantClassName = isMultiTenant ? `tenant-${tenantColorName}` : 'tenant-single';

  return (
    <Card
      radius={0}
      m={0}
      padding={0}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        onEventClicked(event, e);
      }}
      className={isSelected ? `selected ${classes.card} ${tenantClassName}` : `${classes.card} ${tenantClassName}`}
    >
      {isMultiTenant && (
        <Tooltip
          position="right"
          label={
            <Group wrap="nowrap" gap={8}>
              <Text>{tenantName}</Text>
              <Box className={cx([classes.tenantDot, `tenant-${tenantColorName}`])} h={13} w={13} pos="relative" />
            </Group>
          }
          offset={2}
          withArrow
        >
          <Box className={`${classes.sideStripe} stripe`} />
        </Tooltip>
      )}
      {isSelected && <Box className={`${classes.arrow} selected-arrow`} />}
      <CardSection p={16} pt={12} pb={0} style={{ zIndex: 1 }}>
        <Text className="date-text" size="xs" fw={700} tt="uppercase" ml={0}>
          {formatDate(event.localDateTime, DateFormats.Standard)}
        </Text>
        <Text mt={6} size="sm" fw={600} className={classes.name} lineClamp={2}>
          {event.name}
        </Text>
        <Group className={classes.venue}>
          <Text className={classes.venueName} size="xs" fw={500}>
            {event.venue?.name}
          </Text>
          <Text className={classes.venueText} size="xs" fw={500}>
            &nbsp;·&nbsp;{`${event.venue?.city}, ${event.venue?.state}`}
          </Text>
        </Group>
      </CardSection>
      <CardSection p={16} pt={0} pb={12} className={classes.statsWrapper}>
        <Group p={0} m={0} className={classes.stats}>
          <ListingCostIconLabel cost={event.openCost} skeleton={isLoadingEventIdDebounced?.toString() === TenantIdEventId.create(event.tenantId, event.eventId).toString()} />
          <OpenlistingsLabel listings={event.openListings} skeleton={isLoadingEventIdDebounced?.toString() === TenantIdEventId.create(event.tenantId, event.eventId).toString()} />
          <OpenticketsLabel tickets={event.openTickets} skeleton={isLoadingEventIdDebounced?.toString() === TenantIdEventId.create(event.tenantId, event.eventId).toString()} />
          <LastSoldLabel lastSoldAt={event.lastSoldAt} skeleton={isLoadingEventIdDebounced?.toString() === TenantIdEventId.create(event.tenantId, event.eventId).toString()} />
        </Group>
      </CardSection>
    </Card>
  );
}
