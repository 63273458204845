import { useDTIMarketplace } from '../DTI/DTI.Marketplace.hooks';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import MobileDataOffIcon from '../../components/icons/MobileDataOff';
import classes from '../MarketListings/MarketListings.styles.tsx.module.css';
import { Box, Loader, Stack } from '@mantine/core';
import { useMemo } from 'react';
import { useResizeObserver } from '@mantine/hooks';
import { FixedSizeList } from 'react-window';
import { SalesCard } from './MarketplaceResults.SalesCard';

export function MarketplaceResultsSalesList() {
  const { sales, isFetchingSeatGeek } = useDTIMarketplace('sales', 'isFetchingSeatGeek');
  const [parentRef, parentRect] = useResizeObserver();

  function CustomNoRowsOverlay() {
    return (
      <BNEmptyState
        h="90%"
        border={false}
        icon={<MobileDataOffIcon color="var(--colors-iconFill)" size={28} />}
        iconBg="var(--colors-opacity-hover)"
        title="No Results Found"
        description="Try adjusting the filter options"
      />
    );
  }

  function CustomLoadingOverlay() {
    return (
      <div className={`${classes.customLoadingOverlay} custom-loading-overlay`}>
        <Loader color="var(--colors-gray-5)" type="dots" />
      </div>
    );
  }

  const Row = useMemo(
    () =>
      ({ index, style }: { index: number; style: React.CSSProperties }) => {
        const sale = sales[index];

        return (
          <Box style={style} className={classes.listingCardDeliveryWrapper}>
            <SalesCard saleDate={sale.sale_date} quantity={sale.quantity} section={sale.section} row={sale.row ?? 'GA'} price={sale.price} />
          </Box>
        );
      },
    [sales],
  );

  return (
    <Box className={classes.outerWrapper}>
      <Stack gap={0} className={classes.innerWrapper}>
        <Box className={`${classes.listWrapper} market-results-wrapper`} pos="relative" ref={parentRef}>
          {isFetchingSeatGeek && CustomLoadingOverlay()}
          {!isFetchingSeatGeek && sales.length === 0 && CustomNoRowsOverlay()}
          {!isFetchingSeatGeek && sales.length > 0 && (
            <FixedSizeList className="market-results" height={parentRect?.height || 0} itemCount={sales.length} itemSize={50} overscanCount={10} layout="vertical" width="100%">
              {Row}
            </FixedSizeList>
          )}
        </Box>
      </Stack>
    </Box>
  );
}
