import { useMemo, useRef, useState } from 'react';
import { SimpleKeyboard } from 'react-simple-keyboard';
import { Box, Center, Divider, Flex, Group, Modal, Text } from '@mantine/core';
import classes from './MobileNumberInputModal.module.css';
import { BNButton } from '../../components/Button/Button';
import KeyboardWrapper from '../../components/Keyboard/KeyboardWrapper';
import 'react-simple-keyboard/build/css/index.css';
import '../../components/Keyboard/keyboard.css';
import { formatCurrency, formatNumber } from '../../utils/formatters';
import ArrowForwardIcon from '../../components/icons/ArrowForward';
import WarningIcon from '../../components/icons/Warning';
import { parseCurrency } from '../../utils/price-utils';

export function MobileNumberInputModal({
  cancel,
  save,
  initialInput,
  disabled,
  handleKeyboardInput,
  cost,
  drasticAmount,
}: {
  cancel: () => void;
  save: () => void;
  initialInput: string;
  disabled?: boolean;
  handleKeyboardInput: (input: string) => void;
  cost: number;
  drasticAmount?: number;
}) {
  const keyboardRef = useRef<SimpleKeyboard>(null);

  function handleInput(input: string) {
    if (!keyboardRef.current) {
      return;
    }

    let finalInput = input;

    const dotButton = keyboardRef.current.getButtonElement('.');

    if (dotButton && !Array.isArray(dotButton)) {
      if (finalInput.includes('.')) {
        if (!dotButton.getAttribute('disabled')) {
          dotButton.setAttribute('disabled', 'true');
          dotButton.style.pointerEvents = 'none';
        }

        const parts = finalInput.split('.');
        if (parts[1].length > 2) {
          finalInput = `${parts[0]}.${parts[1].substring(0, 2)}`;
          handleKeyboardInput(finalInput);
          keyboardRef.current.replaceInput({ default: finalInput });
          keyboardRef.current.setCaretPosition(0, finalInput.length);
        }
      } else if (dotButton.getAttribute('disabled')) {
        dotButton.removeAttribute('disabled');
        dotButton.style.pointerEvents = 'auto';
      }
    }

    handleKeyboardInput(finalInput);
  }

  const backspaceSvg =
    '<svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 -960 960 960" width="36"><path fill="currentcolor" d="M367.71-220.001q-21.923 0-40.367-10.539-18.445-10.538-31.035-28.385L140.001-480l156.307-220.306q12.59-17.846 31-28.769 18.41-10.924 40.385-10.924h389.742q25.705 0 44.134 18.43 18.43 18.429 18.43 44.134v394.87q0 25.705-18.43 44.134-18.429 18.43-44.134 18.43H367.71Zm402.034-50.255V-689.744v419.488Zm-407.297 0h394.988q4.616 0 8.462-3.847 3.847-3.846 3.847-8.462v-394.87q0-4.616-3.847-8.462-3.846-3.847-8.462-3.847H362.411q-5.77 0-10.963 2.885-5.192 2.885-8.269 7.116L202.153-480l141.062 199.743q3.077 4.231 8.269 7.116 5.193 2.885 10.963 2.885Zm82.835-66.566 107.794-107.794 107.795 107.794 36.051-35.793L588.46-480l107.385-107.385-35.794-35.793-106.975 107.794-107.794-107.794-35.794 35.793L517.693-480 409.488-372.615l35.794 35.793Z"/></svg>';

  const [showConfirmDrasticChange, setShowConfirmDrasticChange] = useState(false);

  const percentChange = useMemo(() => {
    if (!drasticAmount) {
      return 0;
    }

    const value = parseCurrency(initialInput);
    const change = drasticAmount - value;
    return Math.round((change / value) * 100);
  }, [drasticAmount, initialInput]);

  return (
    <Modal
      opened
      overlayProps={{ opacity: 0 }}
      closeOnClickOutside
      onClose={cancel}
      withCloseButton={false}
      className={classes.bottomSheetModal}
      transitionProps={{ transition: 'slide-up' }}
    >
      {drasticAmount !== undefined ? (
        showConfirmDrasticChange ? (
          <Flex className={classes.confirmMessage} direction="column" justify="center" align="center" pb="md">
            <Flex
              align="center"
              gap="xs"
              justify="center"
              w="100%"
              c="var(--colors-paper)"
              bg="var(--colors-yellow-warning)"
              ta="center"
              py={4}
              style={{ borderBottom: '1px solid var(--colors-opacity-hover)', borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
            >
              <Center h={24} w={24} style={{ borderRadius: 100 }}>
                <WarningIcon size={24} color="var(--colors-paper)" />
              </Center>
              <Text fz={13} fw={600} style={{ textShadow: '0 0 16px rgba(0,0,0,0.2)' }}>
                This is a large price change.
              </Text>
            </Flex>
            <Group gap={0} ta="center" pt="xs">
              <Text size="md">{formatCurrency(parseCurrency(initialInput))}</Text>
              <ArrowForwardIcon color="var(--colors-gray-4)" />
              <Text size="md">{formatCurrency(drasticAmount)}</Text>
            </Group>
            <Text fz="xs" c="gray.5">
              {parseFloat(initialInput) === 0 ? `Cost is ${formatCurrency(cost)}` : `${formatNumber(Math.abs(percentChange))}% ${percentChange > 0 ? 'Increase' : 'Decrease'}`}
            </Text>
            <Divider w="100%" color="var(--colors-divider)" mt={12} />
            <Flex gap="sm" w="100%" pt={12} pb={0} px="md">
              <BNButton fullWidth variant="default" onClick={save} c="var(--colors-paperReverse)">
                Confirm
              </BNButton>
              <BNButton fullWidth variant="default" onClick={cancel} c="var(--colors-paperReverse)">
                Cancel
              </BNButton>
            </Flex>
          </Flex>
        ) : (
          <Flex gap="sm" w="100%" py={24} px="md">
            <BNButton fullWidth variant="default" onClick={cancel}>
              Cancel
            </BNButton>
            <BNButton fullWidth variant="filled" color="green" disabled={disabled} onClick={() => setShowConfirmDrasticChange(true)}>
              Save
            </BNButton>
          </Flex>
        )
      ) : (
        <Flex gap="sm" w="100%" py={24} px="md">
          <BNButton fullWidth variant="default" onClick={cancel}>
            Cancel
          </BNButton>
          <BNButton fullWidth variant="filled" color="green" disabled={disabled} onClick={save}>
            Save
          </BNButton>
        </Flex>
      )}
      <KeyboardWrapper
        keyboardRef={keyboardRef}
        layout={{
          default: ['1 2 3', '4 5 6', '7 8 9', '. 0 {bksp}'],
        }}
        display={{
          '{bksp}': backspaceSvg,
        }}
        useButtonTag
        onChange={(input: string) => {
          handleInput(input);
        }}
        onInit={(keyboard) => {
          keyboard.setInput(initialInput);
          keyboard.setCaretPosition(0, initialInput.length);
        }}
      />
      <Box className={classes.bottomPaddingBox} h="calc(2rem + var(--safe-area-inset-bottom))" w="100%" />
    </Modal>
  );
}
