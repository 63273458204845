import { HeaderAction } from '../hoc/Admin/HeaderAction';
import { Divider, Group, Text } from '@mantine/core';
import { InviteModal } from '../hoc/InviteModal/InviteModal';
import { useEffect } from 'react';
import { useGlobalState } from '../data/GlobalState';
import { useNavigate } from 'react-router-dom';
import { isDtiHosted, supportEmail } from '../utils/whitelabel-consts';

export function WaitingRoom() {
  const { tenants } = useGlobalState('tenants');
  const navigate = useNavigate();

  const supportName = isDtiHosted ? 'DTI Management' : 'Broker Nerds';

  useEffect(() => {
    if (tenants && tenants?.length > 0) {
      navigate('/');
    }
  }, [tenants, navigate]);

  return (
    <>
      <HeaderAction links={[]} />
      <InviteModal />
      <Group pb={48} px={24} w="100%" h="100%" maw="1280px" mx="auto" style={{ gap: 32, flex: 0, display: 'flex', alignItems: 'start' }}>
        <div className="content-page" style={{ textAlign: 'left', width: '100%', maxWidth: '60ch', marginLeft: 'auto', marginRight: 'auto' }}>
          <h1>Account Not Found</h1>
          <Text size="lg" bg="var(--colors-gray-1)" p={24} px={24} style={{ borderRadius: 3 }}>
            You either do not have an account, or you may have logged in with a different account by mistake. Please use the menu in the top-right to log out.
          </Text>
          <Text my="xl">
            If you believe this is an error, please contact support at <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
          </Text>
          <Divider color="var(--colors-gray-1)" variant="dotted" />
          <p>{supportName}</p>
        </div>
      </Group>
    </>
  );
}
