import { Group, Text, useComputedColorScheme } from '@mantine/core';
import { useResizeObserver } from '@mantine/hooks';
import React, { useMemo } from 'react';
import { FixedSizeList } from 'react-window';
import SearchIcon from '../../components/icons/Search';
import BNOrderSelect from '../../components/Select/OrderSelect';
import { useSearchResults } from './SearchResults.hooks';
import pluralize from 'pluralize';
import { useGlobalState } from '../../data/GlobalState';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import classes from './Search.styles.tsx.module.css';
import { EventCardColors } from './SearchResults.EventCardColors';
import { EventCardSkeleton } from './SearchResults.EventCardSkeleton';

export function SearchResults() {
  const {
    sortedData: data,
    isLoading,
    sort,
    sortOptions,
    setSort,
    selectedEvents,
    sortOrder,
    setSortOrder,
  } = useSearchResults('sortedData', 'isLoading', 'sort', 'sortOptions', 'setSort', 'selectedEvents', 'sortOrder', 'setSortOrder');
  // const parentRef = useRef<HTMLDivElement>(null);
  const [parentRef, parentRect] = useResizeObserver();
  const [isSortOptionsOpen, setIsSortOptionsOpen] = React.useState(false);
  // https://mantine.dev/hooks/use-resize-observer/
  const { principal, tenants } = useGlobalState('principal', 'tenants');

  const Row = useMemo(
    () =>
      ({ index, style }: { index: number; style: React.CSSProperties }) => {
        const event = data[index];
        const isSelected = data.length !== selectedEvents.length && selectedEvents.map((x) => x.tenantId + x.eventId).includes(event.tenantId + event.eventId);
        const tenantName = tenants?.find((t) => t.tenantId === event?.tenantId)?.name || 'Unknown';
        const tenantColor = principal?.settings?.tenantColors[event.tenantId] ?? 'var(--colors-brandcolor-5)';
        const isMultiTenant = tenants && tenants.length > 1;

        return (
          <div style={style}>
            <EventCardColors event={event} isSelected={isSelected} tenantName={tenantName} tenantColor={tenantColor} isMultiTenant={isMultiTenant} />
          </div>
        );
      },
    [data, principal?.settings?.tenantColors, selectedEvents, tenants],
  );

  return (
    <>
      <Group className={`resultsArea ${classes.wrapper}`} justify="space-between" px={16} py={0}>
        <Group align="center" gap={2}>
          <Text className={classes.selectLabel} c="var(--colors-gray-5)" fz={11}>
            Sort:
          </Text>
          <BNOrderSelect
            data={sortOptions.map((x) => x)}
            dropdownWidth={140}
            selectedItem={sort}
            isOpen={isSortOptionsOpen}
            sortOrder={sortOrder}
            onClose={() => setIsSortOptionsOpen(false)}
            onClick={() => setIsSortOptionsOpen(!isSortOptionsOpen)}
            onSortOrderChange={(value: 'asc' | 'desc') => {
              setSortOrder(value);
              setIsSortOptionsOpen(false);
            }}
            onSelectionChange={(value) => {
              setSort(value as (typeof sortOptions)[number]);
              setIsSortOptionsOpen(false);
            }}
          />
        </Group>
        <Group>
          <Text fz={11} c="gray.5" tt="uppercase">
            {data ? data.length : 0} {pluralize('Event', data ? data.length : 0)}
          </Text>
        </Group>
      </Group>
      <div className={classes.searchResultsOuterWrap} ref={parentRef}>
        <div className={classes.searchResultsInnerWrap}>
          {data && data.length > 0 ? (
            <FixedSizeList className="searchResultsList" height={parentRect.height || 0} itemCount={data.length} itemSize={128} overscanCount={10} layout="vertical" width="100%">
              {Row}
            </FixedSizeList>
          ) : isLoading ? (
            <SearchResults.EventCardSkeleton />
          ) : (
            <BNEmptyState
              h="90%"
              border={false}
              icon={<SearchIcon color="var(--colors-iconFill)" size={28} />}
              title="No Events Found"
              description="Try adjusting the search options"
            />
          )}
        </div>
      </div>
    </>
  );
}

SearchResults.EventCardSkeleton = EventCardSkeleton;
