import { IconPropsBase } from './IconPropsBase';

export default function QrCodeScannerIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M100-679.23V-860h180.77v60H160v120.77h-60ZM100-100v-180.77h60V-160h120.77v60H100Zm579.23 0v-60H800v-120.77h60V-100H679.23ZM800-679.23V-800H679.23v-60H860v180.77h-60ZM688.85-271.15h54.23v54.23h-54.23v-54.23Zm0-108.47h54.23v54.23h-54.23v-54.23Zm-54.24 54.23h54.24v54.24h-54.24v-54.24Zm-54.23 54.24h54.23v54.23h-54.23v-54.23Zm-54.23-54.24h54.23v54.24h-54.23v-54.24Zm108.46-108.46h54.24v54.23h-54.24v-54.23Zm-54.23 54.23h54.23v54.23h-54.23v-54.23Zm-54.23-54.23h54.23v54.23h-54.23v-54.23Zm216.93-309.23v216.93H526.15v-216.93h216.93ZM433.85-433.85v216.93H216.92v-216.93h216.93Zm0-309.23v216.93H216.92v-216.93h216.93Zm-47.7 478.46v-121.53H264.62v121.53h121.53Zm0-309.23v-121.53H264.62v121.53h121.53Zm309.23 0v-121.53H573.85v121.53h121.53Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M116-671.23V-844h172.77v52H168v120.77h-52ZM116-116v-172.77h52V-168h120.77v52H116Zm555.23 0v-52H792v-120.77h52V-116H671.23ZM792-671.23V-792H671.23v-52H844v172.77h-52ZM660.85-299.15h42.23v42.23h-42.23v-42.23Zm0-84.47h42.23v42.23h-42.23v-42.23Zm-42.24 42.23h42.24v42.24h-42.24v-42.24Zm-42.23 42.24h42.23v42.23h-42.23v-42.23Zm-42.23-42.24h42.23v42.24h-42.23v-42.24Zm84.46-84.46h42.24v42.23h-42.24v-42.23Zm-42.23 42.23h42.23v42.23h-42.23v-42.23Zm-42.23-42.23h42.23v42.23h-42.23v-42.23Zm192.93-301.23v192.93H534.15v-192.93h192.93ZM425.85-425.85v192.93H232.92v-192.93h192.93Zm0-301.23v192.93H232.92v-192.93h192.93Zm-35.7 458.46v-121.53H268.62v121.53h121.53Zm0-301.23v-121.53H268.62v121.53h121.53Zm301.23 0v-121.53H569.85v121.53h121.53Z"
        />
      </svg>
    );
  }
}
