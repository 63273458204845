import { Box, Center, Flex, Text, UnstyledButton } from '@mantine/core';
import classes from './ViewSwitcher.module.css';
import cx from 'clsx';
import ViewDefaultIllustration from '../icons/ViewDefault';
import TableRowsIcon from '../icons/TableRows';
import ViewSplitIllustration from '../icons/ViewSplit';
import ViewAgendaIcon from '../icons/ViewAgenda';
import { PricerView } from '../../types';

type BNViewSwitcherProps = {
  selectedView: PricerView;
  onChange: (newValue: PricerView) => void;
  h?: number | null;
  w?: number | null;
};

const viewOptions = [
  {
    image: <ViewDefaultIllustration />,
    icon: <TableRowsIcon />,
    title: 'Default',
    description: 'View event search results and all of their listings in a single scrollable grid. The left sidebar allows for selecting a single event.',
    value: PricerView.Standard,
  },
  {
    image: <ViewSplitIllustration />,
    icon: <ViewAgendaIcon />,
    title: 'Split',
    description: 'View event search results in the top grid, and listings in the bottom grid. Listings are only displayed for the selected event.',
    value: PricerView.Split,
  },
];

export function BNViewSwitcher({ selectedView, onChange, h, w }: BNViewSwitcherProps) {
  return (
    <Flex gap="xl" align="start">
      {viewOptions.map(({ value, image, icon, description, title }) => {
        return (
          <UnstyledButton key={value} className={classes.viewButtonWrapper} onClick={() => onChange(value)} w={w || 282}>
            <Box className={cx(classes.viewButton, selectedView === value && classes.viewSelected)} h={h || 162} w={w || 282} pos="relative">
              <Box className={cx(classes.viewButtonContent)} h={(h && h - 2) || 160} w={(w && w - 2) || 280} pos="relative">
                {image}
              </Box>
              <Center className={classes.innerIcon} pos="absolute" bottom={0} right={0}>
                {icon}
              </Center>
            </Box>
            <Text size="sm" fw={selectedView === value ? 600 : 500} pt="xs">
              {title}
            </Text>
            <Text c="var(--colors-gray-5)">{description}</Text>
          </UnstyledButton>
        );
      })}
    </Flex>
  );
}
