import { Flex, Group, Stack, Text, useComputedColorScheme } from '@mantine/core';
import { useResizeObserver } from '@mantine/hooks';
import React, { useMemo } from 'react';
import { FixedSizeList } from 'react-window';
import SearchIcon from '../../components/icons/Search';
import { useSearchResults } from './SearchResults.hooks';
import pluralize from 'pluralize';
import { useGlobalState } from '../../data/GlobalState';
import { BNButton } from '../../components/Button/Button';
import ArrowBackIcon from '../../components/icons/ArrowBack';
import classes from './SeasonList.module.css';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import { BNTextInput } from '../../components/TextInput/TextInput';
import { SeasonCard } from './SeasonList.SeasonCard';
import { SeasonCardSkeleton } from './SeasonList.SeasonCardSkeleton';

export function SeasonList() {
  const { seasons, isLoading, selectedSeason } = useSearchResults('seasons', 'isLoading', 'sort', 'sortOptions', 'setSort', 'sortOrder', 'setSortOrder', 'selectedSeason');
  const [parentRef, parentRect] = useResizeObserver();
  // https://mantine.dev/hooks/use-resize-observer/
  const { principal, tenants } = useGlobalState('principal', 'tenants');
  const [seasonFilter, setSeasonFilter] = React.useState('');

  const filteredSeasons = seasons?.filter((season) => season.seasonName.toLowerCase().includes(seasonFilter.toLowerCase()));

  const Row = useMemo(
    () =>
      ({ index, style }: { index: number; style: React.CSSProperties }) => {
        const season = filteredSeasons[index];
        const isSelected = selectedSeason?.seasonName === season.seasonName;
        const tenantName = tenants?.find((t) => t.tenantId === season?.tenantId)?.name || 'Unknown';
        const tenantColor = principal?.settings?.tenantColors[season.tenantId] ?? 'var(--colors-brandcolor-5)';
        const isMultiTenant = tenants && tenants.length > 1;

        return (
          <div style={style}>
            <SeasonCard season={season} isSelected={isSelected} tenantName={tenantName} tenantColor={tenantColor} isMultiTenant={isMultiTenant} />
          </div>
        );
      },
    [filteredSeasons, selectedSeason?.seasonName, tenants, principal?.settings?.tenantColors],
  );

  return (
    <>
      <Stack px="md" py="md">
        <BNButton
          fullWidth
          onClick={() => {
            window.location.href = '/';
          }}
          className="exit-season-pricer"
          size="xs"
          leftSection={<ArrowBackIcon />}
          variant="filled"
          color="gray"
        >
          Exit Season Pricer
        </BNButton>
        <BNTextInput
          id="filter-text-box"
          leftSection={<SearchIcon color="var(--colors-gray-4)" />}
          size="xs"
          value={seasonFilter}
          aria-label="Season Search"
          onChange={(e) => setSeasonFilter(e.currentTarget.value)}
          clearable
          clearableOnClick={() => setSeasonFilter('')}
          placeholder="Find Season"
        />
      </Stack>
      <Group className={`resultsArea ${classes.wrapper}`} px={16} py={0}>
        <Group>
          <Text fz={11} color="var(--colors-gray-5)" tt="uppercase" className={classes.selectLabel}>
            {seasons ? seasons.length : 0} {pluralize('Season', seasons ? seasons.length : 0)}
          </Text>
        </Group>
      </Group>
      <div className={classes.searchResultsOuterWrap} ref={parentRef}>
        <div className={classes.searchResultsInnerWrap}>
          {filteredSeasons && filteredSeasons.length > 0 ? (
            <FixedSizeList
              className="searchResultsList"
              height={parentRect.height || 0}
              itemCount={filteredSeasons.length}
              itemSize={128}
              overscanCount={10}
              layout="vertical"
              width="100%"
            >
              {Row}
            </FixedSizeList>
          ) : isLoading ? (
            <SeasonCardSkeleton />
          ) : seasonFilter !== '' ? (
            <Flex align="center" justify="center" h="85%">
              <BNEmptyState
                title="No Results Found"
                description="Try changing your search criteria."
                icon={<SearchIcon color="var(--colors-iconFill)" size={28} />}
                border={false}
                buttonText="Clear Search"
                buttonOnClick={() => {
                  setSeasonFilter('');
                }}
              />
            </Flex>
          ) : (
            // Empty State Example
            <Flex align="center" justify="center" h="85%">
              <BNEmptyState
                title="No Eligible Seasons Found"
                description="Season pricing requires similar listings for multiple events."
                icon={<SearchIcon color="var(--colors-iconFill)" size={28} />}
                border={false}
                buttonText="Exit Season Pricer"
                buttonVariant="filled"
                buttonColor="gray"
                buttonOnClick={() => {
                  window.location.href = '/';
                }}
              />
            </Flex>
          )}
        </div>
      </div>
    </>
  );
}
