import { PlanExpirationBanner } from '../hoc/Banners/PlanExpirationBanner';
import { ActionIcon, Box, Center, Flex, Group, Menu, Modal, Stepper, Text, UnstyledButton } from '@mantine/core';
import { SupportActivationBanner } from '../hoc/Banners/SupportActivationBanner';
import { UnpaidInvoiceBanner } from '../hoc/Banners/UnpaidInvoiceBanner';
import { VersionNotificationBanner } from '../hoc/Banners/VersionNotificationBanner';
import { BulletinModal } from '../hoc/Bulletin/BulletinModal';
import { InviteModal } from '../hoc/InviteModal/InviteModal';
import classes from '../hoc/Purchase/Purchase.module.css';
import cx from 'clsx';
import { PurchaseStateProvider, usePurchaseState } from '../hoc/Purchase/Purchase.hooks';
import CloseIcon from '../components/icons/Close';
import SelectArrowsVariableIcon from '../components/icons/SelectArrows';
import StadiumIcon from '../components/icons/Stadium';
import ListIcon from '../components/icons/List';
import ReceiptIcon from '../components/icons/Receipt';
import CheckCircleIcon from '../components/icons/CheckCircle';
import { useInventory } from '../hoc/Inventory/Inventory.hooks';
import { Step_CatalogSelect } from '../hoc/Purchase/Purchase.Step.Catalog';
import { Step_AddInventory } from '../hoc/Purchase/Purchase.Step.AddInventory';
import { Step_Summary } from '../hoc/Purchase/Purchase.Step.Summary';
import { useMemo } from 'react';
import ErrorFilledIcon from '../components/icons/ErrorFilled';

export function Purchase() {
  return (
    <>
      <BulletinModal />
      <InviteModal />
      <div className="outer-appWrap">
        <VersionNotificationBanner />
        <SupportActivationBanner />
        <PlanExpirationBanner />
        <UnpaidInvoiceBanner />
        <PurchaseStateProvider>
          <PurchaseInterior />
        </PurchaseStateProvider>
      </div>
    </>
  );
}

export function PurchaseModal() {
  const { showPurchaseModal, closePurchaseModal, initialPurchaseEvent } = useInventory('showPurchaseModal', 'closePurchaseModal', 'initialPurchaseEvent');
  if (!showPurchaseModal) return null;
  return (
    <Modal
      opened
      onClose={() => closePurchaseModal()}
      centered
      withCloseButton={false}
      size={1640}
      styles={{
        content: { height: 1000, maxHeight: '90%', width: '100%', maxWidth: 1640, padding: 0, flex: 1 },
        body: { height: 1000, maxHeight: '100%', width: 1640, maxWidth: '100%', padding: 0 },
        inner: { padding: 0, maxHeight: '100%', width: '90%', marginLeft: '5%', marginRight: '5%', background: 'transparent' },
      }}
    >
      <PurchaseStateProvider onClose={closePurchaseModal} initialEvent={initialPurchaseEvent}>
        <PurchaseInterior />
      </PurchaseStateProvider>
    </Modal>
  );
}

export function PurchaseInterior() {
  const { active, setActive, selectedAccountId, setSelectedAccountId, dtiAccounts, selectedEvents, isSelectEventsEnabled, isAccountError } = usePurchaseState(
    'active',
    'setActive',
    'selectedAccountId',
    'setSelectedAccountId',
    'dtiAccounts',
    'selectedEvents',
    'isSelectEventsEnabled',
    'isAccountError',
  );

  const { closePurchaseModal } = useInventory('closePurchaseModal');

  const eligibleAccounts = dtiAccounts.filter((a) => a.perms.add_inventory.enabled === '1');
  const filteredDtiAccounted = eligibleAccounts.filter((x) => x.id !== -100);
  const isMultiTenant = eligibleAccounts.length > 1;
  const dtiAccount = useMemo(() => eligibleAccounts.find((x) => x.id === selectedAccountId)?.name, [eligibleAccounts, selectedAccountId]);

  return (
    <Flex direction="column" h="100%" w="100%" pos="relative">
      <Flex h="100%">
        <Flex className="col2" flex={1} direction="column" h="100%" style={{ borderRight: 'none' }}>
          <Flex h={52} align="center" pos="absolute" top={0} left={0} w={288}>
            <Menu disabled={!isMultiTenant} withArrow width={260} classNames={{ dropdown: classes.tenantMenu }}>
              <Menu.Target>
                {!isMultiTenant ? (
                  <UnstyledButton className={cx(classes.tenantButton, !isMultiTenant && 'tenant-single')} h={52} lts={-0.25} pl="md" pr="sm" w="100%">
                    <Box style={{ overflow: 'hidden' }}>
                      <Text fw="600" fz="sm" truncate flex={1} lh={1.3}>
                        {dtiAccount}
                      </Text>
                    </Box>
                    <Center className={classes.selectArrows}>
                      <SelectArrowsVariableIcon color="var(--colors-gray-5)" size={20} />
                    </Center>
                  </UnstyledButton>
                ) : (
                  <UnstyledButton className={cx(classes.tenantButton, isAccountError && classes.tenantButtonError)} h={52} lts={-0.25} pl="md" pr="sm" w="100%">
                    <Box style={{ overflow: 'hidden' }}>
                      <Group gap={6}>
                        {isAccountError && <ErrorFilledIcon color="var(--colors-red-error)" />}
                        <Text fw="600" fz="sm" truncate flex={1} lh={1.3}>
                          {selectedAccountId ? dtiAccount : 'Select an account'}
                        </Text>
                      </Group>
                    </Box>
                    <Center className={classes.selectArrows}>
                      <SelectArrowsVariableIcon color="var(--colors-gray-5)" size={20} />
                    </Center>
                  </UnstyledButton>
                )}
              </Menu.Target>
              <Menu.Dropdown mah={240} style={{ overflowY: 'auto' }}>
                {filteredDtiAccounted?.map((account) => (
                  <Menu.Item key={account.id} onClick={() => setSelectedAccountId(account.id)} className={cx(selectedAccountId === account.id && classes.tenantSelected)}>
                    {account.name}
                  </Menu.Item>
                ))}
              </Menu.Dropdown>
            </Menu>
          </Flex>
          <Flex h={52} align="center" justify="end" pos="absolute" top={0} right={0} w={288} px="xs">
            <ActionIcon m={0} onClick={() => closePurchaseModal()}>
              <CloseIcon size={24} />
            </ActionIcon>
          </Flex>
          <Stepper
            active={active}
            onStepClick={setActive}
            size="xs"
            className={classes.stepper}
            classNames={{ root: classes.stepper_root, content: classes.stepper_content, steps: classes.stepper_steps, separator: classes.stepper_separator }}
            completedIcon={<CheckCircleIcon />}
          >
            {isSelectEventsEnabled && (
              <Stepper.Step label="Select Events" withIcon icon={<StadiumIcon size={18} />}>
                <Step_CatalogSelect />
              </Stepper.Step>
            )}
            <Stepper.Step
              label="Add Inventory"
              withIcon
              icon={<ListIcon size={18} />}
              disabled={selectedEvents.length === 0}
              style={{
                cursor: selectedEvents.length === 0 ? 'default' : 'unset',
              }}
            >
              <Step_AddInventory />
            </Stepper.Step>
            <Stepper.Step
              label="Summary"
              withIcon
              icon={<ReceiptIcon size={18} />}
              disabled={selectedEvents.length === 0}
              style={{
                cursor: selectedEvents.length === 0 ? 'default' : 'unset',
              }}
            >
              <Step_Summary />
            </Stepper.Step>
          </Stepper>
        </Flex>
      </Flex>
    </Flex>
  );
}
