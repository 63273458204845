import { ActionIcon, Badge, Center, Divider, Flex, Group, Indicator, Modal, Stack, Text, Textarea, Tooltip } from '@mantine/core';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import HistoryIcon from '../../components/icons/History';
import LiveHelpIcon from '../../components/icons/LiveHelp';
import MoreVertIcon from '../../components/icons/MoreVert';
import WifiIcon from '../../components/icons/Wifi';
import { BarkerEventListing, ListingUpdate } from '../../types';
import { useDebouncedValue, useDisclosure, useToggle } from '@mantine/hooks';
import { BarkerCoreEnumsPointOfSale, BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal, deleteApiPricingRulesRuleId, patchApiInventoryListingsListingId } from '../../api';
import { ArrowsSplit } from 'tabler-icons-react';
import {
  applyRuleToSelectedGroupedListingsAtom,
  filterOutListingsAtom,
  listingsMatchingSelectedRuleIdAtom,
  pendingListingUpdatesAtom,
  selectedTenantListingIdAtom,
  transientGlobalStateAtom,
  updateListingsAtom,
} from '../../data/atoms';
import { useAtomValue, useSetAtom } from 'jotai';
import { useGlobalState } from '../../data/GlobalState';
import { SupportForm } from '../Support/SupportForm';
import { BNButton } from '../../components/Button/Button';
import { BNSelect } from '../../components/Select/Select';
import { useFlag } from '@unleash/proxy-client-react';
import SplitscreenIcon from '../../components/icons/Splitscreen';
import SettingsIcon from '../../components/icons/Settings';
import DeleteIcon from '../../components/icons/Delete';
import ContentCopyIcon from '../../components/icons/ContentCopy';
import StickyNoteIcon from '../../components/icons/StickyNote';
import ExportNotesIcon from '../../components/icons/ExportNotes';
import { useForm } from '@mantine/form';
import { getPointOfSaleSplitOptions, getPointOfSaleSplitType, setSplitsModelForUpdate } from '../../components/Splits/Splits.utils';
import { BNAccordionMenu } from '../../components/Accordion/BNAccordion';
import classes from './Inventory.Action.module.css';
import { SplitListing } from './Inventory.SplitListing';
import { useListingState } from '../../data/ListingState';
import SortIcon from '../../components/icons/Sort';
import { orderedRowsDoubleBetter, orderedRowsSingleBetter, reservedRows } from '../../utils/rule-utils';
import ArrowForwardIcon from '../../components/icons/ArrowForward';
import { isDtiHosted } from '../../utils/whitelabel-consts';
import PointOfSaleIcon from '../../components/icons/PointOfSale';
import { dti } from '../../data/atoms.dti';
import { DtiModals } from '../DTI/DTIModals';
import MotionPhotosPausedFillIcon from '../../components/icons/MotionPhotosPausedFill';
import { DTISupportForm } from '../DTI/ListingSupport/ListingSupportForm';
import EditNoteIcon from '../../components/icons/EditNote';
import { SplitsButtonGroupVariable } from '../SeatingChart/Filters/Filters.SplitsButtonGroupVariable';
import { DeleteListingForm } from '../DTI/DeleteListing/DeleteListingForm';
import { ReserveUnreserveForm } from '../DTI/Reserve/ReserveUnreserveForm';
import { SharingMenuPanel } from '../DTI/Sharing/SharingMenuPanel';
import PictureAsPDFIcon from '../../components/icons/PictureAsPDF';
import { ReserveUnreserveAction } from './Inventory.Action.ReserveUnreserve';

export type InventoryActionProps = {
  listing: BarkerEventListing;
  ruleId: string | null | undefined;
  updateBroadcasting: (tenantId: string, listingId: string, isBroadcasting: boolean) => void;
  setSelectedHistoryListing: Dispatch<
    SetStateAction<{
      listing: BarkerEventListing | null;
      isOpen: boolean;
    }>
  >;
  isAnchorListing: boolean;
};
export const InventoryAction = ({ listing, ruleId, setSelectedHistoryListing, updateBroadcasting, isAnchorListing }: InventoryActionProps) => {
  const { tenants, needsFeatureHint } = useGlobalState('tenants', 'needsFeatureHint');
  const [menuOpen, toggleMenu] = useToggle();
  const [supportOpen, { open: openSupport, close: closeSupport }] = useDisclosure();
  const tenantPointOfSale = useMemo(() => tenants?.find((t) => t.tenantId === listing.event?.tenantId)?.pointOfSaleId || 'Unknown', [listing.event?.tenantId, tenants]);
  const needsReserveInventoryHint = needsFeatureHint('reserve-inventory') && tenantPointOfSale === 'SkyBox';

  return (
    <>
      <BNAccordionMenu
        width={302}
        opened={menuOpen}
        onClose={() => toggleMenu(false)}
        position="right"
        offset={15}
        target={
          <Indicator size={needsReserveInventoryHint ? 6 : 0} offset={3} color="blue" display="flex">
            <ActionIcon onClick={() => toggleMenu()}>
              <MoreVertIcon size={20} color="var(--colors-gray-5)" />
            </ActionIcon>
          </Indicator>
        }
      >
        {menuOpen && (
          <InventoryActionForm
            listing={listing}
            ruleId={ruleId}
            setSelectedHistoryListing={setSelectedHistoryListing}
            updateBroadcasting={updateBroadcasting}
            openSupport={openSupport}
            toggleMenu={toggleMenu}
            isAnchorListing={isAnchorListing}
            tenantPointOfSale={tenantPointOfSale}
          />
        )}
      </BNAccordionMenu>
      {supportOpen && (
        <Modal
          opened={supportOpen}
          onClose={closeSupport}
          closeOnClickOutside={false}
          title={
            <Group gap="xs">
              <LiveHelpIcon color="var(--colors-iconFill)" />
              <Text size="sm">Support Request - Inventory</Text>
            </Group>
          }
          centered
          size="md"
        >
          {isDtiHosted ? (
            <DTISupportForm listing={listing} onClose={closeSupport} />
          ) : (
            <SupportForm type="inventoryListing" listing={listing} onClose={closeSupport} pointOfSaleId={tenantPointOfSale} />
          )}
        </Modal>
      )}
    </>
  );
};

export const InventoryActionForm = ({
  listing,
  ruleId,
  setSelectedHistoryListing,
  updateBroadcasting,
  toggleMenu,
  openSupport,
  isAnchorListing,
  tenantPointOfSale,
}: InventoryActionProps & {
  toggleMenu: (value?: boolean) => void;
  openSupport: () => void;
  tenantPointOfSale: BarkerCoreEnumsPointOfSale;
}) => {
  const { currentUser, needsFeatureHint, markFeatureSeen, dtiAccounts } = useGlobalState('currentUser', 'needsFeatureHint', 'markFeatureSeen', 'dtiAccounts');
  const { listingId, tenantId } = listing;
  const { patchListing, patchListingLoading } = useListingState('patchListing', 'patchListingLoading');
  const updateListing = useSetAtom(updateListingsAtom);
  const ignoreListing = useSetAtom(filterOutListingsAtom);
  const listingsMatchingRuleId = useAtomValue(listingsMatchingSelectedRuleIdAtom);
  const initialSplitValues = getPointOfSaleSplitType(tenantPointOfSale, listing.splits);
  const setSelectedListingId = useSetAtom(selectedTenantListingIdAtom);
  const setPendingListingUpdates = useSetAtom(pendingListingUpdatesAtom);
  const setTransientGlobalState = useSetAtom(transientGlobalStateAtom);
  const applyRuleToSelectedGroupedListings = useSetAtom(applyRuleToSelectedGroupedListingsAtom);

  const isDev = useFlag('dev');
  const needsReserveInventoryHint = needsFeatureHint('reserve-inventory');

  const initialValues = {
    internalNotes: listing.internalNotes || '',
    externalNotes: listing.externalNotes || '',
    splitType: initialSplitValues?.splitType,
    customSplits: initialSplitValues?.customSplits,
  };
  const { values, getInputProps, isDirty, reset, resetDirty } = useForm({
    initialValues,
  });

  const onClose = ({ validateDirty } = { validateDirty: false }) => {
    if (!validateDirty || !isDirty()) {
      reset();
      toggleMenu(false);
    }
  };

  const splitOptions = useMemo(() => getPointOfSaleSplitOptions(tenantPointOfSale), [tenantPointOfSale]);

  const canSplitListing = tenantPointOfSale !== 'EIBO';

  const updateInternalNotes = async (note: string | null | undefined) => {
    if (listingId && typeof note === 'string') {
      const { data: listingResult } = await patchListing({
        tenantId,
        listingId,
        data: [{ op: 'replace', path: '/internalNotes', value: note }],
      });
      if (listingResult) {
        updateListing(listingResult);
        resetDirty({ ...initialValues, internalNotes: note });
      }
    }
  };
  const updateExternalNotes = async (note: string | null | undefined) => {
    if (listingId && typeof note === 'string') {
      const { data: listingResult } = await patchListing({
        tenantId,
        listingId,
        data: [{ op: 'replace', path: '/externalNotes', value: note }],
      });
      if (listingResult) {
        updateListing(listingResult);
        resetDirty({ ...initialValues, externalNotes: note });
      }
    }
  };
  const updateSplits = async () => {
    if (listingId) {
      const update = setSplitsModelForUpdate(tenantPointOfSale, values);
      if (update) {
        const { data: listingResult } = await patchListing({
          tenantId,
          listingId,
          data: [update],
        });
        if (listingResult) {
          updateListing(listingResult);
          const getPointOfSaleSplitTypeResult = getPointOfSaleSplitType(tenantPointOfSale, listingResult.splits);
          resetDirty({ ...initialValues, ...getPointOfSaleSplitTypeResult });
          setPendingListingUpdates((prev) => [...prev.filter((x) => !(x.listingId === listing.listingId && x.property === 'splits'))]);
        }
      }
    }
  };

  const containsSingleAndDoubleLetterRows =
    isAnchorListing &&
    listingsMatchingRuleId.some((l) => reservedRows.has(l.row) && l.row.length === 1) &&
    listingsMatchingRuleId.some((l) => reservedRows.has(l.row) && l.row.length === 2);

  const reorderGroup = async (sort: string) => {
    if (!listingId || !isAnchorListing || listingsMatchingRuleId.length <= 1) return;

    const sortedListings = [...listingsMatchingRuleId];

    if (sort === 'By Cost (Asc)') {
      sortedListings.sort((a, b) => a.unitCost - b.unitCost);
    } else if (sort === 'By Cost (Desc)') {
      sortedListings.sort((a, b) => b.unitCost - a.unitCost);
    } else if (sort === 'By Quantity (Asc)') {
      sortedListings.sort((a, b) => a.quantityRemaining - b.quantityRemaining);
    } else if (sort === 'By Quantity (Desc)') {
      sortedListings.sort((a, b) => b.quantityRemaining - a.quantityRemaining);
    } else if (sort === 'By Row (Asc)') {
      sortedListings.sort((a, b) => a.row.localeCompare(b.row, undefined, { numeric: true }));
    } else if (sort === 'By Row (Desc)') {
      sortedListings.sort((a, b) => b.row.localeCompare(a.row, undefined, { numeric: true }));
    } else if (sort === 'By Row (Asc) (AA-ZZ A-Z)') {
      sortedListings.sort((a, b) => orderedRowsDoubleBetter[a.row] - orderedRowsDoubleBetter[b.row]);
    } else if (sort === 'By Row (Asc) (A-Z AA-ZZ)') {
      sortedListings.sort((a, b) => orderedRowsSingleBetter[a.row] - orderedRowsSingleBetter[b.row]);
    } else if (sort === 'By Row (Desc) (ZZ-AA Z-A)') {
      sortedListings.sort((a, b) => orderedRowsSingleBetter[b.row] - orderedRowsSingleBetter[a.row]);
    } else if (sort === 'By Row (Desc) (Z-A ZZ-AA)') {
      sortedListings.sort((a, b) => orderedRowsDoubleBetter[b.row] - orderedRowsDoubleBetter[a.row]);
    }

    sortedListings.forEach((l, i) => {
      // eslint-disable-next-line no-param-reassign
      l.ruleTier = i * 100;
    });

    setPendingListingUpdates((prev) => [
      ...prev.filter((x) => !(x.listingId === listing.listingId && x.tenantId === listing.tenantId && x.property === 'ruleTier')),
      ...sortedListings.map(
        (l) =>
          ({
            listingId: l.listingId,
            tenantId: l.tenantId,
            property: 'ruleTier',
            value: l.ruleTier,
            previousValue: listingsMatchingRuleId.find((x) => x.listingId === l.listingId && x.tenantId === l.tenantId)!.ruleTier,
          }) satisfies ListingUpdate,
      ),
    ]);

    updateListing(sortedListings);

    const anchorListing = sortedListings[0];

    setTransientGlobalState((s) => ({
      ...s,
      applyGroupListingRule: true,
      expandGroupByListingId: anchorListing.tenantIdListingId,
    }));

    setSelectedListingId(anchorListing.tenantIdListingId, { keepRule: true });

    applyRuleToSelectedGroupedListings();

    // Close the menu, because if the anchor swaps it unrenders anyways, we might as well close it all the time to make it consistent.
    toggleMenu();
  };

  const setCurrentDtiModal = useSetAtom(dti.currentModalAtom);

  const isListingSold = listing.listingId.endsWith('sales') || listing.sale?.saleId !== undefined;

  const [broadcastingSwitchOn, setBroadcastingSwitchOn] = useState(listing.isBroadcasting);

  let pdfDisabledText = '';

  if (listing.vendorProperties?.pointOfSaleId === 'DtiPortal') {
    const dtiProperties = listing.vendorProperties as BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal;

    const uploadPdfPermission = dtiAccounts.find((a) => a.id === dtiProperties.ownerId)?.perms?.upload_pdf?.enabled ?? '0';

    if (uploadPdfPermission !== '1') {
      pdfDisabledText = 'Insufficient permissions';
    } else if (listing.isBroadcasting) {
      pdfDisabledText = 'Cannot add PDFs to a broadcasting listing';
    } else if (dtiProperties.numPdfs === listing.quantityRemaining) {
      pdfDisabledText = 'All tickets have PDFs';
    } else if (dtiProperties.location !== 'TF' && dtiProperties.location !== 'MOBILE') {
      pdfDisabledText = 'Invalid ticket stock for PDFs';
    }
  }

  // This is a bit of a hack because the loading indicator on the switch seems to act funky when flipping too quickly between loading and not loading
  const handleBroadcastingSwitch = () => {
    setBroadcastingSwitchOn(!listing.isBroadcasting);
    updateBroadcasting(listing.tenantId, listing.listingId, !listing.isBroadcasting);
  };
  const [patchListingLoadingDebounced] = useDebouncedValue(patchListingLoading, 250);

  const reserveInventoryFlag = useFlag('reserve-inventory');

  if (isListingSold) {
    return (
      <BNAccordionMenu.Item
        title="View History"
        leftSection={<HistoryIcon />}
        onClick={() => {
          onClose();
          setSelectedHistoryListing({ listing, isOpen: true });
        }}
        data-pendo-name="View Listing History MenuItem"
      />
    );
  }

  return (
    <>
      {isAnchorListing && (
        <BNAccordionMenu.Panel
          id="reorder_group"
          leftSection={<SortIcon />}
          title="Reorder Group"
          className={classes.bnOptionsAccordionPanel}
          data-pendo-name="Reorder Group MenuItem"
        >
          <Flex justify="flex-end">
            <Stack gap={0} w={254} pr="xs">
              {containsSingleAndDoubleLetterRows ? (
                <Flex justify="space-between" align="start" h={60} mt="xs">
                  <Text>By Row</Text>
                  <Stack gap={4} mt={-1}>
                    <Group gap={0}>
                      <Tooltip label="Reorder By Row (Asc) (AA-ZZ A-Z)" withArrow>
                        <BNButton onClick={() => reorderGroup('By Row (Asc) (AA-ZZ A-Z)')} size="compact-xs" fz={10} c="var(--colors-paperReverse)" variant="subtle" pl={2}>
                          <Center style={{ transform: 'rotate(-90deg)' }}>
                            <ArrowForwardIcon color="var(--colors-gray-5)" size={18} />
                          </Center>
                          AA-ZZ A-Z
                        </BNButton>
                      </Tooltip>
                      <Tooltip label="Reorder By Row (Asc) (A-Z AA-ZZ)" withArrow>
                        <BNButton onClick={() => reorderGroup('By Row (Asc) (A-Z AA-ZZ)')} size="compact-xs" fz={10} c="var(--colors-paperReverse)" variant="subtle" pl={2}>
                          <Center style={{ transform: 'rotate(-90deg)' }}>
                            <ArrowForwardIcon color="var(--colors-gray-5)" size={18} />
                          </Center>
                          A-Z AA-ZZ
                        </BNButton>
                      </Tooltip>
                    </Group>
                    <Group gap={0}>
                      <Tooltip label="Reorder By Row (Desc) (ZZ-AA Z-A)" withArrow>
                        <BNButton onClick={() => reorderGroup('By Row (Desc) (ZZ-AA Z-A)')} size="compact-xs" fz={10} c="var(--colors-paperReverse)" variant="subtle" pl={2}>
                          <Center style={{ transform: 'rotate(90deg)' }}>
                            <ArrowForwardIcon color="var(--colors-gray-5)" size={18} />
                          </Center>
                          ZZ-AA Z-A
                        </BNButton>
                      </Tooltip>
                      <Tooltip label="Reorder By Row (Desc) (Z-A ZZ-AA)" withArrow>
                        <BNButton onClick={() => reorderGroup('By Row (Desc) (Z-A ZZ-AA)')} size="compact-xs" fz={10} c="var(--colors-paperReverse)" variant="subtle" pl={2}>
                          <Center style={{ transform: 'rotate(90deg)' }}>
                            <ArrowForwardIcon color="var(--colors-gray-5)" size={18} />
                          </Center>
                          Z-A ZZ-AA
                        </BNButton>
                      </Tooltip>
                    </Group>
                  </Stack>
                </Flex>
              ) : (
                <Flex justify="space-between" align="center" h={36}>
                  <Text>By Row</Text>
                  <Group gap={0}>
                    <Tooltip label="Reorder By Row (Asc)" withArrow>
                      <BNButton onClick={() => reorderGroup('By Row (Asc)')} size="compact-xs" fz={10} c="var(--colors-paperReverse)" variant="subtle" pl={2}>
                        <Center style={{ transform: 'rotate(-90deg)' }}>
                          <ArrowForwardIcon color="var(--colors-gray-5)" size={18} />
                        </Center>
                        ASC
                      </BNButton>
                    </Tooltip>
                    <Tooltip label="Reorder By Row (Desc)" withArrow>
                      <BNButton onClick={() => reorderGroup('By Row (Desc)')} size="compact-xs" fz={10} c="var(--colors-paperReverse)" variant="subtle" pl={2}>
                        <Center style={{ transform: 'rotate(90deg)' }}>
                          <ArrowForwardIcon color="var(--colors-gray-5)" size={18} />
                        </Center>
                        DESC
                      </BNButton>
                    </Tooltip>
                  </Group>
                </Flex>
              )}

              <Divider color="var(--colors-paperHover)" />
              <Flex justify="space-between" align="center" h={36}>
                <Text>By Cost</Text>
                <Group gap={0}>
                  <Tooltip label="Reorder By Cost (Asc)" withArrow>
                    <BNButton onClick={() => reorderGroup('By Cost (Asc)')} size="compact-xs" fz={10} c="var(--colors-paperReverse)" variant="subtle" pl={2}>
                      <Center style={{ transform: 'rotate(-90deg)' }}>
                        <ArrowForwardIcon color="var(--colors-gray-5)" size={18} />
                      </Center>
                      ASC
                    </BNButton>
                  </Tooltip>
                  <Tooltip label="Reorder By Cost (Desc)" withArrow>
                    <BNButton onClick={() => reorderGroup('By Cost (Desc)')} size="compact-xs" fz={10} c="var(--colors-paperReverse)" variant="subtle" pl={2}>
                      <Center style={{ transform: 'rotate(90deg)' }}>
                        <ArrowForwardIcon color="var(--colors-gray-5)" size={18} />
                      </Center>
                      DESC
                    </BNButton>
                  </Tooltip>
                </Group>
              </Flex>
              <Divider color="var(--colors-paperHover)" />
              <Flex justify="space-between" align="center" h={36}>
                <Text>By Quantity</Text>
                <Group gap={0}>
                  <Tooltip label="Reorder By Quantity (Asc)" withArrow>
                    <BNButton onClick={() => reorderGroup('By Quantity (Asc)')} size="compact-xs" fz={10} c="var(--colors-paperReverse)" variant="subtle" pl={2}>
                      <Center style={{ transform: 'rotate(-90deg)' }}>
                        <ArrowForwardIcon color="var(--colors-gray-5)" size={18} />
                      </Center>
                      ASC
                    </BNButton>
                  </Tooltip>
                  <Tooltip label="Reorder By Quantity (Desc)" withArrow>
                    <BNButton onClick={() => reorderGroup('By Quantity (Desc)')} size="compact-xs" fz={10} c="var(--colors-paperReverse)" variant="subtle" pl={2}>
                      <Center style={{ transform: 'rotate(90deg)' }}>
                        <ArrowForwardIcon color="var(--colors-gray-5)" size={18} />
                      </Center>
                      DESC
                    </BNButton>
                  </Tooltip>
                </Group>
              </Flex>
            </Stack>
          </Flex>
        </BNAccordionMenu.Panel>
      )}
      <BNAccordionMenu.Switch
        title="Broadcasting"
        leftSection={
          <Center h={20} w={20}>
            <WifiIcon size={18} />
          </Center>
        }
        checked={broadcastingSwitchOn}
        disabled={currentUser?.roleId === 'ReadOnlyUser' || listing.quantityReserved > 0}
        onChange={() => handleBroadcastingSwitch()}
        loading={patchListingLoadingDebounced}
        data-pendo-name="Broadcasting Switch"
      />
      {isDtiHosted && <SharingMenuPanel onClose={onClose} />}
      <BNAccordionMenu.Panel id="edit_splits" leftSection={<SplitscreenIcon />} title="Splits" data-pendo-name="Listing Splits MenuItem">
        <form>
          <Group gap="xs" align="flex-end" justify="right">
            <Stack gap="xs">
              <BNSelect
                {...getInputProps('splitType')}
                m={0}
                p={0}
                w={236}
                disabled={patchListingLoading || listing.quantityReserved > 0 || currentUser?.roleId === 'ReadOnlyUser'}
                size="xs"
                aria-label="Split Type"
                labelProps={{ style: { whiteSpace: 'nowrap' } }}
                data={splitOptions}
              />
              {values.splitType === 'CUSTOM' && tenantPointOfSale !== 'DtiPortal' && (
                <Stack gap="xs" w={236} mah={100} px="xs" py="xs" style={{ border: '1px solid var(--colors-gray-3)', borderRadius: 3, overflow: 'auto' }}>
                  <SplitsButtonGroupVariable count={listing.quantityRemaining} size="xs" splits={values.customSplits} onChange={getInputProps('customSplits').onChange} />
                  {/* <SplitsButtonGroup size="xs" splits={[1, 2, 3, 4, 5, 6, 7, 8]} onChange={() => {}} /> */}
                </Stack>
              )}
              <Group gap="xs" align="flex-end" justify="right" wrap="nowrap">
                <BNButton
                  fullWidth
                  variant="default"
                  disabled={patchListingLoading}
                  size="xs"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </BNButton>
                <BNButton
                  disabled={!isDirty('splitType') || listing.quantityReserved > 0 || currentUser?.roleId === 'ReadOnlyUser'}
                  fullWidth
                  loading={patchListingLoading}
                  variant="filled"
                  color="green"
                  size="xs"
                  onClick={() => updateSplits()}
                >
                  Apply
                </BNButton>
              </Group>
            </Stack>
          </Group>
        </form>
      </BNAccordionMenu.Panel>
      <BNAccordionMenu.Panel id="internal_notes" leftSection={<StickyNoteIcon />} title="Internal Notes" data-pendo-name="Internal Notes MenuItem">
        <form>
          <Group gap="xs" align="flex-end" justify="right">
            <Stack gap="xs">
              <Textarea
                {...getInputProps('internalNotes')}
                size="xs"
                name="internalNotes"
                aria-label="Internal Notes"
                disabled={patchListingLoading || listing.quantityReserved > 0}
                placeholder="Add your internal notes"
                minRows={4}
                w={236}
              />
              <Group gap="xs" align="flex-end" justify="right" wrap="nowrap">
                <BNButton
                  fullWidth
                  variant="default"
                  disabled={patchListingLoading}
                  size="xs"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </BNButton>
                <BNButton
                  disabled={!isDirty('internalNotes') || listing.quantityReserved > 0 || currentUser?.roleId === 'ReadOnlyUser'}
                  loading={patchListingLoading}
                  fullWidth
                  variant="filled"
                  color="green"
                  size="xs"
                  onClick={() => {
                    updateInternalNotes(values.internalNotes);
                  }}
                >
                  Apply
                </BNButton>
              </Group>
            </Stack>
          </Group>
        </form>
      </BNAccordionMenu.Panel>
      <BNAccordionMenu.Panel id="external_notes" leftSection={<ExportNotesIcon />} title="External Notes" data-pendo-name="External Notes MenuItem">
        <form>
          <Group gap="xs" align="flex-end" justify="right">
            <Stack gap="xs">
              <Textarea
                {...getInputProps('externalNotes')}
                size="xs"
                name="externalNotes"
                disabled={patchListingLoading || listing.quantityReserved > 0}
                aria-label="External Notes"
                placeholder="Add your external notes"
                minRows={4}
                w={236}
              />
              <Group gap="xs" align="flex-end" justify="right" wrap="nowrap">
                <BNButton
                  fullWidth
                  variant="default"
                  disabled={patchListingLoading}
                  size="xs"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </BNButton>
                <BNButton
                  disabled={!isDirty('externalNotes') || listing.quantityReserved > 0 || currentUser?.roleId === 'ReadOnlyUser'}
                  fullWidth
                  variant="filled"
                  loading={patchListingLoading}
                  color="green"
                  size="xs"
                  onClick={() => {
                    updateExternalNotes(values.externalNotes);
                  }}
                >
                  Apply
                </BNButton>
              </Group>
            </Stack>
          </Group>
        </form>
      </BNAccordionMenu.Panel>
      {canSplitListing && (
        <BNAccordionMenu.Panel
          id="split_listing"
          leftSection={
            <Center w={20} h={20}>
              <ArrowsSplit size={18} />
            </Center>
          }
          title="Split Listing"
          data-pendo-name="Split Listing MenuItem"
        >
          <SplitListing listing={listing} onClose={onClose} pointOfSale={tenantPointOfSale} />
        </BNAccordionMenu.Panel>
      )}
      {reserveInventoryFlag && tenantPointOfSale === 'SkyBox' && (
        <BNAccordionMenu.Panel
          id="reserve_unreserve"
          title={listing.quantityReserved > 0 ? 'Unreserve' : 'Reserve'}
          leftSection={<MotionPhotosPausedFillIcon />}
          data-pendo-name="Reserve/Unreserve MenuItem"
          rightSection={
            needsReserveInventoryHint && (
              <Badge ml={2} variant="filled" color="blue" size="xs" w={50}>
                NEW
              </Badge>
            )
          }
          onClick={() => {
            if (needsReserveInventoryHint) {
              markFeatureSeen('reserve-inventory');
            }
          }}
        >
          <ReserveUnreserveAction onClose={onClose} listing={listing} />
        </BNAccordionMenu.Panel>
      )}
      {isDtiHosted && tenantPointOfSale === 'DtiPortal' && (
        <>
          <BNAccordionMenu.Panel
            id="reserve_unreserve"
            title={listing.quantityReserved > 0 ? 'Unreserve' : 'Reserve'}
            leftSection={<MotionPhotosPausedFillIcon />}
            data-pendo-name="Reserve/Unreserve MenuItem"
          >
            <ReserveUnreserveForm onClose={onClose} />
          </BNAccordionMenu.Panel>
          <BNAccordionMenu.Item
            title="Edit Properties"
            disabled={listing.quantityReserved > 0}
            disabledText={listing.quantityReserved > 0 ? 'Cannot edit properties of listings on hold' : undefined}
            leftSection={<EditNoteIcon />}
            onClick={() => {
              setCurrentDtiModal(DtiModals.EditProperties);
            }}
            data-pendo-name="Edit DTI Properties"
          />
          <BNAccordionMenu.Item
            title="Add PDFs"
            disabled={pdfDisabledText !== ''}
            disabledText={pdfDisabledText !== '' ? pdfDisabledText : undefined}
            leftSection={<PictureAsPDFIcon />}
            onClick={() => {
              setCurrentDtiModal(DtiModals.AddPdfs);
            }}
            data-pendo-name="Add DTI PDFs"
          />
          <BNAccordionMenu.Item
            title="Sell Privately"
            disabled={listing.quantityReserved > 0}
            disabledText={listing.quantityReserved > 0 ? 'Cannot sell listings on hold' : undefined}
            leftSection={<PointOfSaleIcon />}
            onClick={() => {
              setCurrentDtiModal(DtiModals.SellPrivately);
            }}
            data-pendo-name="Sell Privately MenuItem"
          />

          <BNAccordionMenu.Panel id="delete_listing" title="Delete Listing" leftSection={<DeleteIcon />} data-pendo-name="Delete Listing MenuItem">
            <DeleteListingForm onClose={onClose} />
          </BNAccordionMenu.Panel>
        </>
      )}
      <BNAccordionMenu.Item
        title="View History"
        leftSection={<HistoryIcon />}
        onClick={() => {
          onClose();
          setSelectedHistoryListing({ listing, isOpen: true });
        }}
        data-pendo-name="View Listing History MenuItem"
      />
      <BNAccordionMenu.Item
        title="Request Support"
        leftSection={<LiveHelpIcon />}
        onClick={() => {
          onClose();
          openSupport();
        }}
      />
      {currentUser?.roleId === 'GlobalAdmin' && (
        <>
          <BNAccordionMenu.Panel id="admin_functions" title="Admin Functions" leftSection={<SettingsIcon />} className={classes.bnAdminAccordionPanel}>
            <BNAccordionMenu.Item
              leftSection={<ContentCopyIcon />}
              title="Copy Rule ID"
              disabled={!ruleId}
              onClick={() => {
                navigator.clipboard.writeText(ruleId!).then(() => onClose());
              }}
            />
            {/* <BNAccordionMenu.Item
              leftSection={<OpenNewIcon />}
              title="View Logs"
              disabled={!ruleId}
              onClick={() => {
                if (import.meta.env.VITE_APP_INSIGHTS_KEY === 'ceb3c621-0efb-4e2e-ae39-66384b7f86a5') {
                  window.open(
                    `https://portal.azure.com#@9e85d428-80a3-4ff9-b28b-e83bcaec60fb/blade/Microsoft_OperationsManagementSuite_Workspace/Logs.ReactView/resourceId/%2Fsubscriptions%2F99da547d-fe1e-46e0-95d0-4209106c7991%2FresourceGroups%2Fbrokernerds%2Fproviders%2Fmicrosoft.insights%2Fcomponents%2Fprod-insights/source/LogsBlade.AnalyticsShareLinkToQuery/query/union%20*%0A%7C%20where%20timestamp%20%3E%20ago(2h)%0A%7C%20where%20customDimensions.ApplicationName%20%3D%3D%20%22Barker%22%0A%7C%20where%20customDimensions.RuleId%20%3D%3D%20%22${ruleId}%22%0A%7C%20order%20by%20timestamp%20desc`,
                    '_blank',
                  );
                } else {
                  window.open(
                    `https://portal.azure.com#@9e85d428-80a3-4ff9-b28b-e83bcaec60fb/blade/Microsoft_OperationsManagementSuite_Workspace/Logs.ReactView/resourceId/%2Fsubscriptions%2F99da547d-fe1e-46e0-95d0-4209106c7991%2FresourceGroups%2Fbrokernerds-dev%2Fproviders%2Fmicrosoft.insights%2Fcomponents%2Fdev-insights/source/LogsBlade.AnalyticsShareLinkToQuery/query/union%20*%0A%7C%20where%20timestamp%20%3E%20ago(2h)%0A%7C%20where%20customDimensions.ApplicationName%20%3D%3D%20%22Barker%22%0A%7C%20where%20customDimensions.RuleId%20%3D%3D%20%22${ruleId}%22%0A%7C%20order%20by%20timestamp%20desc`,
                    '_blank',
                  );
                }
                onClose();
              }}
            /> */}
            <BNAccordionMenu.Item
              leftSection={<ContentCopyIcon />}
              title="Copy Listing ID"
              onClick={() => {
                navigator.clipboard.writeText(listing.listingId).then(() => onClose());
              }}
            />
            <BNAccordionMenu.Item
              leftSection={<ContentCopyIcon />}
              title="Copy Tenant ID"
              onClick={() => {
                navigator.clipboard.writeText(listing.tenantId).then(() => onClose());
              }}
            />
            {isDev && (
              <>
                <BNAccordionMenu.Item
                  leftSection={<DeleteIcon />}
                  title="Remove Rule"
                  disabled={!ruleId}
                  onClick={() => {
                    onClose();
                    deleteApiPricingRulesRuleId(ruleId!);
                    listingsMatchingRuleId.forEach((_listing) => {
                      patchApiInventoryListingsListingId(
                        _listing.listingId,
                        [
                          { op: 'remove', path: '/ruleId' },
                          { op: 'replace', path: '/pricerStatusId', value: 'None' },
                        ],
                        {
                          headers: {
                            'x-tenant-id': _listing.tenantId,
                          },
                        },
                      );
                      updateListing({
                        ..._listing,
                        ruleId: null,
                        pricerStatusId: 'None',
                        floorPrice: null,
                        ceilingPrice: null,
                      });
                    });
                  }}
                />
                <BNAccordionMenu.Item
                  leftSection={<DeleteIcon />}
                  title="Remove Listing"
                  disabled={!ruleId}
                  onClick={() => {
                    onClose();
                    ignoreListing((prev) => [...prev, listing.tenantIdListingId]);
                  }}
                />
              </>
            )}
          </BNAccordionMenu.Panel>
        </>
      )}
    </>
  );
};
