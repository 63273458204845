import { Box, Center, Divider, Flex, SimpleGrid, Space, Text, UnstyledButton } from '@mantine/core';
import { BNButton } from '../../../components/Button/Button';
import { MobileMarketButton } from '../../SeatingChart/SeatingChart.MarketplaceSelector.Mobile';
import MarketplaceStubHubIcon from '../../../components/icons/MarketplaceStubHub';
import MarketplaceVividIcon from '../../../components/icons/MarketplaceVivid';
import MarketplaceTicketmasterIcon from '../../../components/icons/MarketplaceTicketmaster';
import MarketplaceAXSIcon from '../../../components/icons/MarketplaceAXS';
import MarketplaceGoTicketsIcon from '../../../components/icons/MarketplaceGoTickets';
import MarketplaceSeatGeekIcon from '../../../components/icons/MarketplaceSeatGeek';
import MarketplaceTickPickIcon from '../../../components/icons/MarketplaceTickPick';
import MarketplaceTicketEvolutionIcon from '../../../components/icons/MarketplaceTicketEvolution';
import MarketplaceTicketNetworkIcon from '../../../components/icons/MarketplaceTicketNetwork';
import MarketplaceGametimeIcon from '../../../components/icons/MarketplaceGametime';
import { useState } from 'react';
import { BNSwitch } from '../../../components/Switch/Switch';
import SelectAllIcon from '../../../components/icons/SelectAll';
import { useSetAtom } from 'jotai/index';
import { updateListingsAtom } from '../../../data/atoms';
import { useDTIInventory } from '../../DTI/DTI.Inventory.hooks';
import { DTIMarketplaces, DTIMarketplacesItems, Marketplace } from '../../../utils/dti-utils';
import { BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal } from '../../../api';

export type BroadcastingOptionsFormProps = {
  closeModal: () => void;
};

export function BroadcastingOptionsForm({ closeModal }: BroadcastingOptionsFormProps) {
  const updateListing = useSetAtom(updateListingsAtom);
  const { vendorProperties, editSharing, isUpdatingListing, selectedListing } = useDTIInventory('vendorProperties', 'editSharing', 'isUpdatingListing', 'selectedListing');

  const [omittedMarketplaces, setOmittedMarketplaces] = useState(
    vendorProperties?.confirmationNumber?.replace('-', '').replace('S', '').replace('F', '').replace('M', '').replace('I', '') ?? '',
  );

  const handleSelectionChange = (marketplace: Marketplace) => {
    setOmittedMarketplaces((prev) => {
      if (prev.includes(marketplace.value)) {
        return prev.replace(marketplace.value, '');
      }
      return prev + marketplace.value;
    });
  };

  const toggleAllMarketplaces = () => {
    omittedMarketplaces === '' ? setOmittedMarketplaces(Array.from(new Set(Object.values(DTIMarketplacesItems))).join('')) : setOmittedMarketplaces('');
  };

  const [isBroadcasting, setIsBroadcasting] = useState(selectedListing?.isBroadcasting ?? false);
  const activeMarketplaceSize = new Set(Object.values(DTIMarketplaces).map((x) => x.value)).size;

  const allSelected = omittedMarketplaces.length === 0;
  const checkedCount = Math.abs(omittedMarketplaces.length - activeMarketplaceSize);

  if (!selectedListing || !vendorProperties) {
    return null;
  }

  return (
    <>
      <Flex p={0} h="100%" justify="space-between">
        <Flex py="sm" px="md" gap="xs" align="center" justify="space-between" style={{ flex: 1 }}>
          <Text size="md" fw={600}>
            Broadcasting
          </Text>
          <Flex align="center" pr={4}>
            <BNSwitch
              size="sm"
              label={
                <Text component="span" tt="uppercase" w={30}>
                  <Text component="span" fw={600} c="var(--colors-selectedBorder)">
                    {checkedCount}
                  </Text>
                  <Text component="span" c="var(--colors-gray-5)">
                    /{activeMarketplaceSize}
                  </Text>
                </Text>
              }
              checked={isBroadcasting}
              onChange={() => {
                setIsBroadcasting((prev) => !prev);
              }}
            />
          </Flex>
        </Flex>
      </Flex>
      <Divider color="var(--colors-divider)" />
      <Box px="md" pt="xs" pb="lg" w="100%">
        <Text c="var(--colors-gray-5)" py={8}>
          Marketplaces
        </Text>
        <SimpleGrid cols={4} px={4} spacing="xs">
          <MobileMarketButton
            selected={!omittedMarketplaces.includes(DTIMarketplaces.StubHub.value)}
            onClick={() => handleSelectionChange(DTIMarketplaces.StubHub)}
            title="StubHub"
            img={<MarketplaceStubHubIcon />}
          />
          <MobileMarketButton
            selected={!omittedMarketplaces.includes(DTIMarketplaces['Vivid Seats'].value)}
            onClick={() => handleSelectionChange(DTIMarketplaces['Vivid Seats'])}
            title="Vivid Seats"
            img={<MarketplaceVividIcon />}
          />
          <MobileMarketButton
            selected={!omittedMarketplaces.includes(DTIMarketplaces.Ticketmaster.value)}
            onClick={() => handleSelectionChange(DTIMarketplaces.Ticketmaster)}
            title="Ticketmaster"
            img={<MarketplaceTicketmasterIcon />}
          />
          <MobileMarketButton
            selected={!omittedMarketplaces.includes(DTIMarketplaces.AXS.value)}
            onClick={() => handleSelectionChange(DTIMarketplaces.AXS)}
            title="AXS"
            img={<MarketplaceAXSIcon />}
          />
          <MobileMarketButton
            selected={!omittedMarketplaces.includes(DTIMarketplaces.GoTickets.value)}
            onClick={() => handleSelectionChange(DTIMarketplaces.GoTickets)}
            title="GoTickets"
            img={<MarketplaceGoTicketsIcon />}
          />
          <MobileMarketButton
            selected={!omittedMarketplaces.includes(DTIMarketplaces.SeatGeek.value)}
            onClick={() => handleSelectionChange(DTIMarketplaces.SeatGeek)}
            title="SeatGeek"
            img={<MarketplaceSeatGeekIcon />}
          />
          <MobileMarketButton
            selected={!omittedMarketplaces.includes(DTIMarketplaces.TickPick.value)}
            onClick={() => handleSelectionChange(DTIMarketplaces.TickPick)}
            title="TickPick"
            img={<MarketplaceTickPickIcon />}
          />
          <MobileMarketButton
            selected={!omittedMarketplaces.includes(DTIMarketplaces['Ticket Evolution'].value)}
            onClick={() => handleSelectionChange(DTIMarketplaces['Ticket Evolution'])}
            title="Ticket Evolution"
            img={<MarketplaceTicketEvolutionIcon />}
          />
          <MobileMarketButton
            selected={!omittedMarketplaces.includes(DTIMarketplaces['Ticket Network'].value)}
            onClick={() => handleSelectionChange(DTIMarketplaces['Ticket Network'])}
            title="TicketNetwork"
            img={<MarketplaceTicketNetworkIcon />}
          />
          <MobileMarketButton
            selected={!omittedMarketplaces.includes(DTIMarketplaces.Gametime.value)}
            onClick={() => handleSelectionChange(DTIMarketplaces.Gametime)}
            title="Gametime"
            img={<MarketplaceGametimeIcon />}
          />
          <Space />
          <UnstyledButton
            h={96}
            py="sm"
            style={{ border: '1px solid transparent', borderRadius: 6, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12 }}
            onClick={toggleAllMarketplaces}
          >
            <Center h={44} w={44} tt="uppercase" fz={12} fw="600" c="var(--colors-gray-5)" style={{ border: '1px solid var(--colors-gray-2)', borderRadius: 44 }}>
              <SelectAllIcon size={24} />
            </Center>
            <Text fz={10} truncate maw="100%" px={2}>
              {allSelected ? 'Clear All' : 'Select All'}
            </Text>
          </UnstyledButton>
        </SimpleGrid>
      </Box>
      <Divider color="var(--colors-gray-0)" />
      <Flex gap="sm" w="100%" py="xl" px="md" mb="var(--safe-area-inset-bottom, 0)">
        <BNButton fullWidth variant="default" onClick={closeModal} disabled={isUpdatingListing}>
          Cancel
        </BNButton>
        <BNButton
          fullWidth
          variant="filled"
          color="green"
          type="submit"
          loading={isUpdatingListing}
          onClick={() => {
            editSharing(selectedListing, isBroadcasting, omittedMarketplaces).then(() => {
              const newProperties = { ...vendorProperties, confirmationNumber: omittedMarketplaces } satisfies BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal;

              updateListing({
                ...selectedListing,
                isBroadcasting,
                vendorProperties: newProperties,
              });

              closeModal();
            });
          }}
        >
          Save
        </BNButton>
      </Flex>
    </>
  );
}
