import { ActionIcon, Box, Checkbox, Divider, Flex, Group, MultiSelect, Space, Stack, Text, Textarea, Tooltip } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import DeleteIcon from '../../components/icons/Delete';
import { BNNumberInput } from '../../components/NumberInput/NumberInput';
import { BNSelect } from '../../components/Select/Select';
import { BNTextInput } from '../../components/TextInput/TextInput';
import { Barcodes } from './Purchase.Form.Barcodes';
import { disclosures_options, location_options, stock_options, usePurchaseState } from './Purchase.hooks';
import cx from 'clsx';
import classes from './Purchase.module.css';
import { BNDatePicker } from '../../components/DatePicker/DatePicker';
import DateRangeIcon from '../../components/icons/DateRange';
import { UseFormReturnType } from '@mantine/form';
import { Order, OrderEdit, TicketGroup, TicketGroupEdit } from '../../types';
import { BarkerCoreModelsDTIAccountPermissions } from '../../api';

type OrderItemProps<T extends TicketGroup | TicketGroupEdit> = {
  groupIndex: number;
  index: number;
  order: T['orders'][0];
  group: T;
  form: UseFormReturnType<{ groups: T[] }>;
  activeListing?: { groupIndex: number; index: number };
  setActiveListing?: ReturnType<typeof usePurchaseState>['setActiveListing'];
  copyListing?: ReturnType<typeof usePurchaseState>['copyListing'];
  addListing?: ReturnType<typeof usePurchaseState>['addListing'];
  targetRef?: ReturnType<typeof usePurchaseState>['targetRef'];
  calculateSeatRange: NonNullable<ReturnType<typeof usePurchaseState>['calculateSeatRange']>;
  ticketAssetsLoading?: boolean;
  permissions?: BarkerCoreModelsDTIAccountPermissions;
  isBroadcasting?: boolean;
};

export function OrderItem<T extends TicketGroup | TicketGroupEdit>({
  groupIndex,
  index,
  order,
  group,
  activeListing,
  setActiveListing,
  form,
  calculateSeatRange,
  addListing,
  copyListing,
  targetRef,
  ticketAssetsLoading,
  permissions,
  isBroadcasting,
}: OrderItemProps<T>) {
  const formatDetails = (_order: Order | OrderEdit) => {
    const seatRange = calculateSeatRange(_order.seat_from, _order.quantity, _order.odd_even || false);
    return `Row ${_order.row || '#'} · Seats ${seatRange || '#'} · Quantity ${_order.quantity || '#'}`;
  };
  const showEditFields = !activeListing;

  // Permissions are undefined for Add Inventory, but will be populated for Edit Properties
  const canEditCost = permissions === undefined || permissions.item.edit.cost.enabled === '1';
  const canEditCreditCard = permissions === undefined || permissions.cc_info.enabled === '1';

  return (
    <Box
      bg="var(--colors-paper)"
      key={order.key}
      id={order.key}
      px={16}
      ref={activeListing && activeListing.groupIndex === groupIndex && activeListing.index === index ? targetRef : undefined}
      className={cx(
        !activeListing ? null : activeListing && activeListing.groupIndex === groupIndex && activeListing.index === index ? classes.activeListing : classes.collapsedListing,
        !activeListing ? classes.editCard : classes.listingCard,
      )}
    >
      {activeListing && (
        <>
          <Group className={classes.listingCardHeader} justify="space-between" py="xs">
            <Box>
              <Text fw={600}>{form.getInputProps(`groups.${groupIndex}.orders.${index}.section`).value || 'New Listing'}</Text>
              {activeListing && activeListing.groupIndex === groupIndex && activeListing.index === index ? (
                <Text c="var(--colors-gray-5)">Editing Listing</Text>
              ) : (
                <Text c="var(--colors-gray-5)">{formatDetails(order)}</Text>
              )}
            </Box>
            {setActiveListing && (
              <Group className={classes.listingCardHeaderActions} w={64}>
                <BNButton
                  size="xs"
                  fullWidth
                  variant="default"
                  onClick={() => {
                    const { hasErrors } = form.validate();
                    if (!hasErrors) {
                      setActiveListing({ groupIndex, index });
                    }
                  }}
                >
                  Edit
                </BNButton>
              </Group>
            )}
          </Group>
          <Divider mx={-16} color="var(--colors-borderDivider)" />
        </>
      )}

      <Flex gap="lg" pt="xs" className={classes.orderItemFields}>
        <Stack gap={6} maw={416} mx="auto">
          <Flex gap="md">
            <Box w={272}>
              <BNTextInput maxLength={50} size="xs" clearable label="Section" {...form.getInputProps(`groups.${groupIndex}.orders.${index}.section`)} />
            </Box>
            <Box w={128}>
              <BNTextInput maxLength={50} size="xs" clearable label="Row" {...form.getInputProps(`groups.${groupIndex}.orders.${index}.row`)} />
            </Box>
          </Flex>
          {!showEditFields && (
            <Flex gap="md">
              <Box w={128}>
                <BNNumberInput size="xs" label="Quantity" {...form.getInputProps(`groups.${groupIndex}.orders.${index}.quantity`)} />
              </Box>
              <Box w={128}>
                <BNNumberInput size="xs" label="Seat From" {...form.getInputProps(`groups.${groupIndex}.orders.${index}.seat_from`)} />
              </Box>
              <Flex w={128} h={30} justify="start" gap="sm">
                <BNSelect
                  size="xs"
                  data={[
                    { label: 'Consecutive', value: '' },
                    { label: 'Odd/Even', value: 'true' },
                  ]}
                  value={form.values.groups[groupIndex].orders[index].odd_even ? 'true' : ''}
                  onChange={(value) => {
                    form.setFieldValue(`groups.${groupIndex}.orders.${index}.odd_even`, !!value);
                  }}
                  label="Seating Type"
                />
              </Flex>
            </Flex>
          )}
          <Flex gap="md">
            <Box w={128}>
              <Tooltip label="Insufficient permissions" disabled={canEditCost} withArrow>
                <BNNumberInput
                  leftSection={<Text>$</Text>}
                  size="xs"
                  label="Cost"
                  selectOnFocus
                  {...form.getInputProps(`groups.${groupIndex}.orders.${index}.cost`)}
                  fixedDecimalScale
                  decimalScale={2}
                  disabled={!canEditCost}
                />
              </Tooltip>
            </Box>
            {activeListing && (
              <Box w={128}>
                <BNNumberInput
                  leftSection={<Text>$</Text>}
                  selectOnFocus
                  size="xs"
                  label="Price"
                  {...form.getInputProps(`groups.${groupIndex}.orders.${index}.price`)}
                  fixedDecimalScale
                  decimalScale={2}
                />
              </Box>
            )}
            {showEditFields && (
              <Box w={128}>
                <BNSelect
                  label="Location"
                  size="xs"
                  variant="default"
                  disabled={!!(form.values.groups[groupIndex].orders[index].location_id === '23') || isBroadcasting}
                  value={form.values.groups[groupIndex].orders[index].location_id?.toString()}
                  onChange={(value) => {
                    form.setFieldValue(`groups.${groupIndex}.orders.${index}.location_id`, parseInt(value ?? ''));
                  }}
                  data={
                    location_options
                      .filter((x) => x.primary)
                      .map((x) => x.value)
                      .includes(form.values.groups[groupIndex].orders[index].location_id?.toString() || '')
                      ? location_options.filter((l) => l.primary)
                      : location_options
                  }
                />
              </Box>
            )}
            {!showEditFields && (
              <Box w={128}>
                <BNSelect
                  label="Stock"
                  size="xs"
                  variant="default"
                  value={form.values.groups[groupIndex].orders[index].stock?.toString()}
                  onChange={(value) => {
                    form.setFieldValue(`groups.${groupIndex}.orders.${index}.stock`, parseInt(value ?? ''));
                  }}
                  data={stock_options}
                />
              </Box>
            )}
            {!activeListing && !showEditFields && (
              <Flex w={128} h={30} pt={38} align="center" justify="start" gap="sm" style={{ flexShrink: 0 }}>
                <Checkbox size="xs" label="Multiday" {...form.getInputProps(`groups.${groupIndex}.orders.${index}.multiday`, { type: 'checkbox' })} />
              </Flex>
            )}

            {showEditFields && (
              <Box w={128}>
                <BNNumberInput size="xs" label="Seat From" {...form.getInputProps(`groups.${groupIndex}.orders.${index}.seat_from`)} disabled={isBroadcasting} />
              </Box>
            )}
          </Flex>
          <Flex gap="md">
            <Box w={activeListing ? 272 : '100%'}>
              <MultiSelect
                label="Disclosures"
                {...form.getInputProps(`groups.${groupIndex}.orders.${index}.disclosures`)}
                size="xs"
                data={disclosures_options}
                disabled={isBroadcasting}
              />
            </Box>
            {showEditFields && activeListing && (
              <Box w={128}>
                <BNDatePicker label="Inhand Date" size="xs" clearable valueFormat="MM/DD/YY" leftSection={<DateRangeIcon size={20} />} selectOnFocus />
              </Box>
            )}
            {!showEditFields && activeListing && (
              <Flex w={128} h={30} pt={40} align="center" justify="start" gap="sm">
                <Checkbox size="xs" label="Multiday" {...form.getInputProps(`groups.${groupIndex}.orders.${index}.multiday`, { type: 'checkbox' })} />
              </Flex>
            )}
          </Flex>
          <Flex gap="md">
            <Box w="100%">
              <Textarea
                size="xs"
                maxLength={255}
                label="Additional External Notes"
                {...form.getInputProps(`groups.${groupIndex}.orders.${index}.notes`)}
                disabled={isBroadcasting}
              />
            </Box>
            {showEditFields && activeListing && (
              <Flex w={128} h={30} pt={33} align="center" justify="start" gap="sm" style={{ flexShrink: 0 }}>
                <Checkbox size="xs" label="Multiday" {...form.getInputProps(`groups.${groupIndex}.orders.${index}.multiday`, { type: 'checkbox' })} />
              </Flex>
            )}
          </Flex>
          <Box pt={4} mb={-4}>
            {!showEditFields && group.eventIds.length > 1 && <Text>Note: Barcodes may only be added for single event groups</Text>}
            {!showEditFields && group.eventIds.length === 1 && (
              <Barcodes group={group} groupIndex={groupIndex} index={index} order={order} form={form} isEditForm={showEditFields} loading={ticketAssetsLoading} />
            )}
          </Box>
        </Stack>
        <Divider orientation="vertical" color="var(--colors-borderDivider)" />
        <Stack gap={6} w="100%" maw={416} mx="auto">
          <Flex gap="md">
            <Box w="100%">
              <BNTextInput size="xs" clearable maxLength={200} label="Account Info" placeholder="" {...form.getInputProps(`groups.${groupIndex}.orders.${index}.account_info`)} />
            </Box>
            <Box w="100%">
              <BNTextInput
                size="xs"
                clearable
                maxLength={200}
                label="Order Number"
                placeholder="XX-12345/ABC"
                {...form.getInputProps(`groups.${groupIndex}.orders.${index}.order_number`)}
              />
            </Box>
          </Flex>
          {showEditFields && (
            <Flex gap="md">
              <Box w="100%">
                <Tooltip label="Insufficient permissions" disabled={canEditCreditCard} withArrow>
                  <BNTextInput
                    size="xs"
                    clearable
                    maxLength={4}
                    type="number"
                    label="CC Info"
                    placeholder="1234"
                    disabled={!canEditCreditCard}
                    {...form.getInputProps(`groups.${groupIndex}.orders.${index}.cc_info_card`)}
                  />
                </Tooltip>
              </Box>
              <Box w="100%">
                <Tooltip label="Insufficient permissions" disabled={canEditCreditCard} withArrow>
                  <BNTextInput
                    size="xs"
                    clearable
                    maxLength={200}
                    label="Cardholder Name"
                    placeholder="John Doe"
                    disabled={!canEditCreditCard}
                    {...form.getInputProps(`groups.${groupIndex}.orders.${index}.cc_info_purchaser`)}
                  />
                </Tooltip>
              </Box>
            </Flex>
          )}
          <Box w="100%">
            <BNTextInput
              size="xs"
              clearable
              maxLength={50}
              label="Purchaser Email"
              placeholder="purchaser@email.com"
              {...form.getInputProps(`groups.${groupIndex}.orders.${index}.purchaser`)}
            />
          </Box>
          {!showEditFields && (
            <>
              <Box w="100%">
                <Textarea size="xs" maxLength={255} label="Internal Notes" {...form.getInputProps(`groups.${groupIndex}.orders.${index}.internal_notes`)} />
              </Box>
            </>
          )}
          {showEditFields && (
            <>
              <Box w="100%">
                <Textarea size="xs" maxLength={255} label="My Notes" {...form.getInputProps(`groups.${groupIndex}.orders.${index}.internal_notes`)} />
              </Box>
              <Box w="100%">
                <Textarea size="xs" maxLength={255} label="Extra Notes" {...form.getInputProps(`groups.${groupIndex}.orders.${index}.extra_note`)} />
              </Box>
            </>
          )}
        </Stack>
      </Flex>
      {showEditFields && (
        <>
          <Divider mt={24} mb={0} color="var(--colors-borderDivider)" />
          <Flex direction="column">
            <Barcodes group={group} groupIndex={groupIndex} index={index} order={order} form={form} isEditForm={showEditFields} loading={ticketAssetsLoading} />
          </Flex>
        </>
      )}
      {activeListing ? (
        <>
          <Divider mt={24} mb={12} color="var(--colors-borderDivider)" />
          <Flex w="100%" justify="space-between">
            <Tooltip label="Remove Listing" withArrow>
              <ActionIcon
                className={classes.btnFocusStyles}
                onClick={() => {
                  form.removeListItem(`groups.${groupIndex}.orders`, index);
                }}
              >
                <DeleteIcon />
              </ActionIcon>
            </Tooltip>
            <Group h="100%" align="flex-start">
              {copyListing && (
                <Tooltip label="Copy Down Listing" withArrow>
                  <BNButton
                    className={classes.btnFocusStyles}
                    size="xs"
                    onClick={() => {
                      copyListing(groupIndex, index);
                    }}
                  >
                    Copy Down
                  </BNButton>
                </Tooltip>
              )}
              {addListing && (
                <Tooltip label="Add New Listing" withArrow>
                  <BNButton
                    className={classes.btnFocusStyles}
                    size="xs"
                    onClick={() => {
                      addListing(groupIndex, true);
                    }}
                  >
                    Add New
                  </BNButton>
                </Tooltip>
              )}
            </Group>
          </Flex>
        </>
      ) : (
        <Space h={24} />
      )}
    </Box>
  );
}
