import { IconPropsBase } from './IconPropsBase';

export default function MarketplaceTicketEvolutionIcon({ size = 24, color = 'currentColor' }: IconPropsBase) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <rect fill={color} x="14.97" y="11.6" width="6.3" height=".4" />
      <path fill={color} d="M14.97,12.6v5.55h.85c.48-.81,1.36-1.31,2.3-1.31s1.82.5,2.3,1.31h.85v-5.55h-6.3Z" />
      <path fill={color} d="M18.12,7.16c-.94,0-1.82-.5-2.3-1.31h-.85v3.86h6.3v-3.86h-.85c-.48.81-1.36,1.31-2.3,1.31Z" />
      <path
        fill={color}
        d="M19.88,5.22c-.15.66-.88,1.17-1.76,1.17s-1.61-.5-1.76-1.17h-2.12v13.55h2.09c0-.79.8-1.43,1.79-1.43s1.79.64,1.79,1.43h2.09V5.22h-2.12ZM21.31,18.19h-.92c-.47-.81-1.34-1.31-2.27-1.31s-1.8.5-2.27,1.3h0s-.92,0-.92,0V5.81h.92c.47.81,1.34,1.31,2.27,1.31s1.8-.5,2.27-1.3h0s.92,0,.92,0v12.38Z"
      />
      <path
        fill={color}
        d="M13.63,8.54c-.61,0-1.1-.49-1.1-1.1h-1.01c0,.61-.49,1.1-1.1,1.1s-1.1-.49-1.1-1.1h-1.06c-.02.57-.49,1.03-1.07,1.04v9.17c.6.01,1.08.5,1.08,1.1,0,.01,0,.02,0,.03h1.06c.02-.59.5-1.07,1.1-1.07s1.08.48,1.1,1.07h1.02c.02-.59.5-1.07,1.1-1.07,0,0,.01,0,.02,0v-9.17s-.01,0-.02,0ZM12.85,17.13h-4.88v-1.23h4.88v1.23ZM12.85,14.52h-4.88v-5.49h4.88v5.49Z"
      />
      <path
        fill={color}
        d="M6.03,9.96s0-.08.01-.12h-.29c0,.14-.11.26-.26.26s-.26-.11-.26-.26h-.25c0,.14-.11.26-.26.26s-.26-.11-.26-.26h-.25c0,.14-.11.26-.26.26s-.26-.11-.26-.26h-.25c0,.14-.11.26-.26.26s-.26-.11-.26-.26h-.29s.01.08.01.12c0,.37-.3.67-.67.67v7.48c.37,0,.67.3.67.67h.28c0-.14.11-.26.26-.26s.26.11.26.26h.25c0-.14.11-.26.26-.26s.26.11.26.26h.25c0-.14.11-.26.26-.26s.26.11.26.26h.25c0-.14.11-.26.26-.26s.26.11.26.26h.28c0-.37.3-.67.67-.67v-7.48c-.37,0-.67-.3-.67-.67ZM6.15,17.24h-.36v.33h-.26v-.33h-2.35v.33h-.26v-.33h-.37v-5.87h.37v5.87h.26v-5.87h2.35v5.87h.26v-5.87h.36v5.87Z"
      />
    </svg>
  );
}
