import { useDTIInventory } from '../DTI.Inventory.hooks';
import { useForm } from '@mantine/form';
import { BarkerCoreEnumsPointOfSale } from '../../../api';
import { Box, Container, Group, Textarea } from '@mantine/core';
import { FormHeader } from '../../Support/SupportForm';
import { BNSelect } from '../../../components/Select/Select';
import { BNButton } from '../../../components/Button/Button';
import { BarkerEventListing } from '../../../types';

const DTI_LISTING_OPTIONS = [
  { value: '1', label: 'Quantity is incorrect on StubHub.' },
  { value: '2', label: 'Tickets not showing as instant download on StubHub.' },
  { value: '3', label: 'Tickets are not on StubHub.' },
  { value: '4', label: 'Tickets are in the slop bucket on StubHub.' },
  { value: '5', label: 'Not mapping to TM+' },
  { value: '7', label: 'Tickets not on SeatGeek' },
  { value: '6', label: 'Other' },
];

export function DTISupportForm({ onClose, listing }: { onClose: () => void; listing: BarkerEventListing }) {
  const { reportIssue, isReportingIssue, vendorProperties } = useDTIInventory('reportIssue', 'isReportingIssue', 'vendorProperties');

  const form = useForm<{
    issue?: string;
    reason?: string;
  }>({
    validate: {
      issue: (value) => {
        if (!value) {
          return 'Subject is required';
        }
        return null;
      },
      reason: (value, values) => {
        if (!value && values.issue && values.issue === '6') {
          return 'Comments are required when subject is "Other"';
        }
        return null;
      },
    },
    validateInputOnChange: true,
  });

  return (
    <Container p={0}>
      <FormHeader type="inventoryListing" listing={listing} pointOfSaleId={BarkerCoreEnumsPointOfSale.DtiPortal} onClose={onClose} />
      <form
        onReset={() => {
          form.setValues({});
          onClose();
        }}
        onSubmit={form.onSubmit((values) => {
          reportIssue({
            accountId: vendorProperties?.ownerId ?? 0,
            data: [
              {
                issue: parseInt(values.issue ?? '0'),
                reason: values.reason ?? '',
                listing_id: listing.listingId,
              },
            ],
          }).then(() => onClose());
        })}
      >
        <Box m={-16} p="md">
          <BNSelect
            label="Subject *"
            size="xs"
            aria-label="Support Subject"
            placeholder="I need help with..."
            data={DTI_LISTING_OPTIONS}
            withinPortal
            {...form.getInputProps('issue')}
          />
          <Textarea
            size="xs"
            label={`Comments${form.values.issue === '6' ? ' *' : ''}`}
            aria-label="Support comments"
            mt="xs"
            styles={{
              input: {
                minHeight: 80,
              },
            }}
            placeholder="Please describe your issue..."
            minRows={4}
            {...form.getInputProps('reason')}
          />
          <Group mt="md" gap={12} wrap="nowrap" justify="right">
            <BNButton size="xs" fullWidth variant="default" type="reset" px="xs" maw={90}>
              Cancel
            </BNButton>
            <BNButton size="xs" variant="filled" color="green" fullWidth type="submit" loading={isReportingIssue} maw={120} px="xs" disabled={!form.isValid()}>
              Submit Request
            </BNButton>
          </Group>
        </Box>
      </form>
    </Container>
  );
}
