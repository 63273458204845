import { Box, Group, SimpleGrid, Skeleton, Text, Title } from '@mantine/core';
import { AdminContentHeader } from '../../hoc/Admin/AdminContentHeader';
import { Link, useLocation } from 'react-router-dom';
import { useBilling } from '../../hoc/Admin/Billing.hooks';
import { formatCurrency } from '../../utils/formatters';
import ChevronBackIcon from '../../components/icons/ChevronBack';
import { TransactionsTable } from './Invoice';
import classes from './Invoice.tsx.module.css';

export default function CurrentUsage() {
  const { currentInvoice, totalSales, totalFees, projectedFees, projectedSales, tenantPointOfSale, isLoading } = useBilling(
    'currentInvoice',
    'totalSales',
    'totalFees',
    'projectedFees',
    'projectedSales',
    'tenantPointOfSale',
    'isLoading',
  );
  const location = useLocation();

  const backlinkTo = location.pathname.replace(/\/invoices\/.*/, '');

  return (
    <>
      {!isLoading ? (
        <AdminContentHeader title="Current Usage" backlinkTo={backlinkTo} backlinkTitle="Billing Overview" sticky />
      ) : (
        <Box h={81}>
          <Group c="var(--colors-gray-5)" h={18} gap={0} align="center" justify="left" mt={-18} className={classes.backlink}>
            {backlinkTo && (
              <>
                <ChevronBackIcon size={12} />
                <Link to={backlinkTo}>
                  <Text size="xs">Billing Overview</Text>
                </Link>
              </>
            )}
          </Group>
          <Skeleton maw={180} h={24} mt={20} mb={10} />
        </Box>
      )}

      {/* <Group h={320} mb={48} bg="var(--colors-opacity-hover)" align="center" justify="center">
        <Text color="var(--colors-gray-5)">Usage Chart</Text>
      </Group> */}
      <SimpleGrid
        cols={4}
        style={{
          borderBottom: '1px solid var(--colors-gray-1)',
        }}
      >
        <Box pos="relative" pr="md" pb="xs" pt="md" style={{ borderLeft: 'none' }}>
          {!isLoading ? (
            <Text fw={600} fz={11} tt="uppercase" c="var(--colors-gray-5)" lts=".025em">
              Current Sales
            </Text>
          ) : (
            <Skeleton maw={120} h={8} mt={4} mb={14} />
          )}
          {!isLoading ? (
            <Title mt={2} mb={6} order={4}>
              {formatCurrency(totalSales)}
            </Title>
          ) : (
            <Skeleton maw={120} h={16} mt={12} mb={4} />
          )}
        </Box>
        <Box pos="relative" pl="md" pb="xs" pt="md" style={{ borderLeft: '1px solid var(--colors-gray-1)' }}>
          {!isLoading ? (
            <Text fw={600} fz={11} tt="uppercase" c="var(--colors-gray-5)" lts=".025em">
              Current Fees
            </Text>
          ) : (
            <Skeleton maw={120} h={8} mt={4} mb={14} />
          )}
          {!isLoading ? (
            <Title mt={2} mb={6} order={4}>
              {formatCurrency(totalFees)}
            </Title>
          ) : (
            <Skeleton maw={120} h={16} mt={12} mb={4} />
          )}
        </Box>
        <Box pos="relative" pl="md" pb="xs" pt="md" mih={77} style={{ borderLeft: '1px solid var(--colors-gray-1)' }}>
          {!isLoading ? (
            <Text fw={600} fz={11} tt="uppercase" c="var(--colors-gray-5)" lts=".025em">
              Projected Sales
            </Text>
          ) : (
            <Skeleton maw={120} h={8} mt={4} mb={14} />
          )}
          {!isLoading ? (
            <Title mt={2} mb={6} order={4}>
              {formatCurrency(projectedSales)}
            </Title>
          ) : (
            <Skeleton maw={120} h={16} mt={12} mb={4} />
          )}
        </Box>
        <Box pos="relative" pl="md" pb="xs" pt="md" style={{ borderLeft: '1px solid var(--colors-gray-1)' }}>
          {!isLoading ? (
            <Text fw={600} fz={11} tt="uppercase" c="var(--colors-gray-5)" lts=".025em">
              Projected Fees
            </Text>
          ) : (
            <Skeleton maw={120} h={8} mt={4} mb={14} />
          )}
          {!isLoading ? (
            <Title mt={2} mb={6} order={4}>
              {formatCurrency(projectedFees)}
            </Title>
          ) : (
            <Skeleton maw={120} h={16} mt={12} mb={4} />
          )}
        </Box>
      </SimpleGrid>
      <TransactionsTable items={currentInvoice} isLoading={isLoading} tenantPointOfSale={tenantPointOfSale} />
    </>
  );
}
