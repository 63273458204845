export class Marketplace extends String {
  constructor(
    public value: string,
    public name: string,
  ) {
    super(name);
  }
}

// NOTE: The order of this matters for the conf no display on DTI listing.
export const DTIMarketplacesItems = {
  Ticketmaster: 'T',
  SeatGeek: 'A',
  TickPick: 'P',
  'Ticket Network': 'D',
  'Ticket Evolution': 'O',
  // GoldStar: 'S',
  // TicketMonster: 'M',
  // 'Fanxchange': 'F',
  Gametime: 'G',
  // Viagogo: 'I',
  AXS: 'X',
  'Vivid Seats': 'V',
  GoTickets: 'C',
  StubHub: 'Z',
} as const;

export const DTIMarketplaces: Record<keyof typeof DTIMarketplacesItems, Marketplace> = Object.assign(
  {},
  ...Object.entries(DTIMarketplacesItems).map(([key, value]) => ({ [key]: new Marketplace(value, key) })),
);

export function getSelectedMarketplaces(omittedMarketplaces: string) {
  return Object.entries(DTIMarketplacesItems)
    .filter(([, value]) => !omittedMarketplaces.includes(value))
    .map(([key, value]) => new Marketplace(value, key));
}
