import DotGridGraph from '../../img/dot-grid-graph.svg';
import DotGridGraphDti from '../../img/dot-grid-graph-dti.svg';
import { isDtiHosted } from '../../utils/whitelabel-consts';

export default function DotsBackground() {
  return (
    <div className="dot-grid" aria-hidden="true">
      <img src={isDtiHosted ? DotGridGraphDti : DotGridGraph} alt="Dot Grid Graph" height="362" width="2541" aria-hidden="true" />
    </div>
  );
}
