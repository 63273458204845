import { IconPropsBase } from './IconPropsBase';

export default function LocalActivityIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M382.62-340 480-414l95.38 73.23-36.61-118.31 98.92-77.23H518.61L480-655.38l-38.61 119.07H322.31l96.92 77.23L382.62-340ZM172.31-180q-29.92 0-51.12-21.19Q100-222.39 100-252.31v-109.61q0-9.85 5.66-17.46 5.65-7.62 15.11-10.39 26.31-12.23 42.77-36.31Q180-450.15 180-480t-16.46-53.92q-16.46-24.08-42.77-36.31-9.46-2.77-15.11-10.39-5.66-7.61-5.66-17.46v-109.61q0-29.92 21.19-51.12Q142.39-780 172.31-780h615.38q29.92 0 51.12 21.19Q860-737.61 860-707.69v109.61q0 9.85-5.66 17.46-5.65 7.62-15.11 10.39-26.31 12.23-42.77 36.31Q780-509.85 780-480t16.46 53.92q16.46 24.08 42.77 36.31 9.46 2.77 15.11 10.39 5.66 7.61 5.66 17.46v109.61q0 29.92-21.19 51.12Q817.61-180 787.69-180H172.31Zm0-60h615.38q5.39 0 8.85-3.46t3.46-8.85V-342q-37-22-58.5-58.5T720-480q0-43 21.5-79.5T800-618v-89.69q0-5.39-3.46-8.85t-8.85-3.46H172.31q-5.39 0-8.85 3.46t-3.46 8.85V-618q37 22 58.5 58.5T240-480q0 43-21.5 79.5T160-342v89.69q0 5.39 3.46 8.85t8.85 3.46ZM480-480Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M390.62-338 480-407l89.38 68.23-34.61-109.31 90.92-72.23H515.68L480-629.38l-35.68 109.07H334.31l90.92 72.23L390.62-338ZM180.31-212q-26.62 0-45.47-18.8Q116-249.6 116-276.17v-95.87q0-8.73 5.08-15.73 5.09-7 13.65-9.61 24.35-11.62 38.81-33.2Q188-452.15 188-480t-14.46-49.42q-14.46-21.58-38.81-33.16-8.56-2.61-13.65-9.58-5.08-6.96-5.08-15.92v-95.8q0-26.54 18.84-45.33Q153.69-748 180.31-748h599.38q26.62 0 45.47 18.8Q844-710.4 844-683.83v95.87q0 8.73-5.08 15.73-5.09 7-13.65 9.61-24.35 11.62-38.81 33.2Q772-507.85 772-480t14.46 49.42q14.46 21.58 38.81 33.16 8.56 2.61 13.65 9.58 5.08 6.96 5.08 15.92v95.8q0 26.54-18.84 45.33Q806.31-212 779.69-212H180.31Zm0-52h599.38q5.39 0 8.85-3.46t3.46-8.85V-355q-32-19-52-52t-20-73q0-40 20-73t52-52v-78.69q0-5.39-3.46-8.85t-8.85-3.46H180.31q-5.39 0-8.85 3.46t-3.46 8.85V-605q32 19 52 52t20 73q0 40-20 73t-52 52v78.69q0 5.39 3.46 8.85t8.85 3.46ZM480-480Z"
        />
      </svg>
    );
  }
}
