import { useOAuth2 } from '../components/react-use-oauth2';

export function QuickBooksAuth() {
  // https://developer.intuit.com/app/developer/qbo/docs/develop/authentication-and-authorization/oauth-2.0

  const { data, loading, error, getAuth } = useOAuth2({
    authorizeUrl: 'https://appcenter.intuit.com/connect/oauth2',
    clientId: import.meta.env.VITE_QBO_CLIENT_ID,
    redirectUri: `${window.location.origin}/qbo/callback`,
    scope: 'com.intuit.quickbooks.accounting openid profile email phone address',
    responseType: 'code',
    exchangeCodeForTokenServerURL: `${import.meta.env.VITE_API_BASE_URL}/api/quickbooks/auth`,
    exchangeCodeForTokenMethod: 'POST',
    onSuccess: (payload) => {
      // eslint-disable-next-line no-console
      console.log('Success', payload);
    },
    onError: (error_) => {
      // eslint-disable-next-line no-console
      console.log('Error', error_);
    },
  });

  const isLoggedIntoQuickBooks = data?.access_token;

  if (error) {
    return <div>{error}</div>;
  }

  if (loading) {
    return <div>loading...</div>;
  }

  if (isLoggedIntoQuickBooks) {
    return <pre>{JSON.stringify(data)}</pre>;
  }

  return (
    <button style={{ margin: '24px' }} type="button" onClick={() => getAuth()}>
      Login
    </button>
  );
}
