import { IconPropsBase } from './IconPropsBase';

export default function SplitscreenIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M252.309-579.999h455.382q5.385 0 8.847-3.461 3.462-3.462 3.462-8.847v-195.384q0-5.385-3.462-8.847-3.462-3.462-8.847-3.462H252.309q-5.385 0-8.847 3.462-3.462 3.462-3.462 8.847v195.384q0 5.385 3.462 8.847 3.462 3.461 8.847 3.461Zm0 59.999q-30.308 0-51.308-21t-21-51.307v-195.384q0-30.308 21-51.308t51.308-21h455.382q30.308 0 51.308 21t21 51.308v195.384q0 30.307-21 51.307-21 21-51.308 21H252.309Zm0 360h455.382q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847v-195.384q0-5.385-3.462-8.847-3.462-3.461-8.847-3.461H252.309q-5.385 0-8.847 3.461-3.462 3.462-3.462 8.847v195.384q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462Zm0 59.999q-30.308 0-51.308-21t-21-51.308v-195.384q0-30.307 21-51.307 21-21 51.308-21h455.382q30.308 0 51.308 21t21 51.307v195.384q0 30.308-21 51.308t-51.308 21H252.309ZM240-579.999V-800v220.001ZM240-160v-220.001V-160Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M276.309-579.999h407.382q5.385 0 8.847-3.461 3.462-3.462 3.462-8.847v-187.384q0-5.385-3.462-8.847-3.462-3.462-8.847-3.462H276.309q-5.385 0-8.847 3.462-3.462 3.462-3.462 8.847v187.384q0 5.385 3.462 8.847 3.462 3.461 8.847 3.461Zm0 51.999q-26.308 0-45.308-19t-19-45.307v-187.384q0-27.008 19-45.658 19-18.65 45.308-18.65h407.382q26.308 0 45.308 18.65t19 45.658v187.384q0 26.307-19 45.307-19 19-45.308 19H276.309Zm0 360h407.382q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847v-187.384q0-5.385-3.462-8.847-3.462-3.461-8.847-3.461H276.309q-5.385 0-8.847 3.461-3.462 3.462-3.462 8.847v187.384q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462Zm0 51.999q-26.308 0-45.308-19t-19-45.308v-187.384q0-27.007 19-45.657 19-18.65 45.308-18.65h407.382q26.308 0 45.308 18.65t19 45.657v187.384q0 26.308-19 45.308t-45.308 19H276.309ZM264-579.999V-792v212.001ZM264-168v-212.001V-168Z"
        />
      </svg>
    );
  }
}
