import { IconPropsExtended } from './IconPropsExtended';

export default function AutoPriceIcon({ size = 20, color = 'currentColor', fill = false, weight = 'normal' }: IconPropsExtended) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M18.775 8.2 17.7 5.875 15.375 4.8 17.7 3.75l1.075-2.325 1.05 2.325 2.35 1.05-2.35 1.075Zm0 14.375L17.7 20.25l-2.325-1.05 2.325-1.075 1.075-2.325 1.05 2.325 2.35 1.075-2.35 1.05Zm-13.325-6.9 3.1-8.425h1.575l3.125 8.425H11.8l-.75-2h-3.4l-.75 2Zm2.575-3.1h2.65L9.35 8.8ZM9.35 18q2.5 0 4.25-1.75T15.35 12q0-2.5-1.75-4.25T9.35 6Q6.85 6 5.1 7.75T3.35 12q0 2.5 1.75 4.25T9.35 18Zm0 1.5q-3.15 0-5.325-2.175Q1.85 15.15 1.85 12q0-3.15 2.175-5.325Q6.2 4.5 9.35 4.5q3.125 0 5.313 2.175Q16.85 8.85 16.85 12q0 3.15-2.175 5.325Q12.5 19.5 9.35 19.5Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d={
            fill
              ? 'm15.479 7.438-1-2.188-2.167-1 2.167-1 1-2.188.979 2.188 2.188 1-2.188 1Zm0 11.479-1-2.188-2.187-1 2.187-.979 1-2.188.979 2.188 2.188.979-2.188 1ZM4.562 12.688 6.729 6.5h1.375l2.208 6.188H8.979l-.417-1.25h-2.27l-.417 1.25Zm2.063-2.23H8.25l-.812-2.562Zm.813 3.98q1.854 0 3.145-1.292 1.292-1.292 1.292-3.146 0-1.854-1.292-3.146-1.291-1.292-3.145-1.292-1.855 0-3.146 1.292Q3 8.146 3 10q0 1.854 1.292 3.146 1.291 1.292 3.146 1.292Zm0 1.645q-2.542 0-4.313-1.771Q1.354 12.542 1.354 10q0-2.542 1.771-4.312 1.771-1.771 4.313-1.771 2.541 0 4.312 1.771 1.771 1.77 1.771 4.312 0 2.542-1.771 4.312-1.771 1.771-4.312 1.771Z'
              : weight === 'bold'
              ? 'm15.479 7.438-1-2.188-2.167-1 2.167-1 1-2.188.979 2.188 2.188 1-2.188 1Zm0 11.479-1-2.188-2.187-1 2.187-.979 1-2.188.979 2.188 2.188.979-2.188 1ZM4.562 12.688 6.729 6.5h1.375l2.208 6.188H8.979l-.417-1.25h-2.27l-.417 1.25Zm2.063-2.23H8.25l-.812-2.562Zm.813 3.98q1.854 0 3.145-1.292 1.292-1.292 1.292-3.146 0-1.854-1.292-3.146-1.291-1.292-3.145-1.292-1.855 0-3.146 1.292Q3 8.146 3 10q0 1.854 1.292 3.146 1.291 1.292 3.146 1.292Zm0 1.645q-2.542 0-4.313-1.771Q1.354 12.542 1.354 10q0-2.542 1.771-4.312 1.771-1.771 4.313-1.771 2.541 0 4.312 1.771 1.771 1.77 1.771 4.312 0 2.542-1.771 4.312-1.771 1.771-4.312 1.771Z'
              : 'm15.438 7.229-.959-2.062-2.083-.959 2.083-.937.959-2.083.937 2.083 2.104.937-2.104.959Zm0 11.583-.959-2.083-2.083-.937 2.083-.959.959-2.062.937 2.062 2.104.959-2.104.937ZM4.542 12.938l2.583-7.021h1.313l2.604 7.021H9.833l-.625-1.667H6.375l-.625 1.667Zm2.146-2.584h2.208L7.792 7.208Zm1.104 4.563q2.041 0 3.479-1.438 1.437-1.437 1.437-3.479t-1.437-3.479Q9.833 5.083 7.792 5.083q-2.042 0-3.48 1.438Q2.875 7.958 2.875 10t1.427 3.479q1.427 1.438 3.49 1.438Zm0 1.333q-2.625 0-4.438-1.812Q1.542 12.625 1.542 10t1.812-4.438Q5.167 3.75 7.792 3.75q2.604 0 4.427 1.812Q14.042 7.375 14.042 10t-1.813 4.438q-1.812 1.812-4.437 1.812Z'
          }
        />
      </svg>
    );
  }
}
