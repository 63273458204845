import classes from './TableOfContents.tsx.module.css';
import { Group, Title, Text, Badge } from '@mantine/core';
import { NavLink } from 'react-router-dom';

interface TableOfContentsProps {
  label: React.ReactNode;
  links: { label: string; link: string; order: number; disabled?: boolean }[];
}

export function TableOfContents({ label, links }: TableOfContentsProps) {
  const items = links.map((item) =>
    !item.disabled ? (
      <NavLink to={item.link} key={item.label} className={classes.link}>
        {item.label}
      </NavLink>
    ) : (
      <Text component="div" className={`${classes.link} ${classes.disabledLink}`} pos="relative" key={item.label}>
        {item.label}
        <Badge color="var(--colors-gray-5)" variant="default" size="xs" pos="absolute" right={12} opacity={0.5}>
          Coming Soon
        </Badge>
      </Text>
    ),
  );

  return (
    <div>
      <Group mb="lg" pl={16}>
        <Title order={5}>{label}</Title>
      </Group>
      {items}
    </div>
  );
}
