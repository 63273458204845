import { ActionIcon, Badge, Box, Button, Container, Divider, Group, Menu, Popover, SimpleGrid, Text } from '@mantine/core';
import { useAtomValue } from 'jotai';
import { ReadOnly } from './NowPricing.ReadOnly';
import { RuleForm } from './NowPricing.RuleForm';
import { NowPricingProvider, useNowPricing } from './NowPricing.hooks';
import { ruleStateAtom, selectedTenantListingIdAtom } from '../../data/atoms';
import { PriceForm } from './NowPricing.PriceForm';
import { BNButton } from '../../components/Button/Button';
import { useCallback, useMemo } from 'react';
import { useRuleState } from '../../data/RuleState';
import WarningIcon from '../../components/icons/Warning';
import ArrowForwardIcon from '../../components/icons/ArrowForward';
import ArrowDropdownIcon from '../../components/icons/ArrowDropdown';
import { useDidUpdate } from '@mantine/hooks';
import { formatCurrency } from '../../utils/formatters';
import dayjs from 'dayjs';
import ErrorIcon from '../../components/icons/Error';
import { useGlobalState } from '../../data/GlobalState';
import { ScheduledForm } from './NowPricing.ScheduledForm';
import classes from './NowPricing.styles.tsx.module.css';
import ListIcon from '../../components/icons/List';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import { useFlag } from '@unleash/proxy-client-react';
import { useLocation } from 'react-router-dom';

export function NowPricing() {
  const selectedListingId = useAtomValue(selectedTenantListingIdAtom);
  const rule = useAtomValue(ruleStateAtom);
  const { formKey } = useRuleState('formKey');

  return (
    <NowPricingProvider key={formKey.toString() + selectedListingId.toString() + (rule.ruleId || '')}>
      <NowPricingImpl />
    </NowPricingProvider>
  );
}

function NowPricingImpl() {
  const {
    form,
    saveRuleAndPrice,
    isLoading,
    isFormReady,
    cancel,
    hasPendingUpdates,
    filtersEmpty,
    initiallyAutoPriced,
    selectedListing,
    isChangeConflict,
    refreshRule,
    isNoSectionRule,
    isDirty,
    closeNoSectionDialog,
    setIsDrasticChangeSnoozed,
    onSave,
    isDrasticChangeDialog,
    isNoSectionDialog,
    saving,
    closeDrasticChangeDialog,
  } = useNowPricing(
    'form',
    'saveRuleAndPrice',
    'isLoading',
    'isFormReady',
    'cancel',
    'hasPendingUpdates',
    'filtersEmpty',
    'initiallyAutoPriced',
    'selectedListing',
    'isChangeConflict',
    'refreshRule',
    'isNoSectionRule',
    'isDirty',
    'closeNoSectionDialog',
    'setIsDrasticChangeSnoozed',
    'onSave',
    'isDrasticChangeDialog',
    'isNoSectionDialog',
    'saving',
    'closeDrasticChangeDialog',
  );
  const { getUserName, currentUser, tenants } = useGlobalState('getUserName', 'currentUser', 'tenants');
  const updatedBy = isChangeConflict?.updatedBy;
  const updatedAt = isChangeConflict?.updatedAt;
  const user = getUserName(updatedBy);
  const formattedDate = dayjs(updatedAt).fromNow(true);
  const { tenantId } = useAtomValue(selectedTenantListingIdAtom);
  const allowSnooze = tenants?.find((x) => x.tenantId === tenantId)?.settings?.pricerSettings?.allowDrasticPriceAlertSnooze ?? true;
  const isRuleRoute = useLocation().pathname.includes('/rule');

  const disableSave =
    initiallyAutoPriced || form.values.isAutoPriced || form.values.isScheduled
      ? currentUser?.roleId === 'ReadOnlyUser' ||
        !isFormReady ||
        (form.values.isScheduled ? !form.isDirty() && !isDirty && !hasPendingUpdates : filtersEmpty || (!isDirty && !hasPendingUpdates))
      : currentUser?.roleId === 'ReadOnlyUser' || !isFormReady || !form.isDirty() || !form.isValid();

  const disableCancel =
    initiallyAutoPriced || form.values.isAutoPriced || form.values.isScheduled
      ? isLoading || !isFormReady || (form.values.isScheduled ? !form.isDirty() && !isDirty && !hasPendingUpdates : !isDirty && !hasPendingUpdates)
      : isLoading || !isFormReady || !form.isDirty();

  useDidUpdate(() => {
    if (!isNoSectionRule) {
      closeNoSectionDialog();
    }
  }, [isNoSectionRule]);

  const onConfirmSave = useCallback(() => {
    closeDrasticChangeDialog();
    saveRuleAndPrice();
  }, [closeDrasticChangeDialog, saveRuleAndPrice]);

  const percentChange = useMemo(() => {
    if (selectedListing && typeof form.values.listPrice === 'number') {
      const change = form.values.listPrice - selectedListing.unitPrice;
      return Math.round((change / selectedListing.unitPrice) * 100);
    }
    return 0;
  }, [form.values.listPrice, selectedListing]);

  const snooze = useCallback(
    (interval: 'day' | 'hour') => {
      setIsDrasticChangeSnoozed(dayjs().add(1, interval).toDate());
      saveRuleAndPrice();
    },
    [saveRuleAndPrice, setIsDrasticChangeSnoozed],
  );

  return (
    // <Container m={8} p={16} pt={32} pb={20} className={`${classes.wrapper} ${form.values.isScheduled ? 'scheduled' : ''}`}>
    <Container m={8} p={16} pt={32} pb={20} className={classes.wrapper}>
      <form onSubmit={onSave}>
        <Badge radius={0} fz={10} className={`slantBadge ${classes.slantBadge}`}>
          {isRuleRoute && !selectedListing ? 'Rule Template' : form.values.isScheduled ? 'Scheduled Pricing' : 'Now Pricing'}
        </Badge>
        {!isRuleRoute && !selectedListing && (
          <BNEmptyState h={80} title="No Listing Selected" border={false} description="Select a listing for pricing options" icon={<ListIcon size={28} />} />
        )}
        {isRuleRoute && !selectedListing && (
          <>
            <RuleForm />
          </>
        )}
        {selectedListing && (
          <>
            <ReadOnly />
            <Divider color="var(--colors-divider)" my="sm" mx="-1rem" />
            <PriceForm />
            {form.values.isAutoPriced && <RuleForm />}
            {form.values.isScheduled && <ScheduledForm />}
          </>
        )}
        {(isRuleRoute || selectedListing) && (
          <>
            <SimpleGrid mt={16} cols={2} spacing={12} w="100%">
              <BNButton disabled={disableCancel} size="xs" fullWidth variant="default" onClick={() => cancel()}>
                Cancel
              </BNButton>
              <Popover
                width={220}
                disabled={disableCancel}
                position="bottom"
                withArrow
                closeOnClickOutside
                onClose={() => closeDrasticChangeDialog()}
                opened={isDrasticChangeDialog || !!isChangeConflict || isNoSectionDialog}
              >
                <Popover.Target>
                  <BNButton type="submit" disabled={disableSave} loading={saving} size="xs" color="green" fullWidth variant="filled">
                    Save
                  </BNButton>
                </Popover.Target>
                <Popover.Dropdown p={0}>
                  {isNoSectionDialog && (
                    <Box>
                      <Group wrap="nowrap" p={12} align="start" gap={8}>
                        <Box>
                          <WarningIcon color="var(--colors-yellow-6)" />
                        </Box>
                        <Box style={{ flex: 1 }}>
                          {/* Title */}
                          <Text size="xs" fw="bold">
                            No Section Selected
                          </Text>
                          {/* Description */}
                          <Group gap={0}>
                            <Text size="xs">
                              You are attempting to save an auto-priced rule with no sections selected.
                              <br /> <br />
                              Are you sure you wish to continue?
                            </Text>
                          </Group>
                        </Box>
                      </Group>
                      <Divider color="var(--colors-divider)" />
                      {/* Dropdown buttons */}
                      <Button.Group>
                        <Group wrap="nowrap" gap={0} style={{ flex: 1 }}>
                          <BNButton radius={0} fullWidth variant="subtle" size="xs" onClick={() => onConfirmSave()} style={{ borderRight: '1px solid var(--colors-divider)' }}>
                            Confirm
                          </BNButton>
                          <BNButton onClick={() => closeNoSectionDialog()} radius={0} fullWidth variant="subtle" size="xs" maw="50%">
                            Cancel
                          </BNButton>
                        </Group>
                      </Button.Group>
                    </Box>
                  )}
                  {isDrasticChangeDialog && (
                    <Box>
                      <Group wrap="nowrap" p={12} align="start" gap={8}>
                        <Box>
                          <WarningIcon color="var(--colors-yellow-6)" />
                        </Box>
                        <Box style={{ flex: 1 }}>
                          {/* Title */}
                          <Text size="xs" fw="bold">
                            Drastic Price Change
                          </Text>
                          {/* Description */}
                          <Group gap={0}>
                            <Text size="xs">{formatCurrency(selectedListing?.unitPrice!)}</Text>
                            <ArrowForwardIcon color="var(--colors-gray-4)" />
                            <Text size="xs">{formatCurrency(form.values.listPrice)}</Text>
                          </Group>
                          {/* Secondary Description */}
                          <Text fz={11} c="var(--colors-gray-5)">
                            {Math.abs(percentChange)}% {percentChange > 0 ? 'Increase' : 'Decrease'}
                          </Text>
                        </Box>
                      </Group>
                      <Divider color="var(--colors-divider)" />
                      {/* Dropdown buttons */}
                      <Button.Group>
                        <BNButton
                          onClick={() => closeDrasticChangeDialog()}
                          radius={0}
                          fullWidth
                          variant="subtle"
                          size="xs"
                          maw="50%"
                          style={{ borderRight: '1px solid var(--colors-divider)' }}
                        >
                          Cancel
                        </BNButton>
                        <Group wrap="nowrap" gap={0} style={{ flex: 1 }}>
                          <BNButton radius={0} fullWidth variant="subtle" size="xs" onClick={() => onConfirmSave()}>
                            Confirm
                          </BNButton>
                          {/* Button wrapped in a menu to allow for snooze & extra options */}
                          {allowSnooze && (
                            <Menu withinPortal={false}>
                              <Menu.Target>
                                <ActionIcon radius={0} h="100%" style={{ borderLeft: '1px solid var(--colors-divider)' }}>
                                  <ArrowDropdownIcon />
                                </ActionIcon>
                              </Menu.Target>
                              <Menu.Dropdown>
                                <Menu.Item fz="xs" onClick={() => snooze('hour')}>
                                  Confirm & snooze for 1 hour
                                </Menu.Item>
                                <Menu.Item fz="xs" onClick={() => snooze('day')}>
                                  Confirm & snooze for 1 day
                                </Menu.Item>
                              </Menu.Dropdown>
                            </Menu>
                          )}
                        </Group>
                      </Button.Group>
                    </Box>
                  )}
                  {isChangeConflict && (
                    <Box>
                      <Group wrap="nowrap" p={12} align="start" gap={8}>
                        <Box>
                          <ErrorIcon fill color="var(--colors-red-error)" />
                        </Box>
                        <Box style={{ flex: 1 }}>
                          {/* Title */}
                          <Text size="xs" fw="bold">
                            Rule Conflict
                          </Text>
                          {/* Description */}
                          <Group gap={0}>
                            <Text size="xs">
                              {user} updated this rule{user === 'You' ? 'in another window.' : '.'}
                            </Text>
                          </Group>
                          {/* Secondary Description */}
                          <Text fz={11} c="gray.5">
                            {formattedDate}
                          </Text>
                        </Box>
                      </Group>
                      <Divider color="var(--colors-divider)" />
                      {/* Dropdown buttons */}
                      <Button.Group>
                        <Group wrap="nowrap" gap={0} style={{ flex: 1 }}>
                          <BNButton radius={0} fullWidth variant="subtle" size="xs" onClick={() => refreshRule()}>
                            Refresh
                          </BNButton>
                        </Group>
                      </Button.Group>
                    </Box>
                  )}
                </Popover.Dropdown>
              </Popover>
            </SimpleGrid>
          </>
        )}
      </form>
    </Container>
  );
}
