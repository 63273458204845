import { IconPropsBase } from './IconPropsBase';

export default function RocketLaunchIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="m216.769-535.152 88 37.231q16.308-32.616 34.77-63.039 18.462-30.424 40.693-59.885l-58.693-11.385q-3.077-.769-5.962.192-2.884.962-5.192 3.27l-93.616 93.616Zm135.845 74.922 113.616 113q44.692-18.692 92.692-51.692 48-33 91.154-76.154 67.308-67.308 104.115-146.653 36.808-79.346 36.423-163.27-83.924-.384-163.385 36.423-79.461 36.808-146.768 104.115-43.154 43.154-76.154 91.347-33 48.192-51.693 92.884Zm192.617-80.001q-20.308-20.307-20.308-49.384 0-29.076 20.308-49.384 20.307-20.307 49.884-20.307 29.576 0 49.884 20.307 20.307 20.308 20.307 49.384 0 29.077-20.307 49.384-20.308 20.308-49.884 20.308-29.577 0-49.884-20.308Zm-4.464 329.462 93.617-93.616q2.307-2.308 3.269-5.192.962-2.885.192-5.962l-11.384-58.693q-29.462 22.231-59.885 40.385-30.424 18.154-63.04 34.462l37.231 88.616Zm304.538-629.152q12.846 115.615-27.346 219.153T685.691-425.154l-3.462 3.461-3.462 3.462 18.077 90.538q3.615 18.077-1.615 35.154-5.231 17.076-18.077 29.923l-156.846 156.46-75.153-176.614-156.768-156.768-176.614-75.538 155.845-156.46q12.847-12.846 30.231-18.269 17.384-5.424 35.461-1.808l91.307 18.461q1.924-1.923 3.27-3.462 1.346-1.538 3.269-3.461 92.076-92.076 195.306-132.384 103.23-40.307 218.845-27.462ZM180.848-317.154q29.23-29.23 71.268-29.345 42.038-.116 71.268 29.115 29.231 29.23 28.923 71.268-.308 42.038-29.538 71.268-22.692 22.692-74.461 38.961t-132.844 26.038q9.769-81.075 26.23-132.844 16.462-51.768 39.154-74.461Zm42.768 42.538q-10.769 10.769-20.769 37.077-10 26.308-13.616 53.692 27.385-3.615 53.693-13.5 26.307-9.884 37.077-20.654 12-12 12.615-28.807.615-16.808-11.385-28.808t-28.807-11.5q-16.808.5-28.808 12.5Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="m231.769-523.152 88 30.231q17.308-32.616 36.77-63.539 19.462-30.924 41.693-60.385l-57.693-7.385q-3.077-.769-5.962.192-2.884.962-5.192 3.27l-97.616 97.616Zm131.845 56.922 101.616 102q50.692-27.692 93.692-57.192 43-29.5 82.154-68.654 57.308-57.308 94.115-124.153 36.808-66.846 41.423-162.77-96.924 4.616-163.385 40.923-66.461 36.308-123.768 93.615-39.154 39.154-69.154 82.347-30 43.192-56.693 93.884Zm184.617-82.001q-18.308-18.307-18.308-43.884 0-25.576 18.308-43.884 18.307-18.307 43.884-18.307 25.576 0 43.884 18.307 18.307 18.308 18.307 43.884 0 25.577-18.307 43.884-18.308 18.308-43.884 18.308-25.577 0-43.884-18.308Zm-25.464 316.462 97.617-97.616q2.307-2.308 3.269-5.192.962-2.885.192-5.962l-7.384-57.693q-29.462 22.231-60.385 41.885-30.924 19.654-63.54 36.962l30.231 87.616Zm298.538-590.152q19.846 96.615-22.346 193.653T687.691-462.154q-1.924 1.923-11.462 11.961-9.539 10.039-11.462 11.962l10.077 92.538q1.615 15.077-3.115 28.654-4.731 13.576-15.577 24.423l-158.846 158.46-59.153-182.614-120.768-121.768-183.614-59.538 158.845-159.46q10.847-10.846 24.231-15.769 13.384-4.924 28.461-3.308l93.307 11.461q1.924-1.923 12.77-12.462 10.846-10.538 12.769-12.461 69.076-69.076 164.806-110.884 95.73-41.807 192.345-20.962ZM201.848-327.154q26.23-24.23 61.268-23.345 35.038.884 61.268 27.115 24.231 24.23 24.923 58.268.692 34.038-21.538 60.268-40.692 46.692-98.461 57.461-57.769 10.769-99.844 17.538 6.769-41.075 17.23-97.844 10.462-56.768 55.154-99.461Zm30.768 43.538q-17.769 17.769-23.269 40.577t-12.116 46.192q23.385-5.615 45.693-12 22.307-6.384 39.077-24.154 11-12 13.115-27.807 2.115-15.808-8.885-26.808t-26.307-9q-15.308 2-27.308 13Z"
        />
      </svg>
    );
  }
}
