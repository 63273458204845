import { Box, Center, Flex, Text, UnstyledButton } from '@mantine/core';
import classes from '../../pages/EventListings.Mobile.module.css';
import { useDisclosure } from '@mantine/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import WifiIcon from '../../components/icons/Wifi';
import DragIndicatorIcon from '../../components/icons/DragIndicator';
import AutoPriceIcon from '../../components/icons/AutoPrice';
import { Dispatch, SetStateAction, useCallback, useRef } from 'react';
import { formatCurrency, formatPricedAt, formatSeatRange } from '../../utils/formatters';
import WifiOffIcon from '../../components/icons/WifiOff';
import { useInventory } from '../Inventory/Inventory.hooks';
import { BarkerCoreEnumsPricerStatus } from '../../api';
import { useForm } from '@mantine/form';
import ErrorIcon from '../../components/icons/Error';
import MobileDataOffIcon from '../../components/icons/MobileDataOff';
import ScheduledPriceIcon from '../../components/icons/ScheduledPrice';
import VerticalAlignBottomIcon from '../../components/icons/VerticalAlignBottom';
import VerticalAlignTopIcon from '../../components/icons/VerticalAlignTop';
import { MobileNumberInputModal } from '../MobileNumberInputModal/MobileNumberInputModal';
import { BarkerEventListing } from '../../types';
import { useGlobalState } from '../../data/GlobalState';
import { isDrasticChange } from '../../utils/price-utils';
import WarningIcon from '../../components/icons/Warning';
import { isDtiHosted } from '../../utils/whitelabel-consts';

export type ListingCardProps = {
  isExpanded?: boolean;
  setIsExpanded?: Dispatch<SetStateAction<boolean>>;
  isInGroup?: boolean;
  listing: BarkerEventListing;
  updatePrice?: (tenantId: string, listingId: string, price: number, previousPrice: number) => void;
  scrollAreaRef?: React.RefObject<HTMLDivElement>;
};

export function ListingCard({ isExpanded, setIsExpanded, isInGroup, listing, updatePrice, scrollAreaRef }: ListingCardProps) {
  const navigate = useNavigate();
  const { currentUser, tenants } = useGlobalState('currentUser', 'tenants');
  const [priceUpdateModalOpened, { open: openPriceUpdateModal, close: closePriceUpdateModal }] = useDisclosure(false);
  const { inventoryQuickFilter } = useInventory('inventoryQuickFilter');
  const companySettings = tenants?.find((x) => x.tenantId === listing.tenantId)?.settings;
  const percentage = companySettings?.pricerSettings?.drasticPriceThresholdPercentage ?? 0;
  const threshold = companySettings?.pricerSettings?.drasticPriceThresholdAmount ?? 0;
  const { pathname } = useLocation();
  const cardRef = useRef<HTMLDivElement>(null);
  const testPartialBroadcasting = false;
  const priceUpdateForm = useForm<{ unitPrice: number | '' }>({
    initialValues: {
      unitPrice: listing.unitPrice,
    },
  });

  const _isDrasticChange = isDrasticChange(percentage, threshold, priceUpdateForm.values.unitPrice || 0, listing.unitPrice, listing.unitCost);

  function handlePriceInputClick() {
    openPriceUpdateModal();

    if (scrollAreaRef && scrollAreaRef.current && cardRef.current) {
      const isCoveredByKeyboard = cardRef.current.getBoundingClientRect().bottom > scrollAreaRef.current.getBoundingClientRect().bottom - 320;

      // Determine how much it's covered
      if (isCoveredByKeyboard) {
        if (!document.getElementById('keyboardPlaceholder')) {
          const placeholderDiv = document.createElement('div');
          placeholderDiv.id = 'keyboardPlaceholder';
          placeholderDiv.style.height = `${318}px`;
          scrollAreaRef.current.appendChild(placeholderDiv);
        }

        const safeAreaOffsetBottom = parseInt(getComputedStyle(document.body).getPropertyValue('--safe-area-inset-bottom'));
        const scrollAmount =
          cardRef.current.getBoundingClientRect().bottom - scrollAreaRef.current.getBoundingClientRect().bottom + 320 + scrollAreaRef.current.scrollTop + safeAreaOffsetBottom;
        scrollAreaRef.current.scrollTo({ top: scrollAmount, behavior: 'smooth' });
      }
      cardRef.current?.classList.add('active-card');
    }
  }

  function handleKeyboardClose() {
    if (scrollAreaRef && scrollAreaRef.current && cardRef.current) {
      cardRef.current?.classList.remove('active-card');
      const placeholder = document.getElementById('keyboardPlaceholder');
      if (placeholder) {
        placeholder.remove();
      }
    }
    closePriceUpdateModal();
  }

  const isGroup = (listing.ruleCount ?? 0) > 1;
  const isAuto = listing.pricerStatusId !== 'None';
  const Icon = useCallback(({ pricerStatusId }: { pricerStatusId: BarkerCoreEnumsPricerStatus }) => {
    if (pricerStatusId === BarkerCoreEnumsPricerStatus.Errored) {
      return (
        <Box className={`${classes.iconCircle} ${classes.error} priceBadge`}>
          <ErrorIcon size={18} />
        </Box>
      );
    }
    if (pricerStatusId === BarkerCoreEnumsPricerStatus.NoComparables) {
      return (
        <Box className={`${classes.iconCircle} ${classes.warning} priceBadge`}>
          <MobileDataOffIcon size={18} />
        </Box>
      );
    }
    if (pricerStatusId === BarkerCoreEnumsPricerStatus.AtFloor) {
      return (
        <Box className={`${classes.iconCircle} ${classes.error} priceBadge`}>
          <VerticalAlignBottomIcon size={18} />
        </Box>
      );
    }
    if (pricerStatusId === BarkerCoreEnumsPricerStatus.AtCeiling) {
      return (
        <Box className={`${classes.iconCircle} ${classes.success} priceBadge`}>
          <VerticalAlignTopIcon size={18} />
        </Box>
      );
    }
    if (pricerStatusId === BarkerCoreEnumsPricerStatus.AutoPriced) {
      return (
        <Box className={`${classes.iconCircle} ${classes.selected} priceBadge`}>
          <AutoPriceIcon size={18} />
        </Box>
      );
    }
    if (pricerStatusId === BarkerCoreEnumsPricerStatus.Scheduled) {
      return (
        <Box className={`${classes.iconCircle} ${classes.selected} ${classes.scheduled} priceBadge`}>
          <ScheduledPriceIcon size={20} />
        </Box>
      );
    }
    return null;
  }, []);

  return (
    <Flex bg="var(--colors-paper)" className={isInGroup ? classes.nestedListingCard : !isExpanded ? classes.listingCard : classes.listingCardSelected} ref={cardRef}>
      <Center w={isInGroup ? 36 : 44} style={{ flexShrink: 0 }}>
        {!isGroup || isInGroup ? (
          <DragIndicatorIcon color="var(--colors-gray-4)" size={22} />
        ) : (
          <Center className={classes.groupNumber} h={20} w={20} onClick={() => setIsExpanded?.(!isExpanded)}>
            <Text fz={11} c="var(--colors-paper)">
              {listing.ruleCount}
            </Text>
          </Center>
        )}
      </Center>
      <UnstyledButton
        className={classes.listingDescription}
        onClick={() => {
          if (!isInGroup) {
            const position = document.getElementById('scrollArea')?.scrollTop ?? 0;
            navigate(`${pathname}?tenantId=${listing.tenantId}`, { state: { tenantId: listing.tenantId, filter: inventoryQuickFilter, scrollPosition: position } });
            navigate(`${listing.listingId}?tenantId=${listing.tenantId}`);
          }
        }}
      >
        <Flex gap="xs" mt={-1} mb={-1}>
          <Text fz="sm" fw={600} truncate>
            {listing.section}
          </Text>
          {isDtiHosted && testPartialBroadcasting ? (
            <Center h={18} w={18} className={classes.partialBroadcastIcon} style={{ borderRadius: 100 }}>
              <WifiIcon size={12} color="var(--colors-yellow-warning)" />
            </Center>
          ) : (
            <Center h={18} w={18} className={listing.isBroadcasting ? classes.broadcastIcon : classes.unBroadcastIcon} style={{ borderRadius: 100 }}>
              {listing.isBroadcasting ? <WifiIcon size={12} color="var(--colors-green-broadcast)" /> : <WifiOffIcon size={12} color="var(--colors-gray-5)" />}
            </Center>
          )}
        </Flex>
        <Text className={classes.descRow} c="var(--colors-gray-5)" fz="xs" truncate>
          Row <strong>{listing.row}</strong> &middot; Qty <strong>{listing.quantityRemaining}</strong> &middot; Cost <strong>{formatCurrency(listing.unitCost, false)}</strong>
        </Text>
        <Text className={classes.descRow} c="var(--colors-gray-5)" fz="xs" truncate>
          Seats <strong>{formatSeatRange(listing.seatFrom, listing.seatThru, listing.quantityRemaining)}</strong> &middot; Repriced{' '}
          <strong>{formatPricedAt(listing.pricedAt, listing.posPricedAt, listing.unitPrice, listing.latestPrice)}</strong>
        </Text>
      </UnstyledButton>
      <Flex pr={isInGroup ? 8 : 'md'} h="100%" align="center" gap="xs" style={{ flexShrink: 0 }}>
        {isAuto && (
          <Center bg="var(--colors-paper)" mr={-4} h={24} w={24} style={{ borderRadius: 100 }}>
            <Icon pricerStatusId={listing.pricerStatusId} />
          </Center>
        )}
        {isAuto ? (
          <UnstyledButton
            h={36}
            w={96}
            px={8}
            ml={!isAuto ? 2 : 0}
            className={classes.priceButton}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderRadius: 6,
              border: '1px solid var(--colors-gray-3)',
              background: 'var(--colors-opacity-hover)',
            }}
            onClick={() => (isInGroup ? undefined : navigate(`${listing.listingId}?tenantId=${listing.tenantId}`))}
          >
            {/* <TextInput pos="absolute" inset={0} opacity={0} onClick={() => openModal()} /> */}
            <Text fz="sm" fw={500} c={isAuto ? 'var(--colors-gray-5)' : 'var(--colors-gray-9)'}>
              $
            </Text>
            <Text fz="sm" fw={500} c={isAuto ? 'var(--colors-gray-5)' : 'var(--colors-gray-9)'}>
              {listing.unitPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Text>
          </UnstyledButton>
        ) : (
          <UnstyledButton
            h={36}
            w={96}
            px={8}
            ml={!isAuto ? 2 : 0}
            className={classes.priceButton}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderRadius: 6,
              border: '1px solid var(--colors-gray-3)',
              background: priceUpdateModalOpened ? 'var(--colors-paper)' : 'transparent',
              borderColor: priceUpdateModalOpened ? (_isDrasticChange ? 'var(--colors-yellow-warning)' : 'var(--colors-selectedBorder)') : 'var(--colors-gray-3)',
            }}
            onClick={handlePriceInputClick}
          >
            {_isDrasticChange ? (
              <Center ml={-4} h="100%">
                <WarningIcon color="var(--colors-yellow-warning)" size={18} />
              </Center>
            ) : (
              <Text fz="sm" fw={500} c="var(--colors-gray-5)">
                $
              </Text>
            )}
            <Text
              fz="sm"
              fw={500}
              className={priceUpdateModalOpened && priceUpdateForm.values.unitPrice === listing.unitPrice ? classes.priceTextActive : classes.priceText}
              c={isAuto ? 'var(--colors-gray-5)' : 'var(--colors-gray-9)'}
              bg={priceUpdateModalOpened && priceUpdateForm.values.unitPrice === listing.unitPrice ? 'var(--colors-selectedBg)' : ''}
            >
              {priceUpdateModalOpened || priceUpdateForm.values.unitPrice !== listing.unitPrice ? (
                <>
                  {priceUpdateForm.values.unitPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  <span className="price-cursor">|</span>
                </>
              ) : (
                listing.unitPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              )}
            </Text>
          </UnstyledButton>
        )}
      </Flex>
      {priceUpdateModalOpened && (
        <MobileNumberInputModal
          disabled={currentUser?.roleId === 'ReadOnlyUser'}
          cancel={() => {
            priceUpdateForm.setFieldValue('unitPrice', listing.unitPrice);
            handleKeyboardClose();
          }}
          save={() => {
            const newPrice = priceUpdateForm.values.unitPrice === '' ? 0 : priceUpdateForm.values.unitPrice;
            if (updatePrice) {
              updatePrice(listing.tenantId, listing.listingId, newPrice, listing.unitPrice);
            }
            priceUpdateForm.resetDirty({ unitPrice: newPrice });
            priceUpdateForm.setFieldValue('unitPrice', newPrice);
            handleKeyboardClose();
          }}
          initialInput={listing.unitPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          handleKeyboardInput={(input) => priceUpdateForm.setFieldValue('unitPrice', input === '' ? '' : parseFloat(input))}
          cost={listing.unitCost}
          drasticAmount={_isDrasticChange ? priceUpdateForm.values.unitPrice || 0 : undefined}
        />
      )}
    </Flex>
  );
}
