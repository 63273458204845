import { IconPropsBase } from './IconPropsBase';

export default function ViewAgendaIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M228.31-535.69q-26.31 0-45.31-18.65-19-18.65-19-45.66v-119.38q0-26.31 19-45.31 19-19 45.31-19h503.38q27.01 0 45.66 19 18.65 19 18.65 45.31V-600q0 27.01-18.65 45.66-18.65 18.65-45.66 18.65H228.31Zm0-52h503.38q4.62 0 8.46-3.85Q744-595.38 744-600v-119.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H228.31q-4.62 0-8.46 3.85Q216-724 216-719.38V-600q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85Zm0 411.38q-26.31 0-45.31-18.65-19-18.65-19-45.66V-360q0-26.31 19-45.31 19-19 45.31-19h503.38q27.01 0 45.66 19Q796-386.31 796-360v119.38q0 27.01-18.65 45.66-18.65 18.65-45.66 18.65H228.31Zm0-52h503.38q4.62 0 8.46-3.85Q744-236 744-240.62V-360q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H228.31q-4.62 0-8.46 3.85Q216-364.62 216-360v119.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85ZM216-731.69v144-144Zm0 359.38v144-144Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M228.31-535.69q-26.31 0-45.31-18.65-19-18.65-19-45.66v-119.38q0-26.31 19-45.31 19-19 45.31-19h503.38q27.01 0 45.66 19 18.65 19 18.65 45.31V-600q0 27.01-18.65 45.66-18.65 18.65-45.66 18.65H228.31Zm0-52h503.38q4.62 0 8.46-3.85Q744-595.38 744-600v-119.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H228.31q-4.62 0-8.46 3.85Q216-724 216-719.38V-600q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85Zm0 411.38q-26.31 0-45.31-18.65-19-18.65-19-45.66V-360q0-26.31 19-45.31 19-19 45.31-19h503.38q27.01 0 45.66 19Q796-386.31 796-360v119.38q0 27.01-18.65 45.66-18.65 18.65-45.66 18.65H228.31Zm0-52h503.38q4.62 0 8.46-3.85Q744-236 744-240.62V-360q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H228.31q-4.62 0-8.46 3.85Q216-364.62 216-360v119.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85ZM216-731.69v144-144Zm0 359.38v144-144Z"
        />
      </svg>
    );
  }
}
