import { IconPropsBase } from './IconPropsBase';

export default function CompareArrowsIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="m8 19.65-1.05-1.075 2.825-2.825H2.25v-1.5h7.525L6.95 11.425 8 10.35 12.65 15Zm8-6L11.35 9 16 4.35l1.05 1.075-2.825 2.825h7.525v1.5h-7.525l2.825 2.825Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="m7 16.208-.771-.77 2.396-2.396H2.208v-1.084h6.417L6.229 9.562 7 8.792l3.708 3.708Zm6-5L9.292 7.5 13 3.792l.771.77-2.396 2.396h6.417v1.084h-6.417l2.396 2.396Z"
        />
      </svg>
    );
  }
}
