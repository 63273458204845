import { useEventPerformance } from './EventPerformance.hooks';
import { BNLoadingState } from '../../components/Loading/LoadingState';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import { BarkerCoreEnumsPointOfSale, BarkerCoreModelsSalesListingSoldVendorPropertiesTradeDeskPos } from '../../api';
import TableRowsIcon from '../../components/icons/TableRows';
import dayjs from 'dayjs';
import { formatCurrency } from '../../utils/formatters';
import { Divider, ScrollArea, Table } from '@mantine/core';
import { humanizeTime } from '../../utils/human-time';
import classes from './EventPerformance.OfferTable.module.css';

export function EventPerformanceOfferTable() {
  const { listingsSold, isListingsSoldLoading } = useEventPerformance('listingsSold', 'isListingsSoldLoading');

  if (isListingsSoldLoading) {
    return <BNLoadingState border />;
  }

  const isEmpty =
    listingsSold === undefined ||
    listingsSold.length === 0 ||
    !listingsSold.some((l) => (l.vendorProperties as BarkerCoreModelsSalesListingSoldVendorPropertiesTradeDeskPos)?.listingExternalId);

  if (isEmpty) {
    return <BNEmptyState title="No Offer Data" description="Check back later" icon={<TableRowsIcon size={28} />} />;
  }

  const offerTotals = listingsSold.reduce(
    (acc, listing) => {
      if (!listing.vendorProperties || listing.vendorProperties.pointOfSaleId !== BarkerCoreEnumsPointOfSale.TradeDeskPos) {
        return acc;
      }

      const vendorProperties = listing.vendorProperties as BarkerCoreModelsSalesListingSoldVendorPropertiesTradeDeskPos;

      if (vendorProperties.listingExternalId === undefined || vendorProperties.listingExternalId === null) {
        return acc;
      }

      const totals = acc[vendorProperties.listingExternalId] ?? {};

      totals.sales = (totals.sales ?? 0) + 1;
      totals.tickets = (totals.tickets ?? 0) + listing.quantity;
      totals.revenue = (totals.revenue ?? 0) + listing.quantity * listing.unitPrice;
      totals.latestSale =
        totals.latestSale === undefined ? listing.sale.createdAt : dayjs(totals.latestSale).isBefore(listing.sale.createdAt) ? listing.sale.createdAt : totals.latestSale;

      acc[vendorProperties.listingExternalId] = totals;

      return acc;
    },
    {} as Record<string, OfferTotals>,
  );

  const rows = Object.entries(offerTotals)
    .map(([listingExternalId, totals]) => ({
      listingExternalId,
      ...totals,
    }))
    .sort((a, b) => b.revenue - a.revenue)
    .map((row) => (
      <tr key={row.listingExternalId}>
        <td>{row.listingExternalId}</td>
        <td>{row.sales}</td>
        <td>{row.tickets}</td>
        <td>{formatCurrency(row.revenue, false)}</td>
        <td>{humanizeTime(row.latestSale)}</td>
      </tr>
    ));

  return (
    <ScrollArea type="auto" pr={14} mr={-14} styles={{ scrollbar: { bottom: 0 } }}>
      <Table className={classes.offerTable}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Offer</Table.Th>
            <Table.Th>Sales</Table.Th>
            <Table.Th>Tickets</Table.Th>
            <Table.Th>Revenue</Table.Th>
            <Table.Th>Latest Sale</Table.Th>
          </Table.Tr>
          <Divider color="var(--colors-borderDivider)" pos="absolute" left={0} right={0} w="100%" />
        </Table.Thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
}

type OfferTotals = {
  sales: number;
  tickets: number;
  revenue: number;
  latestSale: Date;
};
