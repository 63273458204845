import { Divider, Flex, Text } from '@mantine/core';
import { BNButton } from '../../../components/Button/Button';
import { useRef } from 'react';
import { AdjustmentValueInputDiagonalMerged } from '../../../components/AdjustmentInput/AdjustmentValueInputDiagonal.Merged';
import { MobileModalProps } from '../../../components/MobilePriceUpdateForm/MobilePriceUpdateForm';
import { useNowPricing } from '../../NowPricing/NowPricing.hooks';
import { useForm } from '@mantine/form';
import { useViewportPosition } from '../../../utils/use-viewport-position';

export function StaggerByUpdateForm({ closeModal }: MobileModalProps) {
  const { form } = useNowPricing('form');
  const {
    getInputProps,
    onSubmit,
    values: { staggerByTypeId, staggerByValue },
  } = useForm({
    initialValues: {
      staggerByTypeId: form.values.staggerByTypeId,
      staggerByValue: form.values.staggerByValue,
    },
  });

  const ref = useRef<HTMLInputElement | null>(null);
  useViewportPosition(ref, [staggerByTypeId, staggerByValue]);

  return (
    <form
      onSubmit={onSubmit((values) => {
        form.setFieldValue('staggerByTypeId', values.staggerByTypeId);
        form.setFieldValue('staggerByValue', values.staggerByValue);
        closeModal();
      })}
    >
      <Flex p={0} h="100%" justify="space-between">
        <Flex py="sm" px="md" gap="xs" align="center" justify="start" style={{ flex: 1 }}>
          <Text size="md" fw={600}>Stagger By</Text>
        </Flex>
      </Flex>
      <Divider color="var(--colors-gray-0)" />
      <Flex py="xl" px="xl" w="100%" align="center" justify="center" gap="md">
        <AdjustmentValueInputDiagonalMerged
          mobile
          ref={ref}
          positiveOnly
          onChange={(value) => {
            getInputProps('staggerByValue').onChange(value.value);
            getInputProps('staggerByTypeId').onChange(value.type);
          }}
          value={staggerByValue || 0}
          type={staggerByTypeId || 'Amount'}
        />
      </Flex>
      <Divider color="var(--colors-gray-0)" />
      <Flex gap="sm" w="100%" py="xl" px="md">
        <BNButton fullWidth variant="default" onClick={() => closeModal()}>
          Cancel
        </BNButton>
        <BNButton fullWidth variant="filled" color="gray" type="submit">
          Done
        </BNButton>
      </Flex>
    </form>
  );
}
