export function getNestedProperty(obj: Record<string, any>, path: string): any {
  const keys = path.split('.');
  let result = obj;

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    if (result[key] !== undefined) {
      result = result[key];
    } else {
      return undefined;
    }
  }

  return result;
}
