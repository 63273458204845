import { IconPropsBase } from './IconPropsBase';

export default function MarketplaceVividIcon({ size = 24, color = 'currentColor' }: IconPropsBase) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <polygon fill={color} points="5.41 8.94 5.4 8.94 5.4 8.94 5.4 8.94 5.4 8.94 5.4 8.94 5.41 8.94 5.41 8.94" />
      <polygon fill={color} points="5.96 9.75 15.69 14.33 18.14 9.94 5.96 9.75" />
      <polygon fill={color} points="5.41 8.94 7.26 8.94 18.7 8.94 22 3 5.41 8.94" />
      <polygon fill={color} points="5.53 7.83 19.03 3 2.1 3 5 8 5.53 7.83" />
      <polygon fill={color} points="15.21 15.2 6.36 10.75 6.36 10.75 12.05 20.88 15.21 15.2" />
    </svg>
  );
}
