import { IconPropsBase } from './IconPropsBase';

export default function ChevronForwardIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path fill={color} d="M8.025 21.65 6.6 20.225 14.825 12 6.6 3.775 8.025 2.35l9.65 9.65Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path fill={color} d="m6 17.708-1.125-1.125L11.458 10 4.875 3.417 6 2.292 13.708 10Z" />
      </svg>
    );
  }
}
