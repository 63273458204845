import { IconPropsBase } from './IconPropsBase';

export default function SearchIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="m19.55 20.575-6.3-6.275q-.75.625-1.725.975-.975.35-2 .35-2.575 0-4.35-1.775Q3.4 12.075 3.4 9.5q0-2.55 1.775-4.338 1.775-1.787 4.35-1.787 2.55 0 4.325 1.775 1.775 1.775 1.775 4.35 0 1.075-.35 2.05-.35.975-.95 1.7l6.275 6.275Zm-10.025-6.45q1.925 0 3.263-1.35 1.337-1.35 1.337-3.275 0-1.925-1.337-3.275-1.338-1.35-3.263-1.35-1.95 0-3.287 1.35Q4.9 7.575 4.9 9.5q0 1.925 1.338 3.275 1.337 1.35 3.287 1.35Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="m15.896 16.646-4.979-4.958q-.625.479-1.365.739-.74.261-1.531.261-1.959 0-3.323-1.365Q3.333 9.958 3.333 8q0-1.938 1.365-3.312 1.364-1.376 3.323-1.376 1.937 0 3.302 1.365T12.688 8q0 .812-.261 1.562-.26.75-.719 1.355l4.959 4.958Zm-7.875-5.042q1.5 0 2.541-1.052Q11.604 9.5 11.604 8t-1.042-2.552Q9.521 4.396 8.021 4.396q-1.521 0-2.563 1.052Q4.417 6.5 4.417 8t1.041 2.552q1.042 1.052 2.563 1.052Z"
        />
      </svg>
    );
  }
}
