import { IconPropsBase } from './IconPropsBase';

export default function WarningIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" style={{ marginTop: -4 }}>
        <path
          fill={color}
          d="M1.875 20.5 12 3l10.125 17.5ZM4.45 19h15.1L12 6ZM12 17.8q.35 0 .575-.225.225-.225.225-.575t-.225-.575Q12.35 16.2 12 16.2t-.575.225q-.225.225-.225.575t.225.575q.225.225.575.225Zm-.75-2.6h1.5v-5h-1.5Zm.75-2.7Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" style={{ marginTop: -4 }}>
        <path
          fill={color}
          d="M1.729 17.583 10 3.833l8.271 13.75ZM3.646 16.5h12.708L10 5.917ZM10 15.333q.25 0 .417-.166.166-.167.166-.417 0-.25-.166-.417-.167-.166-.417-.166-.25 0-.417.166-.166.167-.166.417 0 .25.166.417.167.166.417.166Zm-.542-2.166h1.084v-4H9.458ZM10 11.208Z"
        />
      </svg>
    );
  }
}
