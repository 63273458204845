export function useSectionHighlight(section: string) {
  // Lowercase the section so we can match the ID for Seat Geek seating chart
  const seatGeekSectionId = section.replace(/\s+/g, '-').toLowerCase();

  // Ticket master seems to do upper case currently for venues looked at.
  const ticketMasterSectionId = section.toUpperCase();

  // Capitalize the first letter of each word so we can match the data attribute value for Tevo seating chart
  const tevoSection = section.split(' ');
  const tevoSectionId = tevoSection.map((word) => (word[0] ?? '').toUpperCase() + word.slice(1)).join(' ');

  const highlightSection = () => {
    const seatGeekSectionHovered = document.getElementById(`${seatGeekSectionId}`);
    const ticketMasterSectionHovered = document.querySelector(`[data-section-id="${ticketMasterSectionId}"]`);
    const tevoSectionHovered = [...document.querySelectorAll(`[data-section-id="${tevoSectionId}"]`)];

    seatGeekSectionHovered?.classList.add('market-hover');
    ticketMasterSectionHovered?.classList.add('market-hover');
    tevoSectionHovered?.forEach((tevoPath) => {
      tevoPath.classList.add('market-hover');
    });
  };
  const unhighlightSection = () => {
    const nodesHovered = [...document.querySelectorAll('.market-hover')];

    nodesHovered?.forEach((node) => {
      node.classList.remove('market-hover');
    });
  };

  return { highlightSection, unhighlightSection };
}
