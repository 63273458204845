import { Flex } from '@mantine/core';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import classes from './NavBar.styles.tsx.module.css';
import { NotificationsProvider } from '../Notifications/Notifications.hooks';
import { NavBarCollapsed } from './NavBar.Collapsed';
import { NavBar } from './NavBar.Sidebar';

type BNSidebarProps = {
  children?: ReactNode;
};

export function BNSidebar({ children }: BNSidebarProps) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnter = useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      document.body.classList.add('flyout');
      timeoutRef.current = null;
    }, 50);
  }, []);

  const handleMouseout = useCallback(() => {
    document.body.classList.remove('flyout');
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  }, []);

  useEffect(
    () => () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    },
    [],
  );

  const [isMouseoutSuppressed, setIsMouseoutSuppressed] = useState(false);

  return (
    <Flex direction="column" h="100%" pos="relative" onMouseEnter={handleMouseEnter} onMouseLeave={() => !isMouseoutSuppressed && handleMouseout()}>
      <Flex className={classes.fullSidebar} direction="column" h="100%">
        <NavBar setIsMouseoutSuppressed={setIsMouseoutSuppressed} collapseNavBar={handleMouseout} />
        <Flex h="100" flex={1}>
          {children}
        </Flex>
      </Flex>
      <NotificationsProvider>
        <NavBarCollapsed />
      </NotificationsProvider>
    </Flex>
  );
}
