import { Box, Collapse, Divider, Flex, Menu, Text } from '@mantine/core';
import { DragDropContext, Draggable, DraggableProvided, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { BNButton } from '../../components/Button/Button';
import { BNSwitch } from '../../components/Switch/Switch';
import ResetIcon from '../../components/icons/Reset';
import { SortItem } from './Inventory.SortItem';
import { useInventoryHeader } from './Inventory.Header.hooks';
import SelectArrowsIcon from '../../components/icons/SelectArrows';
import classes from './Inventory.styles.tsx.module.css';
import DragIndicatorIcon from '../../components/icons/DragIndicator';
import { useDebouncedValue, useDidUpdate, useListState, useToggle } from '@mantine/hooks';
import { ColumnSettingItem } from '../../types';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { onColumnMoved, onColumnSorted, useInventoryEvents } from './Inventory.Events.hooks';
import { openConfirmModal } from '@mantine/modals';

export function EventSortBar() {
  const [enableUpdates, toggleUpdates] = useToggle();
  const { showSettings, onMenuVisibilityChange, isMenuOpenedRef } = useInventoryHeader('showSettings', 'onMenuVisibilityChange', 'isMenuOpenedRef');
  const { gridRef, gridReady, defaultColumnDefs, eventGridState } = useInventoryEvents('gridRef', 'gridReady', 'defaultColumnDefs', 'eventGridState');
  const defaultColumnSettings = useMemo(
    () =>
      defaultColumnDefs
        .filter((x) => !x.exclude)
        .map(
          (x) =>
            ({
              key: x.field || x.colId!,
              name: x.headerName!,
              visible: !x.hide,
              width: x.width ?? undefined,
              pinned: x.colId === 'action' ? 'right' : undefined,
            }) satisfies ColumnSettingItem,
        ),
    [defaultColumnDefs],
  );
  const eventGridStateRef = useRef(eventGridState);
  const [columnSettings, columnSettingsHandlers] = useListState<ColumnSettingItem>([]);
  const [eventSort, eventSortHandlers] = useListState<SortItem>([]);
  const [columnSettingsDebounced] = useDebouncedValue(columnSettings, 250);
  const eventColumnSettingsItems = (columnSettings ?? []).map((item, index) => (
    <Draggable key={item.key} index={index} draggableId={item.key}>
      {(provided: DraggableProvided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Menu.Item
            {...provided.dragHandleProps}
            component="a"
            leftSection={<DragIndicatorIcon color="var(--colors-gray-4)" size={20} />}
            c={item.visible ? 'var(--colors-gray-9)' : 'var(--colors-gray-5)'}
            fz="xs"
            py={6}
            tt="capitalize"
            style={{ borderBottom: '1px solid var(--colors-paperHover)' }}
            rightSection={
              <BNSwitch
                size="xs"
                label={undefined}
                checked={item.visible}
                onClick={() => {
                  columnSettingsHandlers.setItemProp(index, 'visible', !item.visible);
                }}
              />
            }
            data-pendo-item={`Column Settings - ${item.name} Switch`}
          >
            {item.name}
          </Menu.Item>
        </div>
      )}
    </Draggable>
  ));

  const processColumnSettings = useCallback(() => {
    if (!gridRef.current || !gridRef.current.columnApi) return;
    const ignoredColIds = ['dragCell'];
    const columns = gridRef.current.columnApi.getColumns();
    const columnState = gridRef.current.columnApi.getColumnState()!;
    const _columnSettingItems = columnState
      .filter((col) => !ignoredColIds.includes(col.colId))
      .map((col) => {
        const columnDef = columns?.find((x) => x.getColId() === col.colId)?.getColDef();
        return {
          key: col.colId,
          name: columnDef?.headerName ?? columnDef?.field ?? '',
          visible: !col.hide,
          width: col.width,
          pinned: col.pinned,
          sortIndex: col.sortIndex || 0,
        };
      });
    columnSettingsHandlers.setState(_columnSettingItems);
  }, [columnSettingsHandlers, gridRef]);

  useDidUpdate(() => {
    eventGridStateRef.current = eventGridState;
  }, [eventGridState]);

  useDidUpdate(() => {
    if (enableUpdates) {
      gridRef.current?.columnApi.applyColumnState({
        applyOrder: true,
        state: [{ colId: 'dragCell' }, ...columnSettingsDebounced.map((x) => ({ colId: x.key, hide: !x.visible, width: x.width, pinned: x.pinned }))],
      });
    }
  }, [columnSettingsDebounced]);

  useDidUpdate(() => {
    if (enableUpdates) {
      const updateOrder = eventSort.map((x, i) => ({ colId: x.key, sort: x.dir ?? null, sortIndex: x.dir ? i : null }));
      gridRef.current?.columnApi.applyColumnState({
        state: updateOrder,
      });
    }
  }, [eventSort]);

  const allowedSortingColumns = useMemo(
    () =>
      structuredClone(
        defaultColumnDefs
          .filter((x) => x.sortIndex != null || x.allowSorting !== false)
          .map((x) => ({ key: x.field || x.colId!, name: x.headerName || x.field || x.colId!, dir: x.sort ?? null, sortIndex: x.sortIndex })),
      ).sort((a, b) => {
        const aIndex = typeof a.sortIndex === 'number' ? a.sortIndex : Infinity;
        const bIndex = typeof b.sortIndex === 'number' ? b.sortIndex : Infinity;
        return aIndex - bIndex || (a.dir || '').localeCompare(b.dir || '') || a.name.localeCompare(b.name);
      }),
    [defaultColumnDefs],
  );

  const processEventSort = useCallback(
    ({ initialRender }: { initialRender?: boolean } = { initialRender: false }) => {
      if (!gridRef.current || !gridRef.current.columnApi) return;
      const columnState = gridRef.current.columnApi.getColumnState();
      const columnSort = columnState
        .sort((x) => x.sortIndex || Infinity)
        .map((x) => {
          const columnDef = gridRef.current?.columnApi.getColumn(x.colId)?.getColDef();
          return {
            key: x.colId,
            name: columnDef?.headerName ?? columnDef?.field ?? '',
            dir: x.sort,
            sortIndex: x.sortIndex,
          } satisfies SortItem;
        });

      let finalListingSort = [...columnSort.filter((x) => allowedSortingColumns.map((y) => y.key.toLowerCase()).includes(x.key.toLowerCase()))];
      if (initialRender) {
        finalListingSort = structuredClone(
          finalListingSort.map((x) => {
            const savedState = eventGridStateRef.current?.find((y) => y.colId === x.key) || {};
            const { dir, sortIndex, ...rest } = allowedSortingColumns.find((y) => y.key === x.key) || {};
            return { ...x, ...rest, sort: dir, sortIndex, ...savedState };
          }),
        );
      }
      finalListingSort.sort((a, b) => {
        const aIndex = typeof a.sortIndex === 'number' ? a.sortIndex : Infinity;
        const bIndex = typeof b.sortIndex === 'number' ? b.sortIndex : Infinity;
        return aIndex - bIndex || (a.dir || '').localeCompare(b.dir || '') || a.name.localeCompare(b.name);
      });
      eventSortHandlers.setState(finalListingSort);
    },
    [allowedSortingColumns, gridRef, eventSortHandlers],
  );

  useEffect(() => {
    if (gridReady && showSettings) {
      processEventSort({ initialRender: true });
      processColumnSettings();

      setTimeout(() => toggleUpdates(true), 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridReady, showSettings]);

  const onColumnMovedEvt = useCallback(() => {
    if (!isMenuOpenedRef.current) {
      processColumnSettings();
    }
  }, [isMenuOpenedRef, processColumnSettings]);

  const onColumnSortedEvt = useCallback(() => {
    if (!isMenuOpenedRef.current) {
      processEventSort();
    }
  }, [isMenuOpenedRef, processEventSort]);

  const resetColumnDefaults = useCallback(() => {
    columnSettingsHandlers.setState(defaultColumnSettings);
  }, [defaultColumnSettings, columnSettingsHandlers]);

  const resetEventDefaults = useCallback(() => {
    eventSortHandlers.setState(allowedSortingColumns);
  }, [allowedSortingColumns, eventSortHandlers]);

  const resetAllDefaults = useCallback(() => {
    openConfirmModal({
      title: 'Reset confirmation',
      children: <Text size="sm">Are you sure you want to reset all the grid settings to their defaults?</Text>,
      labels: { confirm: 'Reset', cancel: 'Cancel' },
      confirmProps: { className: 'confirmButton', variant: 'filled', color: 'gray', size: 'sm' },
      cancelProps: { className: 'cancelButton', variant: 'default', size: 'sm' },
      closeButtonProps: { size: 'md' },
      onConfirm: () => {
        resetColumnDefaults();
        resetEventDefaults();
      },
    });
  }, [resetColumnDefaults, resetEventDefaults]);

  useEffect(() => {
    if (enableUpdates) {
      document.addEventListener(onColumnMoved.type, onColumnMovedEvt);
      document.addEventListener(onColumnSorted.type, onColumnSortedEvt);
    }
    return () => {
      document.removeEventListener(onColumnMoved.type, onColumnMovedEvt);
      document.removeEventListener(onColumnSorted.type, onColumnSortedEvt);
    };
  }, [enableUpdates, onColumnMovedEvt, onColumnSortedEvt]);

  return (
    <Collapse in={showSettings} transitionTimingFunction="ease-in-out">
      <Box px="xs" className={classes.settingsShelf}>
        <Flex wrap="nowrap" p={0} gap={0} align="center" justify="space-between" maw="100%" w="100%">
          <Flex align="center" wrap="nowrap" pl={0} pr={0} gap={0} flex={1} maw="62.5%" w="100%">
            <Menu withArrow arrowPosition="center" width={240} closeOnItemClick={false} onChange={onMenuVisibilityChange}>
              <Menu.Target>
                <BNButton px={6} size="xs" variant="subtle" maw="50%" className={classes.dropBtn} rightSection={<SelectArrowsIcon />}>
                  <Text fw={600} truncate>
                    Event Sorting
                  </Text>
                </BNButton>
              </Menu.Target>
              <DragDropContext
                onDragEnd={({ destination, source }) => {
                  eventSortHandlers.reorder({ from: source.index, to: destination?.index || 0 });
                  if (destination?.droppableId === 'unsorted') {
                    eventSortHandlers.setItemProp(destination.index, 'dir', null);
                  } else {
                    eventSortHandlers.setItemProp(destination!.index, 'dir', eventSort[destination!.index].dir || 'asc');
                  }
                }}
              >
                <Menu.Dropdown p={4} className={classes.menuDropdown}>
                  <Droppable droppableId="sorted" direction="vertical">
                    {(provided: DroppableProvided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {eventSort
                          .map((item, index) => ({ ...item, index }))
                          .filter((item) => item.dir !== null)
                          .map((item) => (
                            <SortItem key={item.key} item={item} updateHandler={eventSortHandlers} />
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <Menu.Divider
                    my={4}
                    mx={-4}
                    style={{
                      borderTop: '2px solid var(--colors-gray-3)',
                      borderBottom: '4px solid var(--colors-paperHover)',
                    }}
                  />
                  <Droppable droppableId="unsorted" direction="vertical">
                    {(provided: DroppableProvided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {eventSort
                          .map((item, index) => ({ ...item, index }))
                          .filter((item) => item.dir === null)
                          .map((item) => (
                            <SortItem key={item.key} item={item} updateHandler={eventSortHandlers} />
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <Divider color="var(--colors-divider)" my={4} mx={-4} />
                  <Box>
                    <BNButton leftSection={<ResetIcon />} size="xs" variant="subtle" className={classes.resetBtn} fullWidth onClick={resetEventDefaults}>
                      Reset to Default
                    </BNButton>
                  </Box>
                </Menu.Dropdown>
              </DragDropContext>
            </Menu>

            <Menu withArrow arrowPosition="center" width={240} closeOnItemClick={false} onChange={onMenuVisibilityChange}>
              <Menu.Target>
                <BNButton size="xs" px={6} variant="subtle" maw="50%" className={classes.dropBtn} rightSection={<SelectArrowsIcon />}>
                  <Text fw={600} truncate>
                    Column Settings
                  </Text>
                </BNButton>
              </Menu.Target>
              <DragDropContext
                onDragEnd={({ destination, source }) =>
                  columnSettingsHandlers.reorder({
                    from: source.index,
                    to: destination?.index || 0,
                  })
                }
              >
                <Menu.Dropdown p={4} className={classes.menuDropdown}>
                  <Droppable droppableId="key" direction="vertical">
                    {(provided: DroppableProvided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {eventColumnSettingsItems}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <Divider color="var(--colors-divider)" my={4} mx={-4} />
                  <Box>
                    <BNButton leftSection={<ResetIcon />} size="xs" variant="subtle" className={classes.resetBtn} fullWidth onClick={resetColumnDefaults}>
                      Reset to Default
                    </BNButton>
                  </Box>
                </Menu.Dropdown>
              </DragDropContext>
            </Menu>
          </Flex>
          <Flex wrap="nowrap" justify="end" gap={0} maw="37.5%" w="100%">
            <BNButton onClick={resetAllDefaults} size="xs" variant="subtle" color="gray" className={classes.resetDropBtn} leftSection={<ResetIcon />}>
              Reset to Default
            </BNButton>
          </Flex>
        </Flex>
      </Box>
    </Collapse>
  );
}
