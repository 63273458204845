import { ActionIcon, Badge, Box, Center, Divider, Drawer, Flex, Group, SimpleGrid, Space, Text, Tooltip } from '@mantine/core';
import classes from './EventPerformance.module.css';
import PerformanceChart from './EventPerformance.PerformanceChart';
import MarketplaceBreakdown from './EventPerformance.MarketplaceBreakdown';
import MarketplaceTrends from './EventPerformance.MarketplaceTrends';
import DateRangeIcon from '../../components/icons/DateRange';
import RefreshIcon from '../../components/icons/Refresh';
import { StatBox } from '../../components/DataDisplay/StatBox';
import PaidIcon from '../../components/icons/Paid';
import ConfirmationNumberIcon from '../../components/icons/ConfirmationNumber';
import ArrowForwardIcon from '../../components/icons/ArrowForward';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import ErrorIcon from '../../components/icons/Error';
import { useEventPerformance } from './EventPerformance.hooks';
import { useGlobalState } from '../../data/GlobalState';
import { formatCurrency, formatDate } from '../../utils/formatters';
import { DateFormats } from '../../utils/globals';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import CloseIcon from '../../components/icons/Close';
import { EventPerformanceOfferTable } from './EventPerformance.OfferTable';

function ProfitabilityArrow({ value }: { value?: number }) {
  if (value === undefined || value === 0) {
    return (
      <Center>
        <ArrowForwardIcon />
      </Center>
    );
  }

  if (value > 0) {
    return (
      <Center style={{ transform: 'rotate(-45deg)' }}>
        <ArrowForwardIcon color="var(--colors-chart-green-1)" />
      </Center>
    );
  }

  return (
    <Center style={{ transform: 'rotate(45deg)' }}>
      <ArrowForwardIcon color="var(--colors-chart-red-1)" />
    </Center>
  );
}

export function EventPerformance() {
  const {
    showErrorStates,
    showEmptyStates,
    showEventPerformance,
    setShowEventPerformance,
    selectedEvent,
    soldCost,
    soldRevenue,
    soldReturnOnInvestment,
    openCost,
    openTickets,
    openTicketsBroadcasting,
    soldTickets,
    soldTicketsLast24Hours,
    soldTicketsLast7Days,
    soldTicketsLast30Days,
    soldProfit,
    projectedProfit,
    projectedReturnOnInvestment,
    isListingsOpenLoading,
    isListingsSoldLoading,
    refresh,
    isRefreshing,
  } = useEventPerformance(
    'showErrorStates',
    'showEmptyStates',
    'showEventPerformance',
    'setShowEventPerformance',
    'selectedEvent',
    'soldCost',
    'soldRevenue',
    'soldReturnOnInvestment',
    'openCost',
    'openTickets',
    'openTicketsBroadcasting',
    'soldTickets',
    'soldTicketsLast24Hours',
    'soldTicketsLast7Days',
    'soldTicketsLast30Days',
    'soldProfit',
    'projectedProfit',
    'projectedReturnOnInvestment',
    'isListingsOpenLoading',
    'isListingsSoldLoading',
    'refresh',
    'isRefreshing',
  );
  const { tenants } = useGlobalState('tenants');

  const isMultiTenant = tenants && tenants.length > 1;

  if (!selectedEvent) {
    return null;
  }

  const inTuneFlag =
    (selectedEvent?.tenantId === 'deac90fd-337e-4ef5-9f11-954437fd9298' || // InTune Prod
      selectedEvent?.tenantId === '"0e1461d2-6e23-4f29-8ebc-e100cdacb2ae"') ?? // InTune Test
    false;

  const daysUntilEvent = dayjs(selectedEvent.localDateTime).diff(dayjs(), 'days');
  const isProfitable = soldProfit === undefined || soldProfit >= 0;

  return (
    <>
      <Drawer
        opened={showEventPerformance}
        withinPortal={false}
        onClose={() => setShowEventPerformance(false)}
        title=""
        position="right"
        overlayProps={{ display: 'none' }}
        size="100%"
        withCloseButton={false}
        styles={{ inner: { position: 'absolute' } }}
        // transitionProps={{ duration: 0 }}
        classNames={{ inner: classes.innerDrawer, content: classes.drawerContent, body: classes.drawerBody }}
        lockScroll={false}
      >
        <Box bg="var(--colors-darkPaper)" pos="absolute" inset={0}>
          <Box className={classes.container} px="sm" bg="var(--colors-paper)" pos="absolute" inset={8}>
            <Badge radius={0} className={`${classes.slantBadgeRight} slantBadge`} pl={6}>
              Event Performance
            </Badge>
            <Flex
              wrap={{ base: 'wrap', lg: 'nowrap' }}
              direction={{ base: 'column', lg: 'row' }}
              justify="space-between"
              align={{ base: 'flex-start', lg: 'flex-end' }}
              px={2}
              py="sm"
            >
              <Box py={6} flex={1}>
                <Text c={isMultiTenant ? 'var(--colors-gray-6)' : 'var(--colors-brandcolor-7)'} tt="uppercase" fw={700} fz="xs">
                  {formatDate(selectedEvent.localDateTime, DateFormats.Standard)}
                </Text>
                <Text fw={600} fz="sm" mt={2} mb={-2}>
                  {selectedEvent.name}
                </Text>
                <Text c="var(--colors-gray-5)" fz="xs">
                  {selectedEvent.venue?.name} · {`${selectedEvent.venue?.city}, ${selectedEvent.venue?.state}`}
                </Text>
              </Box>
              <Flex fz="xs" h={30} align="center" gap="xs" className={classes.headerActionRow}>
                {daysUntilEvent >= 0 && (
                  <Text fw={600} fz={11} tt="uppercase" c="var(--colors-gray-5)" bg="var(--colors-gray-0)" py={0} px="xs" style={{ borderRadius: 3, flexShrink: 0 }}>
                    {daysUntilEvent === 0 ? <>Today</> : <>{`${daysUntilEvent} ${pluralize('day', daysUntilEvent)} Away`}</>}
                  </Text>
                )}
                {selectedEvent.lastSoldAt && (
                  <Group wrap="nowrap" gap={4} align="center" c="var(--colors-gray-5)" style={{ flex: 1, overflow: 'hidden' }}>
                    <DateRangeIcon />
                    <Text truncate>
                      Last Sale{' '}
                      {dayjs(selectedEvent.lastSoldAt).isAfter(dayjs().startOf('day'))
                        ? `Today at ${dayjs(selectedEvent.lastSoldAt).format(DateFormats.TimeOnly)}`
                        : dayjs(selectedEvent.lastSoldAt).format(DateFormats.MonthDayYear)}
                    </Text>
                  </Group>
                )}
                {/* Text block below can be added to show when the sales/event data was most recently updated... */}
                {/* <Text c="gray.5">Data as of 8:30 AM</Text> */}
                <Tooltip label="Refresh Performance Data" withArrow>
                  <ActionIcon onClick={refresh} loading={isRefreshing} loaderProps={{ type: 'oval', color: 'gray' }}>
                    <RefreshIcon size={21} />
                  </ActionIcon>
                </Tooltip>
                <Divider orientation="vertical" color="var(--colors-divider)" h={16} style={{ alignSelf: 'auto' }} />
                <Tooltip label="Close Performance Panel" withArrow>
                  <ActionIcon onClick={() => setShowEventPerformance(false)}>
                    <CloseIcon size={21} />
                  </ActionIcon>
                </Tooltip>
              </Flex>
            </Flex>
            <Divider color="var(--colors-divider)" mx={-12} />
            <Box className={classes.panelContent} mx={-12} px={12}>
              <SimpleGrid cols={{ base: 2, lg: 4 }} py={24}>
                <StatBox
                  title="P/L (Current)"
                  statValue={formatCurrency(soldProfit ?? 0)}
                  color={isListingsSoldLoading || soldProfit === undefined || soldProfit === 0 ? 'gray' : isProfitable ? 'green' : 'red'}
                  isLoading={isListingsSoldLoading}
                  isError={showErrorStates}
                >
                  <StatBox.StatLine left="Revenue" right={formatCurrency(soldRevenue ?? 0)} />
                  <StatBox.StatLine left="Cost" right={formatCurrency(soldCost ?? 0)} />
                  <StatBox.StatLine left="ROI" right={`${soldReturnOnInvestment !== undefined && isFinite(soldReturnOnInvestment) ? soldReturnOnInvestment.toFixed(2) : '--'} %`} />
                </StatBox>
                <StatBox title="Open Cost" statValue={formatCurrency(openCost ?? 0)} icon={<PaidIcon />} isLoading={isListingsOpenLoading} isError={showErrorStates}>
                  <StatBox.StatLine left="Tickets" right={openTickets ?? 0} />
                  <StatBox.StatLine left="Broadcast" right={openTicketsBroadcasting ?? 0} />
                  <StatBox.StatLine left="Unbroadcast" right={openTickets !== undefined && openTicketsBroadcasting !== undefined ? openTickets - openTicketsBroadcasting : 0} />
                </StatBox>
                <StatBox title="Tickets Sold" statValue={`${soldTickets ?? 0}`} icon={<ConfirmationNumberIcon />} isLoading={isListingsSoldLoading} isError={showErrorStates}>
                  <StatBox.StatLine left="Last 24 Hrs" right={soldTicketsLast24Hours !== undefined ? soldTicketsLast24Hours : '--'} />
                  <StatBox.StatLine left="Last 7 Days" right={soldTicketsLast7Days !== undefined ? soldTicketsLast7Days : '--'} />
                  <StatBox.StatLine left="Last 30 Days" right={soldTicketsLast30Days !== undefined ? soldTicketsLast30Days : '--'} />
                </StatBox>
                <Flex direction="column" gap="xs">
                  <StatBox
                    title="P/L (Projected)"
                    statValue={formatCurrency(projectedProfit ?? 0)}
                    color="gray"
                    icon={!showEmptyStates ? <ProfitabilityArrow value={projectedProfit} /> : <ProfitabilityArrow value={undefined} />}
                    isLoading={isListingsOpenLoading}
                    isError={showErrorStates}
                  />
                  <StatBox
                    title="ROI (Projected)"
                    statValue={`${projectedReturnOnInvestment !== undefined && isFinite(projectedReturnOnInvestment) ? projectedReturnOnInvestment.toFixed(2) : '--'} %`}
                    color="gray"
                    icon={!showEmptyStates ? <ProfitabilityArrow value={projectedReturnOnInvestment} /> : <ProfitabilityArrow value={undefined} />}
                    isLoading={isListingsOpenLoading}
                    isError={showErrorStates}
                  />
                </Flex>
              </SimpleGrid>
              <Flex direction="column" gap="xs" className={classes.performanceChartWrapper}>
                {showErrorStates ? (
                  <BNEmptyState title="Data Error" description="Contact support if this issue persists" icon={<ErrorIcon size={28} color="var(--colors-red-error)" />} />
                ) : (
                  <PerformanceChart />
                )}
              </Flex>
              <Divider my={{ base: 10, lg: 24 }} color="var(--colors-divider)" className={classes.divider} />
              <Flex h={240} pb="sm" direction={{ base: 'column', lg: 'row' }} gap={{ base: 'xs', lg: 0 }}>
                <Flex direction="column" gap="xs" style={{ flex: 1 }}>
                  {inTuneFlag ? (
                    <EventPerformanceOfferTable />
                  ) : showErrorStates ? (
                    <BNEmptyState title="Data Error" description="Contact support if this issue persists" icon={<ErrorIcon color="var(--colors-red-error)" size={28} />} />
                  ) : (
                    <Flex direction="column" h="100%" w="100%" pos="relative">
                      <MarketplaceTrends />
                    </Flex>
                  )}
                </Flex>
                <Divider orientation="vertical" mx="md" color="var(--colors-divider)" />
                <Flex direction="column" gap="xs" style={{ flex: 1 }}>
                  <Group justify="space-between">
                    <Text size="sm" fw={600}>
                      Marketplace Breakdown
                    </Text>
                  </Group>
                  {showErrorStates ? (
                    <BNEmptyState
                      title="Data Error"
                      titleFz={14}
                      description="Contact support if this issue persists"
                      icon={<ErrorIcon color="var(--colors-red-error)" size={28} />}
                    />
                  ) : (
                    <MarketplaceBreakdown />
                  )}
                </Flex>
                <Space h="sm" w="100%" className={classes.bottomSpace} />
              </Flex>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
