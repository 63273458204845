import { Box, Combobox, FloatingPosition, Group, Input, Pill, PillsInput, Text, useCombobox } from '@mantine/core';
import classes from './ExclusionsMultiSelect.module.css';
import { BarkerCoreEnumsDeliveryMethod, BarkerCoreEnumsListingDisclosure } from '../../api';
import AccessibilityNewIcon from '../icons/AccessibilityNew';
import AccessibleIcon from '../icons/Accessible';
import VisibilityOffIcon from '../icons/VisibilityOff';
import ConfirmationNumberIcon from '../icons/ConfirmationNumber';
import FlashOnIcon from '../icons/FlashOn';
import LocalShippingIcon from '../icons/LocalShipping';
import LocationOnIcon from '../icons/LocationOn';
import QrCodeIcon from '../icons/QrCode';
import SelectArrowsVariableIcon from '../icons/SelectArrows';
import FoamFingerIcon from '../icons/FoamFinger';

export const ExclusionsList = [
  {
    group: 'Disclosures',
    icon: <VisibilityOffIcon color="var(--colors-iconFill)" />,
    label: 'Limited View',
    value: BarkerCoreEnumsListingDisclosure.LimitedObstructedView,
  },
  {
    group: 'Disclosures',
    icon: <AccessibleIcon color="var(--colors-iconFill)" />,
    label: 'Accessible Seating',
    value: BarkerCoreEnumsListingDisclosure.Accessibility,
  },
  {
    group: 'Disclosures',
    icon: <AccessibilityNewIcon color="var(--colors-iconFill)" />,
    label: 'Standing Room Only',
    value: BarkerCoreEnumsListingDisclosure.StandingRoomOnly,
  },
  {
    group: 'Disclosures',
    icon: <FoamFingerIcon color="var(--colors-iconFill)" />,
    label: 'Fan Seller',
    value: BarkerCoreEnumsListingDisclosure.FanListing,
  },
  {
    group: 'Delivery Methods',
    icon: <FlashOnIcon color="var(--colors-iconFill)" />,
    label: 'Instant Delivery',
    value: BarkerCoreEnumsDeliveryMethod.Instant,
  },
  {
    group: 'Delivery Methods',
    icon: <QrCodeIcon color="var(--colors-iconFill)" />,
    label: 'Electronic Delivery',
    value: BarkerCoreEnumsDeliveryMethod.Electronic,
  },
  {
    group: 'Delivery Methods',
    icon: <LocalShippingIcon color="var(--colors-iconFill)" />,
    label: 'Physical Delivery',
    value: BarkerCoreEnumsDeliveryMethod.Physical,
  },
  {
    group: 'Delivery Methods',
    icon: <LocationOnIcon color="var(--colors-iconFill)" />,
    label: 'Special Delivery',
    value: BarkerCoreEnumsDeliveryMethod.Special,
  },
  {
    group: 'Delivery Methods',
    icon: <ConfirmationNumberIcon color="var(--colors-iconFill)" />,
    label: 'Unknown Delivery',
    value: BarkerCoreEnumsDeliveryMethod.Unknown,
  },
] as const;

type BaseProps = {
  value: (BarkerCoreEnumsListingDisclosure | BarkerCoreEnumsDeliveryMethod)[];
  placeholder?: string;
  removeSelected?: boolean;
  filter?: (typeof ExclusionsList)[number]['group'] | undefined;
  label?: string;
  position?: FloatingPosition;
};

type PropWithSplitResults = {
  splitResults: true;
  onChange: (value: Record<(typeof ExclusionsList)[number]['group'], (BarkerCoreEnumsListingDisclosure | BarkerCoreEnumsDeliveryMethod)[]>) => void;
};

type PropWithoutSplitResults = {
  splitResults?: false;
  onChange: (value: (BarkerCoreEnumsListingDisclosure | BarkerCoreEnumsDeliveryMethod)[]) => void;
};

type Props = BaseProps & (PropWithSplitResults | PropWithoutSplitResults);

export const BNExclusionsMultiSelect = ({ value, filter, placeholder, onChange, removeSelected, splitResults, label, position }: Props) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const handleValueSelect = (val: string) => {
    const typedVal = val as BarkerCoreEnumsListingDisclosure | BarkerCoreEnumsDeliveryMethod;
    if (splitResults) {
      const selectedList = ExclusionsList.filter((exclusion) => value.includes(exclusion.value))!;
      // Create groups from selected values
      const groupedValues = selectedList.reduce(
        (acc, item) => {
          acc[item.group] = [...(acc[item.group] ?? []), item.value];
          return acc;
        },
        {} as Record<(typeof ExclusionsList)[number]['group'], (BarkerCoreEnumsListingDisclosure | BarkerCoreEnumsDeliveryMethod)[]>,
      );

      const selectedValObj = ExclusionsList.find((exclusion) => exclusion.value === typedVal)!;
      const selectedGroup = groupedValues[selectedValObj.group] ?? [];
      selectedGroup.push(typedVal);

      onChange(groupedValues);
    } else {
      onChange([...value, typedVal]);
    }
  };
  const handleValueRemove = (val: BarkerCoreEnumsListingDisclosure | BarkerCoreEnumsDeliveryMethod) => {
    if (splitResults) {
      const selectedList = ExclusionsList.filter((exclusion) => value.includes(exclusion.value))!;
      const groupedValues = selectedList.reduce(
        (acc, item) => {
          acc[item.group] = [...(acc[item.group] ?? []), item.value];
          return acc;
        },
        {} as Record<(typeof ExclusionsList)[number]['group'], (BarkerCoreEnumsListingDisclosure | BarkerCoreEnumsDeliveryMethod)[]>,
      );

      const selectedValObj = ExclusionsList.find((exclusion) => exclusion.value === val)!;
      const selectedGroup = groupedValues[selectedValObj.group] ?? [];
      selectedGroup.splice(selectedGroup.indexOf(val), 1);

      onChange(groupedValues);
    } else {
      onChange(value.filter((v) => v !== val));
    }
  };

  const values = value.map((item) => {
    const listItem = ExclusionsList.find((exclusion) => exclusion.value === item)!;
    return (
      <Pill className={classes.exclusionPill} key={listItem.value} withRemoveButton onRemove={() => handleValueRemove(item)}>
        {listItem.icon}
      </Pill>
    );
  });

  const groupedOptions = ExclusionsList.filter((x) => (removeSelected ? !value.includes(x.value) : true))
    .filter((x) => (filter ? x.group === filter : x))
    .reduce(
      (acc, item) => {
        acc[item.group] = [...(acc[item.group] ?? []), item];
        return acc;
      },
      {} as Record<(typeof ExclusionsList)[number]['group'], (typeof ExclusionsList)[number][]>,
    );

  const renderOption = (items: (typeof ExclusionsList)[number][]) => (
    <>
      {items.map((item) => (
        <Combobox.Option value={item.value} key={item.value} active={value.includes(item.value)}>
          <Box>
            <Group gap={10} wrap="nowrap">
              {item?.icon}
              <div>
                <Text size="xs">{item?.label}</Text>
              </div>
            </Group>
          </Box>
        </Combobox.Option>
      ))}
    </>
  );

  const options = !filter
    ? Object.entries(groupedOptions).map(([group, items]) => (
        <Combobox.Group label={group} key={group}>
          {renderOption(items)}
        </Combobox.Group>
      ))
    : renderOption(groupedOptions[filter] ?? []);

  return (
    <Combobox store={combobox} onOptionSubmit={handleValueSelect} position={position}>
      <Combobox.DropdownTarget>
        <PillsInput
          label={label}
          size="xs"
          className={classes.exclusionPillInput}
          pointer
          rightSection={<SelectArrowsVariableIcon />}
          rightSectionPointerEvents="none"
          onClick={() => combobox.toggleDropdown()}
        >
          <Pill.Group>
            {values.length > 0 ? values : <Input.Placeholder>{placeholder}</Input.Placeholder>}
            <Combobox.EventsTarget>
              <PillsInput.Field
                type="hidden"
                onBlur={() => combobox.closeDropdown()}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace') {
                    event.preventDefault();
                    handleValueRemove(value[value.length - 1]);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
