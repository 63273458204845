import pluralize from 'pluralize';
import { Text } from '@mantine/core';
import { formatCurrency } from '../../utils/formatters';
import { usePurchaseState } from './Purchase.hooks';

export function Totals() {
  const { selectedEvents, totalListings, totalCost, totalTickets } = usePurchaseState('selectedEvents', 'totalListings', 'totalCost', 'totalTickets');
  return (
    <Text size="xs" fw={600} lh={1.3} ta="right">
      {selectedEvents.length} {pluralize('Event', selectedEvents.length)} &middot; {totalListings} {pluralize('Listing', totalListings)} &middot; {totalTickets}{' '}
      {pluralize('Tickets', totalTickets)} &middot; {formatCurrency(totalCost)} Total Cost
    </Text>
  );
}
