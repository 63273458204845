import { Badge, Box } from '@mantine/core';
import { useMemo } from 'react';
import classes from './Tags.module.css';

export type BNTagsProps = {
  tags: string[] | null;
};

export const BNTags = ({ tags }: BNTagsProps) => {
  const sortedTags = useMemo(() => tags?.sort((a, b) => a.localeCompare(b)), [tags]);
  const colorizedTags = useMemo(() => sortedTags?.map((tag) => ({ tag, color: 'gray' })), [sortedTags]);
  return (
    <Box>
      {colorizedTags?.map((tag) => (
        <Badge key={tag.tag} radius="xs" py="sm" px="xs" mr={4} variant="outline" bg="var(--colors-paperHover)" className={classes.tagBadge}>
          {tag.tag}
        </Badge>
      ))}
    </Box>
  );
};
