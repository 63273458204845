import classes from './Invoice.tsx.module.css';
import { Box, Center, Group, Loader, SimpleGrid, Skeleton, Table, Text, Title } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import OpenNewIcon from '../../components/icons/OpenNew';
import { AdminContentHeader } from '../../hoc/Admin/AdminContentHeader';
import { Link, useLocation } from 'react-router-dom';
import { useBilling } from '../../hoc/Admin/Billing.hooks';
import { formatCurrency } from '../../utils/formatters';
import dayjs from 'dayjs';
import { generateSkyBoxInvoiceUrl } from '../../utils/urls';
import { InvoiceStatusBadge } from '../Billing';
import * as xlsx from 'xlsx';
import ChevronBackIcon from '../../components/icons/ChevronBack';
import { BarkerCoreEnumsPointOfSale, BarkerCoreModelsAdministrativeInvoiceItem } from '../../api';

function exportToXlsx(name: string) {
  const table = document.getElementById('transaction-table');
  const wb = xlsx.utils.table_to_book(table, { sheet: name });
  xlsx.writeFile(wb, `${name}.xlsx`);
}

export function TransactionsTable({
  items,
  tenantPointOfSale,
  isLoading,
}: {
  items: BarkerCoreModelsAdministrativeInvoiceItem[] | null | undefined;
  tenantPointOfSale: BarkerCoreEnumsPointOfSale;
  isLoading: boolean;
}) {
  const rows = (items ?? [])
    .sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix())
    .map((element) => (
      <tr key={element.saleId}>
        <td width={140}>{dayjs(element.createdAt).format('M/D/YYYY')}</td>
        <td width={140}>{element.category}</td>
        <td>{element.description}</td>
        <td width={160}>
          {element.saleId && (
            <>
              {tenantPointOfSale === BarkerCoreEnumsPointOfSale.SkyBox ? (
                <>
                  <Text component="a" size="sm" href={generateSkyBoxInvoiceUrl(element.saleId)} target="_blank" td="underline" mr={4} c="var(--colors-brandcolor-5)">
                    {element.saleId}
                  </Text>
                  <OpenNewIcon size={12} color="var(--colors-gray-5)" />
                </>
              ) : (
                <Text>{element.saleId}</Text>
              )}
            </>
          )}
        </td>
        <td width={120} align="right">
          {formatCurrency(element.amount)}
        </td>
      </tr>
    ));

  return (
    <Box pt="sm">
      <Group justify="space-between">
        <Title my="lg" order={4}>
          Transactions
        </Title>
        <Group align="center" justify="center">
          {/* <ActionIcon>
                <SearchIcon size={24} />
              </ActionIcon>
              <Divider orientation="vertical" h="sm" my="auto" color="var(--colors-lightDivider)" /> */}
          <BNButton size="xs" variant="default" onClick={() => exportToXlsx('Current Usage')}>
            Export
          </BNButton>
        </Group>
      </Group>
      <Table verticalSpacing="xs" className={classes.adminTable} id="transaction-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Description</th>
            <th>Sale ID</th>
            <th>Fees</th>
          </tr>
        </thead>
        {!isLoading ? (
          rows.length > 0 ? (
            <tbody>{rows}</tbody>
          ) : (
            <tbody>
              <tr className="loadingRow">
                <td width={140} />
                <td width={140} />
                <td height={240}>
                  <Center>
                    <Text c="var(--colors-gray-5)" pos="absolute" right={0} left={0} ta="center">
                      No Transactions Found
                    </Text>
                  </Center>
                </td>
                <td width={160} />
                <td width={120} />
              </tr>
            </tbody>
          )
        ) : (
          <tbody>
            <tr className="loadingRow">
              <td width={140} />
              <td width={140} />
              <td height={240}>
                <Loader type="dots" pos="absolute" right="calc(50% - 18px)" top={110} />
              </td>
              <td width={160} />
              <td width={120} />
            </tr>
          </tbody>
        )}
      </Table>
    </Box>
  );
}

export default function Invoice() {
  const { selectedInvoice, invoiceId, totalSales, totalFees, tenantPointOfSale, isLoading } = useBilling(
    'selectedInvoice',
    'invoiceId',
    'totalSales',
    'totalFees',
    'tenantPointOfSale',
    'isLoading',
  );
  const location = useLocation();

  const backlinkTo = location.pathname.replace(/\/invoices\/.*/, '');

  return (
    <>
      {!isLoading ? (
        <AdminContentHeader
          title={invoiceId === 'current' ? 'Current Usage' : selectedInvoice ? `Invoice #${selectedInvoice?.invoiceNumber}` : ''}
          backlinkTo={backlinkTo}
          backlinkTitle="Billing Overview"
          sticky
        />
      ) : (
        <Box h={81}>
          <Group c="var(--colors-gray-5)" h={18} gap={0} align="center" justify="left" mt={-18} className={classes.backlink}>
            {backlinkTo && (
              <>
                <ChevronBackIcon size={12} />
                <Link to={backlinkTo}>
                  <Text size="xs">Billing Overview</Text>
                </Link>
              </>
            )}
          </Group>
          <Skeleton maw={180} h={24} mt={20} mb={10} />
        </Box>
      )}

      {/* <Group h={320} mb={48} bg="var(--colors-opacity-hover)" align="center" justify="center">
        <Text color="var(--colors-gray-5)">Usage Chart</Text>
      </Group> */}
      <SimpleGrid
        cols={4}
        style={{
          borderBottom: '1px solid var(--colors-gray-1)',
        }}
      >
        <Box pos="relative" pr="md" pb="xs" pt="md">
          {!isLoading ? (
            <Text fw={600} fz={11} tt="uppercase" c="var(--colors-gray-5)" lts=".025em">
              Date Sent
            </Text>
          ) : (
            <Skeleton maw={120} h={8} mt={4} mb={14} />
          )}
          {!isLoading ? (
            <Title mt={2} mb={6} order={4}>
              {dayjs(selectedInvoice?.sentAt).format('M/D/YYYY')}
            </Title>
          ) : (
            <Skeleton maw={120} h={16} mt={12} mb={4} />
          )}
        </Box>
        <Box pos="relative" pl="md" pb="xs" pt="md" style={{ borderLeft: '1px solid var(--colors-gray-1)' }}>
          {!isLoading ? (
            <Text fw={600} fz={11} tt="uppercase" c="var(--colors-gray-5)" lts=".025em">
              Status
            </Text>
          ) : (
            <Skeleton maw={120} h={8} mt={4} mb={14} />
          )}
          {!isLoading && selectedInvoice ? (
            <Group justify="space-between" mt={6}>
              <InvoiceStatusBadge paidAt={selectedInvoice.paidAt} dueAt={selectedInvoice?.dueAt} />
              {!selectedInvoice.paidAt && (
                <BNButton size="compact-xs" variant="default">
                  Resend
                </BNButton>
              )}
            </Group>
          ) : (
            <Skeleton maw={120} h={16} mt={12} mb={4} />
          )}
        </Box>
        <Box pos="relative" pl="md" pb="xs" pt="md" mih={77} style={{ borderLeft: '1px solid var(--colors-gray-1)' }}>
          {!isLoading ? (
            <Text fw={600} fz={11} tt="uppercase" color="var(--colors-gray-5)" lts=".025em">
              Total Sales
            </Text>
          ) : (
            <Skeleton maw={120} h={8} mt={4} mb={14} />
          )}
          {!isLoading ? (
            <Title mt={2} mb={6} order={4}>
              {formatCurrency(totalSales)}
            </Title>
          ) : (
            <Skeleton maw={120} h={16} mt={12} mb={4} />
          )}
        </Box>
        <Box pos="relative" pl="md" pb="xs" pt="md" style={{ borderLeft: '1px solid var(--colors-gray-1)' }}>
          {!isLoading ? (
            <Text fw={600} fz={11} tt="uppercase" c="var(--colors-gray-5)" lts=".025em">
              Total Fees
            </Text>
          ) : (
            <Skeleton maw={120} h={8} mt={4} mb={14} />
          )}
          {!isLoading ? (
            <Title mt={2} mb={6} order={4}>
              {formatCurrency(totalFees)}
            </Title>
          ) : (
            <Skeleton maw={120} h={16} mt={12} mb={4} />
          )}
        </Box>
      </SimpleGrid>
      <TransactionsTable items={selectedInvoice?.items} isLoading={isLoading} tenantPointOfSale={tenantPointOfSale} />
    </>
  );
}
