import { ActionIcon, Box, Flex, Group, Menu, Stack, Text, Tooltip } from '@mantine/core';
import { usePurchaseState } from './Purchase.hooks';
import StadiumIcon from '../../components/icons/Stadium';
import MoreVertIcon from '../../components/icons/MoreVert';
import AddIcon from '../../components/icons/Add';
import DeleteIcon from '../../components/icons/Delete';
import { EventsSelector } from './Purchase.EventsSelector';
import { formatDate } from '../../utils/formatters';
import { DateFormats } from '../../utils/globals';
import dayjs from 'dayjs';
import { OrderItem } from './Purchase.OrderItem';
import { TicketGroup } from '../../types';

export function PurchaseForm() {
  const { form, onSubmit, addListing, activeListing, setActiveListing, calculateSeatRange, copyListing, targetRef } = usePurchaseState(
    'form',
    'onSubmit',
    'addListing',
    'activeListing',
    'setActiveListing',
    'calculateSeatRange',
    'copyListing',
    'targetRef',
  );

  return (
    <Box w="100%" maw={860}>
      <Stack>
        <form onSubmit={form.onSubmit(onSubmit)}>
          {form.values.groups.map((group, groupIndex) => (
            <Box pt={10} pb={0} px={16} my={12} style={{ border: '1px solid var(--colors-gray-2)', borderRadius: 3 }} key={group.group}>
              <>
                <Flex align="center" w="100%" justify="space-between" pb="xs">
                  <EventTitle {...group} />
                  <Group align="center" gap={0}>
                    <EventsSelector selectedEventIds={group.eventIds} groupIndex={groupIndex} />
                    <Menu withArrow>
                      <Menu.Target>
                        <ActionIcon my={-10}>
                          <MoreVertIcon />
                        </ActionIcon>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item leftSection={<AddIcon />} onClick={() => addListing(groupIndex)}>
                          Add New Listing
                        </Menu.Item>
                        <Menu.Item leftSection={<DeleteIcon />} onClick={() => form.removeListItem('groups', groupIndex)} style={{ borderBottom: 'none' }}>
                          Delete Group
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </Group>
                </Flex>
                {group.orders.map((order, index) => (
                  <OrderItem
                    key={order.key}
                    group={group}
                    order={order}
                    groupIndex={groupIndex}
                    index={index}
                    activeListing={activeListing}
                    calculateSeatRange={calculateSeatRange}
                    form={form}
                    addListing={addListing}
                    copyListing={copyListing}
                    setActiveListing={setActiveListing}
                    targetRef={targetRef}
                  />
                ))}
              </>
            </Box>
          ))}
        </form>
      </Stack>
    </Box>
  );
}

const EventTitle = (group: TicketGroup) => {
  const { orderEvents } = usePurchaseState('orderEvents');
  const event = orderEvents.find((_event) => _event.id === group.eventIds[0]);
  if (group.eventIds.length > 1 || !event) {
    const eventNames = group.eventIds.map((eventId) => {
      const _event = orderEvents.find((x) => x.id === eventId);
      return _event ? `${_event.event_name}` : '';
    });
    return (
      <Group wrap="nowrap" gap={8} align="center" flex={1} pr="xs" style={{ overflow: 'hidden' }}>
        <Tooltip
          label={
            <Text maw={280} component="div" p="xs" style={{ whiteSpace: 'normal' }}>
              <ul>
                {eventNames.map((name) => (
                  <li key={name}>{name}</li>
                ))}
              </ul>
            </Text>
          }
          withArrow
        >
          <Group wrap="nowrap" gap={8}>
            <StadiumIcon size={20} color="var(--colors-gray-5)" />
            <Text fw={600}>{group.eventIds.length} Events</Text>
          </Group>
        </Tooltip>
      </Group>
    );
  }

  return (
    <Group wrap="nowrap" gap={8} align="center" flex={1} pr="xs" style={{ overflow: 'hidden' }}>
      <Group wrap="nowrap" gap={8}>
        <StadiumIcon size={20} color="var(--colors-gray-5)" />
        <Text fw={600}>{event.event_name}</Text>
      </Group>
      <Text flex={1} truncate c="var(--colors-gray-5)">
        {formatDate(dayjs(event.event_date_time).toDate(), DateFormats.Standard)} &middot; {event.venue_name}
      </Text>
    </Group>
  );
};
