export default function ViewSplitIllustration() {
  return (
    <svg width="280" height="160" viewBox="0 0 280 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H280V160H0V0Z" fill="var(--colors-gray-0)" />
      <path d="M21 23C21 21.8954 21.8954 21 23 21H280V160H21V23Z" fill="var(--colors-paper)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23 20C21.3431 20 20 21.3431 20 23V160H21V23C21 21.8954 21.8954 21 23 21H280V20H23Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M280 35H21V34H280V35Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M280 53.5H87V52.5H280V53.5Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M279 63.5H87V62.5H279V63.5Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M280 73.5H87V72.5H280V73.5Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M279 119.5H87V118.5H279V119.5Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M279 129.5H87V128.5H279V129.5Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M280 139.5H87V138.5H280V139.5Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M280 149.5H87V148.5H280V149.5Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M280 110H78V109H280V110Z" fill="var(--colors-gray-0)" />
      <path d="M78 84H280V92H78V84Z" fill="var(--colors-gray-0)" />
      <path d="M78 35H280V43H78V35Z" fill="var(--colors-gray-0)" />
      <path d="M83 35L83 160L79 160L79 35L83 35Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M78 160L78 21L79 21L79 160L78 160Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M107 40H87V38H107V40Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M167 40H147V38H167V40Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M227 40H207V38H227V40Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M257 40H237V38H257V40Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M45.5 29H25V27H45.5V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M58 29H52V27H58V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M66 29H60V27H66V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M74 29H68V27H74V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M103.5 29H83V27H103.5V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M116 29H110V27H116V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M124 29H118V27H124V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M132 29H126V27H132V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M243.5 29H223V27H243.5V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M256 29H250V27H256V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M264 29H258V27H264V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M272 29H266V27H272V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M85 92L85 109L83 109L83 92L85 92Z" fill="var(--colors-brandcolor-5)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M107 89H87V87H107V89Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M137 89H117V87H137V89Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M167 89H147V87H167V89Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M197 89H177V87H197V89Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M227 89H207V87H227V89Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M257 89H237V87H257V89Z" fill="var(--colors-gray-2)" />
      <path d="M83 54C83 53.4477 83.4477 53 84 53H280V63H84C83.4477 63 83 62.5523 83 62V54Z" fill="var(--colors-selectedBg)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M83 54V62C83 62.5523 83.4477 63 84 63H280V62H84V54H280V53H84C83.4477 53 83 53.4477 83 54Z" fill="var(--colors-selectedBorder)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M137 59H87V57H137V59Z" fill="var(--colors-selected-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M197 59H147V57H197V59Z" fill="var(--colors-selected-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M227 59H207V57H227V59Z" fill="var(--colors-selected-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M257 59H237V57H257V59Z" fill="var(--colors-selected-2)" />
      <path d="M83 120C83 119.448 83.4477 119 84 119H280V129H84C83.4477 129 83 128.552 83 128V120Z" fill="var(--colors-selectedBg)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M83 120V128C83 128.552 83.4477 129 84 129H280V128H84V120H280V119H84C83.4477 119 83 119.448 83 120Z" fill="var(--colors-selectedBorder)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M107 125H87V123H107V125Z" fill="var(--colors-selected-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M137 125H117V123H137V125Z" fill="var(--colors-selected-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M167 125H147V123H167V125Z" fill="var(--colors-selected-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M197 125H177V123H197V125Z" fill="var(--colors-selected-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M227 125H207V123H227V125Z" fill="var(--colors-selected-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M257 125H237V123H257V125Z" fill="var(--colors-selected-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M230 101H224V99H230V101Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M244 101H238V99H244V101Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M258 101H252V99H258V101Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M272 101H266V99H272V101Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M106 104H90V102H106V104Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M106 99H90V97H106V99Z" fill="var(--colors-brandcolor-5)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M149 104H113V102H149V104Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M193 99H113V97H193V99Z" fill="var(--colors-gray-5)" />
      <path d="M25 41C25 40.4477 25.4477 40 26 40H47C47.5523 40 48 40.4477 48 41V45C48 45.5523 47.5523 46 47 46H26C25.4477 46 25 45.5523 25 45V41Z" fill="var(--colors-paper)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47 41H26L26 45H47V41ZM26 40C25.4477 40 25 40.4477 25 41V45C25 45.5523 25.4477 46 26 46H47C47.5523 46 48 45.5523 48 45V41C48 40.4477 47.5523 40 47 40H26Z"
        fill="var(--colors-gray-2)"
      />
      <path d="M51 41C51 40.4477 51.4477 40 52 40H73C73.5523 40 74 40.4477 74 41V45C74 45.5523 73.5523 46 73 46H52C51.4477 46 51 45.5523 51 45V41Z" fill="var(--colors-paper)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73 41H52L52 45H73V41ZM52 40C51.4477 40 51 40.4477 51 41V45C51 45.5523 51.4477 46 52 46H73C73.5523 46 74 45.5523 74 45V41C74 40.4477 73.5523 40 73 40H52Z"
        fill="var(--colors-gray-2)"
      />
      <path d="M25 50C25 49.4477 25.4477 49 26 49H73C73.5523 49 74 49.4477 74 50V54C74 54.5523 73.5523 55 73 55H26C25.4477 55 25 54.5523 25 54V50Z" fill="var(--colors-paper)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73 50H26L26 54H73V50ZM26 49C25.4477 49 25 49.4477 25 50V54C25 54.5523 25.4477 55 26 55H73C73.5523 55 74 54.5523 74 54V50C74 49.4477 73.5523 49 73 49H26Z"
        fill="var(--colors-gray-2)"
      />
      <path d="M25 59C25 58.4477 25.4477 58 26 58H73C73.5523 58 74 58.4477 74 59V101C74 101.552 73.5523 102 73 102H26C25.4477 102 25 101.552 25 101V59Z" fill="var(--colors-paper)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73 59L26 59L26 101H73V59ZM26 58C25.4477 58 25 58.4477 25 59V101C25 101.552 25.4477 102 26 102H73C73.5523 102 74 101.552 74 101V59C74 58.4477 73.5523 58 73 58H26Z"
        fill="var(--colors-gray-2)"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M280 84H78.5V83H280V84Z" fill="var(--colors-gray-2)" />
    </svg>
  );
}
