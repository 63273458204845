import { IconPropsBase } from './IconPropsBase';

export default function ResetIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M11.975 13.5q-.6 0-1.05-.438-.45-.437-.45-1.062t.45-1.062q.45-.438 1.05-.438.625 0 1.063.438.437.437.437 1.062t-.437 1.062q-.438.438-1.063.438Zm0 7q-3.25 0-5.663-2.137Q3.9 16.225 3.55 13h1.525q.375 2.6 2.337 4.3Q9.375 19 11.975 19q2.925 0 4.963-2.038 2.037-2.037 2.037-4.962t-2.037-4.963Q14.9 5 11.975 5q-1.625 0-3.062.725-1.438.725-2.463 2h2.6v1.5H3.975V4.15h1.5v2.375Q6.7 5.075 8.4 4.287q1.7-.787 3.575-.787 1.775 0 3.325.675Q16.85 4.85 18 6q1.15 1.15 1.812 2.688.663 1.537.663 3.312t-.663 3.312Q19.15 16.85 18 18q-1.15 1.15-2.7 1.825-1.55.675-3.325.675Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M9.979 11.083q-.437 0-.76-.323-.323-.322-.323-.76 0-.458.323-.771.323-.312.76-.312.459 0 .771.323.312.322.312.76 0 .458-.322.771-.323.312-.761.312Zm0 5.5q-2.729 0-4.667-1.916Q3.375 12.75 3.417 10h1.062q.063 2.292 1.656 3.896Q7.729 15.5 9.979 15.5q2.313 0 3.906-1.604 1.594-1.604 1.594-3.896t-1.594-3.896Q12.292 4.5 9.979 4.5q-1.146 0-2.187.469-1.042.469-1.854 1.302h2.104v1.083h-4.23V3.125h1.084v2.729q.958-1.166 2.281-1.802 1.323-.635 2.802-.635 1.375 0 2.573.521 1.198.52 2.083 1.406.886.885 1.407 2.083.52 1.198.52 2.573t-.52 2.573q-.521 1.198-1.407 2.083-.885.886-2.083 1.406-1.198.521-2.573.521Z"
        />
      </svg>
    );
  }
}
