export type BarkerCoreEnumsPricerStatus = (typeof BarkerCoreEnumsPricerStatus)[keyof typeof BarkerCoreEnumsPricerStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BarkerCoreEnumsPricerStatus = {
  None: 'None',
  AutoPriced: 'AutoPriced',
  AtCeiling: 'AtCeiling',
  AtFloor: 'AtFloor',
  NoComparables: 'NoComparables',
  Errored: 'Errored',
  Scheduled: 'Scheduled',
} as const;
