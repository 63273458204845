import { IconPropsBase } from './IconPropsBase';

export default function ReceiptIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="M140.001-104.617v-750.766l55.385 49.23 56.923-49.23 56.922 49.23 56.923-49.23 56.923 49.23L480-855.383l56.923 49.23 56.923-49.23 56.923 49.23 56.922-49.23 56.923 49.23 55.385-49.23v750.766l-55.385-49.23-56.923 49.23-56.922-49.23-56.923 49.23-56.923-49.23L480-104.617l-56.923-49.23-56.923 49.23-56.923-49.23-56.922 49.23-56.923-49.23-55.385 49.23Zm110-197.692h459.998v-59.999H250.001v59.999Zm0-147.692h459.998v-59.998H250.001v59.998Zm0-147.691h459.998v-59.999H250.001v59.999ZM200-196h560v-568H200v568Zm0-568v568-568Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="M164.001-120.617v-718.766l51.385 34.23 52.923-34.23 52.922 34.23 52.923-34.23 52.923 34.23L480-839.383l52.923 34.23 52.923-34.23 52.923 34.23 52.922-34.23 52.923 34.23 51.385-34.23v718.766l-51.385-34.23-52.923 34.23-52.922-34.23-52.923 34.23-52.923-34.23L480-120.617l-52.923-34.23-52.923 34.23-52.923-34.23-52.922 34.23-52.923-34.23-51.385 34.23Zm134-213.692h363.998v-51.999H298.001v51.999Zm0-119.692h363.998v-51.998H298.001v51.998Zm0-119.691h363.998v-51.999H298.001v51.999ZM216-216h528v-528H216v528Zm0-528v528-528Z" />
      </svg>
    );
  }
}
