import { useMutation, useQuery } from '@tanstack/react-query';
import type { MutationFunction, QueryFunction, QueryKey, UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { BarkerCoreModelsNotificationsUserNotification } from './models';

export const getApiNotificationsUnreadCount = (options?: AxiosRequestConfig): Promise<AxiosResponse<number>> => {
  return axios.get(`/api/Notifications/Unread/Count`, options);
};

export const getGetApiNotificationsUnreadCountQueryKey = () => {
  return [`/api/Notifications/Unread/Count`] as const;
};

export const getGetApiNotificationsUnreadCountQueryOptions = <TData = Awaited<ReturnType<typeof getApiNotificationsUnreadCount>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiNotificationsUnreadCount>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiNotificationsUnreadCountQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiNotificationsUnreadCount>>> = ({ signal }) => getApiNotificationsUnreadCount({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiNotificationsUnreadCount>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiNotificationsUnreadCountQueryResult = NonNullable<Awaited<ReturnType<typeof getApiNotificationsUnreadCount>>>;
export type GetApiNotificationsUnreadCountQueryError = AxiosError<void>;

export const useGetApiNotificationsUnreadCount = <TData = Awaited<ReturnType<typeof getApiNotificationsUnreadCount>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiNotificationsUnreadCount>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiNotificationsUnreadCountQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiNotificationsActiveLatest = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsNotificationsUserNotification[]>> => {
  return axios.get(`/api/Notifications/Active/Latest`, options);
};

export const getGetApiNotificationsActiveLatestQueryKey = () => {
  return [`/api/Notifications/Active/Latest`] as const;
};

export const getGetApiNotificationsActiveLatestQueryOptions = <TData = Awaited<ReturnType<typeof getApiNotificationsActiveLatest>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiNotificationsActiveLatest>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiNotificationsActiveLatestQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiNotificationsActiveLatest>>> = ({ signal }) => getApiNotificationsActiveLatest({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiNotificationsActiveLatest>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiNotificationsActiveLatestQueryResult = NonNullable<Awaited<ReturnType<typeof getApiNotificationsActiveLatest>>>;
export type GetApiNotificationsActiveLatestQueryError = AxiosError<void>;

export const useGetApiNotificationsActiveLatest = <TData = Awaited<ReturnType<typeof getApiNotificationsActiveLatest>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiNotificationsActiveLatest>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiNotificationsActiveLatestQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiNotificationsLatest = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsNotificationsUserNotification[]>> => {
  return axios.get(`/api/Notifications/Latest`, options);
};

export const getGetApiNotificationsLatestQueryKey = () => {
  return [`/api/Notifications/Latest`] as const;
};

export const getGetApiNotificationsLatestQueryOptions = <TData = Awaited<ReturnType<typeof getApiNotificationsLatest>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiNotificationsLatest>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiNotificationsLatestQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiNotificationsLatest>>> = ({ signal }) => getApiNotificationsLatest({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiNotificationsLatest>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiNotificationsLatestQueryResult = NonNullable<Awaited<ReturnType<typeof getApiNotificationsLatest>>>;
export type GetApiNotificationsLatestQueryError = AxiosError<void>;

export const useGetApiNotificationsLatest = <TData = Awaited<ReturnType<typeof getApiNotificationsLatest>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiNotificationsLatest>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiNotificationsLatestQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putApiNotificationsNotificationIdRead = (notificationId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Notifications/${notificationId}/Read`, undefined, options);
};

export const getPutApiNotificationsNotificationIdReadMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiNotificationsNotificationIdRead>>, TError, { notificationId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiNotificationsNotificationIdRead>>, TError, { notificationId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiNotificationsNotificationIdRead>>, { notificationId: string }> = (props) => {
    const { notificationId } = props ?? {};

    return putApiNotificationsNotificationIdRead(notificationId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiNotificationsNotificationIdReadMutationResult = NonNullable<Awaited<ReturnType<typeof putApiNotificationsNotificationIdRead>>>;

export type PutApiNotificationsNotificationIdReadMutationError = AxiosError<void>;

export const usePutApiNotificationsNotificationIdRead = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiNotificationsNotificationIdRead>>, TError, { notificationId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiNotificationsNotificationIdRead>>, TError, { notificationId: string }, TContext> => {
  const mutationOptions = getPutApiNotificationsNotificationIdReadMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiNotificationsNotificationIdArchive = (notificationId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Notifications/${notificationId}/Archive`, undefined, options);
};

export const getPutApiNotificationsNotificationIdArchiveMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiNotificationsNotificationIdArchive>>, TError, { notificationId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiNotificationsNotificationIdArchive>>, TError, { notificationId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiNotificationsNotificationIdArchive>>, { notificationId: string }> = (props) => {
    const { notificationId } = props ?? {};

    return putApiNotificationsNotificationIdArchive(notificationId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiNotificationsNotificationIdArchiveMutationResult = NonNullable<Awaited<ReturnType<typeof putApiNotificationsNotificationIdArchive>>>;

export type PutApiNotificationsNotificationIdArchiveMutationError = AxiosError<void>;

export const usePutApiNotificationsNotificationIdArchive = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiNotificationsNotificationIdArchive>>, TError, { notificationId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiNotificationsNotificationIdArchive>>, TError, { notificationId: string }, TContext> => {
  const mutationOptions = getPutApiNotificationsNotificationIdArchiveMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiNotificationsNotificationIdUnarchive = (notificationId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Notifications/${notificationId}/Unarchive`, undefined, options);
};

export const getPutApiNotificationsNotificationIdUnarchiveMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiNotificationsNotificationIdUnarchive>>, TError, { notificationId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiNotificationsNotificationIdUnarchive>>, TError, { notificationId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiNotificationsNotificationIdUnarchive>>, { notificationId: string }> = (props) => {
    const { notificationId } = props ?? {};

    return putApiNotificationsNotificationIdUnarchive(notificationId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiNotificationsNotificationIdUnarchiveMutationResult = NonNullable<Awaited<ReturnType<typeof putApiNotificationsNotificationIdUnarchive>>>;

export type PutApiNotificationsNotificationIdUnarchiveMutationError = AxiosError<void>;

export const usePutApiNotificationsNotificationIdUnarchive = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiNotificationsNotificationIdUnarchive>>, TError, { notificationId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiNotificationsNotificationIdUnarchive>>, TError, { notificationId: string }, TContext> => {
  const mutationOptions = getPutApiNotificationsNotificationIdUnarchiveMutationOptions(options);

  return useMutation(mutationOptions);
};
