import { useEffect, useState } from 'react';
import { ActionIcon, Collapse, Group, Modal, Paper, Text, UnstyledButton } from '@mantine/core';
import CloseIcon from '../../components/icons/Close';
import { useQuery } from '@tanstack/react-query';
import SyncIcon from '../../components/icons/Sync';

export function VersionNotificationBanner() {
  const [bannerOpen, setBannerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { data: serverVersion } = useQuery(
    ['version'],
    () =>
      fetch('/metadata.json')
        .then((res) => res.json() as Promise<{ version: string }>)
        .then((data) => data.version),
    {
      refetchOnWindowFocus: true,
      refetchInterval: 1000 * 60 * 5, // 5 minutes
    },
  );

  const localVersion = import.meta.env.VITE_APP_VERSION;

  useEffect(() => {
    if (!serverVersion || !localVersion) {
      return;
    }

    // Parse major/minor/patch versions
    const localVersionParts = localVersion.split('.');
    const serverVersionParts = serverVersion.split('.');

    // Compare major/minor/patch versions
    if (parseInt(localVersionParts[0]) < parseInt(serverVersionParts[0])) {
      // Major version is lower, we need to display modal to force users to refresh
      setModalOpen(true);
    } else if (parseInt(localVersionParts[1]) < parseInt(serverVersionParts[1])) {
      // Minor version is lower, we need to display banner to encourage users to refresh
      setBannerOpen(true);
    }
    // We don't care about patch version differences
  }, [serverVersion, localVersion]);

  return (
    <>
      <Collapse in={bannerOpen} w="100%" ta="center">
        <Paper w="100%" bg="var(--colors-gray-5)" c="var(--colors-paper)">
          <Group mih={36} align="center" justify="center" gap="xs">
            <SyncIcon size={20} color="var(--colors-paper)" />
            <Text size="xs" c="var(--colors-paper)">
              There is a new version of this app available. Please{' '}
              <UnstyledButton fz="xs" c="var(--colors-paper)" fw={500} td="underline" onClick={() => window.location.reload()}>
                refresh your browser
              </UnstyledButton>{' '}
              to get the latest version.
            </Text>
            <ActionIcon c="var(--colors-paper)" onClick={() => setBannerOpen(false)} pos="absolute" right={16}>
              <CloseIcon size={20} />
            </ActionIcon>
          </Group>
        </Paper>
      </Collapse>
      <Modal size="sm" opened={modalOpen} withCloseButton={false} onClose={() => true} title="New Release - Update Required" centered>
        <Text size="sm" mb="xs">
          There is a new version of this app available. Please{' '}
          <UnstyledButton fz="sm" fw={500} c="var(--colors-brandcolor-5)" td="underline" onClick={() => window.location.reload()}>
            refresh your browser
          </UnstyledButton>{' '}
          to get the latest version.
        </Text>
      </Modal>
    </>
  );
}
