import { useMutation, useQuery } from '@tanstack/react-query';
import type { MutationFunction, QueryFunction, QueryKey, UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  BarkerCoreModelsDTIBaseResponse,
  BarkerCoreModelsDTIBaseResponseWithMessage,
  BarkerCoreModelsDTIBulkEditListingRequest,
  BarkerCoreModelsDTIBulkEditStateRequest,
  BarkerCoreModelsDTIBulkListingsUpdateResponse,
  BarkerCoreModelsDTIBulkPurchaseRequest,
  BarkerCoreModelsDTIBulkResponseWithErrors,
  BarkerCoreModelsDTIBulkUpdateListingRequest,
  BarkerCoreModelsDTIDeleteListingRequest,
  BarkerCoreModelsDTIDownloadAirbillRequest,
  BarkerCoreModelsDTIEventIssueRequest,
  BarkerCoreModelsDTIGetAccountsResponse,
  BarkerCoreModelsDTIGetCategoriesResponse,
  BarkerCoreModelsDTIGetEventListingsResponse,
  BarkerCoreModelsDTIGetEventsResponse,
  BarkerCoreModelsDTIGetHeadlinersResponse,
  BarkerCoreModelsDTIGetInvoicesRequest,
  BarkerCoreModelsDTIGetInvoicesResponse,
  BarkerCoreModelsDTIGetItemHistoryResponse,
  BarkerCoreModelsDTIGetItemsResponse,
  BarkerCoreModelsDTIGetSeatGeekMarketplaceDataResponse,
  BarkerCoreModelsDTIGetVenuesResponse,
  BarkerCoreModelsDTIListingIssueRequest,
  BarkerCoreModelsDTIReportMissingEventRequest,
  BarkerCoreModelsDTISellPrivatelyRequest,
  GetDtiEventsParams,
  GetDtiItemsAccountIdParams,
  PostDtiInvoiceAccountIdUploadProofBody,
  PostDtiPdfsAccountIdAttachBody,
} from './models';

export const getDtiListingsAccountIdEventId = (
  accountId: number,
  eventId: number,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsDTIGetEventListingsResponse>> => {
  return axios.get(`/dti/listings/${accountId}/${eventId}`, options);
};

export const getGetDtiListingsAccountIdEventIdQueryKey = (accountId: number, eventId: number) => {
  return [`/dti/listings/${accountId}/${eventId}`] as const;
};

export const getGetDtiListingsAccountIdEventIdQueryOptions = <TData = Awaited<ReturnType<typeof getDtiListingsAccountIdEventId>>, TError = AxiosError<void>>(
  accountId: number,
  eventId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiListingsAccountIdEventId>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDtiListingsAccountIdEventIdQueryKey(accountId, eventId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDtiListingsAccountIdEventId>>> = ({ signal }) =>
    getDtiListingsAccountIdEventId(accountId, eventId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!(accountId && eventId), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getDtiListingsAccountIdEventId>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetDtiListingsAccountIdEventIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDtiListingsAccountIdEventId>>>;
export type GetDtiListingsAccountIdEventIdQueryError = AxiosError<void>;

export const useGetDtiListingsAccountIdEventId = <TData = Awaited<ReturnType<typeof getDtiListingsAccountIdEventId>>, TError = AxiosError<void>>(
  accountId: number,
  eventId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiListingsAccountIdEventId>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDtiListingsAccountIdEventIdQueryOptions(accountId, eventId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postDtiItemsAccountIdSellPrivately = (
  accountId: number,
  barkerCoreModelsDTISellPrivatelyRequest: BarkerCoreModelsDTISellPrivatelyRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsDTIBaseResponse>> => {
  return axios.post(`/dti/items/${accountId}/sellPrivately`, barkerCoreModelsDTISellPrivatelyRequest, options);
};

export const getPostDtiItemsAccountIdSellPrivatelyMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDtiItemsAccountIdSellPrivately>>,
    TError,
    { accountId: number; data: BarkerCoreModelsDTISellPrivatelyRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postDtiItemsAccountIdSellPrivately>>, TError, { accountId: number; data: BarkerCoreModelsDTISellPrivatelyRequest }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDtiItemsAccountIdSellPrivately>>, { accountId: number; data: BarkerCoreModelsDTISellPrivatelyRequest }> = (
    props,
  ) => {
    const { accountId, data } = props ?? {};

    return postDtiItemsAccountIdSellPrivately(accountId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostDtiItemsAccountIdSellPrivatelyMutationResult = NonNullable<Awaited<ReturnType<typeof postDtiItemsAccountIdSellPrivately>>>;
export type PostDtiItemsAccountIdSellPrivatelyMutationBody = BarkerCoreModelsDTISellPrivatelyRequest;
export type PostDtiItemsAccountIdSellPrivatelyMutationError = AxiosError<void>;

export const usePostDtiItemsAccountIdSellPrivately = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDtiItemsAccountIdSellPrivately>>,
    TError,
    { accountId: number; data: BarkerCoreModelsDTISellPrivatelyRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postDtiItemsAccountIdSellPrivately>>, TError, { accountId: number; data: BarkerCoreModelsDTISellPrivatelyRequest }, TContext> => {
  const mutationOptions = getPostDtiItemsAccountIdSellPrivatelyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getDtiItemsAccountId = (
  accountId: number,
  params?: GetDtiItemsAccountIdParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsDTIGetItemsResponse>> => {
  return axios.get(`/dti/items/${accountId}`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetDtiItemsAccountIdQueryKey = (accountId: number, params?: GetDtiItemsAccountIdParams) => {
  return [`/dti/items/${accountId}`, ...(params ? [params] : [])] as const;
};

export const getGetDtiItemsAccountIdQueryOptions = <TData = Awaited<ReturnType<typeof getDtiItemsAccountId>>, TError = AxiosError<void>>(
  accountId: number,
  params?: GetDtiItemsAccountIdParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiItemsAccountId>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDtiItemsAccountIdQueryKey(accountId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDtiItemsAccountId>>> = ({ signal }) => getDtiItemsAccountId(accountId, params, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!accountId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getDtiItemsAccountId>>, TError, TData> & { queryKey: QueryKey };
};

export type GetDtiItemsAccountIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDtiItemsAccountId>>>;
export type GetDtiItemsAccountIdQueryError = AxiosError<void>;

export const useGetDtiItemsAccountId = <TData = Awaited<ReturnType<typeof getDtiItemsAccountId>>, TError = AxiosError<void>>(
  accountId: number,
  params?: GetDtiItemsAccountIdParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiItemsAccountId>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDtiItemsAccountIdQueryOptions(accountId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putDtiListingsAccountId = (
  accountId: number,
  barkerCoreModelsDTIBulkUpdateListingRequest: BarkerCoreModelsDTIBulkUpdateListingRequest[],
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsDTIBulkListingsUpdateResponse>> => {
  return axios.put(`/dti/listings/${accountId}`, barkerCoreModelsDTIBulkUpdateListingRequest, options);
};

export const getPutDtiListingsAccountIdMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putDtiListingsAccountId>>, TError, { accountId: number; data: BarkerCoreModelsDTIBulkUpdateListingRequest[] }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putDtiListingsAccountId>>, TError, { accountId: number; data: BarkerCoreModelsDTIBulkUpdateListingRequest[] }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDtiListingsAccountId>>, { accountId: number; data: BarkerCoreModelsDTIBulkUpdateListingRequest[] }> = (props) => {
    const { accountId, data } = props ?? {};

    return putDtiListingsAccountId(accountId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutDtiListingsAccountIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDtiListingsAccountId>>>;
export type PutDtiListingsAccountIdMutationBody = BarkerCoreModelsDTIBulkUpdateListingRequest[];
export type PutDtiListingsAccountIdMutationError = AxiosError<void>;

export const usePutDtiListingsAccountId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putDtiListingsAccountId>>, TError, { accountId: number; data: BarkerCoreModelsDTIBulkUpdateListingRequest[] }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putDtiListingsAccountId>>, TError, { accountId: number; data: BarkerCoreModelsDTIBulkUpdateListingRequest[] }, TContext> => {
  const mutationOptions = getPutDtiListingsAccountIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postDtiListingsAccountId = (
  accountId: number,
  barkerCoreModelsDTIBulkPurchaseRequest: BarkerCoreModelsDTIBulkPurchaseRequest[],
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsDTIBulkResponseWithErrors>> => {
  return axios.post(`/dti/listings/${accountId}`, barkerCoreModelsDTIBulkPurchaseRequest, options);
};

export const getPostDtiListingsAccountIdMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postDtiListingsAccountId>>, TError, { accountId: number; data: BarkerCoreModelsDTIBulkPurchaseRequest[] }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postDtiListingsAccountId>>, TError, { accountId: number; data: BarkerCoreModelsDTIBulkPurchaseRequest[] }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDtiListingsAccountId>>, { accountId: number; data: BarkerCoreModelsDTIBulkPurchaseRequest[] }> = (props) => {
    const { accountId, data } = props ?? {};

    return postDtiListingsAccountId(accountId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostDtiListingsAccountIdMutationResult = NonNullable<Awaited<ReturnType<typeof postDtiListingsAccountId>>>;
export type PostDtiListingsAccountIdMutationBody = BarkerCoreModelsDTIBulkPurchaseRequest[];
export type PostDtiListingsAccountIdMutationError = AxiosError<void>;

export const usePostDtiListingsAccountId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postDtiListingsAccountId>>, TError, { accountId: number; data: BarkerCoreModelsDTIBulkPurchaseRequest[] }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postDtiListingsAccountId>>, TError, { accountId: number; data: BarkerCoreModelsDTIBulkPurchaseRequest[] }, TContext> => {
  const mutationOptions = getPostDtiListingsAccountIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putDtiListingsAccountIdEditState = (
  accountId: number,
  barkerCoreModelsDTIBulkEditStateRequest: BarkerCoreModelsDTIBulkEditStateRequest[],
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsDTIBulkListingsUpdateResponse>> => {
  return axios.put(`/dti/listings/${accountId}/edit/state`, barkerCoreModelsDTIBulkEditStateRequest, options);
};

export const getPutDtiListingsAccountIdEditStateMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putDtiListingsAccountIdEditState>>,
    TError,
    { accountId: number; data: BarkerCoreModelsDTIBulkEditStateRequest[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putDtiListingsAccountIdEditState>>, TError, { accountId: number; data: BarkerCoreModelsDTIBulkEditStateRequest[] }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDtiListingsAccountIdEditState>>, { accountId: number; data: BarkerCoreModelsDTIBulkEditStateRequest[] }> = (
    props,
  ) => {
    const { accountId, data } = props ?? {};

    return putDtiListingsAccountIdEditState(accountId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutDtiListingsAccountIdEditStateMutationResult = NonNullable<Awaited<ReturnType<typeof putDtiListingsAccountIdEditState>>>;
export type PutDtiListingsAccountIdEditStateMutationBody = BarkerCoreModelsDTIBulkEditStateRequest[];
export type PutDtiListingsAccountIdEditStateMutationError = AxiosError<void>;

export const usePutDtiListingsAccountIdEditState = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putDtiListingsAccountIdEditState>>,
    TError,
    { accountId: number; data: BarkerCoreModelsDTIBulkEditStateRequest[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putDtiListingsAccountIdEditState>>, TError, { accountId: number; data: BarkerCoreModelsDTIBulkEditStateRequest[] }, TContext> => {
  const mutationOptions = getPutDtiListingsAccountIdEditStateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putDtiListingsAccountIdEditListing = (
  accountId: number,
  barkerCoreModelsDTIBulkEditListingRequest: BarkerCoreModelsDTIBulkEditListingRequest[],
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsDTIBulkListingsUpdateResponse>> => {
  return axios.put(`/dti/listings/${accountId}/edit/listing`, barkerCoreModelsDTIBulkEditListingRequest, options);
};

export const getPutDtiListingsAccountIdEditListingMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putDtiListingsAccountIdEditListing>>,
    TError,
    { accountId: number; data: BarkerCoreModelsDTIBulkEditListingRequest[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putDtiListingsAccountIdEditListing>>,
  TError,
  { accountId: number; data: BarkerCoreModelsDTIBulkEditListingRequest[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDtiListingsAccountIdEditListing>>, { accountId: number; data: BarkerCoreModelsDTIBulkEditListingRequest[] }> = (
    props,
  ) => {
    const { accountId, data } = props ?? {};

    return putDtiListingsAccountIdEditListing(accountId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutDtiListingsAccountIdEditListingMutationResult = NonNullable<Awaited<ReturnType<typeof putDtiListingsAccountIdEditListing>>>;
export type PutDtiListingsAccountIdEditListingMutationBody = BarkerCoreModelsDTIBulkEditListingRequest[];
export type PutDtiListingsAccountIdEditListingMutationError = AxiosError<void>;

export const usePutDtiListingsAccountIdEditListing = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putDtiListingsAccountIdEditListing>>,
    TError,
    { accountId: number; data: BarkerCoreModelsDTIBulkEditListingRequest[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putDtiListingsAccountIdEditListing>>,
  TError,
  { accountId: number; data: BarkerCoreModelsDTIBulkEditListingRequest[] },
  TContext
> => {
  const mutationOptions = getPutDtiListingsAccountIdEditListingMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postDtiEventsReportMissing = (
  barkerCoreModelsDTIReportMissingEventRequest: BarkerCoreModelsDTIReportMissingEventRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsDTIBaseResponseWithMessage>> => {
  return axios.post(`/dti/events/report_missing`, barkerCoreModelsDTIReportMissingEventRequest, options);
};

export const getPostDtiEventsReportMissingMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postDtiEventsReportMissing>>, TError, { data: BarkerCoreModelsDTIReportMissingEventRequest }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postDtiEventsReportMissing>>, TError, { data: BarkerCoreModelsDTIReportMissingEventRequest }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDtiEventsReportMissing>>, { data: BarkerCoreModelsDTIReportMissingEventRequest }> = (props) => {
    const { data } = props ?? {};

    return postDtiEventsReportMissing(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostDtiEventsReportMissingMutationResult = NonNullable<Awaited<ReturnType<typeof postDtiEventsReportMissing>>>;
export type PostDtiEventsReportMissingMutationBody = BarkerCoreModelsDTIReportMissingEventRequest;
export type PostDtiEventsReportMissingMutationError = AxiosError<void>;

export const usePostDtiEventsReportMissing = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postDtiEventsReportMissing>>, TError, { data: BarkerCoreModelsDTIReportMissingEventRequest }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postDtiEventsReportMissing>>, TError, { data: BarkerCoreModelsDTIReportMissingEventRequest }, TContext> => {
  const mutationOptions = getPostDtiEventsReportMissingMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getDtiHeadliners = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsDTIGetHeadlinersResponse>> => {
  return axios.get(`/dti/headliners`, options);
};

export const getGetDtiHeadlinersQueryKey = () => {
  return [`/dti/headliners`] as const;
};

export const getGetDtiHeadlinersQueryOptions = <TData = Awaited<ReturnType<typeof getDtiHeadliners>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiHeadliners>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDtiHeadlinersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDtiHeadliners>>> = ({ signal }) => getDtiHeadliners({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getDtiHeadliners>>, TError, TData> & { queryKey: QueryKey };
};

export type GetDtiHeadlinersQueryResult = NonNullable<Awaited<ReturnType<typeof getDtiHeadliners>>>;
export type GetDtiHeadlinersQueryError = AxiosError<void>;

export const useGetDtiHeadliners = <TData = Awaited<ReturnType<typeof getDtiHeadliners>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiHeadliners>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDtiHeadlinersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDtiVenues = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsDTIGetVenuesResponse>> => {
  return axios.get(`/dti/venues`, options);
};

export const getGetDtiVenuesQueryKey = () => {
  return [`/dti/venues`] as const;
};

export const getGetDtiVenuesQueryOptions = <TData = Awaited<ReturnType<typeof getDtiVenues>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiVenues>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDtiVenuesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDtiVenues>>> = ({ signal }) => getDtiVenues({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getDtiVenues>>, TError, TData> & { queryKey: QueryKey };
};

export type GetDtiVenuesQueryResult = NonNullable<Awaited<ReturnType<typeof getDtiVenues>>>;
export type GetDtiVenuesQueryError = AxiosError<void>;

export const useGetDtiVenues = <TData = Awaited<ReturnType<typeof getDtiVenues>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiVenues>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDtiVenuesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDtiCategories = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsDTIGetCategoriesResponse>> => {
  return axios.get(`/dti/categories`, options);
};

export const getGetDtiCategoriesQueryKey = () => {
  return [`/dti/categories`] as const;
};

export const getGetDtiCategoriesQueryOptions = <TData = Awaited<ReturnType<typeof getDtiCategories>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiCategories>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDtiCategoriesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDtiCategories>>> = ({ signal }) => getDtiCategories({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getDtiCategories>>, TError, TData> & { queryKey: QueryKey };
};

export type GetDtiCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getDtiCategories>>>;
export type GetDtiCategoriesQueryError = AxiosError<void>;

export const useGetDtiCategories = <TData = Awaited<ReturnType<typeof getDtiCategories>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiCategories>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDtiCategoriesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDtiEvents = (params: GetDtiEventsParams, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsDTIGetEventsResponse>> => {
  return axios.get(`/dti/events`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetDtiEventsQueryKey = (params: GetDtiEventsParams) => {
  return [`/dti/events`, ...(params ? [params] : [])] as const;
};

export const getGetDtiEventsQueryOptions = <TData = Awaited<ReturnType<typeof getDtiEvents>>, TError = AxiosError<void>>(
  params: GetDtiEventsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiEvents>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDtiEventsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDtiEvents>>> = ({ signal }) => getDtiEvents(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getDtiEvents>>, TError, TData> & { queryKey: QueryKey };
};

export type GetDtiEventsQueryResult = NonNullable<Awaited<ReturnType<typeof getDtiEvents>>>;
export type GetDtiEventsQueryError = AxiosError<void>;

export const useGetDtiEvents = <TData = Awaited<ReturnType<typeof getDtiEvents>>, TError = AxiosError<void>>(
  params: GetDtiEventsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiEvents>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDtiEventsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDtiEventsEventIdMarketplaceSeatgeek = (
  eventId: number,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsDTIGetSeatGeekMarketplaceDataResponse>> => {
  return axios.get(`/dti/events/${eventId}/marketplace/seatgeek`, options);
};

export const getGetDtiEventsEventIdMarketplaceSeatgeekQueryKey = (eventId: number) => {
  return [`/dti/events/${eventId}/marketplace/seatgeek`] as const;
};

export const getGetDtiEventsEventIdMarketplaceSeatgeekQueryOptions = <TData = Awaited<ReturnType<typeof getDtiEventsEventIdMarketplaceSeatgeek>>, TError = AxiosError<void>>(
  eventId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiEventsEventIdMarketplaceSeatgeek>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDtiEventsEventIdMarketplaceSeatgeekQueryKey(eventId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDtiEventsEventIdMarketplaceSeatgeek>>> = ({ signal }) =>
    getDtiEventsEventIdMarketplaceSeatgeek(eventId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!eventId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getDtiEventsEventIdMarketplaceSeatgeek>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetDtiEventsEventIdMarketplaceSeatgeekQueryResult = NonNullable<Awaited<ReturnType<typeof getDtiEventsEventIdMarketplaceSeatgeek>>>;
export type GetDtiEventsEventIdMarketplaceSeatgeekQueryError = AxiosError<void>;

export const useGetDtiEventsEventIdMarketplaceSeatgeek = <TData = Awaited<ReturnType<typeof getDtiEventsEventIdMarketplaceSeatgeek>>, TError = AxiosError<void>>(
  eventId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiEventsEventIdMarketplaceSeatgeek>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDtiEventsEventIdMarketplaceSeatgeekQueryOptions(eventId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postDtiListingsAccountIdDelete = (
  accountId: number,
  barkerCoreModelsDTIDeleteListingRequest: BarkerCoreModelsDTIDeleteListingRequest[],
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsDTIBaseResponseWithMessage>> => {
  return axios.post(`/dti/listings/${accountId}/delete`, barkerCoreModelsDTIDeleteListingRequest, options);
};

export const getPostDtiListingsAccountIdDeleteMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDtiListingsAccountIdDelete>>,
    TError,
    { accountId: number; data: BarkerCoreModelsDTIDeleteListingRequest[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postDtiListingsAccountIdDelete>>, TError, { accountId: number; data: BarkerCoreModelsDTIDeleteListingRequest[] }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDtiListingsAccountIdDelete>>, { accountId: number; data: BarkerCoreModelsDTIDeleteListingRequest[] }> = (
    props,
  ) => {
    const { accountId, data } = props ?? {};

    return postDtiListingsAccountIdDelete(accountId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostDtiListingsAccountIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof postDtiListingsAccountIdDelete>>>;
export type PostDtiListingsAccountIdDeleteMutationBody = BarkerCoreModelsDTIDeleteListingRequest[];
export type PostDtiListingsAccountIdDeleteMutationError = AxiosError<void>;

export const usePostDtiListingsAccountIdDelete = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDtiListingsAccountIdDelete>>,
    TError,
    { accountId: number; data: BarkerCoreModelsDTIDeleteListingRequest[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postDtiListingsAccountIdDelete>>, TError, { accountId: number; data: BarkerCoreModelsDTIDeleteListingRequest[] }, TContext> => {
  const mutationOptions = getPostDtiListingsAccountIdDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getDtiItemsAccountIdItemIdHistory = (
  accountId: number,
  itemId: number,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsDTIGetItemHistoryResponse>> => {
  return axios.get(`/dti/items/${accountId}/${itemId}/history`, options);
};

export const getGetDtiItemsAccountIdItemIdHistoryQueryKey = (accountId: number, itemId: number) => {
  return [`/dti/items/${accountId}/${itemId}/history`] as const;
};

export const getGetDtiItemsAccountIdItemIdHistoryQueryOptions = <TData = Awaited<ReturnType<typeof getDtiItemsAccountIdItemIdHistory>>, TError = AxiosError<void>>(
  accountId: number,
  itemId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiItemsAccountIdItemIdHistory>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDtiItemsAccountIdItemIdHistoryQueryKey(accountId, itemId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDtiItemsAccountIdItemIdHistory>>> = ({ signal }) =>
    getDtiItemsAccountIdItemIdHistory(accountId, itemId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!(accountId && itemId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDtiItemsAccountIdItemIdHistory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDtiItemsAccountIdItemIdHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof getDtiItemsAccountIdItemIdHistory>>>;
export type GetDtiItemsAccountIdItemIdHistoryQueryError = AxiosError<void>;

export const useGetDtiItemsAccountIdItemIdHistory = <TData = Awaited<ReturnType<typeof getDtiItemsAccountIdItemIdHistory>>, TError = AxiosError<void>>(
  accountId: number,
  itemId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiItemsAccountIdItemIdHistory>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDtiItemsAccountIdItemIdHistoryQueryOptions(accountId, itemId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postDtiListingsAccountIdIssue = (
  accountId: number,
  barkerCoreModelsDTIListingIssueRequest: BarkerCoreModelsDTIListingIssueRequest[],
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsDTIBaseResponseWithMessage>> => {
  return axios.post(`/dti/listings/${accountId}/issue`, barkerCoreModelsDTIListingIssueRequest, options);
};

export const getPostDtiListingsAccountIdIssueMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postDtiListingsAccountIdIssue>>, TError, { accountId: number; data: BarkerCoreModelsDTIListingIssueRequest[] }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postDtiListingsAccountIdIssue>>, TError, { accountId: number; data: BarkerCoreModelsDTIListingIssueRequest[] }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDtiListingsAccountIdIssue>>, { accountId: number; data: BarkerCoreModelsDTIListingIssueRequest[] }> = (
    props,
  ) => {
    const { accountId, data } = props ?? {};

    return postDtiListingsAccountIdIssue(accountId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostDtiListingsAccountIdIssueMutationResult = NonNullable<Awaited<ReturnType<typeof postDtiListingsAccountIdIssue>>>;
export type PostDtiListingsAccountIdIssueMutationBody = BarkerCoreModelsDTIListingIssueRequest[];
export type PostDtiListingsAccountIdIssueMutationError = AxiosError<void>;

export const usePostDtiListingsAccountIdIssue = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postDtiListingsAccountIdIssue>>, TError, { accountId: number; data: BarkerCoreModelsDTIListingIssueRequest[] }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postDtiListingsAccountIdIssue>>, TError, { accountId: number; data: BarkerCoreModelsDTIListingIssueRequest[] }, TContext> => {
  const mutationOptions = getPostDtiListingsAccountIdIssueMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getDtiAccounts = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsDTIGetAccountsResponse>> => {
  return axios.get(`/dti/accounts`, options);
};

export const getGetDtiAccountsQueryKey = () => {
  return [`/dti/accounts`] as const;
};

export const getGetDtiAccountsQueryOptions = <TData = Awaited<ReturnType<typeof getDtiAccounts>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiAccounts>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDtiAccountsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDtiAccounts>>> = ({ signal }) => getDtiAccounts({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getDtiAccounts>>, TError, TData> & { queryKey: QueryKey };
};

export type GetDtiAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof getDtiAccounts>>>;
export type GetDtiAccountsQueryError = AxiosError<void>;

export const useGetDtiAccounts = <TData = Awaited<ReturnType<typeof getDtiAccounts>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getDtiAccounts>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDtiAccountsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postDtiEventsEventIdIssue = (
  eventId: number,
  barkerCoreModelsDTIEventIssueRequest: BarkerCoreModelsDTIEventIssueRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsDTIBaseResponseWithMessage>> => {
  return axios.post(`/dti/events/${eventId}/issue`, barkerCoreModelsDTIEventIssueRequest, options);
};

export const getPostDtiEventsEventIdIssueMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postDtiEventsEventIdIssue>>, TError, { eventId: number; data: BarkerCoreModelsDTIEventIssueRequest }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postDtiEventsEventIdIssue>>, TError, { eventId: number; data: BarkerCoreModelsDTIEventIssueRequest }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDtiEventsEventIdIssue>>, { eventId: number; data: BarkerCoreModelsDTIEventIssueRequest }> = (props) => {
    const { eventId, data } = props ?? {};

    return postDtiEventsEventIdIssue(eventId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostDtiEventsEventIdIssueMutationResult = NonNullable<Awaited<ReturnType<typeof postDtiEventsEventIdIssue>>>;
export type PostDtiEventsEventIdIssueMutationBody = BarkerCoreModelsDTIEventIssueRequest;
export type PostDtiEventsEventIdIssueMutationError = AxiosError<void>;

export const usePostDtiEventsEventIdIssue = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postDtiEventsEventIdIssue>>, TError, { eventId: number; data: BarkerCoreModelsDTIEventIssueRequest }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postDtiEventsEventIdIssue>>, TError, { eventId: number; data: BarkerCoreModelsDTIEventIssueRequest }, TContext> => {
  const mutationOptions = getPostDtiEventsEventIdIssueMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postDtiInvoiceAccountId = (
  accountId: number,
  barkerCoreModelsDTIGetInvoicesRequest: BarkerCoreModelsDTIGetInvoicesRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsDTIGetInvoicesResponse>> => {
  return axios.post(`/dti/invoice/${accountId}`, barkerCoreModelsDTIGetInvoicesRequest, options);
};

export const getPostDtiInvoiceAccountIdMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postDtiInvoiceAccountId>>, TError, { accountId: number; data: BarkerCoreModelsDTIGetInvoicesRequest }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postDtiInvoiceAccountId>>, TError, { accountId: number; data: BarkerCoreModelsDTIGetInvoicesRequest }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDtiInvoiceAccountId>>, { accountId: number; data: BarkerCoreModelsDTIGetInvoicesRequest }> = (props) => {
    const { accountId, data } = props ?? {};

    return postDtiInvoiceAccountId(accountId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostDtiInvoiceAccountIdMutationResult = NonNullable<Awaited<ReturnType<typeof postDtiInvoiceAccountId>>>;
export type PostDtiInvoiceAccountIdMutationBody = BarkerCoreModelsDTIGetInvoicesRequest;
export type PostDtiInvoiceAccountIdMutationError = AxiosError<void>;

export const usePostDtiInvoiceAccountId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postDtiInvoiceAccountId>>, TError, { accountId: number; data: BarkerCoreModelsDTIGetInvoicesRequest }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postDtiInvoiceAccountId>>, TError, { accountId: number; data: BarkerCoreModelsDTIGetInvoicesRequest }, TContext> => {
  const mutationOptions = getPostDtiInvoiceAccountIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postDtiInvoiceAccountIdAirbillDownload = (
  accountId: number,
  barkerCoreModelsDTIDownloadAirbillRequest: BarkerCoreModelsDTIDownloadAirbillRequest[],
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsDTIBaseResponseWithMessage>> => {
  return axios.post(`/dti/invoice/${accountId}/airbill/download`, barkerCoreModelsDTIDownloadAirbillRequest, options);
};

export const getPostDtiInvoiceAccountIdAirbillDownloadMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDtiInvoiceAccountIdAirbillDownload>>,
    TError,
    { accountId: number; data: BarkerCoreModelsDTIDownloadAirbillRequest[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postDtiInvoiceAccountIdAirbillDownload>>,
  TError,
  { accountId: number; data: BarkerCoreModelsDTIDownloadAirbillRequest[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postDtiInvoiceAccountIdAirbillDownload>>,
    { accountId: number; data: BarkerCoreModelsDTIDownloadAirbillRequest[] }
  > = (props) => {
    const { accountId, data } = props ?? {};

    return postDtiInvoiceAccountIdAirbillDownload(accountId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostDtiInvoiceAccountIdAirbillDownloadMutationResult = NonNullable<Awaited<ReturnType<typeof postDtiInvoiceAccountIdAirbillDownload>>>;
export type PostDtiInvoiceAccountIdAirbillDownloadMutationBody = BarkerCoreModelsDTIDownloadAirbillRequest[];
export type PostDtiInvoiceAccountIdAirbillDownloadMutationError = AxiosError<void>;

export const usePostDtiInvoiceAccountIdAirbillDownload = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDtiInvoiceAccountIdAirbillDownload>>,
    TError,
    { accountId: number; data: BarkerCoreModelsDTIDownloadAirbillRequest[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postDtiInvoiceAccountIdAirbillDownload>>,
  TError,
  { accountId: number; data: BarkerCoreModelsDTIDownloadAirbillRequest[] },
  TContext
> => {
  const mutationOptions = getPostDtiInvoiceAccountIdAirbillDownloadMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postDtiPdfsAccountIdAttach = (
  accountId: number,
  postDtiPdfsAccountIdAttachBody: PostDtiPdfsAccountIdAttachBody,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsDTIBaseResponseWithMessage>> => {
  const formData = new FormData();
  formData.append('metadata', postDtiPdfsAccountIdAttachBody.metadata);
  formData.append('file', postDtiPdfsAccountIdAttachBody.file);

  return axios.post(`/dti/pdfs/${accountId}/attach`, formData, options);
};

export const getPostDtiPdfsAccountIdAttachMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postDtiPdfsAccountIdAttach>>, TError, { accountId: number; data: PostDtiPdfsAccountIdAttachBody }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postDtiPdfsAccountIdAttach>>, TError, { accountId: number; data: PostDtiPdfsAccountIdAttachBody }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDtiPdfsAccountIdAttach>>, { accountId: number; data: PostDtiPdfsAccountIdAttachBody }> = (props) => {
    const { accountId, data } = props ?? {};

    return postDtiPdfsAccountIdAttach(accountId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostDtiPdfsAccountIdAttachMutationResult = NonNullable<Awaited<ReturnType<typeof postDtiPdfsAccountIdAttach>>>;
export type PostDtiPdfsAccountIdAttachMutationBody = PostDtiPdfsAccountIdAttachBody;
export type PostDtiPdfsAccountIdAttachMutationError = AxiosError<void>;

export const usePostDtiPdfsAccountIdAttach = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postDtiPdfsAccountIdAttach>>, TError, { accountId: number; data: PostDtiPdfsAccountIdAttachBody }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postDtiPdfsAccountIdAttach>>, TError, { accountId: number; data: PostDtiPdfsAccountIdAttachBody }, TContext> => {
  const mutationOptions = getPostDtiPdfsAccountIdAttachMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postDtiInvoiceAccountIdUploadProof = (
  accountId: number,
  postDtiInvoiceAccountIdUploadProofBody: PostDtiInvoiceAccountIdUploadProofBody,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsDTIBaseResponseWithMessage>> => {
  const formData = new FormData();
  formData.append('invoice_id', postDtiInvoiceAccountIdUploadProofBody.invoice_id);
  formData.append('vendor_id', postDtiInvoiceAccountIdUploadProofBody.vendor_id);
  formData.append('file', postDtiInvoiceAccountIdUploadProofBody.file);

  return axios.post(`/dti/invoice/${accountId}/upload-proof`, formData, options);
};

export const getPostDtiInvoiceAccountIdUploadProofMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDtiInvoiceAccountIdUploadProof>>,
    TError,
    { accountId: number; data: PostDtiInvoiceAccountIdUploadProofBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postDtiInvoiceAccountIdUploadProof>>, TError, { accountId: number; data: PostDtiInvoiceAccountIdUploadProofBody }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDtiInvoiceAccountIdUploadProof>>, { accountId: number; data: PostDtiInvoiceAccountIdUploadProofBody }> = (
    props,
  ) => {
    const { accountId, data } = props ?? {};

    return postDtiInvoiceAccountIdUploadProof(accountId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostDtiInvoiceAccountIdUploadProofMutationResult = NonNullable<Awaited<ReturnType<typeof postDtiInvoiceAccountIdUploadProof>>>;
export type PostDtiInvoiceAccountIdUploadProofMutationBody = PostDtiInvoiceAccountIdUploadProofBody;
export type PostDtiInvoiceAccountIdUploadProofMutationError = AxiosError<void>;

export const usePostDtiInvoiceAccountIdUploadProof = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDtiInvoiceAccountIdUploadProof>>,
    TError,
    { accountId: number; data: PostDtiInvoiceAccountIdUploadProofBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postDtiInvoiceAccountIdUploadProof>>, TError, { accountId: number; data: PostDtiInvoiceAccountIdUploadProofBody }, TContext> => {
  const mutationOptions = getPostDtiInvoiceAccountIdUploadProofMutationOptions(options);

  return useMutation(mutationOptions);
};
