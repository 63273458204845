import { IconPropsBase } from './IconPropsBase';

export default function AccessibleIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M12.05 6.125q-.75 0-1.3-.537-.55-.538-.55-1.288 0-.775.55-1.313.55-.537 1.3-.537t1.3.537q.55.538.55 1.313 0 .75-.55 1.288-.55.537-1.3.537Zm5.125 15.65V17q0-.125-.075-.188-.075-.062-.175-.062h-4.85q-.725 0-1.25-.525t-.525-1.25V9q0-.725.512-1.238.513-.512 1.238-.512.475 0 .863.225.387.225.887.8 1.375 1.65 2.45 2.25 1.075.6 2.475.7v1.5q-1.4-.075-2.663-.688-1.262-.612-2.337-1.637v4.425H16.9q.725 0 1.25.525t.525 1.25v5.175Zm-7.125 0q-1.975 0-3.375-1.4T5.275 17q0-1.775 1.138-3.088Q7.55 12.6 9.3 12.3v1.525q-1.1.275-1.812 1.137-.713.863-.713 2.038 0 1.375.95 2.325.95.95 2.325.95 1.175 0 2.038-.725.862-.725 1.137-1.8h1.525q-.275 1.725-1.6 2.875-1.325 1.15-3.1 1.15Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M10.542 5.604q-.667 0-1.146-.479-.479-.479-.479-1.146 0-.667.479-1.135.479-.469 1.146-.469.666 0 1.146.479.479.479.479 1.146 0 .667-.479 1.135-.48.469-1.146.469Zm4.104 11.958V13.75q0-.104-.063-.156-.062-.052-.145-.052h-3.876q-.541 0-.927-.365-.385-.365-.385-.948V7.75q0-.521.365-.906.364-.386.885-.386.375 0 .625.167t.5.5q.792 1.063 1.802 1.656 1.011.594 2.344.657v1.083q-1.146-.063-2.042-.427-.896-.365-1.958-1.282v3.459h2.417q.645 0 1.093.458.448.459.448 1.104v3.729Zm-6.604 0q-1.584 0-2.698-1.124-1.115-1.126-1.115-2.688 0-1.646 1.167-2.76Q6.562 9.875 8.25 9.917v1.062q-1.229-.062-2.083.75-.855.813-.855 2.021 0 1.146.792 1.938.792.791 1.938.791.896 0 1.604-.552.708-.552.958-1.385h1.125q-.291 1.333-1.312 2.177-1.021.843-2.375.843Z"
        />
      </svg>
    );
  }
}
