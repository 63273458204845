import { IconPropsBase } from './IconPropsBase';

export default function AddCommentIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="M450.001-410.001h59.998v-120h120v-59.998h-120v-120h-59.998v120h-120v59.998h120v120Zm-350 291.537v-669.227q0-30.308 21-51.308t51.308-21h615.382q30.308 0 51.308 21t21 51.308v455.382q0 30.308-21 51.308t-51.308 21H241.539L100.001-118.464Zm116-201.536h571.69q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-455.382q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H172.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v523.076L216.001-320ZM160-320v-480V-320Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="M454.001-394.001h51.998v-132h132v-51.998h-132v-132h-51.998v132h-132v51.998h132v132Zm-338 259.537v-645.227q0-27.008 18.65-45.658 18.65-18.65 45.658-18.65h599.382q27.008 0 45.658 18.65 18.65 18.65 18.65 45.658v455.382q0 27.008-18.65 45.658-18.65 18.65-45.658 18.65H241.539L116.001-134.464Zm104-177.536h559.69q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-455.382q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H180.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v520.076L220.001-312ZM168-312v-480V-312Z" />
      </svg>
    );
  }
}
