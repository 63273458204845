import { IconPropsBase } from './IconPropsBase';

export default function StickyNoteIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M5.3 19H14v-5h5V5.3q0-.125-.087-.212Q18.825 5 18.7 5H5.3q-.125 0-.212.088Q5 5.175 5 5.3v13.4q0 .125.088.213.087.087.212.087Zm0 1.5q-.75 0-1.275-.525Q3.5 19.45 3.5 18.7V5.3q0-.75.525-1.275Q4.55 3.5 5.3 3.5h13.4q.75 0 1.275.525.525.525.525 1.275v9.35l-5.85 5.85Zm2.15-6.775v-1.5H12v1.5Zm0-3.975v-1.5h9.1v1.5ZM5 19V5v14Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M4.75 15.5h6.75v-4h4V4.75q0-.104-.073-.177T15.25 4.5H4.75q-.104 0-.177.073T4.5 4.75v10.5q0 .104.073.177t.177.073Zm0 1.083q-.542 0-.938-.395-.395-.396-.395-.938V4.75q0-.542.395-.937.396-.396.938-.396h10.5q.542 0 .938.396.395.395.395.937v6.958l-4.875 4.875Zm1.625-5.312v-1.083H10v1.083Zm0-2.979V7.208h7.25v1.084ZM4.5 15.5v-11 11Z"
        />
      </svg>
    );
  }
}
