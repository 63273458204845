import { useState, useRef, useCallback, useEffect } from 'react';

/**
 * Delays value update by specified amount of time
 * @param value Value to delay
 * @param delay Delay in ms
 * @param defaultValue Default value to use before delay
 * @param options.instantDefaultValues Set to false to prevent to prevent values that match defaultValue from being set instantly avoiding delay timer.
 */
export function useDelayedValue<T = unknown>(value: T, delay: number, defaultValue?: T, options: { instantDefaultValues: boolean } = { instantDefaultValues: true }) {
  const [_value, setValue] = useState<T | undefined>(defaultValue);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();
  const cancel = useCallback(() => clearTimeout(timeoutRef.current), []);
  useEffect(() => {
    cancel();
    if (options.instantDefaultValues && value === defaultValue) {
      setValue(value);
      return;
    }
    timeoutRef.current = setTimeout(() => {
      setValue(value);
    }, delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, delay]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => cancel(), []);

  return [_value, cancel] as const;
}
