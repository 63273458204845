import { Button, MantineSize } from '@mantine/core';
import { memo, useCallback } from 'react';
import { BNButton } from '../../../components/Button/Button';
import classes from './Filters.SplitsButtonGroup.styles.tsx.module.css';

export const SplitsButtonGroup = ({
  splits = [],
  onChange,
  disabled,
  size,
}: {
  splits: number[] | null | undefined;
  onChange: (splits: number[]) => void;
  disabled?: boolean;
  size?: MantineSize | undefined;
}) => {
  const onSplitButtonClick = useCallback(
    (value: number) => {
      if (splits?.includes(value)) {
        onChange(splits.filter((split) => split !== value));
      } else {
        onChange([...(splits || []), value]);
      }
    },
    [onChange, splits],
  );

  interface SplitsButtonProps {
    label: string;
    value: number;
    active: boolean;
    onClick: (value: number) => void;
    isDisabled?: boolean;
    bsize?: MantineSize | undefined;
  }
  const SplitsButton = memo(({ label, value, active, onClick, isDisabled, bsize }: SplitsButtonProps) => (
    <BNButton
      disabled={isDisabled}
      size={bsize}
      variant="default"
      c="var(--colors-paperReverse)"
      p={0}
      className={active ? `${classes.buttons} ${classes.activeButton}` : `${classes.buttons}`}
      onClick={() => onClick(value)}
    >
      {label}
    </BNButton>
  ));

  return (
    <Button.Group className={classes.btnGroup}>
      <SplitsButton bsize={size} isDisabled={disabled} label="1" value={1} onClick={onSplitButtonClick} active={splits?.includes(1) ?? false} />
      <SplitsButton bsize={size} isDisabled={disabled} label="2" value={2} onClick={onSplitButtonClick} active={splits?.includes(2) ?? false} />
      <SplitsButton bsize={size} isDisabled={disabled} label="3" value={3} onClick={onSplitButtonClick} active={splits?.includes(3) ?? false} />
      <SplitsButton bsize={size} isDisabled={disabled} label="4" value={4} onClick={onSplitButtonClick} active={splits?.includes(4) ?? false} />
      <SplitsButton bsize={size} isDisabled={disabled} label="5" value={5} onClick={onSplitButtonClick} active={splits?.includes(5) ?? false} />
      <SplitsButton bsize={size} isDisabled={disabled} label="6" value={6} onClick={onSplitButtonClick} active={splits?.includes(6) ?? false} />
      <SplitsButton bsize={size} isDisabled={disabled} label="7" value={7} onClick={onSplitButtonClick} active={splits?.includes(7) ?? false} />
      <SplitsButton bsize={size} isDisabled={disabled} label="8" value={8} onClick={onSplitButtonClick} active={splits?.includes(8) ?? false} />
    </Button.Group>
  );
};
