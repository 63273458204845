import { ActionIcon } from '@mantine/core';
import { useMemo } from 'react';
import MoreVertIcon from '../../components/icons/MoreVert';
import { useToggle } from '@mantine/hooks';
import { useGlobalState } from '../../data/GlobalState';
import { BNAccordionMenu } from '../../components/Accordion/BNAccordion';
import WarningIcon from '../../components/icons/Warning';
import { Sale } from './Sales.types';
import { BarkerCoreModelsInventoryEvent, BarkerCoreModelsSalesDtiPortalFulfillmentStatus, BarkerCoreModelsSalesListingSoldVendorPropertiesDtiPortal } from '../../api';
import { useFlag } from '@unleash/proxy-client-react';
import { useSetAtom } from 'jotai';
import { selectedSaleAtom } from '../../data/atoms';
import ScreenshotIcon from '../../components/icons/Screenshot';
import { isDtiHosted } from '../../utils/whitelabel-consts';
import HistoryIcon from '../../components/icons/History';
import { BarkerEventListing } from '../../types';
import { StringWithPipe } from '../../ts-utils';

export type SaleActionProps = {
  sale: Sale;
  setSelectedHistoryListing: (value: { listing: BarkerEventListing; isOpen: boolean }) => void;
};
export const SalesAction = ({ sale, setSelectedHistoryListing }: SaleActionProps) => {
  const [menuOpen, toggleMenu] = useToggle();

  return (
    <>
      <BNAccordionMenu
        width={200}
        opened={menuOpen}
        onClose={() => toggleMenu(false)}
        position="right"
        offset={15}
        target={
          <ActionIcon onClick={() => toggleMenu()}>
            <MoreVertIcon size={20} color="var(--colors-gray-5)" />
          </ActionIcon>
        }
      >
        {menuOpen && <SalesActionForm sale={sale} setSelectedHistoryListing={setSelectedHistoryListing} toggleMenu={toggleMenu} />}
      </BNAccordionMenu>
    </>
  );
};

export const SalesActionForm = ({ sale, setSelectedHistoryListing, toggleMenu }: SaleActionProps & { toggleMenu: (value?: boolean) => void }) => {
  const { currentUser, tenants } = useGlobalState('currentUser', 'tenants');
  const tenantPointOfSale = useMemo(() => tenants?.find((t) => t.tenantId === sale?.tenantId)?.pointOfSaleId || 'Unknown', [sale.tenantId, tenants]);

  const vendorProperties = sale.vendorProperties as BarkerCoreModelsSalesListingSoldVendorPropertiesDtiPortal;
  const fulfillmentStatus = sale.fulfillmentStatus as BarkerCoreModelsSalesDtiPortalFulfillmentStatus;

  const isEligibleForAirbill =
    sale.vendorProperties?.pointOfSaleId === 'DtiPortal' &&
    !vendorProperties.hasAirbill &&
    fulfillmentStatus.status === 'Incomplete' &&
    (vendorProperties.location === 'HARD' || vendorProperties.location === 'DROPSHIP');

  const isEligibleForSelfService =
    sale.vendorProperties?.pointOfSaleId === 'DtiPortal' &&
    fulfillmentStatus.status === 'Incomplete' &&
    (vendorProperties.location === 'MOBILE_XFR' || vendorProperties.location === 'FLASH');

  const dtiSelfServeFlag = useFlag('dti-self-serve');
  const hasSaleHistoryFlag = useFlag('sale-history');

  const setSelectedSale = useSetAtom(selectedSaleAtom);

  return (
    <>
      {isDtiHosted && (
        <BNAccordionMenu.Item
          title="Complete Self-Service"
          leftSection={<ScreenshotIcon />}
          disabled={!dtiSelfServeFlag || !isEligibleForSelfService}
          onClick={() => setSelectedSale(sale)}
          data-pendo-name="Complete Self-Service MenuItem"
        />
      )}
      {isDtiHosted && (
        <BNAccordionMenu.Item
          title="Request Airbill"
          leftSection={<WarningIcon />}
          disabled={!isEligibleForAirbill}
          onClick={() => {}}
          data-pendo-name="Request Airbill MenuItem"
        />
      )}
      {hasSaleHistoryFlag && (
        <BNAccordionMenu.Item
          title="View History"
          leftSection={<HistoryIcon />}
          onClick={() => {
            if (!sale?.event?.venue) return;

            const listing = {
              ...sale,
              tenantIdListingId: `${sale.tenantId}|${sale.listingId}` as StringWithPipe,
              tenantIdEventId: `${sale.tenantId}|${sale.event.eventId}` as StringWithPipe,
              isBroadcasting: false,
              quantityRemaining: sale.quantity,
              quantityReserved: 0,
              pricerStatusId: 'None',
              numBarcodes: 0,
              seatingType: 'Unknown',
              unitFace: 0,
              event: {
                ...sale.event,
                venue: { ...sale.event.venue, tenantId: sale.tenantId },
                links: [],
                numComments: 0,
                numTicketsSold: 0,
                monitors: [],
                openCost: 0,
                openListings: 0,
                openTickets: 0,
                tenantId: sale.tenantId,
              } satisfies BarkerCoreModelsInventoryEvent,
            } satisfies BarkerEventListing;
            setSelectedHistoryListing({ listing, isOpen: true });
          }}
          data-pendo-name="View Listing History MenuItem"
        />
      )}
    </>
  );
};

// export const UploadTransferProofModal = ({ sale, closeModal }: { sale: Sale; closeModal: () => void }) => {
//
//   const { mutateAsync: uploadTransferProof } = usePostApiSalesSaleIdUploadTransferProof();
//
//   return (
//
//   )
// };
