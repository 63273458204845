import { IconPropsBase } from './IconPropsBase';

export default function PartlyCloudyDayIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M450.001-751.538v-147.691h59.998v147.691h-59.998Zm242.921 100.769-42.153-42.538L755.307-798.46l42.153 43.153-104.538 104.538Zm58.616 200.768v-59.998h147.691v59.998H751.538Zm3.769 287.461L650.769-267.078l42.538-42.537L798.46-205.693l-43.153 43.153ZM267.463-650.385 162.155-755.692l43.153-42.383 104.307 105.538-42.152 42.152Zm-15.924 452.693h172.307q28.847 0 49.424-20.577 20.577-20.577 20.577-49.424 0-28.846-19.885-49.231-19.884-20.385-48.731-20.385h-44.846l-17.307-41.461q-14.769-34.539-46.116-54.808-31.346-20.269-69.269-20.269-52.693 0-89.424 36.731-36.73 36.731-36.73 89.423 0 54.231 37.884 92.116 37.885 37.885 92.116 37.885Zm0 59.998q-78.769 0-134.384-55.615Q61.54-248.924 61.54-327.693q0-77.615 54.269-131.884 54.269-54.269 131.884-54.269 57.307 0 104.307 31.731t68.307 84.808q54.154 0 93.654 36.653 39.5 36.653 39.885 96.269-1.231 53.153-38.846 89.922-37.615 36.769-91.154 36.769H251.539Zm301.537-134.384q-3.846-15-7.692-29.192t-7.692-29.191q46.154-19.385 74.231-59.577Q640-430.231 640-480q0-66-47-113t-113-47q-61.154 0-106.539 40.346-45.384 40.347-51.846 101.5-14.999-3.846-30.614-7.076-15.615-3.231-30.615-7.077 11.692-81.846 74.423-134.769 62.73-52.923 145.191-52.923 91.538 0 155.768 64.231 64.231 64.23 64.231 155.768 0 70.461-40.346 126.961-40.346 56.499-106.577 80.961ZM481-480Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M454.001-736.538v-131.691h51.998v131.691h-51.998Zm225.921 92.769-37.153-37.538 93.538-94.153 37.153 38.153-93.538 93.538Zm55.616 188.768v-51.998h131.691v51.998H735.538Zm-.231 266.461-91.538-94.538 35.538-36.537 94.153 92.922-38.153 38.153ZM280.463-643.385l-93.308-94.307 36.153-36.383 95.307 93.538-38.152 37.152Zm-4.924 429.693h148.307q28.847 0 49.424-20.783 20.577-20.783 20.577-49.924 0-29.14-20.173-49.525-20.173-20.385-49.443-20.385h-29.846l.693-8.461q-7.769-46.539-42.116-76.808-34.346-30.269-81.269-30.269-52.693 0-89.424 36.231-36.73 36.231-36.73 89.217 0 54.525 37.884 92.616 37.885 38.091 92.116 38.091Zm0 51.998q-75.449 0-128.724-53.548Q93.54-268.79 93.54-344.626q0-74.682 51.929-125.951 51.929-51.269 126.224-51.269 57.307 0 102.807 32.231t65.807 85.308q44.154 8 74.654 38.653 30.5 30.653 30.885 78.269.769 51.153-35.085 88.422-35.855 37.269-86.915 37.269H275.539Zm267.537-158.384q-6.846-11-14.192-22.692t-14.192-22.691q39.154-11.385 62.231-43.515Q600-441.107 600-481q0-50-35.25-84.5T480-600q-46.154 0-80.059 29.042-33.905 29.042-38.326 72.804-11.999-4.846-24.114-10.576-12.115-5.731-24.115-10.577 14.692-57.846 60.923-95.269 46.23-37.423 105.691-37.423 71.538 0 121.768 49.939 50.231 49.939 50.231 121.06 0 53.461-29.346 97.461-29.346 43.999-79.577 63.461ZM481-481Z"
        />
      </svg>
    );
  }
}
