import { ColumnPinnedType } from '@ag-grid-community/core';
import {
  BarkerCoreEnumsFulfillmentStatus,
  BarkerCoreEnumsMarketplace,
  BarkerCoreEnumsPaymentStatus,
  BarkerCoreEnumsPricerStatus,
  BarkerCoreEnumsTicketStock,
  BarkerCoreModelsDTIBarcodeItem,
  BarkerCoreModelsDTIBulkEditListingRequest,
  BarkerCoreModelsDTIBulkPurchaseRequest,
  BarkerCoreModelsDTIItem,
  BarkerCoreModelsInventoryEvent,
  BarkerCoreModelsInventoryListing,
  BarkerCoreModelsInventoryPosNextSplitRules,
  BarkerCoreModelsInventorySeasonLocation,
  BarkerCoreModelsMarketplacesEvent,
  BarkerCoreModelsMarketplacesListing,
  BarkerCoreModelsMarketplacesSeatingChartZone,
  BarkerCoreModelsPricingRule,
  BarkerCoreModelsPricingRuleBase,
  BarkerCoreModelsSalesSaleBase,
  GetApiSalesParams,
} from './api';
import { DeepOmit, StringWithPipe, UnDot, WithRequiredProperty } from './ts-utils';
import { PRICE_VARIATIONS } from './utils/globals';

export type BarkerCoreModelsInventoryGetInventoryResponseExtended = {
  events: BarkerCoreModelsInventoryEvent[];
  listings: BarkerCoreModelsInventoryListingExtended[];
};

export type BarkerCoreModelsInventoryListingExtended = BarkerCoreModelsInventoryListing & UIListingProps;

export type UIListingProps = {
  ruleCount?: number;
};

export type BarkerSelectedEventListing = {
  event: BarkerCoreModelsInventoryEvent;
  listing: BarkerCoreModelsInventoryListing & UIListingProps;
};

export type BarkerEventListing = {
  event: BarkerCoreModelsInventoryEvent;
  sale?: BarkerCoreModelsSalesSaleBase;
  meta?: Record<string, unknown>;
  tenantIdEventId: StringWithPipe;
  tenantIdListingId: StringWithPipe;
} & UIListingProps &
  WithRequiredProperty<BarkerCoreModelsInventoryListing, 'listingId'>;

export type BarkerEventListingWithPending = { pendingBroadcast?: boolean | null | undefined } & BarkerEventListing;

export type SeatingChartInstance = {
  rows: string;
  splits: string;
  exclusions: string;
  price: string;
  marketplaceEventId: string;
  selectedMarketplace: BarkerCoreEnumsMarketplace;
};

export type GetSeatingChartResponse = {
  asOfDate: Date;
  svgUrl: string;
  zones: BarkerCoreModelsMarketplacesSeatingChartZone[] | undefined;
};

// export type FiltersWithNullableOutlierCriteria = Omit<BarkerCoreModelsPricingRuleBase['filters'], 'outlierCriteria'> & { outlierCriteria?: BarkerCoreModelsPricingOutlierCriteria | null };

// export type Rule = Omit<
//   BarkerCoreModelsPricingRuleBase & { ruleId: string | null; meta?: { adjustmentOverride?: boolean; numComparablesOverride?: boolean } },
//   'pointOfSaleEventId' | 'tenantId' | 'filters'
// > &
//   { filters: FiltersWithNullableOutlierCriteria } &
//   Partial<Pick<BarkerCoreModelsPricingRule, 'updatedAt'>>;

export type Rule = Omit<
  BarkerCoreModelsPricingRuleBase & { ruleId: string | null; meta?: { isLocalRule?: boolean; adjustmentOverride?: boolean; numComparablesOverride?: boolean } },
  'pointOfSaleEventId' | 'tenantId'
> &
  Partial<Pick<BarkerCoreModelsPricingRule, 'updatedAt'>>;

export type ListingUpdate = keyof BarkerCoreModelsInventoryListing extends infer K extends keyof BarkerCoreModelsInventoryListing
  ? K extends K
    ? {
        listingId: string;
        tenantId: string;
        property: K;
        value: BarkerCoreModelsInventoryListing[K];
        previousValue: BarkerCoreModelsInventoryListing[K];
        inlinePriceChange?: boolean;
      }
    : never
  : never;

export type GlobalTransientState = {
  autoUpdateAutoPricedListPriceRuleId: string | null | undefined;
  applyGroupListingRule: boolean;
  ignoreGridScrolling: boolean;
  expandGroupByListingId: StringWithPipe | null;
};

export type PriceVariation = (typeof PRICE_VARIATIONS)[number];

export type MarketplacesListingWithAlias = {
  sectionAlias?: string;
} & BarkerCoreModelsMarketplacesListing;

export type PartialBarkerCoreModelsInventoryPosNextSplitRules = DeepOmit<BarkerCoreModelsInventoryPosNextSplitRules, UnDot<'splitRule.name'>>;

export type SectionCounts = { sectionId: string; section: string; count: number; totalQuantity: number };
export type RecordSectionCounts = Record<string, SectionCounts>;

export type SeasonSummary = Omit<BarkerCoreModelsInventorySeasonLocation, 'listings' | 'row' | 'section'> & {
  numLocations: number;
  numEvents: number;
  seasonYear: string;
  locations: InventorySeasonLocationExtended[];
};

export type InventorySeasonLocationExtended = BarkerCoreModelsInventorySeasonLocation & {
  hashId: string;
  ruleId?: string;
  ruleTier?: number;
  ruleCount?: number;
};

export type MarketplaceEventExtended = {
  marketplaceEventId: string | null | undefined;
  svgDetails: any;
  marketplaceId: BarkerCoreEnumsMarketplace | null | undefined;
  marketplaceEvent: BarkerCoreModelsMarketplacesEvent | null | undefined;
  isUserMapped: boolean;
  metadata?: string | null;
  sections: BarkerCoreModelsMarketplacesSeatingChartZone[];
};

export interface Window {
  store: any;
}

export type InventorySearchParams = {
  query: string;
  fromDate: string | null | undefined;
  toDate: string | null | undefined;
  pricerStatuses: BarkerCoreEnumsPricerStatus[] | undefined;
  purchaseDateFrom: string | undefined;
  purchaseDateTo: string | undefined;
  daysSinceRepriced: number | undefined;
  daysSinceViewed: number | undefined;
  listingId: string | undefined;
  eventId: string | undefined;
  tags: string[] | undefined;
  antiTags: string[] | undefined;
  daysSinceLastSale: number | undefined;
  onlyNotBroadcasting: boolean | undefined;
  showEvents: 'open' | 'mine' | 'all' | undefined;
};

export type InventorySearchParamsWithDates = {
  fromDate: Date | null | undefined;
  toDate: Date | null | undefined;
  purchaseDateFrom: Date | undefined;
  purchaseDateTo: Date | undefined;
  tags: string[];
  antiTags: string[];
} & Omit<InventorySearchParams, 'fromDate' | 'toDate' | 'purchaseDateFrom' | 'purchaseDateTo'>;

export type SalesSearchParamsWithDates = {
  fromDate: Date | null | undefined;
  toDate: Date | null | undefined;
  eventDateFrom: Date | null | undefined;
  eventDateTo: Date | null | undefined;
  fulfillmentStatusId: BarkerCoreEnumsFulfillmentStatus | null | undefined;
  paymentStatusId: BarkerCoreEnumsPaymentStatus | null | undefined;
} & Omit<GetApiSalesParams, 'fromDate' | 'toDate' | 'eventDateFrom' | 'eventDateTo' | 'fulfillmentStatusId' | 'paymentStatusId'>;

export type ColumnSettingItem = {
  sortIndex?: number;
  key: string;
  name: string;
  visible: boolean; // TODO: Rename this to hide to match the grid eventually.
  width: number | undefined;
  pinned: ColumnPinnedType;
};

export enum PricerView {
  Split = 'split',
  Standard = 'standard',
  Bulk = 'bulk',
}

export enum FilterMode {
  Standard = 'standard',
  Locked = 'locked',
}

export type TicketmasterMetadata = {
  TicketmasterId?: string;
  IsResaleEnabled: boolean;
};

type TicketGroupBase = {
  eventIds: number[];
  group: string;
};

export type TicketGroup = {
  orders: Order[];
} & TicketGroupBase;
export type TicketGroupEdit = {
  orders: OrderEdit[];
} & TicketGroupBase;

export type Order = Omit<BarkerCoreModelsDTIBulkPurchaseRequest, 'quantity' | 'cost' | 'price' | 'stock' | 'event_id' | 'account_id' | 'seat_from'> & {
  event_id: number | undefined;
  quantity: number | undefined;
  cost: number | undefined;
  price: number | undefined;
  barcodes: string[];
  stock: number | undefined;
  account_id: number | undefined;
  seat_from: number | undefined;
  key: string;
  disclosures: string[];
  location_id: undefined; // TODO: This should really be typed or a number
};

export type OrderEdit = Omit<BarkerCoreModelsDTIBulkEditListingRequest, 'original' | 'checksum' | 'tags' | 'listing_request_id' | 'user_initiated_changes' | 'start_seat'> & {
  account_info: string | null | undefined;
  order_number: string | null | undefined;
  disclosures: string[];
  seat_from: number | undefined; // NOTE: start_seat is swapped for seat_from to match purchase model.
  quantity: number;
  odd_even: boolean;
  barcodes: BarkerCoreModelsDTIBarcodeItem[];
  key: string;
  stock: BarkerCoreEnumsTicketStock;
  location_id: string | undefined; // TODO: This should really be typed or a number
  items: BarkerCoreModelsDTIItem[] | null | undefined;
};

export type OrderEditPlus = OrderEdit & { location?: number; location_from?: number; start_seat?: number };
