import { Group, NumberInputFactory, StylesApiProps } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { useCallback, useState } from 'react';
import { BarkerCoreEnumsAdjustmentType } from '../../api';
import { BNNumberInput } from '../NumberInput/NumberInput';
import { BNSelect } from '../Select/Select';
import classes from './StaggerByValueInput.module.css';
import BNInputGroup from '../Group/InputGroup';
import cx from 'clsx';

export function StaggerByValueInput({
  type,
  numInputWidth,
  disabled,
  value,
  label,
  onChange,
  onKeyUp,
  error,
}: {
  type: BarkerCoreEnumsAdjustmentType;
  numInputWidth?: number;
  value: number | '';
  onChange: (value: { value: number | undefined; type: BarkerCoreEnumsAdjustmentType }) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled: boolean;
  label?: string | undefined;
  error?: React.ReactNode;
}) {
  const convertValue = useCallback((_value: number | '', _type: BarkerCoreEnumsAdjustmentType) => {
    if (_value === 0 || _value === '' || _value === undefined || _value === null) {
      return 0;
    }
    let result = _value;
    if (_type === 'Percentage') {
      result = parseFloat(((_value - 1) * 100).toFixed(4));
    }
    return result;
  }, []);

  const [_valueInternal, _setValueInternal] = useState(convertValue(value, type));
  const [_typeInternal, _setTypeInternal] = useState(type);

  // Updated from outside world
  useDidUpdate(() => {
    const convertedValue = convertValue(value, type);
    if (convertedValue !== _valueInternal && type === _typeInternal) {
      _setValueInternal(convertedValue);
    } else if (type !== _typeInternal) {
      _setTypeInternal(type);
      _setValueInternal(convertedValue);
    }
  }, [value, type]);

  const _dualOnChangeHandler = useCallback(
    (type: BarkerCoreEnumsAdjustmentType) => {
      _setTypeInternal(type);
      _onChangeInternal(_valueInternal, type);
    },
    [_valueInternal],
  );

  const _onChangeInternal = useCallback(
    (_value: number | '', _type?: BarkerCoreEnumsAdjustmentType) => {
      type = _type || type;
      if (_value === '' || _value === undefined || _value === null) {
        _setValueInternal(0);
        if (type === 'Percentage') {
          return onChange({ value: 1, type: type });
        }
        return onChange({ value: 0, type: type });
      }

      let result = _value;
      if (type === 'Percentage') {
        result = parseFloat((1 + _value / 100).toFixed(4));
      }
      _setValueInternal(_value);
      onChange({ value: result, type: type });
    },
    [type, value],
  );

  return (
    <BNInputGroup label="Stagger By" error={error}>
      <BNInputGroup.Item>
        <BNSelect
          onChange={(x) => _dualOnChangeHandler(x as BarkerCoreEnumsAdjustmentType)}
          value={_typeInternal}
          disabled={disabled}
          m={0}
          p={0}
          size="xs"
          label={label}
          w="auto"
          className={cx(classes.staggerByType, classes.shortSelectRoot)}
          styles={{ input: { paddingRight: 0 } }}
          rightSectionWidth={20}
          error={error}
          data={[
            { value: BarkerCoreEnumsAdjustmentType.Amount, label: '$' },
            { value: BarkerCoreEnumsAdjustmentType.Percentage, label: '%' },
          ]}
        />
      </BNInputGroup.Item>
      <BNInputGroup.Item>
        <BNNumberInput
          disabled={disabled}
          onChange={_onChangeInternal}
          value={_valueInternal}
          label={label}
          radius={0}
          onKeyUp={onKeyUp}
          m={0}
          p={0}
          w={numInputWidth}
          className={classes.staggerByNumberInput}
          size="xs"
          decimalScale={2}
          fixedDecimalScale
          step={0.1}
          stepHoldDelay={500}
          stepHoldInterval={100}
          min={0}
          error={error}
          selectOnFocus
        />
      </BNInputGroup.Item>
    </BNInputGroup>
  );
}
