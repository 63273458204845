import { IconPropsBase } from './IconPropsBase';

export default function MobileDataOffIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" style={{ transform: 'rotate(90deg) scaleY(-1)' }}>;
        <path
          fill={color}
          d="m19.75 21.85-10-10v5.8l1.825-1.825 1.075 1.05L9 20.55l-3.65-3.675 1.075-1.05L8.25 17.65v-7.3l-6.125-6.1 1.05-1.075L20.8 20.8Zm-4-8.95-1.5-1.5V6.7L12.4 8.55 11.35 7.5 15 3.85l3.65 3.65-1.05 1.05-1.85-1.85Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" style={{ transform: 'rotate(90deg) translateY(-0.75px) scaleY(-1)' }}>
        <path
          fill={color}
          d="M15.958 16.438 7.542 8.021v5.521l1.416-1.417.75.771L7 15.625l-2.708-2.729.75-.771 1.416 1.417V6.938L2.479 2.979l.771-.791 13.479 13.479Zm-2.416-6.084-1.084-1.083V4.729l-1.396 1.396-.77-.771L13 2.646l2.708 2.708-.77.771-1.396-1.396Z"
        />
      </svg>
    );
  }
}
