import { IconPropsBase } from './IconPropsBase';

export default function SportsHockeyIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M95.386-177.694v-89.228q0-15.077 10.153-25.231 10.154-10.153 25.231-10.153h36.923v124.612H95.386Zm112.307 0v-124.612h163.846l37.077-84.155 45.538 99.228-39.385 87.539q-5.385 11.385-14.423 16.693-9.039 5.307-21.577 5.307H207.693Zm584.614 0v-124.612h36.923q15.077 0 25.231 10.153 10.153 10.154 10.153 25.231v89.228h-72.307Zm-40 0H581.231q-12.153 0-21.384-5.307-9.231-5.308-14.616-16.693L281.694-782.306h93.997L480-547.381l104.309-234.925h93.997L525.537-444.461l62.924 142.155h163.846v124.612Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M159.386-209.694v-65.228q0-18.477 12.453-30.931 12.454-12.453 30.931-12.453h-3.077v108.612h-40.307Zm88.307 0v-108.612h132.846l35.077-76.155 34.538 74.228-41.385 90.539q-4.385 8.385-11.165 14.193-6.781 5.807-16.835 5.807H247.693Zm512.614 0v-108.612h-3.077q18.477 0 30.931 12.453 12.453 12.454 12.453 30.931v65.228h-40.307Zm-48 0H579.02q-9.942 0-16.173-5.807-6.231-5.808-10.616-14.193L315.694-750.306h65.997L480-534.381l98.309-215.925h65.997L514.537-462.461l64.924 144.155h132.846v108.612Z"
        />
      </svg>
    );
  }
}
