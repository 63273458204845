import { IconPropsBase } from './IconPropsBase';

export default function SportsBaseballIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M230.154-279.538q47.308-35.769 73.577-88.462 26.27-52.692 26.27-112t-26.27-112q-26.269-52.693-73.577-88.462-34.077 43.154-52.115 94.269Q160-535.077 160-480t18.039 106.193q18.038 51.115 52.115 94.269ZM480-160q57.308 0 110.539-19.231t96.385-56.923q-55.462-45.231-86.193-108.808-30.73-63.577-30.73-135.038 0-71.461 30.73-135.038 30.731-63.577 86.193-108.808-43.154-37.692-96.385-56.923T480-800q-57.308 0-110.539 19.231t-96.385 56.923q55.462 45.231 86.193 108.808 30.73 63.577 30.73 135.038 0 71.461-30.73 135.038-30.731 63.577-86.193 108.808 43.154 37.692 96.385 56.923T480-160Zm249.846-119.538q34.077-43.154 52.115-94.269Q800-424.923 800-480t-18.039-106.193q-18.038-51.115-52.115-94.269-47.308 35.769-73.577 88.462-26.27 52.692-26.27 112t26.27 112q26.269 52.693 73.577 88.462ZM480-480Zm.067 379.999q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M252.154-265.538q54.308-34.769 86.077-90.462 31.77-55.692 31.77-124 0-65.308-31.77-123-31.769-57.693-87.077-90.462-40.077 44.154-61.615 98.769Q168-540.077 168-480t21.539 115.193q21.538 55.115 62.615 99.269ZM480-168q51.308 0 98.539-15.731t88.385-46.423q-59.462-45.231-94.193-108.808-34.73-63.577-34.73-141.038 0-79.461 34.73-142.038 34.731-62.577 94.193-107.808-41.154-31.692-88.385-46.923Q531.308-792 480-792t-99.039 15.731q-47.731 15.731-87.885 47.423 59.462 44.231 94.193 106.808 34.73 62.577 34.73 142.038 0 77.461-34.73 141.038-34.731 63.577-94.193 107.808 40.154 31.692 87.885 47.423Q428.692-168 480-168Zm227.846-98.538q41.077-43.154 62.615-98.269Q792-419.923 792-480t-21.039-115.193q-21.038-55.115-62.115-98.269-56.308 31.769-87.577 89.962-31.27 58.192-31.27 123.5 0 68.308 31.27 124 31.269 55.693 86.577 89.462ZM480-480Zm.067 363.999q-74.836 0-141.204-28.42-66.369-28.42-116.182-78.21-49.814-49.791-78.247-116.129-28.433-66.337-28.433-141.173 0-75.836 28.42-141.704 28.42-65.869 78.21-115.682 49.791-49.814 116.129-78.247 66.337-28.433 141.173-28.433 75.836 0 141.704 28.42 65.869 28.42 115.682 78.21 49.814 49.791 78.247 115.629 28.433 65.837 28.433 141.673 0 74.836-28.42 141.204-28.42 66.369-78.21 116.182-49.791 49.814-115.629 78.247-65.837 28.433-141.673 28.433Z"
        />
      </svg>
    );
  }
}
