import { IconPropsBase } from './IconPropsBase';

export default function CorporateIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M2.375 20.575v-17.5h9.5v4h9.75v13.5Zm1.5-1.5h6.5v-2.5h-6.5Zm0-4h6.5v-2.5h-6.5Zm0-4h6.5v-2.5h-6.5Zm0-4h6.5v-2.5h-6.5Zm8 12h8.25v-10.5h-8.25Zm2.175-6.5v-1.5h3.7v1.5Zm0 4v-1.5h3.7v1.5Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M2.312 16.646V3.562h7.584v3h7.792v10.084Zm1.084-1.084h5.416v-1.916H3.396Zm0-3h5.416v-1.916H3.396Zm0-3h5.416V7.646H3.396Zm0-3h5.416V4.646H3.396Zm6.5 9h6.708V7.646H9.896Zm1.646-4.916V9.562h3.25v1.084Zm0 3v-1.084h3.25v1.084Z"
        />
      </svg>
    );
  }
}
