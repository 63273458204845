import { Box, Divider, Flex, Stack, Text } from '@mantine/core';
import { BNButton } from '../../../components/Button/Button';
import { useMemo } from 'react';
import { useSetAtom } from 'jotai/index';
import { pendingListingUpdatesAtom, updateListingsAtom } from '../../../data/atoms';
import { useGlobalState } from '../../../data/GlobalState';
import { useForm } from '@mantine/form';
import { BNSelect } from '../../../components/Select/Select';
import { getPointOfSaleSplitOptions, getPointOfSaleSplitType, setSplitsModelForUpdate } from '../../../components/Splits/Splits.utils';
import { BarkerEventListing } from '../../../types';
import { useListingState } from '../../../data/ListingState';
import { SplitsButtonGroupVariable } from '../../SeatingChart/Filters/Filters.SplitsButtonGroupVariable';

export type SplitsUpdateFormProps = {
  closeModal: () => void;
  listing: BarkerEventListing;
};

export function SplitsUpdateForm({ closeModal, listing }: SplitsUpdateFormProps) {
  const { tenants, currentUser } = useGlobalState('tenants', 'currentUser');
  const { patchListing, patchListingLoading } = useListingState('patchListing', 'patchListingLoading');
  const tenantPointOfSale = useMemo(() => tenants?.find((t) => t.tenantId === listing.tenantId)?.pointOfSaleId || 'Unknown', [listing.tenantId, tenants]);
  const splitOptions = useMemo(() => getPointOfSaleSplitOptions(tenantPointOfSale), [tenantPointOfSale]);
  const initialSplitValues = getPointOfSaleSplitType(tenantPointOfSale, listing.splits);
  const updateListing = useSetAtom(updateListingsAtom);
  const setPendingListingUpdates = useSetAtom(pendingListingUpdatesAtom);
  const { listingId, tenantId } = listing;
  const initialValues = {
    splitType: initialSplitValues?.splitType,
    customSplits: initialSplitValues?.customSplits,
  };
  const { values, getInputProps, isDirty, reset, resetDirty } = useForm({
    initialValues,
  });

  const onClose = ({ validateDirty } = { validateDirty: false }) => {
    if (!validateDirty || !isDirty()) {
      reset();
    }
    closeModal();
  };

  const onSave = () => {
    updateSplits().then(() => closeModal());
  };

  const updateSplits = async () => {
    if (listingId) {
      const update = setSplitsModelForUpdate(tenantPointOfSale, values);
      if (update) {
        const { data: listingResult } = await patchListing({
          tenantId,
          listingId,
          data: [update],
        });
        if (listingResult) {
          updateListing(listingResult);
          const getPointOfSaleSplitTypeResult = getPointOfSaleSplitType(tenantPointOfSale, listingResult.splits);
          resetDirty({ ...initialValues, ...getPointOfSaleSplitTypeResult });
          setPendingListingUpdates((prev) => [...prev.filter((x) => !(x.listingId === listing.listingId && x.property === 'splits'))]);
        }
      }
    }
  };

  return (
    <>
      <Flex p={0} h="100%" justify="space-between">
        <Flex py="sm" px="md" gap="xs" align="center" justify="start" style={{ flex: 1 }}>
          <Text size="md" fw={600}>
            Splits
          </Text>
        </Flex>
      </Flex>
      <Divider color="var(--colors-gray-0)" />
      <Box p="md" w="100%">
        <BNSelect
          {...getInputProps('splitType')}
          m={0}
          p={0}
          w="100%"
          disabled={patchListingLoading}
          size="sm"
          labelProps={{ style: { whiteSpace: 'nowrap' } }}
          aria-label="Split Type"
          data={splitOptions}
          withinPortal
        />
        {values.splitType === 'CUSTOM' && tenantPointOfSale !== 'DtiPortal' && (
          <Stack gap="xs" w="100%" mah={100} px="xs" py="xs" mt="sm" style={{ border: '1px solid var(--colors-gray-3)', borderRadius: 3, overflow: 'auto' }}>
            <SplitsButtonGroupVariable count={listing.quantityRemaining} size="sm" splits={values.customSplits} onChange={getInputProps('customSplits').onChange} />
          </Stack>
        )}
      </Box>
      <Divider color="var(--colors-gray-0)" />
      <Flex gap="sm" w="100%" py="xl" px="md" mb="var(--safe-area-inset-bottom, 0)">
        <BNButton fullWidth variant="default" onClick={() => onClose()} disabled={patchListingLoading}>
          Cancel
        </BNButton>
        <BNButton
          fullWidth
          variant="filled"
          color="green"
          type="submit"
          disabled={!isDirty('splitType') || currentUser?.roleId === 'ReadOnlyUser'}
          loading={patchListingLoading}
          onClick={onSave}
        >
          Save
        </BNButton>
      </Flex>
    </>
  );
}
