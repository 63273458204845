import { Box, SimpleGrid } from '@mantine/core';
import { BNNumberInput } from '../../components/NumberInput/NumberInput';
import { StaggerByValueInput } from '../../components/AdjustmentInput/StaggerByValueInput';
import { useNowPricing } from './NowPricing.hooks';
import { AdjustmentValueInputDiagonal } from '../../components/AdjustmentInput/AdjustmentValueInputDiagonal';

export const RuleForm = () => {
  const { form, isGroupedListing, isFormReady, onSave } = useNowPricing('form', 'isGroupedListing', 'isFormReady', 'onSave');

  return (
    <Box>
      <SimpleGrid cols={2} spacing="sm" my={8}>
        <AdjustmentValueInputDiagonal
          label="Adjustment"
          disabled={!isFormReady}
          onChange={(value) => {
            form.getInputProps('adjustmentValue').onChange(value.value);
            form.getInputProps('adjustmentTypeId').onChange(value.type);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onSave();
            }
          }}
          value={form.values.adjustmentValue || 0}
          type={form.values.adjustmentTypeId || 'Amount'}
        />

        <BNNumberInput
          selectOnFocus
          disabled={!isFormReady}
          {...form.getInputProps('numComparables')}
          label="# Comparables"
          decimalScale={0}
          allowDecimal={false}
          min={1}
          size="xs"
          defaultValue={1}
        />
      </SimpleGrid>
      {isGroupedListing && (
        <SimpleGrid mb={8} cols={2} spacing="sm">
          <BNNumberInput disabled={!isFormReady} {...form.getInputProps('numActive')} label="# Active" decimalScale={0} allowDecimal={false} min={0} step={1} size="xs" />
          <StaggerByValueInput
            numInputWidth={126}
            disabled={!isFormReady}
            label="Stagger By"
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                onSave();
              }
            }}
            onChange={(value) => {
              form.getInputProps('staggerByValue').onChange(value.value);
              form.getInputProps('staggerByTypeId').onChange(value.type);
            }}
            value={form.values.staggerByValue || 0}
            type={form.values.staggerByTypeId || 'Amount'}
          />
          {/* </Group> */}
        </SimpleGrid>
      )}
    </Box>
  );
};
