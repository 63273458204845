import { IconPropsBase } from './IconPropsBase';

export default function CloudIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M260-180.001q-82.922 0-141.461-57.615-58.538-57.615-58.538-140.538 0-73.384 47-130.538 47-57.153 119.923-67.614 19.231-89.693 90.192-146.693 70.961-57 162.884-57 108.538 0 184.268 75.731 75.731 75.73 75.731 184.268v20.001h12.308q63.23 4.923 105.461 50.846Q899.999-403.23 899.999-340q0 66.922-46.538 113.461Q806.922-180.001 740-180.001H260ZM260-240h480q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-83-58.5-141.5T480-720q-83 0-141.5 58.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41Zm220-240Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M240-212.001q-71.922 0-121.961-50.615-50.038-50.615-50.038-122.538 0-71.384 52-123.038 52-51.653 123.923-50.114 17.231-82.693 82.692-136.193 65.461-53.5 153.384-53.5 94.538 0 164.768 63.731 70.231 63.73 79.231 156.268v20.001h12.308q64.23-3.077 109.961 40.346Q891.999-424.23 891.999-360q0 61.922-43.038 104.961Q805.922-212.001 744-212.001H240ZM240-264h504q40 0 68-28t28-68q0-40-28-68t-68-28h-66l-6-65q-7-74-62-124.5T480-696q-64 0-115 38.5T297-556l-14 49-51 3q-48 3-80 37.5T120-384q0 50 35 85t85 35Zm240-216Z"
        />
      </svg>
    );
  }
}
