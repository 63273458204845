export type BarkerCoreEnumsMarketplace = (typeof BarkerCoreEnumsMarketplace)[keyof typeof BarkerCoreEnumsMarketplace];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BarkerCoreEnumsMarketplace = {
  Unknown: 'Unknown',
  SeatGeek: 'SeatGeek',
  StubHub: 'StubHub',
  TicketEvolution: 'TicketEvolution',
  TicketNetwork: 'TicketNetwork',
  VividSeats: 'VividSeats',
  Ticketmaster: 'Ticketmaster',
} as const;
