import { IconPropsBase } from './IconPropsBase';

export default function ConfirmationNumberIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M4 19.5q-.625 0-1.062-.438Q2.5 18.625 2.5 18v-3.6q.85-.15 1.425-.825Q4.5 12.9 4.5 12q0-.9-.575-1.575Q3.35 9.75 2.5 9.6V6q0-.625.438-1.062Q3.375 4.5 4 4.5h16q.625 0 1.062.438.438.437.438 1.062v3.6q-.85.15-1.425.825Q19.5 11.1 19.5 12q0 .9.575 1.575.575.675 1.425.825V18q0 .625-.438 1.062-.437.438-1.062.438ZM4 18h16v-2.55q-.925-.55-1.462-1.462Q18 13.075 18 12t.538-1.988Q19.075 9.1 20 8.55V6H4v2.55q.925.55 1.463 1.462Q6 10.925 6 12t-.537 1.988Q4.925 14.9 4 15.45Zm8-1.35q.325 0 .538-.212.212-.213.212-.538 0-.325-.212-.537-.213-.213-.538-.213-.325 0-.537.213-.213.212-.213.537 0 .325.213.538.212.212.537.212Zm0-3.9q.325 0 .538-.213.212-.212.212-.537 0-.325-.212-.538-.213-.212-.538-.212-.325 0-.537.212-.213.213-.213.538 0 .325.213.537.212.213.537.213Zm0-3.9q.325 0 .538-.213.212-.212.212-.537 0-.325-.212-.538-.213-.212-.538-.212-.325 0-.537.212-.213.213-.213.538 0 .325.213.537.212.213.537.213ZM12 12Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M3.5 15.583q-.438 0-.76-.323-.323-.322-.323-.76v-2.667q.645-.125 1.073-.645.427-.521.427-1.188 0-.667-.427-1.188-.428-.52-1.073-.645V5.5q0-.438.323-.76.322-.323.76-.323h13q.438 0 .76.323.323.322.323.76v2.667q-.645.125-1.073.645-.427.521-.427 1.188 0 .667.427 1.188.428.52 1.073.645V14.5q0 .438-.323.76-.322.323-.76.323Zm0-1.083h13v-1.896q-.667-.396-1.083-1.083Q15 10.833 15 10t.417-1.521q.416-.687 1.083-1.083V5.5h-13v1.896q.667.396 1.083 1.083Q5 9.167 5 10t-.417 1.521q-.416.687-1.083 1.083Zm6.5-1.292q.229 0 .385-.156.157-.156.157-.385t-.157-.386q-.156-.156-.385-.156t-.385.156q-.157.157-.157.386t.157.385q.156.156.385.156Zm0-2.666q.229 0 .385-.157.157-.156.157-.385t-.157-.385q-.156-.157-.385-.157t-.385.157q-.157.156-.157.385t.157.385q.156.157.385.157Zm0-2.667q.229 0 .385-.156.157-.157.157-.386t-.157-.385q-.156-.156-.385-.156t-.385.156q-.157.156-.157.385t.157.386q.156.156.385.156ZM10 10Z"
        />
      </svg>
    );
  }
}
