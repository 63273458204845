import { IconPropsBase } from './IconPropsBase';

export default function SortIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path fill={color} d="M3.25 17.75v-1.5h5.5v1.5Zm0-5.2v-1.5h11.5v1.5Zm0-5.175v-1.5h17.5v1.5Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path fill={color} d="M3.208 14.292v-1.084h4.584v1.084Zm0-3.917V9.292h8.584v1.083Zm0-3.896V5.396h13.584v1.083Z" />
      </svg>
    );
  }
}
