import { IconPropsBase } from './IconPropsBase';

export default function ThingsToDoIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M121.54-140v-60H260v-170h-69.23v-60h71.69q7.39-73.46 60.54-125.85 53.15-52.38 127-61.69v-185.53h241.54v136.92H510v48.61q73.85 9.31 127 61.69 53.15 52.39 60.54 125.85h71.69v60H700v170h138.46v60H121.54ZM320-200h130v-170H320v170Zm190 0h130v-170H510v170ZM323.69-430h312.62q-8.62-56.85-53.35-93.42Q538.23-560 480-560t-102.96 36.58q-44.73 36.57-53.35 93.42ZM480-430Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M137.54-164v-52H260v-178h-37.23v-52h85.69q4.51-61.64 44.6-105.43 40.09-43.8 100.94-50.11v-177.53h237.54v120.92H506v56.61q60.85 6.31 100.5 50.19 39.65 43.89 45.04 105.35h85.69v52H700v178h122.46v52H137.54ZM312-216h142v-178H312v178Zm194 0h142v-178H506v178ZM359.69-446h240.62q-3.62-45.85-38.35-75.92Q527.23-552 480-552t-81.96 30.08q-34.73 30.07-38.35 75.92ZM480-446Z"
        />
      </svg>
    );
  }
}
