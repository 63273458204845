import { Box, Group, Stack, Text, Title } from '@mantine/core';
import { BNButton } from '../components/Button/Button';
import { AdminContentHeader } from '../hoc/Admin/AdminContentHeader';
import { useParams } from 'react-router-dom';
import { useGlobalState } from '../data/GlobalState';
import { useForm } from '@mantine/form';
import { BarkerCoreEnumsRounding, BarkerCoreModelsAdministrativeTenantPricerSettings, getGetApiTenantsQueryKey, usePutApiTenantsTenantIdSettingsPricer } from '../api';
import { useState } from 'react';
import { BNNumberInput } from '../components/NumberInput/NumberInput';
import { useQueryClient } from '@tanstack/react-query';
import { BNSwitch } from '../components/Switch/Switch';
import { BNPercentageInput } from '../components/NumberInput/PercentageInput';
import { BNSelect } from '../components/Select/Select';
import { SplitsButtonGroup } from '../hoc/SeatingChart/Filters/Filters.SplitsButtonGroup';

function getFormValues(settings: BarkerCoreModelsAdministrativeTenantPricerSettings) {
  return {
    ...settings,
    drasticPriceThresholdPercentage: (settings.drasticPriceThresholdPercentage ?? 0) * 100,
    maximumPercentageUnder: settings.maximumPercentageUnder == null ? null : settings.maximumPercentageUnder * 100,
    disableSuggestions: settings.disableSuggestions ?? false,
  };
}

export default function CompanySettings() {
  const { tenantId } = useParams();
  const { tenants } = useGlobalState('tenants');
  const [selectedTenant] = useState(tenants?.find((tenant) => tenant.tenantId === tenantId));

  const settings = selectedTenant?.settings?.pricerSettings ?? ({} as BarkerCoreModelsAdministrativeTenantPricerSettings);
  const form = useForm<BarkerCoreModelsAdministrativeTenantPricerSettings>({
    initialValues: getFormValues(settings),
    transformValues: (values) => ({
      ...values,
      drasticPriceThresholdPercentage:
        typeof values.drasticPriceThresholdPercentage === 'string' ? null : !values.drasticPriceThresholdPercentage ? null : values.drasticPriceThresholdPercentage / 100,
      maximumPercentageUnder: typeof values.maximumPercentageUnder === 'string' ? null : !values.maximumPercentageUnder ? null : values.maximumPercentageUnder / 100,
      maximumAmountUnder: typeof values.maximumAmountUnder === 'string' ? null : !values.maximumAmountUnder ? null : values.maximumAmountUnder,
      disableSuggestions: values.disableSuggestions ?? false,
    }),
    validate: (values) => {
      const errors: Partial<Record<keyof BarkerCoreModelsAdministrativeTenantPricerSettings, string>> = {};
      if (typeof values.drasticPriceThresholdAmount === 'string' || values.drasticPriceThresholdAmount == null || values.drasticPriceThresholdAmount < 0) {
        errors.drasticPriceThresholdAmount = 'Please enter a valid number';
      } else if (values.drasticPriceThresholdAmount === 0) {
        errors.drasticPriceThresholdAmount = 'Please enter a number greater than 0';
      }
      if (typeof values.drasticPriceThresholdPercentage === 'string' || values.drasticPriceThresholdPercentage == null) {
        errors.drasticPriceThresholdPercentage = 'Please enter a valid number';
      } else if (values.drasticPriceThresholdPercentage === 0) {
        errors.drasticPriceThresholdPercentage = 'Please enter a number greater than 0';
      }
      return errors;
    },
  });

  const { mutateAsync: updateSettings, isLoading } = usePutApiTenantsTenantIdSettingsPricer();
  const queryClient = useQueryClient();

  if (!selectedTenant) {
    return null;
  }

  const isAnyticketsAffiliate =
    selectedTenant.tenantId === '4db7e08e-3f2d-4d2e-b96a-40f99c651c51' ||
    selectedTenant.parentTenantId === '4db7e08e-3f2d-4d2e-b96a-40f99c651c51' ||
    selectedTenant.tenantId === '433a633f-2d59-4388-b7eb-35f9426e6296' ||
    selectedTenant.parentTenantId === '433a633f-2d59-4388-b7eb-35f9426e6296';

  return (
    <>
      <form
        onReset={() => form.reset()}
        onSubmit={form.onSubmit((values) => {
          updateSettings({
            tenantId: selectedTenant.tenantId,
            data: values,
          }).then(() => {
            form.resetDirty();
            queryClient.invalidateQueries(getGetApiTenantsQueryKey());
          });
        })}
      >
        <AdminContentHeader
          title="Pricing Settings"
          sticky
          rightSection={
            <>
              <BNButton size="xs" variant="default" disabled={!form.isDirty()} loading={isLoading} type="reset">
                Cancel
              </BNButton>
              <BNButton size="xs" variant="filled" color="green" disabled={!form.isDirty()} loading={isLoading} type="submit">
                Save
              </BNButton>
            </>
          }
        />
        <Box py="xl" pb="lg">
          <Title order={3} size="h5" pt="xs">
            Auto-Adjusting Splits
          </Title>
          <Text size="xs" mb="xs" c="var(--colors-gray-5)">
            Select the splits you would like the pricer to compare against when you chose the automatic splits option.
          </Text>
          <Box maw={280}>
            <Group wrap="nowrap" gap={0}>
              <Text w={44} size="xs" c="var(--colors-gray-5)">
                Qty
              </Text>
              <Text size="xs" c="var(--colors-gray-5)">
                Splits
              </Text>
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                1
              </Text>
              <Box w="100%">
                <SplitsButtonGroup
                  splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['1']}
                  onChange={(splits) => {
                    form.setFieldValue('quantitySplitMatrix', {
                      ...form.values.quantitySplitMatrix,
                      1: splits,
                    });
                  }}
                  size="xs"
                />
              </Box>
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                2
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['2']}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    2: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                3
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['3']}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    3: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                4
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['4']}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    4: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                5
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['5']}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    5: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                6
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['6']}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    6: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                7
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['7']}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    7: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                8+
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['8']}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    8: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Text size="xs" mb="xs" c="var(--colors-gray-5)" style={{ textWrap: 'nowrap' }}>
              Listings identified as general admission will use the following splits. If none are selected, the pricer will fallback to the appropriate split based on the quantity.
            </Text>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                GA
              </Text>
              <SplitsButtonGroup
                splits={form.values.generalAdmissionSplits}
                onChange={(splits) => {
                  form.setFieldValue('generalAdmissionSplits', splits);
                }}
                size="xs"
              />
            </Group>
            <Text size="xs" mb="xs" c="var(--colors-gray-5)" style={{ textWrap: 'nowrap' }}>
              If your row isn&#39;t numeric, single-letter, double-letter, or triple-letter, the pricer will assume it&#39;s general admission.
            </Text>
          </Box>
        </Box>
        <Box py="xl" pb="lg">
          <Title order={3} size="h5">
            Section Suggestions
          </Title>
          <Box>
            <Text size="xs" c="var(--colors-gray-5)" mb="xs">
              Suggestions are enabled by default. If you wish to disable them, you may do so here.
            </Text>
            <BNSwitch label="Disable Section Suggestions" size="sm" pt={4} checked={form.values.disableSuggestions} {...form.getInputProps('disableSuggestions')} />
          </Box>
        </Box>
        <Box py="xl" pb="lg">
          <Title order={3} size="h5">
            Price Rounding
          </Title>
          <Text size="xs" c="var(--colors-gray-5)" mb="xs">
            Define how the auto-pricer should round prices. This setting will take effect immediately for all existing rules.
          </Text>
          <Box>
            <BNSelect
              label="Dollar Price Rounding"
              labelProps={{ style: { whiteSpace: 'nowrap' } }}
              size="xs"
              style={{ maxWidth: 134 }}
              data={[
                { value: BarkerCoreEnumsRounding.None, label: 'None' },
                {
                  value: BarkerCoreEnumsRounding.AlwaysDown,
                  label: 'Always Down',
                },
                { value: BarkerCoreEnumsRounding.AlwaysUp, label: 'Always Up' },
                { value: BarkerCoreEnumsRounding.Nearest, label: 'Nearest' },
              ]}
              {...form.getInputProps('roundPrices')}
            />
            <Text size="xs" mt={4} c="var(--colors-gray-5)">
              The pricer will round prices to whole dollar amounts based on the selected method.
            </Text>
          </Box>
        </Box>
        <Box py="xl" pb="lg">
          <Title order={3} size="h5">
            Auto-Pricing Constraints
          </Title>
          <Text size="xs" c="var(--colors-gray-5)" mb="xs">
            These constraints will be enforced for any new rules your users create.
          </Text>
          <Stack gap="xs">
            <Box>
              <BNNumberInput
                label="Minimum Floor"
                leftSection={<Text size="xs">$</Text>}
                size="xs"
                step={1}
                min={isAnyticketsAffiliate ? 10 : 0}
                decimalScale={2}
                fixedDecimalScale
                style={{ maxWidth: 134 }}
                selectOnFocus
                {...form.getInputProps('minimumFloorPrice')}
              />
              <Text fz={11} mt={4} c="var(--colors-gray-5)">
                {isAnyticketsAffiliate ? 'Anytickets enforces a minimum floor price of $10.00.' : 'Your floor price will not be allowed to be lower than this amount.'}
              </Text>
            </Box>
            <Box>
              <BNNumberInput
                label="Maximum Amount Under"
                labelProps={{ style: { whiteSpace: 'nowrap' } }}
                leftSection={<Text size="xs">$</Text>}
                size="xs"
                step={1}
                min={0}
                decimalScale={2}
                fixedDecimalScale
                style={{ maxWidth: 134 }}
                selectOnFocus
                {...form.getInputProps('maximumAmountUnder')}
              />
              <Text size="xs" mt={4} c="var(--colors-gray-5)">
                Your adjustment amount will not be allowed to be lower than this.
              </Text>
            </Box>
            <Box>
              <BNPercentageInput
                label="Maximum Percentage Under"
                labelProps={{ style: { whiteSpace: 'nowrap' } }}
                size="xs"
                step={1}
                min={0}
                decimalScale={2}
                fixedDecimalScale
                style={{ maxWidth: 134 }}
                selectOnFocus
                {...form.getInputProps('maximumPercentageUnder')}
              />
              <Text size="xs" mt={4} c="var(--colors-gray-5)">
                Your adjustment percentage will not be allowed to be lower than this.
              </Text>
            </Box>
          </Stack>
        </Box>
        <Box py="xl" pb="lg">
          <Title order={3} size="h5">
            Large Price Change Warnings
          </Title>
          <Text size="xs" c="var(--colors-gray-5)" mb="xs">
            Define thresholds for when the pricer should warn your users about potential pricing mistakes.
          </Text>
          <Stack gap="xs">
            <Box>
              <BNPercentageInput
                label="Percentage Change Threshold"
                labelProps={{ style: { whiteSpace: 'nowrap', flexWrap: 'nowrap' } }}
                size="xs"
                step={1}
                min={0}
                decimalScale={2}
                fixedDecimalScale
                style={{ maxWidth: 134 }}
                selectOnFocus
                {...form.getInputProps('drasticPriceThresholdPercentage')}
              />
              <Text size="xs" mt={4} c="var(--colors-gray-5)">
                Price changes must be greater than this percentage to trigger a warning.
              </Text>
            </Box>
            <Box>
              <BNNumberInput
                label="Minimum Dollar Amount"
                labelProps={{ style: { whiteSpace: 'nowrap', flexWrap: 'nowrap' } }}
                leftSection={<Text size="xs">$</Text>}
                size="xs"
                step={1}
                min={0}
                decimalScale={2}
                fixedDecimalScale
                style={{ maxWidth: 134 }}
                selectOnFocus
                {...form.getInputProps('drasticPriceThresholdAmount')}
              />
              <Text size="xs" mt={4} c="var(--colors-gray-5)">
                Price changes must be greater than this amount to trigger a warning.
              </Text>
            </Box>
            <Box>
              <BNSwitch label="Allow Snooze" size="sm" pt={4} checked={form.values.allowDrasticPriceAlertSnooze} {...form.getInputProps('allowDrasticPriceAlertSnooze')} />
              <Text size="xs" mt={4} c="var(--colors-gray-5)">
                Users will be able to snooze warnings for a period of time.
              </Text>
            </Box>
          </Stack>
        </Box>
      </form>
    </>
  );
}
