import yasml from '@thirtytech/yasml';
import {
  type BarkerCoreModelsDTIBulkEditListingRequest,
  BarkerCoreModelsInventoryDtiSplitRules,
  BarkerCoreModelsInventoryListing,
  BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal,
  getApiInventory,
  getApiInventoryListingsListingId,
  PostDtiPdfsAccountIdAttachBody,
  useGetApiInventoryListingsListingId,
  useGetApiPrincipalsInterrupts,
  usePostDtiEventsEventIdIssue,
  usePostDtiItemsAccountIdSellPrivately,
  usePostDtiListingsAccountIdDelete,
  usePostDtiListingsAccountIdIssue,
  usePostDtiPdfsAccountIdAttach,
  usePutDtiListingsAccountId,
  usePutDtiListingsAccountIdEditListing,
  usePutDtiListingsAccountIdEditState,
} from '../../api';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import {
  markListingsAtom,
  mergedEventListingsUnfilteredRulesAtom,
  searchParamsHistoryAtom,
  selectedEventAtom,
  selectedMergedListingAtom,
  updateListingsAtom,
} from '../../data/atoms';
import { TenantIdListingId } from '../../models/tenantIdListingId';
import { auth } from '../../data/atoms.auth';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { StringWithPipe } from '../../ts-utils';
import { BarkerEventListing } from '../../types';
import { seatRange } from '../../utils/formatters';
import { location_options, mapStringToDisclosures } from '../Purchase/Purchase.hooks';
import { useGlobalState } from '../../data/GlobalState';
import { delay } from '../../utils/delay';

export const DtiProperties = [
  { label: 'Section', value: 'section' },
  { label: 'Row', value: 'row' },
  { label: 'Location', value: 'location_id', key: 'location' },
  { label: 'Disclosures', value: 'disclosures', key: 'notes' },
  { label: 'External Notes', value: 'external_notes', key: 'notes' },
  { label: 'Internal Notes', value: 'internal_notes' },
  { label: 'CC Info', value: 'cc_info_card', key: 'cc_info_card' },
  { label: 'Purchaser', value: 'cc_info_purchaser', key: 'cc_info_purchaser' },
] as const;

export type DtiPropertyKeys = (typeof DtiProperties)[number]['value'];

const DTIInventoryState = () => {
  const navigate = useNavigate();
  const apiToken = useAtomValue(auth.apiTokenAtom);
  const updateLocalListing = useSetAtom(updateListingsAtom);
  const selectedListing = useAtomValue(selectedMergedListingAtom);
  const setSelectedEvent = useSetAtom(selectedEventAtom);
  const { isMobile } = useGlobalState('isMobile');

  // We might move these to a global handler in the future, but for now, the only interrupt is DTI-specific for onboarding
  const { data: interrupts } = useGetApiPrincipalsInterrupts({
    query: {
      enabled: !!apiToken && !isMobile,
      staleTime: Number.MAX_SAFE_INTEGER,
      cacheTime: Number.MAX_SAFE_INTEGER,
    },
  });

  useEffect(() => {
    if (interrupts?.data.some((i) => i.interruptTypeId === 'Onboarding')) {
      navigate('/onboarding');
    }
  }, [interrupts, navigate]);

  const { mutateAsync: sellPrivately, isLoading: isSellingPrivately } = usePostDtiItemsAccountIdSellPrivately();
  const { mutateAsync: bulkUpdateState, isLoading: isBulkUpdating } = usePutDtiListingsAccountIdEditState();
  const { mutateAsync: deleteListings, isLoading: isDeletingListings } = usePostDtiListingsAccountIdDelete();
  const { mutateAsync: reportListingIssue, isLoading: isReportingIssue } = usePostDtiListingsAccountIdIssue();
  const { mutateAsync: editDtiListing, isLoading: isEditLoading } = usePutDtiListingsAccountIdEditListing();
  const { mutateAsync: updateDtiListing, isLoading: isUpdatingListing } = usePutDtiListingsAccountId();
  const { mutateAsync: reportEventIssue } = usePostDtiEventsEventIdIssue();
  const { mutateAsync: savePdfsMutation, isLoading: isSavePdfsLoading } = usePostDtiPdfsAccountIdAttach();
  const {
    data: fetchListingResponse,
    refetch: refetchListing,
    isRefetching: isRefetchingListing,
  } = useGetApiInventoryListingsListingId(selectedListing?.listingId ?? '-1', {
    query: {
      enabled: false,
    },
    axios: {
      headers: {
        'x-tenant-id': selectedListing?.tenantId ?? '',
      },
    },
  });

  useEffect(() => {
    if (fetchListingResponse?.data) {
      updateLocalListing(fetchListingResponse.data);
    }
  }, [fetchListingResponse, updateLocalListing]);

  const vendorProperties = selectedListing?.vendorProperties as BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal | undefined;

  const savePdfs = useCallback(
    async ({ accountId, data }: { accountId: number; data: PostDtiPdfsAccountIdAttachBody }) => {
      await savePdfsMutation({ accountId, data });

      await refetchListing();
    },
    [savePdfsMutation, refetchListing],
  );
  const reportEventOccurred = useCallback(
    async (eventId: number) => {
      await reportEventIssue({
        eventId,
        data: {
          issue: 2,
        },
      });
    },
    [reportEventIssue],
  );

  const accountId = vendorProperties?.ownerId;

  const editListing = useCallback(
    async (changes: BarkerCoreModelsDTIBulkEditListingRequest, refetch: boolean = true) => {
      if (!selectedListing) return;

      await editDtiListing({
        accountId: accountId ?? 0,
        data: [changes],
      });
      if (refetch) {
        await refetchListing();
      }
    },
    [editDtiListing, selectedListing, refetchListing, accountId],
  );

  const reserveListing = useCallback(async () => {
    if (!accountId || !selectedListing) return;

    await bulkUpdateState({
      accountId,
      data: [
        {
          listing_id: selectedListing.listingId,
          checksum: vendorProperties.checksum,
          state: 1, // Reserved
        },
      ],
    });

    const response = await getApiInventory(
      {
        eventIds: [selectedListing.eventId],
        fromDate: dayjs(selectedListing.event.localDateTime).startOf('day').toDate(),
        toDate: dayjs(selectedListing.event.localDateTime).add(1, 'day').startOf('day').toDate(),
      },
      {
        headers: {
          'x-tenant-id': selectedListing.tenantId,
        },
      },
    );

    // Attempt to find the new listing. It should be the old listing ID with some alpha-numeric characters after it
    const newListing = response.data?.listings.find(
      (x) =>
        x.eventId === selectedListing.eventId &&
        x.tenantId === selectedListing.tenantId &&
        x.section === selectedListing.section &&
        x.row === selectedListing.row &&
        x.seatFrom === selectedListing.seatFrom &&
        x.seatThru === selectedListing.seatThru &&
        x.quantityReserved === selectedListing.quantityRemaining,
    );

    updateLocalListing({
      listingsToUpdate: response.data?.listings ?? [],
      listingsToRemove: [new TenantIdListingId(selectedListing.tenantIdListingId)],
      newSelectedListingId: `${newListing?.tenantId}|${newListing?.listingId}` as StringWithPipe,
    });
  }, [bulkUpdateState, selectedListing, vendorProperties?.checksum, updateLocalListing, accountId]);

  const unreserveListing = useCallback(async () => {
    if (!accountId || !selectedListing) return;
    await bulkUpdateState({
      accountId,
      data: [
        {
          listing_id: selectedListing.listingId,
          checksum: vendorProperties.checksum,
          state: 0, // Available
        },
      ],
    });

    const response = await getApiInventory(
      {
        eventIds: [selectedListing.eventId],
        fromDate: dayjs(selectedListing.event.localDateTime).startOf('day').toDate(),
        toDate: dayjs(selectedListing.event.localDateTime).add(1, 'day').startOf('day').toDate(),
      },
      {
        headers: {
          'x-tenant-id': selectedListing.tenantId,
        },
      },
    );

    // Attempt to find the new listing.
    const newListing = response.data?.listings.find(
      (x) =>
        x.eventId === selectedListing.eventId &&
        x.tenantId === selectedListing.tenantId &&
        x.section === selectedListing.section &&
        x.row === selectedListing.row &&
        x.seatFrom === selectedListing.seatFrom &&
        x.seatThru === selectedListing.seatThru &&
        x.quantityRemaining === selectedListing.quantityReserved,
    );

    updateLocalListing({
      listingsToUpdate: response.data?.listings ?? [],
      listingsToRemove: [new TenantIdListingId(selectedListing.tenantIdListingId)],
      newSelectedListingId: `${newListing?.tenantId}|${newListing?.listingId}` as StringWithPipe,
    });
  }, [accountId, bulkUpdateState, selectedListing, vendorProperties?.checksum, updateLocalListing]);

  const deleteListing = useCallback(async () => {
    if (!accountId || !selectedListing) return;
    await deleteListings({
      accountId,
      data: [
        {
          listing_id: selectedListing.listingId,
          checksum: vendorProperties.checksum,
          owner_id: accountId,
        },
      ],
    });

    updateLocalListing({ listingsToUpdate: [], listingsToRemove: [new TenantIdListingId(selectedListing.tenantIdListingId)] });
    setSelectedEvent(selectedListing.event);
  }, [accountId, selectedListing, deleteListings, vendorProperties?.checksum, updateLocalListing, setSelectedEvent]);

  const editSharing = useCallback(
    async (listing: BarkerEventListing, isBroadcasting: boolean, omittedMarketplaces: string, autoRefetchListing: boolean = true) => {
      if (!accountId || !listing) return;
      const changes = ['share_exchange', 'exchange_sharing', 'allowed_splits'];

      const { splitType } = listing.splits as BarkerCoreModelsInventoryDtiSplitRules;

      const splits = splitType === 'CUSTOM' ? 2 : splitType === 'NEVERLEAVEONE' ? -1 : splitType === 'NONE' ? 0 : splitType === 'ANY' ? 1 : 0;

      if (splits === -1) {
        changes.push('not_leave_one_share');
      } else if (splits === 0) {
        changes.push('no_share');
      } else if (splits === 1) {
        changes.push('any_share');
      } // Splits === 2 is allowed, but no special handling is needed

      await updateDtiListing({
        accountId,
        data: [
          {
            listing_id: listing.listingId,
            checksum: (listing.vendorProperties as BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal).checksum,
            exchange_sharing: omittedMarketplaces,
            share_exchange: isBroadcasting,
            share_splits: splits,
            user_initiated_changes: changes,
          },
        ],
      });

      if (autoRefetchListing) {
        await refetchListing();
      }
    },
    [accountId, updateDtiListing, refetchListing],
  );

  const markedListingIds = useAtomValue(markListingsAtom);
  const allListings = useAtomValue(mergedEventListingsUnfilteredRulesAtom);

  const editSharingBulk = useCallback(
    async (listing: BarkerEventListing, isBroadcasting: boolean, omittedMarketplaces: string) => {
      const updatedListings: BarkerCoreModelsInventoryListing[] = [];
      const markedListingsAndSelectedListing = markedListingIds.concat([listing.tenantIdListingId]);

      await Promise.all(
        markedListingsAndSelectedListing.map(async (id) => {
          const _listing = allListings.find((l) => l.tenantIdListingId === id)!;
          await editSharing(_listing, isBroadcasting, omittedMarketplaces, false);
          const updateListing = (await getApiInventoryListingsListingId(_listing.listingId)).data;
          updatedListings.push(updateListing);
        }),
      );

      if (updatedListings.length > 0) {
        updateLocalListing({ listingsToUpdate: updatedListings, listingsToRemove: [] });
      }
    },
    [allListings, editSharing, markedListingIds, updateLocalListing],
  );

  const searchParams = useAtomValue(searchParamsHistoryAtom);
  const checkForNewListing = useCallback((listings: BarkerCoreModelsInventoryListing[], section: string, row: string, seat: string) => {
    const newListing = listings.find((x) => x.section === section && x.row === row && x.seatFrom === seat);
    if (newListing) {
      return TenantIdListingId.create(newListing.tenantId, newListing.listingId);
    }
    return null;
  }, []);
  const findNewListingBySectionAndRowAndSeat = useCallback(
    async (eventId: string, oldListingId: string, section: string, row: string, seat: string) => {
      if (searchParams) {
        const response = await getApiInventory(
          {
            ...searchParams,
            eventIds: [eventId],
          },
          {
            headers: {
              'x-tenant-id': selectedListing?.tenantId,
            },
          },
        );
        if (response.data?.listings) {
          const oldListing = response.data.listings.find((x) => x.listingId === oldListingId);
          if (oldListing) {
            // TODO: Add a timeout for this so that it can't run forever.
            await delay(500);
            return findNewListingBySectionAndRowAndSeat(eventId, oldListingId, section, row, seat);
          }
          const newListing = checkForNewListing(response.data.listings, section, row, seat);
          if (newListing) {
            return { listingId: newListing, listings: response.data.listings };
          }
        }
      }
      return null;
    },
    [checkForNewListing, searchParams, selectedListing?.tenantId],
  );

  const editPropertiesBulk = useCallback(
    async (listing: BarkerEventListing, key: DtiPropertyKeys, value: string | string[]) => {
      let _value: string | string[] | number = value;
      let selectNewListingId: StringWithPipe | undefined;
      let updatedListings: BarkerCoreModelsInventoryListing[] = [];
      const markedListingIdsAndSelectedListingId = Array.from(new Set(markedListingIds.concat([listing.tenantIdListingId])));
      const listings = allListings.filter((l) => markedListingIdsAndSelectedListingId.includes(l.tenantIdListingId));
      const eventIds = Array.from(new Set(listings.map((l) => l.eventId)));
      const item = DtiProperties.find((x) => x.value === key);
      const property: string = item && 'key' in item ? item.key : key;
      const trackSelectedListingChanges = { listingId: '', section: '', row: '', seat: '' };
      const listingsToRemove: TenantIdListingId[] = [];

      await Promise.all(
        markedListingIdsAndSelectedListingId.map(async (id) => {
          const _listing = listings.find((l) => l.tenantIdListingId === id)!;
          const _vendorProperties = _listing.vendorProperties as BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal;

          if (key === 'disclosures' && _listing.externalNotes) {
            const { additionalText } = mapStringToDisclosures(_listing.externalNotes);
            _value = [...value, additionalText];
          }

          if (key === 'external_notes' && _listing.externalNotes && typeof value === 'string') {
            const { disclosures } = mapStringToDisclosures(_listing.externalNotes);
            _value = [...disclosures, value];
          }

          if (key === 'location_id') {
            _value = parseInt(value as string);
          }

          if (key === 'section' || key === 'row') {
            // TODO: Track the section and row changes to match selectedListing on the way back out
            if (_listing.section === selectedListing?.section && _listing.row === selectedListing?.row) {
              trackSelectedListingChanges.listingId = _listing.listingId;
              trackSelectedListingChanges.seat = _listing.seatFrom;
              trackSelectedListingChanges[key] = value as string;
              trackSelectedListingChanges[key === 'section' ? 'row' : 'section'] = key === 'section' ? _listing.row : (_listing.section as string);
            }
            listingsToRemove.push(new TenantIdListingId(_listing.tenantIdListingId));
          }

          await editListing(
            {
              checksum: _vendorProperties.checksum,
              listing_id: _listing.listingId,
              pdfs: [],
              barcodes: [],
              transferURLs: [],
              tags: [],
              [property]: Array.isArray(_value) ? _value.join(' ') : _value,
              ...(key === 'location_id' ? { location_from: parseInt(location_options.find((x) => x.label === _vendorProperties.location)!.value) } : {}),
              ...(key === 'location_id' ? { location_id: _value } : {}),
              ...(key === 'location_id' ? { notes: _listing.externalNotes } : {}),
              user_initiated_changes: [property, ...(key === 'location_id' ? ['location_from'] : [])],
              original: {
                event_id: parseInt(_listing?.eventId),
                section: _listing.section,
                row: _listing.row,
                location_id: parseInt(location_options.find((x) => x.label.toUpperCase() === _vendorProperties.location?.toUpperCase())?.value ?? '0'),
                seat_from: parseInt(_listing.seatFrom),
                seat_list: seatRange(parseInt(_listing.seatFrom), _listing.quantityRemaining!, _vendorProperties.isOddEven),
                odd_even: _vendorProperties?.isOddEven ? 1 : 0,
                quantity: _listing.quantityRemaining!,
              },
            },
            false,
          );

          if (listingsToRemove.length === 0) {
            const updateListing = (await getApiInventoryListingsListingId(_listing.listingId)).data;
            updatedListings.push(updateListing);
          }
        }),
      );

      if (listingsToRemove.length > 0 && searchParams) {
        await Promise.all(
          eventIds.map(async (eventId) => {
            const response = await getApiInventory(
              {
                ...searchParams,
                eventIds: [eventId],
              },
              {
                headers: {
                  'x-tenant-id': selectedListing?.tenantId,
                },
              },
            );
            updatedListings.push(...(response.data?.listings ?? []));
          }),
        );
      }

      if (!updatedListings.find((x) => x.listingId === trackSelectedListingChanges.listingId)) {
        const newListingCheck = checkForNewListing(updatedListings, trackSelectedListingChanges.section, trackSelectedListingChanges.row, trackSelectedListingChanges.seat);
        if (newListingCheck) {
          selectNewListingId = newListingCheck.toStringTyped();
        }
      }

      if (!selectNewListingId && selectedListing && trackSelectedListingChanges.listingId) {
        const _listing = await findNewListingBySectionAndRowAndSeat(
          selectedListing.eventId,
          trackSelectedListingChanges.listingId,
          trackSelectedListingChanges.section,
          trackSelectedListingChanges.row,
          trackSelectedListingChanges.seat,
        );
        if (_listing) {
          selectNewListingId = _listing.listingId.toStringTyped();
          updatedListings = updatedListings.filter((x) => x.eventId !== _listing.listings[0].eventId);
          updatedListings.push(..._listing.listings);
        }
      }

      updateLocalListing({ listingsToUpdate: updatedListings, listingsToRemove, newSelectedListingId: selectNewListingId });
    },
    [allListings, checkForNewListing, editListing, findNewListingBySectionAndRowAndSeat, markedListingIds, searchParams, selectedListing, updateLocalListing],
  );

  return {
    selectedListing,
    sellPrivately,
    isSellingPrivately,
    reserveListing,
    unreserveListing,
    isBulkUpdating: isBulkUpdating || isRefetchingListing,
    deleteListing,
    isDeletingListings,
    reportIssue: reportListingIssue,
    isReportingIssue,
    accountId,
    editListing,
    isEditLoading: isEditLoading || isRefetchingListing,
    vendorProperties,
    editSharing,
    editSharingBulk,
    isUpdatingListing: isUpdatingListing || isRefetchingListing,
    reportEventOccurred,
    savePdfs,
    isSavePdfsLoading: isSavePdfsLoading || isRefetchingListing,
    editPropertiesBulk,
  };
};

export const { Provider: DTIInventoryStateProvider, useSelector: useDTIInventory } = yasml(DTIInventoryState);
