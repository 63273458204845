import { IconPropsBase } from './IconPropsBase';

export default function MarketplaceGoTicketsIcon({ size = 24, color = 'currentColor' }: IconPropsBase) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <path
        fill={color}
        d="M11.49,11.04h-5.12v1.9h2.98v.65c0,.4-.26.66-.66.66h-3.87c-.41,0-.67-.26-.67-.66v-3.19c0-.41.26-.68.67-.68h6.36v-1.9h-6.63c-1.53,0-2.54,1.02-2.54,2.54v3.27c0,1.52,1.02,2.54,2.54,2.54h4.41c1.53,0,2.53-1.02,2.53-2.54v-2.59ZM15.05,16.17h4.41c1.53,0,2.53-1.02,2.53-2.54v-3.27c0-1.52-1-2.54-2.53-2.54h-4.41c-1.53,0-2.54,1.02-2.54,2.54v3.27c0,1.52,1.02,2.54,2.54,2.54ZM15.33,14.25c-.41,0-.67-.26-.67-.66v-3.19c0-.41.26-.68.67-.68h3.87c.4,0,.66.26.66.68v3.19c0,.4-.26.66-.66.66h-3.87Z"
      />
    </svg>
  );
}
