import { ActionIcon, Grid, Group, Skeleton, Space, Text } from '@mantine/core';
import ArrowBackIcon from '../../components/icons/ArrowBack';
import ArrowForwardIcon from '../../components/icons/ArrowForward';
import BNText from '../../components/Text/BNText';
import { useAtomValue } from 'jotai';
import { selectedMergedListingAtom } from '../../data/atoms';
import { useNowPricing } from './NowPricing.hooks';
import { formatSeatRange } from '../../utils/formatters';
import { useFlag } from '@unleash/proxy-client-react';

export const ReadOnly = () => {
  const selectedListing = useAtomValue(selectedMergedListingAtom);
  const { prevListing, nextListing } = useNowPricing('prevListing', 'nextListing');
  const { section, row, seatFrom, seatThru, quantityRemaining, unitCost } = selectedListing || {};
  const seats = seatFrom ? formatSeatRange(seatFrom, seatThru, quantityRemaining) : null;
  const hideCostFlag = useFlag('hide-cost');
  return (
    <>
      <Group pos="absolute" top={4} right={8} gap="md">
        <ActionIcon onClick={prevListing}>
          <ArrowBackIcon size={24} />
        </ActionIcon>
        <ActionIcon onClick={nextListing}>
          <ArrowForwardIcon size={24} />
        </ActionIcon>
      </Group>
      <Space h="xs" />
      <Grid>
        <Grid.Col span={7}>
          <Text lh={1} size="xs" c="var(--colors-gray-5)">
            Section
          </Text>
          {section ? <Text size="md">{section}</Text> : <Skeleton height={8} my={8.4} width={100} />}
        </Grid.Col>
        <Grid.Col span={5}>
          <Text lh={1} size="xs" c="var(--colors-gray-5)">
            Row
          </Text>
          {row ? <Text size="md">{row}</Text> : <Skeleton height={8} my={8.4} width={100} />}
        </Grid.Col>
      </Grid>
      <Space h="xs" />
      <Grid>
        <Grid.Col span={4}>
          <Text lh={1} size="xs" c="var(--colors-gray-5)">
            Seats
          </Text>
          {seats ? <Text size="md">{seats}</Text> : <Skeleton height={8} my={8.4} width={60} />}
        </Grid.Col>
        <Grid.Col span={3}>
          <Text lh={1} size="xs" c="var(--colors-gray-5)">
            Qty
          </Text>
          {quantityRemaining ? (
            <Text size="md">
              <span>{quantityRemaining}</span>
            </Text>
          ) : (
            <Skeleton height={8} my={8.4} width={50} />
          )}
        </Grid.Col>
        {!hideCostFlag && (
          <Grid.Col span={5}>
            <Text lh={1} size="xs" c="var(--colors-gray-5)">
              Unit Cost
            </Text>
            {unitCost !== undefined ? (
              <BNText currency size="md">
                {unitCost}
              </BNText>
            ) : (
              <Skeleton height={8} my={8.4} width={80} />
            )}
          </Grid.Col>
        )}
      </Grid>
    </>
  );
};
