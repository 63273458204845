import { Flex, Group, Text, UnstyledButton, useComputedColorScheme } from '@mantine/core';
import { useLocalStorage, useResizeObserver } from '@mantine/hooks';
import React, { useMemo, useRef } from 'react';
import { FixedSizeList } from 'react-window';
import SearchIcon from '../../components/icons/Search';
import BNOrderSelect from '../../components/Select/OrderSelect';
import { useSearchResults } from './SearchResults.hooks';
import pluralize from 'pluralize';
import { useGlobalState } from '../../data/GlobalState';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import StadiumIcon from '../../components/icons/Stadium';
import classes from './SearchResults.Mobile.module.css';
import { useNavigate } from 'react-router-dom';
import BNPullToRefresh from '../../components/PullToRefresh/PullToRefresh';
import objectHash from 'object-hash';
import { EventCardColors } from './SearchResults.EventCardColors';
import { EventCardSkeleton } from './SearchResults.EventCardSkeleton';

export function SearchResultsMobile({ openSearch }: { openSearch: () => void }) {
  const {
    sortedData: data,
    isLoading,
    sort,
    sortOptions,
    setSort,
    sortOrder,
    setSortOrder,
    onEventClicked,
    search,
    form,
  } = useSearchResults('sortedData', 'isLoading', 'sort', 'sortOptions', 'setSort', 'sortOrder', 'setSortOrder', 'onEventClicked', 'search', 'form');
  // const parentRef = useRef<HTMLDivElement>(null);
  const [parentRef, parentRect] = useResizeObserver();
  const [isSortOptionsOpen, setIsSortOptionsOpen] = React.useState(false);
  // https://mantine.dev/hooks/use-resize-observer/
  const { principal, tenants } = useGlobalState('principal', 'tenants');
  const computedColorScheme = useComputedColorScheme();

  const navigate = useNavigate();

  const scrollAreaWrapperRef = useRef<HTMLDivElement>(null);

  const dataHash = objectHash(data);

  const [savedScrollOffset, setSavedScrollOffset] = useLocalStorage<{ hash: string; offset: number }>({
    key: 'mobile-search-results-scroll-offset',
    getInitialValueInEffect: false,
    defaultValue: { hash: dataHash, offset: 0 },
  });

  const scrollOffset = savedScrollOffset.hash === dataHash ? savedScrollOffset.offset : 0;

  const Row = useMemo(
    () =>
      ({ index, style }: { index: number; style: React.CSSProperties }) => {
        const event = data[index];
        const tenantName = tenants?.find((t) => t.tenantId === event?.tenantId)?.name || 'Unknown';
        const tenantColor = principal?.settings?.tenantColors[event.tenantId] ?? 'var(--colors-brandcolor-5)';
        const isMultiTenant = tenants && tenants.length > 1;
        const isSelected = false; // We don't show the selected state on mobile

        return (
          <div style={style}>
            <>
              <UnstyledButton
                className={classes.overlayLink}
                onClick={(e) => {
                  onEventClicked(event, e);
                  navigate(`/mobile/events/${event.eventId}/listings?tenantId=${event.tenantId}`);
                }}
              />
              <EventCardColors event={event} isSelected={isSelected} tenantName={tenantName} tenantColor={tenantColor} isMultiTenant={isMultiTenant} />
            </>
          </div>
        );
      },
    [data, principal?.settings?.tenantColors, tenants, navigate, onEventClicked],
  );

  return (
    <>
      <Group className={`resultsArea ${classes.resultsAreaWrapper}`} px={16} py={0}>
        <Group align="center" gap={2} className={classes.sortSelect}>
          <Text className={classes.selectLabel}>Sort:</Text>
          <BNOrderSelect
            data={sortOptions.map((x) => x)}
            isMobile
            dropdownWidth={200}
            selectedItem={sort}
            isOpen={isSortOptionsOpen}
            sortOrder={sortOrder}
            onClose={() => setIsSortOptionsOpen(false)}
            onClick={() => setIsSortOptionsOpen(!isSortOptionsOpen)}
            onSortOrderChange={(value: 'asc' | 'desc') => {
              setSortOrder(value);
              setIsSortOptionsOpen(false);
            }}
            onSelectionChange={(value) => {
              setSort(value as (typeof sortOptions)[number]);
              setIsSortOptionsOpen(false);
            }}
          />
        </Group>
        <Group gap={8} align="center" className={classes.eventsLabel}>
          <StadiumIcon color="var(--colors-gray-5)" />
          <Text fz={13} c="var(--colors-gray-5)">
            {data ? data.length : 0} {pluralize('Event', data ? data.length : 0)}
          </Text>
        </Group>
      </Group>
      <div className={classes.searchResultsOuterWrap} ref={parentRef}>
        <div className={classes.searchResultsInnerWrap} ref={scrollAreaWrapperRef} style={{ background: 'var(--colors-divider)' }}>
          <BNPullToRefresh wrapperRef={scrollAreaWrapperRef} listClass="searchResultsList" refreshAction={() => search(form.values)} isRefreshing={isLoading ?? false} />
          {data && data.length > 0 ? (
            <FixedSizeList
              onScroll={(props) => setSavedScrollOffset({ hash: dataHash, offset: props.scrollOffset })}
              initialScrollOffset={scrollOffset}
              className="searchResultsList"
              height={parentRect.height || 0}
              itemCount={data.length}
              itemSize={140}
              overscanCount={10}
              layout="vertical"
              width="100%"
            >
              {Row}
            </FixedSizeList>
          ) : isLoading ? (
            <SearchResultsMobile.EventCardSkeleton isMobile />
          ) : (
            <Flex h="100%" bg="var(--colors-paper)" style={{ borderTop: '8px solid var(--colors-divider)' }}>
              <BNEmptyState
                h="90%"
                border={false}
                icon={<SearchIcon color="var(--colors-iconFill)" size={28} />}
                title="No Events Found"
                description="Try changing your search filters by tapping the button below"
                buttonText="Change search filters"
                buttonVariant="filled"
                buttonColor="green"
                buttonOnClick={openSearch}
              />
            </Flex>
          )}
        </div>
      </div>
    </>
  );
}

SearchResultsMobile.EventCardSkeleton = EventCardSkeleton;
