import { ActionIcon, Box, Center, RingProgress, Tooltip } from '@mantine/core';
import { BNSwitch } from '../../components/Switch/Switch';
import { useToggle } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { useSales } from './Sales.hooks';
import RefreshIcon from '../../components/icons/Refresh';

const DEFAULT_AUTO_TIMER = 60;

export function AutoRefresh() {
  const [autoEnabled, toggleAutoEnabled] = useToggle();
  const { refetchSales, isRefetching } = useSales('refetchSales', 'isRefetching');

  const [autoTimer, setAutoTimer] = useState<number>(DEFAULT_AUTO_TIMER);
  useEffect(() => {
    let timer: NodeJS.Timeout;

    function runTimer() {
      timer = setTimeout(() => {
        setAutoTimer((t) => {
          if (t === 0) {
            refetchSales();
            return DEFAULT_AUTO_TIMER;
          }
          return t - 1;
        });
        runTimer();
      }, 1000);
    }

    if (autoEnabled && !isRefetching) {
      runTimer();
    }
    return () => clearTimeout(timer);
  }, [autoEnabled, isRefetching, refetchSales]);
  return (
    <>
      {autoEnabled && !isRefetching ? (
        <Box w={28} h={28} fz="10" fw={600} lh={1.3} ta="center" c="var(--colors-gray-5)" style={{ flexShrink: 0 }} pos="relative">
          <RingProgress
            rootColor="var(--colors-gray-2)"
            size={28}
            thickness={1.5}
            sections={[{ value: (autoTimer / DEFAULT_AUTO_TIMER) * 100, color: 'var(--colors-gray-4)' }]}
            label={<Center>{autoTimer}</Center>}
            pos="absolute"
            inset={0}
          />
        </Box>
      ) : (
        <Tooltip label="Refresh Sales" position="top" withArrow>
          <ActionIcon data-pendo-name="Sales Grid Refresh Button" onClick={() => refetchSales()} loading={isRefetching} loaderProps={{ type: 'oval', color: 'gray' }}>
            <RefreshIcon size={22} />
          </ActionIcon>
        </Tooltip>
      )}
      <Tooltip label="Auto-Refresh" position="top" withArrow>
        <Box w={69}>
          <BNSwitch
            size="xs"
            checked={autoEnabled}
            onChange={() => toggleAutoEnabled()}
            data-pendo-name="Sales Grid Auto-Refresh Switch"
            colorLabel
            label={
              <Box w={28} fz="10" lh={1.3} ta="center" style={{ flex: 1 }}>
                AUTO
              </Box>
            }
          />
        </Box>
      </Tooltip>
    </>
  );
}
