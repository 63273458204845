import React from 'react';
import ReactDOM from 'react-dom/client';
import './dev';
import { queryClient } from './data/api-config';
import { Auth0ProviderWithNavigate } from './components/Auth0ProviderWithNavigate';
import { QueryClientProvider } from '@tanstack/react-query';
import { AppThemeWrapper } from './components/AppThemeWrapper';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStateProvider } from './data/GlobalState';
import { FlagProvider, IConfig } from '@unleash/proxy-client-react';
import structuredClone from '@ungap/structured-clone';
import { ResizeObserver } from '@juggle/resize-observer';
import { datadogRum } from '@datadog/browser-rum';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './components/ErrorPage/ErrorPage';
import { App } from './App';
import { Tooltip } from 'react-tooltip';

if (!('structuredClone' in globalThis)) {
  // @ts-ignore
  globalThis.structuredClone = structuredClone;
}
if (!('ResizeObserver' in globalThis)) {
  // @ts-ignore
  globalThis.ResizeObserver = ResizeObserver;
}

const unleashConfig = {
  url: import.meta.env.VITE_UNLEASH_URL,
  clientKey: 'barker-client-key',
  refreshInterval: 10000,
  appName: 'barker',
} satisfies IConfig;

if (import.meta.env.VITE_DATADOG_ENVIRONMENT === 'production' || import.meta.env.VITE_DATADOG_ENVIRONMENT === 'testing') {
  datadogRum.init({
    applicationId: 'ce132033-9357-4e2f-b863-598b8c6bf086',
    clientToken: 'pub359a3b6f22777df8bf668d225877d0de',
    site: 'datadoghq.com',
    service: 'barker',
    env: import.meta.env.VITE_DATADOG_ENVIRONMENT,
    version: import.meta.env.VITE_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });

  datadogRum.startSessionReplayRecording();
}
const Main = (
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <AppThemeWrapper>
        <Auth0ProviderWithNavigate>
          <FlagProvider config={unleashConfig}>
            <ErrorBoundary
              FallbackComponent={(context) => (
                <ErrorPage
                  title="Something went wrong"
                  content="The pricer encountered an unexpected error. Information regarding this error was logged. If you experience this regularly, we encourage you to contact support."
                  report
                  error={context.error}
                />
              )}
            >
              <GlobalStateProvider>
                <App />
              </GlobalStateProvider>
            </ErrorBoundary>
          </FlagProvider>
        </Auth0ProviderWithNavigate>
      </AppThemeWrapper>
    </BrowserRouter>
    {import.meta.env.VITE_REACT_QUERY_DEVTOOLS && <ReactQueryDevtools initialIsOpen={false} />}
    <Tooltip id="tooltip-global" className="bn-react-tooltip" classNameArrow="bn-react-tooltip-arrow" style={{ zIndex: 99999 }} />
  </QueryClientProvider>
  // </React.StrictMode>
);
ReactDOM.createRoot(document.getElementById('root')!).render(Main);
