import { IconPropsBase } from './IconPropsBase';

export default function ReplyIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M760-220.001V-360q0-53.846-38.077-91.924-38.077-38.077-91.924-38.077H254.922l154 154.001-42.768 42.153L140.001-520l226.153-226.153L408.922-704l-154 154.001h375.077q78.769 0 134.384 55.615Q819.999-438.769 819.999-360v139.999H760Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M744-230.001V-354q0-53.846-38.077-91.924-38.077-38.077-91.924-38.077H263.922l133 133.001-36.768 37.153L164.001-510l196.153-196.153L396.922-669l-133 133.001h350.077q75.769 0 128.884 53.115Q795.999-429.769 795.999-354v123.999H744Z"
        />
      </svg>
    );
  }
}
