import { Box } from '@mantine/core';
import { useState } from 'react';
import { useInventory } from '../Inventory/Inventory.hooks';
import { ListingCard } from './ListingCard';
import { BarkerEventListing } from '../../types';

export type ListingCardWrapperProps = {
  listing: BarkerEventListing;
  updatePrice: (tenantId: string, listingId: string, price: number, previousPrice: number) => void;
  scrollAreaRef: React.RefObject<HTMLDivElement>;
};

export function ListingCardWrapper({ listing, updatePrice, scrollAreaRef }: ListingCardWrapperProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { getDetailRowDataResults } = useInventory('getDetailRowDataResults');
  const groupListings = isExpanded ? getDetailRowDataResults(listing.listingId, listing.ruleId) : [];
  return (
    <>
      <ListingCard isExpanded={isExpanded} setIsExpanded={setIsExpanded} listing={listing} updatePrice={updatePrice} scrollAreaRef={scrollAreaRef} />
      {isExpanded && (
        <Box w="100%" p={8} bg="var(--colors-divider)">
          {groupListings.map((_listing) => (
            <ListingCard isInGroup key={_listing.listingId} listing={_listing} />
          ))}
        </Box>
      )}
    </>
  );
}
