import { Box, Checkbox, CheckboxProps } from '@mantine/core';
import { ReactNode, forwardRef } from 'react';
import RemoveBoldIcon from '../icons/RemoveBold';
import CheckIcon from '../icons/Check';
import classes from './GridCheckbox.module.css';

type BNCheckboxProps = {
  inverted?: boolean; // filled background when selected
  group?: boolean; // adds depth to indicate it is a group that will be selected
  inlay?: ReactNode;
} & CheckboxProps;

/**
 * Checkbox
 */
export const BNGridCheckbox = forwardRef<HTMLInputElement, BNCheckboxProps>(({ icon, inverted, group, className, ...props }, ref) => {
  const CheckboxIcon: CheckboxProps['icon'] = ({ indeterminate, className }) =>
    indeterminate ? (
      <>
        <Box className={`${classes.customCheckIcon} mantine-Checkbox-icon`}>
          <RemoveBoldIcon />
        </Box>
        {group && <Box className="checkbox-groupBg" />}
      </>
    ) : (
      <>
        <Box className={`${classes.customCheckIcon} mantine-Checkbox-icon`}>
          <CheckIcon />
        </Box>
        {group && <Box className="checkbox-groupBg" />}
      </>
    );

  const InlayFc = () => {
    return (
      <Box className="text-inlay" component="span" pos="absolute" inset={0}>
        {props.inlay}
        {group && <Box className="checkbox-groupBg" />}
      </Box>
    );
  };

  return (
    <Checkbox
      {...props}
      ref={ref}
      size="xs"
      className={inverted ? `${classes.invertedGridCheckbox} inverted-check ${className ? className : ''}` : `${classes.gridCheckbox} ${className ? className : ''}`}
      icon={props.checked ? icon || CheckboxIcon : InlayFc || undefined}
    />
  );
});
