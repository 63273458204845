/* eslint-disable max-classes-per-file */
import { StringWithPipe } from '../ts-utils';

export function ensureContainsPipe(s: string): StringWithPipe {
  if (!s.includes('|')) {
    throw new Error('String must contain a |');
  }
  return s as StringWithPipe;
}
export class TenantIdEventId {
  public eventId: string = '';
  public tenantId: string = '';
  constructor(input: StringWithPipe | null | undefined) {
    if (input) {
      const [tenantId, eventId] = ensureContainsPipe(input).split('|');
      this.tenantId = tenantId;
      this.eventId = eventId;
    }
  }
  hasValue() {
    return this.tenantId && this.eventId;
  }
  toString() {
    return `${this.tenantId}|${this.eventId}`;
  }
  toStringTyped() {
    return `${this.tenantId}|${this.eventId}` as StringWithPipe;
  }
  static create(tenantId: string, eventId: string): TenantIdEventId {
    return new TenantIdEventId(`${tenantId}|${eventId}` as StringWithPipe);
  }
  static fromString(input: string | null | undefined): TenantIdEventId {
    if (input) {
      if (ensureContainsPipe(input)) {
        return new TenantIdEventId(input as StringWithPipe);
      }
    }
    throw new Error('Invalid input');
  }
}

export class TenantIdListingId {
  public listingId: string = '';
  public tenantId: string = '';
  constructor(input: StringWithPipe | null | undefined) {
    if (input) {
      const [tenantId, listingId] = ensureContainsPipe(input).split('|');
      this.tenantId = tenantId;
      this.listingId = listingId;
    }
  }
  hasValue() {
    return this.tenantId && this.listingId;
  }
  toString() {
    return `${this.tenantId}|${this.listingId}`;
  }
  toStringTyped() {
    return `${this.tenantId}|${this.listingId}` as StringWithPipe;
  }
  static create(tenantId: string, listingId: string): TenantIdListingId {
    return new TenantIdListingId(`${tenantId}|${listingId}` as StringWithPipe);
  }
  static fromString(input: string | null | undefined): TenantIdListingId {
    if (input) {
      if (ensureContainsPipe(input)) {
        return new TenantIdListingId(input as StringWithPipe);
      }
    }
    throw new Error('Invalid input');
  }
  static from(tenantId: string, listingId: string): TenantIdListingId {
    return new TenantIdListingId(`${tenantId}|${listingId}` as StringWithPipe);
  }
}
