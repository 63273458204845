import TicketVolumeAndPrice from '../../components/Charts/TicketVolumeAndPrice';
import { useMarketplaceTrends } from './MarketplaceTrends.hooks';

export function MarketplaceTrends() {
  const { isLoading, snapshotPeriod, setSnapshotPeriod, data, setShowMedPrice, setShowMinPrice, setShowVolume, showMedPrice, showMinPrice, showVolume } = useMarketplaceTrends(
    'isLoading',
    'snapshotPeriod',
    'setSnapshotPeriod',
    'data',
    'setShowMedPrice',
    'setShowMinPrice',
    'setShowVolume',
    'showMedPrice',
    'showMinPrice',
    'showVolume',
  );

  return (
    <TicketVolumeAndPrice
      title="Trends"
      data={data}
      isLoading={isLoading}
      showMedPrice={showMedPrice}
      setShowMedPrice={setShowMedPrice}
      showMinPrice={showMinPrice}
      setShowMinPrice={setShowMinPrice}
      showVolume={showVolume}
      setShowVolume={setShowVolume}
      timePeriod={snapshotPeriod}
      setTimePeriod={setSnapshotPeriod}
    />
  );
}
