import { BarkerEventListing } from '../../types';
import { GridApi, ICellRendererParams, IRowNode } from '@ag-grid-community/core';
import { useCallback, useEffect, useRef } from 'react';
import { useBulkState } from '../../data/BulkState';
import { BNGridCheckbox } from '../../components/GridCheckbox/GridCheckbox';
import { useAtomValue } from 'jotai';
import { selectedTenantListingIdAtom } from '../../data/atoms';
import { Text } from '@mantine/core';
import { TenantIdListingId } from '../../models/tenantIdListingId';
import { range } from '../../utils/price-utils';

type Props = {
  listingId: string;
  tenantId: string;
  isPrimaryListing?: boolean;
  bulkMode?: boolean;
  ruleCount: number;
  level: number;
  node: IRowNode<BarkerEventListing> & { beans?: { gridApi: GridApi<BarkerEventListing> } };
  registerRowDragger: ICellRendererParams['registerRowDragger'];
  isListingOnHold: boolean;
};


export const DragCell = ({ tenantId, listingId, isPrimaryListing: primaryListing, isListingOnHold, node, level, ruleCount, bulkMode = false, registerRowDragger }: Props) => {
  const { markedListings, setMarkedListings } = useBulkState('markedListings', 'setMarkedListings');
  const selectedTenantListingId = useAtomValue(selectedTenantListingIdAtom);
  const currentTenantIdListingId = TenantIdListingId.fromString(`${tenantId}|${listingId}`);

  const dragRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (dragRef.current) {
      registerRowDragger(dragRef.current, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleChecked = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      if (event.shiftKey) {
        const lastIndex = node.beans?.gridApi.getRowNode(selectedTenantListingId.toString())?.rowIndex!;
        const currentIndex = node.rowIndex!;
        const listingIdsInRange = range(lastIndex, currentIndex)
          .map((i) => node.beans?.gridApi.getDisplayedRowAtIndex(i)?.data?.tenantIdListingId)
          .filter((x) => x != null && !x.endsWith('sales')) as string[];
        setMarkedListings((prev) => [...prev, ...listingIdsInRange]);
      } else {
        setMarkedListings(currentTenantIdListingId.toString());
      }
    },
    [currentTenantIdListingId, node.beans?.gridApi, node.rowIndex, selectedTenantListingId, setMarkedListings],
  );

  if ((bulkMode && listingId.includes('sales')) || isListingOnHold) {
    return null;
  }

  if (bulkMode && level === 1) {
    if (selectedTenantListingId.toString() === currentTenantIdListingId.toString()) {
      return <BNGridCheckbox checked readOnly pt={0} pl={4} size="xs" inverted group={ruleCount > 1} />;
    }
    return (
      <BNGridCheckbox
        onClick={toggleChecked}
        readOnly
        checked={markedListings.includes(currentTenantIdListingId.toString())}
        pt={0}
        pl={4}
        size="xs"
        inverted={primaryListing}
        group={ruleCount > 1}
        inlay={
          ruleCount > 1 ? (
            <Text fz={11} color="gray.4" style={{ pointerEvents: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {ruleCount}
            </Text>
          ) : undefined
        }
        aria-label={`Mark listing ${listingId}`}
      />
    );
  }
  if (ruleCount && level === 1 && ruleCount > 1) {
    return (
      <button
        type="button"
        tabIndex={-1}
        aria-label={`Expand group ${listingId}`}
        onClick={(e) => {
          e.preventDefault();
          node.setExpanded(!node?.expanded);
        }}
        className="custom-icon-grip"
      >
        {ruleCount}
      </button>
    );
  }
  return (
    <div className="ag-cell-wrapper wow" role="presentation">
      <div className="ag-drag-handle ag-row-drag" ref={dragRef}>
        <span className="ag-icon ag-icon-grip" />
      </div>
      <span className="ag-cell-value" />
    </div>
  );
};
