import { useState } from 'react';
import { useNowPricing } from './NowPricing.hooks';
import { Box, SimpleGrid, Text } from '@mantine/core';
import { StaggerByValueInput } from '../../components/AdjustmentInput/StaggerByValueInput';
import { BNNumberInput } from '../../components/NumberInput/NumberInput';
import { AdjustmentValueInputDiagonal } from '../../components/AdjustmentInput/AdjustmentValueInputDiagonal';
import { BNSelect } from '../../components/Select/Select';
import BNInputGroup from '../../components/Group/InputGroup';

export const ScheduledForm = () => {
  const { form, isGroupedListing, isFormReady, setAutoUpdateRuleOnTargetComparableChanges, onSave } = useNowPricing(
    'form',
    'isGroupedListing',
    'isFormReady',
    'setAutoUpdateRuleOnTargetComparableChanges',
    'onSave',
  );

  const [intervalPeriod, setIntervalPeriod] = useState<'Min' | 'Hr' | 'Day'>(
    form.values.intervalMinutes === undefined ? 'Min' : form.values.intervalMinutes % 1440 === 0 ? 'Day' : form.values.intervalMinutes % 60 === 0 ? 'Hr' : 'Min',
  );
  const [intervalValue, setIntervalValue] = useState<number>(
    form.values.intervalMinutes === undefined
      ? 0
      : form.values.intervalMinutes % 1440 === 0
        ? form.values.intervalMinutes / 1440
        : form.values.intervalMinutes % 60 === 0
          ? form.values.intervalMinutes / 60
          : form.values.intervalMinutes,
  );
  return (
    <Box>
      <SimpleGrid cols={2} spacing="sm" my={8}>
        <AdjustmentValueInputDiagonal
          label="Adjustment"
          numInputWidth={78}
          disabled={!isFormReady}
          error={form.errors.adjustmentValue}
          onChange={(value) => {
            form.getInputProps('adjustmentValue').onChange(value.value);
            form.getInputProps('adjustmentTypeId').onChange(value.type);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onSave();
            }
          }}
          value={form.values.adjustmentValue || 0}
          type={form.values.adjustmentTypeId || 'Amount'}
        />
        <BNInputGroup label="Interval" error={form.errors.intervalMinutes}>
          <BNInputGroup.Item>
            <BNNumberInput
              decimalScale={0}
              step={1}
              min={0}
              error={!!form.errors.intervalMinutes}
              size="xs"
              label="Interval"
              value={intervalValue}
              onChange={(e) => {
                if (e === '') {
                  setIntervalValue(0);
                  form.setFieldValue('intervalMinutes', 0);
                } else {
                  setIntervalValue(e);
                  form.setFieldValue('intervalMinutes', e * (intervalPeriod === 'Min' ? 1 : intervalPeriod === 'Hr' ? 60 : 1440));
                }
              }}
            />
          </BNInputGroup.Item>
          <BNInputGroup.Item>
            <BNSelect
              data={['Min', 'Hr', 'Day']}
              size="xs"
              value={intervalPeriod}
              aria-label="Interval Period"
              onChange={(e) => {
                if (e === 'Min' || e === 'Hr' || e === 'Day') {
                  setIntervalPeriod(e);
                  form.setFieldValue('intervalMinutes', intervalValue * (e === 'Min' ? 1 : e === 'Hr' ? 60 : 1440));
                } else {
                  setIntervalPeriod('Min');
                  form.setFieldValue('intervalMinutes', intervalValue);
                }
              }}
            />
          </BNInputGroup.Item>
        </BNInputGroup>
      </SimpleGrid>
      <SimpleGrid mb={8} cols={2} spacing="sm">
        <BNNumberInput
          selectOnFocus
          disabled={!isFormReady}
          leftSection={<Text size="xs">$</Text>}
          {...form.getInputProps('floorPrice')}
          onChange={(value) => {
            setAutoUpdateRuleOnTargetComparableChanges(false);
            form.getInputProps('floorPrice').onChange(value);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onSave();
            }
          }}
          label="Floor"
          decimalScale={2}
          fixedDecimalScale
          step={1}
          stepHoldDelay={500}
          stepHoldInterval={100}
          min={0}
          size="xs"
        />
        <BNNumberInput
          selectOnFocus
          disabled={!isFormReady}
          leftSection={<Text size="xs">$</Text>}
          {...form.getInputProps('ceilingPrice')}
          onChange={(value) => {
            setAutoUpdateRuleOnTargetComparableChanges(false);
            form.getInputProps('ceilingPrice').onChange(value);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onSave();
            }
          }}
          label="Ceiling"
          decimalScale={2}
          fixedDecimalScale
          step={1}
          stepHoldDelay={500}
          stepHoldInterval={100}
          min={0}
          size="xs"
        />
      </SimpleGrid>
      {isGroupedListing && (
        <SimpleGrid mb={8} cols={2} spacing="sm">
          <BNNumberInput disabled={!isFormReady} {...form.getInputProps('numActive')} label="# Active" decimalScale={0} allowDecimal={false} min={0} step={1} size="xs" />
          <StaggerByValueInput
            numInputWidth={78}
            disabled={!isFormReady}
            label="Stagger By"
            onChange={(value) => {
              form.getInputProps('staggerByValue').onChange(value.value);
              form.getInputProps('staggerByTypeId').onChange(value.type);
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                onSave();
              }
            }}
            value={form.values.staggerByValue || 0}
            type={form.values.staggerByTypeId || 'Amount'}
          />
        </SimpleGrid>
      )}
    </Box>
  );
};
