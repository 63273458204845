import millify from 'millify';
import { useMemo } from 'react';
import PaidIcon from '../icons/Paid';
import { BNLabel } from './Label';
import { formatCurrency } from '../../utils/formatters';
import classes from './ListingCostLabel.module.css';

export const ListingCostIconLabel = ({ cost, className, skeleton }: { className?: string; cost: number; skeleton?: boolean }) => {
  const text = useMemo(() => (cost >= 1000 ? `${millify(cost || 0, { lowercase: true })}` : Math.round(cost)), [cost]);
  return <BNLabel className={className || classes.label} leftIcon={<PaidIcon size={20} />} skeleton={skeleton} text={text} tooltip={`${formatCurrency(cost)} listing cost`} />;
};
