import { IconPropsBase } from './IconPropsBase';

export default function TagIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 96 960 960">
        <path
          fill={color}
          d="M817.691 576 659.076 800.46q-11.769 16.769-30.038 26.154-18.269 9.385-39.039 9.385H214.616q-29.922 0-51.115-21.193-21.192-21.192-21.192-51.115V388.309q0-29.923 21.192-51.115 21.193-21.193 51.115-21.193h375.383q21.154 0 39.039 9.77 17.884 9.769 30.038 26.538L817.691 576Zm-73.383 0L609.231 386.001q-3.077-4.231-8.269-7.116Q595.769 376 589.999 376H214.616q-4.615 0-8.462 3.846-3.846 3.847-3.846 8.463v375.382q0 4.616 3.846 8.463 3.847 3.846 8.462 3.846h375.383q5.77 0 10.963-2.885 5.192-2.885 8.269-7.116L744.308 576Zm-542 0v200V376v200Z"
        />
      </svg>
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 96 960 960">
      <path
        fill={color}
        d="M793.691 576 639.076 767.46q-11.769 13.769-27.175 21.154-15.405 7.385-32.902 7.385H278.616q-26.622 0-45.465-18.843-18.842-18.842-18.842-45.465V420.309q0-26.623 18.842-45.465 18.843-18.843 45.465-18.843h300.383q17.881 0 32.902 7.77 15.021 7.769 27.175 21.538L793.691 576Zm-67.383 0L598.231 418.001q-3.077-4.231-8.269-7.116Q584.769 408 578.999 408H278.616q-4.615 0-8.462 3.846-3.846 3.847-3.846 8.463v311.382q0 4.616 3.846 8.463 3.847 3.846 8.462 3.846h300.383q5.77 0 10.963-2.885 5.192-2.885 8.269-7.116L726.308 576Zm-460 0v168V408v168Z"
      />
    </svg>
  );
}
