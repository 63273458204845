import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { useMemo } from 'react';
import LogoLoader from '../Loading/LogoLoader';
import { isDtiHosted } from '../../utils/whitelabel-consts';

export default function AuthenticationGuard({ component }: { component: React.ComponentType<object> }) {
  const Component = useMemo(
    () =>
      withAuthenticationRequired(component, {
        onRedirecting: () => {
          return <LogoLoader />;
        },
      }),
    [component, isDtiHosted],
  );

  return <Component />;
}
