import { ActionIcon, Box, Center, Flex, Group, Skeleton, Space, Text, Tooltip } from '@mantine/core';
import ArrowBackIcon from '../../components/icons/ArrowBack';
import ArrowForwardIcon from '../../components/icons/ArrowForward';
import { useAtomValue } from 'jotai';
import { useNowPricing } from './NowPricing.hooks';
import InfoIcon from '../../components/icons/Info';
import { seasons } from '../../data/atoms.seasons';
import pluralize from 'pluralize';
import classes from './NowPricing.module.css';

export const ReadOnly = () => {
  const location = useAtomValue(seasons.selectedLocationAtom);
  const { prevListing, nextListing } = useNowPricing('prevListing', 'nextListing');
  const { section, row, listings } = location || {};
  const maxQuantity = listings?.reduce((acc, listing) => {
    const { quantity } = listing || {};
    return quantity > acc ? quantity : acc;
  }, 0);

  const isQuantityMatching = listings?.every((listing) => listing.quantity === maxQuantity);

  const seatFrom = listings?.reduce(
    (acc, listing) => {
      if (listing.seatFrom) {
        if (acc === null) {
          return listing.seatFrom;
        }
        return listing.seatFrom < acc ? listing.seatFrom : acc;
      }
      return acc;
    },
    null as string | null,
  );
  const seatThru = listings?.reduce(
    (acc, listing) => {
      if (listing.seatFrom) {
        if (acc === null) {
          return listing.seatThru;
        }
        return listing.seatFrom < acc ? listing.seatThru : acc;
      }
      return acc;
    },
    null as string | null,
  );

  const seats = seatFrom !== seatThru ? `${seatFrom}-${seatThru}` : seatFrom;

  return (
    <>
      <Group pos="absolute" top={4} right={8} gap="md">
        <ActionIcon onClick={prevListing}>
          <ArrowBackIcon size={24} />
        </ActionIcon>
        <ActionIcon onClick={nextListing}>
          <ArrowForwardIcon size={24} />
        </ActionIcon>
      </Group>
      <Space h="xs" />
      <Box className={classes.nowPricingGrid}>
        <Box>
          <Text lh={1} size="xs" c="var(--colors-gray-5)">
            Section
          </Text>
          {section ? (
            <Text size="md" lh={1.3} pt={2}>
              {section}
            </Text>
          ) : (
            <Skeleton height={8} my={8.4} width={50} />
          )}
        </Box>
        <Box miw="max-content">
          <Text lh={1} size="xs" c="var(--colors-gray-5)">
            Row
          </Text>
          {row ? (
            <Text truncate size="md" lh={1.3} pt={2}>
              {row}
            </Text>
          ) : (
            <Skeleton height={8} my={8.4} width={50} />
          )}
        </Box>
        <Box miw="13%">
          <Flex pos="relative">
            <Text lh={1} size="xs" c="var(--colors-gray-5)">
              Qty
            </Text>
            {!isQuantityMatching && (
              <Tooltip label="Not all events have the same quantity/seats." withArrow>
                <Center bg="var(--colors-yellow-0)" pos="absolute" top={-7} left={22} h={24} w={24} c="var(--colors-yellow-warning)" style={{ borderRadius: 100 }}>
                  <InfoIcon />
                </Center>
              </Tooltip>
            )}
          </Flex>
          {maxQuantity ? (
            <Text component="div" truncate size="md" lh={1.3} pt={2}>
              <div>
                {maxQuantity}
                <Box component="span" c="var(--colors-brandcolor-5)" hidden={isQuantityMatching}>
                  *
                </Box>
              </div>
            </Text>
          ) : (
            <Skeleton height={8} my={8.4} width={50} />
          )}
        </Box>
        <Box miw="max-content">
          <Text lh={1} size="xs" c="var(--colors-gray-5)">
            {pluralize('Seat', seats?.includes('-') ? 2 : 1)}
          </Text>
          {seats ? (
            <Text truncate size="md" lh={1.3} pt={2}>
              {seats}
            </Text>
          ) : (
            <Skeleton height={8} my={8.4} width={50} />
          )}
        </Box>
      </Box>
    </>
  );
};
