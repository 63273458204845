import { Box, Group, SimpleGrid, Text, Tooltip } from '@mantine/core';
import { BNNumberInput } from '../../components/NumberInput/NumberInput';
import { StaggerByValueInput } from '../../components/AdjustmentInput/StaggerByValueInput';
import { useNowPricing } from './NowPricing.hooks';
import { AdjustmentValueInputDiagonal } from '../../components/AdjustmentInput/AdjustmentValueInputDiagonal';
import classes from '../SeatingChart/SeatingChart.module.css';
import { BNSwitch } from '../../components/Switch/Switch';
import { BNTextInput } from '../../components/TextInput/TextInput';
import { useFlag } from '@unleash/proxy-client-react';

export const RuleForm = () => {
  const { form, isGroupedListing, isFormReady, setAutoUpdateRuleOnTargetComparableChanges, onSave } = useNowPricing(
    'form',
    'isGroupedListing',
    'isFormReady',
    'setAutoUpdateRuleOnTargetComparableChanges',
    'onSave',
  );

  const hasAllActiveToggleFlag = useFlag('all-active-toggle');

  return (
    <Box>
      <SimpleGrid cols={2} spacing="sm" my={8}>
        <AdjustmentValueInputDiagonal
          label="Adjustment"
          numInputWidth={65}
          disabled={!isFormReady}
          onChange={(value) => {
            form.getInputProps('adjustmentValue').onChange(value.value);
            form.getInputProps('adjustmentTypeId').onChange(value.type);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onSave();
            }
          }}
          value={form.values.adjustmentValue || 0}
          type={form.values.adjustmentTypeId || 'Amount'}
          error={form.errors.adjustmentValue}
        />

        <BNNumberInput selectOnFocus disabled={!isFormReady} {...form.getInputProps('numComparables')} label="# Comparables" decimalScale={0} min={1} size="xs" defaultValue={1} />
      </SimpleGrid>
      <SimpleGrid mb={8} cols={2} spacing="sm">
        <BNNumberInput
          selectOnFocus
          disabled={!isFormReady}
          leftSection={<Text size="xs">$</Text>}
          {...form.getInputProps('floorPrice')}
          onChange={(value) => {
            setAutoUpdateRuleOnTargetComparableChanges(false);
            form.getInputProps('floorPrice').onChange(typeof value === 'string' ? undefined : value);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onSave();
            }
          }}
          label="Floor"
          decimalScale={2}
          fixedDecimalScale
          step={1}
          stepHoldDelay={500}
          stepHoldInterval={100}
          min={0}
          size="xs"
        />
        <BNNumberInput
          selectOnFocus
          disabled={!isFormReady}
          {...form.getInputProps('ceilingPrice')}
          onChange={(value) => {
            setAutoUpdateRuleOnTargetComparableChanges(false);
            form.getInputProps('ceilingPrice').onChange(typeof value === 'string' ? undefined : value);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onSave();
            }
          }}
          leftSection={<Text size="xs">$</Text>}
          label="Ceiling"
          decimalScale={2}
          fixedDecimalScale
          step={1}
          stepHoldDelay={500}
          stepHoldInterval={100}
          min={0}
          size="xs"
        />
      </SimpleGrid>
      {isGroupedListing && (
        <SimpleGrid mb={8} cols={2} spacing="sm">
          {!hasAllActiveToggleFlag && (
            <BNNumberInput
              disabled={!isFormReady}
              label="# Active"
              {...form.getInputProps('numActive')}
              decimalScale={0}
              fixedDecimalScale
              allowDecimal={false}
              min={0}
              step={1}
              size="xs"
            />
          )}
          {hasAllActiveToggleFlag && (
            <Group className={classes.filtersWrapper} gap={0} wrap="wrap">
              <Group justify="space-between" w="100%">
                <Text size="xs" c="var(--colors-gray-5)" lh="1.25rem">
                  # Active
                </Text>
                <Tooltip withArrow label="Broadcast all listings in the group">
                  <Box>
                    <BNSwitch
                      checked={form.values.numActive === 2147483647}
                      onChange={() => form.setFieldValue('numActive', form.values.numActive === 2147483647 ? 1 : 2147483647)}
                      size="xxs"
                      label="ALL"
                    />
                  </Box>
                </Tooltip>
              </Group>
              {form.values.numActive && form.values.numActive >= 2147483647 && <BNTextInput disabled size="xs" value="All Listings" />}
              {(form.values.numActive === undefined || form.values.numActive === null || form.values.numActive < 2147483647) && (
                <BNNumberInput disabled={!isFormReady} {...form.getInputProps('numActive')} decimalScale={0} fixedDecimalScale allowDecimal={false} min={0} step={1} size="xs" />
              )}
            </Group>
          )}
          <StaggerByValueInput
            numInputWidth={78}
            disabled={!isFormReady}
            label="Stagger By"
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                onSave();
              }
            }}
            onChange={(value) => {
              form.getInputProps('staggerByValue').onChange(value.value);
              form.getInputProps('staggerByTypeId').onChange(value.type);
            }}
            value={form.values.staggerByValue || 0}
            type={form.values.staggerByTypeId || 'Amount'}
          />
        </SimpleGrid>
      )}
    </Box>
  );
};
