import { useDidUpdate } from '@mantine/hooks';
import { MutableRefObject, useEffect } from 'react';
import { isAndroid } from 'react-device-detect';

export const useViewportPosition = (ref: MutableRefObject<HTMLInputElement | null>, deps: unknown[], selector?: string) => {
  useDidUpdate(() => {
    ref.current?.focus();
  }, deps);

  useEffect(() => {
    const vv = window.visualViewport!;
    let lastValue = 0;
    const modal = ref.current?.closest(selector ?? '.mantine-Modal-inner') as HTMLDivElement;

    function fixPosition() {
      const { height } = vv;
      if (modal && (height < lastValue || height > lastValue * 1.1)) {
        modal.style.top = `${vv.height}px`;
        lastValue = vv.height;
      }
    }

    if (isAndroid) {
      if (modal) {
        modal.style.transform = 'translateY(-100%)';
      }
      vv.addEventListener('resize', fixPosition);
    }

    return () => {
      vv.removeEventListener('resize', fixPosition);
    };
  }, [ref, selector]);
};
