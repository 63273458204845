import { IconPropsBase } from './IconPropsBase';

export default function VisibilityOffIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M15.775 12.975 14.65 11.85q.225-1.25-.712-2.237Q13 8.625 11.65 8.85l-1.125-1.125q.35-.15.7-.225.35-.075.775-.075 1.7 0 2.887 1.188Q16.075 9.8 16.075 11.5q0 .425-.075.787-.075.363-.225.688Zm3.175 3.1-1.1-1.025q.95-.725 1.688-1.588.737-.862 1.262-1.962-1.25-2.525-3.588-4.013Q14.875 6 12 6q-.725 0-1.425.1-.7.1-1.375.3L8.025 5.225q.95-.375 1.938-.55Q10.95 4.5 12 4.5q3.525 0 6.35 1.938 2.825 1.937 4.1 5.062-.55 1.35-1.425 2.512-.875 1.163-2.075 2.063Zm.8 5.8-4.025-4.025q-.775.3-1.712.475-.938.175-2.013.175-3.525 0-6.338-1.938Q2.85 14.625 1.55 11.5q.55-1.325 1.425-2.475Q3.85 7.875 4.9 7.05l-2.775-2.8 1.05-1.075 17.65 17.65ZM5.95 8.1q-.8.625-1.537 1.513Q3.675 10.5 3.2 11.5q1.25 2.525 3.587 4.012Q9.125 17 12 17q.675 0 1.35-.113.675-.112 1.15-.237l-1.25-1.3q-.275.1-.6.162-.325.063-.65.063-1.7 0-2.887-1.188Q7.925 13.2 7.925 11.5q0-.3.063-.638.062-.337.162-.612Zm7.575 2.625Zm-3.3 1.65Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="m13 10.875-1.021-1.021q.083-.854-.51-1.396-.594-.541-1.323-.458L9.125 6.979q.208-.062.427-.094.219-.031.448-.031 1.312 0 2.229.917T13.146 10q0 .229-.031.458-.032.23-.115.417Zm2.75 2.729-.792-.771q.75-.583 1.365-1.281T17.354 10q-1.021-2.104-3.01-3.302Q12.354 5.5 10 5.5q-.542 0-1.062.062-.521.063-1.021.209l-.896-.896Q7.75 4.604 8.5 4.51q.75-.093 1.5-.093 2.771 0 5.135 1.489Q17.5 7.396 18.542 10q-.438 1.083-1.146 1.969-.708.885-1.646 1.635Zm.208 3.917-2.562-2.604q-.708.312-1.573.489T10 15.583q-2.771 0-5.125-1.489Q2.521 12.604 1.458 10q.48-1.188 1.292-2.177.812-.99 1.833-1.719L2.479 4.021l.771-.792 13.5 13.521ZM5.354 6.896q-.792.562-1.531 1.354-.74.792-1.177 1.75 1.021 2.104 3.01 3.302Q7.646 14.5 10 14.5q.688 0 1.375-.115.687-.114 1.187-.281l-1.229-1.229q-.291.125-.645.198-.355.073-.688.073-1.312 0-2.229-.917T6.854 10q0-.312.094-.677.094-.365.177-.656ZM11 9Zm-1.604 1.812Z"
        />
      </svg>
    );
  }
}
