import { IconPropsBase } from './IconPropsBase';

export default function HistoryIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M11.975 20.5q-3.25 0-5.663-2.137Q3.9 16.225 3.55 13h1.525q.375 2.6 2.325 4.3Q9.35 19 11.975 19q2.925 0 4.963-2.038 2.037-2.037 2.037-4.962t-2.037-4.963Q14.9 5 11.975 5q-1.625 0-3.062.725-1.438.725-2.463 2h2.6v1.5H3.975V4.15h1.5v2.375Q6.7 5.075 8.4 4.287q1.7-.787 3.575-.787 1.775 0 3.325.675Q16.85 4.85 18 6q1.15 1.15 1.812 2.688.663 1.537.663 3.312t-.663 3.312Q19.15 16.85 18 18q-1.15 1.15-2.7 1.825-1.55.675-3.325.675Zm3-4.475L11.25 12.3V7h1.5v4.7l3.275 3.275Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M9.979 16.583q-2.75 0-4.667-1.916Q3.396 12.75 3.417 10h1.062q.021 2.271 1.625 3.885Q7.708 15.5 9.979 15.5t3.886-1.615q1.614-1.614 1.614-3.885t-1.614-3.885Q12.25 4.5 9.979 4.5q-1.208 0-2.25.469-1.041.469-1.791 1.302h2.104v1.083h-4.23V3.125h1.084v2.729q.916-1.125 2.229-1.781 1.313-.656 2.854-.656 1.375 0 2.573.521 1.198.52 2.083 1.406.886.885 1.407 2.083.52 1.198.52 2.573t-.52 2.573q-.521 1.198-1.407 2.083-.885.886-2.083 1.406-1.198.521-2.573.521Zm2.25-3.895L9.458 9.917V6h1.084v3.458L13 11.917Z"
        />
      </svg>
    );
  }
}
