import { Group, Stack, Text } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import { BarkerEventListing } from '../../types';
import { usePutApiInventoryListingListingIdReserve, usePutApiInventoryListingListingIdUnreserve } from '../../api';
import { useSetAtom } from 'jotai/index';
import { updateListingsAtom } from '../../data/atoms';

export function ReserveUnreserveAction({ onClose, listing }: { onClose: () => void; listing: BarkerEventListing }) {
  const isOnHold = (listing?.quantityReserved ?? 0) > 0;
  const updateListing = useSetAtom(updateListingsAtom);
  const { mutateAsync: reserveMutation, isLoading: isReserving } = usePutApiInventoryListingListingIdReserve({
    axios: {
      headers: {
        'X-Tenant-Id': listing.tenantId,
      },
    },
  });

  const { mutateAsync: unreserveMutation, isLoading: isUnreserving } = usePutApiInventoryListingListingIdUnreserve({
    axios: {
      headers: {
        'X-Tenant-Id': listing.tenantId,
      },
    },
  });

  const isLoading = isReserving || isUnreserving;

  async function reserveListing({ listingId }: { listingId: string }) {
    const response = await reserveMutation({
      listingId,
    });

    updateListing(response.data);
  }

  async function unreserveListing({ listingId }: { listingId: string }) {
    const response = await unreserveMutation({
      listingId,
    });

    updateListing(response.data);
  }

  if (isOnHold) {
    return (
      <Group gap="xs" align="flex-end" justify="right" mt={-2}>
        <Stack gap="xs">
          <Text>Remove the hold on this listing?</Text>
          <Group gap="xs" align="flex-end" justify="right" wrap="nowrap" w={236}>
            <BNButton
              fullWidth
              variant="default"
              disabled={isLoading}
              size="xs"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </BNButton>
            <BNButton
              fullWidth
              variant="filled"
              loading={isLoading}
              color="green"
              size="xs"
              onClick={async () => {
                await unreserveListing({
                  listingId: listing.listingId,
                });
                onClose();
              }}
              data-pendo-name="Unreserve Button"
            >
              Apply
            </BNButton>
          </Group>
        </Stack>
      </Group>
    );
  }

  return (
    <Group gap="xs" align="flex-end" justify="right" mt={-2}>
      <Stack gap="xs">
        <Text>Place these tickets on hold?</Text>
        <Group gap="xs" align="flex-end" justify="right" wrap="nowrap" w={236}>
          <BNButton
            fullWidth
            variant="default"
            disabled={isLoading}
            size="xs"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </BNButton>
          <BNButton
            fullWidth
            variant="filled"
            loading={isLoading}
            color="green"
            size="xs"
            onClick={async () => {
              await reserveListing({
                listingId: listing.listingId,
              });
              onClose();
            }}
            data-pendo-name="Reserve Button"
          >
            Apply
          </BNButton>
        </Group>
      </Stack>
    </Group>
  );
}
