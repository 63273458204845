import { useMutation, useQuery } from '@tanstack/react-query';
import type { MutationFunction, QueryFunction, QueryKey, UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  BarkerCoreEnumsMarketplace,
  BarkerCoreEnumsPointOfSale,
  BarkerCoreModelsAdministrativeValidateIntegrationResponse,
  BarkerCoreModelsAdministrativeValidatePointOfSaleRequest,
  BarkerCoreModelsInventoryEvent,
  BarkerCoreModelsInventoryEventComment,
  BarkerCoreModelsInventoryEventMapping,
  BarkerCoreModelsInventoryEventMappingSuccess,
  BarkerCoreModelsInventoryGetInventoryResponse,
  BarkerCoreModelsInventoryInventorySnapshot,
  BarkerCoreModelsInventoryListing,
  BarkerCoreModelsInventoryListingChange,
  BarkerCoreModelsInventoryMergeInventoryRequest,
  BarkerCoreModelsInventorySeasonLocation,
  BarkerCoreModelsInventorySplitInventoryRequest,
  BarkerCoreModelsInventoryUpdateListingsRequestBulkItem,
  GetApiInventoryEventsParams,
  GetApiInventoryParams,
  PostApiInventoryEventsEventIdCommentsParams,
  PutApiInventoryEventsEventIdCommentsCommentIdParams,
  SystemTextJsonPatchJsonPatchDocument,
} from './models';

export const putApiInventoryValidate = (
  barkerCoreModelsAdministrativeValidatePointOfSaleRequest: BarkerCoreModelsAdministrativeValidatePointOfSaleRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsAdministrativeValidateIntegrationResponse>> => {
  return axios.put(`/api/Inventory/Validate`, barkerCoreModelsAdministrativeValidatePointOfSaleRequest, options);
};

export const getPutApiInventoryValidateMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryValidate>>, TError, { data: BarkerCoreModelsAdministrativeValidatePointOfSaleRequest }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryValidate>>, TError, { data: BarkerCoreModelsAdministrativeValidatePointOfSaleRequest }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiInventoryValidate>>, { data: BarkerCoreModelsAdministrativeValidatePointOfSaleRequest }> = (props) => {
    const { data } = props ?? {};

    return putApiInventoryValidate(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiInventoryValidateMutationResult = NonNullable<Awaited<ReturnType<typeof putApiInventoryValidate>>>;
export type PutApiInventoryValidateMutationBody = BarkerCoreModelsAdministrativeValidatePointOfSaleRequest;
export type PutApiInventoryValidateMutationError = AxiosError<void>;

export const usePutApiInventoryValidate = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryValidate>>, TError, { data: BarkerCoreModelsAdministrativeValidatePointOfSaleRequest }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiInventoryValidate>>, TError, { data: BarkerCoreModelsAdministrativeValidatePointOfSaleRequest }, TContext> => {
  const mutationOptions = getPutApiInventoryValidateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiInventory = (params: GetApiInventoryParams, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsInventoryGetInventoryResponse>> => {
  return axios.get(`/api/Inventory`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetApiInventoryQueryKey = (params: GetApiInventoryParams) => {
  return [`/api/Inventory`, ...(params ? [params] : [])] as const;
};

export const getGetApiInventoryQueryOptions = <TData = Awaited<ReturnType<typeof getApiInventory>>, TError = AxiosError<void>>(
  params: GetApiInventoryParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventory>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiInventoryQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiInventory>>> = ({ signal }) => getApiInventory(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiInventory>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiInventoryQueryResult = NonNullable<Awaited<ReturnType<typeof getApiInventory>>>;
export type GetApiInventoryQueryError = AxiosError<void>;

export const useGetApiInventory = <TData = Awaited<ReturnType<typeof getApiInventory>>, TError = AxiosError<void>>(
  params: GetApiInventoryParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventory>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiInventoryQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiInventoryEvents = (params: GetApiInventoryEventsParams, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsInventoryEvent[]>> => {
  return axios.get(`/api/Inventory/Events`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetApiInventoryEventsQueryKey = (params: GetApiInventoryEventsParams) => {
  return [`/api/Inventory/Events`, ...(params ? [params] : [])] as const;
};

export const getGetApiInventoryEventsQueryOptions = <TData = Awaited<ReturnType<typeof getApiInventoryEvents>>, TError = AxiosError<void>>(
  params: GetApiInventoryEventsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEvents>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiInventoryEventsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiInventoryEvents>>> = ({ signal }) => getApiInventoryEvents(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEvents>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiInventoryEventsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiInventoryEvents>>>;
export type GetApiInventoryEventsQueryError = AxiosError<void>;

export const useGetApiInventoryEvents = <TData = Awaited<ReturnType<typeof getApiInventoryEvents>>, TError = AxiosError<void>>(
  params: GetApiInventoryEventsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEvents>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiInventoryEventsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiInventoryEventsEventId = (eventId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsInventoryEvent>> => {
  return axios.get(`/api/Inventory/Events/${eventId}`, options);
};

export const getGetApiInventoryEventsEventIdQueryKey = (eventId: string) => {
  return [`/api/Inventory/Events/${eventId}`] as const;
};

export const getGetApiInventoryEventsEventIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiInventoryEventsEventId>>, TError = AxiosError<void>>(
  eventId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEventsEventId>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiInventoryEventsEventIdQueryKey(eventId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiInventoryEventsEventId>>> = ({ signal }) => getApiInventoryEventsEventId(eventId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!eventId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEventsEventId>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiInventoryEventsEventIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiInventoryEventsEventId>>>;
export type GetApiInventoryEventsEventIdQueryError = AxiosError<void>;

export const useGetApiInventoryEventsEventId = <TData = Awaited<ReturnType<typeof getApiInventoryEventsEventId>>, TError = AxiosError<void>>(
  eventId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEventsEventId>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiInventoryEventsEventIdQueryOptions(eventId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const patchApiInventoryEventsEventId = (
  eventId: string,
  systemTextJsonPatchJsonPatchDocument: SystemTextJsonPatchJsonPatchDocument,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsInventoryEvent>> => {
  return axios.patch(`/api/Inventory/Events/${eventId}`, systemTextJsonPatchJsonPatchDocument, options);
};

export const getPatchApiInventoryEventsEventIdMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof patchApiInventoryEventsEventId>>, TError, { eventId: string; data: SystemTextJsonPatchJsonPatchDocument }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof patchApiInventoryEventsEventId>>, TError, { eventId: string; data: SystemTextJsonPatchJsonPatchDocument }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiInventoryEventsEventId>>, { eventId: string; data: SystemTextJsonPatchJsonPatchDocument }> = (props) => {
    const { eventId, data } = props ?? {};

    return patchApiInventoryEventsEventId(eventId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApiInventoryEventsEventIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiInventoryEventsEventId>>>;
export type PatchApiInventoryEventsEventIdMutationBody = SystemTextJsonPatchJsonPatchDocument;
export type PatchApiInventoryEventsEventIdMutationError = AxiosError<void>;

export const usePatchApiInventoryEventsEventId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof patchApiInventoryEventsEventId>>, TError, { eventId: string; data: SystemTextJsonPatchJsonPatchDocument }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof patchApiInventoryEventsEventId>>, TError, { eventId: string; data: SystemTextJsonPatchJsonPatchDocument }, TContext> => {
  const mutationOptions = getPatchApiInventoryEventsEventIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiInventoryEventsEventIdListings = (eventId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsInventoryListing[]>> => {
  return axios.get(`/api/Inventory/Events/${eventId}/Listings`, options);
};

export const getGetApiInventoryEventsEventIdListingsQueryKey = (eventId: string) => {
  return [`/api/Inventory/Events/${eventId}/Listings`] as const;
};

export const getGetApiInventoryEventsEventIdListingsQueryOptions = <TData = Awaited<ReturnType<typeof getApiInventoryEventsEventIdListings>>, TError = AxiosError<void>>(
  eventId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEventsEventIdListings>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiInventoryEventsEventIdListingsQueryKey(eventId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiInventoryEventsEventIdListings>>> = ({ signal }) =>
    getApiInventoryEventsEventIdListings(eventId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!eventId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEventsEventIdListings>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiInventoryEventsEventIdListingsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiInventoryEventsEventIdListings>>>;
export type GetApiInventoryEventsEventIdListingsQueryError = AxiosError<void>;

export const useGetApiInventoryEventsEventIdListings = <TData = Awaited<ReturnType<typeof getApiInventoryEventsEventIdListings>>, TError = AxiosError<void>>(
  eventId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEventsEventIdListings>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiInventoryEventsEventIdListingsQueryOptions(eventId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiInventoryEventsEventIdMappings = (eventId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsInventoryEventMapping[]>> => {
  return axios.get(`/api/Inventory/Events/${eventId}/Mappings`, options);
};

export const getGetApiInventoryEventsEventIdMappingsQueryKey = (eventId: string) => {
  return [`/api/Inventory/Events/${eventId}/Mappings`] as const;
};

export const getGetApiInventoryEventsEventIdMappingsQueryOptions = <TData = Awaited<ReturnType<typeof getApiInventoryEventsEventIdMappings>>, TError = AxiosError<void>>(
  eventId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEventsEventIdMappings>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiInventoryEventsEventIdMappingsQueryKey(eventId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiInventoryEventsEventIdMappings>>> = ({ signal }) =>
    getApiInventoryEventsEventIdMappings(eventId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!eventId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEventsEventIdMappings>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiInventoryEventsEventIdMappingsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiInventoryEventsEventIdMappings>>>;
export type GetApiInventoryEventsEventIdMappingsQueryError = AxiosError<void>;

export const useGetApiInventoryEventsEventIdMappings = <TData = Awaited<ReturnType<typeof getApiInventoryEventsEventIdMappings>>, TError = AxiosError<void>>(
  eventId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEventsEventIdMappings>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiInventoryEventsEventIdMappingsQueryOptions(eventId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postApiInventoryEventsEventIdMappings = (
  eventId: string,
  barkerCoreModelsInventoryEventMappingSuccess: BarkerCoreModelsInventoryEventMappingSuccess,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsInventoryEventMapping>> => {
  return axios.post(`/api/Inventory/Events/${eventId}/Mappings`, barkerCoreModelsInventoryEventMappingSuccess, options);
};

export const getPostApiInventoryEventsEventIdMappingsMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiInventoryEventsEventIdMappings>>,
    TError,
    { eventId: string; data: BarkerCoreModelsInventoryEventMappingSuccess },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiInventoryEventsEventIdMappings>>,
  TError,
  { eventId: string; data: BarkerCoreModelsInventoryEventMappingSuccess },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiInventoryEventsEventIdMappings>>, { eventId: string; data: BarkerCoreModelsInventoryEventMappingSuccess }> = (
    props,
  ) => {
    const { eventId, data } = props ?? {};

    return postApiInventoryEventsEventIdMappings(eventId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiInventoryEventsEventIdMappingsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiInventoryEventsEventIdMappings>>>;
export type PostApiInventoryEventsEventIdMappingsMutationBody = BarkerCoreModelsInventoryEventMappingSuccess;
export type PostApiInventoryEventsEventIdMappingsMutationError = AxiosError<void>;

export const usePostApiInventoryEventsEventIdMappings = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiInventoryEventsEventIdMappings>>,
    TError,
    { eventId: string; data: BarkerCoreModelsInventoryEventMappingSuccess },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiInventoryEventsEventIdMappings>>,
  TError,
  { eventId: string; data: BarkerCoreModelsInventoryEventMappingSuccess },
  TContext
> => {
  const mutationOptions = getPostApiInventoryEventsEventIdMappingsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get event mappings for a list of event IDs
 */
export const postApiInventoryEventsMappings = (
  postApiInventoryEventsMappingsBody: string[],
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsInventoryEventMapping[]>> => {
  return axios.post(`/api/Inventory/Events/Mappings`, postApiInventoryEventsMappingsBody, options);
};

export const getPostApiInventoryEventsMappingsMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiInventoryEventsMappings>>, TError, { data: string[] }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiInventoryEventsMappings>>, TError, { data: string[] }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiInventoryEventsMappings>>, { data: string[] }> = (props) => {
    const { data } = props ?? {};

    return postApiInventoryEventsMappings(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiInventoryEventsMappingsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiInventoryEventsMappings>>>;
export type PostApiInventoryEventsMappingsMutationBody = string[];
export type PostApiInventoryEventsMappingsMutationError = AxiosError<void>;

/**
 * @summary Get event mappings for a list of event IDs
 */
export const usePostApiInventoryEventsMappings = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiInventoryEventsMappings>>, TError, { data: string[] }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postApiInventoryEventsMappings>>, TError, { data: string[] }, TContext> => {
  const mutationOptions = getPostApiInventoryEventsMappingsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteApiInventoryEventsEventIdMappingsMarketplaceId = (
  eventId: string,
  marketplaceId: BarkerCoreEnumsMarketplace,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/Inventory/Events/${eventId}/Mappings/${marketplaceId}`, options);
};

export const getDeleteApiInventoryEventsEventIdMappingsMarketplaceIdMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiInventoryEventsEventIdMappingsMarketplaceId>>,
    TError,
    { eventId: string; marketplaceId: BarkerCoreEnumsMarketplace },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiInventoryEventsEventIdMappingsMarketplaceId>>,
  TError,
  { eventId: string; marketplaceId: BarkerCoreEnumsMarketplace },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiInventoryEventsEventIdMappingsMarketplaceId>>,
    { eventId: string; marketplaceId: BarkerCoreEnumsMarketplace }
  > = (props) => {
    const { eventId, marketplaceId } = props ?? {};

    return deleteApiInventoryEventsEventIdMappingsMarketplaceId(eventId, marketplaceId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiInventoryEventsEventIdMappingsMarketplaceIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiInventoryEventsEventIdMappingsMarketplaceId>>>;

export type DeleteApiInventoryEventsEventIdMappingsMarketplaceIdMutationError = AxiosError<void>;

export const useDeleteApiInventoryEventsEventIdMappingsMarketplaceId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiInventoryEventsEventIdMappingsMarketplaceId>>,
    TError,
    { eventId: string; marketplaceId: BarkerCoreEnumsMarketplace },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiInventoryEventsEventIdMappingsMarketplaceId>>,
  TError,
  { eventId: string; marketplaceId: BarkerCoreEnumsMarketplace },
  TContext
> => {
  const mutationOptions = getDeleteApiInventoryEventsEventIdMappingsMarketplaceIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiInventoryEventsEventIdViewed = (eventId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Inventory/Events/${eventId}/Viewed`, undefined, options);
};

export const getPutApiInventoryEventsEventIdViewedMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryEventsEventIdViewed>>, TError, { eventId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryEventsEventIdViewed>>, TError, { eventId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiInventoryEventsEventIdViewed>>, { eventId: string }> = (props) => {
    const { eventId } = props ?? {};

    return putApiInventoryEventsEventIdViewed(eventId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiInventoryEventsEventIdViewedMutationResult = NonNullable<Awaited<ReturnType<typeof putApiInventoryEventsEventIdViewed>>>;

export type PutApiInventoryEventsEventIdViewedMutationError = AxiosError<void>;

export const usePutApiInventoryEventsEventIdViewed = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryEventsEventIdViewed>>, TError, { eventId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiInventoryEventsEventIdViewed>>, TError, { eventId: string }, TContext> => {
  const mutationOptions = getPutApiInventoryEventsEventIdViewedMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiInventoryListingsListingId = (listingId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsInventoryListing>> => {
  return axios.get(`/api/Inventory/Listings/${listingId}`, options);
};

export const getGetApiInventoryListingsListingIdQueryKey = (listingId: string) => {
  return [`/api/Inventory/Listings/${listingId}`] as const;
};

export const getGetApiInventoryListingsListingIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiInventoryListingsListingId>>, TError = AxiosError<void>>(
  listingId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryListingsListingId>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiInventoryListingsListingIdQueryKey(listingId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiInventoryListingsListingId>>> = ({ signal }) =>
    getApiInventoryListingsListingId(listingId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!listingId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryListingsListingId>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiInventoryListingsListingIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiInventoryListingsListingId>>>;
export type GetApiInventoryListingsListingIdQueryError = AxiosError<void>;

export const useGetApiInventoryListingsListingId = <TData = Awaited<ReturnType<typeof getApiInventoryListingsListingId>>, TError = AxiosError<void>>(
  listingId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryListingsListingId>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiInventoryListingsListingIdQueryOptions(listingId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const patchApiInventoryListingsListingId = (
  listingId: string,
  systemTextJsonPatchJsonPatchDocument: SystemTextJsonPatchJsonPatchDocument,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsInventoryListing>> => {
  return axios.patch(`/api/Inventory/Listings/${listingId}`, systemTextJsonPatchJsonPatchDocument, options);
};

export const getPatchApiInventoryListingsListingIdMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiInventoryListingsListingId>>,
    TError,
    { listingId: string; data: SystemTextJsonPatchJsonPatchDocument },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof patchApiInventoryListingsListingId>>, TError, { listingId: string; data: SystemTextJsonPatchJsonPatchDocument }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiInventoryListingsListingId>>, { listingId: string; data: SystemTextJsonPatchJsonPatchDocument }> = (
    props,
  ) => {
    const { listingId, data } = props ?? {};

    return patchApiInventoryListingsListingId(listingId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApiInventoryListingsListingIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiInventoryListingsListingId>>>;
export type PatchApiInventoryListingsListingIdMutationBody = SystemTextJsonPatchJsonPatchDocument;
export type PatchApiInventoryListingsListingIdMutationError = AxiosError<void>;

export const usePatchApiInventoryListingsListingId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiInventoryListingsListingId>>,
    TError,
    { listingId: string; data: SystemTextJsonPatchJsonPatchDocument },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof patchApiInventoryListingsListingId>>, TError, { listingId: string; data: SystemTextJsonPatchJsonPatchDocument }, TContext> => {
  const mutationOptions = getPatchApiInventoryListingsListingIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Places a listing on hold indefinitely
 */
export const putApiInventoryListingListingIdReserve = (listingId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsInventoryListing>> => {
  return axios.put(`/api/Inventory/Listing/${listingId}/Reserve`, undefined, options);
};

export const getPutApiInventoryListingListingIdReserveMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryListingListingIdReserve>>, TError, { listingId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryListingListingIdReserve>>, TError, { listingId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiInventoryListingListingIdReserve>>, { listingId: string }> = (props) => {
    const { listingId } = props ?? {};

    return putApiInventoryListingListingIdReserve(listingId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiInventoryListingListingIdReserveMutationResult = NonNullable<Awaited<ReturnType<typeof putApiInventoryListingListingIdReserve>>>;

export type PutApiInventoryListingListingIdReserveMutationError = AxiosError<void>;

/**
 * @summary Places a listing on hold indefinitely
 */
export const usePutApiInventoryListingListingIdReserve = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryListingListingIdReserve>>, TError, { listingId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiInventoryListingListingIdReserve>>, TError, { listingId: string }, TContext> => {
  const mutationOptions = getPutApiInventoryListingListingIdReserveMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Removes a listing from hold
 */
export const putApiInventoryListingListingIdUnreserve = (listingId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsInventoryListing>> => {
  return axios.put(`/api/Inventory/Listing/${listingId}/Unreserve`, undefined, options);
};

export const getPutApiInventoryListingListingIdUnreserveMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryListingListingIdUnreserve>>, TError, { listingId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryListingListingIdUnreserve>>, TError, { listingId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiInventoryListingListingIdUnreserve>>, { listingId: string }> = (props) => {
    const { listingId } = props ?? {};

    return putApiInventoryListingListingIdUnreserve(listingId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiInventoryListingListingIdUnreserveMutationResult = NonNullable<Awaited<ReturnType<typeof putApiInventoryListingListingIdUnreserve>>>;

export type PutApiInventoryListingListingIdUnreserveMutationError = AxiosError<void>;

/**
 * @summary Removes a listing from hold
 */
export const usePutApiInventoryListingListingIdUnreserve = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryListingListingIdUnreserve>>, TError, { listingId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiInventoryListingListingIdUnreserve>>, TError, { listingId: string }, TContext> => {
  const mutationOptions = getPutApiInventoryListingListingIdUnreserveMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get listings for a list of listing IDs
 */
export const postApiInventoryListings = (postApiInventoryListingsBody: string[], options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsInventoryListing[]>> => {
  return axios.post(`/api/Inventory/Listings`, postApiInventoryListingsBody, options);
};

export const getPostApiInventoryListingsMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiInventoryListings>>, TError, { data: string[] }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiInventoryListings>>, TError, { data: string[] }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiInventoryListings>>, { data: string[] }> = (props) => {
    const { data } = props ?? {};

    return postApiInventoryListings(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiInventoryListingsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiInventoryListings>>>;
export type PostApiInventoryListingsMutationBody = string[];
export type PostApiInventoryListingsMutationError = AxiosError<void>;

/**
 * @summary Get listings for a list of listing IDs
 */
export const usePostApiInventoryListings = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiInventoryListings>>, TError, { data: string[] }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postApiInventoryListings>>, TError, { data: string[] }, TContext> => {
  const mutationOptions = getPostApiInventoryListingsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiInventoryListingsBulk = (
  barkerCoreModelsInventoryUpdateListingsRequestBulkItem: BarkerCoreModelsInventoryUpdateListingsRequestBulkItem[],
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Inventory/Listings/Bulk`, barkerCoreModelsInventoryUpdateListingsRequestBulkItem, options);
};

export const getPutApiInventoryListingsBulkMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryListingsBulk>>, TError, { data: BarkerCoreModelsInventoryUpdateListingsRequestBulkItem[] }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryListingsBulk>>, TError, { data: BarkerCoreModelsInventoryUpdateListingsRequestBulkItem[] }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiInventoryListingsBulk>>, { data: BarkerCoreModelsInventoryUpdateListingsRequestBulkItem[] }> = (props) => {
    const { data } = props ?? {};

    return putApiInventoryListingsBulk(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiInventoryListingsBulkMutationResult = NonNullable<Awaited<ReturnType<typeof putApiInventoryListingsBulk>>>;
export type PutApiInventoryListingsBulkMutationBody = BarkerCoreModelsInventoryUpdateListingsRequestBulkItem[];
export type PutApiInventoryListingsBulkMutationError = AxiosError<void>;

export const usePutApiInventoryListingsBulk = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryListingsBulk>>, TError, { data: BarkerCoreModelsInventoryUpdateListingsRequestBulkItem[] }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiInventoryListingsBulk>>, TError, { data: BarkerCoreModelsInventoryUpdateListingsRequestBulkItem[] }, TContext> => {
  const mutationOptions = getPutApiInventoryListingsBulkMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiInventoryListingsListingIdViewed = (listingId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Inventory/Listings/${listingId}/Viewed`, undefined, options);
};

export const getPutApiInventoryListingsListingIdViewedMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryListingsListingIdViewed>>, TError, { listingId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryListingsListingIdViewed>>, TError, { listingId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiInventoryListingsListingIdViewed>>, { listingId: string }> = (props) => {
    const { listingId } = props ?? {};

    return putApiInventoryListingsListingIdViewed(listingId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiInventoryListingsListingIdViewedMutationResult = NonNullable<Awaited<ReturnType<typeof putApiInventoryListingsListingIdViewed>>>;

export type PutApiInventoryListingsListingIdViewedMutationError = AxiosError<void>;

export const usePutApiInventoryListingsListingIdViewed = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiInventoryListingsListingIdViewed>>, TError, { listingId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiInventoryListingsListingIdViewed>>, TError, { listingId: string }, TContext> => {
  const mutationOptions = getPutApiInventoryListingsListingIdViewedMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiInventoryListingsListingIdChanges = (listingId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsInventoryListingChange[]>> => {
  return axios.get(`/api/Inventory/Listings/${listingId}/Changes`, options);
};

export const getGetApiInventoryListingsListingIdChangesQueryKey = (listingId: string) => {
  return [`/api/Inventory/Listings/${listingId}/Changes`] as const;
};

export const getGetApiInventoryListingsListingIdChangesQueryOptions = <TData = Awaited<ReturnType<typeof getApiInventoryListingsListingIdChanges>>, TError = AxiosError<void>>(
  listingId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryListingsListingIdChanges>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiInventoryListingsListingIdChangesQueryKey(listingId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiInventoryListingsListingIdChanges>>> = ({ signal }) =>
    getApiInventoryListingsListingIdChanges(listingId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!listingId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryListingsListingIdChanges>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiInventoryListingsListingIdChangesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiInventoryListingsListingIdChanges>>>;
export type GetApiInventoryListingsListingIdChangesQueryError = AxiosError<void>;

export const useGetApiInventoryListingsListingIdChanges = <TData = Awaited<ReturnType<typeof getApiInventoryListingsListingIdChanges>>, TError = AxiosError<void>>(
  listingId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryListingsListingIdChanges>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiInventoryListingsListingIdChangesQueryOptions(listingId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putApiInventoryListingsListingIdSplit = (
  listingId: string,
  barkerCoreModelsInventorySplitInventoryRequest: BarkerCoreModelsInventorySplitInventoryRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsInventoryListing[]>> => {
  return axios.put(`/api/Inventory/Listings/${listingId}/Split`, barkerCoreModelsInventorySplitInventoryRequest, options);
};

export const getPutApiInventoryListingsListingIdSplitMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiInventoryListingsListingIdSplit>>,
    TError,
    { listingId: string; data: BarkerCoreModelsInventorySplitInventoryRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiInventoryListingsListingIdSplit>>,
  TError,
  { listingId: string; data: BarkerCoreModelsInventorySplitInventoryRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiInventoryListingsListingIdSplit>>,
    { listingId: string; data: BarkerCoreModelsInventorySplitInventoryRequest }
  > = (props) => {
    const { listingId, data } = props ?? {};

    return putApiInventoryListingsListingIdSplit(listingId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiInventoryListingsListingIdSplitMutationResult = NonNullable<Awaited<ReturnType<typeof putApiInventoryListingsListingIdSplit>>>;
export type PutApiInventoryListingsListingIdSplitMutationBody = BarkerCoreModelsInventorySplitInventoryRequest;
export type PutApiInventoryListingsListingIdSplitMutationError = AxiosError<void>;

export const usePutApiInventoryListingsListingIdSplit = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiInventoryListingsListingIdSplit>>,
    TError,
    { listingId: string; data: BarkerCoreModelsInventorySplitInventoryRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiInventoryListingsListingIdSplit>>,
  TError,
  { listingId: string; data: BarkerCoreModelsInventorySplitInventoryRequest },
  TContext
> => {
  const mutationOptions = getPutApiInventoryListingsListingIdSplitMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiInventoryTags = (options?: AxiosRequestConfig): Promise<AxiosResponse<string[]>> => {
  return axios.get(`/api/Inventory/Tags`, options);
};

export const getGetApiInventoryTagsQueryKey = () => {
  return [`/api/Inventory/Tags`] as const;
};

export const getGetApiInventoryTagsQueryOptions = <TData = Awaited<ReturnType<typeof getApiInventoryTags>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryTags>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiInventoryTagsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiInventoryTags>>> = ({ signal }) => getApiInventoryTags({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryTags>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiInventoryTagsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiInventoryTags>>>;
export type GetApiInventoryTagsQueryError = AxiosError<void>;

export const useGetApiInventoryTags = <TData = Awaited<ReturnType<typeof getApiInventoryTags>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryTags>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiInventoryTagsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postApiInventoryEventsEventIdComments = (
  eventId: string,
  params?: PostApiInventoryEventsEventIdCommentsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsInventoryEventComment>> => {
  return axios.post(`/api/Inventory/Events/${eventId}/Comments`, undefined, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPostApiInventoryEventsEventIdCommentsMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiInventoryEventsEventIdComments>>,
    TError,
    { eventId: string; params?: PostApiInventoryEventsEventIdCommentsParams },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiInventoryEventsEventIdComments>>,
  TError,
  { eventId: string; params?: PostApiInventoryEventsEventIdCommentsParams },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiInventoryEventsEventIdComments>>,
    { eventId: string; params?: PostApiInventoryEventsEventIdCommentsParams }
  > = (props) => {
    const { eventId, params } = props ?? {};

    return postApiInventoryEventsEventIdComments(eventId, params, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiInventoryEventsEventIdCommentsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiInventoryEventsEventIdComments>>>;

export type PostApiInventoryEventsEventIdCommentsMutationError = AxiosError<void>;

export const usePostApiInventoryEventsEventIdComments = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiInventoryEventsEventIdComments>>,
    TError,
    { eventId: string; params?: PostApiInventoryEventsEventIdCommentsParams },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiInventoryEventsEventIdComments>>,
  TError,
  { eventId: string; params?: PostApiInventoryEventsEventIdCommentsParams },
  TContext
> => {
  const mutationOptions = getPostApiInventoryEventsEventIdCommentsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiInventoryEventsEventIdComments = (eventId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsInventoryEventComment[]>> => {
  return axios.get(`/api/Inventory/Events/${eventId}/Comments`, options);
};

export const getGetApiInventoryEventsEventIdCommentsQueryKey = (eventId: string) => {
  return [`/api/Inventory/Events/${eventId}/Comments`] as const;
};

export const getGetApiInventoryEventsEventIdCommentsQueryOptions = <TData = Awaited<ReturnType<typeof getApiInventoryEventsEventIdComments>>, TError = AxiosError<void>>(
  eventId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEventsEventIdComments>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiInventoryEventsEventIdCommentsQueryKey(eventId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiInventoryEventsEventIdComments>>> = ({ signal }) =>
    getApiInventoryEventsEventIdComments(eventId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!eventId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEventsEventIdComments>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiInventoryEventsEventIdCommentsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiInventoryEventsEventIdComments>>>;
export type GetApiInventoryEventsEventIdCommentsQueryError = AxiosError<void>;

export const useGetApiInventoryEventsEventIdComments = <TData = Awaited<ReturnType<typeof getApiInventoryEventsEventIdComments>>, TError = AxiosError<void>>(
  eventId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEventsEventIdComments>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiInventoryEventsEventIdCommentsQueryOptions(eventId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putApiInventoryEventsEventIdCommentsCommentId = (
  eventId: string,
  commentId: number,
  params?: PutApiInventoryEventsEventIdCommentsCommentIdParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsInventoryEventComment>> => {
  return axios.put(`/api/Inventory/Events/${eventId}/Comments/${commentId}`, undefined, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getPutApiInventoryEventsEventIdCommentsCommentIdMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiInventoryEventsEventIdCommentsCommentId>>,
    TError,
    { eventId: string; commentId: number; params?: PutApiInventoryEventsEventIdCommentsCommentIdParams },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiInventoryEventsEventIdCommentsCommentId>>,
  TError,
  { eventId: string; commentId: number; params?: PutApiInventoryEventsEventIdCommentsCommentIdParams },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiInventoryEventsEventIdCommentsCommentId>>,
    { eventId: string; commentId: number; params?: PutApiInventoryEventsEventIdCommentsCommentIdParams }
  > = (props) => {
    const { eventId, commentId, params } = props ?? {};

    return putApiInventoryEventsEventIdCommentsCommentId(eventId, commentId, params, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiInventoryEventsEventIdCommentsCommentIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiInventoryEventsEventIdCommentsCommentId>>>;

export type PutApiInventoryEventsEventIdCommentsCommentIdMutationError = AxiosError<void>;

export const usePutApiInventoryEventsEventIdCommentsCommentId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiInventoryEventsEventIdCommentsCommentId>>,
    TError,
    { eventId: string; commentId: number; params?: PutApiInventoryEventsEventIdCommentsCommentIdParams },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiInventoryEventsEventIdCommentsCommentId>>,
  TError,
  { eventId: string; commentId: number; params?: PutApiInventoryEventsEventIdCommentsCommentIdParams },
  TContext
> => {
  const mutationOptions = getPutApiInventoryEventsEventIdCommentsCommentIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteApiInventoryEventsEventIdCommentsCommentId = (eventId: string, commentId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/Inventory/Events/${eventId}/Comments/${commentId}`, options);
};

export const getDeleteApiInventoryEventsEventIdCommentsCommentIdMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiInventoryEventsEventIdCommentsCommentId>>, TError, { eventId: string; commentId: number }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteApiInventoryEventsEventIdCommentsCommentId>>, TError, { eventId: string; commentId: number }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiInventoryEventsEventIdCommentsCommentId>>, { eventId: string; commentId: number }> = (props) => {
    const { eventId, commentId } = props ?? {};

    return deleteApiInventoryEventsEventIdCommentsCommentId(eventId, commentId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiInventoryEventsEventIdCommentsCommentIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiInventoryEventsEventIdCommentsCommentId>>>;

export type DeleteApiInventoryEventsEventIdCommentsCommentIdMutationError = AxiosError<void>;

export const useDeleteApiInventoryEventsEventIdCommentsCommentId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiInventoryEventsEventIdCommentsCommentId>>, TError, { eventId: string; commentId: number }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof deleteApiInventoryEventsEventIdCommentsCommentId>>, TError, { eventId: string; commentId: number }, TContext> => {
  const mutationOptions = getDeleteApiInventoryEventsEventIdCommentsCommentIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postApiInventorySnapshots = (options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/Inventory/Snapshots`, undefined, options);
};

export const getPostApiInventorySnapshotsMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiInventorySnapshots>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiInventorySnapshots>>, TError, void, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiInventorySnapshots>>, void> = () => {
    return postApiInventorySnapshots(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiInventorySnapshotsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiInventorySnapshots>>>;

export type PostApiInventorySnapshotsMutationError = AxiosError<void>;

export const usePostApiInventorySnapshots = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiInventorySnapshots>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postApiInventorySnapshots>>, TError, void, TContext> => {
  const mutationOptions = getPostApiInventorySnapshotsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiInventoryEventsEventIdSnapshots = (eventId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsInventoryInventorySnapshot[]>> => {
  return axios.get(`/api/Inventory/Events/${eventId}/Snapshots`, options);
};

export const getGetApiInventoryEventsEventIdSnapshotsQueryKey = (eventId: string) => {
  return [`/api/Inventory/Events/${eventId}/Snapshots`] as const;
};

export const getGetApiInventoryEventsEventIdSnapshotsQueryOptions = <TData = Awaited<ReturnType<typeof getApiInventoryEventsEventIdSnapshots>>, TError = AxiosError<void>>(
  eventId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEventsEventIdSnapshots>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiInventoryEventsEventIdSnapshotsQueryKey(eventId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiInventoryEventsEventIdSnapshots>>> = ({ signal }) =>
    getApiInventoryEventsEventIdSnapshots(eventId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!eventId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEventsEventIdSnapshots>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiInventoryEventsEventIdSnapshotsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiInventoryEventsEventIdSnapshots>>>;
export type GetApiInventoryEventsEventIdSnapshotsQueryError = AxiosError<void>;

export const useGetApiInventoryEventsEventIdSnapshots = <TData = Awaited<ReturnType<typeof getApiInventoryEventsEventIdSnapshots>>, TError = AxiosError<void>>(
  eventId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryEventsEventIdSnapshots>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiInventoryEventsEventIdSnapshotsQueryOptions(eventId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postApiInventoryListingsMerge = (
  barkerCoreModelsInventoryMergeInventoryRequest: BarkerCoreModelsInventoryMergeInventoryRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsInventoryListing>> => {
  return axios.post(`/api/Inventory/Listings/Merge`, barkerCoreModelsInventoryMergeInventoryRequest, options);
};

export const getPostApiInventoryListingsMergeMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiInventoryListingsMerge>>, TError, { data: BarkerCoreModelsInventoryMergeInventoryRequest }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiInventoryListingsMerge>>, TError, { data: BarkerCoreModelsInventoryMergeInventoryRequest }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiInventoryListingsMerge>>, { data: BarkerCoreModelsInventoryMergeInventoryRequest }> = (props) => {
    const { data } = props ?? {};

    return postApiInventoryListingsMerge(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiInventoryListingsMergeMutationResult = NonNullable<Awaited<ReturnType<typeof postApiInventoryListingsMerge>>>;
export type PostApiInventoryListingsMergeMutationBody = BarkerCoreModelsInventoryMergeInventoryRequest;
export type PostApiInventoryListingsMergeMutationError = AxiosError<void>;

export const usePostApiInventoryListingsMerge = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiInventoryListingsMerge>>, TError, { data: BarkerCoreModelsInventoryMergeInventoryRequest }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postApiInventoryListingsMerge>>, TError, { data: BarkerCoreModelsInventoryMergeInventoryRequest }, TContext> => {
  const mutationOptions = getPostApiInventoryListingsMergeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiInventorySeasons = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsInventorySeasonLocation[]>> => {
  return axios.get(`/api/Inventory/Seasons`, options);
};

export const getGetApiInventorySeasonsQueryKey = () => {
  return [`/api/Inventory/Seasons`] as const;
};

export const getGetApiInventorySeasonsQueryOptions = <TData = Awaited<ReturnType<typeof getApiInventorySeasons>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventorySeasons>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiInventorySeasonsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiInventorySeasons>>> = ({ signal }) => getApiInventorySeasons({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiInventorySeasons>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiInventorySeasonsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiInventorySeasons>>>;
export type GetApiInventorySeasonsQueryError = AxiosError<void>;

export const useGetApiInventorySeasons = <TData = Awaited<ReturnType<typeof getApiInventorySeasons>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventorySeasons>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiInventorySeasonsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiInventoryStatus = (options?: AxiosRequestConfig): Promise<AxiosResponse<string>> => {
  return axios.get(`/api/Inventory/Status`, options);
};

export const getGetApiInventoryStatusQueryKey = () => {
  return [`/api/Inventory/Status`] as const;
};

export const getGetApiInventoryStatusQueryOptions = <TData = Awaited<ReturnType<typeof getApiInventoryStatus>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryStatus>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiInventoryStatusQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiInventoryStatus>>> = ({ signal }) => getApiInventoryStatus({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryStatus>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiInventoryStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getApiInventoryStatus>>>;
export type GetApiInventoryStatusQueryError = AxiosError<void>;

export const useGetApiInventoryStatus = <TData = Awaited<ReturnType<typeof getApiInventoryStatus>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryStatus>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiInventoryStatusQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiInventoryStatusPointOfSaleId = (pointOfSaleId: BarkerCoreEnumsPointOfSale, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> => {
  return axios.get(`/api/Inventory/Status/${pointOfSaleId}`, options);
};

export const getGetApiInventoryStatusPointOfSaleIdQueryKey = (pointOfSaleId: BarkerCoreEnumsPointOfSale) => {
  return [`/api/Inventory/Status/${pointOfSaleId}`] as const;
};

export const getGetApiInventoryStatusPointOfSaleIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiInventoryStatusPointOfSaleId>>, TError = AxiosError<unknown>>(
  pointOfSaleId: BarkerCoreEnumsPointOfSale,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryStatusPointOfSaleId>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiInventoryStatusPointOfSaleIdQueryKey(pointOfSaleId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiInventoryStatusPointOfSaleId>>> = ({ signal }) =>
    getApiInventoryStatusPointOfSaleId(pointOfSaleId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!pointOfSaleId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryStatusPointOfSaleId>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiInventoryStatusPointOfSaleIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiInventoryStatusPointOfSaleId>>>;
export type GetApiInventoryStatusPointOfSaleIdQueryError = AxiosError<unknown>;

export const useGetApiInventoryStatusPointOfSaleId = <TData = Awaited<ReturnType<typeof getApiInventoryStatusPointOfSaleId>>, TError = AxiosError<unknown>>(
  pointOfSaleId: BarkerCoreEnumsPointOfSale,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryStatusPointOfSaleId>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiInventoryStatusPointOfSaleIdQueryOptions(pointOfSaleId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiInventoryPosPriceChangeStatusPointOfSaleId = (pointOfSaleId: BarkerCoreEnumsPointOfSale, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> => {
  return axios.get(`/api/Inventory/PosPriceChange/Status/${pointOfSaleId}`, options);
};

export const getGetApiInventoryPosPriceChangeStatusPointOfSaleIdQueryKey = (pointOfSaleId: BarkerCoreEnumsPointOfSale) => {
  return [`/api/Inventory/PosPriceChange/Status/${pointOfSaleId}`] as const;
};

export const getGetApiInventoryPosPriceChangeStatusPointOfSaleIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiInventoryPosPriceChangeStatusPointOfSaleId>>,
  TError = AxiosError<unknown>,
>(
  pointOfSaleId: BarkerCoreEnumsPointOfSale,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryPosPriceChangeStatusPointOfSaleId>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiInventoryPosPriceChangeStatusPointOfSaleIdQueryKey(pointOfSaleId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiInventoryPosPriceChangeStatusPointOfSaleId>>> = ({ signal }) =>
    getApiInventoryPosPriceChangeStatusPointOfSaleId(pointOfSaleId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!pointOfSaleId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiInventoryPosPriceChangeStatusPointOfSaleId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiInventoryPosPriceChangeStatusPointOfSaleIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiInventoryPosPriceChangeStatusPointOfSaleId>>>;
export type GetApiInventoryPosPriceChangeStatusPointOfSaleIdQueryError = AxiosError<unknown>;

export const useGetApiInventoryPosPriceChangeStatusPointOfSaleId = <
  TData = Awaited<ReturnType<typeof getApiInventoryPosPriceChangeStatusPointOfSaleId>>,
  TError = AxiosError<unknown>,
>(
  pointOfSaleId: BarkerCoreEnumsPointOfSale,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiInventoryPosPriceChangeStatusPointOfSaleId>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiInventoryPosPriceChangeStatusPointOfSaleIdQueryOptions(pointOfSaleId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
