import { IconPropsBase } from './IconPropsBase';

export default function PictureAsPDFIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M353.08-462.31h35.38v-80h44.62q15.07 0 25.23-10.15 10.15-10.16 10.15-25.23v-44.62q0-15.07-10.15-25.23-10.16-10.15-25.23-10.15h-80v195.38Zm35.38-115.38v-44.62h44.62v44.62h-44.62Zm120.77 115.38h78.46q15.08 0 25.23-10.15 10.16-10.16 10.16-25.23v-124.62q0-15.07-10.16-25.23-10.15-10.15-25.23-10.15h-78.46v195.38Zm35.38-35.38v-124.62h43.08v124.62h-43.08Zm123.08 35.38h35.39v-80h47.69v-35.38h-47.69v-44.62h47.69v-35.38h-83.08v195.38ZM322.31-260Q292-260 271-281q-21-21-21-51.31v-455.38Q250-818 271-839q21-21 51.31-21h455.38Q808-860 829-839q21 21 21 51.31v455.38Q850-302 829-281q-21 21-51.31 21H322.31Zm0-60h455.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-455.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H322.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v455.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85Zm-140 200Q152-120 131-141q-21-21-21-51.31v-515.38h60v515.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h515.38v60H182.31ZM310-800v480-480Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M353.08-458.31h43.38v-72h28.62q18.47 0 30.93-12.45 12.45-12.46 12.45-30.93v-28.62q0-18.47-12.45-30.93-12.46-12.45-30.93-12.45h-72v187.38Zm43.38-115.38v-28.62h28.62v28.62h-28.62Zm96.77 115.38h70.46q18.48 0 30.93-12.45 12.46-12.46 12.46-30.93v-100.62q0-18.47-12.46-30.93-12.45-12.45-30.93-12.45h-70.46v187.38Zm43.38-43.38v-100.62h27.08v100.62h-27.08Zm99.08 43.38h43.39v-72h55.69v-43.38h-55.69v-28.62h55.69v-43.38h-99.08v187.38ZM314.31-260q-27.01 0-45.66-18.65Q250-297.3 250-324.31v-455.38q0-27.01 18.65-45.66Q287.3-844 314.31-844h455.38q27.01 0 45.66 18.65Q834-806.7 834-779.69v455.38q0 27.01-18.65 45.66Q796.7-260 769.69-260H314.31Zm0-52h455.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-455.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H314.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v455.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85Zm-124 176q-27.01 0-45.66-18.65Q126-173.3 126-200.31v-507.38h52v507.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h507.38v52H190.31ZM302-792v480-480Z"
        />
      </svg>
    );
  }
}
