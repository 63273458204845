import classes from './AdminContentHeader.tsx.module.css';
import { Group, Title, Text, Stack } from '@mantine/core';
import { Link } from 'react-router-dom';
import ChevronBackIcon from '../../components/icons/ChevronBack';

interface AdminContentHeaderProps {
  title: string;
  backlinkTo?: string;
  backlinkTitle?: string;
  rightSection?: JSX.Element;
  sidebarPage?: boolean;
  sticky?: boolean;
}

export function AdminContentHeader({ title, backlinkTo, rightSection, sidebarPage = true, backlinkTitle, sticky = false }: AdminContentHeaderProps) {
  return (
    <>
      {sidebarPage && (
        <Group c="var(--colors-gray-5)" h={18} gap={0} align="center" justify="left" mt={-18} className={classes.backlink}>
          {backlinkTo && (
            <>
              <ChevronBackIcon size={12} />
              <Link to={backlinkTo}>
                <Text size="xs">
                  {backlinkTitle}
                </Text>
              </Link>
            </>
          )}
        </Group>
      )}
      <Group pt="md" pb="md" mt={0} className={sticky ? classes.titleWrapperSticky : classes.titleWrapper}>
        <Stack gap={0}>
          <Title order={3}>{title}</Title>
        </Stack>
        <Group mih={30} gap={12}>{rightSection}</Group>
      </Group>
    </>
  );
}
