import { IconPropsBase } from './IconPropsBase';

export default function RainyIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M555.307-89.77q-11.153 6.077-22.807 1.923-11.653-4.153-17.73-15.307l-63.846-127.693q-6.077-11.153-1.923-22.807 4.153-11.653 15.307-17.73 11.153-6.077 22.999-1.731 11.846 4.346 17.923 15.5l63.846 127.308q6.077 11.153 1.731 22.807-4.346 11.654-15.5 17.73Zm240.385 0q-11.153 6.077-22.807 1.923-11.654-4.153-17.73-15.307l-63.847-127.693q-6.077-11.153-1.923-22.807 4.154-11.653 15.308-17.73 11.153-6.077 22.999-1.731 11.846 4.346 17.923 15.5l63.846 127.308q6.077 11.153 1.731 22.807-4.346 11.654-15.5 17.73Zm-480.385 0q-11.153 6.077-22.999 1.923-11.846-4.153-17.923-15.307l-63.846-127.693q-6.077-11.153-1.731-22.807 4.346-11.653 15.5-17.73 11.153-6.077 22.807-1.731 11.654 4.346 17.73 15.5l63.847 127.308q6.077 11.153 1.923 22.807-4.154 11.654-15.308 17.73ZM300-330q-87.154 0-148.576-61.423Q90.001-452.846 90.001-540q0-80.307 53.653-139.615 53.654-59.307 133.5-69.154 30.846-56.615 84.423-88.923Q415.154-869.999 480-869.999q88.077 0 152.269 57.307 64.192 57.308 76.269 142.923 71.308 5.231 116.384 54.116Q869.999-566.769 869.999-500q0 70.769-49.615 120.384Q770.769-330.001 700-330.001H300Zm0-59.998h400q45.846 0 77.924-32.077 32.077-32.078 32.077-77.924 0-45.846-32.077-77.924-32.078-32.077-77.924-32.077h-49.999V-640q0-70.231-49.885-120.116Q550.231-810.001 480-810.001q-51.077 0-93.077 27.731-42.001 27.731-63.154 74.424l-7.308 17.845H298q-61.231 2-104.616 45.385T149.999-540q0 62.231 43.885 106.116T300-389.999ZM480-600Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M547.307-105.77q-10.153 5.077-19.807 1.923-9.653-3.153-14.73-13.307l-63.846-127.693q-5.077-10.153-1.923-19.807 3.153-9.653 13.307-14.73 9.153-5.077 19.499-1.731 10.346 3.346 15.423 13.5l63.846 127.308q5.077 10.153 1.731 19.807-3.346 9.654-13.5 14.73Zm198.385 0q-10.153 5.077-20.307 1.923-10.154-3.153-15.23-13.307l-63.847-127.693q-5.077-10.153-1.423-19.807 3.654-9.653 13.808-14.73 10.153-5.077 19.999-1.731 9.846 3.346 14.923 13.5l63.846 127.308q5.077 10.153 1.731 19.807-3.346 9.654-13.5 14.73Zm-396.385 0q-10.153 5.077-20.499 1.923-10.346-3.153-15.423-13.307l-63.846-127.693q-5.077-10.153-1.231-19.807 3.846-9.653 14-14.73 10.153-5.077 19.807-1.731 9.654 3.346 14.73 13.5l63.847 127.308q5.077 10.153 1.923 19.807-3.154 9.654-13.308 14.73ZM295-346.001q-81.154 0-135.076-58.423Q106.001-462.846 106.001-545q0-74.307 51.153-127.115 51.154-52.807 127-61.654 28.846-55.615 81.423-87.923Q418.154-853.999 480-853.999q83.077 0 143.769 54.307 60.692 54.308 72.769 136.923 69.308 1.231 113.384 49.116Q853.999-565.769 853.999-504q0 65.769-46.115 111.884Q761.769-346.001 696-346.001H295Zm5-51.998h396q43.846 0 74.924-31.077 31.077-31.078 31.077-74.924 0-43.846-31.077-74.924-31.078-31.077-74.924-31.077h-42.999l-9-49.999q-8-61.231-54.885-101.616T480-802.001q-49.077 0-89.577 26.731-40.501 26.731-61.654 71.424l-11.308 21.845H300q-59.231 0-100.616 41.385T157.999-540q0 59.231 41.385 100.616T300-397.999ZM480-600Z"
        />
      </svg>
    );
  }
}
