import { BarkerCoreEnumsAdjustmentType, BarkerCoreEnumsMarketplace, BarkerCoreModelsPricingRuleFilters } from '../api';
import { Rule } from '../types';

export const DEFAULT_FILTERS = {
  noFilters: false,
  exclusions: [],
  deliveryExclusions: [],
  ignoredListingIds: [],
  maxPrice: undefined,
  outlierCriteria: undefined,
  minPrice: undefined,
  rows: '',
  sectionIds: [],
  splits: [],
} as BarkerCoreModelsPricingRuleFilters;

export const DEFAULT_RULE = {
  floorPrice: undefined,
  ceilingPrice: undefined,
  numActive: 2147483647,
  adjustmentTypeId: BarkerCoreEnumsAdjustmentType.Amount,
  staggerByTypeId: BarkerCoreEnumsAdjustmentType.Percentage,
  staggerByValue: 1.05,
  adjustmentValue: undefined,
  filters: { ...DEFAULT_FILTERS },
  isAutoPriced: false,
  marketplaceId: BarkerCoreEnumsMarketplace.SeatGeek,
  numComparables: 1,
  autoAdjustSplits: false,
} satisfies Omit<Rule, 'ruleId'>;

export const DEFAULT_RULE_SETTINGS = {
  broadcastSingleListing: true,
  intervalMinutes: 1440,
  colorPricesBelowCost: false,
};
