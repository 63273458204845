import { IconPropsBase } from './IconPropsBase';

export default function PriceChangeIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M330.001-290.001h59.998v-40h44.616q15.077 0 25.231-10.153 10.153-10.154 10.153-25.231v-109.23q0-15.077-10.153-25.231-10.154-10.153-25.231-10.153H309.999v-60.002h160v-59.998h-80v-40h-59.998v40h-44.616q-15.077 0-25.231 10.153-10.153 10.154-10.153 25.231v109.23q0 15.077 10.153 25.231 10.154 10.153 25.231 10.153h124.616v60.002h-160v59.998h80v40ZM640-315.385l70.769-70.769H569.231L640-315.385Zm-70.769-248.461h141.538L640-634.615l-70.769 70.769ZM172.309-180.001q-30.308 0-51.308-21t-21-51.308v-455.382q0-30.308 21-51.308t51.308-21h615.382q30.308 0 51.308 21t21 51.308v455.382q0 30.308-21 51.308t-51.308 21H172.309Zm0-59.999h615.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-455.382q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H172.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v455.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846ZM160-240V-720-240Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M334.001-298.001h51.998v-48h40.616q18.477 0 30.931-12.453 12.453-12.454 12.453-30.931v-73.23q0-18.477-12.453-30.931-12.454-12.453-30.931-12.453H301.999v-56.002h168v-51.998h-84v-48h-51.998v48h-40.616q-18.477 0-30.931 12.453-12.453 12.454-12.453 30.931v73.23q0 18.477 12.453 30.931 12.454 12.453 30.931 12.453h124.616v56.002h-168v51.998h84v48ZM648-341.385l62.769-62.769H585.231L648-341.385Zm-62.769-214.461h125.538L648-618.615l-62.769 62.769ZM180.309-212.001q-27.008 0-45.658-18.662-18.65-18.662-18.65-45.686v-407.626q0-27.024 18.65-45.524t45.658-18.5h599.382q27.008 0 45.658 18.662 18.65 18.662 18.65 45.686v407.626q0 27.024-18.65 45.524t-45.658 18.5H180.309Zm0-51.999h599.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-407.382q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H180.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v407.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846ZM168-264V-696-264Z"
        />
      </svg>
    );
  }
}
