import { IconPropsBase } from './IconPropsBase';

export default function PersonIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 96 960 960">
        <path
          fill={color}
          d="M480 563.691q-57.749 0-98.874-41.124-41.125-41.125-41.125-98.874 0-57.75 41.125-98.874 41.125-41.125 98.874-41.125 57.749 0 98.874 41.125 41.125 41.124 41.125 98.874 0 57.749-41.125 98.874-41.125 41.124-98.874 41.124ZM180.001 868.306v-88.922q0-29.384 15.962-54.422 15.961-25.038 42.653-38.5 59.308-29.077 119.654-43.615T480 628.309q61.384 0 121.73 14.538 60.346 14.538 119.654 43.615 26.692 13.462 42.653 38.5 15.962 25.038 15.962 54.422v88.922H180.001ZM240 808.307h480v-28.923q0-12.154-7.039-22.5-7.038-10.346-19.115-16.885-51.692-25.461-105.418-38.577Q534.702 688.307 480 688.307t-108.428 13.115q-53.726 13.116-105.418 38.577-12.077 6.539-19.115 16.885Q240 767.23 240 779.384v28.923Zm240-304.614q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm0-80Zm0 384.614Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 96 960 960">
        <path
          fill={color}
          d="M480 563.691q-51.749 0-87.874-36.124-36.125-36.125-36.125-87.874 0-51.75 36.125-87.874 36.125-36.125 87.874-36.125 51.749 0 87.874 36.125 36.125 36.124 36.125 87.874 0 51.749-36.125 87.874-36.125 36.124-87.874 36.124ZM212.001 836.306v-72.922q0-18.384 10.962-35.422 10.961-17.038 30.653-29.5 52.308-30.077 109.946-46.115t116.23-16.038q58.592 0 116.438 16.038 57.846 16.038 110.154 46.115 19.692 11.462 30.653 29 10.962 17.538 10.962 35.922v72.922H212.001ZM264 784.307h432v-20.923q0-6.33-4.564-11.912-4.563-5.581-12.59-9.473-43.692-26.461-94.418-40.077Q533.702 688.307 480 688.307t-104.428 13.615q-50.726 13.616-94.418 40.077-8.077 5.539-12.615 10.609Q264 757.679 264 763.384v20.923Zm216.212-272.614q29.788 0 50.788-21.213 21-21.212 21-51 0-29.787-21.212-50.787-21.213-21-51-21-29.788 0-50.788 21.212-21 21.213-21 51 0 29.788 21.212 50.788 21.213 21 51 21Zm-.212-72Zm0 344.614Z"
        />
      </svg>
    );
  }
}
