import { IconPropsBase } from './IconPropsBase';

export default function CommentIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="M250.001-410.001h459.998v-59.998H250.001v59.998Zm0-120h459.998v-59.998H250.001v59.998Zm0-120h459.998v-59.998H250.001v59.998Zm609.998 531.537L718.461-260.001H172.309q-30.308 0-51.308-21t-21-51.308v-455.382q0-30.308 21-51.308t51.308-21h615.382q30.308 0 51.308 21t21 51.308v669.227ZM172.309-320h571.69L800-264.615v-523.076q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H172.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v455.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846ZM160-320V-800v480Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="M250.001-394.001h459.998v-51.998H250.001v51.998Zm0-132h459.998v-51.998H250.001v51.998Zm0-132h459.998v-51.998H250.001v51.998Zm593.998 523.537L718.461-260.001H180.309q-27.008 0-45.658-18.65-18.65-18.65-18.65-45.658v-455.382q0-27.008 18.65-45.658 18.65-18.65 45.658-18.65h599.382q27.008 0 45.658 18.65 18.65 18.65 18.65 45.658v645.227ZM180.309-312h559.69L792-259.615v-520.076q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H180.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v455.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846ZM168-312V-792v480Z" />
      </svg>
    );
  }
}
