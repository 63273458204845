import { IconPropsBase } from './IconPropsBase';

export default function VerticalAlignTopIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path fill={color} d="M11.25 20.75V10.2L8.4 13.05 7.35 12 12 7.35 16.65 12l-1.05 1.05-2.85-2.85v10.55Zm-7-16v-1.5h15.5v1.5Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path fill={color} d="M9.458 16.792V8.375l-2.396 2.396-.77-.771L10 6.292 13.708 10l-.77.771-2.396-2.396v8.417Zm-5.25-12.5V3.208h11.584v1.084Z" />
      </svg>
    );
  }
}
