import { IconPropsBase } from './IconPropsBase';

export default function SportsBasketballIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M161.231-509.999h126.308q-4.846-42.616-23.769-81.193-18.923-38.578-48.385-68.039-22.231 32.461-36.27 69.962-14.038 37.5-17.884 79.27Zm511.23 0h126.308q-3.846-41.385-17.884-78.693-14.039-37.308-36.27-69.77-30.616 29.846-48.962 67.847-18.346 38-23.192 80.616ZM215.385-301.538q29.462-29.846 48.385-67.847 18.923-38 23.769-80.616H161.231q4.23 41.77 18.077 78.886 13.846 37.115 36.077 69.577Zm529.23 0q22.231-32.462 36.27-69.77 14.038-37.308 17.884-78.693H672.461q4.846 42.616 23.192 80.616 18.346 38.001 48.962 67.847ZM348.769-509.999h101.232v-288.77Q392-792.693 341.692-768.692q-50.308 24-88.616 63.616 39.77 39.154 64.693 88.615 24.923 49.462 31 106.462Zm161.23 0h101.232q6.077-57 30.808-106.847 24.731-49.846 64.885-88.23-38.308-39.616-88.616-63.616Q568-792.693 509.999-798.769v288.77Zm-59.998 348.768v-288.77H348.769q-6.077 57.77-30.808 106.847-24.731 49.076-64.885 87.461 38.308 39.616 88.232 64.001 49.923 24.385 108.693 30.461Zm59.998 0q58.001-6.076 108.309-30.077 50.308-24 88.616-63.616-40.154-38.384-64.885-87.846-24.731-49.461-30.808-107.231H509.999v288.77ZM480-480Zm0 379.999q-78.769 0-148.115-29.962-69.346-29.961-120.653-81.269-51.308-51.307-81.269-120.653Q100.001-401.231 100.001-480t29.962-148.115q29.961-69.346 81.269-120.653 51.307-51.308 120.653-81.269Q401.231-859.999 480-859.999t148.115 29.962q69.346 29.961 120.653 81.269 51.308 51.307 81.269 120.653Q859.999-558.769 859.999-480t-29.962 148.115q-29.961 69.346-81.269 120.653-51.307 51.308-120.653 81.269Q558.769-100.001 480-100.001Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M169.231-505.999h129.308q-3.846-44.616-20.269-87.193-16.423-42.578-47.885-75.039-27.231 35.461-42.27 76.462-15.038 41-18.884 85.77Zm492.23 0h129.308q-3.846-44.385-18.884-85.193-15.039-40.808-42.27-76.27-31.616 32.846-47.462 74.847-15.846 42-20.692 86.616ZM221.385-306.538q27.462-32.846 48.385-69.347 20.923-36.5 24.769-78.116H169.231q4.23 40.77 16.577 77.886 12.346 37.115 35.577 69.577Zm517.23 0q24.231-32.462 36.27-69.77 12.038-37.308 15.884-77.693H665.461q3.846 41.616 23.692 78.616 19.846 37.001 49.462 68.847ZM349.769-505.999h104.232v-284.77Q401-787.693 352.692-765.192q-48.308 22.5-86.616 59.116 38.77 41.154 59.693 93.115 20.923 51.962 24 106.962Zm156.23 0h104.232q4.077-55 23.808-107.347 19.731-52.346 59.885-91.73-38.308-36.616-86.616-59.616Q559-787.693 505.999-790.769v284.77Zm-51.998 336.768v-284.77H346.769q-3.077 54.77-28.808 101.847-25.731 47.076-64.885 85.461 40.308 41.616 91.732 67.001 51.423 25.385 109.193 30.461Zm51.998 0q57.001-5.076 108.309-30.077 51.308-25 91.616-66.616-39.154-38.384-64.385-85.846-25.231-47.461-28.308-102.231H505.999v284.77ZM480-480Zm0 363.999q-74.769 0-141.115-28.462-66.346-28.461-116.153-78.269-49.808-49.807-78.269-116.153Q116.001-405.231 116.001-480q0-75.769 28.462-141.615 28.461-65.846 78.269-115.653 49.807-49.808 116.153-78.269Q405.231-843.999 480-843.999q75.769 0 141.615 28.462 65.846 28.461 115.653 78.269 49.808 49.807 78.269 115.653Q843.999-555.769 843.999-480q0 74.769-28.462 141.115-28.461 66.346-78.269 116.153-49.807 49.808-115.653 78.269Q555.769-116.001 480-116.001Z"
        />
      </svg>
    );
  }
}
