export type BarkerCoreEnumsUserRole = (typeof BarkerCoreEnumsUserRole)[keyof typeof BarkerCoreEnumsUserRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BarkerCoreEnumsUserRole = {
  None: 'None',
  GlobalAdmin: 'GlobalAdmin',
  TenantAdmin: 'TenantAdmin',
  User: 'User',
  ReadOnlyUser: 'ReadOnlyUser',
} as const;
