import { IconPropsBase } from './IconPropsBase';

export default function CreditCardIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M860-707.69v455.38Q860-222 839-201q-21 21-51.31 21H172.31Q142-180 121-201q-21-21-21-51.31v-455.38Q100-738 121-759q21-21 51.31-21h615.38Q818-780 839-759q21 21 21 51.31Zm-700 83.85h640v-83.85q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H172.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v83.85Zm0 127.68v243.85q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h615.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-243.85H160ZM160-240v-480 480Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M844-683.69v407.38Q844-250 825.35-231q-18.65 19-45.66 19H180.31Q154-212 135-231q-19-19-19-45.31v-407.38Q116-710 135-729q19-19 45.31-19h599.38q27.01 0 45.66 19Q844-710 844-683.69Zm-676 75.85h624v-75.85q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H180.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v75.85Zm0 111.68v219.85q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h599.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-219.85H168ZM168-264v-432 432Z"
        />
      </svg>
    );
  }
}
