import { IconPropsExtended } from './IconPropsExtended';

export default function EditIcon({ size = 20, color = 'currentColor' }: IconPropsExtended) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 96 960 960" width={size}>
        <path
          fill={color}
          d="M206.154 856h49.461l370.387-370.386-49.461-49.462-370.387 370.387V856Zm548.152-413.77L619.309 308.463l52.154-52.153q17.615-17.615 42.845-17.615t42.845 17.615l48.692 48.691q17.615 17.615 18.23 42.23.615 24.615-17 42.23l-52.769 52.769Zm-43.383 43.999-429.77 429.77H146.156V781.001l429.769-429.77 134.998 134.998Zm-109.844-25.538-24.538-24.539 49.461 49.462-24.923-24.923Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 96 960 960" width={size}>
        <path
          fill={color}
          d="M210.154 852h52.461l361.387-361.386-52.461-52.462-361.387 361.387V852Zm549.152-404.77L615.309 302.463l45.154-45.153q19.615-19.615 45.845-19.615t45.845 19.615l52.692 52.691q18.615 18.615 18.23 46.23-.385 27.615-19 46.23l-44.769 44.769Zm-44.383 43.999-424.77 424.77H146.156V772.001l424.769-424.77 143.998 143.998Zm-116.844-26.538-26.538-26.539 52.461 52.462-25.923-25.923Z"
        />
      </svg>
    );
  }
}
