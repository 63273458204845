import { Box, Group, HoverCard, Text } from '@mantine/core';
import { ReactNode } from 'react';
import classes from './InputGroup.module.css';
import ErrorFilledIcon from '../icons/ErrorFilled';

type BNInputGroupProps = {
  label: ReactNode;
  children: ReactNode;
  error?: ReactNode;
  errorPosition?: 'auto' | 'bottom';
  w?: string | number;
};

type BNInputGroupItemsProps = {
  children: ReactNode;
  w?: string | number;
  zIndex?: number;
};

function Item({ children, w, zIndex }: BNInputGroupItemsProps) {
  return (
    <Box w={w} maw={w} style={{ flexBasis: w, zIndex: zIndex }}>
      {children}
    </Box>
  );
}

type BNInputGroupErrorProps = {
  error?: ReactNode;
  label: ReactNode;
};

function ErrorHoverCard({ error, label }: BNInputGroupErrorProps) {
  return (
    <HoverCard
      withinPortal
      withArrow
      position="top"
      shadow="md"
      styles={{
        arrow: {
          borderColor: 'var(--colors-red-error)',
        },
        dropdown: {
          maxWidth: 220,
          borderColor: 'var(--colors-red-error)',
          color: 'var(--colors-gray-7)',
          textAlign: 'center',
        },
      }}
    >
      <HoverCard.Target>
        <Text component="span" display="flex" p={0} fw={500} style={{ alignItems: 'end', gap: 4 }}>
          <Text component="span" c="var(--colors-red-error)">
            {label}
          </Text>
          <ErrorFilledIcon color="var(--colors-red-error)" size={20} />
        </Text>
      </HoverCard.Target>
      {!!error && (
        <HoverCard.Dropdown>
          <Text size="xs">{error}</Text>
        </HoverCard.Dropdown>
      )}
    </HoverCard>
  );
}

function BNInputGroup({ label, children, error, errorPosition, w }: BNInputGroupProps) {
  return (
    <Box>
      {label && (
        <Text component="label" display="inline-block" fz="xs" fw={500} lh="1.25rem" c={error ? 'var(--colors-red-error)' : 'var(--colors-gray-5)'} style={{ alignItems: 'end' }}>
          {error && errorPosition !== 'bottom' ? <ErrorHoverCard label={label} error={error} /> : label}
        </Text>
      )}
      <Group w={w} wrap="nowrap" gap={0} className={error ? `${classes.inputGroup} error` : `${classes.inputGroup}`}>
        {children}
      </Group>
      {errorPosition === 'bottom' && (
        <Text c="var(--colors-red-error)" fz="xs">
          {error}
        </Text>
      )}
    </Box>
  );
}

BNInputGroup.Item = Item;
export default BNInputGroup;
