import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { notifications } from '@mantine/notifications';
import ErrorIcon from '../components/icons/Error';
import { BarkerCoreEnumsUserRole } from '../api';
import { datadogRum } from '@datadog/browser-rum';
import { isDtiHosted } from '../utils/whitelabel-consts';

const AUTH_ERRORS = [401, 403];
const EXCLUDE_ERRORS = [404].concat(AUTH_ERRORS);

axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;
axios.defaults.paramsSerializer = {
  indexes: null,
};
axios.interceptors.response.use((originalResponse) => {
  handleDates(originalResponse.data);
  return originalResponse;
});

if (isDtiHosted) {
  axios.interceptors.response.use((response) => {
    if (response.config.method !== 'get' && response.status === 200 && typeof response.data === 'object' && 'allSucceeded' in response.data && !response.data.allSucceeded) {
      const message = response.data.details?.[0]?.messages;
      return Promise.reject(new Error(`Operation Failed: ${message}`));
    }
    return response;
  });
}

let accessTokenInterceptor: number | undefined;
let currentUserRoleIdInterceptor: number | undefined;

export function setAxiosTokenInterceptor(accessToken: string) {
  if (accessTokenInterceptor !== undefined) {
    axios.interceptors.request.eject(accessTokenInterceptor);
  }

  accessTokenInterceptor = axios.interceptors.request.use(
    (req) => {
      if (accessToken && req.url?.startsWith('/api')) {
        req.headers.Authorization = `Bearer ${accessToken}`;
      }

      if (req.url?.startsWith('/dti')) {
        req.baseURL = import.meta.env.VITE_API_DTI_BASE_URL;
        req.url = req.url.replace('/dti', '');

        if (req.url.includes('/edit')) {
          // eslint-disable-next-line prefer-destructuring
          req.url = req.url.split('/edit')[0];
        }
        req.headers['Auth-Token'] = accessToken;
      }

      return req;
    },
    null,
    { synchronous: true },
  );
}

export function setUserRoleInterceptor(roleId: BarkerCoreEnumsUserRole) {
  if (currentUserRoleIdInterceptor !== undefined) {
    axios.interceptors.request.eject(currentUserRoleIdInterceptor);
  }

  if (roleId === 'ReadOnlyUser') {
    currentUserRoleIdInterceptor = axios.interceptors.request.use((req) => {
      if ((req.method === 'post' || req.method === 'put' || req.method === 'delete' || req.method === 'patch') && req.url?.startsWith('/api')) {
        // Allowed Methods
        if (req.method === 'put' && (req.url?.includes('/Snapshots/Filtered') || req.url?.includes('/api/Principals/Bulletins'))) {
          return req;
        }
        // showReadOnlyAlert();
        const cancelToken = axios.CancelToken.source();
        req.cancelToken = cancelToken.token;
        cancelToken.cancel('[TITLE:Read Only Mode]The save operation was not performed due to user permissions.');
      }

      return req;
    });
  }
}

// Intentionally mutable for now until AUTH system is in place.
// eslint-disable-next-line import/no-mutable-exports
let tenantInterceptor: number | undefined;

export const updateTenantId = (tenantIds: string[]) => {
  if (tenantInterceptor !== undefined) {
    axios.interceptors.request.eject(tenantInterceptor);
  }

  tenantInterceptor = axios.interceptors.request.use(
    (request) => {
      request.headers = request.headers || {};
      if (request.url?.startsWith('/api') && !request.headers.get('x-tenant-id')) {
        request.headers.set('x-tenant-id', tenantIds.join(','));
      }
      return request;
    },
    null,
    { synchronous: true },
  );
};

const excludeRetries = [404, 403, 401, 400];

const handleException = (error: unknown) => {
  const axiosError = error as AxiosError;
  if (axiosError.message !== 'canceled' && !EXCLUDE_ERRORS.includes(axiosError.response?.status || 0) && !axiosError.config?.url?.endsWith('/Viewed')) {
    const title = axiosError.message.match(/\[TITLE:(.*?)\]/)?.[1] || 'Unexpected error.';
    const message = title !== 'Unexpected error.' ? axiosError.message.replace(/\[TITLE:(.*?)\]/, '') : `${axiosError.message}. If this problem persists please contact support.`;
    notifications.show({
      title,
      message,
      icon: <ErrorIcon size={24} />,
      color: 'red',
    });
    if (import.meta.env.VITE_DATADOG_ENVIRONMENT === 'production' || import.meta.env.VITE_DATADOG_ENVIRONMENT === 'testing') {
      datadogRum.addError(axiosError, {
        url: axiosError.config?.url,
        tenantId: axiosError.config?.headers['x-tenant-id'],
      });
    }
  } else if (axiosError.message === 'canceled') {
    datadogRum.addAction('canceled-request', {
      url: axiosError.config?.url,
      tenantId: axiosError.config?.headers['x-tenant-id'],
    });
  }
};

export const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: handleException,
  }),
  queryCache: new QueryCache({
    // onError: handleException,
  }),
  defaultOptions: {
    queries: {
      staleTime: 5000,
      cacheTime: 5000,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (axios.isAxiosError(error)) {
          if (excludeRetries.includes(error.response?.status || 0)) {
            handleException(error);
            return false;
          }
        }
        const shouldRetry = failureCount < 3;
        if (!shouldRetry) {
          handleException(error);
        }
        return shouldRetry;
      },
    },
  },
});

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value);
}

export function handleDates(body: { [key: string]: unknown } | null | undefined) {
  if (body === null || body === undefined || typeof body !== 'object') return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) {
      // eslint-disable-next-line no-param-reassign
      body[key] = dayjs(value as string).toDate(); // Day.js conversion
    } else if (typeof value === 'object') {
      handleDates(value as { [key: string]: unknown });
    }
  }
  return body;
}
