import { useCallback, useEffect, useMemo, useRef, DependencyList, EffectCallback, MutableRefObject } from 'react';
import { weakEquals } from './weakEquals';

type DependencyOptions = {
  deepDeps: DependencyList;
  deps?: DependencyList;
};

export function useMemoDeep<T>(factory: () => T, deepDeps: DependencyList | DependencyOptions, deps: React.DependencyList = []): T {
  const _deepDeps = (Array.isArray(deepDeps) ? deepDeps : (deepDeps as DependencyOptions).deepDeps) || [];
  const _deps = (Array.isArray(deepDeps) ? deps : (deepDeps as DependencyOptions).deps) || [];
  const previousDepsRef: MutableRefObject<any> = useRef([]);
  const computedValueRef = useRef<T>();

  const deepDepsChanged = _deepDeps.some((value: any, index) => !weakEquals(value, previousDepsRef.current[index]));
  const regularDepsChanged = useMemo(() => {
    const changed = deps.some((value, index) => value !== previousDepsRef.current[index] && !_deepDeps.includes(previousDepsRef.current[index]));
    if (changed) {
      previousDepsRef.current = [..._deepDeps, ...deps];
    }
    return changed;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_deps, _deepDeps]);

  if (deepDepsChanged || regularDepsChanged || computedValueRef.current === undefined) {
    previousDepsRef.current = [..._deepDeps, ...deps];
    computedValueRef.current = factory();
  }
  return computedValueRef.current as T;
}

export function useCallbackDeep<T extends Function>(callback: T, deepDeps: DependencyList | DependencyOptions, deps: DependencyList = []): T {
  const _deepDeps = Array.isArray(deepDeps) ? deepDeps : (deepDeps as DependencyOptions).deepDeps;
  const _deps = Array.isArray(deepDeps) ? deps : (deepDeps as DependencyOptions).deps || [];
  const previousDepsRef = useRef<DependencyList>([]);

  // @ts-ignore
  const deepDepsChanged = _deepDeps.some((value, index) => !weakEquals(value, previousDepsRef.current[index]));
  const regularDepsChanged = useMemo(() => {
    const changed = deps.some((value, index) => value !== previousDepsRef.current[index] && !_deepDeps.includes(previousDepsRef.current[index]));
    if (changed) {
      previousDepsRef.current = [..._deepDeps, ..._deps];
    }
    return changed;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps, deepDeps]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCallback = useCallback(callback, [deepDepsChanged, regularDepsChanged]);

  return memoizedCallback;
}

export function useEffectDeep(effect: EffectCallback, deepDeps: DependencyList | DependencyOptions, deps: DependencyList = []): void {
  const _deepDeps = Array.isArray(deepDeps) ? deepDeps : (deepDeps as DependencyOptions).deepDeps;
  const _deps = Array.isArray(deepDeps) ? deps : (deepDeps as DependencyOptions).deps || [];
  const previousDepsRef = useRef<DependencyList>([]);
  const previousDeepDepsRef = useRef<DependencyList>([]);

  useEffect(() => {
    // @ts-ignore
    const deepDepsChanged = _deepDeps.some((value, index) => !weakEquals(value, previousDeepDepsRef.current[index]));
    // @ts-ignore
    const shallowDepsChanged = _deps.some((value, index) => !weakEquals(value, previousDepsRef.current[index]));

    if (deepDepsChanged || shallowDepsChanged) {
      effect();
    }

    previousDepsRef.current = _deps;
    previousDeepDepsRef.current = _deepDeps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [..._deps, ..._deepDeps]);
}

export function useDidUpdateDeep(effect: EffectCallback, deepDeps: DependencyList | DependencyOptions, deps: DependencyList = []): void {
  const _deepDeps = Array.isArray(deepDeps) ? deepDeps : (deepDeps as DependencyOptions).deepDeps;
  const _deps = Array.isArray(deepDeps) ? deps : (deepDeps as DependencyOptions).deps || [];
  const previousDepsRef = useRef<DependencyList>(_deps);
  const previousDeepDepsRef = useRef<DependencyList>(_deepDeps);

  useEffect(() => {
    // @ts-ignore
    const deepDepsChanged = _deepDeps.some((value, index) => !weakEquals(value, previousDeepDepsRef.current[index]));
    // @ts-ignore
    const shallowDepsChanged = _deps.some((value, index) => !weakEquals(value, previousDepsRef.current[index]));

    if (deepDepsChanged || shallowDepsChanged) {
      effect();
    }

    previousDepsRef.current = _deps;
    previousDeepDepsRef.current = _deepDeps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_deepDeps, _deps]);
}
