import { IconPropsBase } from './IconPropsBase';

export default function BarChartIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M7.375 16.75h1.5v-6.5h-1.5Zm3.875 0h1.5v-9.5h-1.5Zm3.875 0h1.5v-3.5h-1.5ZM5.3 20.5q-.75 0-1.275-.525Q3.5 19.45 3.5 18.7V5.3q0-.75.525-1.275Q4.55 3.5 5.3 3.5h13.4q.75 0 1.275.525.525.525.525 1.275v13.4q0 .75-.525 1.275-.525.525-1.275.525Zm0-1.5h13.4q.1 0 .2-.1t.1-.2V5.3q0-.1-.1-.2t-.2-.1H5.3q-.1 0-.2.1t-.1.2v13.4q0 .1.1.2t.2.1ZM5 5v14V5Z"
        ></path>
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M6.312 13.792h1.084V8.208H6.312Zm6.396 0h1.084v-2.584h-1.084Zm-3.354 0h1.084V6.208H9.354ZM4.75 16.583q-.562 0-.948-.385-.385-.386-.385-.948V4.75q0-.562.385-.948.386-.385.948-.385h10.5q.562 0 .948.385.385.386.385.948v10.5q0 .562-.385.948-.386.385-.948.385Zm0-1.083h10.5q.083 0 .167-.083.083-.084.083-.167V4.75q0-.083-.083-.167-.084-.083-.167-.083H4.75q-.083 0-.167.083-.083.084-.083.167v10.5q0 .083.083.167.084.083.167.083Zm-.25-11v11-11Z"
        ></path>
      </svg>
    );
  }
}
