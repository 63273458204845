import { Center, Flex, Progress, Text } from '@mantine/core';
import { MutableRefObject, useEffect, useState } from 'react';
import ArrowForwardIcon from '../../components/icons/ArrowForward';
import { isMobile } from 'react-device-detect';

type PullToRefreshProps = {
  wrapperRef: MutableRefObject<any>;
  listClass: string;
  refreshAction: () => void;
  topPadding?: number;
  isRefreshing: boolean;
};

function BNPullToRefresh({ wrapperRef, listClass, refreshAction, topPadding = 0, isRefreshing }: PullToRefreshProps) {
  const [startPosition, setStartPosition] = useState(0);
  const [pullPosition, setPullPosition] = useState(0);
  const [distanceFromTop, setDistanceFromTop] = useState(0);

  const scrollAreaRef = document.querySelector(`.${listClass}`);

  const handleTouchStart = (e: TouchEvent) => {
    const { clientY } = e.targetTouches[0];
    setStartPosition(clientY);
    if (wrapperRef.current) {
      const distance = wrapperRef?.current.getBoundingClientRect().top;
      setDistanceFromTop(distance);
    }
  };

  const handleTouchMove = (e: TouchEvent) => {
    const touch = e.targetTouches[0];
    const { clientY } = touch;
    const pullLength = startPosition < clientY ? Math.abs(clientY - startPosition) : 0;
    if (startPosition > distanceFromTop - 8 && ((scrollAreaRef && scrollAreaRef.scrollTop <= 0) || !scrollAreaRef)) {
      setPullPosition(pullLength);

      // once pulling has started, set the scroll container to overflow:hidden to prevent scroll/touch event confusion or interference
      if (pullLength > 10) {
        scrollAreaRef?.classList.add('hide-overflow');
      }
    }
  };

  const handleTouchEnd = () => {
    if (pullPosition && pullPosition > REFRESH_THRESHOLD && ((scrollAreaRef && scrollAreaRef.scrollTop <= 0) || !scrollAreaRef)) {
      refreshAction();
    }
    setStartPosition(0);
    setPullPosition(0);
    scrollAreaRef?.classList.remove('hide-overflow');
  };

  // Pull to Refresh Event Listeners
  useEffect(() => {
    if (isMobile && wrapperRef.current) {
      const scrollList = wrapperRef.current;
      // Add event listeners
      scrollList.addEventListener('touchstart', handleTouchStart as EventListener);
      scrollList.addEventListener('touchmove', handleTouchMove as EventListener);
      scrollList.addEventListener('touchend', handleTouchEnd as EventListener);
      return () => {
        scrollList?.removeEventListener('touchstart', handleTouchStart as EventListener);
        scrollList?.removeEventListener('touchmove', handleTouchMove as EventListener);
        scrollList?.removeEventListener('touchend', handleTouchEnd as EventListener);
      };
    }
    return undefined;
  });

  const REFRESH_THRESHOLD = 140;
  const pct = (pullPosition / REFRESH_THRESHOLD > 1 ? 1 : pullPosition / REFRESH_THRESHOLD) * 100;
  return (
    <>
      <Flex
        w="100%"
        h={pullPosition > 0 || isRefreshing ? 4 : 0}
        bg="var(--colors-divider)"
        pos="relative"
        direction="column"
        opacity={pullPosition > 0 || isRefreshing ? 1 : 0}
        style={{ overflow: 'visible', zIndex: 1, transition: '0.2s ease-in-out', transform: `translateY(-${topPadding}px)` }}
      >
        {pullPosition > 0 && (
          <Progress radius={0} value={pct} size={4} color="var(--colors-brandcolor-5)" bg="var(--colors-gray-3)" w="100%" bottom={0} left={0} right={0} top={0} />
        )}
        {isRefreshing && <Progress radius={0} value={100} w="100%" animated color="var(--colors-brandcolor-4)" size={4} />}
        {pct > 0 && pct < 100 && (
          <Center w="100%" h={40} pos="absolute" top={0}>
            <Flex
              align="center"
              justify="center"
              gap={4}
              opacity={pct > 40 ? 1 : 0}
              style={{ transition: '.2s ease-in-out', transform: pct > 40 ? 'translateY(0px)' : 'translateY(-6px)' }}
            >
              <Center style={{ transform: 'rotate(90deg)' }}>
                <ArrowForwardIcon color="var(--colors-brandcolor-6)" />
              </Center>
              <Text c="var(--colors-gray-5)" fw={600} size="xs" p={0}>
                Pull to Refresh
              </Text>
            </Flex>
          </Center>
        )}
        {pct >= 100 && (
          <Center w="100%" h={40} pos="absolute" top={0}>
            <Flex align="center" justify="center" gap={4} opacity={pct > 50 ? 1 : 0} style={{ transition: 'opacity .2s ease-in-out' }}>
              <Center style={{ transform: 'rotate(-90deg)' }}>
                <ArrowForwardIcon color="var(--colors-brandcolor-6)" />
              </Center>
              <Text c="var(--colors-brandcolor-6)" fw={600} size="xs" p={0}>
                Release to Refresh
              </Text>
            </Flex>
          </Center>
        )}
      </Flex>
      <Flex justify="center" h={isRefreshing ? 0 : pullPosition / 3.118 || 0} />
    </>
  );
}

export default BNPullToRefresh;
