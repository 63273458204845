import { IconPropsBase } from './IconPropsBase';

export default function FlashOnIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="m480-336 144.155-208.233H500.539L574-800H367.693v320H480v144Zm-59.999 192.918v-276.919H307.694v-439.998H653.46l-73.461 255.768h158.076L420.001-143.082ZM480-480H367.693 480Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="m480-334 146.155-218.233H512.539L578-792H367.693v312H480v146Zm-51.999 174.918v-268.919H315.694v-415.998H645.46l-65.461 239.768h142.076L428.001-159.082ZM480-480H367.693 480Z" />
      </svg>
    );
  }
}
