import { Box, Center, Overlay, Paper } from '@mantine/core';
import classes from './SeasonEvents.module.css';
import { useNowPricing } from '../SeasonNowPricing/NowPricing.hooks';
import { BNButton } from '../../components/Button/Button';
import CheckIcon from '../../components/icons/Check';
import { SeasonEventsHeader } from './SeasonEvents.Header';
import { SeasonEventsList } from './SeasonEvents.List';

export function SeasonEvents() {
  const { submit, ruleForm } = useNowPricing('submit', 'ruleForm');
  return (
    <Paper className={classes.seasonEventsWrapper} pos="relative">
      {ruleForm.isDirty() && (
        <Box>
          <Overlay bg="var(--colors-overlay)" center>
            <Center>
              <BNButton className={classes.subtleButton} variant="subtle" bg="var(--colors-paper)" leftSection={<CheckIcon />} size="sm" onClick={() => submit()}>
                Apply Filters
              </BNButton>
            </Center>
          </Overlay>
        </Box>
      )}
      <SeasonEventsHeader />
      <SeasonEventsList />
    </Paper>
  );
}
