import { Flex, Group, Stack, Text } from '@mantine/core';
import { BNNumberInput } from '../../components/NumberInput/NumberInput';
import { BNButton } from '../../components/Button/Button';
import { BarkerEventListing } from '../../types';
import { BarkerCoreEnumsPointOfSale } from '../../api';
import { useForm } from '@mantine/form';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import { useSetAtom } from 'jotai/index';
import { useListingState } from '../../data/ListingState';
import { updateListingsAtom } from '../../data/atoms';

export interface SplitListingProps {
  listing: BarkerEventListing;
  onClose: () => void;
  pointOfSale: BarkerCoreEnumsPointOfSale;
}

export const SplitListing = ({ listing, onClose, pointOfSale }: SplitListingProps) => {
  const isDti = pointOfSale === BarkerCoreEnumsPointOfSale.DtiPortal;
  const { splitGivenListing, splitListingLoading } = useListingState('splitGivenListing', 'splitListingLoading');
  const updateListing = useSetAtom(updateListingsAtom);

  const initialValues = {
    newQuantity: 0,
    newPrice: isDti && listing.unitPrice > 0 ? listing.unitPrice + 1.0 : listing.unitPrice,
  };

  const form = useForm({
    initialValues,
    validate: {
      newQuantity: (value) => {
        if (value >= listing.quantityRemaining) {
          return 'New quantity must be less than existing quantity';
        }
        if (value <= 0) {
          return 'New quantity must be greater than zero';
        }
        return null;
      },
      newPrice: (value) => {
        if (isDti && value === listing.unitPrice) {
          return 'New price must be different from existing price for DTI';
        }
        return null;
      },
    },
    validateInputOnChange: true,
  });

  const { values, getInputProps, reset, resetDirty } = form;
  const handleSplitListing = async (listingToSplit: BarkerEventListing, splitValues: { splitOffQuantity: number; newPrice: number }) => {
    const splitListings = await splitGivenListing(listing, splitValues.splitOffQuantity, splitValues.newPrice);

    if (splitListings) {
      updateListing(splitListings);
    }

    resetDirty();
    onClose();
  };

  if (listing.quantityRemaining <= 1) {
    return (
      <Flex mb={40}>
        <BNEmptyState border={false} title="" description="Cannot split a listing with only a single ticket" />
      </Flex>
    );
  }

  return (
    <form>
      <Group gap="xs" align="flex-end" justify="right" mt={-2}>
        <Stack gap="xs">
          <Group gap="xs" align="flex-end" justify="right" wrap="nowrap" w={236}>
            <BNNumberInput
              {...getInputProps('newQuantity')}
              label="New Quantity"
              disabled={splitListingLoading || listing.quantityReserved > 0}
              size="xs"
              selectOnFocus
              min={0}
              style={{ flexBasis: '100%' }}
            />
            <BNNumberInput
              {...getInputProps('newPrice')}
              label="New Price"
              disabled={splitListingLoading || listing.quantityReserved > 0}
              size="xs"
              decimalScale={2}
              fixedDecimalScale
              leftSection={
                <Text size="sm" ml={-2}>
                  $
                </Text>
              }
              selectOnFocus
              stepHoldDelay={500}
              stepHoldInterval={100}
              step={1}
              min={0}
              style={{ flexBasis: '100%' }}
            />
          </Group>
          <Group gap="xs" align="flex-end" justify="right" wrap="nowrap">
            <BNButton
              fullWidth
              variant="default"
              size="xs"
              disabled={splitListingLoading}
              onClick={() => {
                reset();
                resetDirty();
                onClose();
              }}
            >
              Cancel
            </BNButton>
            <BNButton
              disabled={!form.isValid() || listing.quantityReserved > 0}
              loading={splitListingLoading}
              fullWidth
              variant="filled"
              color="green"
              size="xs"
              onClick={async () => {
                await handleSplitListing(listing, { splitOffQuantity: values.newQuantity, newPrice: values.newPrice });
                reset();
                resetDirty();
                onClose();
              }}
            >
              Apply
            </BNButton>
          </Group>
        </Stack>
      </Group>
    </form>
  );
};
