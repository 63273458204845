import { IconPropsBase } from './IconPropsBase';

export default function MenuIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M140.001-254.616v-59.999h679.998v59.999H140.001Zm0-195.385v-59.998h679.998v59.998H140.001Zm0-195.384v-59.999h679.998v59.999H140.001Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M164.001-278.616v-51.999h631.998v51.999H164.001Zm0-175.385v-51.998h631.998v51.998H164.001Zm0-175.384v-51.999h631.998v51.999H164.001Z"
        />
      </svg>
    );
  }
}
