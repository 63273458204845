import { useDTIInventory } from '../DTI.Inventory.hooks';
import { Group, Stack, Text } from '@mantine/core';
import { BNButton } from '../../../components/Button/Button';

export function ReserveUnreserveForm({ onClose }: { onClose: () => void }) {
  const { selectedListing, reserveListing, unreserveListing, isBulkUpdating } = useDTIInventory('selectedListing', 'reserveListing', 'unreserveListing', 'isBulkUpdating');

  const isOnHold = (selectedListing?.quantityReserved ?? 0) > 0;

  if (isOnHold) {
    return (
      <Group gap="xs" align="flex-end" justify="right" mt={-2}>
        <Stack gap="xs">
          <Text>Remove the hold on this listing?</Text>
          <Group gap="xs" align="flex-end" justify="right" wrap="nowrap" w={236}>
            <BNButton
              fullWidth
              variant="default"
              disabled={isBulkUpdating}
              size="xs"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </BNButton>
            <BNButton
              fullWidth
              variant="filled"
              loading={isBulkUpdating}
              color="green"
              size="xs"
              onClick={async () => {
                await unreserveListing();
                onClose();
              }}
            >
              Apply
            </BNButton>
          </Group>
        </Stack>
      </Group>
    );
  }

  return (
    <Group gap="xs" align="flex-end" justify="right" mt={-2}>
      <Stack gap="xs">
        <Text>Place these tickets on hold?</Text>
        <Group gap="xs" align="flex-end" justify="right" wrap="nowrap" w={236}>
          <BNButton
            fullWidth
            variant="default"
            disabled={isBulkUpdating}
            size="xs"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </BNButton>
          <BNButton
            fullWidth
            variant="filled"
            loading={isBulkUpdating}
            color="green"
            size="xs"
            onClick={async () => {
              await reserveListing();
              onClose();
            }}
          >
            Apply
          </BNButton>
        </Group>
      </Stack>
    </Group>
  );
}
