import { ActionIcon, Box, Button, Divider, Group, Stack, Text, Tooltip } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import { BarkerCoreEnumsMarketplace } from '../../api';
import EditIcon from '../../components/icons/Edit';
import { generateStubHubUrl, generateVividSeatsUrl } from '../../utils/urls';
import classes from './SeatingChart.module.css';
import { useFlag } from '@unleash/proxy-client-react';
import { MarketplaceSelectorProps } from './SeatingChart.MarketplaceSelector.types';

export function MarketplaceSelectorDesktop({
  onChangeMarketplace,
  eventMappings,
  selectedMarketplaceId,
  editMarketplaceLinksDialogHandler,
  availableMarketplaces,
  isSeasonView,
}: MarketplaceSelectorProps) {
  const isTicketNetworkMarketplaceEnabled = useFlag('ticket-network-marketplace');

  return (
    <Stack>
      <Stack gap={2} pb={4}>
        <Text size="xs" c="gray.5" lh="1.25rem">
          Select a Marketplace
        </Text>
        {/* Marketplace selector */}
        <Button.Group bg="var(--colors-lightPaper)" className={classes.marketplaceButtonGroup}>
          {!availableMarketplaces || !availableMarketplaces.includes(BarkerCoreEnumsMarketplace.TicketEvolution) ? (
            <Tooltip label="Missing Integration Details" withArrow withinPortal>
              <Box className={classes.disabledFirstBox}>
                <BNButton fullWidth size="xs" disabled variant="default" className="firstButton">
                  <Text component="span">TE</Text>
                </BNButton>
              </Box>
            </Tooltip>
          ) : (
            <Tooltip label="Ticket Evolution" withArrow withinPortal>
              <BNButton
                size="xs"
                fullWidth
                disabled={!availableMarketplaces || !availableMarketplaces.includes(BarkerCoreEnumsMarketplace.TicketEvolution)}
                onClick={() => onChangeMarketplace(BarkerCoreEnumsMarketplace.TicketEvolution)}
                variant="default"
                className={
                  selectedMarketplaceId === BarkerCoreEnumsMarketplace.TicketEvolution
                    ? `${classes.marketplaceButtons} ${classes.marketplaceActiveButton} firstButton`
                    : `${classes.marketplaceButtons} firstButton`
                }
                data-pendo-name="TicketEvolution Marketplace Button"
              >
                <Text component="span">TE</Text>
              </BNButton>
            </Tooltip>
          )}
          {!availableMarketplaces || !availableMarketplaces.includes(BarkerCoreEnumsMarketplace.SeatGeek) ? (
            <Tooltip label="Missing Integration Details" withArrow withinPortal>
              <Box>
                <BNButton fullWidth size="xs" disabled variant="default">
                  <Text component="span">SG</Text>
                </BNButton>
              </Box>
            </Tooltip>
          ) : (
            <Tooltip label="SeatGeek" withArrow withinPortal>
              <BNButton
                size="xs"
                fullWidth
                disabled={!availableMarketplaces || !availableMarketplaces.includes(BarkerCoreEnumsMarketplace.SeatGeek)}
                onClick={() => onChangeMarketplace(BarkerCoreEnumsMarketplace.SeatGeek)}
                radius={0}
                variant="default"
                className={
                  selectedMarketplaceId === BarkerCoreEnumsMarketplace.SeatGeek
                    ? `${classes.marketplaceButtons} ${classes.marketplaceActiveButton}`
                    : `${classes.marketplaceButtons}`
                }
                data-pendo-name="SeatGeek Marketplace Button"
              >
                <Text component="span">SG</Text>
              </BNButton>
            </Tooltip>
          )}
          {isTicketNetworkMarketplaceEnabled ? (
            !availableMarketplaces || !availableMarketplaces.includes(BarkerCoreEnumsMarketplace.TicketNetwork) ? (
              <Tooltip label="Missing Integration Details" withArrow withinPortal>
                <Box>
                  <BNButton fullWidth size="xs" disabled variant="default">
                    <Text component="span">TN</Text>
                  </BNButton>
                </Box>
              </Tooltip>
            ) : (
              <Tooltip label="TicketNetwork" withArrow withinPortal>
                <BNButton
                  size="xs"
                  fullWidth
                  disabled={!availableMarketplaces || !availableMarketplaces.includes(BarkerCoreEnumsMarketplace.TicketNetwork)}
                  onClick={() => onChangeMarketplace(BarkerCoreEnumsMarketplace.TicketNetwork)}
                  variant="default"
                  className={
                    selectedMarketplaceId === BarkerCoreEnumsMarketplace.TicketNetwork
                      ? `${classes.marketplaceButtons} ${classes.marketplaceActiveButton}`
                      : `${classes.marketplaceButtons}`
                  }
                  data-pendo-name="TicketNetwork Marketplace Button"
                >
                  <Text component="span">TN</Text>
                </BNButton>
              </Tooltip>
            )
          ) : (
            <Tooltip label="Coming Soon" withArrow withinPortal>
              <Box>
                <BNButton fullWidth size="xs" disabled variant="default">
                  <Text component="span">TN</Text>
                </BNButton>
              </Box>
            </Tooltip>
          )}
          {!availableMarketplaces || !availableMarketplaces.includes(BarkerCoreEnumsMarketplace.Ticketmaster) ? (
            <Tooltip label="Missing Integration Details" withArrow withinPortal>
              <Box className={classes.disabledLastBox}>
                <BNButton fullWidth size="xs" disabled variant="default">
                  <Text component="span">TM</Text>
                </BNButton>
              </Box>
            </Tooltip>
          ) : (
            <Tooltip label="Ticketmaster" withArrow withinPortal>
              <BNButton
                size="xs"
                fullWidth
                disabled={!availableMarketplaces || !availableMarketplaces.includes(BarkerCoreEnumsMarketplace.Ticketmaster)}
                onClick={() => onChangeMarketplace(BarkerCoreEnumsMarketplace.Ticketmaster)}
                variant="default"
                className={
                  selectedMarketplaceId === BarkerCoreEnumsMarketplace.Ticketmaster
                    ? `${classes.marketplaceButtons} ${classes.marketplaceActiveButton} lastButton`
                    : `${classes.marketplaceButtons} lastButton`
                }
                data-pendo-name="Ticketmaster Marketplace Button"
              >
                <Text component="span">TM</Text>
              </BNButton>
            </Tooltip>
          )}
        </Button.Group>
      </Stack>
      {!isSeasonView && (
        <>
          <Divider color="var(--colors-divider)" mt={-4} mb={-4} />
          <Stack gap={0} pb={4}>
            <Group w="100%" justify="space-between">
              <Text size="xs" c="gray.5" lh="1.25rem">
                Marketplace Links
              </Text>
              <ActionIcon size="xs" onClick={editMarketplaceLinksDialogHandler.toggle}>
                <EditIcon />
              </ActionIcon>
            </Group>
            <Button.Group bg="var(--colors-lightPaper)" className={classes.marketplaceButtonGroup} w={94}>
              {!eventMappings?.some((em) => em.marketplaceId === BarkerCoreEnumsMarketplace.StubHub) ? (
                <Tooltip label="No Link Entered" withArrow withinPortal>
                  <Box className={classes.disabledFirstBox}>
                    <BNButton size="xs" fullWidth className={`${classes.marketplaceButtons} firstButton`} disabled variant="default">
                      SH
                    </BNButton>
                  </Box>
                </Tooltip>
              ) : (
                <Tooltip label="StubHub" withArrow withinPortal>
                  <BNButton
                    size="xs"
                    fullWidth
                    className={`${classes.marketplaceButtons} firstButton`}
                    variant="default"
                    onClick={() => {
                      window.open(generateStubHubUrl(eventMappings!.find((em) => em.marketplaceId === BarkerCoreEnumsMarketplace.StubHub)!.marketplaceEventId!), '_blank');
                    }}
                  >
                    SH
                  </BNButton>
                </Tooltip>
              )}
              {!eventMappings?.some((em) => em.marketplaceId === BarkerCoreEnumsMarketplace.VividSeats) ? (
                <Tooltip label="No Link Entered" withArrow withinPortal>
                  <Box className={classes.disabledLastBox}>
                    <BNButton size="xs" fullWidth className={`${classes.marketplaceButtons} lastButton`} disabled variant="default">
                      VS
                    </BNButton>
                  </Box>
                </Tooltip>
              ) : (
                <Tooltip label="Vivid Seats" withArrow withinPortal>
                  <BNButton
                    size="xs"
                    fullWidth
                    className={`${classes.marketplaceButtons} lastButton`}
                    variant="default"
                    onClick={() => {
                      window.open(generateVividSeatsUrl(eventMappings!.find((em) => em.marketplaceId === BarkerCoreEnumsMarketplace.VividSeats)!.marketplaceEventId!), '_blank');
                    }}
                  >
                    VS
                  </BNButton>
                </Tooltip>
              )}
            </Button.Group>
          </Stack>
        </>
      )}
      {/* Edit Links Dialog */}
    </Stack>
  );
}
