import { Center } from '@mantine/core';
import classes from './DTILogoHover.module.css';
interface LogoProps {
  size?: number;
}

export default function DTILogoHover({ size = 60 }: LogoProps) {
  return (
    <Center className={classes.dtiHoverableLogo}>
      <svg className="dti-logo" width={size} viewBox="0 0 226.45 79.33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="var(--colors-text)"
          d="M86.39,54.85c.52-.94,1.24-1.68,2.14-2.21.9-.53,1.91-.79,3.03-.79,1.36,0,2.52.32,3.49.97.97.64,1.67,1.55,2.11,2.72h-1.43c-.35-.82-.88-1.47-1.59-1.93-.71-.46-1.57-.7-2.58-.7-.89,0-1.7.21-2.41.63-.71.42-1.27,1.02-1.68,1.8-.4.78-.61,1.69-.61,2.72s.21,1.94.61,2.71c.41.78.97,1.37,1.68,1.79.71.42,1.52.62,2.41.62,1.01,0,1.87-.23,2.58-.69.71-.46,1.24-1.09,1.59-1.9h1.43c-.44,1.16-1.15,2.06-2.12,2.69-.98.64-2.14.96-3.48.96-1.11,0-2.12-.26-3.03-.79-.9-.53-1.62-1.26-2.14-2.2-.52-.94-.78-2.01-.78-3.2s.26-2.26.78-3.21Z"
        />
        <path
          fill="var(--colors-text)"
          d="M104.06,63.47c-.92-.53-1.64-1.26-2.16-2.21-.53-.94-.79-2.01-.79-3.21s.26-2.26.79-3.21c.53-.94,1.25-1.68,2.16-2.21.92-.53,1.94-.79,3.06-.79s2.16.26,3.08.79c.91.53,1.63,1.27,2.16,2.21.52.95.78,2.01.78,3.21s-.26,2.27-.78,3.21c-.52.95-1.24,1.68-2.16,2.21-.92.53-1.94.79-3.08.79s-2.14-.26-3.06-.79ZM109.56,62.58c.72-.42,1.29-1.02,1.7-1.8.42-.78.63-1.69.63-2.72s-.21-1.94-.63-2.71c-.42-.78-.99-1.38-1.7-1.79-.72-.42-1.53-.63-2.44-.63s-1.72.21-2.43.63c-.72.42-1.29,1.01-1.7,1.79-.42.78-.63,1.68-.63,2.71s.21,1.94.63,2.72c.42.78.98,1.38,1.7,1.8.72.42,1.53.63,2.43.63s1.72-.21,2.44-.63Z"
        />
        <path fill="var(--colors-text)" d="M129.34,52.14v12h-1.22v-9.58l-4.28,9.58h-.89l-4.28-9.55v9.55h-1.22v-12h1.29l4.65,10.4,4.64-10.4h1.31Z" />
        <path
          fill="var(--colors-text)"
          d="M140.94,58c-.68.64-1.71.96-3.08.96h-2.45v5.18h-1.22v-12.16h3.67c1.36,0,2.38.32,3.07.96.69.64,1.04,1.48,1.04,2.54s-.34,1.88-1.03,2.52ZM140.71,55.48c0-.81-.23-1.43-.68-1.85-.45-.42-1.17-.63-2.17-.63h-2.45v4.91h2.45c1.9,0,2.85-.81,2.85-2.44Z"
        />
        <path fill="var(--colors-text)" d="M151.74,61.26h-5.53l-1.06,2.89h-1.29l4.43-12h1.37l4.42,12h-1.29l-1.06-2.89ZM151.38,60.25l-2.4-6.57-2.4,6.57h4.8Z" />
        <path fill="var(--colors-text)" d="M167.29,64.14h-1.22l-6.73-10.23v10.23h-1.22v-12.16h1.22l6.73,10.21v-10.21h1.22v12.16Z" />
        <path fill="var(--colors-text)" d="M180.18,51.99l-3.9,7.43v4.73h-1.22v-4.73l-3.93-7.43h1.38l3.17,6.31,3.15-6.31h1.36Z" />

        <path fill="var(--colors-text)" d="M94.77,24.49h-5.72l-1.1,2.99h-1.33l4.59-12.42h1.42l4.57,12.42h-1.33l-1.1-2.99ZM94.39,23.45l-2.48-6.8-2.48,6.8h4.97Z" />

        <path fill="var(--colors-vlText)" d="M91.23,46.32l-5.75-12.39h1.31l3.2,6.94c1.18,2.55,1.63,3.63,1.88,4.28h.04c.2-.55.96-2.24,1.66-3.76l3.48-7.46h1.29l-5.84,12.39h-1.26Z" />
        <path fill="var(--colors-vlText)" d="M102.6,33.93v12.39h-1.21v-12.39h1.21Z" />
        <path
          fill="var(--colors-vlText)"
          d="M118.79,42.84c-.79,2.1-2.31,3.75-5.78,3.75-4.09,0-6.44-2.8-6.44-6.42s2.62-6.49,6.5-6.49c3.58,0,5.21,1.84,5.77,3.86h-1.26c-.69-1.57-1.87-2.8-4.53-2.8-3.18,0-5.2,2.26-5.2,5.39s2.05,5.4,5.22,5.4c2.67,0,3.71-1.15,4.5-2.69h1.22Z"
        />
        <path fill="var(--colors-vlText)" d="M126.1,35h-5.04v-1.07h11.3v1.07h-5.04v11.33h-1.22v-11.33Z" />
        <path
          fill="var(--colors-vlText)"
          d="M147.21,40.09c0,3.53-2.48,6.49-6.59,6.49s-6.63-2.86-6.63-6.51c0-3.45,2.64-6.41,6.64-6.41s6.58,2.96,6.58,6.42ZM135.27,40.03c0,2.81,1.88,5.5,5.36,5.5s5.3-2.39,5.3-5.4c0-2.74-1.93-5.39-5.4-5.39-3.2,0-5.25,2.43-5.25,5.29Z"
        />
        <path
          fill="var(--colors-vlText)"
          d="M152.3,40.72v5.6h-1.2v-12.39h5.7c2.46,0,4.25,1.05,4.25,3.28,0,1.45-.68,2.57-2.38,2.97.99.24,2.05.92,2.05,3.06v.51c0,1,0,2.08.14,2.57h-1.23c-.18-.54-.13-1.58-.13-2.49v-.42c0-2.09-.85-2.69-3.26-2.69h-3.93ZM152.3,39.67h4.15c2.22,0,3.35-.75,3.35-2.42,0-1.52-1.13-2.26-3.26-2.26h-4.24v4.68Z"
        />
        <path
          fill="var(--colors-vlText)"
          d="M168.72,46.32v-4.85c0-.11-.04-.18-.11-.27l-5.37-7.28h1.44c1.6,2.2,4.11,5.49,4.69,6.46.63-.98,3-4.25,4.62-6.46h1.37l-5.35,7.28c-.05.09-.09.14-.09.28v4.83h-1.21Z"
        />
        <path fill="var(--colors-vlText)" d="M177.58,34.11h3.74v9.51h6.84l-.46,2.71h-10.12v-12.22Z" />
        <path fill="var(--colors-vlText)" d="M194.87,34.11v12.22h-3.73v-12.22h3.73Z" />
        <path fill="var(--colors-vlText)" d="M202.77,46.32l-4.97-12.22h4.09l1.96,5.57c.73,2.07,1.04,3.2,1.25,4.03h.04c.2-.76.3-1.2,1.41-4.29l1.83-5.31h4l-5.06,12.22h-4.53Z" />
        <path fill="var(--colors-vlText)" d="M225.66,41.38h-6.81v2.24h7.6l-.43,2.7h-10.88v-12.22h10.88v2.71h-7.18v1.86h6.81v2.71Z" />
        <path
          fill="var(--colors-markBg)"
          d="M42.26,0l-3.68,27.36,5.07.04,1.74-13.6,11.37.07-1.74,13.63,4.1.03-.4,3.77h3.96l-.41,3.6,8.38.05-3.44,24.52-11.37-.07,3.04-21.66-5.16-.04-2.77,21.63-11.37-.07,2.77-21.66-5.15-.04-.05.39h-.02s-2.92,21.58-2.92,21.58l-11.36-.07h-5.91c-5.41,0-9.8-4.4-9.8-9.8v-13.35c0-5.41,4.4-9.8,9.8-9.8h9.51c.29,0,.58.02.87.04L30.88,0H0v79.33h27.57c.13-6.4,5.35-11.56,11.79-11.56s11.66,5.15,11.79,11.56h28.19V0h-37.07ZM70.66,27.45h-7.36l.63-5.89h7.36l-.63,5.89Z"
        />
        <path fill="var(--colors-markBg)" d="M25.93,35.43h-9c-.48,0-.89.41-.89.89v13.35c0,.43.33.8.75.88h7.1l2.04-15.11Z" />
      </svg>
    </Center>
  );
}
