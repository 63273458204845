import { ActionIcon, CloseIcon, Divider, Flex } from '@mantine/core';
import DateRangeIcon from '../../components/icons/DateRange';
import SearchIcon from '../../components/icons/Search';
import StadiumIcon from '../../components/icons/Stadium';
import ReceiptIcon from '../../components/icons/Receipt';
import classes from './Sales.styles.module.css';
import { BNButton } from '../../components/Button/Button';
import { useSales } from './Sales.hooks';
import LocalShippingIcon from '../../components/icons/LocalShipping';
import PaidIcon from '../../components/icons/Paid';
import dayjs from 'dayjs';

type SearchPillProps = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  onClose: () => void;
};

function SearchPill({ icon, label, onClick, onClose }: SearchPillProps) {
  return (
    <Flex className={classes.filterBtn}>
      <BNButton onClick={onClick} variant="subtle" size="xs" pl={4} pr="xs" fw={500} c="var(--colors-paperReverse)" leftSection={icon}>
        {label}
      </BNButton>
      <Divider orientation="vertical" h={24} mr={-1} color="var(--colors-gray-2)" pos="relative" style={{ alignSelf: 'center' }} />
      <ActionIcon size="sm" w={28} px={2} onClick={onClose} h={30}>
        <CloseIcon />
      </ActionIcon>
    </Flex>
  );
}

function getDateRangeLabel(fromDate: Date, toDate: Date) {
  const format = fromDate.getFullYear() === toDate.getFullYear() ? 'MM/DD' : 'MM/DD/YY';
  const from = dayjs(fromDate).format(format);
  const to = dayjs(toDate).format(format);
  const today = dayjs().format(format);
  return from === to && from === today ? 'Today' : from === to ? from : `${from} - ${to}`;
}

export function SalesSearchPillsArea() {
  const { form, setShowSidebar, search } = useSales('form', 'setShowSidebar', 'search');

  return (
    <Flex h="100%" align="center" gap="xs" w="100%" px="xs">
      {form.values.fromDate && form.values.toDate && (
        <SearchPill
          icon={<DateRangeIcon />}
          label={getDateRangeLabel(form.values.fromDate, form.values.toDate)}
          onClick={() => setShowSidebar(true)}
          onClose={() => {
            form.setFieldValue('fromDate', null);
            form.setFieldValue('toDate', null);
            setShowSidebar(true); // We show the sidebar because the user needs to pick valid dates to search on anything
          }}
        />
      )}
      {form.values.query?.length > 0 && (
        <SearchPill icon={<SearchIcon />} label={form.values.query} onClick={() => setShowSidebar(true)} onClose={() => form.setFieldValue('Query', '')} />
      )}
      {form.values.eventDateFrom && form.values.eventDateTo && (
        <SearchPill
          icon={<StadiumIcon />}
          label={`Events ${getDateRangeLabel(form.values.eventDateFrom, form.values.eventDateTo)}`}
          onClick={() => setShowSidebar(true)}
          onClose={() => {
            form.setFieldValue('eventDateFrom', null);
            form.setFieldValue('eventDateTo', null);
          }}
        />
      )}
      {/*<SearchPill icon={<ListIcon />} label="Listing Filter" onClick={() => console.log('Filter Pill Click')} onClose={() => console.log('Filter Pill Remove')} />*/}
      {form.values.invoiceId && (
        <SearchPill icon={<ReceiptIcon />} label={`Invoice #${form.values.invoiceId}`} onClick={() => setShowSidebar(true)} onClose={() => form.setFieldValue('invoiceId', '')} />
      )}
      {form.values.externalReference && (
        <SearchPill
          icon={<ReceiptIcon />}
          label={`Reference #${form.values.invoiceId}`}
          onClick={() => setShowSidebar(true)}
          onClose={() => form.setFieldValue('externalReference', '')}
        />
      )}
      {form.values.fulfillmentStatusId && (
        <SearchPill
          icon={<LocalShippingIcon />}
          label={form.values.fulfillmentStatusId}
          onClick={() => setShowSidebar(true)}
          onClose={() => form.setFieldValue('fulfillmentStatusId', null)}
        />
      )}
      {form.values.paymentStatusId && (
        <SearchPill icon={<PaidIcon />} label={form.values.paymentStatusId} onClick={() => setShowSidebar(true)} onClose={() => form.setFieldValue('paymentStatusId', null)} />
      )}
      {form.isDirty() && (
        <BNButton onClick={() => search(form.values)} variant="filled" size="xs">
          Apply Changes
        </BNButton>
      )}
    </Flex>
  );
}
