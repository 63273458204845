import { IconPropsBase } from './IconPropsBase';

export default function TableRowsIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M760-212.31v-121.15H200v121.15q0 5.39 3.46 8.85t8.85 3.46h535.38q5.39 0 8.85-3.46t3.46-8.85Zm0-181.15v-173.08H200v173.08h560Zm0-233.08v-121.15q0-5.39-3.46-8.85t-8.85-3.46H212.31q-5.39 0-8.85 3.46t-3.46 8.85v121.15h560ZM212.31-140Q182-140 161-161q-21-21-21-51.31v-535.38Q140-778 161-799q21-21 51.31-21h535.38Q778-820 799-799q21 21 21 51.31v535.38Q820-182 799-161q-21 21-51.31 21H212.31Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M744-228.31v-121.15H216v121.15q0 5.39 3.46 8.85t8.85 3.46h503.38q5.39 0 8.85-3.46t3.46-8.85Zm0-173.15v-157.08H216v157.08h528Zm0-209.08v-121.15q0-5.39-3.46-8.85t-8.85-3.46H228.31q-5.39 0-8.85 3.46t-3.46 8.85v121.15h528ZM228.31-164Q202-164 183-183q-19-19-19-45.31v-503.38q0-27.01 19-45.66Q202-796 228.31-796h503.38q27.01 0 45.66 18.65Q796-758.7 796-731.69v503.38Q796-202 777.35-183q-18.65 19-45.66 19H228.31Z"
        />
      </svg>
    );
  }
}
