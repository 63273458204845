import { Button, Tooltip } from '@mantine/core';
import classes from './MobileDateButtonGroup.module.css';
import { BNButton } from '../Button/Button';
import { MobileDateOptions } from '../../hoc/EventSearch/Search.types';

type TempSearchResultsMobileProps = {
  dateLabel: MobileDateOptions;
  handleDateChange: (newState: MobileDateOptions) => void;
};

export function MobileDateButtonGroup({ dateLabel, handleDateChange }: TempSearchResultsMobileProps) {
  return (
    <Button.Group className={classes.btnGroup}>
      <Tooltip label="Show Events for Today" withArrow>
        <BNButton
          variant="default"
          size="sm"
          fz="sm"
          data-pendo-name="Today Date Filter Button - Mobile"
          className={dateLabel === 'Today' ? `${classes.buttons} ${classes.activeButton}` : `${classes.buttons}`}
          onClick={() => handleDateChange('Today')}
          fullWidth
        >
          Today
        </BNButton>
      </Tooltip>
      <Tooltip label="Show Events for the Next 7 Days" withArrow>
        <BNButton
          variant="default"
          size="sm"
          fz="sm"
          data-pendo-name="7 Days Date Filter Button - Mobile"
          className={dateLabel === '7 Days' ? `${classes.buttons} ${classes.activeButton}` : `${classes.buttons}`}
          onClick={() => handleDateChange('7 Days')}
          fullWidth
        >
          7 Days
        </BNButton>
      </Tooltip>
      <Tooltip label="Show Events for the Next 30 Days" withArrow>
        <BNButton
          variant="default"
          size="sm"
          fz="sm"
          data-pendo-name="30 Days Date Filter Button - Mobile"
          className={dateLabel === '30 Days' ? `${classes.buttons} ${classes.activeButton}` : `${classes.buttons}`}
          onClick={() => handleDateChange('30 Days')}
          fullWidth
        >
          30 Days
        </BNButton>
      </Tooltip>
      <Tooltip label="Show Events for a Custom Date Range" withArrow>
        <BNButton
          variant="default"
          size="sm"
          fz="sm"
          data-pendo-name="Custom Date Filter Button - Mobile"
          className={dateLabel === 'Custom' ? `${classes.buttons} ${classes.activeButton}` : `${classes.buttons}`}
          onClick={() => handleDateChange('Custom')}
          fullWidth
        >
          Custom
        </BNButton>
      </Tooltip>
      <Tooltip label="Show All Events" withArrow>
        <BNButton
          variant="default"
          size="sm"
          fz="sm"
          data-pendo-name="All Date Filter Button - Mobile"
          className={dateLabel === 'All' ? `${classes.buttons} ${classes.activeButton}` : `${classes.buttons}`}
          onClick={() => handleDateChange('All')}
          fullWidth
        >
          All
        </BNButton>
      </Tooltip>
    </Button.Group>
  );
}
