import { useSetAtom } from 'jotai';
import { setAxiosTokenInterceptor } from '../../data/api-config';
import { auth } from '../../data/atoms.auth';
import { useAuth0 } from '@auth0/auth0-react';

export function useTokenRefresh() {
  const setApiToken = useSetAtom(auth.apiTokenAtom);
  const { getAccessTokenSilently, logout } = useAuth0();

  const handleAccessTokenSilently = () => {
    return getAccessTokenSilently()
      .then((token) => {
        setAxiosTokenInterceptor(token);
        setApiToken(token);
      })
      .catch((error) => {
        console.error('Token Error', error);
        // TODO: Log error in appInsights.
        logout();
      });
  };

  return {
    handleAccessTokenSilently,
  };
}
