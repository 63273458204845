import classes from './Billing.tsx.module.css';
import { Badge, Box, Group, Loader, SimpleGrid, Skeleton, Table, Text, Title } from '@mantine/core';
import { Link, Outlet } from 'react-router-dom';
import { BNButton } from '../components/Button/Button';
import { BNSelect } from '../components/Select/Select';
import { AdminContentHeader } from '../hoc/Admin/AdminContentHeader';
import { formatCurrency } from '../utils/formatters';
import { BillingStateProvider, useBilling } from '../hoc/Admin/Billing.hooks';
import dayjs from 'dayjs';

export function BillingFrame() {
  return (
    <BillingStateProvider>
      <Outlet />
    </BillingStateProvider>
  );
}

export function InvoiceStatusBadge({ paidAt, dueAt }: { paidAt: Date | null | undefined; dueAt: Date }) {
  return (
    <Badge w={100} color={!paidAt && dueAt < new Date() ? 'red' : 'gray'} variant="light">
      {paidAt ? `Paid - ${dayjs(paidAt).format('M/D')}` : dueAt < new Date() ? 'Past Due' : 'Pending'}
    </Badge>
  );
}

export default function Billing() {
  const { currentPlan, totalSales, totalFees, projectedFees, invoices, filterOptions, filter, setFilter, isLoading } = useBilling(
    'currentPlan',
    'totalSales',
    'totalFees',
    'projectedFees',
    'currentInvoice',
    'invoices',
    'filterOptions',
    'filter',
    'setFilter',
    'isLoading',
  );

  const sortedInvoices = invoices?.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)) ?? [];

  const rows = sortedInvoices.map((element) => (
    <tr key={element.invoiceId}>
      <td>{dayjs(element.createdAt).format('MMMM YYYY')}</td>
      <td width={140} align="right">
        <Link to={`invoices/${element.invoiceId}`}>
          <Text size="sm" td="underline" mr={4} c="var(--colors-brandcolor-5)">
            {formatCurrency(element.totalAmount)}
          </Text>
        </Link>
      </td>
      <td width={160} align="right">
        {formatCurrency(element.totalSales ?? 0)}
      </td>
      <td width={150}>
        <InvoiceStatusBadge dueAt={element.dueAt} paidAt={element.paidAt} />
      </td>
      {/* Removed download option, see comments further down */}
      {/*<td width={180}>*/}
      {/*  <Text component="a" href="#" onClick={() => getApiAccountingInvoicesInvoiceIdPdf(element.invoiceId)} td="underline" mr={4} c="var(--colors-brandcolor-5)">*/}
      {/*    {`Invoice ${element.invoiceNumber}`}*/}
      {/*  </Text>*/}
      {/*  <OpenNewIcon size={12} color="var(--colors-gray-5)" />*/}
      {/*</td>*/}
    </tr>
  ));

  return (
    <>
      <AdminContentHeader sticky title="Billing Overview" />
      <SimpleGrid
        cols={4}
        style={{
          borderBottom: '1px solid var(--colors-gray-1)',
          borderTop: '0px solid var(--colors-gray-1)',
        }}
      >
        <Box pos="relative" pr="md" pb="xs" pt="md" mih={77} style={{ borderLeft: 'none' }}>
          <Text fw={600} fz={11} tt="uppercase" color="var(--colors-gray-5)" lts=".025em">
            Current Sales
          </Text>
          {!isLoading ? (
            <Title mt={2} mb={6} order={4}>
              {formatCurrency(totalSales)}
            </Title>
          ) : (
            <Skeleton maw={120} h={14} mt={8} mb={4} />
          )}
        </Box>
        <Box pos="relative" pl="md" pb="xs" pt="md" style={{ borderLeft: '1px solid var(--colors-gray-1)' }}>
          <Text fw={600} fz={11} tt="uppercase" color="var(--colors-gray-5)" lts=".025em">
            Current Fees
          </Text>
          {!isLoading ? (
            <Group justify="space-between" align="center" wrap="nowrap">
              <Title my={2} mb={6} order={4} lineClamp={1}>
                {formatCurrency(totalFees)}
              </Title>
              <Link to="invoices/current">
                <BNButton size="compact-xs" variant="default">
                  View
                </BNButton>
              </Link>
            </Group>
          ) : (
            <Skeleton maw={120} h={14} mt={8} mb={4} />
          )}
        </Box>
        <Box pos="relative" pl="md" pb="xs" pt="md" style={{ borderLeft: '1px solid var(--colors-gray-1)' }}>
          <Text fw={600} fz={11} tt="uppercase" color="var(--colors-gray-5)" lts=".025em">
            Projected Fees
          </Text>
          {!isLoading ? (
            <Title mt={2} mb={6} order={4}>
              {formatCurrency(projectedFees)}
            </Title>
          ) : (
            <Skeleton maw={120} h={14} mt={8} mb={4} />
          )}
        </Box>
        <Box pos="relative" pl="md" pb="xs" pt="md" style={{ borderLeft: '1px solid var(--colors-gray-1)' }}>
          <Text fw={600} fz={11} tt="uppercase" c="var(--colors-gray-5)" lts=".025em">
            Current Plan
          </Text>
          {!isLoading ? (
            <>
              <Title mt={2} mb={6} order={4}>
                {currentPlan?.plan.name ?? 'None'}
              </Title>
              <Link to="plan">
                <BNButton size="compact-xs" variant="default" pos="absolute" bottom={16} right={0}>
                  View
                </BNButton>
              </Link>
            </>
          ) : (
            <Skeleton maw={120} h={14} mt={8} mb={4} />
          )}
        </Box>
      </SimpleGrid>
      <Box pt="sm">
        <Group justify="space-between">
          <Title my="lg" order={4}>
            Invoice History
          </Title>
          <Group>
            <Text component="label" size="xs" color="var(--colors-gray-5)">
              Show Invoices for:
            </Text>
            <BNSelect m={0} p={0} size="xs" w={110} aria-label="Show Invoices for" data={filterOptions} defaultValue={filterOptions[0]} value={filter} onChange={(value) => setFilter(value!)} />
          </Group>
        </Group>
        {/* <Group h={320} bg="var(--colors-opacity-hover)" align="center" justify="center">
          <Text color="var(--colors-gray-5)">Invoice Table</Text>
        </Group> */}
        <Table verticalSpacing="xs" className={classes.adminTable}>
          <thead>
            <tr>
              <th>Period</th>
              <th style={{ width: 140 }}>Fees</th>
              <th style={{ width: 160 }}>Sales</th>
              <th style={{ width: 150 }}>Status</th>
              {/*<th style={{ width: 180 }}>Download</th>*/}
              {/* Removing Download option for now, because TEVO/DTI/1Ticket users will be billed separately */}
            </tr>
          </thead>
          {!isLoading ? (
            <tbody>{rows}</tbody>
          ) : (
            <tbody>
              <tr className="loadingRow">
                <td height={240}>
                  <Loader type="dots" pos="absolute" right="calc(50% - 18px)" top={110} />
                </td>
                <td width={140} />
                <td width={160} />
                <td width={150} />
                {/*<td width={180} />*/}
              </tr>
            </tbody>
          )}
        </Table>
      </Box>
      {/* <Box pt="sm">
        <Title my="lg" order={4}>
          Payment Method
        </Title>
        <Divider color="gray.1" />
        <Group py="md" align="center" gap="sm" justify="space-between">
          <Group align="center" gap="sm">
            <AccountBalanceIcon size={24} color="var(--colors-iconFill)" />
            <Text size="sm">ACH - ending in 3456</Text>
            <Divider orientation="vertical" color="var(--colors-divider)" />
            <Group gap={4}>
              <Text size="sm" td="underline" c="var(--colors-brandcolor-5)" component="a" href="#" target="_blank">
                Update
              </Text>
              <OpenNewIcon size={12} color="var(--colors-gray-5)" />
            </Group>
          </Group>
          <Text size="xs" color="var(--colors-gray-5)">
            Payments powered by QuickBooks
          </Text>
        </Group>
      </Box> */}
    </>
  );
}
