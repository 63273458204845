import { Badge, Box, Center, Flex, Group, Text, UnstyledButton } from '@mantine/core';
import React from 'react';
import classes from './OnboardingSelector.module.css';
import cx from 'clsx';
import CheckIcon from '../icons/Check';
import { useHover } from '@mantine/hooks';
import { PricerView } from '../../types';
import { BarkerCoreEnumsPricingMode } from '../../api';

type Option = {
  icon: React.ReactNode;
  title: string;
  description: string;
  image: string;
  imageHover: string;
  badgeText: string;
  value: PricerView | BarkerCoreEnumsPricingMode;
};

type BNOnboardingSelectorProps = {
  value?: PricerView | BarkerCoreEnumsPricingMode;
  onChange: (value: PricerView | BarkerCoreEnumsPricingMode) => void;
  options: Option[];
};

export function BNOnboardingSelector({ value, onChange, options }: BNOnboardingSelectorProps) {
  return (
    <Flex gap="xl" align="start">
      {options.map((option, index) => {
        return <BNOnboardingButton selected={value === option.value} option={option} onClick={() => onChange(option.value)} key={option.badgeText} />;
      })}
    </Flex>
  );
}

type BNOnboardingButtonProps = {
  selected: boolean;
  onClick: () => void;
  option: Option;
};

function BNOnboardingButton({ selected, onClick, option }: BNOnboardingButtonProps) {
  const { hovered, ref } = useHover();
  return (
    <UnstyledButton key={option.badgeText} className={cx(classes.onboardingOption, selected && classes.viewSelected)} onClick={() => onClick()}>
      <Flex ref={ref} className={classes.onboardingOptionShot} align="center" justify="center" w={600} h={422} mah="100%" maw="100%" bg="var(--colors-paper)" pos="relative">
        <Badge className={classes.slantBadgeRight} size="md" pos="absolute" top={0} right={0} color="gray.3" c="gray.6" style={{ borderRadius: 0, zIndex: 100 }}>
          {option.badgeText}
        </Badge>
        <Badge className={classes.hoverBadge} pos="absolute" top="calc(50% - 30px)" fz="10" h={30} px="md" style={{ zIndex: 100, boxShadow: '0 0 25px rgba(0,0,0,.15)' }}>
          Hover for Preview
        </Badge>
        <Box className={classes.previewImage}>
          <img src={hovered ? option.imageHover : option.image} alt="Split View Preview" height={416} width={594} />
        </Box>
        <Flex
          bg="var(--colors-paper)"
          style={{ borderTop: '1px solid var(--colors-divider)' }}
          pos="absolute"
          bottom={0}
          right={0}
          left={0}
          w="100%"
          align="center"
          justify="space-between"
          p="md"
        >
          <Box>
            <Group gap="md">
              <Center h={48} w={48} className={classes.circleIcon}>
                {option.icon}
              </Center>
              <Box>
                <Text size="md" fw="600">
                  {option.title}
                </Text>
                <Text c="var(--colors-gray-5)">{option.description}</Text>
              </Box>
            </Group>
          </Box>
          <Center className={cx(classes.checkIcon, selected && classes.checked)} h={24} w={24} style={{ borderRadius: 100 }}>
            {selected && <CheckIcon />}
          </Center>
        </Flex>
      </Flex>
    </UnstyledButton>
  );
}
