import dayjs from 'dayjs';
import { HumanizeDuration, HumanizeDurationLanguage } from 'humanize-duration-ts';

export function humanizeTime(date: Date | null | undefined) {
  if (date == null) return 'n/a';
  const service = new HumanizeDuration(new HumanizeDurationLanguage());
  const humanizedNumber = service.humanize(dayjs(date).diff(dayjs()), {
    round: true,
    largest: 1,
    spacer: '',
    units: ['y', 'w', 'd', 'h', 'm'],
    language: 'shortEn',
    languages: {
      shortEn: {
        decimal: '.',
        y: () => 'y',
        mo: () => 'mo',
        w: () => 'w',
        d: () => 'd',
        h: () => 'h',
        m: () => 'm',
        s: () => 's',
        ms: () => 'ms',
      },
    },
  });

  if (humanizedNumber === '0m') {
    return '<1m';
  }

  return humanizedNumber;
}
