import { IconPropsBase } from './IconPropsBase';

export default function SyncIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M4.325 19.675v-1.5H7.25l-.725-.7q-1.225-1.15-1.75-2.563-.525-1.412-.525-2.837 0-2.6 1.513-4.65Q7.275 5.375 9.75 4.6v1.575q-1.8.7-2.9 2.313-1.1 1.612-1.1 3.587 0 1.175.45 2.275.45 1.1 1.375 2.05l.625.625V14.3h1.5v5.375Zm9.925-.275v-1.575q1.8-.7 2.9-2.313 1.1-1.612 1.1-3.587 0-1.175-.45-2.275-.45-1.1-1.375-2.05l-.625-.625V9.7h-1.5V4.325h5.375v1.5H16.75l.725.7q1.2 1.175 1.737 2.575.538 1.4.538 2.825 0 2.6-1.512 4.65-1.513 2.05-3.988 2.825Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M4.771 15.729v-1.083h1.687l-.27-.292q-.876-.833-1.428-1.937-.552-1.105-.552-2.396 0-1.959 1.146-3.459T8.292 4.5v1.125q-1.313.521-2.157 1.698-.843 1.177-.843 2.698 0 1.041.427 1.927.427.885 1.135 1.531l.313.313V12.25H8.25v3.479Zm6.937-.229v-1.125q1.313-.521 2.157-1.698.843-1.177.843-2.698 0-1.041-.427-1.927-.427-.885-1.135-1.531l-.313-.313V7.75H11.75V4.271h3.479v1.083h-1.687l.27.292q.917.792 1.448 1.916.532 1.126.532 2.417 0 1.959-1.146 3.459T11.708 15.5Z"
        />
      </svg>
    );
  }
}
