import { forwardRef } from 'react';
import { BNButton } from '../../../components/Button/Button';
import LibraryAddIcon from '../../../components/icons/LibraryAdd';
import PlaylistAddIcon from '../../../components/icons/PlaylistAdd';
import SelectArrowsIcon from '../../../components/icons/SelectArrows';
import TabGroupIcon from '../../../components/icons/TabGroup';
import classes from '../Inventory.MultiSelectActionbar.styles.tsx.module.css';
import { Box, Menu, Text, Tooltip } from '@mantine/core';
import { useBulkState } from '../../../data/BulkState';

export function Grouping() {
  const { isAddToGroupEnabled, isCreateGroupEnabled, createGroup, addToGroup } = useBulkState(
    'isAddToGroupEnabled',
    'isAutoPricingEnabled',
    'isCreateGroupEnabled',
    'isPricingEnabled',
    'createGroup',
    'addToGroup',
    'disableAutoPricing',
    'onPricingCloseResetPending',
  );
  return (
    <>
      {!isAddToGroupEnabled && !isCreateGroupEnabled && (
        <Tooltip
          withArrow
          label={
            <Text maw={280} component="div" p="xs" style={{ whiteSpace: 'normal' }}>
              <strong>Grouping conditions not met:</strong>
              <ul>
                <li>Cannot create a group of listings across different events or with listings already auto-priced</li>
                <li>An auto-priced listing must be selected to add to group.</li>
                <li>All listings must belong to the same company</li>
              </ul>
            </Text>
          }
        >
          <Box className={classes.actionButtonContainer}>
            <GroupingButton disabled />
          </Box>
        </Tooltip>
      )}
      {(isAddToGroupEnabled || isCreateGroupEnabled) && (
        <Menu width={160} withArrow offset={0}>
          <Menu.Target>
            <Box className={classes.actionButtonContainer}>
              <GroupingButton />
            </Box>
          </Menu.Target>
          <Menu.Dropdown>
            <Tooltip
              label="Cannot create a group of listings across different events or with listings already auto-priced"
              position="top"
              withArrow
              disabled={isCreateGroupEnabled}
            >
              <Box>
                <Menu.Item leftSection={<LibraryAddIcon />} fz="xs" py={6} onClick={createGroup} disabled={!isCreateGroupEnabled} data-pendo-name="Create Group MenuItem">
                  Create Group
                </Menu.Item>
              </Box>
            </Tooltip>
            <Tooltip label="An auto-priced listing must be selected to add to group." position="bottom" withArrow disabled={isAddToGroupEnabled}>
              <Box>
                <Menu.Item leftSection={<PlaylistAddIcon />} fz="xs" py={6} onClick={addToGroup} disabled={!isAddToGroupEnabled} data-pendo-name="Add to Group MenuItem">
                  Add to Group
                </Menu.Item>
              </Box>
            </Tooltip>
          </Menu.Dropdown>
        </Menu>
      )}
    </>
  );
}

const GroupingButton = forwardRef<HTMLButtonElement, { disabled?: boolean }>(({ disabled }, ref) => (
  <BNButton
    ref={ref}
    c="var(--colors-selectedBorder)"
    variant="subtle"
    disabled={disabled}
    size="xs"
    leftSection={<TabGroupIcon />}
    rightSection={<SelectArrowsIcon />}
    className={classes.actionButton}
  >
    <Text fw={600} truncate>
      Grouping
    </Text>
  </BNButton>
));
