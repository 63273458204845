export default function ViewDefaultIllustration() {
  return (
    <svg width="280" height="160" viewBox="0 0 280 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H280V160H0V0Z" fill="var(--colors-gray-0)" />
      <path d="M21 23C21 21.8954 21.8954 21 23 21H280V158C280 159.105 279.105 160 278 160H21V23Z" fill="var(--colors-paper)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23 20C21.3431 20 20 21.3431 20 23V160H21V23C21 21.8954 21.8954 21 23 21H280V20H23Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M280 35H21V34H280V35Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M280 71.5H88V70.5H280V71.5Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M78 61H21V60H78V61Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M76.5 87H23V86H76.5V87Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M280 81.5H88V80.5H280V81.5Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M279 91.5H88V90.5H279V91.5Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M280 139.5H88V138.5H280V139.5Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M280 149.5H88V148.5H280V149.5Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M280 101.5H88V100.5H280V101.5Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M280 111.5H79V110.5H280V111.5Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M280 61H79V60H280V61Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M280 129H79V128H280V129Z" fill="var(--colors-gray-0)" />
      <path d="M79 35H280V43H79V35Z" fill="var(--colors-gray-0)" />
      <path d="M83 35L83 160L79 160L79 35L83 35Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M78 160L78 21L79 21L79 160L78 160Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M108 40H88V38H108V40Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M138 40H118V38H138V40Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M168 40H148V38H168V40Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M198 40H178V38H198V40Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M228 40H208V38H228V40Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M258 40H238V38H258V40Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M106 55H90V53H106V55Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M106 50H90V48H106V50Z" fill="var(--colors-brandcolor-5)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M230 52H224V50H230V52Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M244 52H238V50H244V52Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M258 52H252V50H258V52Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M272 52H266V50H272V52Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M46.5 29H26V27H46.5V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M59 29H53V27H59V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M67 29H61V27H67V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M75 29H69V27H75V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M103.5 29H83V27H103.5V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M116 29H110V27H116V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M124 29H118V27H124V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M132 29H126V27H132V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M243.5 29H223V27H243.5V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M256 29H250V27H256V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M264 29H258V27H264V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M272 29H266V27H272V29Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M149 55H113V53H149V55Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M193 50H113V48H193V50Z" fill="var(--colors-gray-5)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M42 66H26V64H42V66Z" fill="var(--colors-brandcolor-5)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M42 76H26V74H42V76Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M32 83H26V81H32V83Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M46 83H40V81H46V83Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M60 83H54V81H60V83Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M74 83H68V81H74V83Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M66.5 72H26V70H66.5V72Z" fill="var(--colors-gray-5)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M42 92H26V90H42V92Z" fill="var(--colors-brandcolor-5)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M42 102H26V100H42V102Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M32 109H26V107H32V109Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M46 109H40V107H46V109Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M60 109H54V107H60V109Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M74 109H68V107H74V109Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M66.5 98H26V96H66.5V98Z" fill="var(--colors-gray-5)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M42 118H26V116H42V118Z" fill="var(--colors-brandcolor-5)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M42 128H26V126H42V128Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M32 135H26V133H32V135Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M46 135H40V133H46V135Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M60 135H54V133H60V135Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M74 135H68V133H74V135Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M66.5 124H26V122H66.5V124Z" fill="var(--colors-gray-5)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M42 144H26V142H42V144Z" fill="var(--colors-brandcolor-5)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M42 154H26V152H42V154Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M66.5 150H26V148H66.5V150Z" fill="var(--colors-gray-5)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M85 43L85 60L83 60L83 43L85 43Z" fill="var(--colors-brandcolor-5)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23 61L23 160L21 160L21 61L23 61Z" fill="var(--colors-brandcolor-5)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M85 111L85 128L83 128L83 111L85 111Z" fill="var(--colors-brandcolor-5)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M76.5 113H23V112H76.5V113Z" fill="var(--colors-gray-0)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M76.5 139H23V138H76.5V139Z" fill="var(--colors-gray-0)" />
      <path d="M25 41C25 40.4477 25.4477 40 26 40H47C47.5523 40 48 40.4477 48 41V45C48 45.5523 47.5523 46 47 46H26C25.4477 46 25 45.5523 25 45V41Z" fill="var(--colors-paper)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47 41H26L26 45H47V41ZM26 40C25.4477 40 25 40.4477 25 41V45C25 45.5523 25.4477 46 26 46H47C47.5523 46 48 45.5523 48 45V41C48 40.4477 47.5523 40 47 40H26Z"
        fill="var(--colors-gray-2)"
      />
      <path d="M51 41C51 40.4477 51.4477 40 52 40H73C73.5523 40 74 40.4477 74 41V45C74 45.5523 73.5523 46 73 46H52C51.4477 46 51 45.5523 51 45V41Z" fill="var(--colors-paper)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73 41H52L52 45H73V41ZM52 40C51.4477 40 51 40.4477 51 41V45C51 45.5523 51.4477 46 52 46H73C73.5523 46 74 45.5523 74 45V41C74 40.4477 73.5523 40 73 40H52Z"
        fill="var(--colors-gray-2)"
      />
      <path d="M25 50C25 49.4477 25.4477 49 26 49H73C73.5523 49 74 49.4477 74 50V54C74 54.5523 73.5523 55 73 55H26C25.4477 55 25 54.5523 25 54V50Z" fill="var(--colors-paper)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73 50H26L26 54H73V50ZM26 49C25.4477 49 25 49.4477 25 50V54C25 54.5523 25.4477 55 26 55H73C73.5523 55 74 54.5523 74 54V50C74 49.4477 73.5523 49 73 49H26Z"
        fill="var(--colors-gray-2)"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M106 123H90V121H106V123Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M106 118H90V116H106V118Z" fill="var(--colors-brandcolor-5)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M149 123H113V121H149V123Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M193 118H113V116H193V118Z" fill="var(--colors-gray-5)" />
      <path d="M83 82C83 81.4477 83.4477 81 84 81H280V91H84C83.4477 91 83 90.5523 83 90V82Z" fill="var(--colors-selectedBg)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M83 82V90C83 90.5523 83.4477 91 84 91H280V90H84V82H280V81H84C83.4477 81 83 81.4477 83 82Z" fill="var(--colors-selectedBorder)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M107 87H87V85H107V87Z" fill="var(--colors-selected-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M137 87H117V85H137V87Z" fill="var(--colors-selected-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M167 87H147V85H167V87Z" fill="var(--colors-selected-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M197 87H177V85H197V87Z" fill="var(--colors-selected-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M227 87H207V85H227V87Z" fill="var(--colors-selected-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M257 87H237V85H257V87Z" fill="var(--colors-selected-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M230 120H224V118H230V120Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M244 120H238V118H244V120Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M258 120H252V118H258V120Z" fill="var(--colors-gray-2)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M272 120H266V118H272V120Z" fill="var(--colors-gray-2)" />
    </svg>
  );
}
