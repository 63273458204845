import { IconPropsExtended } from "./IconPropsExtended";

export default function BlockIcon({ size = 20, color = 'currentColor', fill = false, weight = 'normal' }: IconPropsExtended) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M12 21.5q-1.975 0-3.712-.75Q6.55 20 5.275 18.725T3.25 15.712Q2.5 13.975 2.5 12t.75-3.713Q4 6.55 5.275 5.275T8.288 3.25Q10.025 2.5 12 2.5t3.713.75q1.737.75 3.012 2.025t2.025 3.012q.75 1.738.75 3.713t-.75 3.712q-.75 1.738-2.025 3.013t-3.012 2.025q-1.738.75-3.713.75Zm0-1.5q3.35 0 5.675-2.325Q20 15.35 20 12q0-1.4-.475-2.713Q19.05 7.975 18.15 6.9L6.9 18.15q1.075.9 2.388 1.375Q10.6 20 12 20Zm-6.15-2.9L17.1 5.85q-1.075-.9-2.387-1.375Q13.4 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 1.4.475 2.712.475 1.313 1.375 2.388Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d={
            fill
              ? 'M10 18.167q-1.688 0-3.177-.636-1.49-.635-2.604-1.76-1.115-1.125-1.75-2.615-.636-1.489-.636-3.156 0-1.708.636-3.187.635-1.48 1.75-2.594 1.114-1.115 2.604-1.75Q8.312 1.833 10 1.833q1.708 0 3.188.636 1.479.635 2.593 1.75 1.115 1.114 1.75 2.594.636 1.479.636 3.187 0 1.667-.636 3.156-.635 1.49-1.75 2.615-1.114 1.125-2.593 1.76-1.48.636-3.188.636Zm0-1.729q2.688 0 4.562-1.886 1.876-1.885 1.876-4.552 0-1.104-.344-2.094-.344-.989-.99-1.802l-9 9q.813.646 1.802.99.99.344 2.094.344Zm-5.104-2.563 8.979-9q-.813-.625-1.792-.969-.979-.344-2.083-.344-2.688 0-4.562 1.876Q3.562 7.312 3.562 10q0 1.083.344 2.073.344.989.99 1.802Z'
              : weight === 'bold'
              ? 'M10 18.167q-1.688 0-3.177-.636-1.49-.635-2.604-1.76-1.115-1.125-1.75-2.615-.636-1.489-.636-3.156 0-1.708.636-3.187.635-1.48 1.75-2.594 1.114-1.115 2.604-1.75Q8.312 1.833 10 1.833q1.708 0 3.188.636 1.479.635 2.593 1.75 1.115 1.114 1.75 2.594.636 1.479.636 3.187 0 1.667-.636 3.156-.635 1.49-1.75 2.615-1.114 1.125-2.593 1.76-1.48.636-3.188.636Zm0-1.729q2.688 0 4.562-1.886 1.876-1.885 1.876-4.552 0-1.104-.344-2.094-.344-.989-.99-1.802l-9 9q.813.646 1.802.99.99.344 2.094.344Zm-5.104-2.563 8.979-9q-.813-.625-1.792-.969-.979-.344-2.083-.344-2.688 0-4.562 1.876Q3.562 7.312 3.562 10q0 1.083.344 2.073.344.989.99 1.802Z'
              : 'M10 17.583q-1.562 0-2.948-.593-1.385-.594-2.417-1.625-1.031-1.032-1.625-2.417-.593-1.386-.593-2.948 0-1.583.593-2.958.594-1.375 1.625-2.407Q5.667 3.604 7.052 3.01 8.438 2.417 10 2.417q1.583 0 2.958.593 1.375.594 2.407 1.625 1.031 1.032 1.625 2.407.593 1.375.593 2.958 0 1.562-.593 2.948-.594 1.385-1.625 2.417-1.032 1.031-2.407 1.625-1.375.593-2.958.593Zm0-1.083q2.708 0 4.604-1.896T16.5 10q0-1.188-.406-2.26-.406-1.073-1.136-1.928l-9.146 9.146q.855.73 1.928 1.136 1.072.406 2.26.406Zm-4.958-2.312 9.146-9.146q-.855-.73-1.928-1.136Q11.188 3.5 10 3.5q-2.708 0-4.604 1.896T3.5 10q0 1.188.406 2.26.406 1.073 1.136 1.928Z'
          }
        />
      </svg>
    );
  }
}
