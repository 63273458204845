import { Center, Loader } from '@mantine/core';
import classes from './LoadingState.module.css';

type LoadingStateProps = {
  h?: number | string;
  w?: number | string;
  border?: boolean;
};

export function BNLoadingState({ h, w, border }: LoadingStateProps) {
  return (
    <Center h={h || '100%'} w={w || '100%'} p="xl" className={border ? `${classes.wrapperBorder}` : ''}>
      <Loader color="var(--colors-gray-5)" type="dots" />
    </Center>
  );
}
