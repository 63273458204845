import { IconPropsBase } from './IconPropsBase';

export default function ChevronBackIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path fill={color} d="M10 21.65.35 12 10 2.35l1.425 1.425L3.175 12l8.25 8.225Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path fill={color} d="M8 17.708.292 10 8 2.292l1.125 1.125L2.521 10l6.604 6.583Z" />
      </svg>
    );
  }
}
