import { Box, Center, Divider, Flex, SimpleGrid, Skeleton, Text } from '@mantine/core';
import { ReactNode, useState } from 'react';
import classes from './StatBox.module.css';
import ArrowForwardIcon from '../icons/ArrowForward';
import { BNEmptyState } from '../EmptyState/EmptyState';
import ErrorIcon from '../icons/Error';

type StatLineProps = {
  left?: string | ReactNode;
  right?: string | ReactNode;
};

export function StatLine({ left, right }: StatLineProps) {
  return (
    <>
      <Text tt="uppercase" fz={11} fw={500} c="var(--colors-gray-5)" w={80} style={{ whiteSpace: 'nowrap' }}>
        {left}
      </Text>
      <Text ta="right" className={classes.statValue}>
        {right}
      </Text>
    </>
  );
}

type StatBoxProps = {
  title: string;
  statValue: string;
  color?: string;
  icon?: ReactNode;
  children?: ReactNode;
  isLoading?: boolean;
  isError?: boolean;
};
export function StatBox({ statValue, title, color, icon, children, isLoading, isError }: StatBoxProps) {
  const border = color === 'red' ? '1px solid var(--colors-chart-red-1)' : color === 'green' ? '1px solid var(--colors-chart-green-1)' : '1px solid var(--colors-gray-2)';
  const iconSection =
    color === 'red' ? (
      <Center className={classes.icon} bg="var(--colors-chart-red-1)" pos="relative" h={{ base: 20, lg: 28 }} w={{ base: 20, lg: 28 }} style={{ transform: 'rotate(45deg)' }}>
        <ArrowForwardIcon color="white" />
      </Center>
    ) : color === 'green' ? (
      <Center className={classes.icon} bg="var(--colors-chart-green-1)" pos="relative" h={{ base: 20, lg: 28 }} w={{ base: 20, lg: 28 }} style={{ transform: 'rotate(-45deg)' }}>
        <ArrowForwardIcon color="white" />
      </Center>
    ) : (
      <Center className={classes.icon} bg="var(--colors-gray-2)" pos="relative" h={{ base: 20, lg: 28 }} w={{ base: 20, lg: 28 }} c="var(--colors-gray-5)">
        {icon ? icon : <ArrowForwardIcon />}
      </Center>
    );
  return (
    <Flex bg="var(--colors-paper)" direction="column" py="xs" px="sm" gap="xs" style={{ border: border, borderRadius: 3 }}>
      <Text tt="uppercase" fz={11} c="var(--colors-gray-5)" truncate style={{ whiteSpace: 'noWrap' }}>
        {title}
      </Text>
      {!isError ? (
        <>
          {isLoading ? (
            <Flex justify="space-between" align="center" h={{ base: 24, lg: 31 }}>
              <Skeleton h="xs" maw="50%" />
              <Skeleton circle={true} h={{ base: 20, lg: 28 }} w={{ base: 20, lg: 28 }} style={{ borderRadius: 100 }} />
            </Flex>
          ) : (
            <Flex justify="space-between" align="center">
              <Text fw={600} lts={-0.5} fz={{ base: 'md', lg: 'xl' }}>
                {statValue}
              </Text>
              {iconSection}
            </Flex>
          )}
          {children && (
            <>
              <Divider color="var(--colors-divider)" />
              <Skeleton visible={isLoading}>
                <SimpleGrid fz="xs" cols={2} spacing={0} verticalSpacing={2} pt={6} fw={600}>
                  {children}
                </SimpleGrid>
              </Skeleton>
            </>
          )}
        </>
      ) : children ? (
        <Flex h={121} align="center" justify="center">
          <BNEmptyState title="Data Error" titleFz={14} h="100%" border={false} />
        </Flex>
      ) : (
        <Flex h={31} align="center" justify="center">
          <BNEmptyState title="Data Error" titleFz={14} h="100%" border={false} />
        </Flex>
      )}
    </Flex>
  );
}

StatBox.StatLine = StatLine;
