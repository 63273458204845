import { Avatar, AvatarProps, Indicator, Text, Tooltip } from '@mantine/core';
import { BarkerCoreModelsAdministrativePrincipal } from '../../api';
import AccountIcon from '../icons/Account';
import classes from './Avatar.module.css';

interface BNAvatarProps extends AvatarProps {
  principal: BarkerCoreModelsAdministrativePrincipal | undefined;
  isClickable?: boolean;
  indicator?: boolean;
}

export function BNAvatar({ principal, isClickable, indicator }: BNAvatarProps) {
  {
    const nameAvailable = (principal?.givenName ?? '') !== '' || (principal?.familyName ?? '') !== '';
    const avatarHover = nameAvailable ? `${principal?.givenName} ${principal?.familyName} (${principal?.email})` : principal?.email;
    const AvatarIcon = nameAvailable ? (
      <Text fw={500}>{(principal?.givenName?.charAt(0).toUpperCase() || '') + (principal?.familyName?.charAt(0).toUpperCase() || '')}</Text>
    ) : (
      <AccountIcon size={28} color="var(--colors-gray-5)" />
    );

    return (
      <Tooltip label={avatarHover} position="right" withArrow>
        <Indicator offset={4} size={12} withBorder disabled={!indicator} color="var(--colors-red-error)" styles={{ indicator: { borderColor: 'var(--colors-paper)' } }}>
          <Avatar radius="xl" size="md" className={isClickable ? classes.avatarHoverOutline : classes.avatar}>
            {AvatarIcon}
          </Avatar>
        </Indicator>
      </Tooltip>
    );
  }
}
