import { IconPropsBase } from './IconPropsBase';

export default function ListingAgeIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M11.1,21.4C10.4,21,9.9,20.5,9.4,20H5.3c-0.1,0-0.1,0-0.2-0.1C5,19.8,5,19.8,5,19.7v-9.4h14v0.8c0.5,0.1,1,0.2,1.5,0.4V6.3
	c0-0.5-0.2-0.9-0.5-1.3s-0.8-0.5-1.3-0.5h-1.4V2.4h-1.5v2.1H8.2V2.4H6.7v2.1H5.3C4.8,4.5,4.4,4.7,4,5S3.5,5.8,3.5,6.3v13.4
	c0,0.5,0.2,0.9,0.5,1.3s0.8,0.5,1.3,0.5c0,0,3.7,0,6,0C11.2,21.4,11.2,21.4,11.1,21.4z M5,6.3c0-0.1,0-0.1,0.1-0.2
	C5.2,6,5.2,6,5.3,6h13.4c0.1,0,0.1,0,0.2,0.1C19,6.2,19,6.2,19,6.3v2.5H5V6.3z M17,19.5c1,0,1.9-0.2,2.7-0.7c0.8-0.5,1.5-1.1,2-1.8
	c-0.5-0.8-1.2-1.4-2-1.8S18,14.5,17,14.5c-1,0-1.9,0.2-2.7,0.7s-1.5,1.1-2,1.8c0.5,0.8,1.2,1.4,2,1.8C15.1,19.3,16,19.5,17,19.5z
	 M17,21c-1.4,0-2.7-0.4-3.9-1.1c-1.2-0.7-2-1.7-2.5-2.9c0.5-1.2,1.4-2.1,2.5-2.9c1.2-0.7,2.5-1.1,3.9-1.1s2.7,0.4,3.9,1.1
	c1.2,0.7,2,1.7,2.5,2.9c-0.5,1.2-1.4,2.1-2.5,2.9C19.7,20.6,18.4,21,17,21z M17,18.4c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1
	c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1c0,0.4-0.1,0.7-0.4,1
	C17.7,18.3,17.4,18.4,17,18.4z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M9.8,17.3c-0.4-0.2-0.8-0.5-1.2-0.8H4.8c-0.1,0-0.1,0-0.2-0.1s-0.1-0.1-0.1-0.2v-7h11v0.1c0.4,0,0.7,0.1,1.1,0.2V5.8
	c0-0.4-0.1-0.7-0.4-0.9c-0.3-0.3-0.6-0.4-0.9-0.4h-1.8V2.3h-1.1v2.1H7.7V2.3H6.6v2.1H4.8c-0.4,0-0.7,0.1-0.9,0.4
	C3.5,5.1,3.4,5.4,3.4,5.8v10.5c0,0.4,0.1,0.7,0.4,1s0.6,0.4,1,0.4h5.6C10.1,17.5,10,17.4,9.8,17.3z M4.5,5.8c0-0.1,0-0.1,0.1-0.2
	s0.1-0.1,0.2-0.1h10.5c0.1,0,0.1,0,0.2,0.1s0.1,0.1,0.1,0.2v2.4h-11V5.8z M14.4,15.9c0.8,0,1.6-0.2,2.3-0.5c0.7-0.3,1.3-0.8,1.8-1.5
	c-0.4-0.7-1-1.2-1.8-1.5c-0.7-0.3-1.5-0.5-2.3-0.5s-1.6,0.2-2.3,0.5c-0.7,0.3-1.3,0.8-1.8,1.5c0.4,0.7,1,1.2,1.8,1.5
	C12.9,15.7,13.6,15.9,14.4,15.9z M14.4,17c-1.1,0-2.1-0.3-3.1-0.8c-1-0.5-1.7-1.3-2.2-2.3c0.5-1,1.2-1.8,2.2-2.3
	c1-0.5,2-0.8,3.1-0.8s2.1,0.3,3.1,0.8c1,0.5,1.7,1.3,2.2,2.3c-0.5,1-1.2,1.8-2.2,2.3C16.6,16.7,15.6,17,14.4,17z M14.4,15.1
	c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.8,0.3
	c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8C15.1,15,14.8,15.1,14.4,15.1z"
        />
      </svg>
    );
  }
}
