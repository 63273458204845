import { useMutation, useQuery } from '@tanstack/react-query';
import type { MutationFunction, QueryFunction, QueryKey, UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  BarkerCoreModelsAdministrativeFeature,
  BarkerCoreModelsAdministrativeGetMarketingStatusResponse,
  BarkerCoreModelsAdministrativeInterrupt,
  BarkerCoreModelsAdministrativePrincipal,
  BarkerCoreModelsAdministrativePrincipalSettings,
  BarkerCoreModelsAdministrativeUserInvite,
  BarkerCoreModelsNotificationsBulletin,
  SystemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativePrincipalBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull,
} from './models';

export const postApiPrincipalsSelf = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativePrincipal>> => {
  return axios.post(`/api/Principals/Self`, undefined, options);
};

export const getPostApiPrincipalsSelfMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiPrincipalsSelf>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiPrincipalsSelf>>, TError, void, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiPrincipalsSelf>>, void> = () => {
    return postApiPrincipalsSelf(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiPrincipalsSelfMutationResult = NonNullable<Awaited<ReturnType<typeof postApiPrincipalsSelf>>>;

export type PostApiPrincipalsSelfMutationError = AxiosError<void>;

export const usePostApiPrincipalsSelf = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiPrincipalsSelf>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postApiPrincipalsSelf>>, TError, void, TContext> => {
  const mutationOptions = getPostApiPrincipalsSelfMutationOptions(options);

  return useMutation(mutationOptions);
};
export const patchApiPrincipalsSelf = (
  systemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativePrincipalBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull: SystemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativePrincipalBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsAdministrativePrincipal>> => {
  return axios.patch(
    `/api/Principals/Self`,
    systemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativePrincipalBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull,
    options,
  );
};

export const getPatchApiPrincipalsSelfMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiPrincipalsSelf>>,
    TError,
    { data: SystemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativePrincipalBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchApiPrincipalsSelf>>,
  TError,
  { data: SystemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativePrincipalBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchApiPrincipalsSelf>>,
    { data: SystemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativePrincipalBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull }
  > = (props) => {
    const { data } = props ?? {};

    return patchApiPrincipalsSelf(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApiPrincipalsSelfMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiPrincipalsSelf>>>;
export type PatchApiPrincipalsSelfMutationBody = SystemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativePrincipalBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull;
export type PatchApiPrincipalsSelfMutationError = AxiosError<void>;

export const usePatchApiPrincipalsSelf = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiPrincipalsSelf>>,
    TError,
    { data: SystemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativePrincipalBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchApiPrincipalsSelf>>,
  TError,
  { data: SystemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativePrincipalBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull },
  TContext
> => {
  const mutationOptions = getPatchApiPrincipalsSelfMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiPrincipalsSelf = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativePrincipal>> => {
  return axios.get(`/api/Principals/Self`, options);
};

export const getGetApiPrincipalsSelfQueryKey = () => {
  return [`/api/Principals/Self`] as const;
};

export const getGetApiPrincipalsSelfQueryOptions = <TData = Awaited<ReturnType<typeof getApiPrincipalsSelf>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsSelf>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiPrincipalsSelfQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiPrincipalsSelf>>> = ({ signal }) => getApiPrincipalsSelf({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsSelf>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiPrincipalsSelfQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPrincipalsSelf>>>;
export type GetApiPrincipalsSelfQueryError = AxiosError<void>;

export const useGetApiPrincipalsSelf = <TData = Awaited<ReturnType<typeof getApiPrincipalsSelf>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsSelf>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPrincipalsSelfQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiPrincipalsId = (id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativePrincipal>> => {
  return axios.get(`/api/Principals/${id}`, options);
};

export const getGetApiPrincipalsIdQueryKey = (id: string) => {
  return [`/api/Principals/${id}`] as const;
};

export const getGetApiPrincipalsIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiPrincipalsId>>, TError = AxiosError<void>>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsId>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiPrincipalsIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiPrincipalsId>>> = ({ signal }) => getApiPrincipalsId(id, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsId>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiPrincipalsIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPrincipalsId>>>;
export type GetApiPrincipalsIdQueryError = AxiosError<void>;

export const useGetApiPrincipalsId = <TData = Awaited<ReturnType<typeof getApiPrincipalsId>>, TError = AxiosError<void>>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsId>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPrincipalsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteApiPrincipalsId = (id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/Principals/${id}`, options);
};

export const getDeleteApiPrincipalsIdMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiPrincipalsId>>, TError, { id: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteApiPrincipalsId>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiPrincipalsId>>, { id: string }> = (props) => {
    const { id } = props ?? {};

    return deleteApiPrincipalsId(id, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiPrincipalsIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiPrincipalsId>>>;

export type DeleteApiPrincipalsIdMutationError = AxiosError<void>;

export const useDeleteApiPrincipalsId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiPrincipalsId>>, TError, { id: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof deleteApiPrincipalsId>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteApiPrincipalsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiPrincipalsIdDisable = (id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Principals/${id}/Disable`, undefined, options);
};

export const getPutApiPrincipalsIdDisableMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsIdDisable>>, TError, { id: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsIdDisable>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiPrincipalsIdDisable>>, { id: string }> = (props) => {
    const { id } = props ?? {};

    return putApiPrincipalsIdDisable(id, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiPrincipalsIdDisableMutationResult = NonNullable<Awaited<ReturnType<typeof putApiPrincipalsIdDisable>>>;

export type PutApiPrincipalsIdDisableMutationError = AxiosError<void>;

export const usePutApiPrincipalsIdDisable = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsIdDisable>>, TError, { id: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiPrincipalsIdDisable>>, TError, { id: string }, TContext> => {
  const mutationOptions = getPutApiPrincipalsIdDisableMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiPrincipalsLogin = (options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Principals/Login`, undefined, options);
};

export const getPutApiPrincipalsLoginMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsLogin>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsLogin>>, TError, void, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiPrincipalsLogin>>, void> = () => {
    return putApiPrincipalsLogin(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiPrincipalsLoginMutationResult = NonNullable<Awaited<ReturnType<typeof putApiPrincipalsLogin>>>;

export type PutApiPrincipalsLoginMutationError = AxiosError<void>;

export const usePutApiPrincipalsLogin = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsLogin>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiPrincipalsLogin>>, TError, void, TContext> => {
  const mutationOptions = getPutApiPrincipalsLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiPrincipalsBulletins = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsNotificationsBulletin[]>> => {
  return axios.get(`/api/Principals/Bulletins`, options);
};

export const getGetApiPrincipalsBulletinsQueryKey = () => {
  return [`/api/Principals/Bulletins`] as const;
};

export const getGetApiPrincipalsBulletinsQueryOptions = <TData = Awaited<ReturnType<typeof getApiPrincipalsBulletins>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsBulletins>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiPrincipalsBulletinsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiPrincipalsBulletins>>> = ({ signal }) => getApiPrincipalsBulletins({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsBulletins>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiPrincipalsBulletinsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPrincipalsBulletins>>>;
export type GetApiPrincipalsBulletinsQueryError = AxiosError<void>;

export const useGetApiPrincipalsBulletins = <TData = Awaited<ReturnType<typeof getApiPrincipalsBulletins>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsBulletins>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPrincipalsBulletinsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putApiPrincipalsBulletinsBulletinIdAcknowledge = (bulletinId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Principals/Bulletins/${bulletinId}/Acknowledge`, undefined, options);
};

export const getPutApiPrincipalsBulletinsBulletinIdAcknowledgeMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsBulletinsBulletinIdAcknowledge>>, TError, { bulletinId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsBulletinsBulletinIdAcknowledge>>, TError, { bulletinId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiPrincipalsBulletinsBulletinIdAcknowledge>>, { bulletinId: string }> = (props) => {
    const { bulletinId } = props ?? {};

    return putApiPrincipalsBulletinsBulletinIdAcknowledge(bulletinId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiPrincipalsBulletinsBulletinIdAcknowledgeMutationResult = NonNullable<Awaited<ReturnType<typeof putApiPrincipalsBulletinsBulletinIdAcknowledge>>>;

export type PutApiPrincipalsBulletinsBulletinIdAcknowledgeMutationError = AxiosError<void>;

export const usePutApiPrincipalsBulletinsBulletinIdAcknowledge = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsBulletinsBulletinIdAcknowledge>>, TError, { bulletinId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiPrincipalsBulletinsBulletinIdAcknowledge>>, TError, { bulletinId: string }, TContext> => {
  const mutationOptions = getPutApiPrincipalsBulletinsBulletinIdAcknowledgeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiPrincipalsFeatures = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativeFeature[]>> => {
  return axios.get(`/api/Principals/Features`, options);
};

export const getGetApiPrincipalsFeaturesQueryKey = () => {
  return [`/api/Principals/Features`] as const;
};

export const getGetApiPrincipalsFeaturesQueryOptions = <TData = Awaited<ReturnType<typeof getApiPrincipalsFeatures>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsFeatures>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiPrincipalsFeaturesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiPrincipalsFeatures>>> = ({ signal }) => getApiPrincipalsFeatures({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsFeatures>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiPrincipalsFeaturesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPrincipalsFeatures>>>;
export type GetApiPrincipalsFeaturesQueryError = AxiosError<void>;

export const useGetApiPrincipalsFeatures = <TData = Awaited<ReturnType<typeof getApiPrincipalsFeatures>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsFeatures>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPrincipalsFeaturesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putApiPrincipalsFeaturesFeatureIdSeen = (featureId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Principals/Features/${featureId}/Seen`, undefined, options);
};

export const getPutApiPrincipalsFeaturesFeatureIdSeenMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsFeaturesFeatureIdSeen>>, TError, { featureId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsFeaturesFeatureIdSeen>>, TError, { featureId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiPrincipalsFeaturesFeatureIdSeen>>, { featureId: string }> = (props) => {
    const { featureId } = props ?? {};

    return putApiPrincipalsFeaturesFeatureIdSeen(featureId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiPrincipalsFeaturesFeatureIdSeenMutationResult = NonNullable<Awaited<ReturnType<typeof putApiPrincipalsFeaturesFeatureIdSeen>>>;

export type PutApiPrincipalsFeaturesFeatureIdSeenMutationError = AxiosError<void>;

export const usePutApiPrincipalsFeaturesFeatureIdSeen = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsFeaturesFeatureIdSeen>>, TError, { featureId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiPrincipalsFeaturesFeatureIdSeen>>, TError, { featureId: string }, TContext> => {
  const mutationOptions = getPutApiPrincipalsFeaturesFeatureIdSeenMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiPrincipalsInterrupts = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativeInterrupt[]>> => {
  return axios.get(`/api/Principals/Interrupts`, options);
};

export const getGetApiPrincipalsInterruptsQueryKey = () => {
  return [`/api/Principals/Interrupts`] as const;
};

export const getGetApiPrincipalsInterruptsQueryOptions = <TData = Awaited<ReturnType<typeof getApiPrincipalsInterrupts>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsInterrupts>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiPrincipalsInterruptsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiPrincipalsInterrupts>>> = ({ signal }) => getApiPrincipalsInterrupts({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsInterrupts>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiPrincipalsInterruptsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPrincipalsInterrupts>>>;
export type GetApiPrincipalsInterruptsQueryError = AxiosError<void>;

export const useGetApiPrincipalsInterrupts = <TData = Awaited<ReturnType<typeof getApiPrincipalsInterrupts>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsInterrupts>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPrincipalsInterruptsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putApiPrincipalsInterruptsInterruptIdComplete = (interruptId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Principals/Interrupts/${interruptId}/Complete`, undefined, options);
};

export const getPutApiPrincipalsInterruptsInterruptIdCompleteMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsInterruptsInterruptIdComplete>>, TError, { interruptId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsInterruptsInterruptIdComplete>>, TError, { interruptId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiPrincipalsInterruptsInterruptIdComplete>>, { interruptId: string }> = (props) => {
    const { interruptId } = props ?? {};

    return putApiPrincipalsInterruptsInterruptIdComplete(interruptId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiPrincipalsInterruptsInterruptIdCompleteMutationResult = NonNullable<Awaited<ReturnType<typeof putApiPrincipalsInterruptsInterruptIdComplete>>>;

export type PutApiPrincipalsInterruptsInterruptIdCompleteMutationError = AxiosError<void>;

export const usePutApiPrincipalsInterruptsInterruptIdComplete = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsInterruptsInterruptIdComplete>>, TError, { interruptId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiPrincipalsInterruptsInterruptIdComplete>>, TError, { interruptId: string }, TContext> => {
  const mutationOptions = getPutApiPrincipalsInterruptsInterruptIdCompleteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiPrincipalsInvites = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativeUserInvite[]>> => {
  return axios.get(`/api/Principals/Invites`, options);
};

export const getGetApiPrincipalsInvitesQueryKey = () => {
  return [`/api/Principals/Invites`] as const;
};

export const getGetApiPrincipalsInvitesQueryOptions = <TData = Awaited<ReturnType<typeof getApiPrincipalsInvites>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsInvites>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiPrincipalsInvitesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiPrincipalsInvites>>> = ({ signal }) => getApiPrincipalsInvites({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsInvites>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiPrincipalsInvitesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPrincipalsInvites>>>;
export type GetApiPrincipalsInvitesQueryError = AxiosError<void>;

export const useGetApiPrincipalsInvites = <TData = Awaited<ReturnType<typeof getApiPrincipalsInvites>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsInvites>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPrincipalsInvitesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiPrincipalsMarketing = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativeGetMarketingStatusResponse>> => {
  return axios.get(`/api/Principals/Marketing`, options);
};

export const getGetApiPrincipalsMarketingQueryKey = () => {
  return [`/api/Principals/Marketing`] as const;
};

export const getGetApiPrincipalsMarketingQueryOptions = <TData = Awaited<ReturnType<typeof getApiPrincipalsMarketing>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsMarketing>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiPrincipalsMarketingQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiPrincipalsMarketing>>> = ({ signal }) => getApiPrincipalsMarketing({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsMarketing>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiPrincipalsMarketingQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPrincipalsMarketing>>>;
export type GetApiPrincipalsMarketingQueryError = AxiosError<void>;

export const useGetApiPrincipalsMarketing = <TData = Awaited<ReturnType<typeof getApiPrincipalsMarketing>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsMarketing>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPrincipalsMarketingQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putApiPrincipalsMarketingSubscribe = (options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Principals/Marketing/Subscribe`, undefined, options);
};

export const getPutApiPrincipalsMarketingSubscribeMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsMarketingSubscribe>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsMarketingSubscribe>>, TError, void, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiPrincipalsMarketingSubscribe>>, void> = () => {
    return putApiPrincipalsMarketingSubscribe(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiPrincipalsMarketingSubscribeMutationResult = NonNullable<Awaited<ReturnType<typeof putApiPrincipalsMarketingSubscribe>>>;

export type PutApiPrincipalsMarketingSubscribeMutationError = AxiosError<void>;

export const usePutApiPrincipalsMarketingSubscribe = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsMarketingSubscribe>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiPrincipalsMarketingSubscribe>>, TError, void, TContext> => {
  const mutationOptions = getPutApiPrincipalsMarketingSubscribeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiPrincipalsMarketingUnsubscribe = (options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Principals/Marketing/Unsubscribe`, undefined, options);
};

export const getPutApiPrincipalsMarketingUnsubscribeMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsMarketingUnsubscribe>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsMarketingUnsubscribe>>, TError, void, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiPrincipalsMarketingUnsubscribe>>, void> = () => {
    return putApiPrincipalsMarketingUnsubscribe(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiPrincipalsMarketingUnsubscribeMutationResult = NonNullable<Awaited<ReturnType<typeof putApiPrincipalsMarketingUnsubscribe>>>;

export type PutApiPrincipalsMarketingUnsubscribeMutationError = AxiosError<void>;

export const usePutApiPrincipalsMarketingUnsubscribe = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiPrincipalsMarketingUnsubscribe>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiPrincipalsMarketingUnsubscribe>>, TError, void, TContext> => {
  const mutationOptions = getPutApiPrincipalsMarketingUnsubscribeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiPrincipalsSettingsDefault = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativePrincipalSettings>> => {
  return axios.get(`/api/Principals/Settings/Default`, options);
};

export const getGetApiPrincipalsSettingsDefaultQueryKey = () => {
  return [`/api/Principals/Settings/Default`] as const;
};

export const getGetApiPrincipalsSettingsDefaultQueryOptions = <TData = Awaited<ReturnType<typeof getApiPrincipalsSettingsDefault>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsSettingsDefault>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiPrincipalsSettingsDefaultQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiPrincipalsSettingsDefault>>> = ({ signal }) => getApiPrincipalsSettingsDefault({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsSettingsDefault>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiPrincipalsSettingsDefaultQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPrincipalsSettingsDefault>>>;
export type GetApiPrincipalsSettingsDefaultQueryError = AxiosError<void>;

export const useGetApiPrincipalsSettingsDefault = <TData = Awaited<ReturnType<typeof getApiPrincipalsSettingsDefault>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiPrincipalsSettingsDefault>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPrincipalsSettingsDefaultQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
