import { IconPropsBase } from './IconPropsBase';

export default function ContentCopyIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M230.002-107.694q-30.307 0-51.307-21-21-21-21-51.307V-687.69h59.999v507.689q0 4.615 3.846 8.462 3.846 3.846 8.462 3.846h387.689v59.999H230.002Zm139.999-139.999q-30.308 0-51.308-21-20.999-21-20.999-51.307v-455.383q0-30.307 20.999-51.307 21-21 51.308-21h335.383q30.307 0 51.307 21 21 21 21 51.307V-320q0 30.307-21 51.307-21 21-51.307 21H370.001Zm0-59.998h335.383q4.615 0 8.462-3.847 3.846-3.846 3.846-8.462v-455.383q0-4.616-3.846-8.462-3.847-3.846-8.462-3.846H370.001q-4.616 0-8.462 3.846-3.847 3.846-3.847 8.462V-320q0 4.616 3.847 8.462 3.846 3.847 8.462 3.847Zm-12.309 0v-480V-307.691Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M246.002-123.694q-27.007 0-45.657-18.65-18.65-18.65-18.65-45.657V-687.69h51.999v499.689q0 4.615 3.846 8.462 3.846 3.846 8.462 3.846h403.689v51.999H246.002Zm123.999-123.999q-27.008 0-45.658-18.65-18.649-18.649-18.649-45.657v-455.383q0-27.007 18.649-45.657 18.65-18.65 45.658-18.65h359.383q27.007 0 45.657 18.65 18.65 18.65 18.65 45.657V-312q0 27.008-18.65 45.657-18.65 18.65-45.657 18.65H370.001Zm0-51.998h359.383q4.615 0 8.462-3.847 3.846-3.846 3.846-8.462v-455.383q0-4.616-3.846-8.462-3.847-3.846-8.462-3.846H370.001q-4.616 0-8.462 3.846-3.847 3.846-3.847 8.462V-312q0 4.616 3.847 8.462 3.846 3.847 8.462 3.847Zm-12.309 0v-480V-299.691Z"
        />
      </svg>
    );
  }
}
