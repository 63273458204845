import { MarketplaceNames } from '../models/marketplaces';

export function getStandardMarketplaceName(name?: string): MarketplaceNames {
  if (!name) {
    return 'Other';
  }

  const lowerName = name.toLowerCase();

  if (lowerName.includes('geek')) {
    return 'SeatGeek';
  }

  if (lowerName.includes('stub') || lowerName.includes('viagogo')) {
    return 'StubHub';
  }

  if (lowerName.includes('axs') || lowerName.includes('pdn')) {
    return 'AXS';
  }

  if (lowerName.includes('vivid') || lowerName.includes('fanx')) {
    return 'Vivid Seats';
  }

  if (lowerName.includes('ticketsnow') || lowerName.includes('tnow') || lowerName.includes('event inventory') || lowerName.includes('ticketmaster') || lowerName.includes('tm')) {
    return 'Ticketmaster';
  }

  if (lowerName.includes('pick')) {
    return 'TickPick';
  }

  if (lowerName.includes('evo')) {
    return 'TEVO';
  }

  if (lowerName.includes('gametime')) {
    return 'Gametime';
  }

  if (lowerName.includes('ticket network') || lowerName.includes('tnd') || lowerName.includes('ticketnetwork') || lowerName.includes('mercury')) {
    return 'TicketNetwork';
  }

  return 'Other';
}
