import { ActionIcon, Avatar, Box, Center, Checkbox, Divider, Flex, Menu, Modal, SegmentedControl, Space, Text, UnstyledButton } from '@mantine/core';
import mobileClasses from './Mobile.module.css';
import classes from './MarketFilters.Mobile.module.css';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import ChevronBackIcon from '../components/icons/ChevronBack';
import MoreHorizIcon from '../components/icons/MoreHoriz';
import { ReactNode, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import LiveHelpIcon from '../components/icons/LiveHelp';
import ChevronForwardIcon from '../components/icons/ChevronForward';
import { BNTextInput } from '../components/TextInput/TextInput';
import { BNButton } from '../components/Button/Button';
import ResetIcon from '../components/icons/Reset';
import { formatDate, formatSeatRange } from '../utils/formatters';
import { InputModal } from '../hoc/Mobile/InputModal';
import { useSeatingChart } from '../hoc/SeatingChart/SeatingChart.hooks';
import { DateFormats } from '../utils/globals';
import { filtersDirtyAtom, mergedEventListingsAtom, ruleDirtyAtom, ruleStateAtom } from '../data/atoms';
import { useAtom, useAtomValue } from 'jotai';
import { ReactComponent as SeatGeekLogo } from '../img/sg.svg';
import { useRuleState } from '../data/RuleState';
import SelectArrowsIcon from '../components/icons/SelectArrows';
import { BarkerCoreEnumsDeliveryMethod, BarkerCoreEnumsListingDisclosure, BarkerCoreEnumsMarketplace } from '../api';
import tevo from '../img/tevo.png';
import tnet from '../img/tnet.jpeg';
import tm from '../img/tm.png';
import SyncAltIcon from '../components/icons/SyncAlt';
import { ExclusionsList } from '../hoc/SeatingChart/Filters/Filters.ExclusionMultiSelect';
import * as R from 'remeda';
import AccessibleIcon from '../components/icons/Accessible';
import VisibilityOffIcon from '../components/icons/VisibilityOff';
import AccessibilityNewIcon from '../components/icons/AccessibilityNew';
import FlashOnIcon from '../components/icons/FlashOn';
import QrCodeIcon from '../components/icons/QrCode';
import LocalShippingIcon from '../components/icons/LocalShipping';
import LocationOnIcon from '../components/icons/LocationOn';
import ConfirmationNumberIcon from '../components/icons/ConfirmationNumber';
import { OutliersPopoverSelect } from '../hoc/SeatingChart/Filters/Filters.Outliers';
import { useGlobalState } from '../data/GlobalState';
import { BNSwitch } from '../components/Switch/Switch';
import { NavBarMobile } from '../hoc/NavBar/NavBar.Mobile';
import { CustomLoadingOverlay } from '../hoc/SeatingChart/SeatingChart.Loading';
import { Venue } from '../hoc/SeatingChart/SeatingChart.Venue';
import { SectionChecklist } from '../hoc/SeatingChart/SeatingChart.SectionChecklist';
import { SplitsButtonGroupVariable } from '../hoc/SeatingChart/Filters/Filters.SplitsButtonGroupVariable';
import { EmptyState } from '../hoc/SeatingChart/SeatingChart.EmptyState';
import { MarketplaceSelector } from '../hoc/SeatingChart/SeatingChart.MarketplaceSelector';
import { MarketListings } from '../hoc/MarketListings/MarketListings';
import { MarketListingProvider } from '../hoc/MarketListings/MarketListings.hooks';

export function MarketFiltersMobile() {
  return <MarketFiltersMobileInner />;
}

export function MarketFiltersMobileInner() {
  document.body.setAttribute('data-mobile', 'true');

  const [menuModalOpened, { open: openMenuModal, close: closeMenuModal }] = useDisclosure(false);
  const [exclusionsModalOpened, { open: openExclusionsModal, close: closeExclusionsModal }] = useDisclosure(false);
  const [marketplaceModalOpened, { open: openMarketplaceModal, close: closeMarketplaceModal }] = useDisclosure(false);
  const [scValue, setScValue] = useState<'seating-chart' | 'other-filters'>('seating-chart');
  const navigate = useNavigate();
  const { eventId, listingId } = useParams();
  const { selectedEvent, resetAll, isLoading, selectedMarketplaceEvent, isSeatingChartError, sectionsWithListings, forceSectionCheckList, selectedMarketplaceId, form } =
    useSeatingChart(
      'selectedEvent',
      'resetAll',
      'isLoading',
      'selectedMarketplaceEvent',
      'isSeatingChartError',
      'sectionsWithListings',
      'forceSectionCheckList',
      'selectedMarketplaceId',
      'form',
    );
  const { triggerSaveRule } = useRuleState('triggerSaveRule');
  const mergedEventListings = useAtomValue(mergedEventListingsAtom);
  const selectedListing = mergedEventListings.find((x) => x.listingId === listingId && x.eventId === eventId);
  const [rule, setRule] = useAtom(ruleStateAtom);
  const ruleDirty = useAtomValue(ruleDirtyAtom);
  const filtersDirty = useAtomValue(filtersDirtyAtom);
  const unsavedChanges = ruleDirty || filtersDirty;

  const scrollTrackerRef = useRef<HTMLDivElement>(null);

  const { tenants, principal } = useGlobalState('tenants', 'principal');
  const isMultiTenant = tenants && tenants.length > 1;
  // Set tenant color name by searching through color arrays
  const tenantColorName = useMemo(() => {
    if (!selectedEvent?.eventId || !isMultiTenant) return 'brandcolor';

    // List all possible tenant colors from previous options
    const tenantRed = ['#C92A2A', '#F03E3E', '#FF6B6B', '#FFA8A8', 'var(--colors-tenant-red-5)', 'red'];
    const tenantOrange = ['#D9480F', '#F76707', '#FF922B', '#FFC078', 'var(--colors-tenant-orange-5)', 'orange'];
    const tenantYellow = ['#E67700', '#F59F00', '#FCC419', '#FFE066', 'var(--colors-tenant-yellow-5)', 'yellow'];
    const tenantGreen = ['#2B8A3E', '#37B24D', '#51CF66', '#8CE99A', 'var(--colors-tenant-green-5)', 'green'];
    const tenantCyan = ['#0B7285', '#1098AD', '#22B8CF', '#66D9E8', 'var(--colors-tenant-cyan-5)', 'cyan'];
    const tenantBlue = ['#1864AB', '#1C7ED6', '#339AF0', '#74C0FC', 'var(--colors-tenant-blue-5)', 'blue'];
    const tenantGrape = ['#862E9C', '#AE3EC9', '#CC5DE8', '#E599F7', 'var(--colors-tenant-grape-5)', 'grape'];
    const tenantPink = ['#A61E4D', '#D6336C', '#F06595', '#FAA2C1', 'var(--colors-tenant-pink-5)', 'pink'];
    const tenantBrandColor = ['#3d8161', '#63b187', 'var(--colors-brandgreen-5)', 'brandgreen', 'var(--colors-brandcolor-5)', 'brandcolor'];
    const tenantBlack = ['#000000', 'black'];
    const tenantGray4 = ['#333333', 'gray4'];
    const tenantGray3 = ['#666666', 'gray3'];
    const tenantGray2 = ['#999999', 'gray2'];
    const tenantGray1 = ['#E0E0E0', 'gray1'];

    const tenantColor = principal?.settings?.tenantColors[selectedEvent?.tenantId];

    if (tenantColor) {
      if (tenantBrandColor.includes(tenantColor)) {
        return 'brandcolor';
      }
      if (tenantRed.includes(tenantColor)) {
        return 'red';
      }
      if (tenantOrange.includes(tenantColor)) {
        return 'orange';
      }
      if (tenantYellow.includes(tenantColor)) {
        return 'yellow';
      }
      if (tenantGreen.includes(tenantColor)) {
        return 'green';
      }
      if (tenantCyan.includes(tenantColor)) {
        return 'cyan';
      }
      if (tenantBlue.includes(tenantColor)) {
        return 'blue';
      }
      if (tenantGrape.includes(tenantColor)) {
        return 'grape';
      }
      if (tenantPink.includes(tenantColor)) {
        return 'pink';
      }
      if (tenantBlack.includes(tenantColor)) {
        return 'black';
      }
      if (tenantGray4.includes(tenantColor)) {
        return 'gray4';
      }
      if (tenantGray3.includes(tenantColor)) {
        return 'gray3';
      }
      if (tenantGray2.includes(tenantColor)) {
        return 'gray2';
      }
      if (tenantGray1.includes(tenantColor)) {
        return 'gray1';
      }
    }
    return 'brandcolor';
  }, [selectedEvent?.eventId, selectedEvent?.tenantId, isMultiTenant, principal?.settings?.tenantColors]);

  const tenantClassName = isMultiTenant ? `colors-tenant-mobile-${tenantColorName}` : 'tenant-single';
  document.body.style.background = `var(--${tenantClassName}, var(--colors-mobileBg))`;

  if (!selectedEvent || !selectedListing) {
    return null;
  }

  return (
    <>
      <div className={mobileClasses.mobileAppWrap}>
        <NavBarMobile
          leftSide={
            <ActionIcon
              opacity={0.7}
              size={36}
              onClick={() => {
                triggerSaveRule(); // Not going to await this for UX navigation reasons. Will see if that's a problem
                navigate(-1);
              }}
              className={mobileClasses.chevronIconButton}
            >
              <ChevronBackIcon size={20} color="white" />
            </ActionIcon>
          }
          rightSide={
            <ActionIcon opacity={0.7} size={36} onClick={() => openMenuModal()}>
              <MoreHorizIcon size={24} color="white" />
            </ActionIcon>
          }
          titleArea={
            <>
              <Text fw={600} ta="center" c="white" lh={1} fz={14} truncate>
                {selectedListing.section} &middot; Row {selectedListing.row} &middot; Seats{' '}
                {formatSeatRange(selectedListing.seatFrom, selectedListing.seatThru, selectedListing.quantityRemaining)}
              </Text>
            </>
          }
          containerStyle={{ background: `var(--${tenantClassName}, var(--colors-mobileBg))` }}
          headerBg="transparent"
        />
        <Box mih="100%" bg="var(--colors-paper)" ref={scrollTrackerRef} className={classes.contentWrapper}>
          {/* Unsaved Changes Overlay */}
          {rule.isAutoPriced && unsavedChanges && (
            <UnstyledButton className={classes.unsavedChangesOverlay} onClick={() => navigate(-1)}>
              <Flex p="xs" pl="xl" align="center">
                <Center mt={-4} ml={4}>
                  <ChevronBackIcon size={20} color="var(--colors-paper)" />
                </Center>
                <Flex direction="column" px="xl">
                  <Text fz="sm" ta="center" lh={1} c="var(--colors-paper)" fw={600}>
                    Unsaved Changes
                  </Text>
                  <Text ta="center" c="var(--colors-gray-4)" fz={12}>
                    Tap to return to listing
                  </Text>
                </Flex>
              </Flex>
            </UnstyledButton>
          )}
          <Flex direction="column" bg="var(--colors-paper)" pos="absolute" top="calc(56px + var(--safe-area-inset-top, 0))">
            <Flex align="center" justify="space-between" p="md" maw="100vw">
              <Box w="calc(100% - 48px)" maw="calc(100% - 48px)" style={{ overflow: 'hidden', flexBasis: 'calc(100% - 48px)' }}>
                <Text size="sm" fw={600} lh={1.3} truncate>
                  {selectedEvent.name}
                </Text>
                <Text size="xs" c="var(--colors-gray-5)" lh={1.3} truncate>
                  {selectedEvent.venue.name} &middot; {selectedEvent.venue.city}, {selectedEvent.venue.state}
                </Text>
                <Text size="xs" c="var(--colors-gray-5)" lh={1.4}>
                  {formatDate(selectedEvent.localDateTime, DateFormats.Standard)}
                </Text>
              </Box>

              <MobileMarketplaceAvatar marketplaceId={selectedMarketplaceId} onClick={openMarketplaceModal} />
            </Flex>
            <Flex pos="relative" w="100vw">
              <Divider color="var(--colors-divider)" w="100%" pos="absolute" top="50%" />
              <SegmentedControl
                value={scValue}
                onChange={(value) => {
                  setScValue(value as 'seating-chart' | 'other-filters');
                }}
                radius={6}
                bg="var(--colors-darkPaper)"
                size="xs"
                data={[
                  { label: <Text w={120}>Seating Chart</Text>, value: 'seating-chart' },
                  { label: <Text w={120}>Rows, Splits, etc.</Text>, value: 'other-filters' },
                ]}
                style={{ margin: '0 auto', zIndex: 1 }}
              />
            </Flex>
            <Flex mt={-15} w="100vw" style={{ overflow: 'hidden' }}>
              <Flex style={{ transform: scValue === 'seating-chart' ? 'translateX(0)' : 'translateX(-100vw)', transition: '.3s ease-in-out' }}>
                <Box className={classes.seatingChartWrapper} mx="auto" mah="640px" bg="var(--colors-paper)" style={{ zIndex: 0, flexShrink: 0, transition: '.2s ease-in-out' }}>
                  {isLoading && <CustomLoadingOverlay />}
                  {!isLoading && selectedMarketplaceEvent.marketplaceEventId && !isSeatingChartError && <Venue />}
                  {!isLoading && selectedMarketplaceEvent.marketplaceEvent && (isSeatingChartError || forceSectionCheckList) && (
                    <Box className={classes.mobileSectionChecklistWrapper} py="sm" px={0}>
                      <SectionChecklist />
                    </Box>
                  )}
                  {!isLoading &&
                    selectedEvent &&
                    !selectedMarketplaceEvent.marketplaceEvent &&
                    (!selectedMarketplaceEvent.marketplaceEventId || (isSeatingChartError && sectionsWithListings.length === 0)) && <EmptyState />}
                </Box>
                <Box className={classes.otherFiltersWrapper} mt={15}>
                  <Flex className={classes.otherFilters} mah="625px" mih={348} p="md" direction="column" gap="sm">
                    <Box>
                      <Text fz="sm" c="var(--colors-gray-5)" mb={2}>
                        Rows
                      </Text>
                      <BNTextInput
                        value={rule?.filters?.rows ?? ''}
                        onChange={(event) => {
                          setRule({ ...rule, filters: { ...rule.filters, rows: event.currentTarget.value } });
                        }}
                        clearable
                        clearableOnClick={() => setRule({ ...rule, filters: { ...rule.filters, rows: '' } })}
                      />
                    </Box>
                    <Box pos="relative">
                      <Text fz="sm" c="var(--colors-gray-5)" mb={2}>
                        Splits
                      </Text>
                      <SplitsButtonGroupVariable
                        count={8}
                        size="sm"
                        splits={rule.filters.splits}
                        onChange={(event) => {
                          setRule({ ...rule, filters: { ...rule.filters, splits: event } });
                        }}
                        disabled={!!form.values.autoAdjustSplits}
                      />
                      <Box pos="absolute" top={-12} right={0}>
                        <BNSwitch
                          label="AUTO"
                          size="xxs"
                          className={classes.autoSwitch}
                          checked={!!form.values.autoAdjustSplits}
                          onChange={(event) => {
                            form.setFieldValue('autoAdjustSplits', event.currentTarget.checked);
                          }}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Text component="label" c="var(--colors-gray-5)" fz={14}>
                        Exclusions
                      </Text>
                      <UnstyledButton
                        onClick={() => openExclusionsModal()}
                        fz="sm"
                        c="var(--colors-paperReverse)"
                        fw={500}
                        variant="default"
                        w="100%"
                        h={36}
                        style={{ border: '1px solid var(--colors-gray-3)', borderRadius: 6 }}
                      >
                        <Flex w="100%" align="center" justify="space-between" pl="sm" pr={8}>
                          {(form.values.exclusions?.length ?? 0) === 0 && (form.values.deliveryExclusions?.length ?? 0) === 0 ? (
                            <Flex gap={4} style={{ overflow: 'hidden' }}>
                              <Text fz="sm">None</Text>
                            </Flex>
                          ) : (
                            <Flex gap={4} style={{ overflow: 'hidden' }}>
                              {form.values.exclusions?.some((x) => x === BarkerCoreEnumsListingDisclosure.Accessibility) && (
                                <Center className={classes.multiSelectEntry}>
                                  <AccessibleIcon />
                                </Center>
                              )}
                              {form.values.exclusions?.some((x) => x === BarkerCoreEnumsListingDisclosure.LimitedObstructedView) && (
                                <Center className={classes.multiSelectEntry}>
                                  <VisibilityOffIcon />
                                </Center>
                              )}
                              {form.values.exclusions?.some((x) => x === BarkerCoreEnumsListingDisclosure.StandingRoomOnly) && (
                                <Center className={classes.multiSelectEntry}>
                                  <AccessibilityNewIcon />
                                </Center>
                              )}
                              {form.values.deliveryExclusions?.some((x) => x === BarkerCoreEnumsDeliveryMethod.Electronic) && (
                                <Center className={classes.multiSelectEntry}>
                                  <QrCodeIcon />
                                </Center>
                              )}
                              {form.values.deliveryExclusions?.some((x) => x === BarkerCoreEnumsDeliveryMethod.Instant) && (
                                <Center className={classes.multiSelectEntry}>
                                  <FlashOnIcon />
                                </Center>
                              )}
                              {form.values.deliveryExclusions?.some((x) => x === BarkerCoreEnumsDeliveryMethod.Physical) && (
                                <Center className={classes.multiSelectEntry}>
                                  <LocalShippingIcon />
                                </Center>
                              )}
                              {form.values.deliveryExclusions?.some((x) => x === BarkerCoreEnumsDeliveryMethod.Unknown) && (
                                <Center className={classes.multiSelectEntry}>
                                  <ConfirmationNumberIcon />
                                </Center>
                              )}
                              {form.values.deliveryExclusions?.some((x) => x === BarkerCoreEnumsDeliveryMethod.Special) && (
                                <Center className={classes.multiSelectEntry}>
                                  <LocationOnIcon />
                                </Center>
                              )}
                            </Flex>
                          )}
                          <SelectArrowsIcon color="var(--colors-gray-6)" size={18} />
                        </Flex>
                      </UnstyledButton>
                    </Box>
                    <OutliersPopoverSelect />
                    <BNButton className={classes.btnReset} size="sm" variant="subtle" leftSection={<ResetIcon size={22} color="var(--colors-gray-5)" />} onClick={() => resetAll()}>
                      Reset All
                    </BNButton>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          </Flex>
          <ScrollOver scrollTrackerRef={scrollTrackerRef} />
        </Box>
      </div>
      {marketplaceModalOpened && (
        <InputModal closeModal={closeMarketplaceModal}>
          <Box p="xs">
            <Flex align="center" justify="space-between" p={4}>
              <Text fw={600} size="md">
                Marketplace
              </Text>
              <BNButton onClick={() => closeMarketplaceModal()} variant="subtle" c="var(--colors-green-7)" fz="md" size="compact-xs" px={0} py={0} h={28} w={48}>
                Done
              </BNButton>
            </Flex>
            <MarketplaceSelector onChange={() => {}} />
            <Space h="xl" w="100%" />
            {/*** 2/21/24 LWW - Commented out the links for now, they will be added in a future version ***/}
            {/*<Divider w="calc(100% + 20px)" color="var(--colors-divider)" mx={-10} />*/}
            {/*<Divider w="calc(100% + 20px)" size={8} color="var(--colors-gray-0)" mx={-10} />*/}
            {/*<Flex py="sm" px={4}>*/}
            {/*  <Text c="var(--colors-gray-5)" fz="xs">*/}
            {/*    LINKS*/}
            {/*  </Text>*/}
            {/*</Flex>*/}
          </Box>
        </InputModal>
      )}
      {menuModalOpened && (
        <MoreMenuModal
          modalOpened
          closeModal={closeMenuModal}
          changeMarketplaceClick={() => {
            closeMenuModal();
            openMarketplaceModal();
          }}
        />
      )}
      {exclusionsModalOpened && <ExclusionsModal modalOpened closeModal={closeExclusionsModal} />}
    </>
  );
}

type ModalBaseProps = {
  modalOpened: boolean;
  closeModal: () => void;
};

type MarketplaceActionsMenuProps = {
  changeMarketplaceClick: () => void;
} & ModalBaseProps;

function MoreMenuModal({ modalOpened, closeModal, changeMarketplaceClick }: MarketplaceActionsMenuProps) {
  return (
    <Modal
      opened={modalOpened}
      onClose={() => closeModal()}
      closeButtonProps={{ size: 'md' }}
      withCloseButton
      className={classes.bottomSheetMenuModal}
      title="Marketplace Actions"
      transitionProps={{ transition: 'slide-up' }}
    >
      <Flex p={0} h="100%" justify="space-between" pb="xl">
        <Flex className={classes.mobileMenuItemsWrapper} direction="column" w="100%">
          <Menu>
            <Menu.Item
              onClick={changeMarketplaceClick}
              leftSection={<SyncAltIcon size={21} />}
              rightSection={<ChevronForwardIcon size={14} color="var(--colors-gray-5)" />}
              fw={400}
            >
              Change Marketplace
            </Menu.Item>
            <Menu.Item onClick={() => {}} leftSection={<LiveHelpIcon size={21} />} rightSection={<ChevronForwardIcon size={14} color="var(--colors-gray-5)" />} fw={400}>
              Request Support
            </Menu.Item>
          </Menu>
        </Flex>
      </Flex>
    </Modal>
  );
}

type multiSelectItemProps = {
  title: string;
  icon?: ReactNode;
  selected: boolean;
  onSelect: () => void;
  onDeselect: () => void;
};

function MultiSelectItem({ title, icon, selected, onSelect, onDeselect }: multiSelectItemProps) {
  return (
    <Checkbox
      checked={selected}
      className={selected ? classes.checked : classes.unchecked}
      onChange={(event) => {
        event.currentTarget.checked ? onSelect() : onDeselect();
      }}
      //className={itemChecked ? classes.checked : classes.unchecked}
      //onChange={() => setItemChecked(!itemChecked)}
      label={
        <Flex>
          {icon} {title}
        </Flex>
      }
    />
  );
}

function ExclusionsModal({ modalOpened, closeModal }: ModalBaseProps) {
  const { form } = useSeatingChart('form');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialExclusions = useMemo(() => form.values.exclusions || [], []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialDeliveryExclusions = useMemo(() => form.values.deliveryExclusions || [], []);
  const groups = R.groupBy(ExclusionsList, (x) => x.group);

  return (
    <Modal
      opened={modalOpened}
      onClose={() => closeModal()}
      closeButtonProps={{ size: 'md' }}
      withCloseButton={false}
      className={classes.bottomSheetModal}
      title="Market Filters"
      transitionProps={{ transition: 'slide-up' }}
    >
      <>
        <Flex justify="space-between" align="center" p={16} style={{ borderBottom: '1px dotted var(--colors-divider)', zIndex: 1 }}>
          <BNButton
            size="compact-sm"
            px={0}
            py={0}
            h={28}
            w={64}
            fz="md"
            fw={500}
            variant="subtle"
            onClick={() => {
              form.setFieldValue('exclusions', initialExclusions);
              form.setFieldValue('deliveryExclusions', initialDeliveryExclusions);
              closeModal();
            }}
          >
            Reset
          </BNButton>
          <Text fw={600} fz="md">
            Exclusions
          </Text>
          <BNButton
            variant="subtle"
            c="var(--colors-green-7)"
            fz="md"
            size="compact-sm"
            px={0}
            py={0}
            h={28}
            w={64}
            onClick={() => {
              closeModal();
            }}
          >
            Done
          </BNButton>
        </Flex>
        <Box py="md">
          {Object.keys(groups).map((group, i) => (
            <div key={group}>
              <Text fz={10} lts={0.25} c="var(--colors-gray-5)" mx="md" pb="xs">
                {group.toUpperCase()}
              </Text>
              <Flex direction="column" align="start" px="md" className={classes.multiSelectList}>
                {groups[group].map((item) => (
                  <MultiSelectItem
                    key={item.value}
                    title={item.label}
                    icon={item.icon}
                    selected={(form.values.exclusions?.some((x) => x === item.value) || form.values.deliveryExclusions?.some((x) => x === item.value)) ?? false}
                    onSelect={() => {
                      if (item.value in BarkerCoreEnumsListingDisclosure) {
                        const value = item.value as BarkerCoreEnumsListingDisclosure;
                        form.setFieldValue('exclusions', [...(form.values.exclusions || []), value]);
                      } else if (item.value in BarkerCoreEnumsDeliveryMethod) {
                        const value = item.value as BarkerCoreEnumsDeliveryMethod;
                        form.setFieldValue('deliveryExclusions', [...(form.values.deliveryExclusions || []), value]);
                      }
                    }}
                    onDeselect={() => {
                      if (item.value in BarkerCoreEnumsListingDisclosure) {
                        const value = item.value as BarkerCoreEnumsListingDisclosure;
                        form.setFieldValue(
                          'exclusions',
                          (form.values.exclusions || []).filter((x) => x !== value),
                        );
                      } else if (item.value in BarkerCoreEnumsDeliveryMethod) {
                        const value = item.value as BarkerCoreEnumsDeliveryMethod;
                        form.setFieldValue(
                          'deliveryExclusions',
                          (form.values.deliveryExclusions || []).filter((x) => x !== value),
                        );
                      }
                    }}
                  />
                ))}
              </Flex>
              {i < Object.keys(groups).length - 1 ? <Divider color="var(--colors-divider)" my="md" /> : null}
            </div>
          ))}
        </Box>
      </>
    </Modal>
  );
}

type ScrollOverProps = {
  scrollTrackerRef: RefObject<HTMLDivElement>;
};

function ScrollOver({ scrollTrackerRef }: ScrollOverProps) {
  const [resultsScrollOverlay, setResultsScrollOverlay] = useState(true);
  const scrollOverWrapperRef = useRef<HTMLDivElement>(null);

  // Scroll Tracking
  useEffect(() => {
    if (scrollTrackerRef.current) {
      const scrollTrackerRefCurrent = scrollTrackerRef.current;
      scrollTrackerRefCurrent.addEventListener('scroll' as any, handleScroll);
      scrollTrackerRefCurrent.addEventListener('touchstart' as any, handleTouchStart);
      scrollTrackerRefCurrent.addEventListener('touchend' as any, handleTouchEnd);
      return () => {
        scrollTrackerRefCurrent.removeEventListener('scroll' as any, handleScroll);
        scrollTrackerRefCurrent.removeEventListener('touchstart' as any, handleTouchStart);
        scrollTrackerRefCurrent.removeEventListener('touchend' as any, handleTouchEnd);
      };
    }
    return undefined;
  });

  const innerScrollElement = document.querySelector('#results-wrapper .mantine-Paper-root > div:last-child > .mantine-Stack-root > div > div');
  let touchStart = 0;
  const scrollHeight = window.innerWidth - 40;

  // Show/hide invisible overlay based on scroll position
  function handleScroll() {
    if (scrollTrackerRef.current && scrollTrackerRef.current?.scrollTop >= scrollHeight - 2) {
      setResultsScrollOverlay(false);
    } else {
      setResultsScrollOverlay(true);
    }
    // Reset results scroll position when dragging the header bar so the touchend function is more predicttable
    if (innerScrollElement) {
      innerScrollElement.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }
  }

  // Auto-position with touch events
  function handleTouchStart(e: React.TouchEvent<HTMLDivElement>) {
    touchStart = e.changedTouches[0].clientY;
  }

  function handleTouchEnd(e: React.TouchEvent<HTMLDivElement>) {
    if (resultsScrollOverlay && scrollOverWrapperRef.current && touchStart > scrollOverWrapperRef.current?.offsetTop && e.changedTouches[0].clientY < touchStart) {
      if (scrollTrackerRef.current && scrollTrackerRef.current?.scrollTop >= 0) {
        scrollTrackerRef.current?.scrollTo({ top: 1000, left: 0, behavior: 'smooth' });
        setResultsScrollOverlay(false);
      } else {
        scrollTrackerRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setResultsScrollOverlay(true);
      }
    } else if (e.changedTouches[0].clientY > touchStart && scrollTrackerRef && innerScrollElement?.scrollTop === 0) {
      if (scrollTrackerRef.current && scrollTrackerRef.current.scrollTop <= scrollHeight) {
        if (scrollTrackerRef) {
          scrollTrackerRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          setResultsScrollOverlay(true);
        }
      } else {
        scrollTrackerRef.current?.scrollTo({ top: 1000, left: 0, behavior: 'smooth' });
        setResultsScrollOverlay(false);
      }
    }
  }

  return (
    <Box ref={scrollOverWrapperRef} className={classes.filterMarketplaceContentWrapper}>
      <Flex h="100%" w="100%" className={classes.resultsWrapper} id="results-wrapper">
        <MarketListingProvider>
          <MarketListings />
        </MarketListingProvider>
      </Flex>
    </Box>
  );
}

export function MobileMarketplaceAvatar({ marketplaceId, width, onClick }: { marketplaceId: BarkerCoreEnumsMarketplace; width?: number; onClick?: () => void }) {
  if (marketplaceId === BarkerCoreEnumsMarketplace.SeatGeek) {
    return (
      <Avatar key="seatgeek" m={0} radius="xl" h={width ?? 44} w={width ?? 44} variant="outline" className={classes.avatar} color="var(--colors-darkPaper)" onClick={onClick}>
        <SeatGeekLogo />
      </Avatar>
    );
  }

  return (
    <Avatar key="other" m={0} radius="xl" h={width ?? 44} w={width ?? 44} variant="outline" className={classes.avatar} color="var(--colors-darkPaper)" onClick={onClick}>
      <img
        className="mantine-Avatar-image"
        height={44}
        width={44}
        src={marketplaceId === BarkerCoreEnumsMarketplace.TicketEvolution ? tevo : marketplaceId === BarkerCoreEnumsMarketplace.TicketNetwork ? tnet : tm}
        alt="Selected Marketplace"
      />
    </Avatar>
  );
}
