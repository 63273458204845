import { getGetApiTenantsQueryKey, useGetApiPrincipalsInvites, usePutApiTenantsTenantIdInvitesInviteIdAccept, usePutApiTenantsTenantIdInvitesInviteIdDecline } from '../../api';
import { useAtomValue } from 'jotai';
import { Box, Card, Group, Modal, Stack, Text } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import dayjs from 'dayjs';
import { DateFormats } from '../../utils/globals';
import { useQueryClient } from '@tanstack/react-query';
import { auth } from '../../data/atoms.auth';
import classes from './InviteModal.module.css';

export function InviteModal() {
  const apiToken = useAtomValue(auth.apiTokenAtom);
  const queryClient = useQueryClient();

  const {
    data: invites,
    refetch: refetchInvites,
    isLoading,
  } = useGetApiPrincipalsInvites({
    query: {
      enabled: !!apiToken,
      refetchOnWindowFocus: true,
      select(data) {
        return data.data;
      },
    },
  });

  // Test data
  // const invites = [
  //   {
  //     tenantId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  //     inviteId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  //     tenant: {
  //       tenantId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  //       name: 'Tenant Name',
  //       contactEmail: 'string',
  //       billingEmail: 'string',
  //       createdAt: '2023-03-13T15:50:41.661Z',
  //       createdBy: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  //       updatedAt: '2023-03-13T15:50:41.661Z',
  //       updatedBy: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  //       isSystem: true,
  //     },
  //     email: 'string',
  //     roleId: 'None',
  //     role: {
  //       userRoleId: 'None',
  //       name: 'string',
  //     },
  //     createdBy: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  //     createdAt: '2023-03-13T15:50:41.661Z',
  //     expiresAt: '2023-03-13T15:50:41.661Z',
  //     acceptedAt: '2023-03-13T15:50:41.661Z',
  //     declinedAt: '2023-03-13T15:50:41.661Z',
  //   },
  //   {
  //     tenantId: '3fa85f64-5717-4562-b3fc-2c963f66afa7',
  //     inviteId: '3fa85f64-5717-4562-b3fc-2c963f66afa7',
  //     tenant: {
  //       tenantId: '3fa85f64-5717-4562-b3fc-2c963f66afa7',
  //       name: 'Tenant Name Two',
  //       contactEmail: 'string',
  //       billingEmail: 'string',
  //       createdAt: '2023-03-13T15:50:41.661Z',
  //       createdBy: '3fa85f64-5717-4562-b3fc-2c963f66afa7',
  //       updatedAt: '2023-03-13T15:50:41.661Z',
  //       updatedBy: '3fa85f64-5717-4562-b3fc-2c963f66afa7',
  //       isSystem: true,
  //     },
  //     email: 'string',
  //     roleId: 'None',
  //     role: {
  //       userRoleId: 'None',
  //       name: 'string',
  //     },
  //     createdBy: '3fa85f64-5717-4562-b3fc-2c963f66afa7',
  //     createdAt: '2023-03-13T15:50:41.661Z',
  //     expiresAt: '2023-03-13T15:50:41.661Z',
  //     acceptedAt: '2023-03-13T15:50:41.661Z',
  //     declinedAt: '2023-03-13T15:50:41.661Z',
  //   },
  //   {
  //     tenantId: '3fa85f64-5717-4562-b3fc-2c963f66afa8',
  //     inviteId: '3fa85f64-5717-4562-b3fc-2c963f66afa8',
  //     tenant: {
  //       tenantId: '3fa85f64-5717-4562-b3fc-2c963f66afa8',
  //       name: 'Tenant Name Three',
  //       contactEmail: 'string',
  //       billingEmail: 'string',
  //       createdAt: '2023-03-13T15:50:41.661Z',
  //       createdBy: '3fa85f64-5717-4562-b3fc-2c963f66afa8',
  //       updatedAt: '2023-03-13T15:50:41.661Z',
  //       updatedBy: '3fa85f64-5717-4562-b3fc-2c963f66afa8',
  //       isSystem: true,
  //     },
  //     email: 'string',
  //     roleId: 'None',
  //     role: {
  //       userRoleId: 'None',
  //       name: 'string',
  //     },
  //     createdBy: '3fa85f64-5717-4562-b3fc-2c963f66afa8',
  //     createdAt: '2023-03-13T15:50:41.661Z',
  //     expiresAt: '2023-03-13T15:50:41.661Z',
  //     acceptedAt: '2023-03-13T15:50:41.661Z',
  //     declinedAt: '2023-03-13T15:50:41.661Z',
  //   },
  //   {
  //     tenantId: '3fa85f64-5717-4562-b3fc-2c963f66afa9',
  //     inviteId: '3fa85f64-5717-4562-b3fc-2c963f66afa9',
  //     tenant: {
  //       tenantId: '3fa85f64-5717-4562-b3fc-2c963f66afa9',
  //       name: 'Tenant Name Two',
  //       contactEmail: 'string',
  //       billingEmail: 'string',
  //       createdAt: '2023-03-13T15:50:41.661Z',
  //       createdBy: '3fa85f64-5717-4562-b3fc-2c963f66afa9',
  //       updatedAt: '2023-03-13T15:50:41.661Z',
  //       updatedBy: '3fa85f64-5717-4562-b3fc-2c963f66afa9',
  //       isSystem: true,
  //     },
  //     email: 'string',
  //     roleId: 'None',
  //     role: {
  //       userRoleId: 'None',
  //       name: 'string',
  //     },
  //     createdBy: '3fa85f64-5717-4562-b3fc-2c963f66afa1',
  //     createdAt: '2023-03-13T15:50:41.661Z',
  //     expiresAt: '2023-03-13T15:50:41.661Z',
  //     acceptedAt: '2023-03-13T15:50:41.661Z',
  //     declinedAt: '2023-03-13T15:50:41.661Z',
  //   },
  //   {
  //     tenantId: '3fa85f64-5717-4562-b3fc-2c963f66afa1',
  //     inviteId: '3fa85f64-5717-4562-b3fc-2c963f66afa1',
  //     tenant: {
  //       tenantId: '3fa85f64-5717-4562-b3fc-2c963f66afa1',
  //       name: 'Tenant Name Three',
  //       contactEmail: 'string',
  //       billingEmail: 'string',
  //       createdAt: '2023-03-13T15:50:41.661Z',
  //       createdBy: '3fa85f64-5717-4562-b3fc-2c963f66afa1',
  //       updatedAt: '2023-03-13T15:50:41.661Z',
  //       updatedBy: '3fa85f64-5717-4562-b3fc-2c963f66afa1',
  //       isSystem: true,
  //     },
  //     email: 'string',
  //     roleId: 'None',
  //     role: {
  //       userRoleId: 'None',
  //       name: 'string',
  //     },
  //     createdBy: '3fa85f64-5717-4562-b3fc-2c963f66afa1',
  //     createdAt: '2023-03-13T15:50:41.661Z',
  //     expiresAt: '2023-03-13T15:50:41.661Z',
  //     acceptedAt: '2023-03-13T15:50:41.661Z',
  //     declinedAt: '2023-03-13T15:50:41.661Z',
  //   },
  // ];
  const { mutateAsync: acceptInvite, isLoading: isAcceptingInvite } = usePutApiTenantsTenantIdInvitesInviteIdAccept();
  const { mutateAsync: declineInvite, isLoading: isDecliningInvite } = usePutApiTenantsTenantIdInvitesInviteIdDecline();

  return (
    <Modal
      opened={invites !== undefined && invites.length > 0}
      size="md"
      padding={0}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      onClose={() => false}
      title={invites !== undefined && invites.length > 0 && invites.length === 1 ? 'You Have a Company Invite' : `You Have ${invites?.length} Company Invites`}
      centered
      classNames={{ body: classes.modalBody }}
    >
      <Box mah={320} style={{ overflow: 'auto' }}>
        <Stack gap={0} py={0} px={0} className={classes.cardStack}>
          {invites?.map((invite) => (
            <Card w="100%" px="md" key={invite.inviteId} className={invites.length > 1 ? classes.inviteCardBgHover : classes.inviteCard}>
              <Card.Section p="md">
                <Group wrap="nowrap" justify="space-between" gap="lg">
                  <Box>
                    <Text size="sm" fw={600}>
                      {invite.tenant.name}
                    </Text>
                    <Text size="xs" c="var(--colors-gray-5)">
                      Invited on {dayjs(invite.createdAt).format(DateFormats.MonthDayYear)}
                    </Text>
                    {/* Might be nice to add more information here in the future, like what user sent the invite or when the invite expires? */}
                  </Box>
                  <Group justify="right" gap="sm" wrap="nowrap">
                    <BNButton
                      variant="default"
                      size="xs"
                      onClick={() => declineInvite({ tenantId: invite.tenantId, inviteId: invite.inviteId }).then(() => refetchInvites())}
                      loading={isAcceptingInvite || isDecliningInvite || isLoading}
                      className={classes.declineButton}
                    >
                      Decline
                    </BNButton>
                    <BNButton
                      variant="filled"
                      size="xs"
                      color="green"
                      onClick={() =>
                        acceptInvite({ tenantId: invite.tenantId, inviteId: invite.inviteId })
                          .then(() => refetchInvites())
                          .then(() => queryClient.invalidateQueries(getGetApiTenantsQueryKey()))
                      }
                      loading={isAcceptingInvite || isDecliningInvite || isLoading}
                    >
                      Accept
                    </BNButton>
                  </Group>
                </Group>
              </Card.Section>
            </Card>
          ))}
        </Stack>
      </Box>
    </Modal>
  );
}
