import { IconPropsBase } from './IconPropsBase';

export default function SportsFootballIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M480-480ZM375.847-203.539 203.539-375.847q-4.539 38.769-3.424 85.731Q201.231-243.154 208-210q25.308 7.769 77.77 9.577 52.462 1.808 90.077-3.116Zm72.152-12.153q59.385-11.462 110.616-36.808T646-314q34.769-34.769 60.116-85.308 25.346-50.539 38.192-110.693L510.001-744.308q-57 12.077-107.424 38.115-50.423 26.039-85.423 61.039t-61.039 85.731q-26.038 50.731-40.423 111.424l232.307 232.307ZM396-353.847 353.847-396 564-606.153 606.153-564 396-353.847Zm360.461-228.306q5.154-41.308 4.039-88.693-1.115-47.385-8.5-79.154-25.308-8.385-77.77-10.192-52.462-1.808-90.077 3.731l172.308 174.308ZM313.462-140.001q-52.769 0-97.461-7.347-44.692-7.346-53.769-16.422-8.692-9.693-15.461-53.461-6.77-43.769-6.77-94.846 0-115.538 34.654-213.769 34.654-98.23 97.961-161.538 63.308-63.307 162.654-97.961 99.346-34.654 215.884-34.654 52.23 0 93.73 6.962 41.5 6.961 50.961 16.038 10.231 10.077 17.192 53.269 6.962 43.192 6.962 96.268 0 115.846-34.462 214.269-34.461 98.423-97.384 161.346-62.308 62.307-161.269 97.076-98.961 34.77-213.422 34.77Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M480-480ZM369.847-218.539 218.539-369.847q-3.539 37.769-1.924 80.731Q218.231-246.154 224-225q22.308 5.769 65.27 8.077 42.962 2.308 80.577-1.616Zm65.152-8.153q53.385-8.462 110.116-35.808T636-324q33.769-33.769 61.116-90.308 27.346-56.539 36.192-110.693L525.001-733.308q-52 7.077-109.424 34.615-57.423 27.539-90.423 61.539-33 33-60.539 89.731-27.538 56.731-37.923 112.424l208.307 208.307ZM409-372.847 371.847-409 551-588.153 587.153-551 409-372.847Zm332.461-217.306q3.154-37.308 1.039-80.193T735-735q-24.308-5.385-67.27-7.692-42.962-2.308-77.577 1.231l151.308 151.308ZM324.462-164.001q-50.769 0-91.461-6.347-40.692-6.346-49.769-15.422-9.692-9.693-14.461-48.961-4.77-39.269-4.77-85.346 0-107.538 31.654-199.269 31.654-91.73 91.961-152.038 59.308-59.307 151.654-91.961 92.346-32.654 200.884-32.654 51.23 0 87.73 5.962 36.5 5.961 45.961 15.038 11.231 11.077 16.692 50.269 5.462 39.192 5.462 83.268 0 109.846-31.962 201.769-31.961 91.923-91.884 151.846-59.308 59.307-150.769 91.576-91.461 32.27-196.922 32.27Z"
        />
      </svg>
    );
  }
}
