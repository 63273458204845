import React, { FunctionComponent } from 'react';
import Keyboard from 'react-simple-keyboard';
import { KeyboardOptions } from 'react-simple-keyboard/build/interfaces';
import 'react-simple-keyboard/build/css/index.css';
import './keyboard.css';

type KeyboardWrapperProps = {
  keyboardRef: React.MutableRefObject<any>;
} & KeyboardOptions;

const KeyboardWrapper: FunctionComponent<KeyboardWrapperProps> = ({ keyboardRef, ...props }) => {
  return <Keyboard keyboardRef={(r) => (keyboardRef.current = r)} {...props} />;
};

export default KeyboardWrapper;
