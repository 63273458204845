import { IconPropsBase } from './IconPropsBase';

export default function ViewWeekIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M172.31-240h161.15v-480H172.31q-5.39 0-8.85 3.46t-3.46 8.85v455.38q0 5.39 3.46 8.85t8.85 3.46Zm221.15 0h173.08v-480H393.46v480Zm233.08 0h161.15q5.39 0 8.85-3.46t3.46-8.85v-455.38q0-5.39-3.46-8.85t-8.85-3.46H626.54v480Zm-454.23 60Q142-180 121-201q-21-21-21-51.31v-455.38Q100-738 121-759q21-21 51.31-21h615.38Q818-780 839-759q21 21 21 51.31v455.38Q860-222 839-201q-21 21-51.31 21H172.31Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M180.31-264h161.15v-432H180.31q-5.39 0-8.85 3.46t-3.46 8.85v407.38q0 5.39 3.46 8.85t8.85 3.46Zm213.15 0h173.08v-432H393.46v432Zm225.08 0h161.15q5.39 0 8.85-3.46t3.46-8.85v-407.38q0-5.39-3.46-8.85t-8.85-3.46H618.54v432Zm-438.23 52Q154-212 135-231q-19-19-19-45.31v-407.38Q116-710 135-729q19-19 45.31-19h599.38q27.01 0 45.66 19Q844-710 844-683.69v407.38Q844-250 825.35-231q-18.65 19-45.66 19H180.31Z"
        />
      </svg>
    );
  }
}
