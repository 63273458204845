import { IconPropsBase } from './IconPropsBase';

export default function BarcodeIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M73.85-213.85v-516.92h73.84v516.92H73.85Zm110.77 0v-516.92h73.84v516.92h-73.84Zm110.77 0v-516.92h36.92v516.92h-36.92Zm110.76 0v-516.92H480v516.92h-73.85Zm110.77 0v-516.92h110.77v516.92H516.92Zm147.69 0v-516.92h36.93v516.92h-36.93Zm110.77 0v-516.92h110.77v516.92H775.38Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M81.85-205.85v-532.92h65.84v532.92H81.85Zm110.77 0v-532.92h65.84v532.92h-65.84Zm110.77 0v-532.92h44.92v532.92h-44.92Zm110.76 0v-532.92H480v532.92h-65.85Zm110.77 0v-532.92h86.77v532.92h-86.77Zm131.69 0v-532.92h44.93v532.92h-44.93Zm134.77 0v-532.92h86.77v532.92h-86.77Z"
        />
      </svg>
    );
  }
}
