import { Center, MantineColor, Text } from '@mantine/core';
import { ReactNode } from 'react';
import classes from './EmptyState.module.css';
import { BNButton } from '../Button/Button';

type EmptyStateProps = {
  icon?: ReactNode;
  iconBg?: string;
  title: string;
  description?: string;
  buttonText?: string;
  buttonVariant?: string;
  buttonOnClick?: () => void;
  buttonColor?: MantineColor;
  h?: number | string;
  w?: number | string;
  border?: boolean;
  titleFz?: number;
  bottomContent?: ReactNode;
};

export function BNEmptyState({
  icon,
  iconBg = 'var(--colors-darkPaper)',
  title,
  description,
  buttonText,
  buttonVariant = 'default',
  buttonOnClick,
  buttonColor,
  h,
  w,
  border = true,
  titleFz,
  bottomContent,
}: EmptyStateProps) {
  return (
    <Center h={h || '100%'} w={w || '100%'} p="xl" className={border ? `${classes.wrapper} ${classes.wrapperBorder}` : `${classes.wrapper}`}>
      {icon && (
        <Center bg={iconBg} c="var(--colors-iconFill)" mb="md" h={44} w={44} className={classes.icon}>
          {icon}
        </Center>
      )}
      <Text c="var(--colors-paperReverse)" fw={600} ta="center" lh={1.3} mt={2} mb={2} fz={titleFz ? titleFz : 'md'}>
        {title}
      </Text>
      <Text size="xs" c="var(--colors-gray-5)" ta="center">
        {description}
      </Text>
      {buttonText && (
        <BNButton size="xs" variant={buttonVariant} color={buttonColor} onClick={buttonOnClick} mt="md">
          {buttonText}
        </BNButton>
      )}
      {bottomContent}
    </Center>
  );
}
