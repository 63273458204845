export type BarkerCoreEnumsPaymentStatus = (typeof BarkerCoreEnumsPaymentStatus)[keyof typeof BarkerCoreEnumsPaymentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BarkerCoreEnumsPaymentStatus = {
  Unknown: 'Unknown',
  Unpaid: 'Unpaid',
  Paid: 'Paid',
  Partial: 'Partial',
  Void: 'Void',
} as const;
