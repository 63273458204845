import { IconPropsBase } from './IconPropsBase';

export default function ScatterPlotIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M667.384-120.617q-51.749 0-87.874-36.125-36.124-36.124-36.124-87.874 0-51.749 36.124-87.874 36.125-36.124 87.874-36.124 51.75 0 87.874 36.124 36.125 36.125 36.125 87.874 0 51.75-36.125 87.874-36.124 36.125-87.874 36.125Zm.213-51.999q29.787 0 50.787-21.212t21-51q0-29.788-21.212-50.788-21.213-21-51-21-29.788 0-50.788 21.213-21 21.212-21 51 0 29.787 21.213 50.787 21.212 21 51 21Zm-374.981-68.001q-51.75 0-87.874-36.125-36.125-36.124-36.125-87.874 0-51.749 36.125-87.874 36.124-36.124 87.874-36.124 51.749 0 87.874 36.124 36.124 36.125 36.124 87.874 0 51.75-36.124 87.874-36.125 36.125-87.874 36.125Zm.212-51.999q29.788 0 50.788-21.212 21-21.212 21-51t-21.213-50.788q-21.212-21-51-21-29.787 0-50.787 21.213-21 21.212-21 51 0 29.787 21.212 50.787t51 21ZM432-540.309q-51.749 0-87.874-36.124-36.125-36.125-36.125-87.874 0-51.75 36.125-87.874 36.125-36.125 87.874-36.125 51.749 0 87.874 36.125 36.125 36.124 36.125 87.874 0 51.749-36.125 87.874-36.125 36.124-87.874 36.124Zm.212-51.998q29.788 0 50.788-21.213 21-21.212 21-51 0-29.787-21.212-50.787-21.213-21-51-21-29.788 0-50.788 21.212-21 21.213-21 51 0 29.788 21.212 50.788 21.213 21 51 21Zm235.172 347.691Zm-374.768-120ZM432-664.307Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M675.384-144.617q-57.749 0-98.874-41.125-41.124-41.124-41.124-98.874 0-57.749 41.124-98.874 41.125-41.124 98.874-41.124 57.75 0 98.874 41.124 41.125 41.125 41.125 98.874 0 57.75-41.125 98.874-41.124 41.125-98.874 41.125Zm0-59.999q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm-390.768-60.001q-57.75 0-98.874-41.125-41.125-41.124-41.125-98.874 0-57.749 41.125-98.874 41.124-41.124 98.874-41.124 57.749 0 98.874 41.124 41.124 41.125 41.124 98.874 0 57.75-41.124 98.874-41.125 41.125-98.874 41.125Zm0-59.999q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5ZM440-572.309q-57.749 0-98.874-41.124-41.125-41.125-41.125-98.874 0-57.75 41.125-98.874 41.125-41.125 98.874-41.125 57.749 0 98.874 41.125 41.125 41.124 41.125 98.874 0 57.749-41.125 98.874-41.125 41.124-98.874 41.124Zm0-59.998q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm235.384 347.691Zm-390.768-120ZM440-712.307Z"
        />
      </svg>
    );
  }
}
