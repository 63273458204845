import { IconPropsBase } from './IconPropsBase';

export default function DateSinceSaleIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path fill={color} d="m5.31,21.5c-.51,0-.93-.18-1.28-.53-.35-.35-.53-.78-.53-1.28V6.31c0-.51.18-.93.53-1.28.35-.35.78-.53,1.28-.53h1.38v-2.12h1.54v2.12h7.58v-2.12h1.5v2.12h1.38c.51,0,.93.18,1.28.53s.53.78.53,1.28v5.85h-1.5v-1.85H5v9.38c0,.08.03.15.1.21.06.06.13.1.21.1h6.84v1.5s-6.84,0-6.84,0Zm-.31-12.69h14v-2.5c0-.08-.03-.15-.1-.21-.06-.06-.13-.1-.21-.1H5.31c-.08,0-.15.03-.21.1-.06.06-.1.13-.1.21,0,0,0,2.5,0,2.5Zm0,0v-2.81,2.81Zm12,13.68h1.5v-1h1.12c.25,0,.46-.08.63-.25.17-.17.25-.38.25-.63v-2.73c0-.25-.08-.46-.25-.63-.17-.17-.38-.25-.63-.25h-3.12v-1.5h4v-1.5h-2v-1h-1.5v1h-1.12c-.25,0-.46.08-.63.25-.17.17-.25.38-.25.63v2.73c0,.25.08.46.25.63.17.17.38.25.63.25h3.12v1.5h-4v1.5h2s0,1,0,1Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="m4.76,17.58c-.37,0-.68-.13-.94-.4s-.4-.58-.4-.94V5.76c0-.38.13-.69.4-.95s.58-.39.94-.39h1.82v-2.1h1.12v2.1h4.65v-2.1h1.08v2.1h1.82c.37,0,.68.13.94.39s.4.58.4.95v4.87h-1.08v-1.37H4.5v6.99c0,.06.03.12.08.18.05.05.11.08.18.08h5.86v1.08s-5.86,0-5.86,0Zm-.26-9.41h11v-2.42c0-.06-.03-.12-.08-.18-.05-.05-.11-.08-.18-.08H4.76c-.06,0-.12.03-.18.08-.05.05-.08.11-.08.18,0,0,0,2.42,0,2.42Zm0,0v-2.67,2.67Zm9.25,10.41h1.08v-1h.85c.26,0,.47-.09.64-.26.17-.17.26-.39.26-.64v-1.53c0-.26-.09-.47-.26-.64-.17-.17-.39-.26-.64-.26h-2.6v-1.17h3.5v-1.08h-1.75v-1h-1.08v1h-.85c-.26,0-.47.09-.64.26-.17.17-.26.39-.26.64v1.53c0,.26.09.47.26.64.17.17.39.26.64.26h2.6v1.17h-3.5v1.08h1.75s0,1,0,1Z"
        />
      </svg>
    );
  }
}
