import millify from 'millify';
import { useMemo } from 'react';
import ConfirmationNumberIcon from '../icons/ConfirmationNumber';
import { BNLabel } from './Label';
import pluralize from 'pluralize';
import classes from './OpenTicketsLabel.module.css';

export const OpenticketsLabel = ({ tickets, className, skeleton, hideOpen }: { hideOpen?: boolean; className?: string; tickets: number; skeleton?: boolean }) => {
  const text = useMemo(() => (tickets >= 1000 ? `${millify(tickets || 0, { lowercase: true })}` : tickets), [tickets]);
  return (
    <BNLabel
      className={className || classes.label}
      leftIcon={<ConfirmationNumberIcon size={20} />}
      text={text}
      skeleton={skeleton}
      tooltip={`${tickets?.toLocaleString()}${!hideOpen ? ' open ' : ' '}${pluralize('ticket', tickets)}`}
    />
  );
};
