import { MarketListingsList } from '../MarketListings/MarketListings.List';
import { MarketplaceResultsSalesList } from './MarketplaceResults.SalesList';
import { MarketplaceResultsHeader } from './MarketplaceResultsHeader';
import { useDTIMarketplace } from '../DTI/DTI.Marketplace.hooks';

export function MarketplaceResultsContainer() {
  const { resultsSelection, setResultsSelection } = useDTIMarketplace('resultsSelection', 'setResultsSelection');

  return (
    <>
      <MarketplaceResultsHeader setResultsSelection={setResultsSelection} resultsSelection={resultsSelection} />
      {resultsSelection === 'Listings' && <MarketListingsList />}
      {resultsSelection === 'Sales' && <MarketplaceResultsSalesList />}
    </>
  );
}
