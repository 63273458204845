import { IconPropsBase } from './IconPropsBase';

export default function FoggyIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M720-207.693q-13.731 0-23.019-9.288-9.288-9.288-9.288-23.019 0-13.731 9.288-23.019 9.288-9.288 23.019-9.288 13.731 0 23.019 9.288 9.288 9.288 9.288 23.019 0 13.731-9.288 23.019-9.288 9.288-23.019 9.288Zm-440 120q-13.731 0-23.019-9.288-9.288-9.288-9.288-23.019 0-13.731 9.288-23.019 9.288-9.288 23.019-9.288 13.731 0 23.019 9.288 9.288 9.288 9.288 23.019 0 13.731-9.288 23.019-9.288 9.288-23.019 9.288ZM240-210q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h360q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H240Zm160 120q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h280q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371Q692.75-90 680-90H400ZM300-330q-87.154 0-148.576-61.423Q90.001-452.846 90.001-540q0-80.307 53.653-139.615 53.654-59.307 133.5-69.154 30.881-56.64 84.441-88.935 53.559-32.295 118.257-32.295 88.225 0 152.417 57.307 64.192 57.308 76.269 142.923 71.308 5.231 116.384 54.116 45.077 48.884 45.077 115.769 0 70.653-49.583 120.268Q770.833-330.001 700-330.001H300Zm0-59.998h400q45.846 0 77.924-32.077 32.077-32.078 32.077-77.924 0-45.846-32.077-77.924-32.078-32.077-77.924-32.077h-49.999V-640q0-70.125-49.955-120.063t-120.105-49.938q-51.018 0-92.974 27.667-41.955 27.667-63.198 74.488l-7.363 17.845H298q-61.131 2.143-104.566 45.536T149.999-540q0 62.143 43.929 106.072T300-389.999ZM480-600Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M696.211-223.693q-11.942 0-20.23-8.078-8.288-8.077-8.288-20.018 0-11.942 8.078-20.23 8.077-8.288 20.018-8.288 11.942 0 20.23 8.078 8.288 8.077 8.288 20.018 0 11.942-8.078 20.23-8.077 8.288-20.018 8.288Zm-396 120q-11.942 0-20.23-8.078-8.288-8.077-8.288-20.018 0-11.942 8.078-20.23 8.077-8.288 20.018-8.288 11.942 0 20.23 8.078 8.288 8.077 8.288 20.018 0 11.942-8.078 20.23-8.077 8.288-20.018 8.288Zm-35.923-122.308q-11.038 0-18.662-7.418-7.625-7.418-7.625-18.384 0-10.966 7.466-18.581 7.467-7.615 18.504-7.615h323.741q11.038 0 18.663 7.418 7.624 7.418 7.624 18.384 0 10.966-7.466 18.581-7.467 7.615-18.504 7.615H264.288Zm143.997 120q-11.035 0-18.659-7.418-7.625-7.418-7.625-18.384 0-10.966 7.464-18.581 7.465-7.615 18.499-7.615h251.751q11.035 0 18.66 7.418 7.624 7.418 7.624 18.384 0 10.966-7.464 18.581-7.465 7.615-18.499 7.615H408.285ZM295-346.001q-81.154 0-135.076-58.198Q106.001-462.396 106.001-545q0-74.307 51.062-127.185t127.091-61.584q28.881-55.64 81.441-87.935 52.559-32.295 113.831-32.295 83.554 0 144.294 54.307 60.741 54.308 72.818 136.923 69.308 1.231 113.384 49.116 44.077 47.884 44.077 109.96 0 65.573-46.083 111.633Q761.833-346.001 696-346.001H295Zm5-51.998h396q44.166 0 75.084-30.852 30.917-30.852 30.917-74.924 0-44.071-30.917-75.149-30.918-31.077-75.084-31.077h-42.999l-9-49.999q-8-61.125-54.955-101.563t-109.105-40.438q-49.128 0-89.529 26.667-40.4 26.667-61.643 71.488l-11.363 21.845h-17.491q-59.046.143-100.481 41.677-41.435 41.533-41.435 99.987 0 59.48 41.586 100.909Q241.171-397.999 300-397.999ZM480-600Z"
        />
      </svg>
    );
  }
}
