import { AgGridReact } from '@ag-grid-community/react';
import { Box, Center, Flex, Tooltip } from '@mantine/core';
import { RefObject, useCallback, useMemo, useRef, useState } from 'react';
import { BarkerCoreEnumsPricerStatus } from '../../api';
import { formatCurrency, formatCurrencyNumber } from '../../utils/formatters';
import { BNNumberInput } from '../NumberInput/NumberInput';
import classes from './PriceDisplay.styles.tsx.module.css';
import cx from 'clsx';
import { useDidUpdate, useDisclosure } from '@mantine/hooks';
import WarningIcon from '../icons/Warning';
import ScheduledPriceIcon from '../icons/ScheduledPrice';
import { useAppearanceSettings } from '../../hoc/Inventory/Inventory.AppearanceSettings.hook';
import { useIsDrasticChange } from '../../utils/price-utils';
import ArrowForwardIcon from '../icons/ArrowForward';

export type PriceDisplayProps = {
  value: number;
  basePrice: number;
  cost: number;
  pricerStatusId: BarkerCoreEnumsPricerStatus;
  listingId: string;
  tenantId: string;
  isPending: boolean;
  updatePrice: (tenantId: string, listingId: string, price: number, previousPrice: number) => Promise<void>;
  onChange?: (tenantId: string, listingId: string, price: number, previousPrice: number) => void;
  onCancel?: (tenantId: string, listingId: string) => void;
  gridRef: RefObject<AgGridReact>;
  isDetailRow: boolean;
  readOnly?: boolean;
};

export const PriceDisplayStaged = ({
  value,
  pricerStatusId,
  isPending,
  updatePrice,
  tenantId,
  listingId,
  basePrice,
  cost,
  readOnly,
  onChange: onChangeExternal,
  onCancel: onCancelExternal,
}: PriceDisplayProps) => {
  const isDrasticChange = useIsDrasticChange(tenantId);
  const appearanceSettings = useAppearanceSettings();
  const [updatedValue, setUpdatedValue] = useState(value);
  const [initialValue] = useState(value);
  const [dirty, setDirty] = useState(false);
  const [focused, setFocused] = useState(isPending);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDrasticChangeLabel, { open: showDrasticLabel, close: hideDrasticLabel }] = useDisclosure(false);
  const _isDrasticChange = useMemo(() => isDrasticChange(updatedValue, basePrice, cost), [basePrice, updatedValue]);

  useDidUpdate(() => {
    if (!isPending) {
      setUpdatedValue(initialValue);
      hideDrasticLabel();
      setDirty(false);
    }
  }, [isPending]);

  const onCancel = () => {
    if (onCancelExternal) {
      onCancelExternal(tenantId, listingId);
    }
    hideDrasticLabel();
    setUpdatedValue(initialValue);
    requestAnimationFrame(() => {
      if (inputRef.current) {
        inputRef.current.value = formatCurrencyNumber(initialValue).toFixed(2);
      }
    });
    setDirty(false);
  };

  let tooltip = isPending ? 'Current Price' : '';
  switch (pricerStatusId) {
    case BarkerCoreEnumsPricerStatus.Scheduled:
      tooltip = 'Scheduled';
      break;
  }
  if (isPending) {
    tooltip = 'Current Price';
  }
  if (_isDrasticChange) {
    tooltip = 'Pending Drastic Price Change';
  }
  const Icon = useCallback(() => {
    if (pricerStatusId === BarkerCoreEnumsPricerStatus.Scheduled) {
      return (
        <Box className={`${classes.iconCircle} ${classes.selected} ${classes.scheduled} priceBadge`}>
          <ScheduledPriceIcon size={20} />
        </Box>
      );
    }
    if (_isDrasticChange) {
      return (
        <Box mt={-1} className={`${classes.iconCircle} priceBadge`}>
          <WarningIcon size={20} color="var(--colors-yellow-5)" />
        </Box>
      );
    }
    return null;
  }, [_isDrasticChange, isPending, pricerStatusId]);

  const colorCodePricesBelowCost = (price: number, cost: number): boolean => appearanceSettings.colorPricesBelowCost && price < cost;

  return (
    <Box
      className={cx(
        classes.wrapper,
        (pricerStatusId === BarkerCoreEnumsPricerStatus.None || pricerStatusId === BarkerCoreEnumsPricerStatus.Scheduled) && classes.iconRightSectionOpacity,
      )}
      onMouseOut={() => {
        document.body.classList.remove('unit-price-hover');
      }}
      onMouseOver={() => {
        // Add unit-price-hover class to body
        document.body.classList.add('unit-price-hover');
      }}
    >
      <Tooltip withArrow withinPortal label={tooltip} disabled={tooltip === ''}>
        <Box className={classes.cellIcon}>
          <Icon key="pricerStatusId" />
        </Box>
      </Tooltip>

      <Box className={dirty && focused ? `${classes.cellContent} focused changed` : focused ? `${classes.cellContent} focused` : `${classes.cellContent}`}>
        {isPending && (
          <Flex className={cx(classes.stagedPendingOverlay, isDrasticChangeLabel && classes.isDrastic)} pos="absolute" top={2.5} bottom={3.5} left={1} right={1}>
            <Flex pos="absolute" top={0} bottom={0} left={0} pl={6} align="center" justify="center">
              <Center h={20} w={20} bg="var(--colors-successButton)" style={{ borderRadius: 100, border: '1px solid var(--colors-paper)' }}>
                <ArrowForwardIcon color="white" size={16} />
              </Center>
            </Flex>
          </Flex>
        )}
        <Box
          fw={isPending && value !== updatedValue ? '600' : 'inherit'}
          className={cx(colorCodePricesBelowCost(updatedValue, cost) ? classes.cellDisplayValueBelowCost : classes.cellDisplayValue)}
        >
          {formatCurrency(updatedValue)}
        </Box>
        <BNNumberInput
          ref={inputRef}
          size="xs"
          data-inline-price-input={pricerStatusId === BarkerCoreEnumsPricerStatus.None || pricerStatusId === BarkerCoreEnumsPricerStatus.Scheduled}
          data-listing-id={listingId}
          disabled={readOnly}
          decimalScale={2}
          fixedDecimalScale
          min={0}
          error={isDrasticChangeLabel ? 'Drastic price change' : undefined}
          errorVariant="warning"
          errorWithinPortal
          value={updatedValue}
          selectOnFocus
          tabIndex={pricerStatusId !== BarkerCoreEnumsPricerStatus.None ? -1 : undefined}
          className={cx(
            classes.input,
            'cellInput',
            (pricerStatusId === BarkerCoreEnumsPricerStatus.None || pricerStatusId === BarkerCoreEnumsPricerStatus.Scheduled) && classes.hoverFocus,
          )}
          onChange={(_value) => {
            setUpdatedValue(_value || 0);
            if (onChangeExternal) {
              onChangeExternal(tenantId, listingId, _value || 0, value);
            }
            if (value === _value) {
              setDirty(false);
              onCancelExternal?.(tenantId, listingId);
            } else {
              setDirty(true);
            }
          }}
          onKeyDown={(e) => {
            const _value = parseFloat(e.currentTarget.value);
            if (e.key === 'Enter' || e.key === 'Tab') {
              if (_value !== value) {
                setUpdatedValue(_value || 0);
                updatePrice(tenantId, listingId, _value || 0, value);
              }
              const availableInputs = [...document.querySelectorAll('[data-inline-price-input="true"]').values()].sort((a, b) => {
                const rowIndexA = parseInt(a.closest('[role="row"]')?.getAttribute('row-index') ?? '0', 10);
                const rowIndexB = parseInt(b.closest('[role="row"]')?.getAttribute('row-index') ?? '0', 10);
                return rowIndexA - rowIndexB;
              });
              const currentIndex = availableInputs.indexOf(e.currentTarget);
              const nextElement = availableInputs[currentIndex + (e.shiftKey ? -1 : 1)] as HTMLInputElement;
              nextElement?.focus();
              e.stopPropagation();
              e.preventDefault();
            }
          }}
          onKeyUp={(e) => {
            const _value = parseFloat(e.currentTarget.value);
            if (e.key === 'Tab') {
              return;
            } else if (e.key === 'Escape') {
              onCancel();
            } else if (_value === value) {
              setDirty(false);
            } else {
              if (!isDrasticChange(_value, value, cost)) {
                hideDrasticLabel();
              } else {
                showDrasticLabel();
              }
              setDirty(true);
            }
          }}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={(e) => {
            const _value = parseFloat(e.currentTarget.value);
            if (_value != value) {
              updatePrice(tenantId, listingId, _value || 0, value);
            }
            setFocused(false);
          }}
          leftSection={<Box>$</Box>}
          label={undefined}
        />
        {/* {isPending && value !== updatedValue && <OriginalPriceDisplay />} */}
      </Box>
    </Box>
  );
};
