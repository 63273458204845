import classes from './DiagonalButton.module.css';
import { MantineSize } from '@mantine/core';

interface DiagonalButtonProps {
  value: boolean;
  toggleValue: (value: boolean) => void;
  error?: React.ReactNode;
  disabled?: boolean;
  size?: MantineSize;
}

export default function DiagonalButton({ value: value, toggleValue, error, disabled, size = 'xs' }: DiagonalButtonProps) {
  let buttonSize = 30;
  switch (size) {
    case 'xs':
      buttonSize = 30;
      break;
    case 'sm':
      buttonSize = 36;
      break;
    case 'md':
      buttonSize = 42;
      break;
    case 'lg':
      buttonSize = 50;
      break;
    case 'xl':
      buttonSize = 60;
      break;
  }
  return (
    <svg
      shapeRendering="crispEdges"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      height={buttonSize}
      width={buttonSize}
      overflow="hidden"
      display="block"
      className={disabled ? `${classes.diagonalSvg} disabled size-${size}` : `${classes.diagonalSvg} size-${size}`}
      // style={error ? { borderTop: '1px solid var(--colors-red-error)', borderBottom: '1px solid var(--colors-red-error)' } : {}}
      // ^ added error state below as lines around the edge
    >
      <polygon
        tabIndex={0}
        data-button={true}
        focusable={true}
        onClick={() => toggleValue(true)}
        className={value ? 'svg-over diagonal-btn-active' : 'svg-over'}
        points=".5 .5 29.5 .5 .5 29.5 .5 .5"
        shapeRendering="auto"
      />
      <polygon
        shapeRendering="auto"
        className="svg-plus"
        points="12.16 8.13 9.46 8.13 9.46 5.44 8.13 5.44 8.13 8.13 5.44 8.13 5.44 9.47 8.13 9.47 8.13 12.17 9.46 12.17 9.46 9.47 12.16 9.47 12.16 8.13"
      >
        <title>Over</title>
      </polygon>
      <polygon
        tabIndex={0}
        data-button={true}
        focusable={true}
        onClick={() => toggleValue(false)}
        className={!value ? 'svg-under diagonal-btn-active' : 'svg-under'}
        points=".5 29.5 29.5 .5 29.5 29.5 .5 29.5"
        shapeRendering="auto"
      >
        <title>Under</title>
      </polygon>
      <path className="svg-minus" d="m23.59,20.81v1.26h-6.47v-1.26h6.47Z" shapeRendering="auto" />
      <line className="svg-line" y1="30" x2="30" shapeRendering="crispEdges" />
      {error && (
        <>
          <line x1="0.5" y1="0.5" x2="30.5" y2="0.5" stroke="var(--colors-red-error)" shapeRendering="crispEdges" />
          <line x1="0.5" y1="29.5" x2="30.5" y2="29.5" stroke="var(--colors-red-error)" shapeRendering="crispEdges" />
          <line x1="0.5" y1="0.5" x2="0.5" y2="30.5" stroke="var(--colors-red-error)" shapeRendering="crispEdges" />
          <line x1="29.5" y1="0.5" x2="29.5" y2="29.5" stroke="var(--colors-red-error)" shapeRendering="crispEdges" />
        </>
      )}
    </svg>
  );
}
