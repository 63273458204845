import { ActionIcon, Box, Menu, Tooltip } from '@mantine/core';
import ContingentEventIcon from '../../../components/icons/ContingentEvent';
import EventAvailableIcon from '../../../components/icons/EventAvailable';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { useDTIInventory } from '../DTI.Inventory.hooks';
import { useState } from 'react';

export function ContingentEventMenuButton({ eventId }: { eventId: string }) {
  const [contingentMenuOpened, { open: openContingent, close: closeContingent }] = useDisclosure();
  const contingentMenuRef = useClickOutside(() => closeContingent());
  const { reportEventOccurred } = useDTIInventory('reportEventOccurred');
  const [isReportingEventIssue, setIsReportingEventIssue] = useState(false);

  if (!contingentMenuOpened) {
    return (
      <Tooltip label="Contingent Event" withArrow withinPortal>
        <ActionIcon
          onClick={(e) => {
            e.stopPropagation();
            openContingent();
          }}
          loading={isReportingEventIssue}
          loaderProps={{ type: 'oval', color: 'gray' }}
          data-pendo-name="Contingent Event Action Menu Button"
        >
          <ContingentEventIcon size={21} />
        </ActionIcon>
      </Tooltip>
    );
  }

  return (
    <Menu withinPortal withArrow opened>
      <Menu.Target>
        <ActionIcon
          onClick={(e) => {
            e.stopPropagation();
            closeContingent();
          }}
          loading={isReportingEventIssue}
          loaderProps={{ type: 'oval', color: 'gray' }}
        >
          <ContingentEventIcon size={21} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box ref={contingentMenuRef}>
          <Menu.Item
            leftSection={<EventAvailableIcon />}
            pos="relative"
            disabled={isReportingEventIssue}
            onClick={() => {
              setIsReportingEventIssue(true);
              reportEventOccurred(parseInt(eventId)).then(() => setIsReportingEventIssue(false));
              closeContingent();
            }}
            data-pendo-name="Report Event Occurred MenuItem"
          >
            Report Event Occurred
          </Menu.Item>
        </Box>
      </Menu.Dropdown>
    </Menu>
  );
}
