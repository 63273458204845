import { IconPropsBase } from './IconPropsBase';

export default function RequestQuoteIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 96 960 960">
        <path
          fill={color}
          d="M456.924 840.999h59.999v-40h50.001q12.769 0 21.384-8.615T596.923 771V651q0-12.769-8.615-21.384Q579.693 621 566.924 621H436.923v-60.001h160V501h-80v-40h-59.999v40h-50q-12.769 0-21.385 8.616-8.615 8.615-8.615 21.384v120q0 12.769 8.615 21.384 8.616 8.615 21.385 8.615h130V741h-160v59.999h80v40Zm-204.615 115q-30.308 0-51.308-21t-21-51.308V268.309q0-30.308 21-51.308t51.308-21h317.692l209.998 209.998v477.692q0 30.308-21 51.308t-51.308 21H252.309Zm287.692-544.615V256H252.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v615.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h455.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463V411.384H540.001ZM240 256v155.384V256v640V256Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 96 960 960">
        <path
          fill={color}
          d="M459.04 836.961H515v-40h51.924q11.653 0 19.864-8.212Q595 780.538 595 768.884v-120q0-11.432-8.212-19.658Q578.577 621 566.924 621H435v-64.039h160V501h-80v-40h-55.96v40h-52.116q-11.654 0-19.769 8.226-8.115 8.226-8.115 19.658v120q0 11.654 8.115 19.865 8.115 8.212 19.769 8.212H539.04V741h-160v55.961h80v40ZM255.463 947.922q-28.361 0-48.277-19.916-19.915-19.916-19.915-48.269V272.263q0-28.353 19.915-48.269 19.916-19.916 48.353-19.916h314.462l202.728 202.229v473.346q0 28.437-19.915 48.353-19.916 19.916-48.277 19.916H255.463Zm290.692-536.538V260.038H255.539q-4.615 0-8.462 3.847-3.846 3.846-3.846 8.462v607.306q0 4.616 3.846 8.462 3.847 3.847 8.462 3.847h448.922q4.615 0 8.462-3.847 3.846-3.846 3.846-8.462V411.384H546.155ZM243.231 260.038v151.346-151.346 631.924-631.924Z"
        />
      </svg>
    );
  }
}
