import { IconPropsBase } from './IconPropsBase';

export default function RemoveBoldIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="M194.5-434.5v-91h571v91h-571Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="M234.5-438.5v-83h491v83h-491Z" />
      </svg>
    );
  }
}
