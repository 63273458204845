import { useCallback, useMemo, useState } from 'react';
import { BarkerCoreEnumsPricerStatus, useGetApiInventorySeasons } from '../../api';
import { SortOrder } from '../../components/Select/OrderSelect';
import yasml from '@thirtytech/yasml';
import { seasons } from '../../data/atoms.seasons';
import { useAtom, useSetAtom } from 'jotai';
import { SeasonSummary } from '../../types';
import objectHash from 'object-hash';
import { initialPageLoadAtom } from '../../data/atoms';
import { useDidUpdate } from '@mantine/hooks';

export type SearchParams = {
  query: string;
  fromDate: string | null;
  toDate: string | null;
  pricerStatuses: BarkerCoreEnumsPricerStatus[] | undefined;
  purchaseDateFrom: string | undefined;
  purchaseDateTo: string | undefined;
  daysSinceRepriced: number | undefined;
  daysSinceViewed: number | undefined;
  listingId: string | undefined;
  eventId: string | undefined;
  tags: string[] | undefined;
  antiTags: string[] | undefined;
};

export type SearchParamsWithDates = {
  fromDate: Date | undefined;
  toDate: Date | undefined;
  purchaseDateFrom: Date | undefined;
  purchaseDateTo: Date | undefined;
} & Omit<SearchParams, 'fromDate' | 'toDate' | 'purchaseDateFrom' | 'purchaseDateTo'>;

const SearchResultsState = () => {
  const sortOptions = useMemo(() => ['Date', 'Name', 'Venue', 'City', 'State', 'Last Viewed', 'Tickets Remaining'] as const, []);
  const [sort, setSort] = useState<(typeof sortOptions)[number]>('Date');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
  const [selectedSeason, setSelectedSeason] = useAtom(seasons.selectedSeasonAtom);
  const setIsInitialPageLoad = useSetAtom(initialPageLoadAtom);

  const { data: _seasons = [], isFetching } = useGetApiInventorySeasons({
    // axios: { headers: { 'x-tenant-id': selectedTenantIds[0] } },
    query: {
      select(data) {
        return data.data;
      },
    },
  });

  useDidUpdate(() => {
    setIsInitialPageLoad(false);
  }, [_seasons]);

  const seasonList = useMemo(
    () =>
      [
        ..._seasons
          .reduce((acc, seasonLocation) => {
            const key = `${seasonLocation.tenantId}|${seasonLocation.seasonName}`;
            let aggregation = acc.get(key);

            if (!aggregation) {
              const seasonYear = seasonLocation.seasonName[0].match(/\d/) ? seasonLocation.seasonName.split(' ')[0] : '';

              aggregation = {
                totalCost: 0,
                numLocations: _seasons.filter((x) => x.seasonName === seasonLocation.seasonName).length,
                numEvents: new Set(
                  _seasons
                    .filter((x) => x.seasonName === seasonLocation.seasonName)
                    .flatMap((x) => x.listings)
                    .map((x) => x.eventId),
                ).size,
                tenantId: seasonLocation.tenantId,
                seasonName: seasonLocation.seasonName,
                venueName: seasonLocation.venueName,
                city: seasonLocation.city,
                state: seasonLocation.state,
                seasonYear,
                locations: [],
              };
              acc.set(key, aggregation);
            }

            aggregation.totalCost += seasonLocation.totalCost;
            aggregation.locations.push({
              ...seasonLocation,
              hashId: objectHash({
                section: seasonLocation.section,
                row: seasonLocation.row,
                listings: seasonLocation.listings
                  .map((listing) => listing.listingId)
                  .sort()
                  .join('|'),
              }),
            });

            return acc;
          }, new Map<string, SeasonSummary>())
          .values(),
      ].sort((a, b) => a.seasonName.localeCompare(b.seasonName) || a.tenantId.localeCompare(b.tenantId)),
    [_seasons],
  );

  useDidUpdate(() => {
    if (selectedSeason) {
      const _season = seasonList.find((season) => season.seasonName === selectedSeason.seasonName);
      if (_season) {
        setSelectedSeason(_season);
      }
    } else {
      setSelectedSeason(seasonList[0]);
    }
  }, [seasonList]);

  const onEventClicked = useCallback(
    async (season: SeasonSummary) => {
      setSelectedSeason(season);
    },
    [setSelectedSeason],
  );

  return {
    seasons: seasonList,
    selectedSeason,
    sort,
    setSort,
    sortOptions,
    sortOrder,
    setSortOrder,
    onEventClicked,
    isLoading: isFetching,
  };
};

export const { Provider: SearchResultStateProvider, useSelector: useSearchResults } = yasml(SearchResultsState);
