import React, { useEffect, useRef } from 'react';
import { Box, Flex, List } from '@mantine/core';
import { BarkerCoreModelsAdministrativePrincipal, BarkerCoreModelsInventoryEventComment } from '../../api';
import { CommentCard } from './EventComments.CommentCard';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import CommentIcon from '../../components/icons/Comment';
import classes from './EventComments.module.css';

interface CommentsProps {
  comments: BarkerCoreModelsInventoryEventComment[];
  editComment: (commentToEdit: BarkerCoreModelsInventoryEventComment, revisedComment: string) => void;
  deleteComment: (commentId: number) => void;
  principal: BarkerCoreModelsAdministrativePrincipal | undefined;
}

export function CommentsList({ comments, editComment, deleteComment, principal }: CommentsProps) {
  const endRef = useRef<HTMLDivElement>(null);
  const [editingCommentId, setEditingCommentId] = React.useState(0);
  const [deletingCommentId, setDeletingCommentId] = React.useState(0);

  useEffect(() => {
    endRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [comments]);

  return (
    <Box h="100%" mx={-16} style={{ flex: 1, overflow: 'auto' }}>
      {comments.length === 0 ? (
        <Flex pt="md" mb={16} h="calc(100% - 16px)">
          <BNEmptyState title="No Comments Found" description="Add the first comment below" border={false} icon={<CommentIcon size={24} />} />
        </Flex>
      ) : (
        <List listStyleType="none" p={0} styles={{ itemLabel: { width: '100%' } }}>
          {comments
            .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
            .map((comment) => (
              <List.Item p={0} m={0} key={comment.commentId} className={classes.commentCardWrapper}>
                <CommentCard
                  {...{ editComment, deleteComment, principal }}
                  comment={comment}
                  editingCommentId={editingCommentId}
                  setEditingCommentId={setEditingCommentId}
                  deletingCommentId={deletingCommentId}
                  setDeletingCommentId={setDeletingCommentId}
                />
              </List.Item>
            ))}
        </List>
      )}
      <div ref={endRef} />
    </Box>
  );
}
