import { useCallback } from 'react';
import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { formatCurrency } from '../../utils/formatters';
import pluralize from 'pluralize';
import { Box, Button, Center, Container, Divider, Group, Loader, Text, Title } from '@mantine/core';
import { BNButton } from '../Button/Button';
import LineChartIcon from '../icons/LineChart';
import classes from './TicketVolumeAndPrice.module.css';

export type TicketVolumeAndPriceProps = {
  title: string;
  data: VolumeAndPriceData[];
  isLoading: boolean;
  showMedPrice: boolean;
  setShowMedPrice: (show: boolean) => void;
  showMinPrice: boolean;
  setShowMinPrice: (show: boolean) => void;
  showVolume: boolean;
  setShowVolume: (show: boolean) => void;
  timePeriod: ChartTimePeriod;
  setTimePeriod: (period: ChartTimePeriod) => void;
};

export type VolumeAndPriceData = {
  name: string;
  tickets: number;
  medPrice: number;
  minPrice: number;
};

export type ChartTimePeriod = '1d' | '1w' | '1m' | '3m' | '1y' | 'all';

export default function TicketVolumeAndPrice({
  title,
  data,
  isLoading,
  showMedPrice,
  showMinPrice,
  showVolume,
  timePeriod,
  setTimePeriod,
  setShowVolume,
  setShowMinPrice,
  setShowMedPrice,
}: TicketVolumeAndPriceProps) {
  const CustomTooltip = useCallback(
    ({ active, payload }: TooltipProps<ValueType, NameType>) => {
      if (active && payload && payload.length > 0) {
        if (typeof payload[0].value !== 'number') {
          return null;
        }

        return (
          <div className="custom-tooltip">
            {showMedPrice && (
              <p className="label blue">
                {`${formatCurrency(payload[0].payload.medPrice)}`} <span style={{ fontSize: 10 }}>(MED)</span>
              </p>
            )}
            {showMinPrice && (
              <p className="label green">
                {`${formatCurrency(payload[0].payload.minPrice)}`} <span style={{ fontSize: 10 }}>(MIN)</span>
              </p>
            )}
            {showVolume && (
              <p className="label gray">
                {payload[0].payload.tickets.toLocaleString()} {pluralize('ticket', payload[0].payload.tickets)}
              </p>
            )}
            {payload[0].payload.name && <p className="desc">{`${payload[0].payload.name}`}</p>}
          </div>
        );
      }

      return null;
    },
    [showVolume, showMedPrice, showMinPrice],
  );

  function CustomLoadingOverlay() {
    return (
      <div className={classes.loadingWrapper}>
        <Loader color="var(--colors-gray-5)" type="dots" />
      </div>
    );
  }

  // Use a random id for the linear gradient ID to prevent chart styles from interfering with each other
  function randomGradientId() {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let tempId = '';
    for (let i = 0; i < 8; i++) {
      tempId += letters.charAt(Math.floor(Math.random() * letters.length));
    }
    return tempId;
  }
  const gradientId = randomGradientId();


  return (
    <Box className={classes.graphWrapper}>
      <Group justify="space-between" pb="md">
        <Title fz="sm" fw={600} order={6}>
          {title}
        </Title>
        <Button.Group className={classes.buttonGroup}>
          <Button
            size="compact-xs"
            className={timePeriod === '1d' ? `${classes.chartTimePeriodButton} ${classes.chartTimePeriodButtonSelected}` : `${classes.chartTimePeriodButton}`}
            variant="default"
            onClick={() => setTimePeriod('1d')}
            fz={{ base: 10, lg: 11 }}
            p={2}
            w={24}
            data-pendo-name="Trends Chart 1d Button"
          >
            1d
          </Button>
          <Button
            size="compact-xs"
            className={timePeriod === '1w' ? `${classes.chartTimePeriodButton} ${classes.chartTimePeriodButtonSelected}` : `${classes.chartTimePeriodButton}`}
            variant="default"
            onClick={() => setTimePeriod('1w')}
            fz={{ base: 10, lg: 11 }}
            p={2}
            w={24}
            data-pendo-name="Trends Chart 1w Button"
          >
            1w
          </Button>
          <Button
            size="compact-xs"
            className={timePeriod === '1m' ? `${classes.chartTimePeriodButton} ${classes.chartTimePeriodButtonSelected}` : `${classes.chartTimePeriodButton}`}
            variant="default"
            onClick={() => setTimePeriod('1m')}
            fz={{ base: 10, lg: 11 }}
            p={2}
            w={24}
            data-pendo-name="Trends Chart 1m Button"
          >
            1m
          </Button>
          <Button
            size="compact-xs"
            className={timePeriod === '3m' ? `${classes.chartTimePeriodButton} ${classes.chartTimePeriodButtonSelected}` : `${classes.chartTimePeriodButton}`}
            variant="default"
            onClick={() => setTimePeriod('3m')}
            fz={{ base: 10, lg: 11 }}
            p={2}
            w={24}
            data-pendo-name="Trends Chart 3m Button"
          >
            3m
          </Button>
          <Button
            size="compact-xs"
            className={timePeriod === '1y' ? `${classes.chartTimePeriodButton} ${classes.chartTimePeriodButtonSelected}` : `${classes.chartTimePeriodButton}`}
            variant="default"
            onClick={() => setTimePeriod('1y')}
            fz={{ base: 10, lg: 11 }}
            p={2}
            w={24}
            data-pendo-name="Trends Chart 1y Button"
          >
            1y
          </Button>
          <Button
            size="compact-xs"
            className={timePeriod === 'all' ? `${classes.chartTimePeriodButton} ${classes.chartTimePeriodButtonSelected}` : `${classes.chartTimePeriodButton}`}
            variant="default"
            onClick={() => setTimePeriod('all')}
            fz={{ base: 10, lg: 11 }}
            p={2}
            w={24}
            data-pendo-name="Trends Chart All Button"
          >
            All
          </Button>
        </Button.Group>
      </Group>
      {isLoading && <CustomLoadingOverlay />}
      {!isLoading && data.length === 0 && <EmptyState />}
      {!isLoading && data.length > 0 && (
        <Container className={`charts-wrapper ${classes.chartsWrapper}`} p={0} mt={0}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart width={400} height={316} data={data} className={classes.composedChart}>
              <defs>
                <linearGradient id={`linearGradient_${gradientId}`} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0" stopColor="var(--colors-gray-4)" stopOpacity={1} />
                  <stop offset="100%" stopColor="var(--colors-paper)" stopOpacity={1} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="0" stroke="var(--colors-gray-0" vertical={false} />
              <XAxis minTickGap={48} dataKey="name" strokeWidth={0} style={{ fontSize: 8, opacity: 0.65 }} height={16} />
              <YAxis
                tickLine={false}
                axisLine={false}
                dataKey="medPrice"
                yAxisId="left"
                orientation="left"
                width={32}
                fontSize={8}
                style={{ fontSize: 8, opacity: showMinPrice || showMedPrice ? 0.65 : 0 }}
                tickFormatter={(value) => {
                  if (typeof value !== 'number') {
                    return value;
                  }

                  return formatCurrency(value, false);
                }}
              />
              <YAxis
                tickLine={false}
                axisLine={false}
                dataKey="tickets"
                yAxisId="right"
                orientation="right"
                width={32}
                fontSize={8}
                style={{ fontSize: 8, opacity: showVolume ? 0.65 : 0 }}
                tickFormatter={(value) => {
                  if (typeof value !== 'number') {
                    return value;
                  }

                  return value.toLocaleString('en-US');
                }}
              />
              <Bar opacity={showVolume ? 1 : 0} yAxisId="right" dataKey="tickets" className="gradient-Trends" fill={`url(#linearGradient_${gradientId})`} />
              <Line
                activeDot={showMinPrice}
                opacity={showMinPrice ? 1 : 0}
                yAxisId="left"
                dot={false}
                type="monotone"
                dataKey="minPrice"
                strokeWidth={2}
                stroke="var(--colors-brandcolor-5)"
              />
              <Line
                opacity={showMedPrice ? 1 : 0}
                activeDot={showMedPrice}
                yAxisId="left"
                dot={false}
                type="monotone"
                dataKey="medPrice"
                strokeWidth={2}
                strokeDasharray="3 2.5"
                stroke="var(--colors-selected-6)"
              />
              <Tooltip content={<CustomTooltip />} wrapperStyle={{ outline: 'none' }} />
            </ComposedChart>
          </ResponsiveContainer>
        </Container>
      )}
      <Box className={classes.headerWrapper} px={24}>
        <BNButton size="compact-xs" style={{ opacity: showMedPrice ? 1 : 0.5 }} variant="white" className={classes.headerSide} onClick={() => setShowMedPrice(!showMedPrice)}>
          <Box>
            <Divider variant="dashed" w={16} size={2} color="var(--colors-selected-6)" />
          </Box>
          <Text ml={8} fz={10} fw={600} c="inherit">
            Med. Price
          </Text>
        </BNButton>
        <BNButton size="compact-xs" style={{ opacity: showMinPrice ? 1 : 0.5 }} variant="white" className={classes.headerSide} onClick={() => setShowMinPrice(!showMinPrice)}>
          <Box>
            <Divider w={16} size={2} color="var(--colors-brandcolor-5)" />
          </Box>
          <Text ml={8} fz={10} fw={600} c="inherit">
            Min. Price
          </Text>
        </BNButton>
        <BNButton size="compact-xs" style={{ opacity: showVolume ? 1 : 0.5 }} variant="white" className={classes.headerSide} onClick={() => setShowVolume(!showVolume)}>
          <Group gap={1.5}>
            <Divider mt={4} orientation="vertical" h={8} size={2} color="var(--colors-gray-4)" />
            <Divider orientation="vertical" h={12} size={2} color="var(--colors-gray-4)" />
            <Divider mt={4} orientation="vertical" h={8} size={2} color="var(--colors-gray-4)" />
          </Group>
          <Text fz={10} ml={8} tt="uppercase" fw={600} c="inherit">
            Volume
          </Text>
        </BNButton>
      </Box>
    </Box>
  );
}

function EmptyState() {
  return (
    <>
      <Container className={`charts-wrapper ${classes.chartsWrapper}`} p={0} style={{ border: '1px solid var(--colors-divider)', borderRadius: 3 }}>
        <ResponsiveContainer width="100%" height="100%">
          <Center className={classes.center}>
            <Box className={classes.iconWrapper}>
              <LineChartIcon color="var(--colors-iconFill)" size={28} />
            </Box>
            <Text size="md" fw={600}>
              Trend Data Not Available
            </Text>
            <Group gap={0}>
              <Text size="xs" c="gray.5">
                Check back later
              </Text>
            </Group>
          </Center>
        </ResponsiveContainer>
      </Container>
    </>
  );
}
