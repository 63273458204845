import { humanizeTime } from '../../utils/human-time';
import dayjs from 'dayjs';
import { DateFormats } from '../../utils/globals';
import { BNLabel } from './Label';
import DateSinceSaleIcon from '../icons/DateSinceSale';
import classes from './LastSoldLabel.module.css';

export const LastSoldLabel = ({ lastSoldAt, className, skeleton }: { className?: string; lastSoldAt: Date | null | undefined; skeleton?: boolean }) => {
  const humanizedSoldAt = humanizeTime(lastSoldAt);
  const soldAtDesc = lastSoldAt ? `Last sold ${dayjs(lastSoldAt).format(DateFormats.Standard)}` : 'No sales';
  return <BNLabel className={className || classes.label} leftIcon={<DateSinceSaleIcon size={20} />} text={humanizedSoldAt} skeleton={skeleton} tooltip={soldAtDesc} />;
};
