import { BarkerCoreModelsAdministrativePrincipal, BarkerCoreModelsInventoryEventComment, useGetApiPrincipalsId } from '../../api';
import { Button, Divider, Flex, Group, Text, Tooltip } from '@mantine/core';
import React from 'react';
import { BNTextInput } from '../../components/TextInput/TextInput';
import CloseIcon from '../../components/icons/Close';
import CheckIcon from '../../components/icons/Check';
import { BNButton } from '../../components/Button/Button';
import EditIcon from '../../components/icons/Edit';
import DeleteIcon from '../../components/icons/Delete';
import { BNAvatar } from '../../components/Avatar/Avatar';
import classes from './EventComments.module.css';
import Linkify from 'linkify-react';

interface CommentProps {
  editComment: (commentToEdit: BarkerCoreModelsInventoryEventComment, revisedComment: string) => void;
  deleteComment: (commentId: number) => void;
  comment: BarkerCoreModelsInventoryEventComment;
  principal: BarkerCoreModelsAdministrativePrincipal | undefined;
  editingCommentId: number;
  setEditingCommentId: (editingCommentId: number) => void;
  deletingCommentId: number;
  setDeletingCommentId: (deletingCommentId: number) => void;
}

interface EditCommentProps {
  editComment: (commentToEdit: BarkerCoreModelsInventoryEventComment, revisedComment: string) => void;
  comment: BarkerCoreModelsInventoryEventComment;
  setEditingCommentId: (editingCommentId: number) => void;
}

const EditComment = ({ editComment, comment, setEditingCommentId }: EditCommentProps) => {
  const [revisedComment, setRevisedComment] = React.useState(comment.comment);
  return (
    <Flex className={classes.editingOverlay} mih={48}>
      <BNTextInput
        autoFocus
        value={revisedComment}
        w={260}
        size="xs"
        onChange={(e) => {
          setRevisedComment(e.currentTarget.value);
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            editComment(comment, revisedComment);
            setRevisedComment(revisedComment);
            setEditingCommentId(0);
          } else if (e.key === 'Escape') {
            setRevisedComment(comment.comment);
            setEditingCommentId(0);
          }
        }}
        placeholder="Edit Comment"
        className={classes.editCommentInput}
      />
      <BNButton
        variant="default"
        size="xs"
        px={3}
        className={classes.floatingCancelButton}
        onClick={() => {
          setRevisedComment(comment.comment);
          setEditingCommentId(0);
        }}
      >
        <CloseIcon size={22} />
      </BNButton>
      <BNButton
        variant="filled"
        color="green"
        // disabled={!isDirty()}
        size="xs"
        px={3}
        className={classes.floatingCheckButton}
        onClick={() => {
          editComment(comment, revisedComment);
          setRevisedComment(revisedComment);
          setEditingCommentId(0);
        }}
      >
        <CheckIcon size={22} />
      </BNButton>
    </Flex>
  );
};

export function CommentCard({ editComment, deleteComment, comment, principal, editingCommentId, setEditingCommentId, deletingCommentId, setDeletingCommentId }: CommentProps) {
  const commentCreator = useGetApiPrincipalsId(comment.createdBy)?.data?.data;

  const CommentCreatedAt = () => {
    const twelveHoursAgo = new Date(new Date().setHours(new Date().getHours() - 12));
    const { createdAt } = comment;

    const timeDisplay =
      createdAt > twelveHoursAgo
        ? createdAt.toLocaleTimeString([], {
            hour: 'numeric',
            minute: '2-digit',
          })
        : createdAt.toLocaleDateString();

    return (
      <Text component="span" fz="xs" c="var(--colors-gray-5)" mt={-10}>
        <Tooltip label={createdAt.toLocaleString()} position="right" withArrow>
          <span>{timeDisplay}</span>
        </Tooltip>
      </Text>
    );
  };

  const DeleteComment = () => (
    <Flex align="center" justify="space-between" py="xs" px="sm" bg="var(--colors-paperHover)" pos="absolute" inset={0}>
      <Text fz="xs" maw="50%">
        Are you sure you want to delete this comment?
      </Text>
      <Group gap="xs">
        <BNButton
          size="compact-xs"
          variant="default"
          color="red"
          onClick={() => {
            deleteComment(comment.commentId);
            setDeletingCommentId(0);
          }}
        >
          Delete
        </BNButton>
        <BNButton size="compact-xs" variant="default" onClick={() => setDeletingCommentId(0)}>
          Cancel
        </BNButton>
      </Group>
    </Flex>
  );

  return (
    <Flex w="100%" bg={editingCommentId !== comment.commentId ? 'var(--colors-paper)' : 'var(--colors-paperHover)'} className={classes.commentCard}>
      <BNAvatar principal={commentCreator} />
      <Flex px="xs" justify="center" direction="column" fz="xs" style={{ overflow: 'hidden' }}>
        {editingCommentId === comment.commentId && <EditComment editComment={editComment} comment={comment} setEditingCommentId={setEditingCommentId} />}
        <Linkify as="p" options={{ target: '_blank', rel: 'noopener noreferrer' }}>
          {comment.comment}
        </Linkify>
        <CommentCreatedAt />
      </Flex>
      {principal?.principalId === comment.createdBy && editingCommentId !== comment.commentId && (
        <Flex className={classes.hoverButtonGroup}>
          <Tooltip label="Edit Comment" position="top" withArrow withinPortal>
            <Button size="compact-xs" variant="subtle" onClick={() => setEditingCommentId(comment.commentId)}>
              <EditIcon />
            </Button>
          </Tooltip>
          <Divider orientation="vertical" color="var(--colors-divider)" />
          <Tooltip label="Delete Comment" position="top" withArrow withinPortal>
            <Button size="compact-xs" variant="subtle" onClick={() => setDeletingCommentId(comment.commentId)}>
              <DeleteIcon />
            </Button>
          </Tooltip>
        </Flex>
      )}
      {deletingCommentId === comment.commentId && <DeleteComment />}
    </Flex>
  );
}
