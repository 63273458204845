import { Button, ButtonProps } from '@mantine/core';
import React, { forwardRef } from 'react';

interface BNButtonProps extends ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: 'submit' | 'reset' | 'button' | undefined;
  tabIndex?: number;
}

/**
 * Primary UI component for user interaction
 */
export const BNButton = forwardRef<HTMLButtonElement, BNButtonProps>(({ children, ...props }, ref: any) => {
  return (
    <Button ref={ref} variant={props.variant || 'default'} {...props}>
      {children}
    </Button>
  );
});
