import { IconPropsBase } from './IconPropsBase';

export default function DescriptionIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 96 960 960">
        <path
          fill={color}
          d="M330.001 805.999h299.998v-59.998H330.001v59.998Zm0-160h299.998v-59.998H330.001v59.998Zm-77.692 310q-30.308 0-51.308-21t-21-51.308V268.309q0-30.308 21-51.308t51.308-21h317.692l209.998 209.998v477.692q0 30.308-21 51.308t-51.308 21H252.309Zm287.692-520V256H252.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v615.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h455.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463V435.999H540.001ZM240 256v179.999V256v640V256Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 96 960 960">
        <path
          fill={color}
          d="M346.001 805.999h267.998v-51.998H346.001v51.998Zm0-144h267.998v-51.998H346.001v51.998Zm-69.976 278q-27.024 0-45.524-18.65t-18.5-45.658V276.309q0-27.008 18.65-45.658 18.65-18.65 45.658-18.65h309.692l161.998 161.998v501.692q0 27.008-18.662 45.658-18.662 18.65-45.686 18.65H276.025Zm271.976-528V264H276.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v599.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h407.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463V411.999H548.001ZM264 264v168.999V264v624V264Z"
        />
      </svg>
    );
  }
}
