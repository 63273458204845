import { Box, Group, Modal, SimpleGrid, Tabs } from '@mantine/core';
import { BNTextInput } from '../../components/TextInput/TextInput';
import LinkIcon from '../../components/icons/Link';
import { BNButton } from '../../components/Button/Button';
import classes from './SeatingChart.module.css';
import { useSeatingChart } from './SeatingChart.hooks';
import { BarkerCoreEnumsMarketplace } from '../../api';
import { generateStubHubUrl, generateVividSeatsUrl, parseStubHubUrl, parseVividSeatsUrl } from '../../utils/urls';
import { useForm } from '@mantine/form';
import { useEventMapping } from '../EventMapping/EventMapping.hooks';

export function EditMarketplaceLinksDialog({ isOpened, onClose }: { isOpened: boolean; onClose: () => void }) {
  const { eventMappings } = useSeatingChart('eventMappings');
  const stubHubLink = generateStubHubUrl(eventMappings?.find((mapping) => mapping.marketplaceId === BarkerCoreEnumsMarketplace.StubHub)?.marketplaceEventId ?? null);
  const vividSeatsLink = generateVividSeatsUrl(eventMappings?.find((mapping) => mapping.marketplaceId === BarkerCoreEnumsMarketplace.VividSeats)?.marketplaceEventId ?? null);
  const stubHubForm = useForm<{ url: string }>({
    initialValues: {
      url: stubHubLink,
    },
    validate: {
      url: (value) => (value === null || value === '' ? null : parseStubHubUrl(value) === null ? 'Invalid URL' : null),
    },
  });
  const vividSeatsForm = useForm<{ url: string }>({
    initialValues: {
      url: vividSeatsLink,
    },
    validate: {
      url: (value) => (value === null || value === '' ? null : parseVividSeatsUrl(value) === null ? 'Invalid URL' : null),
    },
  });
  const { linkEvent, isLinkingEvent, selectedEvent, unlinkEvent } = useEventMapping('linkEvent', 'isLinkingEvent', 'selectedEvent', 'unlinkEvent');

  return (
    <Modal opened={isOpened} onClose={onClose} closeOnClickOutside={false} title="Edit Marketplace Links" className={classes.modalNoPadding}>
      <Tabs defaultValue="stubhub" className={classes.tabs}>
        <Tabs.List pl={16} pr={16} className={classes.tabList}>
          <Tabs.Tab value="stubhub">StubHub</Tabs.Tab>
          <Tabs.Tab value="vividseats">Vivid Seats</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="stubhub" pl={16} pr={16} pt="md" pb={0} className={classes.tabPanel}>
          <Box className={classes.tabPanelContentWrapper}>
            <Group className={classes.searchFormRow} gap={0}>
              <Box className={classes.flex1} w="100%">
                <BNTextInput
                  leftSection={<LinkIcon />}
                  size="xs"
                  className={classes.flex1}
                  label=""
                  placeholder="Paste an Event URL"
                  {...stubHubForm.getInputProps('url')}
                  clearable
                  clearableOnClick={() => stubHubForm.setFieldValue('url', '')}
                />
              </Box>
            </Group>
            <Group justify="right">
              <SimpleGrid spacing={12} cols={2}>
                <BNButton
                  variant="default"
                  color="gray"
                  size="xs"
                  onClick={() => {
                    stubHubForm.setValues({ url: stubHubLink });
                  }}
                  disabled={!stubHubForm.isDirty()}
                >
                  Cancel
                </BNButton>
                <BNButton
                  variant="filled"
                  color="gray"
                  size="xs"
                  loading={isLinkingEvent}
                  disabled={!stubHubForm.isDirty()}
                  onClick={() => {
                    if (stubHubForm.values.url === null || stubHubForm.values.url === '') {
                      unlinkEvent(BarkerCoreEnumsMarketplace.StubHub, selectedEvent!.eventId).then(() => {
                        stubHubForm.setValues({ url: generateStubHubUrl(null) });
                        stubHubForm.resetDirty({ url: generateStubHubUrl(null) });
                      });
                    } else {
                      stubHubForm.onSubmit(() => {
                        linkEvent(BarkerCoreEnumsMarketplace.StubHub, selectedEvent!.eventId, {
                          marketplaceId: BarkerCoreEnumsMarketplace.StubHub,
                          marketplaceEventId: parseStubHubUrl(stubHubForm.values.url)!,
                          posEvent: selectedEvent!,
                        }).then(() => {
                          stubHubForm.setValues({
                            url: generateStubHubUrl(parseStubHubUrl(stubHubForm.values.url)!),
                          });
                          stubHubForm.resetDirty({
                            url: generateStubHubUrl(parseStubHubUrl(stubHubForm.values.url)!),
                          });
                        });
                      })();
                    }
                  }}
                >
                  Save
                </BNButton>
              </SimpleGrid>
            </Group>
          </Box>
        </Tabs.Panel>
        <Tabs.Panel value="vividseats" pl={16} pr={16} pt="md" pb={0} className={classes.tabPanel}>
          <Box className={classes.tabPanelContentWrapper}>
            <Group className={classes.searchFormRow} gap={0}>
              <Box className={classes.flex1} w="100%">
                <BNTextInput
                  leftSection={<LinkIcon />}
                  size="xs"
                  className={classes.flex1}
                  label=""
                  placeholder="Paste an Event URL"
                  {...vividSeatsForm.getInputProps('url')}
                  clearable
                  clearableOnClick={() => vividSeatsForm.setFieldValue('url', '')}
                />
              </Box>
            </Group>
            <Group justify="right">
              <SimpleGrid spacing={12} cols={2}>
                <BNButton
                  variant="default"
                  color="gray"
                  size="xs"
                  onClick={() => {
                    vividSeatsForm.setValues({ url: vividSeatsLink });
                  }}
                  disabled={!vividSeatsForm.isDirty()}
                >
                  Cancel
                </BNButton>
                <BNButton
                  variant="filled"
                  color="gray"
                  size="xs"
                  loading={isLinkingEvent}
                  disabled={!vividSeatsForm.isDirty()}
                  onClick={() => {
                    if (vividSeatsForm.values.url === null || vividSeatsForm.values.url === '') {
                      unlinkEvent(BarkerCoreEnumsMarketplace.VividSeats, selectedEvent!.eventId).then(() => {
                        vividSeatsForm.setValues({ url: generateVividSeatsUrl(null) });
                        vividSeatsForm.resetDirty({ url: generateVividSeatsUrl(null) });
                      });
                    } else {
                      vividSeatsForm.onSubmit(() => {
                        linkEvent(BarkerCoreEnumsMarketplace.VividSeats, selectedEvent!.eventId, {
                          marketplaceId: BarkerCoreEnumsMarketplace.VividSeats,
                          marketplaceEventId: parseVividSeatsUrl(vividSeatsForm.values.url)!,
                          posEvent: selectedEvent!,
                        }).then(() => {
                          vividSeatsForm.setValues({
                            url: generateVividSeatsUrl(parseVividSeatsUrl(vividSeatsForm.values.url)!),
                          });
                          vividSeatsForm.resetDirty({
                            url: generateVividSeatsUrl(parseVividSeatsUrl(vividSeatsForm.values.url)!),
                          });
                        });
                      })();
                    }
                  }}
                >
                  Save
                </BNButton>
              </SimpleGrid>
            </Group>
          </Box>
        </Tabs.Panel>
      </Tabs>
    </Modal>
  );
}
