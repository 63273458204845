import { IconPropsBase } from './IconPropsBase';

export default function InventoryIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M620-177.23 463.85-333.38 506-375.54l114 114 226.77-226.77 42.15 42.16L620-177.23ZM820-560h-60v-187.69q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H660v104.61H300V-760h-87.69q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v535.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85H440v60H212.31q-29.92 0-51.12-21.19Q140-182.39 140-212.31v-535.38q0-29.92 21.19-51.12Q182.39-820 212.31-820h176.23q8.31-29.23 33.96-48.46t57.5-19.23q33.08 0 58.42 19.23 25.35 19.23 33.66 48.46h175.61q29.92 0 51.12 21.19Q820-777.61 820-747.69V-560ZM480-755.38q15.46 0 25.81-10.35 10.34-10.35 10.34-25.81 0-15.46-10.34-25.81-10.35-10.34-25.81-10.34-15.46 0-25.81 10.34-10.34 10.35-10.34 25.81 0 15.46 10.34 25.81 10.35 10.35 25.81 10.35Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M616-206.23 460.85-362.38 498-399.54l118 119 221.77-221.77 37.15 37.16L616-206.23ZM796-576h-52v-155.69q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H652v104.61H308V-744h-79.69q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v503.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85H432v52H228.31q-26.62 0-45.47-18.84Q164-201.69 164-228.31v-503.38q0-26.62 18.84-45.47Q201.69-796 228.31-796h180.23q5.31-25.23 24.46-42.46 19.15-17.23 47-17.23 27.08 0 46.42 17.23 19.35 17.23 24.66 42.46h180.61q26.62 0 45.47 18.84Q796-758.31 796-731.69V-576ZM479.79-739.38q13.67 0 23.02-9.14 9.34-9.14 9.34-22.81 0-13.67-9.13-23.02-9.14-9.34-22.81-9.34-13.67 0-23.02 9.13-9.34 9.14-9.34 22.81 0 13.67 9.13 23.02 9.14 9.35 22.81 9.35Z"
        />
      </svg>
    );
  }
}
