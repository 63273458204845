import { Accordion, Badge, Box, Center, FloatingAxesOffsets, FloatingPosition, Menu, MenuItemProps, PopoverProps, Tooltip } from '@mantine/core';
import { forwardRef, ReactElement, ReactNode, useRef } from 'react';
import { BNSwitch } from '../Switch/Switch';
import classes from './BNAccordion.module.css';
import cx from 'clsx';

type ComponentProps<T> = T extends React.ComponentType<infer P> ? P : never;

type AccordionItemProps = {
  /**
   * Unique id used to identify item in accordion. Maps to Accordion.Item value internally
   */
  id: string;
  title: ReactNode;
  leftSection?: ReactNode;
  children: ReactNode | ReactNode[];
  ref?: HTMLDivElement;
  className?: string | undefined;
  rightSection?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  disabledText?: ReactNode;
  tooltipProps?: {
    tooltipPosition?: FloatingPosition | undefined;
    tooltipOffset?: number | FloatingAxesOffsets | undefined;
    tooltipArrowOffset?: number | undefined;
    tooltipMaw?: string | number | undefined;
  };
};
export const BNAccordionItem = forwardRef<HTMLDivElement, AccordionItemProps>(
  ({ id, leftSection, title, children, className, rightSection, onClick, disabled, disabledText, tooltipProps }, ref) => {
    return (
      <Tooltip
        // "Unavailable" tooltip for displaying why this item is disabled
        disabled={!disabledText || !disabled}
        position={tooltipProps?.tooltipPosition ?? 'bottom-end'}
        offset={tooltipProps?.tooltipOffset ?? { mainAxis: -16, crossAxis: 16 }}
        arrowOffset={tooltipProps?.tooltipArrowOffset ?? 40}
        openDelay={100}
        multiline
        label={disabledText}
        withArrow
        maw={tooltipProps?.tooltipMaw ?? 160}
      >
        <Accordion.Item value={id} ref={ref} className={cx(className, disabled && classes.disableAccordion)}>
          <Center pos="relative">
            <Accordion.Control onClick={() => onClick && onClick()} icon={leftSection} disabled={disabled}>
              {title}
            </Accordion.Control>
            {disabled && disabledText ? (
              <>
                <Badge pos="absolute" top={-3} right={-10} size="xs" variant="transparent" color="var(--colors-gray-5)" className={classes.comingSoonBadge}>
                  Unavailable
                </Badge>
                {rightSection}
              </>
            ) : (
              rightSection
            )}
          </Center>
          <Accordion.Panel pos={'relative'}>{disabled && disabledText ? disabledText : children}</Accordion.Panel>
        </Accordion.Item>
      </Tooltip>
    );
  },
);

type AccordionSwitchProps = {
  checked: boolean;
  leftSection?: ReactElement;
  onChange: (value: boolean) => void;
  loading?: boolean;
} & Omit<AccordionItemProps, 'children' | 'id'>;

function BNAccordionSwitch({ leftSection, title, checked, disabled, onChange, loading, disabledText, tooltipProps }: AccordionSwitchProps) {
  return (
    <BNAccordionMenuItem
      leftSection={leftSection}
      closeMenuOnClick={false}
      disabledText={disabled ? disabledText : undefined}
      disabled={disabled}
      tooltipProps={tooltipProps}
      onClick={() => {
        if (!disabled) {
          onChange(!checked);
        }
      }}
      rightSection={
        disabled && disabledText ? undefined : (
          <BNSwitch
            size="xs"
            checked={checked}
            disabled={disabled}
            style={{
              pointerEvents: 'none',
            }}
            loading={loading}
          />
        )
      }
    >
      {title}
    </BNAccordionMenuItem>
  );
}

function BNAccordionMenuItem({
  children,
  title,
  disabled,
  disabledText,
  tooltipProps,
  ...args
}: ComponentProps<typeof Menu.Item> & {
  disabled?: boolean;
  disabledText?: ReactNode;
  onClick?: () => void;
  tooltipProps?: {
    tooltipPosition?: FloatingPosition | undefined;
    tooltipOffset?: number | FloatingAxesOffsets | undefined;
    tooltipArrowOffset?: number | undefined;
    tooltipMaw?: string | number | undefined;
  };
} & (
    | { title?: ReactNode; children: ReactNode }
    | {
        title: ReactNode;
      }
  )) {
  return (
    <Tooltip
      // "Unavailable" tooltip for displaying why this item is disabled
      disabled={!disabledText || !disabled}
      position={tooltipProps?.tooltipPosition ?? 'bottom-end'}
      offset={tooltipProps?.tooltipOffset ?? { mainAxis: -16, crossAxis: 16 }}
      arrowOffset={tooltipProps?.tooltipArrowOffset ?? 40}
      openDelay={100}
      multiline
      label={disabledText}
      withArrow
      maw={tooltipProps?.tooltipMaw ?? 160}
    >
      <Menu.Item fz="xs" fw={500} disabled={disabled} pos="relative" {...args}>
        {children ?? title}
        {disabled && disabledText && (
          // "Unavailable" badge
          <Badge pos="absolute" top={-3} right={-10} size="xs" variant="transparent" color="var(--colors-gray-5)" className={classes.comingSoonBadge}>
            Unavailable
          </Badge>
        )}
      </Menu.Item>
    </Tooltip>
  );
}

export type BNAccordionItems = ReactElement<AccordionSwitchProps | AccordionItemProps | MenuItemProps> | ReactNode;

type BNAccordionMenuProps = {
  target: ReactNode;
  width: number;
  children: BNAccordionItems[] | BNAccordionItems;
  position?: FloatingPosition;
  value?: string | null; // For controlled usage
  closeOnClickOutside?: boolean;
} & Pick<PopoverProps, 'opened' | 'onClose' | 'offset'>;

export function BNAccordionMenu({ width, opened, target, children, onClose, position, value, closeOnClickOutside, ...props }: BNAccordionMenuProps) {
  const targetRef = useRef<HTMLDivElement | null>(null);
  return (
    <Menu withArrow withinPortal opened={opened} onClose={() => onClose?.()} offset={0} width={width} position={position} closeOnClickOutside={closeOnClickOutside} {...props}>
      <Menu.Target ref={targetRef}>
        <Center h="100%">{target}</Center>
      </Menu.Target>
      <Menu.Dropdown p={4} fz="xs">
        <BNAccordion value={value}>{children}</BNAccordion>
      </Menu.Dropdown>
    </Menu>
  );
}

BNAccordionMenu.Item = BNAccordionMenuItem;
BNAccordionMenu.Switch = BNAccordionSwitch;
BNAccordionMenu.Panel = BNAccordionItem;

// const BNAccordionMenuNamespace = Object.assign(BNAccordionMenu, { Item: BNAccordionMenuItem, Switch: BNAccordionSwitch, Panel: BNAccordionItem });
// export { BNAccordionMenuNamespace as BNAccordionMenu };

function BNAccordion({ children, ...props }: { children: BNAccordionItems[] | BNAccordionItems } & ComponentProps<typeof Accordion>) {
  return (
    <Accordion className={classes.BNAccordion} {...props}>
      <Box pos="relative">{children}</Box>
    </Accordion>
  );
}

BNAccordion.Item = BNAccordionItem;
BNAccordion.Switch = BNAccordionSwitch;

// const BNAccordionNamespace = Object.assign(BNAccordion, { Item: BNAccordionItem, Switch: BNAccordionSwitch });
// export { BNAccordionNamespace as BNAccordion };
