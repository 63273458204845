import { useState } from 'react';
import { IconChevronRight } from '@tabler/icons-react';
import { ActionIcon, Checkbox, Combobox, Group, Text, TextInput, useCombobox } from '@mantine/core';
import classes from './TransferList.module.css';

interface RenderListProps {
  label: string;
  options: Array<{ value: string; label: string }>;
  onTransfer: (options: string[]) => void;
  type: 'forward' | 'backward';
}

function RenderList({ label, options, onTransfer, type }: RenderListProps) {
  const combobox = useCombobox();
  const [value, setValue] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  const handleValueSelect = (val: string) => setValue((current) => (current.includes(val) ? current.filter((v) => v !== val) : [...current, val]));

  const items = options
    .filter((item) => item.label.toLowerCase().includes(search.toLowerCase().trim()))
    .map((item) => (
      <Combobox.Option py={0} value={item.value} key={item.value} active={value.includes(item.value)} onMouseOver={() => combobox.resetSelectedOption()}>
        <Group gap="sm">
          <Checkbox w="auto" label={item.label} checked={value.includes(item.value)} onChange={() => {}} aria-hidden tabIndex={-1} style={{ pointerEvents: 'none' }} />
        </Group>
      </Combobox.Option>
    ));

  return (
    <div className={classes.renderList} data-type={type}>
      <Text size="sm" mb="xs">
        {label}
      </Text>
      <Combobox store={combobox} onOptionSubmit={handleValueSelect}>
        <Combobox.EventsTarget>
          <Group wrap="nowrap" gap={0} className={classes.controls}>
            <TextInput
              placeholder="Search companies"
              classNames={{ input: classes.input }}
              value={search}
              onChange={(event) => {
                setSearch(event.currentTarget.value);
                combobox.updateSelectedOptionIndex();
              }}
            />
            <ActionIcon
              radius={0}
              variant="default"
              size={36}
              className={classes.control}
              onClick={() => {
                onTransfer(value);
                setValue([]);
              }}
            >
              <IconChevronRight className={classes.icon} />
            </ActionIcon>
          </Group>
        </Combobox.EventsTarget>

        <div className={classes.list}>
          <Combobox.Options>{items.length > 0 ? items : <Combobox.Empty>Nothing found....</Combobox.Empty>}</Combobox.Options>
        </div>
      </Combobox>
    </div>
  );
}

export type TransferListProps = {
  onChange: (value: string[]) => void;
  searchPlaceholder: string;
  titles: [string, string];
  transferAllMatchingFilter: boolean;
  className: string;
  options: Array<{ value: string; label: string }>;
  selected: Array<{ value: string; label: string }>;
};

export function TransferList({ options, selected, onChange, titles }: TransferListProps) {
  const handleTransfer = (transferFrom: number, selectedOptions: string[]) => {
    if (transferFrom === 0) {
      onChange([...selected.map((x) => x.value), ...selectedOptions]);
    } else if (transferFrom === 1) {
      onChange(selected.map((x) => x.value).filter((item) => !selectedOptions.includes(item)));
    }
  };

  return (
    <div className={classes.root}>
      <RenderList label={titles?.[0]} type="forward" options={options} onTransfer={(options) => handleTransfer(0, options)} />
      <RenderList label={titles?.[1]} type="backward" options={selected} onTransfer={(options) => handleTransfer(1, options)} />
    </div>
  );
}
