import { IconPropsBase } from './IconPropsBase';

export default function TrackChangesIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M480.067-100.001q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.925-148.204q29.925-69.369 81.225-120.682 51.3-51.314 120.65-81.247Q401.15-859.999 480-859.999h29.999v328.308q14.154 8.692 22.077 21.68 7.923 12.988 7.923 30.058 0 24.491-17.731 42.221-17.73 17.731-42.268 17.731t-42.268-17.731q-17.731-17.73-17.731-42.176 0-17.038 7.923-30.372t22.077-21.411v-104.848Q394.154-626 357.077-582.346 320-538.693 320-480q0 66 47 113t113 47q66 0 113-47t47-113q0-36-14.5-66.5T586-600l42.768-42.768q32.692 30.307 51.961 72.161 19.27 41.854 19.27 90.607 0 91.666-64.14 155.832-64.14 64.167-155.768 64.167-91.629 0-155.859-64.167Q260.001-388.334 260.001-480q0-84.23 54.5-145.73 54.5-61.5 135.5-72.038v-101.001q-122.462 11.153-206.232 102.115Q160-605.693 160-480q0 134 93 227t227 93q134 0 227-93t93-227q0-69-27-129t-74-104l42.768-42.768q54.692 52.307 86.461 123.113 31.77 70.805 31.77 152.54 0 78.884-29.92 148.252-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M480.067-116.001q-74.836 0-141.204-28.42-66.369-28.42-116.182-78.21-49.814-49.791-78.247-116.129-28.433-66.337-28.433-141.173 0-75.836 28.425-141.704 28.425-65.869 78.225-115.682 49.8-49.814 116.15-78.247Q405.15-843.999 480-843.999h25.999v319.308q13.154 6.692 19.577 18.68 6.423 11.988 6.423 26.058 0 21.491-15.231 36.721-15.23 15.231-36.768 15.231t-36.768-15.231q-15.231-15.23-15.231-36.676 0-14.038 6.423-25.872t19.577-18.911v-120.848Q393.154-637 352.577-589.846 312-542.693 312-480q0 70 49 119t119 49q70 0 119-49t49-119q0-35-13-65.5T599-599l36.768-36.768q29.692 30.307 46.961 70.161 17.27 39.854 17.27 85.607 0 91.666-64.14 155.832-64.14 64.167-155.768 64.167-91.629 0-155.859-64.167Q260.001-388.334 260.001-480q0-85.23 56-146.23 56-61 138-70.538v-94.001q-120.462 10.153-203.232 98.615Q168-603.693 168-480q0 130 91 221t221 91q130 0 221-91t91-221q0-65-24-121.5T701-701l36.768-36.768q49.692 50.307 77.961 116.113 28.27 65.805 28.27 141.54 0 74.884-28.42 141.252-28.42 66.369-78.21 116.182-49.791 49.814-115.629 78.247-65.837 28.433-141.673 28.433Z"
        />
      </svg>
    );
  }
}
