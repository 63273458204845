import { Modal } from '@mantine/core';
import classes from './InputModal.module.css';

export type InputModalProps = {
  closeModal: () => void;
  children: React.ReactNode;
};

export function InputModal({ closeModal, children }: InputModalProps) {
  return (
    <Modal opened onClose={() => closeModal()} withCloseButton={false} className={classes.bottomSheetModal} title="Update" transitionProps={{ transition: 'slide-up' }}>
      {children}
    </Modal>
  );
}
