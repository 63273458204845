import { useGetApiAccountingInvoices, useGetApiAccountingInvoicesCurrent, useGetApiAccountingInvoicesInvoiceId, useGetApiAccountingPlansCurrent } from '../../api';
import { useMemo, useState } from 'react';
import yasml from '@thirtytech/yasml';
import dayjs from 'dayjs';
import { useGlobalState } from '../../data/GlobalState';
import { useParams } from 'react-router-dom';
import * as R from 'remeda';

const BillingState = () => {
  const { invoiceId, tenantId } = useParams();

  const { data: currentPlan } = useGetApiAccountingPlansCurrent({
    query: {
      select(data) {
        return data.data.find((p) => p.tenantId === tenantId);
      },
    },
    axios: {
      headers: {
        'x-tenant-id': tenantId,
      },
    },
  });

  const { data: selectedInvoice, isFetching: isSelectedFetching } = useGetApiAccountingInvoicesInvoiceId(invoiceId ?? '', {
    query: {
      enabled: invoiceId !== undefined && invoiceId !== 'current',
      select(data) {
        return data.data;
      },
    },
    axios: {
      headers: {
        'x-tenant-id': tenantId,
      },
    },
  });

  const { data: currentInvoice, isFetching: isCurrentFetching } = useGetApiAccountingInvoicesCurrent({
    query: {
      enabled: invoiceId === undefined || invoiceId === 'current',
      select(data) {
        return data.data;
      },
    },
    axios: {
      headers: {
        'x-tenant-id': tenantId,
      },
    },
  });

  const lines = useMemo(() => {
    if (invoiceId !== 'current' && invoiceId) {
      return selectedInvoice?.items ?? [];
    }

    return currentInvoice ?? [];
  }, [invoiceId, selectedInvoice, currentInvoice]);

  const totalSales = useMemo(() => R.sumBy(lines, (line) => line.saleAmount ?? 0), [lines]);

  const totalFees = useMemo(() => R.sumBy(lines, (line) => line.amount), [lines]);

  let startDate = currentPlan?.startedAt ? dayjs(currentPlan.startedAt) : dayjs().startOf('month');
  const today = dayjs().startOf('day');
  let monthAnniversary = startDate.add(1, 'month');
  while (monthAnniversary.isBefore(today)) {
    startDate = monthAnniversary;
    monthAnniversary = monthAnniversary.add(1, 'month');
  }
  const endDate = monthAnniversary;
  const pctThroughMonth = Math.min(Math.max((today.diff(startDate, 'day') + 1) / endDate.diff(startDate, 'day'), 0), 1);

  const projectedFees = useMemo(() => {
    if (selectedInvoice) {
      return totalFees;
    }

    return totalFees / pctThroughMonth;
  }, [selectedInvoice, totalFees, pctThroughMonth]);

  const projectedSales = useMemo(() => {
    if (selectedInvoice) {
      return totalSales;
    }

    return totalSales / pctThroughMonth;
  }, [selectedInvoice, totalSales, pctThroughMonth]);

  const { tenants } = useGlobalState('tenants');

  const tenantPointOfSale = useMemo(() => tenants?.find((t) => t.tenantId === tenantId)?.pointOfSaleId || 'Unknown', [tenantId, tenants]);

  const isLoading = useMemo(() => isCurrentFetching || isSelectedFetching, [isCurrentFetching, isSelectedFetching]);

  const filterOptions = useMemo(() => {
    if (!tenants || tenants.length !== 1 || tenants[0].createdAt > dayjs().startOf('year').toDate()) {
      return ['Last 6 mo', 'Last 12 mo', 'YTD'];
    }

    const years = [];
    let year = dayjs(tenants[0].createdAt).startOf('year');
    while (year.isBefore(dayjs().startOf('year'))) {
      years.push(year.format('YYYY'));
      year = year.add(1, 'year');
    }

    return ['Last 6 mo', 'Last 12 mo', 'YTD', ...years];
  }, [tenants]);

  const [filter, setFilter] = useState(filterOptions?.[0]);

  const searchValues = useMemo(() => {
    if (filter === 'Last 6 mo') {
      return {
        fromDate: dayjs().subtract(6, 'month').toDate(),
        toDate: dayjs().toDate(),
      };
    }

    if (filter === 'Last 12 mo') {
      return {
        fromDate: dayjs().subtract(1, 'year').toDate(),
        toDate: dayjs().toDate(),
      };
    }

    if (filter === 'YTD') {
      return {
        fromDate: dayjs().startOf('year').toDate(),
        toDate: dayjs().toDate(),
      };
    }

    if (filterOptions?.includes(filter)) {
      return {
        fromDate: dayjs(`${filter}-01-01`).startOf('year').toDate(),
        toDate: dayjs(`${filter}-01-01`).endOf('year').toDate(),
      };
    }

    return {
      fromDate: dayjs().subtract(1, 'year').toDate(),
      toDate: dayjs().toDate(),
    };
  }, [filter, filterOptions]);

  const { data: invoices } = useGetApiAccountingInvoices(
    {
      fromDate: searchValues.fromDate,
      toDate: searchValues.toDate,
    },
    {
      query: {
        select(data) {
          return data.data;
        },
      },
      axios: {
        headers: {
          'x-tenant-id': tenantId,
        },
      },
    },
  );

  return {
    currentPlan,
    currentInvoice,
    invoiceId,
    invoices,
    selectedInvoice,
    totalFees,
    totalSales,
    projectedFees,
    projectedSales,
    filterOptions,
    filter,
    setFilter,
    tenantPointOfSale,
    isLoading,
  };
};

export const { Provider: BillingStateProvider, useSelector: useBilling } = yasml(BillingState);
