import { IconPropsBase } from './IconPropsBase';

export default function LinkIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M10.8 16.55H7q-1.875 0-3.212-1.337Q2.45 13.875 2.45 12t1.338-3.213Q5.125 7.45 7 7.45h3.8v1.5H7q-1.25 0-2.15.9-.9.9-.9 2.15t.9 2.15q.9.9 2.15.9h3.8Zm-2.55-3.8v-1.5h7.5v1.5Zm4.95 3.8v-1.5H17q1.25 0 2.15-.9.9-.9.9-2.15t-.9-2.15q-.9-.9-2.15-.9h-3.8v-1.5H17q1.875 0 3.212 1.337Q21.55 10.125 21.55 12t-1.338 3.213Q18.875 16.55 17 16.55Z"
        ></path>
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M8.833 13.625H6q-1.5 0-2.562-1.063Q2.375 11.5 2.375 10q0-1.5 1.063-2.562Q4.5 6.375 6 6.375h2.833v1.083H6q-1.042 0-1.792.75T3.458 10q0 1.042.75 1.792t1.792.75h2.833Zm-1.625-3.083V9.458h5.584v1.084Zm3.959 3.083v-1.083H14q1.042 0 1.792-.75t.75-1.792q0-1.042-.75-1.792T14 7.458h-2.833V6.375H14q1.5 0 2.562 1.063Q17.625 8.5 17.625 10q0 1.5-1.063 2.562Q15.5 13.625 14 13.625Z"
        ></path>
      </svg>
    );
  }
}
