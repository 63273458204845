import { IconPropsBase } from './IconPropsBase';

export default function DiscoverTuneIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M530.001-610.001v-59.998h120v-160h59.998v160h120v59.998H530.001Zm120 480v-379.998h59.998v379.998h-59.998Zm-400 0v-160h-120v-59.998h299.998v59.998h-120v160h-59.998Zm0-320v-379.998h59.998v379.998h-59.998Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M538.001-610.001v-51.998h108v-144h51.998v144h108v51.998H538.001Zm108 456v-363.998h51.998v363.998h-51.998Zm-384 0v-144h-108v-51.998h267.998v51.998h-108v144h-51.998Zm0-288v-363.998h51.998v363.998h-51.998Z"
        />
      </svg>
    );
  }
}
