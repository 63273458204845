import { Center } from '@mantine/core';
interface LogoProps {
  size?: number;
}

export default function BNLogoHover({ size = 53 }: LogoProps) {
  return (
    <svg className="bn-hoverable-logo" xmlns="http://www.w3.org/2000/svg" width={size} height={Math.round(size * 0.528301886792453)} viewBox="0 0 212 112">
      <path
        className="border"
        d="M211,43.82c0-2.27-.73-4.49-2.07-6.33l-22.82-31.15-.04-.06-.04-.06c-.1-.13-.2-.27-.31-.41-1.12-1.5-3.2-4.3-7.02-4.78l-.19-.02h-.19c-.18-.02-.36-.03-.55-.03-3.37,0-6.56,1.91-8.31,4.97-1.73,3.03-1.74,6.58-.04,9.52,1.27,2.33,2.57,4.33,3.96,6.44.86,1.31,1.74,2.67,2.75,4.28l.02.03.02.03,1.94,3.03c.83,1.3,1.67,2.62,2.51,3.92-3.93-.16-7.86-.24-11.74-.24-12.72,0-24.86.85-36.08,2.53h-.03s-.03,0-.03,0c-.89.14-8.64,1.41-16.76,3.42-1.82-.25-3.7-.43-5.6-.54-3.13-.19-5.58-.19-8.71,0-1.91.11-3.79.29-5.6.54-8.13-2.01-15.87-3.28-16.76-3.42h-.03s-.03,0-.03,0c-11.22-1.68-23.36-2.53-36.08-2.53-3.88,0-7.81.08-11.74.24.83-1.3,1.67-2.62,2.51-3.92l1.94-3.03.02-.03.02-.03c1-1.61,1.89-2.97,2.75-4.28,1.38-2.11,2.69-4.11,3.96-6.44,1.7-2.94,1.69-6.49-.04-9.52-1.75-3.07-4.93-4.97-8.31-4.97-.18,0-.36,0-.55.02h-.19s-.19.04-.19.04c-3.82.49-5.9,3.28-7.02,4.78-.1.14-.2.27-.31.41l-.04.06-.04.06L3.07,37.5c-1.35,1.84-2.07,4.05-2.07,6.33v9.49c0,2.78,1.13,5.39,3.19,7.38.07.11.48.83.78,2.88l.03.2.04.2,2.89,13.43c.26,1.64,1.68,9.23,7.03,16.75,5.48,7.7,16.21,16.87,36.18,16.87,1.4,0,2.85-.05,4.3-.14,1.48-.03,10.52-.33,20.06-3.76,14.45-5.19,22.5-15.08,24.95-28.07.05-.25,3.08-15.92,3.11-16.16.44-1.6.94-2.45,2.44-2.46,1.49,0,2,.86,2.44,2.46.03.23,3.07,15.91,3.11,16.16,2.45,13,10.5,22.88,24.95,28.07,9.54,3.43,18.58,3.73,20.06,3.76,1.46.09,2.9.14,4.3.14,19.97,0,30.7-9.18,36.18-16.87,5.35-7.51,6.77-15.1,7.03-16.75l2.89-13.43.04-.2.03-.2c.3-2.06.71-2.77.78-2.88,2.06-1.98,3.19-4.6,3.19-7.38v-9.49Z"
      />
      <path
        className="frame"
        fill="#242424"
        d="M205,43.84c0-1.04-.33-2.05-.95-2.89l-22.79-31.1c-.93-1.2-1.88-2.76-3.42-2.95-2.67-.16-4.99,3.17-3.42,5.79,1.89,3.48,3.84,6.11,6.57,10.5,3.44,5.38,7.56,11.82,10.62,16.56-14.24-1.12-35.81-1.76-58,1.56-.74.12-8.7,1.42-16.81,3.46-.37.09-.74.11-1.12.06-1.88-.27-3.79-.46-5.72-.57-2.63-.17-5.31-.17-7.94,0-1.93.11-3.84.3-5.72.57-.37.05-.75.04-1.12-.06-8.1-2.04-16.06-3.34-16.81-3.46-22.19-3.32-43.76-2.68-58-1.56,3.06-4.74,7.18-11.18,10.62-16.56,2.72-4.39,4.68-7.02,6.57-10.5,1.57-2.62-.75-5.95-3.42-5.79-1.54.2-2.49,1.75-3.42,2.95L7.95,40.95c-.61.84-.95,1.85-.95,2.89v9.47c0,1.22.54,2.36,1.42,3.2s1.94,2.42,2.48,6.23l2.93,13.59s3.9,31.06,41.45,28.65c0,0,34.72.07,39.38-26.78l3.03-15.87c.44-2.57.94-4.78,3.03-6.18h0c3.07-2.12,7.47-2.12,10.54,0h0c2.09,1.4,2.59,3.61,3.03,6.18l3.03,15.87c5.66,26.84,39.38,26.78,39.38,26.78,37.55,2.41,41.45-28.65,41.45-28.65l2.93-13.59c.55-3.8,1.6-5.38,2.48-6.23s1.42-1.98,1.42-3.2v-9.47Z"
      />
      <path
        className="lens"
        fill="#FFFFFF"
        d="M83.96,52.47c-2.09-1.59-4.27-1.93-4.29-1.93-8.59-1.3-20.55-2.58-34.44-2.58-6.04,0-12.75.27-19.39.86-3.44.29-4.19,2.65-4.5,4.35,0,0-.67,6.76-.09,13.22,0,0,.28,6.17,2.79,13.41.03.11,3.04,13.07,18.01,15.73,11.91,2.12,30.2.51,38.34-6.68,6.24-5.5,7.46-12.79,7.42-24.79.02-4.71-1.11-9.5-3.86-11.6Z"
      />
      <path
        className="lens"
        fill="#FFFFFF"
        d="M131.48,88.86c8.15,7.18,26.43,8.8,38.34,6.68,14.97-2.66,17.98-15.62,18.01-15.73,2.51-7.24,2.79-13.41,2.79-13.41.58-6.47-.09-13.22-.09-13.22-.3-1.7-1.05-4.07-4.5-4.35-6.64-.59-13.35-.86-19.39-.86-13.89,0-25.85,1.29-34.44,2.58-.02,0-2.2.34-4.29,1.93-2.75,2.1-3.88,6.89-3.86,11.6-.03,11.99,1.19,19.29,7.42,24.79Z"
      />
      <path
        className="ticket lens-left"
        d="M42.76,91.59c2.95.53,6.37.8,9.87.8,10.92,0,20.55-2.51,25.13-6.54,3.55-3.13,5.24-6.99,5.82-14.21l-13.42-5.03-.06-.02h0c-.26.84-.68,1.65-1.28,2.36-1.93,2.32-5.09,3.04-7.77,2.01-2.69-1-4.57-3.62-4.48-6.63.03-.93.25-1.82.62-2.61l-15.46-5.86-2.87,9.49h0s-5.93,19.64-5.93,19.64c.02.02.04.03.06.05l-.43,1.45c2.25,2.22,5.51,4.27,10.21,5.1Z"
      />
      <path
        className="ticket lens-right"
        d="M169.12,91.59c12.06-2.14,14.72-12.31,14.83-12.75v-.05s.03-.06.03-.06l.04-.13.04-.13c.83-2.4,1.4-4.68,1.78-6.61l-13.98-5.24-.11-.04h0c-.26.84-.68,1.65-1.28,2.36-1.93,2.32-5.09,3.04-7.77,2.01-2.69-1-4.57-3.62-4.48-6.63.03-.93.25-1.82.62-2.61l-15.46-5.86-1.83,6.07-7.33,24.01c4.61,3.99,14.18,6.46,25.03,6.46,3.51,0,6.92-.28,9.87-.8Z"
      />
    </svg>
  );
}
