import { ActionIcon, Badge, Box, Card, Group, Text, Tooltip } from '@mantine/core';
import { BarkerCoreEnumsMarketplace, BarkerCoreModelsInventoryEvent, BarkerCoreModelsMarketplacesEvent } from '../../api';
import dayjs from 'dayjs';
import { DateFormats } from '../../utils/globals';
import OpenNewIcon from '../../components/icons/OpenNew';
import { BNButton } from '../../components/Button/Button';
import { openConfirmModal } from '@mantine/modals';
import { generateSeatGeekUrl, generateTicketEvolutionUrl, generateTicketmasterUrl } from '../../utils/urls';
import classes from './EventMapping.styles.tsx.module.css';
import { formatDate } from '../../utils/formatters';
import { TicketmasterMetadata } from '../../types';

interface MarketplaceSearchResultCardProps {
  event: BarkerCoreModelsMarketplacesEvent;
  selectedMarketplaceEventId: string | null;
  posEvent: BarkerCoreModelsInventoryEvent;
  onLinkEvent?: (marketplaceEvent: BarkerCoreModelsMarketplacesEvent) => void;
  marketplaceId: BarkerCoreEnumsMarketplace;
}

function isEventDateSimilar(posEventDate: Date, marketplaceEventDate: Date) {
  return Math.abs(dayjs(posEventDate).diff(dayjs(marketplaceEventDate), 'minute')) <= 90;
}

export function EventMappingSearchResultsCard({ event, selectedMarketplaceEventId, posEvent, marketplaceId, onLinkEvent }: MarketplaceSearchResultCardProps) {
  if (!event) {
    return null;
  }

  let ticketmasterId: string | undefined = '';

  if (marketplaceId === 'Ticketmaster' && event.metadata) {
    const metadata = JSON.parse(event.metadata) as TicketmasterMetadata;
    ticketmasterId = metadata.TicketmasterId;
  }

  return (
    <>
      <Card h={116} p={0} className={classes.resultCard}>
        <Box p={16} className={classes.resultCardContent}>
          <Group wrap="nowrap" justify="space-between" gap={8} pr={32}>
            <Text c="var(--colors-brandcolor-6)" size="xs" fw={700} tt="uppercase">
              {formatDate(event.dateTimeLocal, DateFormats.Standard)}
            </Text>
            {event.status !== 'Active' && (
              <Badge variant="light" size="xs" color="red">
                {event.status}
              </Badge>
            )}
            <Tooltip label={`Event ID #${event.eventId}`} withArrow>
              <ActionIcon
                color="var(--colors-gray-4)"
                variant="transparent"
                component="a"
                href={
                  marketplaceId === 'TicketEvolution'
                    ? generateTicketEvolutionUrl(event.eventId)
                    : marketplaceId === 'SeatGeek'
                      ? generateSeatGeekUrl(event.eventId)
                      : marketplaceId === 'Ticketmaster' && ticketmasterId
                        ? generateTicketmasterUrl(ticketmasterId)
                        : '#'
                }
                target="_blank"
                pos="absolute"
                right={12}
              >
                <OpenNewIcon size={16} />
              </ActionIcon>
            </Tooltip>
          </Group>
          <Group justify="space-between" h={68} align="start" wrap="nowrap" style={{ overflow: 'hidden' }}>
            <Box pr={20} style={{ flex: 1, flexGrow: 1, overflow: 'hidden' }}>
              <Text size="sm" fw={600} mt={6} lh={1.3}>
                {event.name}
              </Text>
              <Group wrap="nowrap" w="100%" gap={0} mt={6}>
                <Text size="xs" c="gray.5" truncate>
                  {event.venueName}
                </Text>
                <Text size="xs" c="gray.5" style={{ whiteSpace: 'nowrap' }}>
                  &nbsp;·&nbsp;{event.city}, {event.region}
                </Text>
              </Group>
            </Box>
            {event.eventId === selectedMarketplaceEventId ? (
              <Box
                w={108}
                h={30}
                bg="var(--colors-gray-2)"
                style={{
                  alignSelf: 'center',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 3,
                }}
              >
                <Text size="xs" c="gray.4">
                  Linked
                </Text>
              </Box>
            ) : (
              <BNButton
                style={{ alignSelf: 'center' }}
                mt="xs"
                w={108}
                size="xs"
                variant="default"
                onClick={() => {
                  if (!isEventDateSimilar(posEvent.localDateTime, event.dateTimeLocal)) {
                    openConfirmModal({
                      title: 'Event Date/Time Mismatch',
                      children: (
                        <Text size="sm">
                          The date and/or time on this marketplace event is significantly different than the event in your POS. Are you sure you want to proceed with linking to
                          this event?
                        </Text>
                      ),
                      labels: { confirm: 'Confirm', cancel: 'Cancel' },
                      confirmProps: { className: 'confirmButton', variant: 'filled', color: 'gray', size: 'sm' },
                      cancelProps: { className: 'cancelButton', variant: 'default', size: 'sm' },
                      closeButtonProps: { size: 'md' },
                      onConfirm: () => {
                        if (onLinkEvent) {
                          onLinkEvent(event);
                        }
                        //setShowEventMapping(false);
                      },
                    });
                  } else if (onLinkEvent) {
                    onLinkEvent(event);
                  }
                }}
              >
                Select
              </BNButton>
            )}
          </Group>
        </Box>
      </Card>
    </>
  );
}
