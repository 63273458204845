import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import LineChartIcon from '../../components/icons/LineChart';
import { BNLoadingState } from '../../components/Loading/LoadingState';
import TicketVolumeAndPrice, { ChartTimePeriod } from '../../components/Charts/TicketVolumeAndPrice';
import { useMemo, useState } from 'react';
import { useEventPerformance } from './EventPerformance.hooks';
import dayjs from 'dayjs';
import { Flex } from '@mantine/core';

// const data03 = [
//   { name: '9/1', medPrice: 400, minPrice: 260, tickets: 2400 },
//   { name: '9/2', medPrice: 500, minPrice: 380, tickets: 2600 },
//   { name: '9/3', medPrice: 600, minPrice: 526, tickets: 2600 },
//   { name: '9/4', medPrice: 800, minPrice: 670, tickets: 2600 },
//   { name: '9/5', medPrice: 900, minPrice: 707, tickets: 2600 },
//   { name: '9/6', medPrice: 1000, minPrice: 656, tickets: 2600 },
//   { name: '9/7', medPrice: 1040, minPrice: 722, tickets: 2600 },
//   { name: '9/8', medPrice: 900, minPrice: 674, tickets: 2600 },
//   { name: '9/9', medPrice: 600, minPrice: 640, tickets: 2600 },
//   { name: '9/10', medPrice: 400, minPrice: 644, tickets: 2400 },
//   { name: '9/11', medPrice: 500, minPrice: 700, tickets: 2600 },
//   { name: '9/12', medPrice: 730, minPrice: 655, tickets: 2600 },
//   { name: '9/13', medPrice: 800, minPrice: 720, tickets: 2600 },
//   { name: '9/14', medPrice: 910, minPrice: 810, tickets: 2600 },
//   { name: '9/15', medPrice: 960, minPrice: 900, tickets: 2600 },
//   { name: '9/16', medPrice: 1070, minPrice: 800, tickets: 2600 },
//   { name: '9/17', medPrice: 900, minPrice: 820, tickets: 2600 },
//   { name: '9/18', medPrice: 800, minPrice: 780, tickets: 2600 },
//   { name: '9/19', medPrice: 700, minPrice: 840, tickets: 2600 },
//   { name: '9/20', medPrice: 500, minPrice: 860, tickets: 2600 },
//   { name: '9/21', medPrice: 550, minPrice: 864, tickets: 2600 },
//   { name: '9/22', medPrice: 400, minPrice: 878, tickets: 2600 },
//   { name: '9/23', medPrice: 350, minPrice: 900, tickets: 2600 },
//   { name: '9/24', medPrice: 300, minPrice: 820, tickets: 2600 },
//   { name: '9/25', medPrice: 250, minPrice: 940, tickets: 2600 },
//   { name: '9/26', medPrice: 250, minPrice: 1000, tickets: 2800 },
//   { name: '9/27', medPrice: 350, minPrice: 900, tickets: 3000 },
//   { name: '9/28', medPrice: 300, minPrice: 820, tickets: 3200 },
//   { name: '9/29', medPrice: 250, minPrice: 940, tickets: 3600 },
//   { name: '9/30', medPrice: 250, minPrice: 1000, tickets: 3200 },
// ];

export default function MarketplaceTrends() {
  const { seatGeekSnapshots, isSeatGeekSnapshotsLoading } = useEventPerformance('seatGeekSnapshots', 'isSeatGeekSnapshotsLoading');

  const [showMedPrice, setShowMedPrice] = useState(true);
  const [showMinPrice, setShowMinPrice] = useState(true);
  const [showVolume, setShowVolume] = useState(true);
  const [snapshotPeriod, setSnapshotPeriod] = useState<ChartTimePeriod>('1m');

  const data = useMemo(
    () =>
      seatGeekSnapshots
        ?.filter(
          (snapshot) =>
            (snapshotPeriod === '1d' && dayjs(snapshot.createdAt).isAfter(dayjs().subtract(1, 'day'))) ||
            (snapshotPeriod === '1w' && dayjs(snapshot.createdAt).isAfter(dayjs().subtract(1, 'week'))) ||
            (snapshotPeriod === '1m' && dayjs(snapshot.createdAt).isAfter(dayjs().subtract(1, 'month'))) ||
            (snapshotPeriod === '3m' && dayjs(snapshot.createdAt).isAfter(dayjs().subtract(3, 'month'))) ||
            (snapshotPeriod === '1y' && dayjs(snapshot.createdAt).isAfter(dayjs().subtract(1, 'year'))) ||
            snapshotPeriod === 'all',
        )
        .sort((a, b) => (dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1))
        .map((snapshot) => ({
          name: snapshotPeriod === '1d' ? dayjs(snapshot.createdAt).format('h A') : dayjs(snapshot.createdAt).format('M/D'),
          tickets: snapshot.totalTickets,
          medPrice: snapshot.medPrice,
          minPrice: snapshot.minPrice,
        })) ?? [],
    [seatGeekSnapshots, snapshotPeriod],
  );

  if (isSeatGeekSnapshotsLoading) {
    return <BNLoadingState border />;
  }

  if (seatGeekSnapshots === undefined || seatGeekSnapshots.length === 0) {
    return <Flex h="100%" mih={120}><BNEmptyState title="SeatGeek Trends Unavailable" description="Check back later" icon={<LineChartIcon size={28} />} /></Flex>;
  }

  return (
    <TicketVolumeAndPrice
      title="SeatGeek Trends"
      data={data}
      isLoading={isSeatGeekSnapshotsLoading}
      showMedPrice={showMedPrice}
      setShowMedPrice={setShowMedPrice}
      showMinPrice={showMinPrice}
      setShowMinPrice={setShowMinPrice}
      showVolume={showVolume}
      setShowVolume={setShowVolume}
      timePeriod={snapshotPeriod}
      setTimePeriod={setSnapshotPeriod}
    />
  );
}
