import { IconPropsBase } from './IconPropsBase';

export default function QrCodeIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="M140.001-520v-299.999H440V-520H140.001ZM200-579.999h180.001V-760H200v180.001Zm-59.999 439.998V-440H440v299.999H140.001ZM200-200h180.001v-180.001H200V-200Zm320-320v-299.999h299.999V-520H520Zm59.999-59.999H760V-760H579.999v180.001Zm165 439.998v-75h75v75h-75ZM520-365v-75h75v75h-75Zm75 74.999V-365h74.999v74.999H595Zm-75 75v-75h75v75h-75Zm75 75v-75h74.999v75H595Zm74.999-75v-75h75v75h-75Zm0-149.999v-75h75v75h-75Zm75 74.999V-365h75v74.999h-75Z" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="M164.001-528v-267.999H432V-528H164.001ZM216-579.999h164.001V-744H216v164.001Zm-51.999 415.998V-432H432v267.999H164.001ZM216-216h164.001v-164.001H216V-216Zm312-312v-267.999h267.999V-528H528Zm51.999-51.999H744V-744H579.999v164.001Zm149 415.998v-67h67v67h-67ZM528-365v-67h67v67h-67Zm67 66.999V-365h66.999v66.999H595Zm-67 67v-67h67v67h-67Zm67 67v-67h66.999v67H595Zm66.999-67v-67h67v67h-67Zm0-133.999v-67h67v67h-67Zm67 66.999V-365h67v66.999h-67Z" />
      </svg>
    );
  }
}
