import { useMutation, useQuery } from '@tanstack/react-query';
import type { MutationFunction, QueryFunction, QueryKey, UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  BarkerCoreEnumsMarketplace,
  BarkerCoreModelsSupportErrorReport,
  BarkerCoreModelsSupportSupportRequestBase,
  BarkerCoreModelsSupportSupportRequestInventoryEvent,
  BarkerCoreModelsSupportSupportRequestInventoryListing,
  BarkerCoreModelsSupportSupportRequestMarketplaceEvent,
  BarkerCoreModelsSupportSupportRequestMarketplaceListing,
  FreshdeskApiClientContactsModelsContact,
} from './models';

export const getApiSupportContactsSelf = (options?: AxiosRequestConfig): Promise<AxiosResponse<FreshdeskApiClientContactsModelsContact>> => {
  return axios.get(`/api/Support/Contacts/Self`, options);
};

export const getGetApiSupportContactsSelfQueryKey = () => {
  return [`/api/Support/Contacts/Self`] as const;
};

export const getGetApiSupportContactsSelfQueryOptions = <TData = Awaited<ReturnType<typeof getApiSupportContactsSelf>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiSupportContactsSelf>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiSupportContactsSelfQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiSupportContactsSelf>>> = ({ signal }) => getApiSupportContactsSelf({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiSupportContactsSelf>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiSupportContactsSelfQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSupportContactsSelf>>>;
export type GetApiSupportContactsSelfQueryError = AxiosError<void>;

export const useGetApiSupportContactsSelf = <TData = Awaited<ReturnType<typeof getApiSupportContactsSelf>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiSupportContactsSelf>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiSupportContactsSelfQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putApiSupportContactsSelfSendActivation = (options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Support/Contacts/Self/SendActivation`, undefined, options);
};

export const getPutApiSupportContactsSelfSendActivationMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiSupportContactsSelfSendActivation>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiSupportContactsSelfSendActivation>>, TError, void, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiSupportContactsSelfSendActivation>>, void> = () => {
    return putApiSupportContactsSelfSendActivation(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiSupportContactsSelfSendActivationMutationResult = NonNullable<Awaited<ReturnType<typeof putApiSupportContactsSelfSendActivation>>>;

export type PutApiSupportContactsSelfSendActivationMutationError = AxiosError<void>;

export const usePutApiSupportContactsSelfSendActivation = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiSupportContactsSelfSendActivation>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiSupportContactsSelfSendActivation>>, TError, void, TContext> => {
  const mutationOptions = getPutApiSupportContactsSelfSendActivationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postApiSupport = (
  barkerCoreModelsSupportSupportRequestBase: BarkerCoreModelsSupportSupportRequestBase,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/Support`, barkerCoreModelsSupportSupportRequestBase, options);
};

export const getPostApiSupportMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiSupport>>, TError, { data: BarkerCoreModelsSupportSupportRequestBase }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiSupport>>, TError, { data: BarkerCoreModelsSupportSupportRequestBase }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiSupport>>, { data: BarkerCoreModelsSupportSupportRequestBase }> = (props) => {
    const { data } = props ?? {};

    return postApiSupport(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiSupportMutationResult = NonNullable<Awaited<ReturnType<typeof postApiSupport>>>;
export type PostApiSupportMutationBody = BarkerCoreModelsSupportSupportRequestBase;
export type PostApiSupportMutationError = AxiosError<void>;

export const usePostApiSupport = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiSupport>>, TError, { data: BarkerCoreModelsSupportSupportRequestBase }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postApiSupport>>, TError, { data: BarkerCoreModelsSupportSupportRequestBase }, TContext> => {
  const mutationOptions = getPostApiSupportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postApiSupportInventoryEvents = (
  barkerCoreModelsSupportSupportRequestInventoryEvent: BarkerCoreModelsSupportSupportRequestInventoryEvent,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/Support/Inventory/Events`, barkerCoreModelsSupportSupportRequestInventoryEvent, options);
};

export const getPostApiSupportInventoryEventsMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiSupportInventoryEvents>>, TError, { data: BarkerCoreModelsSupportSupportRequestInventoryEvent }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiSupportInventoryEvents>>, TError, { data: BarkerCoreModelsSupportSupportRequestInventoryEvent }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiSupportInventoryEvents>>, { data: BarkerCoreModelsSupportSupportRequestInventoryEvent }> = (props) => {
    const { data } = props ?? {};

    return postApiSupportInventoryEvents(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiSupportInventoryEventsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiSupportInventoryEvents>>>;
export type PostApiSupportInventoryEventsMutationBody = BarkerCoreModelsSupportSupportRequestInventoryEvent;
export type PostApiSupportInventoryEventsMutationError = AxiosError<void>;

export const usePostApiSupportInventoryEvents = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiSupportInventoryEvents>>, TError, { data: BarkerCoreModelsSupportSupportRequestInventoryEvent }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postApiSupportInventoryEvents>>, TError, { data: BarkerCoreModelsSupportSupportRequestInventoryEvent }, TContext> => {
  const mutationOptions = getPostApiSupportInventoryEventsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postApiSupportInventoryListings = (
  barkerCoreModelsSupportSupportRequestInventoryListing: BarkerCoreModelsSupportSupportRequestInventoryListing,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/Support/Inventory/Listings`, barkerCoreModelsSupportSupportRequestInventoryListing, options);
};

export const getPostApiSupportInventoryListingsMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiSupportInventoryListings>>, TError, { data: BarkerCoreModelsSupportSupportRequestInventoryListing }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiSupportInventoryListings>>, TError, { data: BarkerCoreModelsSupportSupportRequestInventoryListing }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiSupportInventoryListings>>, { data: BarkerCoreModelsSupportSupportRequestInventoryListing }> = (props) => {
    const { data } = props ?? {};

    return postApiSupportInventoryListings(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiSupportInventoryListingsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiSupportInventoryListings>>>;
export type PostApiSupportInventoryListingsMutationBody = BarkerCoreModelsSupportSupportRequestInventoryListing;
export type PostApiSupportInventoryListingsMutationError = AxiosError<void>;

export const usePostApiSupportInventoryListings = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiSupportInventoryListings>>, TError, { data: BarkerCoreModelsSupportSupportRequestInventoryListing }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postApiSupportInventoryListings>>, TError, { data: BarkerCoreModelsSupportSupportRequestInventoryListing }, TContext> => {
  const mutationOptions = getPostApiSupportInventoryListingsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postApiSupportMarketplacesMarketplaceIdEvents = (
  marketplaceId: BarkerCoreEnumsMarketplace,
  barkerCoreModelsSupportSupportRequestMarketplaceEvent: BarkerCoreModelsSupportSupportRequestMarketplaceEvent,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/Support/Marketplaces/${marketplaceId}/Events`, barkerCoreModelsSupportSupportRequestMarketplaceEvent, options);
};

export const getPostApiSupportMarketplacesMarketplaceIdEventsMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSupportMarketplacesMarketplaceIdEvents>>,
    TError,
    { marketplaceId: BarkerCoreEnumsMarketplace; data: BarkerCoreModelsSupportSupportRequestMarketplaceEvent },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiSupportMarketplacesMarketplaceIdEvents>>,
  TError,
  { marketplaceId: BarkerCoreEnumsMarketplace; data: BarkerCoreModelsSupportSupportRequestMarketplaceEvent },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiSupportMarketplacesMarketplaceIdEvents>>,
    { marketplaceId: BarkerCoreEnumsMarketplace; data: BarkerCoreModelsSupportSupportRequestMarketplaceEvent }
  > = (props) => {
    const { marketplaceId, data } = props ?? {};

    return postApiSupportMarketplacesMarketplaceIdEvents(marketplaceId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiSupportMarketplacesMarketplaceIdEventsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiSupportMarketplacesMarketplaceIdEvents>>>;
export type PostApiSupportMarketplacesMarketplaceIdEventsMutationBody = BarkerCoreModelsSupportSupportRequestMarketplaceEvent;
export type PostApiSupportMarketplacesMarketplaceIdEventsMutationError = AxiosError<void>;

export const usePostApiSupportMarketplacesMarketplaceIdEvents = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSupportMarketplacesMarketplaceIdEvents>>,
    TError,
    { marketplaceId: BarkerCoreEnumsMarketplace; data: BarkerCoreModelsSupportSupportRequestMarketplaceEvent },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiSupportMarketplacesMarketplaceIdEvents>>,
  TError,
  { marketplaceId: BarkerCoreEnumsMarketplace; data: BarkerCoreModelsSupportSupportRequestMarketplaceEvent },
  TContext
> => {
  const mutationOptions = getPostApiSupportMarketplacesMarketplaceIdEventsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postApiSupportMarketplacesMarketplaceIdListings = (
  marketplaceId: BarkerCoreEnumsMarketplace,
  barkerCoreModelsSupportSupportRequestMarketplaceListing: BarkerCoreModelsSupportSupportRequestMarketplaceListing,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/Support/Marketplaces/${marketplaceId}/Listings`, barkerCoreModelsSupportSupportRequestMarketplaceListing, options);
};

export const getPostApiSupportMarketplacesMarketplaceIdListingsMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSupportMarketplacesMarketplaceIdListings>>,
    TError,
    { marketplaceId: BarkerCoreEnumsMarketplace; data: BarkerCoreModelsSupportSupportRequestMarketplaceListing },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiSupportMarketplacesMarketplaceIdListings>>,
  TError,
  { marketplaceId: BarkerCoreEnumsMarketplace; data: BarkerCoreModelsSupportSupportRequestMarketplaceListing },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiSupportMarketplacesMarketplaceIdListings>>,
    { marketplaceId: BarkerCoreEnumsMarketplace; data: BarkerCoreModelsSupportSupportRequestMarketplaceListing }
  > = (props) => {
    const { marketplaceId, data } = props ?? {};

    return postApiSupportMarketplacesMarketplaceIdListings(marketplaceId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiSupportMarketplacesMarketplaceIdListingsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiSupportMarketplacesMarketplaceIdListings>>>;
export type PostApiSupportMarketplacesMarketplaceIdListingsMutationBody = BarkerCoreModelsSupportSupportRequestMarketplaceListing;
export type PostApiSupportMarketplacesMarketplaceIdListingsMutationError = AxiosError<void>;

export const usePostApiSupportMarketplacesMarketplaceIdListings = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSupportMarketplacesMarketplaceIdListings>>,
    TError,
    { marketplaceId: BarkerCoreEnumsMarketplace; data: BarkerCoreModelsSupportSupportRequestMarketplaceListing },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiSupportMarketplacesMarketplaceIdListings>>,
  TError,
  { marketplaceId: BarkerCoreEnumsMarketplace; data: BarkerCoreModelsSupportSupportRequestMarketplaceListing },
  TContext
> => {
  const mutationOptions = getPostApiSupportMarketplacesMarketplaceIdListingsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postApiSupportDTI = (
  barkerCoreModelsSupportSupportRequestBase: BarkerCoreModelsSupportSupportRequestBase,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/Support/DTI`, barkerCoreModelsSupportSupportRequestBase, options);
};

export const getPostApiSupportDTIMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiSupportDTI>>, TError, { data: BarkerCoreModelsSupportSupportRequestBase }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiSupportDTI>>, TError, { data: BarkerCoreModelsSupportSupportRequestBase }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiSupportDTI>>, { data: BarkerCoreModelsSupportSupportRequestBase }> = (props) => {
    const { data } = props ?? {};

    return postApiSupportDTI(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiSupportDTIMutationResult = NonNullable<Awaited<ReturnType<typeof postApiSupportDTI>>>;
export type PostApiSupportDTIMutationBody = BarkerCoreModelsSupportSupportRequestBase;
export type PostApiSupportDTIMutationError = AxiosError<void>;

export const usePostApiSupportDTI = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiSupportDTI>>, TError, { data: BarkerCoreModelsSupportSupportRequestBase }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postApiSupportDTI>>, TError, { data: BarkerCoreModelsSupportSupportRequestBase }, TContext> => {
  const mutationOptions = getPostApiSupportDTIMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postApiSupportErrors = (barkerCoreModelsSupportErrorReport: BarkerCoreModelsSupportErrorReport, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/Support/Errors`, barkerCoreModelsSupportErrorReport, options);
};

export const getPostApiSupportErrorsMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiSupportErrors>>, TError, { data: BarkerCoreModelsSupportErrorReport }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiSupportErrors>>, TError, { data: BarkerCoreModelsSupportErrorReport }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiSupportErrors>>, { data: BarkerCoreModelsSupportErrorReport }> = (props) => {
    const { data } = props ?? {};

    return postApiSupportErrors(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiSupportErrorsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiSupportErrors>>>;
export type PostApiSupportErrorsMutationBody = BarkerCoreModelsSupportErrorReport;
export type PostApiSupportErrorsMutationError = AxiosError<void>;

export const usePostApiSupportErrors = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiSupportErrors>>, TError, { data: BarkerCoreModelsSupportErrorReport }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postApiSupportErrors>>, TError, { data: BarkerCoreModelsSupportErrorReport }, TContext> => {
  const mutationOptions = getPostApiSupportErrorsMutationOptions(options);

  return useMutation(mutationOptions);
};
