import { ActionIcon, Box, Button, Divider, Group, Menu, Popover, Text } from '@mantine/core';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import dayjs from 'dayjs';
import { SetStateAction, useCallback, useMemo, useState } from 'react';
import { BarkerCoreEnumsAdjustmentType } from '../../../api';
import { BNButton } from '../../../components/Button/Button';
import { BNNumberInput } from '../../../components/NumberInput/NumberInput';
import { BNSelect } from '../../../components/Select/Select';
import ArrowDropdownIcon from '../../../components/icons/ArrowDropdown';
import CloseIcon from '../../../components/icons/Close';
import WarningIcon from '../../../components/icons/Warning';
import classes from '../Inventory.MultiSelectActionbar.styles.tsx.module.css';
import CheckIcon from '../../../components/icons/Check';
import { PriceVariation } from '../../../types';
import { PRICE_VARIATIONS } from '../../../utils/globals';
import { useBulkState } from '../../../data/BulkState';
import { useGlobalState } from '../../../data/GlobalState';
import { useAtomValue } from 'jotai';
import { selectedTenantListingIdAtom } from '../../../data/atoms';

export function EditPricing({ toggleNewPriceDialog }: { toggleNewPriceDialog: (value?: SetStateAction<boolean> | undefined) => void }) {
  const [newPrice, setNewPrice] = useState<number | ''>('');
  const { updatePricing, updatePendingPrice, validatePriceChange } = useBulkState('updatePricing', 'updatePendingPrice', 'validatePriceChange');
  const [priceVariation, setPriceVariation] = useState<PriceVariation>('Decrease by');
  const [priceAdjustment, setPriceAdjustment] = useState<BarkerCoreEnumsAdjustmentType>(BarkerCoreEnumsAdjustmentType.Amount);
  const [isDrasticChangeDialog, { open, close }] = useDisclosure(false);
  const [isDrasticChangeSnoozed, setDrasticChangeSnooze] = useLocalStorage<Date | undefined>({
    key: 'drasticChangeSnooze',
  });
  const { tenantId } = useAtomValue(selectedTenantListingIdAtom);
  const { tenants } = useGlobalState('tenants');
  const allowSnooze = tenants?.find((x) => x.tenantId === tenantId)?.settings?.pricerSettings?.allowDrasticPriceAlertSnooze ?? true;

  const snooze = (interval: 'day' | 'hour') => {
    setDrasticChangeSnooze(dayjs().add(1, interval).toDate());
    onConfirmSave();
  };

  const onCancel = useCallback(() => {
    close();
    setNewPrice('');
    toggleNewPriceDialog(false);
  }, [close, toggleNewPriceDialog]);

  const isDrasticPriceChange = useMemo(
    () => (newPrice !== '' ? validatePriceChange(priceVariation, priceAdjustment, Number(newPrice)) : false),
    [newPrice, priceAdjustment, priceVariation, validatePriceChange],
  );

  const updatePrice = useCallback(() => {
    if (newPrice !== '') {
      updatePricing(priceVariation, priceAdjustment, newPrice);
      setNewPrice('');
      toggleNewPriceDialog(false);
    }
  }, [newPrice, updatePricing, setNewPrice, toggleNewPriceDialog, priceVariation, priceAdjustment]);

  const onConfirmSave = useCallback(() => {
    updatePrice();
    close();
  }, [close, updatePrice]);

  const onSave = useCallback(() => {
    if (validatePriceChange(priceVariation, priceAdjustment, Number(newPrice)) && dayjs(isDrasticChangeSnoozed).diff(dayjs()) <= 0) {
      open();
    } else {
      onConfirmSave();
    }
  }, [isDrasticChangeSnoozed, newPrice, onConfirmSave, open, priceAdjustment, priceVariation, validatePriceChange]);

  return (
    <Group gap="xs">
      <BNButton variant="default" size="xs" px={3} className={`${classes.cancelButton} cancel-button`} onClick={() => onCancel()}>
        <CloseIcon size={22} />
      </BNButton>
      <BNSelect
        w={128}
        value={priceVariation}
        onChange={(value) => {
          setPriceVariation(value as PriceVariation);
          updatePendingPrice(value as PriceVariation, priceAdjustment, newPrice);
        }}
        size="xs"
        data={PRICE_VARIATIONS}
        defaultValue="Set Price"
        aria-label="Price Variation"
      />
      <Group gap={0}>
        {priceVariation !== 'Set To' && (
          <BNSelect
            onChange={(value) => {
              setPriceAdjustment(value as BarkerCoreEnumsAdjustmentType);
              updatePendingPrice(priceVariation, value as BarkerCoreEnumsAdjustmentType, newPrice);
            }}
            value={priceAdjustment}
            defaultValue={BarkerCoreEnumsAdjustmentType.Amount}
            m={0}
            p={0}
            size="xs"
            labelProps={{ sx: { whiteSpace: 'nowrap' } }}
            aria-label="Price Adjustment"
            className={classes.staggerByType}
            data={[
              { value: BarkerCoreEnumsAdjustmentType.Amount, label: '$' },
              { value: BarkerCoreEnumsAdjustmentType.Percentage, label: '%' },
            ]}
          />
        )}
        <BNNumberInput
          onChange={(value) => {
            setNewPrice(value);
            updatePendingPrice(priceVariation, priceAdjustment, value);
          }}
          value={newPrice}
          leftSection={priceVariation === 'Set To' ? <Text size="xs">$</Text> : null}
          errorVariant="warning"
          error={isDrasticPriceChange ? 'Large price change' : undefined}
          decimalScale={2}
          fixedDecimalScale
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              updatePrice();
            }
          }}
          selectOnFocus
          step={1}
          stepHoldDelay={500}
          stepHoldInterval={100}
          min={0}
          w={priceVariation === 'Set To' ? 128 : 77}
          size="xs"
          aria-label="New Price"
          className={priceVariation === 'Set To' ? classes.numInputSingle : classes.numInputGrouped}
        />
      </Group>
      {!isDrasticChangeDialog && (
        <BNButton onClick={onSave} disabled={newPrice === '' || (priceVariation !== 'Set To' && newPrice === 0)} variant="filled" px={3} color="green" size="xs">
          <CheckIcon size={22} />
        </BNButton>
      )}
      {isDrasticChangeDialog && (
        <Popover width={220} position="bottom" withinPortal={false} withArrow onClose={() => close()} opened={isDrasticChangeDialog}>
          <Popover.Target>
            <BNButton onClick={onSave} disabled={newPrice === '' || (priceVariation !== 'Set To' && newPrice === 0)} variant="filled" px={3} color="green" size="xs">
              <CheckIcon size={22} />
            </BNButton>
          </Popover.Target>
          <Popover.Dropdown p={0}>
            <Group wrap="nowrap" p={12} align="start" gap={8}>
              <Box>
                <WarningIcon color="var(--colors-yellow-6)" />
              </Box>
              <Box style={{ flex: 1 }}>
                {/* Title */}
                <Text size="xs" fw="bold">
                  Large Price Change
                </Text>
                {/* Description */}
                <Group gap={0}>
                  {/* <Text size="xs">{formatCurrency(value)}</Text> */}
                  {/* <ArrowForwardIcon color="var(--colors-gray-4)" /> */}
                  {/* <Text size="xs">{formatCurrency(updatedValue)}</Text> */}
                </Group>
                {/* Secondary Description */}
                <Text fz={11} color="var(--colors-gray-5)">
                  {/* {Math.abs(percentChange)}% {percentChange > 0 ? 'Increase' : 'Decrease'} */}
                </Text>
              </Box>
            </Group>
            <Divider color="var(--colors-divider)" />
            {/* Dropdown buttons */}
            <Button.Group>
              <BNButton
                onClick={onCancel}
                radius={0}
                fullWidth
                variant="subtle"
                size="xs"
                aria-label="Inline price cancel"
                maw="50%"
                style={{ borderRight: '1px solid var(--colors-divider)' }}
              >
                Cancel
              </BNButton>
              <Group wrap="nowrap" gap={0} style={{ flex: 1 }}>
                <BNButton radius={0} fullWidth variant="subtle" size="xs" onClick={() => onConfirmSave()} aria-label="Inline price confirm">
                  Confirm
                </BNButton>
                {/* Button wrapped in a menu to allow for snooze & extra options */}
                {allowSnooze && (
                  <Menu withinPortal={false}>
                    <Menu.Target>
                      <ActionIcon radius={0} h="100%" style={{ borderLeft: '1px solid var(--colors-divider)' }}>
                        <ArrowDropdownIcon />
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item fz="xs" onClick={() => snooze('hour')}>
                        Confirm & snooze for 1 hour
                      </Menu.Item>
                      <Menu.Item fz="xs" onClick={() => snooze('day')}>
                        Confirm & snooze for 1 day
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                )}
              </Group>
            </Button.Group>
          </Popover.Dropdown>
        </Popover>
      )}
    </Group>
  );
}
