import { IconPropsBase } from './IconPropsBase';

export default function LineChartIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path fill={color} d="m3.5 18.15-1.125-1.1L9.55 9.875l4 4 7.025-8 1.05 1.05-8.075 9.2-4-4Z"></path>
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path fill={color} d="m3.062 14.771-.75-.729 5.73-5.73 3.27 3.25 5.542-6.291.834.708-6.313 7.167-3.333-3.334Z"></path>
      </svg>
    );
  }
}
