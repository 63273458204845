import { IconPropsBase } from './IconPropsBase';

export default function FileSaveIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M720-134.23 865.77-280 824-321.77l-74 74V-429h-60v181.23l-74-74L574.23-280 720-134.23ZM570-10v-60h300v60H570ZM242.31-170q-29.54 0-50.92-21.39Q170-212.77 170-242.31v-555.38q0-29.54 21.39-50.92Q212.77-870 242.31-870H520l230 230v123.31h-60V-610H490v-200H242.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v555.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h240v60h-240ZM230-230v-580 580Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M732-134.23 861.77-264 825-300.77l-67 67V-398h-52v164.23l-67-67L602.23-264 732-134.23ZM598-10v-52h268v52H598ZM266.31-154q-25.54 0-44.92-19.39Q202-192.77 202-218.31v-571.38q0-25.54 19.39-44.92Q240.77-854 266.31-854H576l182 182v194.31h-52V-634H538v-168H266.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v571.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h264v52h-264ZM254-206v-596 596Z"
        />
      </svg>
    );
  }
}
