import { IconPropsBase } from './IconPropsBase';

export default function WifiOffIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M793.08-80.62 439.39-436.31q-53.93 6.77-101.93 29.92-48 23.16-83.46 60.39l-62.46-63.69q35.46-35.46 79.96-61.39Q316-497 365.7-510L247.23-628.46q-43.31 19.46-85.54 48.23Q119.46-551.46 84-516l-61.69-63.69q35.85-35.85 76.5-65 40.65-29.16 81.96-50.23l-87.84-87.85 42.15-42.15 702.15 702.15-44.15 42.15ZM480-131.54q-33.92 0-57.35-23.73-23.42-23.73-23.42-57.04 0-33.92 23.42-57.34 23.43-23.42 57.35-23.42t57.35 23.42q23.42 23.42 23.42 57.34 0 33.31-23.42 57.04-23.43 23.73-57.35 23.73ZM716-358l-23.04-23.04-23.04-23.04-113.61-113.61q57.54 8 112.65 35.42 55.12 27.43 99.5 71.81L716-358Zm160-158q-77-77-178.5-120.5T480-680q-21 0-41.08 1.5Q418.85-677 400-674l-76.61-76.61q35.15-9.31 73.92-13.96 38.77-4.66 82.69-4.66 135.46 0 252.69 50.5 117.23 50.5 205.77 139.04L876-516Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M772.08-113.62 415.39-469.31q-50.93 6.77-96.43 28.92Q273.46-418.23 238-385l-46.46-45.69Q227-463.15 270-488.08 313-513 361.7-524L224.23-661.46q-40.31 18.46-81.04 46.23Q102.46-587.46 68-555l-45.69-45.69q33.85-32.85 74-61.5 40.15-28.66 79.46-47.73l-48.84-48.85 37.15-37.15 645.15 645.15-37.15 37.15ZM480-155.54q-31.92 0-54.35-22.23-22.42-22.23-22.42-54.54 0-31.92 22.42-54.34 22.43-22.42 54.35-22.42t54.35 22.42q22.42 22.42 22.42 54.34 0 32.31-22.42 54.54-22.43 22.23-54.35 22.23ZM722-386q-22.54-19.54-49.54-35.04t-56.54-24.04l-87.61-87.61q64.54 1 127.15 27.42 62.62 26.43 113 72.81L722-386Zm170-169q-81-77-186.5-121T480-720q-32 0-64.08 3.5Q383.85-713 355-706l-53.61-53.61q39.15-12.31 83.42-18.96 44.27-6.66 95.19-6.66 133.46 0 250.69 49 117.23 49 207.77 135.54L892-555Z"
        />
      </svg>
    );
  }
}
