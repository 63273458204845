import { IconPropsBase } from './IconPropsBase';

export default function StacksIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M480-412.31 60.39-636.15 480-860l420 223.85-420 223.84Zm0 156.15L83.39-467.38l62.84-34.47L480-324.46l334.15-177.39L877-467.38 480-256.16ZM480-100 83.39-311.23l62.84-34.46L480-168.31l334.15-177.38L877-311.23 480-100Zm0-380.61 294.92-155.54L480-791.69 185.46-636.15 480-480.61Zm.38-155.54Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M480-444.31 68.39-644.15 480-844l412 199.85-412 199.84Zm0 164.15L92.39-468.38l58.84-28.47L480-338.46l329.15-158.39L868-468.38 480-280.16ZM480-116 92.39-304.23l58.84-28.46L480-174.31l329.15-158.38L868-304.23 480-116Zm0-386.61 292.92-141.54L480-785.69 187.46-644.15 480-502.61Zm.38-141.54Z"
        />
      </svg>
    );
  }
}
