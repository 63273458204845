import { PlanExpirationBanner } from '../hoc/Banners/PlanExpirationBanner';
import { SupportActivationBanner } from '../hoc/Banners/SupportActivationBanner';
import { UnpaidInvoiceBanner } from '../hoc/Banners/UnpaidInvoiceBanner';
import { VersionNotificationBanner } from '../hoc/Banners/VersionNotificationBanner';
import { BulletinModal } from '../hoc/Bulletin/BulletinModal';
import { InviteModal } from '../hoc/InviteModal/InviteModal';
import { Divider, Flex, Paper } from '@mantine/core';
import { SalesGrid } from '../hoc/Sales/Sales.Grid';
import { SalesSearch } from '../hoc/Sales/Sales.Search';
import { SalesProvider, useSales } from '../hoc/Sales/Sales.hooks';
import { SalesPerformance } from '../hoc/Sales/Sales.Performance';
import cx from 'clsx';
import { SalesFilters } from '../hoc/Sales/Sales.Filters';
import { BNSidebar } from '../hoc/NavBar/Sidebar';
import { SelfServeDialog } from '../hoc/DTI/SelfServe/SelfServeDialog';
import { useAtomValue } from 'jotai/index';
import { selectedSaleAtom } from '../data/atoms';
import { isDtiHosted } from '../utils/whitelabel-consts';
import { ListingHistoryDialog, ListingHistoryDialogDTI } from '../hoc/Inventory/Inventory.ListingHistoryDialog';
import { DTIInventoryStateProvider } from '../hoc/DTI/DTI.Inventory.hooks';

export function Sales() {
  return (
    <>
      <BulletinModal />
      <InviteModal />
      <div className="outer-appWrap">
        <VersionNotificationBanner />
        <SupportActivationBanner />
        <PlanExpirationBanner />
        <UnpaidInvoiceBanner />
        <SalesProvider>
          <SalesInterior />
        </SalesProvider>
      </div>
    </>
  );
}

export function SalesInterior() {
  const { showSidebar } = useSales('showSidebar');
  const selectedSale = useAtomValue(selectedSaleAtom);
  const {
    selectedHistoryListing: { listing, isOpen },
    setSelectedHistoryListing: setListing,
  } = useSales('selectedHistoryListing', 'setSelectedHistoryListing');

  return (
    <div className="inner-appWrap sales-page">
      <div className={cx('appWrap', showSidebar ? 'sidebar-open' : 'sidebar-collapse')}>
        <div className="col1">
          <BNSidebar>
            <Paper className="sidebarSearchWrapper" pos="relative">
              <SalesSearch />
              <Divider color="var(--colors-divider)" />
              <SalesFilters />
            </Paper>
          </BNSidebar>
        </div>
        <Flex className="col2" direction="column">
          <SalesPerformance />
          <SalesGrid.Header />
          <SalesGrid />
        </Flex>
        <SelfServeDialog opened={selectedSale !== undefined} onClose={() => {}} />
        {isDtiHosted ? (
          <DTIInventoryStateProvider>
            <ListingHistoryDialogDTI listing={listing} isOpen={isOpen} setListing={setListing} />
          </DTIInventoryStateProvider>
        ) : (
          <ListingHistoryDialog listing={listing} isOpen={isOpen} setListing={setListing} />
        )}
      </div>
    </div>
  );
}
