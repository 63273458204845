export enum DateFormats {
  Standard = 'ddd · MMM D, YYYY · h:mm A',
  Extended = 'ddd MMM D, YYYY [at] h:mma',
  DateOnly = 'ddd · MMM D, YYYY',
  MonthDayYear = 'MMM DD, YYYY',
  MonthDay = 'MMM DD',
  Short = 'ddd · h:mma',
  TimeOnly = 'h:mma',
  GridFull = 'MM/DD/YYYY h:mm A',
  GridFullWithDOW = 'ddd MM/DD/YYYY h:mm A',
  GridShort = 'MM/DD/YY',
  GridTimeOnly = 'h:mm A',
  DtiDate = 'MM/DD/YYYY',
}

export const DRASTIC_PRICE_CHANGE = 0.3;
export const DRASTIC_PRICE_CHANGE_THRESHOLD = 10;

export const TM_MARKUP = 1.0225;
export const PRICE_VARIATIONS = ['Set To', 'Increase by', 'Decrease by'] as const;
