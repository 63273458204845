import { IconPropsBase } from './IconPropsBase';

export default function ThunderstormIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="m483.925-10 88.23-100.077-80-40 104.461-119.922h79.459l-88.23 100.076 80 40L563.384-10.001h-79.459Zm-240 0 88.23-100.077-80-40 104.461-119.922h79.459l-88.23 100.076 80 40L323.384-10.001h-79.459ZM300-330.002q-87.154 0-148.576-61.423Q90.001-452.846 90.001-540q0-80.307 53.653-139.615 53.654-59.307 133.5-69.154 30.846-56.615 84.423-88.923Q415.154-869.999 480-869.999q88.077 0 152.269 57.307 64.192 57.308 76.269 142.923 71.308 5.231 116.384 54.116Q869.999-566.769 869.999-500q0 70.769-49.615 120.384Q770.769-330.001 700-330.001H300Zm0-59.998h400q45.846 0 77.924-32.077 32.077-32.078 32.077-77.924 0-45.846-32.077-77.924-32.078-32.077-77.924-32.077h-49.999V-640q0-70.231-49.885-120.116Q550.231-810.001 480-810.001q-51.077 0-93.077 27.731-42.001 27.731-63.154 74.424l-7.308 17.845H298q-61.231 2-104.616 45.385T149.999-540q0 62.231 43.885 106.116T300-389.999ZM480-600Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="m495.925-11 88.23-103.077-85-49 107.461-115.922h72.459l-98.23 104.076 83 47L563.384-11.001h-67.459Zm-240 0 88.23-103.077-85-49 107.461-115.922h72.459l-98.23 104.076 83 47L323.384-11.001h-67.459ZM295-346.002q-81.154 0-135.076-58.423Q106.001-462.846 106.001-545q0-74.307 51.153-127.115 51.154-52.807 127-61.654 28.846-55.615 81.423-87.923Q418.154-853.999 480-853.999q83.077 0 143.769 54.307 60.692 54.308 72.769 136.923 69.308 1.231 113.384 49.116Q853.999-565.769 853.999-504q0 65.769-46.115 111.884Q761.769-346.001 696-346.001H295Zm5-51.998h396q43.846 0 74.924-31.077 31.077-31.078 31.077-74.924 0-43.846-31.077-74.924-31.078-31.077-74.924-31.077h-42.999l-9-49.999q-8-61.231-54.885-101.616T480-802.001q-49.077 0-89.577 26.731-40.501 26.731-61.654 71.424l-11.308 21.845H300q-59.231 0-100.616 41.385T157.999-540q0 59.231 41.385 100.616T300-397.999ZM480-600Z"
        />
      </svg>
    );
  }
}
