import { IconPropsBase } from './IconPropsBase';

export default function OpenNewIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M5.3 20.5q-.75 0-1.275-.525Q3.5 19.45 3.5 18.7V5.3q0-.75.525-1.275Q4.55 3.5 5.3 3.5h6.325V5H5.3q-.1 0-.2.1t-.1.2v13.4q0 .1.1.2t.2.1h13.4q.1 0 .2-.1t.1-.2v-6.325h1.5V18.7q0 .75-.525 1.275-.525.525-1.275.525Zm4.425-5.175-1.05-1.05L17.95 5H14V3.5h6.5V10H19V6.05Z"
        ></path>
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M4.75 16.583q-.562 0-.948-.385-.385-.386-.385-.948V4.75q0-.562.385-.948.386-.385.948-.385h4.938V4.5H4.75q-.083 0-.167.083-.083.084-.083.167v10.5q0 .083.083.167.084.083.167.083h10.5q.083 0 .167-.083.083-.084.083-.167v-4.938h1.083v4.938q0 .562-.385.948-.386.385-.948.385Zm3.333-3.895-.771-.771L14.729 4.5H12V3.417h4.583V8H15.5V5.271Z"
        ></path>
      </svg>
    );
  }
}
