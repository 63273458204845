import { IconPropsBase } from './IconPropsBase';

export default function PaidIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M11.3 18.8h1.35v-1.2q1.175-.15 2.113-.875.937-.725.937-2.175 0-1.05-.612-1.862-.613-.813-2.413-1.463-1.575-.525-2.1-.925-.525-.4-.525-1.1 0-.7.538-1.15.537-.45 1.462-.45.775 0 1.262.375.488.375.738.925l1.225-.5q-.3-.8-1.012-1.363-.713-.562-1.563-.637V5.2h-1.35v1.2q-1.275.25-1.962 1.037Q8.7 8.225 8.7 9.2q0 1.125.675 1.812.675.688 2.225 1.238 1.6.6 2.175 1.062.575.463.575 1.238 0 .95-.675 1.375-.675.425-1.525.425-.85 0-1.512-.512-.663-.513-1.013-1.488l-1.275.525q.4 1.1 1.125 1.762.725.663 1.825.913Zm.7 2.7q-1.975 0-3.7-.75t-3.013-2.038Q4 17.425 3.25 15.7T2.5 12q0-1.975.75-3.7t2.037-3.013Q6.575 4 8.3 3.25q1.725-.75 3.7-.75t3.7.75q1.725.75 3.012 2.037Q20 6.575 20.75 8.3q.75 1.725.75 3.7t-.75 3.7q-.75 1.725-2.038 3.012Q17.425 20 15.7 20.75q-1.725.75-3.7.75Zm0-1.5q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"
        ></path>
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M9.458 15.333h1.063v-.937q.958-.104 1.656-.792.698-.687.698-1.604 0-.938-.583-1.552-.584-.615-1.771-1.052-1.333-.479-1.698-.823t-.365-.906q0-.459.427-.792t1.053-.333q.562 0 1.031.271.469.27.656.729l.917-.396q-.271-.625-.802-1.021-.532-.396-1.219-.521v-.937H9.479v.958q-.958.187-1.51.75t-.552 1.313q0 .895.625 1.52t2.062 1.125q.958.355 1.354.74.396.385.396.927 0 .646-.51 1.042-.511.396-1.177.396-.709 0-1.261-.459-.552-.458-.781-1.146l-.979.438q.292.791.906 1.344.615.552 1.406.76Zm.542 2.25q-1.562 0-2.938-.593-1.374-.594-2.416-1.636-1.042-1.042-1.636-2.416-.593-1.376-.593-2.938 0-1.583.593-2.948.594-1.364 1.636-2.406Q5.688 3.604 7.062 3.01 8.438 2.417 10 2.417q1.583 0 2.948.593 1.364.594 2.406 1.636 1.042 1.042 1.636 2.406.593 1.365.593 2.948 0 1.562-.593 2.938-.594 1.374-1.636 2.416-1.042 1.042-2.406 1.636-1.365.593-2.948.593Zm0-1.083q2.708 0 4.604-1.896T16.5 10q0-2.708-1.896-4.604T10 3.5q-2.708 0-4.604 1.896T3.5 10q0 2.708 1.896 4.604T10 16.5Zm0-6.5Z"
        ></path>
      </svg>
    );
  }
}
