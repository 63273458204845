import { IconPropsBase } from './IconPropsBase';

export default function CheckIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      // <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      //   <path fill={color} d="m9.55 17.65-5.325-5.325 1.05-1.075 4.275 4.275 9.175-9.175 1.05 1.075Z" />
      // </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="M382-232.348 146.348-468l64.891-64.891L382-362.131l366.761-366.76L813.652-664 382-232.348Z" />
      </svg>
    );
  } else {
    return (
      // <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
      //   <path fill={color} d="m8.229 13.771-3.208-3.229.729-.75 2.479 2.458 6.021-6 .729.771Z" />
      // </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path fill={color} d="M395-277.348 218.348-455l57.891-57.891L395-395.131l288.761-287.76L741.652-624 395-277.348Z" />
      </svg>
    );
  }
}
