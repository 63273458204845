import { IconPropsBase } from './IconPropsBase';

export default function LiveHelpIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="m12 22.3-2.8-2.8H5.3q-.75 0-1.275-.525Q3.5 18.45 3.5 17.7V4.3q0-.75.525-1.275Q4.55 2.5 5.3 2.5h13.4q.75 0 1.275.525.525.525.525 1.275v13.4q0 .75-.525 1.275-.525.525-1.275.525h-3.9ZM5.3 18h4.525L12 20.175 14.175 18H18.7q.125 0 .213-.087.087-.088.087-.213V4.3q0-.125-.087-.213Q18.825 4 18.7 4H5.3q-.125 0-.212.087Q5 4.175 5 4.3v13.4q0 .125.088.213.087.087.212.087ZM5 4v14V4Zm7 12.45q.425 0 .713-.3.287-.3.287-.725t-.287-.725q-.288-.3-.713-.3-.45 0-.737.3-.288.3-.288.725t.288.725q.287.3.737.3Zm.7-3.575q0-.8.213-1.213.212-.412.837-1.037.775-.775 1.125-1.338.35-.562.35-1.287 0-1.25-.85-2.013-.85-.762-2.2-.762-1.225 0-2.087.625-.863.625-1.238 1.625l1.3.525q.2-.65.7-1.05.5-.4 1.25-.4.8 0 1.263.438.462.437.462 1.062 0 .475-.275.912-.275.438-.85.963-.825.75-1.125 1.375-.3.625-.3 1.575Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="m10 18.583-2.354-2.354H4.5q-.667 0-1.125-.458-.458-.459-.458-1.125V3.667q0-.667.458-1.125.458-.459 1.125-.459h11q.667 0 1.125.459.458.458.458 1.125v10.979q0 .666-.458 1.125-.458.458-1.125.458h-3.167Zm-5.5-3.687h3.688L10 16.708l1.792-1.812H15.5q.104 0 .177-.073t.073-.177V3.667q0-.105-.073-.177-.073-.073-.177-.073h-11q-.104 0-.177.073-.073.072-.073.177v10.979q0 .104.073.177t.177.073ZM4.25 3.417v11.479V3.417ZM10 13.854q.375 0 .625-.26.25-.261.25-.636t-.26-.635q-.261-.261-.636-.261t-.625.261q-.25.26-.25.635t.261.636q.26.26.635.26Zm.604-3q0-.687.167-1.01.167-.323.625-.782.646-.624.969-1.104.323-.479.323-1.146 0-1.062-.74-1.687T10.104 4.5q-1 0-1.729.531-.729.531-1.042 1.365l1.167.479q.271-.604.667-.896.395-.291.979-.291.625 0 .969.322.343.323.343.823 0 .417-.26.771-.26.354-.76.834-.626.583-.855 1.124-.229.542-.229 1.292Z"
        />
      </svg>
    );
  }
}
