import { IconPropsBase } from './IconPropsBase';

export default function MarketplaceGametimeIcon({ size = 24, color = 'currentColor' }: IconPropsBase) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <path fill={color} d="M14.83,2l5.91,10-5.91,10H3.95c-.48,0-.67-.47-.48-.85L14.83,2Z" />
      <path fill={color} d="M3.48,2.85l4.2,7.17L14.83,2H3.95c-.48,0-.76.47-.48.85Z" />
    </svg>
  );
}
