import { Box, Center, Group, Menu, Text, Tooltip, UnstyledButton } from '@mantine/core';
import AutoPriceIcon from '../../components/icons/AutoPrice';
import { BNNumberInput } from '../../components/NumberInput/NumberInput';
import { BNSwitch } from '../../components/Switch/Switch';
import { useNowPricing } from './NowPricing.hooks';
import ScheduledPriceIcon from '../../components/icons/ScheduledPrice';
import SelectArrowsIcon from '../../components/icons/SelectArrows';
import classes from './NowPricing.styles.tsx.module.css';

export const PriceForm = () => {
  const { selectedMarketplaceEvent, isFormReady, onAutoPricerToggle, form, isDrasticChange, onSchedulePricerToggle } = useNowPricing(
    'selectedMarketplaceEvent',
    'isFormReady',
    'onAutoPricerToggle',
    'form',
    'isDrasticChange',
    'onSchedulePricerToggle',
    'onSave',
  );

  return (
    <Group gap="md">
      <Box maw={151} style={{ flex: 1 }}>
        <BNNumberInput
          size="lg"
          disabled={!!form.values.isAutoPriced || !isFormReady}
          {...form.getInputProps('listPrice')}
          value={form.values.listPrice}
          leftSection={
            <Text size="xl" ml={-8}>
              $
            </Text>
          }
          error={form.getInputProps('listPrice').error ?? (isDrasticChange ? 'Large price change' : undefined)}
          errorVariant={form.getInputProps('listPrice').error ? 'error' : 'warning'}
          decimalScale={2}
          fixedDecimalScale
          min={0}
          label="List Price"
          labelProps={{ fz: 'xs' }}
          className={form.values.isAutoPriced ? classes.numInputAutoPriced : classes.numInput}
          selectOnFocus
        />
      </Box>
      <Box className={classes.autoPriceWrapper}>
        <Text c="var(--colors-gray-5)" size="xs" mb={8} mt={-8}>
          {form.values.isScheduled ? 'Automate' : 'Auto-Price'}
        </Text>
        <Group gap={4}>
          <BNSwitch
            disabled={!isFormReady || (!form.values.isAutoPriced && !form.values.isScheduled && !selectedMarketplaceEvent.marketplaceEvent)}
            size="lg"
            {...form.getInputProps('isAutoPriced', { type: 'checkbox' })}
            checked={form.values.isAutoPriced || form.values.isScheduled}
            onChange={onAutoPricerToggle}
          />
          <Menu position="bottom">
            <Menu.Target>
              <UnstyledButton className={classes.toggleButton} pos="relative" pr={0} pl={form.values.isScheduled ? 2 : 3} py={4} w={38} disabled={!isFormReady}>
                <Tooltip label="Switch between auto-pricer and scheduled pricer." withArrow>
                  <Center>
                    <Center w={24}>
                      {form.values.isScheduled && <ScheduledPriceIcon size={24} color="var(--colors-orange)" />}
                      {!form.values.isScheduled && <AutoPriceIcon size={24} color={form.values.isAutoPriced ? 'var(--colors-selectedBorder)' : 'var(--colors-gray-5)'} />}
                    </Center>
                    <Center w={24} pos="absolute" right={-4}>
                      <SelectArrowsIcon color="var(--colors-gray-5)" />
                    </Center>
                  </Center>
                </Tooltip>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              {!form.values.isScheduled && (
                <Menu.Item
                  fz="xs"
                  leftSection={<ScheduledPriceIcon color="var(--colors-orange)" />}
                  onClick={() => onSchedulePricerToggle()}
                  data-pendo-name="Scheduled Pricer Option"
                >
                  Change to Scheduled Pricer
                </Menu.Item>
              )}
              {form.values.isScheduled && (
                <Tooltip label="Unable to enable Auto-Pricer when event is not mapped" hidden={!!selectedMarketplaceEvent.marketplaceEvent} withArrow>
                  <div>
                    <Menu.Item
                      fz="xs"
                      leftSection={<AutoPriceIcon color="var(--colors-selectedBorder)" />}
                      disabled={!selectedMarketplaceEvent.marketplaceEvent}
                      onClick={() => onAutoPricerToggle(true)}
                    >
                      Change to Auto-Pricer
                    </Menu.Item>
                  </div>
                </Tooltip>
              )}
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Box>
    </Group>
  );
};
