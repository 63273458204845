import { IconPropsBase } from './IconPropsBase';

export default function P2PIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M172.31-180q-29.83 0-51.07-21.24Q100-222.48 100-252.31v-535.38q0-29.83 21.24-51.07Q142.48-860 172.31-860h191.54q29.82 0 51.06 21.24 21.24 21.24 21.24 51.07v184.61h-59.99v-80.38H160v326.92h276.15v104.23q0 29.83-21.24 51.07Q393.67-180 363.85-180H172.31Zm420.38 80q-28.4 0-48.62-20.22-20.22-20.23-20.22-48.63v-187.69h59.99v80H800v-326.54H523.85v-104.61q0-29.83 21.24-51.07Q566.33-780 596.15-780h191.54q29.83 0 51.07 21.24Q860-737.52 860-707.69v535.38q0 29.83-21.24 51.07Q817.52-100 787.69-100h-195ZM160-296.54v44.23q0 5.39 3.46 8.85t8.85 3.46h191.54q5.38 0 8.84-3.46 3.47-3.46 3.47-8.85v-44.23H160Zm423.84 80v44.23q0 5.39 3.47 8.85 3.46 3.46 8.84 3.46h191.54q5.39 0 8.85-3.46t3.46-8.85v-44.23H583.84ZM160-743.46h216.16v-44.23q0-5.39-3.47-8.85-3.46-3.46-8.84-3.46H172.31q-5.39 0-8.85 3.46t-3.46 8.85v44.23Zm423.84 80.38H800v-44.61q0-5.39-3.46-8.85t-8.85-3.46H596.15q-5.38 0-8.84 3.46-3.47 3.46-3.47 8.85v44.61ZM323.09-445.39q-14.7 0-24.67-9.94-9.96-9.95-9.96-24.66 0-14.7 9.95-24.66t24.65-9.96q14.71 0 24.67 9.94 9.96 9.95 9.96 24.66 0 14.7-9.95 24.66-9.94 9.96-24.65 9.96Zm156.92 0q-14.7 0-24.66-9.94-9.96-9.95-9.96-24.66 0-14.7 9.94-24.66 9.95-9.96 24.66-9.96 14.7 0 24.66 9.94 9.96 9.95 9.96 24.66 0 14.7-9.94 24.66-9.95 9.96-24.66 9.96Zm156.54 0q-14.7 0-24.67-9.94-9.96-9.95-9.96-24.66 0-14.7 9.95-24.66t24.65-9.96q14.71 0 24.67 9.94 9.96 9.95 9.96 24.66 0 14.7-9.94 24.66-9.95 9.96-24.66 9.96ZM160-296.54V-240-296.54Zm423.84 80V-160-216.54ZM160-743.46V-800-743.46Zm423.84 80.38V-720v56.92Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M180.31-188q-26.53 0-45.42-18.89T116-252.31v-527.38q0-26.53 18.89-45.42T180.31-844h183.54q26.52 0 45.41 18.89 18.89 18.89 18.89 45.42v176.61h-51.99v-72.38H168v318.92h260.15v104.23q0 26.53-18.89 45.42T363.85-188H180.31Zm412.38 72q-25.1 0-42.97-17.87-17.87-17.88-17.87-42.98v-179.69h51.99v72H792v-318.54H531.85v-104.61q0-26.53 18.89-45.42T596.15-772h183.54q26.53 0 45.42 18.89T844-707.69v527.38q0 26.53-18.89 45.42T779.69-116h-187ZM168-304.54v52.23q0 5.39 3.46 8.85t8.85 3.46h183.54q5.38 0 8.84-3.46 3.47-3.46 3.47-8.85v-52.23H168Zm415.84 72v52.23q0 5.39 3.47 8.85 3.46 3.46 8.84 3.46h183.54q5.39 0 8.85-3.46t3.46-8.85v-52.23H583.84ZM168-727.46h208.16v-52.23q0-5.39-3.47-8.85-3.46-3.46-8.84-3.46H180.31q-5.39 0-8.85 3.46t-3.46 8.85v52.23Zm415.84 72.38H792v-52.61q0-5.39-3.46-8.85t-8.85-3.46H596.15q-5.38 0-8.84 3.46-3.47 3.46-3.47 8.85v52.61ZM339.3-449.39q-12.91 0-21.88-8.73-8.96-8.74-8.96-21.66 0-12.91 8.74-21.87t21.65-8.96q12.92 0 21.88 8.73 8.96 8.74 8.96 21.66 0 12.91-8.74 21.87-8.73 8.96-21.65 8.96Zm140.92 0q-12.91 0-21.87-8.73-8.96-8.74-8.96-21.66 0-12.91 8.73-21.87 8.74-8.96 21.66-8.96 12.91 0 21.87 8.73 8.96 8.74 8.96 21.66 0 12.91-8.73 21.87-8.74 8.96-21.66 8.96Zm140.54 0q-12.91 0-21.88-8.73-8.96-8.74-8.96-21.66 0-12.91 8.74-21.87t21.65-8.96q12.92 0 21.88 8.73 8.96 8.74 8.96 21.66 0 12.91-8.73 21.87-8.74 8.96-21.66 8.96ZM168-304.54V-240-304.54Zm415.84 72V-168-232.54ZM168-727.46V-792-727.46Zm415.84 72.38V-720v64.92Z"
        />
      </svg>
    );
  }
}
