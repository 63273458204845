import { Box, Center, Group, Loader, Modal, Stack, Text } from '@mantine/core';
import { useAtom, useAtomValue } from 'jotai';
import { useResizeObserver } from '@mantine/hooks';
import { useCallback, useMemo, useState } from 'react';
import { FixedSizeList } from 'react-window';
import { useMarketListings } from './MarketListings.hooks';
import { marketplaceListingsOutliersAtom, seatingChartFiltersAtom, selectedMarketplaceEventAtom, targetComparablesAtom } from '../../data/atoms';
import { formatCurrency } from '../../utils/formatters';
import MobileDataOffIcon from '../../components/icons/MobileDataOff';
import ErrorFilledIcon from '../../components/icons/ErrorFilled';
import { SupportForm } from '../Support/SupportForm';
import { BarkerCoreModelsMarketplacesListing } from '../../api';
import LiveHelpIcon from '../../components/icons/LiveHelp';
import { useGlobalState } from '../../data/GlobalState';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import BNPullToRefresh from '../../components/PullToRefresh/PullToRefresh';
import classes from './MarketListings.styles.tsx.module.css';
import { ListingCardDelivery } from './MarketListings.ListCardDelivery';

export function MarketListingsList() {
  const { isMobile, principal, tenants } = useGlobalState('isMobile', 'principal', 'tenants');
  const { rowData, isFetching, isAutoPriced, showListingAge, fetchUpdatedListings, isFetchingUpdatedListings } = useMarketListings(
    'rowData',
    'isFetching',
    'isAutoPriced',
    'showListingAge',
    'fetchUpdatedListings',
    'isFetchingUpdatedListings',
  );
  const targetComparables = useAtomValue(targetComparablesAtom);
  const [filters, setFilters] = useAtom(seatingChartFiltersAtom);
  const selectedMarketplaceEvent = useAtomValue(selectedMarketplaceEventAtom);
  const outliers = useAtomValue(marketplaceListingsOutliersAtom);
  const [parentRef, parentRect] = useResizeObserver();
  const [supportListing, setSupportListing] = useState<BarkerCoreModelsMarketplacesListing>();

  function CustomNoRowsOverlay() {
    return (
      <BNEmptyState
        h="90%"
        border={false}
        icon={<MobileDataOffIcon color="var(--colors-iconFill)" size={28} />}
        iconBg="var(--colors-opacity-hover)"
        title="No Results Found"
        description="Try adjusting the filter options"
      />
    );
  }

  function CustomLoadingOverlay() {
    return (
      <div className={`${classes.customLoadingOverlay} custom-loading-overlay`}>
        <Loader color="var(--colors-gray-5)" type="dots" />
      </div>
    );
  }

  const ignoreListing = useCallback(
    (listingId: string) => {
      setFilters((prev) => ({
        ...prev,
        ignoredListingIds: prev.ignoredListingIds ? [...prev.ignoredListingIds, listingId] : [listingId],
      }));
    },
    [setFilters],
  );

  const unignoreListing = useCallback(
    (listingId: string) => {
      setFilters((prev) => ({
        ...prev,
        ignoredListingIds: prev.ignoredListingIds?.filter((id) => id !== listingId),
      }));
    },
    [setFilters],
  );

  const Row = useMemo(
    () =>
      ({ index, style }: { index: number; style: React.CSSProperties }) => {
        const listing = rowData[index];
        const isTarget = targetComparables?.some((c) => c.listingId === listing?.listingId);
        const ignore = filters.ignoredListingIds?.includes(listing.listingId);
        const tenantId = listing?.tenantId;
        const tenantName = tenantId ? tenants?.find((t) => t.tenantId === listing?.tenantId)?.name : undefined;
        const tenantColor = tenantId ? principal?.settings?.tenantColors[tenantId] ?? 'var(--colors-brandcolor-5)' : undefined;
        const isOutlier = outliers?.some((o) => o.listingId === listing.listingId);

        return (
          <Box style={style} className={classes.listingCardDeliveryWrapper}>
            <ListingCardDelivery
              key={listing.listingId}
              listingId={listing.listingId}
              firstSeenAt={listing.firstSeenAt}
              showListingAge={showListingAge}
              section={listing.sectionAlias || listing.section}
              row={listing.row}
              qty={listing.quantity}
              price={formatCurrency(listing.adjustedPrice.value)}
              own={listing.isOwnListing}
              target={isTarget}
              ignore={ignore}
              onIgnore={ignoreListing}
              onUnignore={unignoreListing}
              disclosures={listing.disclosures}
              openSupport={() => setSupportListing(listing)}
              tenantName={tenantName}
              tenantColor={tenantColor}
              outlier={isOutlier}
              deliveryMethod={listing.deliveryMethod}
            />
          </Box>
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowData, targetComparables, filters.ignoredListingIds, ignoreListing, unignoreListing, isAutoPriced, showListingAge],
  );

  return (
    <Box className={classes.outerWrapper}>
      <Stack gap={0} className={classes.innerWrapper}>
        {!isFetching &&
          rowData.length > 0 &&
          isAutoPriced &&
          !((filters?.sectionIds && filters?.sectionIds?.length > 0) || filters?.rows !== '' || (filters?.splits && filters?.splits?.length > 0)) && (
            <Group
              wrap="nowrap"
              px="sm"
              gap="xs"
              fz="xs"
              p="xs"
              py={6.5}
              bg="var(--colors-paper)"
              c="var(--colors-red-error)"
              style={{ borderBottom: '1px solid var(--colors-divider)' }}
            >
              <Center mah={20} maw={20}>
                <ErrorFilledIcon />
              </Center>
              You must set a filter to compare to the market.
            </Group>
          )}
        <Box className={`${classes.listWrapper} market-results-wrapper`} pos="relative" ref={parentRef}>
          {isFetching && CustomLoadingOverlay()}
          {!isFetching && isMobile && (
            <BNPullToRefresh wrapperRef={parentRef} listClass="market-results" refreshAction={fetchUpdatedListings} isRefreshing={isFetchingUpdatedListings} />
          )}
          {!isFetching && rowData.length === 0 && CustomNoRowsOverlay()}
          {!isFetching && rowData.length > 0 && (
            <FixedSizeList className="market-results" height={parentRect?.height || 0} itemCount={rowData.length} itemSize={50} overscanCount={10} layout="vertical" width="100%">
              {Row}
            </FixedSizeList>
          )}
        </Box>
      </Stack>
      {supportListing && selectedMarketplaceEvent && (
        <Modal
          opened
          onClose={() => setSupportListing(undefined)}
          closeOnClickOutside={false}
          title={
            <Group gap="xs">
              <LiveHelpIcon color="var(--colors-iconFill)" />
              <Text size="sm">Support Request - Marketplace</Text>
            </Group>
          }
          centered
          size="md"
        >
          <SupportForm
            type="marketplaceListing"
            marketplaceId={selectedMarketplaceEvent!.marketplaceId!}
            event={selectedMarketplaceEvent!.marketplaceEvent!}
            listing={supportListing}
            onClose={() => setSupportListing(undefined)}
          />
        </Modal>
      )}
    </Box>
  );
}
