import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import {
  BarkerCoreModelsInventoryEvent,
  BarkerCoreModelsInventoryEventMapping,
  BarkerCoreModelsMarketplacesGetListingsResponse,
  getApiMarketplacesMarketplaceIdEventsEventIdListings,
  getGetApiMarketplacesMarketplaceIdEventsEventIdListingsQueryKey,
} from '../../api';
import {
  marketplaceListingsAtom,
  marketplaceListingsFilteredAtom,
  ruleStateAtom,
  selectedEventAtom,
  selectedMarketplaceEventAtom,
  showIgnoredListingsAtom,
  showOutliersAtom,
  showOwnListingsAtom,
} from '../../data/atoms';
import yasml from '@thirtytech/yasml';
import { useLocalStorage } from '@mantine/hooks';
import dayjs from 'dayjs';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useGlobalState } from '../../data/GlobalState';
import pLimit from 'p-limit';
import { useLocation } from 'react-router-dom';

export const marketListingsLimit = pLimit(5);

function MarketListingState({
  overrideEvent,
  overrideMarketplaceEvent,
  disabled,
}: {
  overrideEvent?: BarkerCoreModelsInventoryEvent | null;
  overrideMarketplaceEvent?: BarkerCoreModelsInventoryEventMapping | null;
  disabled?: boolean;
}) {
  const globalSelectedMarketplaceEvent = useAtomValue(selectedMarketplaceEventAtom);
  const globalSelectedEvent = useAtomValue(selectedEventAtom);
  const { isMobile } = useGlobalState('isMobile');
  const location = useLocation();
  const isSeasonView = location.pathname === '/seasons';

  // TODO: Need to fix this typing mismatch
  const selectedMarketplaceEvent = overrideMarketplaceEvent !== undefined ? overrideMarketplaceEvent : globalSelectedMarketplaceEvent;
  const selectedEvent = useMemo(() => (overrideEvent !== undefined ? overrideEvent : globalSelectedEvent), [globalSelectedEvent, overrideEvent]);

  const queryClient = useQueryClient();
  const [showOwnListings, setShowOwnListings] = useLocalStorage({
    key: 'setting-showOwnListings',
    defaultValue: true,
    getInitialValueInEffect: false,
  });
  const { isAutoPriced } = useAtomValue(ruleStateAtom);
  const [showIgnoredListings, setShowIgnoredListings] = useLocalStorage({
    defaultValue: false,
    key: 'setting-showIgnoredListings',
    getInitialValueInEffect: false,
  });
  const [showListingAge, setShowListingAge] = useLocalStorage({
    defaultValue: false,
    key: 'setting-showListingAge',
    getInitialValueInEffect: false,
  });
  const [showOutliers, setShowOutliers] = useLocalStorage({
    defaultValue: false,
    key: 'setting-showOutliers',
    getInitialValueInEffect: true,
  });
  const setShowOwnListingsOnAtom = useSetAtom(showOwnListingsAtom);
  const setShowIgnoredListingsOnAtom = useSetAtom(showIgnoredListingsAtom);
  const setShowOutliersOnAtom = useSetAtom(showOutliersAtom);

  useEffect(() => {
    setShowOwnListingsOnAtom(overrideEvent || isMobile ? true : showOwnListings);
    setShowIgnoredListingsOnAtom(overrideEvent || isMobile ? true : showIgnoredListings);
    setShowOutliersOnAtom(overrideEvent || isMobile ? true : showOutliers);
  }, [overrideEvent, setShowIgnoredListingsOnAtom, setShowOutliersOnAtom, setShowOwnListingsOnAtom, showIgnoredListings, showOutliers, showOwnListings, isMobile]);

  const { data: marketplaceListingResult, isFetching } = useQuery(
    [
      ...getGetApiMarketplacesMarketplaceIdEventsEventIdListingsQueryKey(selectedMarketplaceEvent?.marketplaceId!, selectedMarketplaceEvent?.marketplaceEventId!, {
        bypassCache: false,
      }),
      selectedEvent?.tenantId,
    ],
    () =>
      marketListingsLimit(() =>
        getApiMarketplacesMarketplaceIdEventsEventIdListings(
          selectedMarketplaceEvent?.marketplaceId!,
          selectedMarketplaceEvent?.marketplaceEventId!,
          {
            bypassCache: false,
          },
          {
            headers: {
              'x-tenant-id': selectedEvent?.tenantId,
            },
          },
        ),
      ),
    {
      enabled: !!selectedEvent?.tenantId && !!selectedMarketplaceEvent?.marketplaceEventId && !!selectedMarketplaceEvent?.marketplaceId && !disabled,
      staleTime: 60 * 1000,
      cacheTime: 60 * 1000,
    },
  );

  const setMarketplaceListings = useSetAtom(marketplaceListingsAtom);
  useEffect(() => {
    requestAnimationFrame(() => {
      setMarketplaceListings(marketplaceListingResult?.data.listings || []);
    });
  }, [marketplaceListingResult, setMarketplaceListings]);

  const { refetch: fetchUpdatedListings, isFetching: isFetchingUpdatedListings } = useQuery(
    [selectedMarketplaceEvent?.marketplaceId, selectedMarketplaceEvent?.marketplaceEventId, selectedEvent?.tenantId],
    () => {
      if (!selectedEvent?.tenantId || !selectedMarketplaceEvent?.marketplaceId || !selectedMarketplaceEvent?.marketplaceEventId) {
        return Promise.resolve({ data: {} as BarkerCoreModelsMarketplacesGetListingsResponse });
      }
      return getApiMarketplacesMarketplaceIdEventsEventIdListings(
        selectedMarketplaceEvent?.marketplaceId!,
        selectedMarketplaceEvent?.marketplaceEventId!,
        {
          bypassCache: true,
        },
        {
          headers: {
            'x-tenant-id': selectedEvent?.tenantId,
          },
        },
      ).then((data) => {
        queryClient.setQueryData(
          [
            ...getGetApiMarketplacesMarketplaceIdEventsEventIdListingsQueryKey(selectedMarketplaceEvent?.marketplaceId!, selectedMarketplaceEvent?.marketplaceEventId!, {
              bypassCache: false,
            }),
            selectedEvent?.tenantId,
          ],
          data,
        );
        return data;
      });
    },
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (!marketplaceListingResult) {
      return;
    }

    //@ts-ignore - Header date is not on header object
    if (marketplaceListingResult.data.asOfDate >= dayjs(marketplaceListingResult.headers.get('date')).subtract(5, 'minutes').toDate()) {
      return;
    }

    if (disabled || isSeasonView) {
      return;
    }

    if (selectedMarketplaceEvent?.marketplaceId && selectedMarketplaceEvent?.marketplaceEventId) {
      fetchUpdatedListings();
    }
  }, [disabled, fetchUpdatedListings, isSeasonView, marketplaceListingResult, selectedMarketplaceEvent?.marketplaceEventId, selectedMarketplaceEvent?.marketplaceId]);

  const rowDataRaw = useMemo(() => marketplaceListingResult?.data.listings || [], [marketplaceListingResult]);
  const rowData = useAtomValue(marketplaceListingsFilteredAtom);

  const summaryTotals = useMemo(
    () => ({
      totalListings: rowDataRaw.length,
      totalFilteredListings: rowData.length,
      totalTickets: rowDataRaw.reduce((acc, listing) => acc + (listing.quantity || 0), 0),
      totalFilteredTickets: rowData.reduce((acc, listing) => acc + (listing.quantity || 0), 0),
    }),
    [rowData, rowDataRaw],
  );

  return {
    rowData,
    summaryTotals,
    isFetching,
    setShowOwnListings,
    showOwnListings,
    isLoading: isFetching,
    showIgnoredListings,
    setShowIgnoredListings,
    isAutoPriced,
    isFetchingUpdatedListings,
    fetchUpdatedListings,
    showListingAge,
    setShowListingAge,
    showOutliers,
    setShowOutliers,
    unfilteredListings: rowDataRaw,
    marketplaceId: selectedMarketplaceEvent?.marketplaceId,
    selectedMarketplaceEvent,
  };
}

export const { Provider: MarketListingProvider, useSelector: useMarketListings } = yasml(MarketListingState);
