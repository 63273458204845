import { IconPropsBase } from './IconPropsBase';

export default function CachedIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M482-170.001q-129.769 0-220.884-90.307Q170.001-350.616 170.001-480v-31.233L96-437.232l-42.153-42.153L200-625.537l146.153 146.152L304-437.232l-74.001-74.001V-480q0 104.231 73.385 177.116Q376.769-229.999 482-229.999q24.846 0 49.654-5.423 24.807-5.423 48.423-16.269l44.999 44.998q-33.769 18.154-69.73 27.423-35.962 9.269-73.346 9.269Zm278-164.462L613.847-480.615 656-522.768l74.001 74.001V-480q0-104.231-73.385-177.116Q583.231-730.001 478-730.001q-24.846 0-49.654 5.423-24.807 5.423-48.423 16.269l-44.999-44.998q33.769-18.154 69.73-27.423 35.962-9.269 73.346-9.269 129.769 0 220.884 90.307Q789.999-609.384 789.999-480v31.233L864-522.768l42.153 42.153L760-334.463Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M480-202.001q-115.769 0-198.384-82.307Q199.001-366.616 202.001-486v-24.233L135-443.232l-37.153-37.153L228-610.537l130.153 130.152L321-443.232l-67.001-67.001V-486q-2 98.231 64.885 165.116Q385.769-253.999 480-253.999q20.846 0 40.654-3.423 19.807-3.423 38.423-10.269l37.999 37.998q-25.769 12.154-55.23 19.923-29.462 7.769-61.846 7.769Zm252-147.462L601.847-479.615 639-516.768l67.001 67.001V-474q2-98.231-64.885-165.116Q574.231-706.001 480-706.001q-19.846 0-40.154 3.423-20.307 3.423-38.923 10.269l-37.999-37.998q25.769-12.154 55.73-19.923 29.962-7.769 61.346-7.769 116.769 0 198.884 82.307Q760.999-593.384 757.999-474v24.233L825-516.768l37.153 37.153L732-349.463Z"
        />
      </svg>
    );
  }
}
