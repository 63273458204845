import { IconPropsBase } from './IconPropsBase';

export default function StadiumIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M3.725 7V3.3l3.7 1.85Zm13.85 0V3.3l3.7 1.85Zm-6.45-.925v-3.7l3.675 1.85ZM10.75 21.65q-1.825-.075-3.337-.313Q5.9 21.1 4.8 20.738q-1.1-.363-1.725-.838t-.625-1v-8.6q0-.6.725-1.088.725-.487 2.013-.862 1.287-.375 3.037-.588Q9.975 7.55 12 7.55t3.775.212q1.75.213 3.037.588 1.288.375 2.013.862.725.488.725 1.088v8.6q0 .525-.625 1t-1.725.838q-1.1.362-2.612.599-1.513.238-3.338.313v-4h-2.5ZM12 11.55q2.45 0 4.413-.35 1.962-.35 3.037-.85-.175-.325-2.15-.813-1.975-.487-5.3-.487-3.325 0-5.3.487-1.975.488-2.15.813 1.075.5 2.925.85 1.85.35 4.525.35Zm-2.75 8.475V16.15h5.5v3.875q2.175-.175 3.538-.6Q19.65 19 20.05 18.65v-6.925q-1.5.65-3.55.987-2.05.338-4.5.338t-4.5-.338q-2.05-.337-3.55-.987v6.925q.4.35 1.7.775 1.3.425 3.6.6Zm2.75-4.15Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M3.604 6V3.25l2.75 1.375Zm11.042 0V3.25l2.75 1.375Zm-5.542-.938v-2.75l2.729 1.376Zm-.312 12.646q-1.396-.062-2.573-.26t-2.031-.51q-.855-.313-1.334-.698-.479-.386-.479-.823V8.5q0-.458.563-.823.562-.365 1.583-.635 1.021-.271 2.427-.427Q8.354 6.458 10 6.458q1.646 0 3.042.157 1.396.156 2.427.427 1.031.27 1.593.635.563.365.563.823v6.917q0 .437-.479.823-.479.385-1.334.698-.854.312-2.031.51t-2.573.26v-3.5H8.792ZM10 9.458q1.875 0 3.469-.229 1.593-.229 2.51-.687-.417-.459-2.208-.729-1.792-.271-3.771-.271-2.042 0-3.802.26-1.76.26-2.177.74.917.458 2.417.687 1.5.229 3.562.229Zm-2.292 7.084v-3.417h4.584v3.417q1.375-.104 2.541-.448 1.167-.344 1.709-.782V9.5q-1.23.542-2.896.792-1.667.25-3.646.25-2 0-3.656-.25-1.656-.25-2.886-.792v5.812q.5.417 1.646.771 1.146.355 2.604.459ZM10 13.083Z"
        />
      </svg>
    );
  }
}
