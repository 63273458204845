import { ActionIcon, Box, Group, NumberInputFactory, StylesApiProps, Tooltip } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { ReactNode, forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { BarkerCoreEnumsAdjustmentType } from '../../api';
import { BNNumberInput } from '../NumberInput/NumberInput';
import { BNSelect } from '../Select/Select';
import { roundAdjustmentValue } from '../../utils/rule-utils';
import DiagonalButton from '../DiagonalButton';
import DoNotDisturbIcon from '../icons/DoNotDisturb';
import AddCircleIcon from '../icons/AddCircle';
import classes from './AdjustmentValueInputDiagonal.Merged.module.css';
import cx from 'clsx';

export const AdjustmentValueInputDiagonalMerged = forwardRef(
  (
    {
      type,
      numInputWidth,
      disabled,
      label,
      value,
      onChange,
      error,
      onKeyUp,
      disablePercentage,
      step,
      tooltipLabel,
      round,
      positiveOnly,
      mobile,
    }: {
      type: BarkerCoreEnumsAdjustmentType;
      step?: number;
      positiveOnly?: boolean;
      disablePercentage?: boolean;
      numInputWidth?: number;
      value: number | '';
      label?: string | ReactNode | undefined;
      tooltipLabel?: string | undefined;
      onChange: (value: { value: number | undefined; type: BarkerCoreEnumsAdjustmentType }) => void;
      onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
      disabled?: boolean;
      round?: boolean;
      error?: React.ReactNode;
      mobile?: boolean;
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    // Updated from outside world
    useDidUpdate(() => {
      const convertedValue = convertValue(value, type);
      if (convertedValue !== _valueInternal && type === _typeInternal) {
        _setValueInternal(convertedValue);
      } else if (type !== _typeInternal) {
        _setTypeInternal(type);
        _setValueInternal(convertedValue);
      }
    }, [value, type]);

    const convertValue = useCallback((_value: number | '', _type: BarkerCoreEnumsAdjustmentType) => {
      if (_value === 0 || _value === '' || _value === undefined || _value === null) {
        return 0;
      }
      let result = _value;
      if (_type === 'Percentage' && (_value <= 1 || _value > 1)) {
        result = parseFloat(((_value - 1) * 100).toFixed(4));
      }
      return result;
    }, []);

    const [_valueInternal, _setValueInternal] = useState(convertValue(value, type));
    const [_typeInternal, _setTypeInternal] = useState(type);
    const [isPositive, setIsPositive] = useState(value && value > (type === 'Percentage' ? 1 : 0) ? true : false);

    const _onChangeInternal = (_value: number | '', _type?: BarkerCoreEnumsAdjustmentType, _isPositive?: boolean) => {
      type = _type || type;
      const isPositiveLocal = _isPositive ?? isPositive;
      if (_value === '' || _value === undefined || _value === null) {
        _setValueInternal(0);
        return onChange({ value: type === 'Percentage' ? 1 : 0, type: type });
      }

      let result = _value;

      if (type === 'Amount') {
        result = isPositiveLocal ? Math.abs(_value) : _value < 0 ? _value : -_value;
      } else if (type === 'Percentage') {
        result = parseFloat((1 + ((isPositiveLocal ? 1 : -1) * Math.abs(_value)) / 100).toFixed(4));
      }

      if (round) {
        result = roundAdjustmentValue(result)!;
      }

      _setValueInternal(_value);
      onChange({ value: result, type: type });
    };

    const _dualOnChangeHandler = useCallback(
      (type: BarkerCoreEnumsAdjustmentType) => {
        _setTypeInternal(type);
        _onChangeInternal(_valueInternal, type);
      },
      [_valueInternal],
    );

    useDidUpdate(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.selectionStart = inputRef.current?.selectionEnd;
      }
    }, [value]);

    useImperativeHandle(ref, () => inputRef.current);

    return (
      <Group className="bn-adjustment-input-diagonal" gap={0} mb={mobile && error ? 24 : 0} wrap="nowrap" pos="relative" align="end" w="100%">
        <Tooltip withArrow label={tooltipLabel} hidden={!tooltipLabel}>
          <BNSelect
            onChange={(x) => _dualOnChangeHandler(x as BarkerCoreEnumsAdjustmentType)}
            value={!disablePercentage ? _typeInternal : BarkerCoreEnumsAdjustmentType.Amount}
            m={0}
            error={error}
            withinPortal={mobile}
            mr={-1}
            p={0}
            size={mobile ? 'lg' : 'xs'}
            w={mobile ? 84 : undefined}
            className={cx([mobile ? classes.middleInputMobile : classes.middleInput, isPositive ? classes.isPositive : null, !mobile ? classes.shortSelectRoot : null])}
            styles={{ input: { paddingRight: 0 } }}
            rightSectionWidth={20}
            label={label}
            disabled={disabled}
            readOnly={disablePercentage}
            comboboxProps={{ styles: { option: { paddingLeft: 4, paddingRight: 4, display: 'flex', justifyContent: 'center' } } }}
            data={[{ value: BarkerCoreEnumsAdjustmentType.Amount, label: '$' }, ...(disablePercentage ? [] : [{ value: BarkerCoreEnumsAdjustmentType.Percentage, label: '%' }])]}
          />
        </Tooltip>
        {!positiveOnly && (
          <Box pos="relative" style={{ zIndex: 1 }}>
            <DiagonalButton
              size={mobile ? 'lg' : 'xs'}
              value={isPositive}
              error={error}
              toggleValue={(value) => {
                setIsPositive(value);
                _onChangeInternal(_valueInternal, type, value);
              }}
            />
          </Box>
        )}
        <BNNumberInput
          ref={inputRef}
          disabled={disabled}
          error={error}
          errorPosition={mobile ? 'bottom' : 'auto'}
          value={Math.abs(_valueInternal)}
          forceIconPointerEvents={mobile}
          className={`${mobile ? classes.mobilePriceInput : classes.priceInput} ${isPositive ? classes.isPositive : ''}`}
          w={numInputWidth}
          leftSection={
            mobile && (
              <ActionIcon
                h={36}
                w={36}
                bg="var(--colors-gray-0)"
                // onMouseDown={(e) => e.preventDefault()}
                style={{ borderRadius: 100 }}
                onClick={() => {
                  _onChangeInternal((_valueInternal || 0) - (isPositive ? step || 0.1 : -(step || 0.1)), type, isPositive);
                }}
              >
                <DoNotDisturbIcon size={30} color="var(--colors-selectedBorder)" />
              </ActionIcon>
            )
          }
          leftSectionWidth={mobile ? '33.3333%' : undefined}
          rightSection={
            mobile && (
              <ActionIcon
                h={36}
                w={36}
                bg="var(--colors-gray-0)"
                style={{ borderRadius: 100 }}
                // onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  _onChangeInternal((_valueInternal || 0) + (isPositive ? step || 0.1 : -(step || 0.1)), type, isPositive);
                }}
              >
                <AddCircleIcon size={30} color="var(--colors-selectedBorder)" />
              </ActionIcon>
            )
          }
          rightSectionWidth={mobile ? '33.3333%' : undefined}
          onChange={_onChangeInternal}
          onKeyUp={onKeyUp}
          onKeyDown={(e) => {
            if (e.key === '-') {
              setIsPositive(false);
              e.preventDefault();
              e.stopPropagation();
            } else if (e.key === '+') {
              setIsPositive(true);
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          radius={0}
          m={0}
          ml={-1}
          p={0}
          size={mobile ? 'lg' : 'xs'}
          hideControls={mobile}
          label={label}
          labelProps={{ maw: '0' }}
          decimalScale={2}
          fixedDecimalScale
          selectOnFocus
          stepHoldDelay={500}
          stepHoldInterval={100}
          styles={{input:{paddingRight:0}}}
          min={0}
          step={step || 0.1}
          data-autofocus={mobile}
          autoFocus={mobile}
        />
      </Group>
    );
  },
);
