import { IconPropsBase } from './IconPropsBase';

export default function SettingsIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="m9.7 21.5-.4-3.05q-.4-.125-.812-.375-.413-.25-.763-.525L4.9 18.75l-2.3-4 2.45-1.85q-.05-.225-.062-.45-.013-.225-.013-.45 0-.2.013-.425.012-.225.062-.475L2.6 9.25l2.3-3.975L7.725 6.45q.35-.275.763-.512Q8.9 5.7 9.3 5.55l.4-3.05h4.6l.4 3.05q.45.175.812.387.363.213.738.513l2.85-1.175 2.3 3.975-2.475 1.875q.05.25.05.45V12q0 .2-.013.412-.012.213-.062.488l2.45 1.85-2.3 4-2.8-1.2q-.375.3-.762.525-.388.225-.788.375l-.4 3.05ZM12 15q1.25 0 2.125-.875T15 12q0-1.25-.875-2.125T12 9q-1.25 0-2.125.875T9 12q0 1.25.875 2.125T12 15Zm0-1.5q-.625 0-1.062-.438Q10.5 12.625 10.5 12t.438-1.062Q11.375 10.5 12 10.5t1.062.438q.438.437.438 1.062t-.438 1.062q-.437.438-1.062.438Zm0-1.5Zm-1 8h1.975l.35-2.675q.775-.2 1.4-.575.625-.375 1.225-.95l2.475 1.05 1-1.7-2.175-1.625q.125-.4.163-.775.037-.375.037-.75 0-.4-.037-.762-.038-.363-.163-.738l2.175-1.65-.975-1.7-2.525 1.05q-.5-.525-1.2-.938-.7-.412-1.4-.587L13 4h-1.975l-.35 2.675q-.75.175-1.4.55-.65.375-1.25.95L5.55 7.15l-.975 1.7 2.15 1.6q-.125.375-.175.75-.05.375-.05.8 0 .4.05.775t.175.75l-2.15 1.625.975 1.7 2.475-1.05q.575.575 1.225.95.65.375 1.425.575Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="m8.021 17.917-.313-2.5q-.27-.125-.625-.334-.354-.208-.625-.395l-2.312.979-1.979-3.438 1.979-1.5q-.021-.167-.031-.364-.011-.198-.011-.365 0-.146.011-.344.01-.198.031-.385l-1.979-1.5 1.979-3.417 2.312.958q.271-.187.615-.385t.635-.344l.313-2.5h3.958l.313 2.5q.312.167.625.344.312.177.604.385l2.333-.958 1.979 3.417-1.979 1.521q.021.187.021.364V10q0 .146-.01.333-.011.188-.011.396l1.958 1.5-1.979 3.438-2.312-.979q-.292.208-.615.395-.323.188-.614.334l-.313 2.5Zm1.937-5.355q1.063 0 1.813-.75t.75-1.812q0-1.062-.75-1.812t-1.813-.75q-1.041 0-1.802.75-.76.75-.76 1.812t.76 1.812q.761.75 1.802.75Zm0-1.333q-.5 0-.864-.364-.365-.365-.365-.865t.365-.865q.364-.364.864-.364t.865.364q.365.365.365.865t-.365.865q-.365.364-.865.364ZM10.021 10Zm-.854 6.583h1.666l.25-2.187q.605-.167 1.136-.49.531-.323 1.031-.802l2.021.875.854-1.375-1.792-1.354q.105-.333.136-.635.031-.303.031-.615 0-.292-.031-.573-.031-.281-.115-.635l1.792-1.396-.834-1.375-2.062.875q-.438-.438-1.021-.781-.583-.344-1.125-.49l-.271-2.208H9.167l-.271 2.208q-.584.146-1.125.458-.542.313-1.042.792l-2.021-.854-.833 1.375 1.75 1.354q-.083.333-.125.646-.042.312-.042.604t.042.594q.042.302.125.635l-1.75 1.375.833 1.375 2.021-.854q.479.458 1.021.771.542.312 1.146.479Z"
        />
      </svg>
    );
  }
}
