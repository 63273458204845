import { IconPropsBase } from './IconPropsBase';

export default function WifiIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M12,20.71c-.57,0-1.04-.2-1.43-.59-.39-.39-.59-.87-.59-1.43s.2-1.04.59-1.43c.39-.39.87-.59,1.43-.59s1.04.2,1.43.59c.39.39.59.87.59,1.43s-.2,1.04-.59,1.43c-.39.39-.87.59-1.43.59Z"
        />
        <path
          fill={color}
          d="M2.1,11.1l-1.56-1.56c1.46-1.48,3.17-2.65,5.13-3.5,1.95-.85,4.07-1.27,6.34-1.27s4.38.42,6.34,1.27c1.95.85,3.66,2.01,5.13,3.5l-1.56,1.56c-1.28-1.28-2.77-2.29-4.46-3.01s-3.5-1.09-5.44-1.09-3.75.36-5.44,1.09c-1.69.73-3.18,1.73-4.46,3.01Z"
        />
        <path
          fill={color}
          d="M6.35,15.35l-1.56-1.59c.93-.93,2-1.66,3.23-2.19,1.23-.53,2.56-.8,3.98-.8s2.75.27,3.98.81c1.23.54,2.31,1.28,3.23,2.21l-1.56,1.56c-.73-.73-1.58-1.31-2.55-1.73s-2-.63-3.1-.63-2.13.21-3.1.63-1.82.99-2.55,1.73Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M1.42,8.44l-.97-.99c1.25-1.18,2.68-2.11,4.31-2.79,1.63-.68,3.37-1.02,5.24-1.02s3.61.34,5.24,1.02c1.63.68,3.07,1.61,4.31,2.79l-.97.99c-1.13-1.07-2.42-1.91-3.88-2.52-1.46-.61-3.03-.92-4.7-.92s-3.24.31-4.7.92c-1.46.61-2.76,1.45-3.88,2.52Z"
        />
        <path
          fill={color}
          d="M4.96,11.96l-.97-.95c.81-.74,1.73-1.32,2.74-1.74,1.01-.42,2.1-.63,3.27-.63s2.26.21,3.27.62c1.01.41,1.92.99,2.73,1.73l-.97.97c-.67-.61-1.43-1.09-2.29-1.44-.86-.35-1.78-.52-2.75-.52s-1.89.17-2.75.52c-.86.35-1.62.83-2.29,1.44Z"
        />
        <path
          fill={color}
          d="M10,16.76c-.45,0-.83-.15-1.14-.46-.31-.31-.47-.69-.47-1.13s.15-.83.46-1.14c.31-.31.69-.47,1.13-.47s.83.15,1.14.46c.31.31.47.69.47,1.13s-.15.83-.46,1.14c-.31.31-.69.47-1.13.47Z"
        />
      </svg>
    );
  }
}
