import { IconPropsBase } from './IconPropsBase';

export default function FolderCopyIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M132.31-144.62q-29.92 0-51.12-21.19Q60-187 60-216.92v-481.54h60v481.54q0 5.38 3.46 8.84t8.85 3.46H780v60H132.31Zm140-140q-29.93 0-51.12-21.19Q200-327 200-356.92v-430.77q0-29.92 21.19-51.12Q242.38-860 272.31-860h199.23l80 80h276.15q29.92 0 51.12 21.19Q900-737.61 900-707.69v350.77q0 29.92-21.19 51.11-21.2 21.19-51.12 21.19H272.31Zm0-59.99h555.38q5.39 0 8.85-3.47 3.46-3.46 3.46-8.84v-350.77q0-5.39-3.46-8.85t-8.85-3.46H527l-80-80H272.31q-5.39 0-8.85 3.46t-3.46 8.85v430.77q0 5.38 3.46 8.84 3.46 3.47 8.85 3.47Zm-12.31 0V-800v455.39Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M132.31-168.62q-25.92 0-45.12-19.19Q68-207 68-232.92v-441.54h52v441.54q0 5.38 3.46 8.84t8.85 3.46H748v52H132.31Zm124-124q-25.93 0-45.12-19.19Q192-331 192-356.92v-422.77q0-26.62 19.19-45.47Q230.38-844 256.31-844h168.23l96 96h259.15q26.62 0 45.47 18.84Q844-710.31 844-683.69v326.77q0 25.92-18.84 45.11-18.85 19.19-45.47 19.19H256.31Zm0-51.99h523.38q5.39 0 8.85-3.47 3.46-3.46 3.46-8.84v-326.77q0-5.39-3.46-8.85t-8.85-3.46H500l-96-96H256.31q-5.39 0-8.85 3.46t-3.46 8.85v422.77q0 5.38 3.46 8.84 3.46 3.47 8.85 3.47Zm-12.31 0V-792v447.39Z"
        />
      </svg>
    );
  }
}
