import { IconPropsBase } from './IconPropsBase';

export default function AccountIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M6.025 17.3Q7.3 16.35 8.8 15.8t3.2-.55q1.7 0 3.2.55 1.5.55 2.775 1.5.925-1.025 1.475-2.375T20 12q0-3.325-2.337-5.663Q15.325 4 12 4T6.338 6.337Q4 8.675 4 12q0 1.575.55 2.925.55 1.35 1.475 2.375ZM12 12.75q-1.375 0-2.312-.938-.938-.937-.938-2.312 0-1.375.938-2.312.937-.938 2.312-.938 1.375 0 2.312.938.938.937.938 2.312 0 1.375-.938 2.312-.937.938-2.312.938Zm0 8.75q-1.975 0-3.712-.75Q6.55 20 5.275 18.725T3.25 15.712Q2.5 13.975 2.5 12t.75-3.713Q4 6.55 5.275 5.275T8.288 3.25Q10.025 2.5 12 2.5t3.713.75q1.737.75 3.012 2.025t2.025 3.012q.75 1.738.75 3.713t-.75 3.712q-.75 1.738-2.025 3.013t-3.012 2.025q-1.738.75-3.713.75Zm0-1.5q1.35 0 2.613-.438 1.262-.437 2.237-1.212-.975-.75-2.213-1.175Q13.4 16.75 12 16.75t-2.637.413q-1.238.412-2.213 1.187.975.775 2.237 1.212Q10.65 20 12 20Zm0-8.75q.75 0 1.25-.5t.5-1.25q0-.75-.5-1.25T12 7.75q-.75 0-1.25.5t-.5 1.25q0 .75.5 1.25t1.25.5Zm0-1.75Zm0 8.875Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M5.083 14.229q1.125-.771 2.344-1.146 1.219-.375 2.573-.375 1.354 0 2.573.375 1.219.375 2.344 1.146.771-.854 1.177-1.937.406-1.084.406-2.292 0-2.708-1.896-4.604T10 3.5q-2.708 0-4.604 1.896T3.5 10q0 1.208.406 2.292.406 1.083 1.177 1.937ZM10 11.292q-1.167 0-1.979-.813-.813-.812-.813-1.979t.813-1.979q.812-.813 1.979-.813t1.979.813q.813.812.813 1.979t-.813 1.979q-.812.813-1.979.813Zm0 6.291q-1.562 0-2.948-.593-1.385-.594-2.417-1.625-1.031-1.032-1.625-2.417-.593-1.386-.593-2.948 0-1.583.593-2.958.594-1.375 1.625-2.407Q5.667 3.604 7.052 3.01 8.438 2.417 10 2.417q1.583 0 2.958.593 1.375.594 2.407 1.625 1.031 1.032 1.625 2.407.593 1.375.593 2.958 0 1.562-.593 2.948-.594 1.385-1.625 2.417-1.032 1.031-2.407 1.625-1.375.593-2.958.593Zm0-1.083q1.104 0 2.177-.385 1.073-.386 1.948-1.094-.896-.583-1.948-.906-1.052-.323-2.177-.323t-2.188.302q-1.062.302-1.937.927.875.708 1.948 1.094Q8.896 16.5 10 16.5Zm0-6.292q.708 0 1.208-.5t.5-1.208q0-.708-.5-1.208T10 6.792q-.708 0-1.208.5t-.5 1.208q0 .708.5 1.208t1.208.5ZM10 8.5Zm0 6.542Z"
        />
      </svg>
    );
  }
}
